import {
  Flex,
  Stack,
  Heading,
  Box,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
  Textarea,
  Select,
} from "@chakra-ui/react";
import { ClientStatusDrawer } from "../../common/client-status-drawer";
import { CustomerStatusIndicator } from "../../common/customer-status-indicator/customer-status-indicator";
import { PartnerUserSelector } from "../../common/partner-user-selector";
import { PrimaryLinkButton, PrimaryButton, CancelButton } from "../../components/buttons";
import { Loader } from "../../components/loader";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { useClientDetails } from "./use-client-details";
import { AdminOnly } from "../../common/admin-only";
import { UserSelector } from "../../common/user-selector";

interface Props {
  customerId: number;
}

export function ClientDetails({ customerId }: Props) {
  const {
    customer,
    isOverviewLoading,
    detailsFormik,
    isEditStatusOpen,
    openEditStatusDrawer,
    closeEditStatusDrawer,
    refetch,
    onUserSelected,
    onRCMAccountManagerUserSelected,
    onSave,
    onCancel,
  } = useClientDetails(customerId);

  return (
    <PanelGroup isCollapsible>
      <ClientStatusDrawer
        customerId={customerId}
        isOpen={isEditStatusOpen}
        onClose={closeEditStatusDrawer}
        onEdited={() => {
          closeEditStatusDrawer();
          refetch();
        }}
      />
      <PanelTop showBottomBorder justifyContent="stretch">
        <Flex justifyContent="space-between">
          <Stack spacing={2} shouldWrapChildren>
            <Heading size="md">Client Details</Heading>
            <PrimaryLinkButton onClick={openEditStatusDrawer}>Edit Status</PrimaryLinkButton>
          </Stack>
          <Box>{customer && <CustomerStatusIndicator status={customer.status} />}</Box>
        </Flex>
      </PanelTop>
      <PanelBottom>
        {isOverviewLoading && <Loader type="content" />}
        {!isOverviewLoading && (
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Brand Manager</FormLabel>
              <PartnerUserSelector
                partnerId={customer?.partnerId ?? 0}
                userId={detailsFormik.values.accountManagerUserId}
                onSelectedUserChanged={onUserSelected}
                nullUserText="Unassigned"
                variant="outline"
              />
            </FormControl>
            <AdminOnly>
              <FormControl>
                <FormLabel>RCM Account Manager</FormLabel>
                <UserSelector
                  selectedUserId={detailsFormik.values.rcmAccountManagerUserId}
                  onUserChanged={onRCMAccountManagerUserSelected}
                  nullUserText="Unassigned"
                  variant="outline"
                  isAdminOrSuperAdmin={true}
                />
              </FormControl>
            </AdminOnly>
            <FormControl isRequired isInvalid={!!detailsFormik.errors.name}>
              <FormLabel>Company Name</FormLabel>
              <Input
                variant="outline"
                id="name"
                value={detailsFormik.values.name}
                onChange={detailsFormik.handleChange}
                placeholder="What’s the name of your business?"
              />
              <FormErrorMessage>{detailsFormik.errors.name}</FormErrorMessage>
            </FormControl>
            {/* This should not be included in customer app */}
            <FormControl>
              <FormLabel>Partner Name</FormLabel>
              <Input variant="unstyled" value={customer?.partner.name ?? ""} isReadOnly />
            </FormControl>
            <AdminOnly>
              <FormControl>
                <FormLabel>Partner Type</FormLabel>
                <Select variant="outline" value={customer?.partner?.portal ?? ""} isReadOnly>
                  <option value="partner">RCM Partner</option>
                  <option value="lmr">LMR Partner</option>
                </Select>
              </FormControl>
            </AdminOnly>
            <FormControl isInvalid={!!detailsFormik.errors.analyticsReportingInlineFrameUrl}>
              <FormLabel>Analytics Reporting Inline Frame URL</FormLabel>
              <Input
                variant="outline"
                id="analyticsReportingInlineFrameUrl"
                value={detailsFormik.values.analyticsReportingInlineFrameUrl}
                onChange={detailsFormik.handleChange}
                placeholder="https://datastudio.google.com/embed/reporting..."
              />
              <FormHelperText>
                Optional URL of Inline Frame to be shown to client in the Analytics tab of Reporting Smarts.
              </FormHelperText>
              <FormErrorMessage>{detailsFormik.errors.analyticsReportingInlineFrameUrl}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!detailsFormik.errors.instructions}>
              <FormLabel>Instructions</FormLabel>
              <Textarea
                id="instructions"
                value={detailsFormik.values.instructions ?? ""}
                onChange={detailsFormik.handleChange}
                onBlur={detailsFormik.handleBlur}
                resize="none"
                placeholder="Instructions"
                variant="outline"
              />
              <FormHelperText>
                Additional and important information provided to users fulfilling any type of work for this client.
              </FormHelperText>
              <FormErrorMessage>{detailsFormik.errors.instructions}</FormErrorMessage>
            </FormControl>
            {detailsFormik.dirty && (
              <Box>
                <PrimaryButton onClick={onSave} marginRight="8px">
                  Save
                </PrimaryButton>
                <CancelButton onClick={onCancel}>Cancel</CancelButton>
              </Box>
            )}
          </Stack>
        )}
      </PanelBottom>
    </PanelGroup>
  );
}

export const CAMPAIGN_CATEGORIES = {
  SEO: {
    id: "seo",
    name: "SEO",
  },
  WEBSITE_HOSTING: {
    id: "website-hosting",
    name: "Website Hosting",
  },
  WEB_DESIGN: {
    id: "web-design",
    name: "Web Design",
  },
  SOCIAL: {
    id: "social",
    name: "Social",
  },
  PPC: {
    id: "ppc",
    name: "PPC",
  },
};

import { FormikHelpers, useFormik } from "formik";
import * as yup from "yup";
import { useToast } from "@chakra-ui/react";

import { useMapPublisherLinkLocationMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

interface FormValues {
  publisherId?: number;
  linkLocationId?: number;
}

const validationSchema = yup.object().shape({
  publisherId: yup.number().label("Publisher").required(),
  linkLocationId: yup.number().label("Link Location").required(),
});

interface Options {
  onClose: () => void;
}

export function useMapPublisherLinkLocation(options: Options) {
  const { onClose } = options;
  const toast = useToast();

  const [createPublisherLinkLocation] = useMapPublisherLinkLocationMutation();

  async function onSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await createPublisherLinkLocation({
        variables: {
          input: {
            publisherId: values.publisherId ?? 0,
            linkLocationId: values.linkLocationId ?? 0,
            isRCMPublishing: false,
          },
        },
      });

      if (response.data?.createPublisherLinkLocation.ok) {
        toast({ title: "Success", description: "Publisher Link Location Mapped successfully!", status: "success" });
        formikHelpers.resetForm();
        onClose();
      } else {
        throw new Error(response.data?.createPublisherLinkLocation.error?.message ?? "Something went wrong!");
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      publisherId: undefined,
      linkLocationId: undefined,
    },
    validationSchema,
    onSubmit,
  });

  function onPublisherSelected(publisherId: number | null) {
    formik.setFieldValue("publisherId", publisherId ?? undefined);
  }

  function onLinkLocationSelected(linkLocationId: number | null) {
    formik.setFieldValue("linkLocationId", linkLocationId ?? undefined);
  }

  function onSave() {
    formik.submitForm();
  }

  function onCancel() {
    formik.resetForm();
    onClose();
  }

  return {
    formik,
    onPublisherSelected,
    onLinkLocationSelected,
    onSave,
    onCancel,
  };
}

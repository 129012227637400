import { Text, Tooltip } from "@chakra-ui/react";

import { EmailMessageModal } from "../../common/email-message-modal";
import { PrimaryLinkButton } from "../../components/buttons";
import { FormattedDate } from "../../components/formatted-date";
import { PageHeading } from "../../components/page-heading";
import { PanelBox } from "../../components/panels";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { TruncatedText } from "../../components/truncated-text";
import { DATE_TIME_FORMAT } from "../../constants/constants";
import { useClientDetailsEmails } from "./client-details-emails-hook";

interface Props {
  clientId: number;
}

export function ClientDetailsEmails(props: Props) {
  const { clientId } = props;
  const { emailMessages, loading, onEmailMessageDetailClose, onEmailSelected, selectedEmailMessageId } =
    useClientDetailsEmails(clientId);
  return (
    <>
      <PageHeading>Emails</PageHeading>
      <PanelBox containsTable>
        <Table isEmpty={emailMessages.length === 0} isLoading={loading}>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Date Sent</TableHeaderCell>
              <TableHeaderCell>Sender</TableHeaderCell>
              <TableHeaderCell>Recipient</TableHeaderCell>
              <TableHeaderCell>Subject</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emailMessages.map((emailMessage) => (
              <TableRow key={emailMessage.id}>
                <TableCell>
                  <FormattedDate format={DATE_TIME_FORMAT} date={emailMessage.sentAt ?? emailMessage.createdAt} />
                </TableCell>
                <TableCell>
                  <Tooltip label={emailMessage.fromAddress} aria-label={emailMessage.fromAddress}>
                    <Text>
                      <TruncatedText maxLength={50}>{emailMessage.fromName}</TruncatedText>
                    </Text>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip label={emailMessage.toAddress} aria-label={emailMessage.toAddress}>
                    <Text>
                      <TruncatedText maxLength={50}>{emailMessage.toName}</TruncatedText>
                    </Text>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <PrimaryLinkButton
                    onClick={() => {
                      onEmailSelected(emailMessage.id);
                    }}
                  >
                    <TruncatedText maxLength={50}>{emailMessage.subject}</TruncatedText>{" "}
                  </PrimaryLinkButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <EmailMessageModal emailMessageId={selectedEmailMessageId} onClose={onEmailMessageDetailClose} />
      </PanelBox>
    </>
  );
}

import { useRef, useState } from "react";
import { useToast } from "@chakra-ui/react";
import queryString from "qs";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { PAGE_LIMIT } from "../../constants/constants";
import {
  PartnerUsers_PartnerUserFragment,
  usePartnerUsersQuery,
  useUpdatePartnerUserMutation,
} from "../../generated/graphql";
import { getQueryParams, setParams } from "../../util/query-param-helper";
import { getDisplayMessageForError } from "../../util/error-helper";

interface ToggleActive {
  id: number;
  isActive: boolean;
  userName: string;
}

interface ToggleAdmin {
  id: number;
  isAdmin: boolean;
  userName: string;
}

export function useConfigurationUsers() {
  const { partnerId: partnerIdString } = useParams<"partnerId">();
  const partnerId = parseInt(partnerIdString ?? "");
  const [showAddPartnerUserDrawer, setShowAddPartnerUserDrawer] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [toggleActive, setToggleActive] = useState<ToggleActive | null>(null);
  const [toggleAdmin, setToggleAdmin] = useState<ToggleAdmin | null>(null);
  const [isActiveOpen, setIsActiveOpen] = useState(false);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [updatePartnerUser] = useUpdatePartnerUserMutation();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = getQueryParams(location.search, queryString) || {};
  const searchValue = queryParams.search ?? "";
  const pageOffset = queryParams?.offset ? parseInt(queryParams.offset, 10) : 0;
  let includeInactiveFlag = false;
  if (queryParams && queryParams.includeInactive !== undefined) {
    includeInactiveFlag = queryParams.includeInactive === "true";
  }
  const partnerUsersQueryResult = usePartnerUsersQuery({
    variables: {
      search: searchValue,
      offset: pageOffset,
      limit: PAGE_LIMIT,
      includeInactive: includeInactiveFlag,
      partnerId,
    },
    fetchPolicy: "network-only",
  });
  const toast = useToast();
  const activeCancelRef = useRef<HTMLButtonElement>(null);
  const adminCancelRef = useRef<HTMLButtonElement>(null);

  const partner = partnerUsersQueryResult.data?.partner ?? null;
  const partnerUsers = partnerUsersQueryResult.data?.partner?.partnerUsers.nodes ?? [];

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { search, ...remainingQueryParams } = queryParams;
    let searchParams;
    const value = event.target.value;
    if (value) {
      searchParams = setParams(remainingQueryParams, "search", `${value ? value : ""}`);
    } else {
      searchParams = setParams(remainingQueryParams);
    }
    searchParams = setParams(searchParams, "offset", "0");
    navigate({ search: `?${searchParams}` });
  }

  function onNextPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = parseInt(offset ?? "0", 10) + PAGE_LIMIT;
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onPreviousPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = Math.max(parseInt(offset ?? "0", 10) - PAGE_LIMIT, 0);
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onSwitchChange() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { includeInactive, ...remainingQueryParams } = queryParams;
    let params = setParams(remainingQueryParams, "includeInactive", `${!includeInactiveFlag}`);
    params = setParams(params, "offset", "0");
    navigate({ search: `?${params}` });
  }

  function onUserAdded() {
    partnerUsersQueryResult.refetch();
  }

  function openAddUserDrawer() {
    setShowAddPartnerUserDrawer(true);
  }

  function closeAddUserDrawer() {
    setShowAddPartnerUserDrawer(false);
  }

  function openFilter() {
    setShowFilter(true);
  }

  function closeFilter() {
    setShowFilter(false);
  }

  function onActiveClose() {
    setIsActiveOpen(false);
    setToggleActive(null);
  }

  function onAdminClose() {
    setIsAdminOpen(false);
    setToggleAdmin(null);
  }

  async function onActiveChange(partnerUser: PartnerUsers_PartnerUserFragment) {
    setToggleActive({
      id: partnerUser.id,
      isActive: partnerUser.isActive,
      userName: partnerUser.user.fullName,
    });
    setIsActiveOpen(true);
  }

  async function onAdminChange(partnerUser: PartnerUsers_PartnerUserFragment) {
    setToggleAdmin({
      id: partnerUser.id,
      isAdmin: partnerUser.isAdmin,
      userName: partnerUser.user.fullName,
    });
    setIsAdminOpen(true);
  }

  async function updateToggleActive() {
    if (!toggleActive) {
      return;
    }
    try {
      const response = await updatePartnerUser({
        variables: {
          input: {
            partnerUserId: toggleActive.id,
            isActive: !toggleActive.isActive,
          },
        },
      });

      if (response.data?.updatePartnerUser.partnerUser.id) {
        toast({
          title: "Success",
          description: `"${toggleActive.userName}" has been ${
            toggleActive.isActive ? "deactivated" : "activated"
          } successfully.`,
          status: "success",
        });
        partnerUsersQueryResult.refetch();
      }
    } catch (error: any) {
      toast({ title: "Success", description: getDisplayMessageForError(error), status: "error" });
    }
    setToggleActive(null);
    setIsActiveOpen(false);
  }

  async function updateToggleAdmin() {
    if (!toggleAdmin) {
      return;
    }
    try {
      const response = await updatePartnerUser({
        variables: {
          input: {
            partnerUserId: toggleAdmin.id,
            isAdmin: !toggleAdmin.isAdmin,
          },
        },
      });

      if (response.data?.updatePartnerUser.partnerUser.id) {
        toast({
          title: "Success",
          description: `Admin access ${toggleAdmin.isAdmin ? "revoked from" : "granted to"} "${toggleAdmin.userName}".`,
          status: "success",
        });
        partnerUsersQueryResult.refetch();
      }
    } catch (error: any) {
      toast({ title: "Success", description: getDisplayMessageForError(error), status: "error" });
    }
    setToggleAdmin(null);
    setIsAdminOpen(false);
  }

  return {
    partnerId,
    loading: partnerUsersQueryResult.loading,
    partner,
    partnerUsers,
    totalCount: partnerUsersQueryResult.data?.partner?.partnerUsers.totalCount ?? 0,
    showAddPartnerUserDrawer,
    showFilter,
    onChange,
    onNextPage,
    onPreviousPage,
    onSwitchChange,
    onUserAdded,
    openAddUserDrawer,
    closeAddUserDrawer,
    openFilter,
    closeFilter,
    includeInactiveFlag,
    offset: pageOffset,
    limit: PAGE_LIMIT,
    isActiveOpen,
    isAdminOpen,
    onActiveClose,
    onAdminClose,
    onActiveChange,
    onAdminChange,
    updateToggleActive,
    updateToggleAdmin,
    toggleActive,
    toggleAdmin,
    activeCancelRef,
    adminCancelRef,
  };
}

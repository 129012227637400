import { Box, Button, Flex, FormControl, FormLabel, HStack, Stack } from "@chakra-ui/react";
import { faBan, faSort } from "@fortawesome/free-solid-svg-icons";

import { CustomMultiSelect, CustomSingleSelect } from "../../common/custom-selector";
import { PartnerInvoiceStatusIndicator } from "../../common/partner-invoice-status-indicator";
import { PartnerMultiSelect } from "../../common/partner-selector-v2";
import { AdminBadge } from "../../components/admin-badge";
import AppIcon from "../../components/app-icon";
import AppLink from "../../components/app-link";
import { FormattedDate } from "../../components/formatted-date";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { SearchInput } from "../../components/search-input/search-input";
import {
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TablePagination,
  TableRow,
} from "../../components/table";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { PARTNER_INVOICE_STATUS } from "../../constants/partner-invoice-status";
import { PartnerInvoiceSort } from "../../generated/graphql";
import { currencyFormatter } from "../../util/formatter";
import { useAdminInvoices } from "./admin-invoices-hook";

export function AdminInvoices() {
  const {
    loading,
    handlePartnersChange,
    selectedPartnerIds,
    partnerInvoices,
    pagination,
    totalCount,
    filterCount,
    clearFilters,
    handleSortChange,
    handleStatusIdsChange,
    statusIds,
    sort,
    handleSearchChange,
  } = useAdminInvoices();

  return (
    <LayoutBase pageTitle="Invoices">
      <MainNav />
      <Container centerHorizontally>
        <Stack spacing={2} marginY={4}>
          <PageHeading preventMarginY>Invoices</PageHeading>
          <Box>
            <AdminBadge />
          </Box>
        </Stack>
        <PanelGroup>
          <PanelTop>
            <Flex justifyContent="space-between">
              <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end">
                <FormControl width="inherit">
                  <FormLabel fontSize="sm">Search</FormLabel>
                  <SearchInput onChange={handleSearchChange} />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="sm">Partners</FormLabel>
                  <PartnerMultiSelect onChange={handlePartnersChange} selectedPartnerIds={selectedPartnerIds} />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="sm">Status</FormLabel>
                  <CustomMultiSelect
                    items={Object.values(PARTNER_INVOICE_STATUS).map((status) => ({
                      displayText: status.name,
                      value: status.id,
                    }))}
                    onChange={handleStatusIdsChange}
                    value={statusIds}
                    emptyValueTitle="All"
                    pluralizedItemName="Statuses"
                  />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="sm">Sort</FormLabel>
                  <CustomSingleSelect
                    items={[
                      { displayText: "Newest First", value: PartnerInvoiceSort.IdDesc },
                      { displayText: "Oldest First", value: PartnerInvoiceSort.IdAsc },
                    ]}
                    onChange={handleSortChange}
                    value={sort}
                    allowEmpty={false}
                    selectorIcon={faSort}
                  />
                </FormControl>
                {filterCount > 0 && (
                  <Box>
                    <Button onClick={clearFilters}>
                      <AppIcon icon={faBan} standardRightMargin />
                      Clear Filters
                    </Button>
                  </Box>
                )}
              </HStack>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <Table isLoading={loading} isEmpty={partnerInvoices.length === 0}>
              <TableHead>
                <TableRow key="head">
                  <TableHeaderCell>Invoice ID</TableHeaderCell>
                  <TableHeaderCell>Invoice Date</TableHeaderCell>
                  <TableHeaderCell>Partner</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell>Amount</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partnerInvoices.map((partnerInvoice) => (
                  <TableRow key={partnerInvoice.id}>
                    <TableCell>
                      <AppLink to={`/billing/${partnerInvoice.partner.id}/invoices/${partnerInvoice.id}`}>
                        {partnerInvoice.id}
                      </AppLink>
                    </TableCell>
                    <TableCell>
                      <FormattedDate format={DATE_MONTH_FORMAT} date={partnerInvoice.invoiceDate} />
                    </TableCell>
                    <TableCell>{partnerInvoice.partner.name}</TableCell>
                    <TableCell>
                      <PartnerInvoiceStatusIndicator status={partnerInvoice.status} />
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(partnerInvoice.totalChargesAmount)} (
                      {currencyFormatter.format(partnerInvoice.totalDueAmount)} due)
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFoot>
                <TablePagination {...pagination} totalCount={totalCount} />
              </TableFoot>
            </Table>
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}

import { DateTime } from "luxon";

interface Props {
  date?: Date | null;
  format: string;
  nullText?: string;
}

export function FormattedDate(props: Props) {
  const { format, date, nullText } = props;
  if (!date) {
    return <span>{nullText ?? ""}</span>;
  }
  return <span>{DateTime.fromJSDate(date).toFormat(format)}</span>;
}

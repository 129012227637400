import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  FormErrorMessage,
  FormHelperText,
  Textarea,
  Select,
} from "@chakra-ui/react";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { CategorySelector } from "../category-selector";
import { PartnerUserSelector } from "../partner-user-selector";
import { useAddClientDrawer } from "./add-client-drawer-hook";
import { PartnerSelector } from "../partner-selector";
import { useAccessiblePartnerCount } from "../use-accessible-partner-count";

const PORTAL_OPTIONS = [
  {
    displayText: "Customer Reporting App",
    value: "customer",
  },
  {
    displayText: "Search Launch Pad",
    value: "slp",
  },
];

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAdded: (customerId: number, isAddingAnother: boolean) => void;
}

export function AddClientDrawer(props: Props) {
  const { isOpen } = props;
  const { formik, handleCancel, handleSave, nameFieldRef } = useAddClientDrawer(props);
  const accessiblePartnerCount = useAccessiblePartnerCount();

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="md" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add Client</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={!!formik.errors.partnerId && !!formik.touched.partnerId}>
              <FormLabel>Partner</FormLabel>
              <PartnerSelector
                variant="outline"
                isDisabled={accessiblePartnerCount === 1}
                selectedPartnerId={formik.values.partnerId}
                onPartnerSelected={(partnerId) => {
                  formik.setFieldValue("partnerId", partnerId);
                }}
              />
              <FormErrorMessage>{formik.errors.partnerId}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.firstName && !!formik.touched.firstName}>
              <FormLabel>Primary Contact First Name</FormLabel>
              <Input
                autoFocus
                variant="outline"
                value={formik.values.firstName}
                id="firstName"
                onChange={formik.handleChange}
                ref={nameFieldRef}
              />
              <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.lastName && !!formik.touched.lastName}>
              <FormLabel>Primary Contact Last Name</FormLabel>
              <Input variant="outline" value={formik.values.lastName} id="lastName" onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.email && !!formik.touched.email}>
              <FormLabel>Primary Contact Email</FormLabel>
              <Input variant="outline" value={formik.values.email} id="email" onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.name && !!formik.touched.name}>
              <FormLabel>Company Name</FormLabel>
              <Input variant="outline" value={formik.values.name} id="name" onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.website && !!formik.touched.website}>
              <FormLabel>Company URL</FormLabel>
              <Input variant="outline" value={formik.values.website} id="website" onChange={formik.handleChange} />
              <FormHelperText>Must begin with http:// or https://</FormHelperText>
              <FormErrorMessage>{formik.errors.website}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formik.errors.gbpUrl && !!formik.touched.gbpUrl}>
              <FormLabel>GBP URL</FormLabel>
              <Input variant="outline" value={formik.values.gbpUrl ?? ""} id="gbpUrl" onChange={formik.handleChange} />
              <FormHelperText>Must begin with http:// or https://</FormHelperText>
              <FormErrorMessage>{formik.errors.gbpUrl}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.categoryId && !!formik.touched.categoryId}>
              <FormLabel>Business Category</FormLabel>
              <CategorySelector
                placeholder="Select a Category"
                variant="outline"
                selectedCategoryId={formik.values.categoryId}
                onCategorySelected={(categoryId) => {
                  formik.setFieldValue("categoryId", categoryId);
                }}
              />
              <FormErrorMessage>{formik.errors.categoryId}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formik.errors.accountManagerUserId && !!formik.touched.accountManagerUserId}>
              <FormLabel>Brand Manager</FormLabel>
              <PartnerUserSelector
                variant="outline"
                partnerId={formik.values.partnerId}
                onSelectedUserChanged={(userId) => {
                  formik.setFieldValue("accountManagerUserId", userId);
                }}
                userId={formik.values.accountManagerUserId}
                nullUserText="Unassigned"
              />
              <FormErrorMessage>{formik.errors.accountManagerUserId}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formik.errors.instructions}>
              <FormLabel>Instructions</FormLabel>
              <Textarea
                id="instructions"
                value={formik.values.instructions ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                resize="none"
                placeholder="Instructions"
                variant="outline"
              />
              <FormHelperText>
                Additional and important information provided to users fulfilling any type of work for this client.
              </FormHelperText>
              <FormErrorMessage>{formik.errors.instructions}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formik.errors.portal}>
              <FormLabel>Portal</FormLabel>
              <Select id="portal" value={formik.values.portal} onChange={formik.handleChange}>
                {PORTAL_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.displayText}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{formik.errors.portal}</FormErrorMessage>
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <SimpleGrid columns={2} gridGap={4} width="100%">
            <PrimaryButton
              width="100%"
              onClick={() => {
                handleSave();
              }}
              isDisabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
            >
              Save
            </PrimaryButton>
            <SecondaryButton
              width="100%"
              onClick={() => {
                handleSave(true);
              }}
            >
              Save &amp; Add Another
            </SecondaryButton>
          </SimpleGrid>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

import { Box, Collapse, FormControl, FormLabel, Input, Select, Stack, Textarea } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { CancelButton, PrimaryButton } from "../../components/buttons";
import { ContentStrategyV2_OrderContentStrategyFragment } from "../../generated/graphql";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { useContentStrategyForm } from "./content-strategy-form-hook";
import { CustomerKeywordMultiSelector, CustomerKeywordSelector } from "../customer-keyword-selector";
import { BLOG_POST_PRODUCTS, GUEST_ARTICLE_PRODUCTS, WEB_COPY_PRODUCTS } from "../../constants/products";
import { PURPOSE_OF_CONTENT_OPTIONS } from "../../constants/purpose-of-content-options";
import { VOICE_OPTIONS } from "../../constants/voice-options";
import { AlertInfo } from "../../components/alerts";
import { PAGE_TYPES } from "../../constants/page-types";

interface Props {
  isEditing: boolean;
  onStopEditing: () => void;
  orderContentStrategy: ContentStrategyV2_OrderContentStrategyFragment;
}

export function ContentStrategyForm(props: Props) {
  const { isEditing, orderContentStrategy } = props;

  const { formik, onCancel } = useContentStrategyForm(props);

  if (!orderContentStrategy.order) {
    console.error(`Unable to find order for <orderContentStrategy|${orderContentStrategy.id}>`);
    return null;
  }

  const isGuestArticle = GUEST_ARTICLE_PRODUCTS.includes(orderContentStrategy.order.partnerProduct.product.id);
  const isBlogPost = BLOG_POST_PRODUCTS.includes(orderContentStrategy.order.partnerProduct.product.id);
  const isWebCopy = WEB_COPY_PRODUCTS.includes(orderContentStrategy.order.partnerProduct.product.id);
  const product = orderContentStrategy.order.partnerProduct.product;
  const contentPreference = orderContentStrategy.order.customer.customerQuestionnaire?.contentPreference ?? "";

  return (
    <Stack spacing={4} flex={1} marginRight={4}>
      <FormControl>
        <FormLabel>Product</FormLabel>
        <Input variant="unstyled" value={orderContentStrategy.order.partnerProduct.product.name} isDisabled />
      </FormControl>
      <FormControl>
        <FormLabel>Start Date</FormLabel>
        <Input
          variant="unstyled"
          value={DateTime.fromJSDate(orderContentStrategy.order.startDate).toFormat(DATE_MONTH_FORMAT)}
          isDisabled
        />
      </FormControl>
      {isEditing && orderContentStrategy.order.instructions && (
        <AlertInfo>{orderContentStrategy.order.instructions}</AlertInfo>
      )}
      <FormControl isRequired={isEditing}>
        <FormLabel>{isWebCopy ? "Page Title" : "Topic"}</FormLabel>
        <Input
          variant={isEditing ? "outline" : "unstyled"}
          id="topic"
          value={isEditing ? formik.values.topic ?? "" : formik.values.topic ?? "-"}
          isDisabled={!isEditing}
          onChange={formik.handleChange}
        />
      </FormControl>
      <Collapse in={isEditing}>
        <Stack spacing={4}>
          {product.minWordCount && product.maxWordCount && (
            <FormControl>
              <FormLabel>Word Count</FormLabel>
              <Input variant="unstyled" value={`${product.minWordCount}-${product.maxWordCount} words`} isDisabled />
            </FormControl>
          )}
          {(isBlogPost || isGuestArticle) && (
            <FormControl isRequired>
              <FormLabel>Primary Keyword</FormLabel>
              <CustomerKeywordSelector
                keywordTypes="primary"
                customerId={orderContentStrategy.order.customerId}
                selectedCustomerKeywordId={formik.values.customerKeywordId}
                onSelectedCustomerKeywordChange={(customerKeywordId) => {
                  formik.setFieldValue("customerKeywordId", customerKeywordId);
                }}
              />
            </FormControl>
          )}
          {(isBlogPost || isGuestArticle) && (
            <FormControl>
              <FormLabel>Halo Keywords</FormLabel>
              <Box>
                <CustomerKeywordMultiSelector
                  keywordType="halo"
                  customerId={orderContentStrategy.order.customerId}
                  customerKeywordIds={formik.values.haloKeywordIds}
                  onCustomerKewyordsChanged={(keywordIds) => {
                    formik.setFieldValue("haloKeywordIds", keywordIds);
                  }}
                />
              </Box>
            </FormControl>
          )}
          {(isBlogPost || isGuestArticle) && (
            <FormControl>
              <FormLabel>Purpose of Content</FormLabel>
              <Select id="purposeOfContent" value={formik.values.purposeOfContent ?? ""} onChange={formik.handleChange}>
                <option value="" disabled>
                  Not Selected
                </option>
                {PURPOSE_OF_CONTENT_OPTIONS.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl>
            <FormLabel>Call To Action Link</FormLabel>
            <Input id="linkTo" onChange={formik.handleChange} value={formik.values.linkTo ?? ""} />
          </FormControl>
          {(isBlogPost || isGuestArticle) && (
            <FormControl>
              <FormLabel>Voice</FormLabel>
              <Select id="voice" value={formik.values.voice ?? ""} onChange={formik.handleChange}>
                <option value="">Not Selected</option>
                {VOICE_OPTIONS.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl>
            <FormLabel>Sample Content</FormLabel>
            <Input id="sampleContent" onChange={formik.handleChange} value={formik.values.sampleContent ?? ""} />
          </FormControl>
          <FormControl>
            <FormLabel>Focus Points</FormLabel>
            <Textarea id="focusPoints" onChange={formik.handleChange} value={formik.values.focusPoints ?? ""} />
          </FormControl>
          {isWebCopy && ["Based on Current Content", "The choice is yours"].includes(contentPreference) && (
            <FormControl>
              <FormLabel>Web Page</FormLabel>
              <Input id="webPage" onChange={formik.handleChange} value={formik.values.webPage ?? ""} />
            </FormControl>
          )}
          {isWebCopy && (
            <FormControl>
              <FormLabel>Page Type</FormLabel>
              <Select id="pageType" value={formik.values.pageType ?? ""} onChange={formik.handleChange}>
                <option value="">Not Selected</option>
                {PAGE_TYPES.map((option) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          <Stack isInline spacing={4}>
            <PrimaryButton
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Save
            </PrimaryButton>
            <CancelButton onClick={onCancel}>Cancel</CancelButton>
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
}

import {
  Flex,
  SimpleGrid,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Box,
  Text,
  FormErrorMessage,
  Switch,
  FormHelperText,
  InputGroup,
  InputRightElement,
  Select,
} from "@chakra-ui/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { PageHeading } from "../../components/page-heading";
import { Loader } from "../../components/loader";
import { useAdminPartnerOverview } from "./use-admin-partner-overview";
import { PrimaryButton, CancelButton, SecondaryButton, PrimaryLinkButton } from "../../components/buttons";
import AppIcon from "../../components/app-icon";
import { AppColors } from "../../core/custom-theme";
import { UploadFileDialog } from "../../common/upload-file-modal";

export function AdminPartnerOverview() {
  const {
    loading,
    partner,
    formik,
    showEmailLogoUpload,
    showReportingLogoUpload,
    onEmailLogoUpload,
    onEmailLogoUploadCancel,
    onReportingLogoUpload,
    onReportingLogoUploadCancel,
    onEmailLogoUploaded,
    onReportingLogoUploaded,
    showPassword,
    onPasswordClick,
    user,
    password,
    onChange,
    isDirty,
    isSubmitting,
    onAnalyticCancel,
    onAnalyticSave,
  } = useAdminPartnerOverview();

  return (
    <>
      {/* TODO: Fix Prompt component
      <Prompt when={formik.dirty} message={"Are you sure you want to discard your changes?"} />
      */}
      <UploadFileDialog
        isOpen={showReportingLogoUpload}
        onFileUploaded={onReportingLogoUploaded}
        onCancel={onReportingLogoUploadCancel}
        instructionText="Logo must be a png. Recommended size: 300px x 100px or smaller."
      />
      <UploadFileDialog
        isOpen={showEmailLogoUpload}
        onFileUploaded={onEmailLogoUploaded}
        onCancel={onEmailLogoUploadCancel}
        instructionText="Logo must be a png. Recommended size: 300px x 100px or smaller."
      />
      <PageHeading>{partner?.name ?? "Partner Details"}</PageHeading>
      <SimpleGrid columns={{ base: 1, xl: 2 }} gridGap={4}>
        {loading && (
          <>
            <PanelGroup>
              <PanelTop showBottomBorder>
                <Heading size="md">Basic Information</Heading>
              </PanelTop>
              <PanelBottom>
                <Loader type="content" />
              </PanelBottom>
            </PanelGroup>
          </>
        )}
        {!loading && partner && (
          <>
            <Box>
              <PanelGroup>
                <PanelTop showBottomBorder>
                  <Flex>
                    <Heading size="md">Basic Information</Heading>
                  </Flex>
                </PanelTop>
                <PanelBottom>
                  <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={4}>
                      <FormControl isRequired isInvalid={!!formik.errors.name && !!formik.touched.name}>
                        <FormLabel>Partner Name</FormLabel>
                        <Input id="name" value={formik.values.name} onChange={formik.handleChange} />
                        <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                      </FormControl>
                      <FormControl isRequired isInvalid={!!formik.errors.billingEmail && !!formik.touched.billingEmail}>
                        <FormLabel>Billing Email</FormLabel>
                        <Input value={formik.values.billingEmail} id="billingEmail" onChange={formik.handleChange} />
                        <FormErrorMessage>{formik.errors.billingEmail}</FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!formik.errors.isBillingEnforced && !!formik.touched.isBillingEnforced}>
                        <FormLabel>Is Billing Enforced</FormLabel>
                        <Switch
                          isChecked={formik.values.isBillingEnforced}
                          id="isBillingEnforced"
                          onChange={formik.handleChange}
                        />
                        <FormErrorMessage>{formik.errors.isBillingEnforced}</FormErrorMessage>
                        {formik.values.isBillingEnforced && (
                          <FormHelperText>
                            Show billing information for this partner and enable billing logic when activating orders.
                          </FormHelperText>
                        )}
                        {!formik.values.isBillingEnforced && (
                          <FormHelperText>
                            Prevent showing billing information for this partner and ignore billing when activating
                            orders.
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl
                        isInvalid={
                          !!formik.errors.isUpfrontPaymentRequired && !!formik.touched.isUpfrontPaymentRequired
                        }
                      >
                        <FormLabel>Is Upfront Payment Required</FormLabel>
                        <Switch
                          isChecked={formik.values.isUpfrontPaymentRequired}
                          id="isUpfrontPaymentRequired"
                          onChange={formik.handleChange}
                        />
                        <FormErrorMessage>{formik.errors.isUpfrontPaymentRequired}</FormErrorMessage>
                        {formik.values.isUpfrontPaymentRequired && (
                          <FormHelperText>Orders must be paid for before fulfillment work begins.</FormHelperText>
                        )}
                        {!formik.values.isUpfrontPaymentRequired && (
                          <FormHelperText>
                            Fulfillment will begin on start date of order and will be billed by invoice at the end of
                            the month.
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl isInvalid={!!formik.errors.stripeAccountId && !!formik.touched.stripeAccountId}>
                        <FormLabel>Stripe Account ID</FormLabel>
                        <Input
                          id="stripeAccountId"
                          value={formik.values.stripeAccountId}
                          onChange={formik.handleChange}
                        />
                        <FormErrorMessage>{formik.errors.stripeAccountId}</FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!formik.errors.portal && !!formik.touched.portal}>
                        <FormLabel>Partner Type</FormLabel>
                        <Select
                          variant="outline"
                          name="portal"
                          value={formik.values.portal}
                          onChange={formik.handleChange}
                        >
                          <option value="partner">RCM Partner</option>
                          <option value="lmr">LMR Partner</option>
                        </Select>
                      </FormControl>
                      {formik.dirty && (
                        <Stack isInline spacing={2}>
                          <PrimaryButton type="submit" isLoading={formik.isSubmitting}>
                            Save
                          </PrimaryButton>
                          <CancelButton
                            onClick={() => {
                              formik.resetForm();
                            }}
                          >
                            Cancel
                          </CancelButton>
                        </Stack>
                      )}
                    </Stack>
                  </form>
                </PanelBottom>
              </PanelGroup>
              <PanelGroup>
                <PanelTop showBottomBorder>
                  <Flex>
                    <Heading size="md">Analytic Information</Heading>
                  </Flex>
                </PanelTop>
                <PanelBottom>
                  <form>
                    <Stack spacing={4}>
                      <FormControl>
                        <FormLabel>Analytic User Name</FormLabel>
                        <Input
                          id="analyticUser"
                          placeholder="Enter User Name"
                          value={user}
                          onChange={onChange.bind(null, "user")}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Analytic Password</FormLabel>
                        <InputGroup size="md">
                          <Input
                            pr="4.5rem"
                            id="analyticPassword"
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter password"
                            value={password}
                            onChange={onChange.bind(null, "password")}
                          />
                          <InputRightElement width="4.5rem">
                            <PrimaryLinkButton h="1.75rem" size="sm" onClick={onPasswordClick}>
                              {showPassword ? "Hide" : "Show"}
                            </PrimaryLinkButton>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                      {isDirty && (
                        <Stack isInline spacing={2}>
                          <PrimaryButton onClick={onAnalyticSave} isLoading={isSubmitting}>
                            Save
                          </PrimaryButton>
                          <CancelButton onClick={onAnalyticCancel}>Cancel</CancelButton>
                        </Stack>
                      )}
                    </Stack>
                  </form>
                </PanelBottom>
              </PanelGroup>
            </Box>
            <Box>
              <PanelGroup>
                <PanelTop>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Box>
                      <Heading size="md">Reporting Logo</Heading>
                      <Text fontSize="sm" color={AppColors.textGray}>
                        Recommended Size: 300px x 100px
                      </Text>
                    </Box>
                    <SecondaryButton onClick={onReportingLogoUpload}>Upload</SecondaryButton>
                  </Flex>
                </PanelTop>
                <PanelBottom>
                  {partner.reportingLogoFile && (
                    <Box backgroundColor="#333333" height="64px" display="flex" alignItems="center" paddingLeft={2}>
                      <img
                        src={partner.reportingLogoFile.url}
                        alt={partner.reportingLogoFile.name}
                        style={{ maxHeight: 50 }}
                      />
                    </Box>
                  )}
                  {!partner.reportingLogoFile && (
                    <Text>
                      <AppIcon icon={faInfoCircle} standardRightMargin />
                      Not provided.
                    </Text>
                  )}
                </PanelBottom>
              </PanelGroup>
              <PanelGroup>
                <PanelTop>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Box>
                      <Heading size="md">Email Logo</Heading>
                      <Text fontSize="sm" color={AppColors.textGray}>
                        Recommended Size: 300px x 100px
                      </Text>
                    </Box>
                    <SecondaryButton onClick={onEmailLogoUpload}>Upload</SecondaryButton>
                  </Flex>
                </PanelTop>
                <PanelBottom>
                  {partner.emailLogoFile && (
                    <Box height="64px" display="flex" alignItems="center" paddingLeft={2}>
                      <img src={partner.emailLogoFile.url} alt={partner.emailLogoFile.name} style={{ maxHeight: 50 }} />
                    </Box>
                  )}
                  {!partner.emailLogoFile && (
                    <Text>
                      <AppIcon icon={faInfoCircle} standardRightMargin />
                      Not provided.
                    </Text>
                  )}
                </PanelBottom>
              </PanelGroup>
            </Box>
          </>
        )}
      </SimpleGrid>
    </>
  );
}

import React from "react";
import { Property } from "csstype";

interface Props {
  children?: React.ReactNode;
  align?: Property.TextAlign;
}

export function TableHeaderCell(props: Props) {
  const { children, align } = props;

  return (
    <th
      style={{
        display: "table-cell",
        padding: "16px",
        fontSize: "12px",
        fontWeight: 600,
        textAlign: align ?? "left",
        borderBottom: `1px solid #E3E8EF`,
        borderRadius: "4px 4px 0 0",
      }}
    >
      {children}
    </th>
  );
}

import { PanelGroup, PanelTop, PanelBottom, PanelMiddle } from "../../components/panels";
import { Heading, Stack, Flex, Text, Button } from "@chakra-ui/react";
import { Loader } from "../../components/loader";
import { SecondaryButton } from "../../components/buttons";
import AppIcon from "../../components/app-icon";
import { faInfoCircle, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useCustomerNotes } from "./use-customer-notes";
import { NewCustomerNote } from "./new-customer-note";
import { CustomerNote } from "./customer-note";

interface Props {
  customerId: number;
  preventNewNotes?: boolean;
}

export function CustomerNotes(props: Props) {
  const { customerId, preventNewNotes } = props;
  const {
    loading,
    notes,
    onAddNoteCancel,
    showEditor,
    onNoteAdded,
    onAddNote,
    totalCount,
    offset,
    limit,
    hasNextPage,
    hasPreviousPage,
    onNextPage,
    onPreviousPage,
  } = useCustomerNotes(props);
  return (
    <PanelGroup isCollapsible>
      <PanelTop showBottomBorder>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">Notes ({totalCount})</Heading>
          {!preventNewNotes && !showEditor && <SecondaryButton onClick={onAddNote}>Add Note</SecondaryButton>}
        </Flex>
      </PanelTop>
      <PanelMiddle>
        {loading && <Loader type="content" />}
        {!loading && notes.length === 0 && !showEditor && (
          <Text>
            <AppIcon icon={faInfoCircle} standardRightMargin />
            No notes.
          </Text>
        )}
        {!loading && (
          <Stack spacing={4} shouldWrapChildren>
            {!preventNewNotes && (
              <NewCustomerNote
                onCancel={onAddNoteCancel}
                showEditor={showEditor}
                customerId={customerId}
                onNoteCreated={onNoteAdded}
              />
            )}
            {notes.map((note) => (
              <CustomerNote
                key={note.id}
                authorFullName={note.user.fullName}
                content={note.content}
                createdAt={note.createdAt}
              />
            ))}
          </Stack>
        )}
      </PanelMiddle>
      <PanelBottom showTopBorder>
        <Stack spacing={2} isInline alignItems="center">
          <Button variant="ghost" aria-label="Previous page" isDisabled={!hasPreviousPage} onClick={onPreviousPage}>
            <AppIcon icon={faChevronLeft} />
          </Button>
          <Button variant="ghost" aria-label="Next page" isDisabled={!hasNextPage} onClick={onNextPage}>
            <AppIcon icon={faChevronRight} />
          </Button>
          {totalCount > 0 && (
            <Text>
              Showing {offset + 1} - {Math.min(offset + limit, totalCount)} of {totalCount} items
            </Text>
          )}
        </Stack>
      </PanelBottom>
    </PanelGroup>
  );
}

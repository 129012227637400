import AppLink from "../../components/app-link";
import { FormattedDate } from "../../components/formatted-date";
import {
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TablePagination,
  TableRow,
} from "../../components/table";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { AdminPartners_PartnerFragment } from "../../generated/graphql";

interface Props {
  offset: number;
  limit: number;
  loading: boolean;
  totalCount: number;
  partners: AdminPartners_PartnerFragment[];
  onPreviousPage: () => void;
  onNextPage: () => void;
}

export function PartnersTable(props: Props) {
  const { onNextPage, onPreviousPage, partners, totalCount, loading, offset, limit } = props;

  return (
    <Table isEmpty={partners.length === 0} isLoading={loading}>
      <TableHead>
        <TableRow>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Created</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {partners.map((partner) => (
          <TableRow key={partner.id}>
            <TableCell>
              <AppLink to={`/admin/partners/${partner.id}`}>{partner.name}</AppLink>
            </TableCell>
            <TableCell>
              <FormattedDate format={DATE_MONTH_FORMAT} date={partner.createdAt} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFoot>
        <TablePagination
          totalCount={totalCount}
          offset={offset}
          limit={limit}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
      </TableFoot>
    </Table>
  );
}

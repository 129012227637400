import { DateTime } from "luxon";
import { AppColors } from "../../core/custom-theme";
import { Box, Tooltip } from "@chakra-ui/react";
import { RichContent } from "../rich-content";
import { getRelativeTimeDisplayText } from "../../util/relative-time-helper";
import { DATE_TIME_FORMAT } from "../../constants/constants";

interface Props {
  messageDate: Date;
  content: string;
  isFromCustomer: boolean;
}

const CONVERSATION_GRAY_BACKGROUND = "#EBEBEB";

export function ConversationMessageCard({ messageDate, content, isFromCustomer }: Props) {
  const messageDateStr = DateTime.fromJSDate(messageDate).toFormat(DATE_TIME_FORMAT);
  return (
    <Box
      backgroundColor={isFromCustomer ? CONVERSATION_GRAY_BACKGROUND : AppColors.white}
      border={isFromCustomer ? `1px solid ${AppColors.paleBlue}` : ""}
      padding={4}
      borderRadius={4}
      display="flex"
      flexDirection="column"
      alignSelf="stretch"
      justifyContent="stretch"
      marginRight={isFromCustomer ? "5rem" : 0}
      marginLeft={isFromCustomer ? 0 : "5rem"}
      mb={4}
    >
      <Box padding={4} fontSize={16} color={AppColors.textNormal}>
        <RichContent content={content} />
      </Box>
      <Box width="100%" textAlign="right" fontSize={14} color={AppColors.textDarkGray}>
        <Tooltip aria-label={messageDateStr} label={messageDateStr}>
          <span>{getRelativeTimeDisplayText(messageDate)}</span>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default ConversationMessageCard;

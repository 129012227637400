import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Navigate, useNavigate } from "react-router-dom";
import { Loader } from "../../components/loader";
import { AuthContext } from "../../core/auth-manager";
import { useLoginUserMutation } from "../../generated/graphql";

export const LoginUserSsoRoute = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { code } = useParams<"code">();
  const authContext = useContext(AuthContext);
  const [loginUser] = useLoginUserMutation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkCode = async () => {
      const response = await loginUser({
        variables: {
          input: {
            magicLink: code,
          },
        },
      });
      if (!response?.data?.loginUser?.isLoggedIn) {
        setIsLoading(false);
        setIsLoggedIn(false);
        return;
      }
      setIsLoading(false);
      setIsLoggedIn(true);
      await authContext.refresh();
      navigate("/");
    };

    if (code) {
      checkCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  if (!code) {
    return <Navigate to="/login" />;
  }
  if (isLoading) {
    return (
      <div>
        <Loader type="fullView" />
      </div>
    );
  }
  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return <div>Looks like that&apos;s an invalid code.</div>;
};

import { useState } from "react";
import { DateTime } from "luxon";
import {
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Box,
  Textarea,
  Tooltip,
  Flex,
  Text,
} from "@chakra-ui/react";
import { PanelGroup, PanelTop, PanelBottom, PanelMiddle } from "../../components/panels";
import { DangerButton, PrimaryButton, SecondaryButton } from "../../components/buttons";
import { PageHeading } from "../../components/page-heading";
import { WorkflowNotes } from "../workflow-notes";
import { HelpText } from "../../components/help-text";
import { useTaskWorkspaceKeywordResearch } from "./use-task-workspace-keyword-research";
import { ProposedKeywordRow } from "./proposed-keyword-row";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { Loader } from "../../components/loader";
import AddMultipleKeywords from "./add-multiple-keywords-modal";

interface Props {
  taskId: number;
  onAbandon: () => void;
  onComplete: (isApproved?: boolean, reviewComment?: string) => void;
}

const NEW_KEYWORD = {
  location: "",
  name: "",
  isPrimary: false,
  id: 0,
  rejectionReason: "",
};

export function TaskWorkspaceKeywordResearch(props: Props) {
  const { taskId, onAbandon } = props;
  const {
    customerKeywordProposal,
    rejectedKeywords,
    keywords,
    refetchKeywords,
    summary,
    saveSummary,
    handleChangeSummary,
    isSavingSummary,
    task,
    handleComplete,
  } = useTaskWorkspaceKeywordResearch(props);
  const [showAddKeyword, setShowAddKeyword] = useState(false);
  const [showAddMultipleKeywords, setShowAddMultipleKeywords] = useState(false);

  const keywordsList = showAddKeyword ? [NEW_KEYWORD, ...keywords] : keywords;

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <PageHeading>Keyword Research Task</PageHeading>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <SecondaryButton
            mr={2}
            onClick={() => {
              setShowAddKeyword(false);
              setShowAddMultipleKeywords(true);
            }}
          >
            Add Multiple Keywords
          </SecondaryButton>
          <SecondaryButton onClick={() => setShowAddKeyword(true)}>Add Keyword</SecondaryButton>
        </Box>
      </Box>
      <Grid templateColumns={{ base: "1fr", xl: "1fr 2fr" }} gridGap={4}>
        <Stack spacing={4}>
          <PanelGroup isCollapsible>
            <PanelTop showBottomBorder>
              <Heading size="md">Client Details</Heading>
            </PanelTop>
            <PanelBottom>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Start Date</FormLabel>
                  <Input
                    value={DateTime.fromJSDate(task?.order?.startDate ?? new Date()).toFormat(DATE_MONTH_FORMAT)}
                    variant="outline"
                    isReadOnly
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Client Name</FormLabel>
                  <Tooltip aria-label={task?.customer.name ?? ""} label={task?.customer.name ?? ""}>
                    <Input value={task?.customer.name ?? ""} variant="outline" isReadOnly />
                  </Tooltip>
                </FormControl>
                <FormControl>
                  <FormLabel>Business Category</FormLabel>
                  <Input value={task?.customer.category?.name ?? "Not Provided"} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>About Company</FormLabel>
                  <Textarea
                    value={task?.customer.customerQuestionnaire?.businessDescription ?? "Not Provided"}
                    variant="outline"
                    isReadOnly
                    resize="none"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Target Audience</FormLabel>
                  <Input
                    value={task?.customer.customerQuestionnaire?.targetAudience ?? "Not Provided"}
                    variant="outline"
                    isReadOnly
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Website URL</FormLabel>
                  <Textarea
                    value={task?.customer.website ?? "Not provided"}
                    variant="outline"
                    isReadOnly
                    resize="none"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Products & Services</FormLabel>
                  <Textarea
                    value={task?.customer.customerQuestionnaire?.productsOffered ?? "Not Provided"}
                    variant="outline"
                    isReadOnly
                    resize="none"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Business Type</FormLabel>
                  <Input
                    value={task?.customer.customerQuestionnaire?.customerMarketSize ?? "Not Provided"}
                    variant="outline"
                    isReadOnly
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Service Area(s)</FormLabel>
                  <Textarea
                    value={task?.customer.customerQuestionnaire?.serviceArea ?? "Not Provided"}
                    variant="outline"
                    isReadOnly
                    resize="none"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Current Search Terms</FormLabel>
                  <Textarea
                    value={task?.customer.customerQuestionnaire?.targetKeywords ?? "Not Provided"}
                    variant="outline"
                    isReadOnly
                    resize="none"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Competitors / Not Permitted</FormLabel>
                  <Textarea
                    value={task?.customer.customerQuestionnaire?.competitorWebsites ?? "Not Provided"}
                    variant="outline"
                    isReadOnly
                    resize="none"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Client Instructions</FormLabel>
                  <Textarea
                    value={task?.customer?.instructions ?? "Not Provided"}
                    variant="outline"
                    isReadOnly
                    resize="none"
                  />
                </FormControl>
              </Stack>
            </PanelBottom>
          </PanelGroup>
        </Stack>
        <Stack spacing={4}>
          {keywordsList.map((keyword) => (
            <ProposedKeywordRow
              key={keyword.id}
              customerKeywordProposalId={customerKeywordProposal?.id ?? 0}
              keyword={keyword}
              refetchKeywords={refetchKeywords}
              deleteNewKeyword={() => setShowAddKeyword(false)}
              onAddSuccess={() => setShowAddKeyword(false)}
              isRejected={false}
            />
          ))}
          {rejectedKeywords.map((keyword) => (
            <ProposedKeywordRow
              key={keyword.id}
              customerKeywordProposalId={customerKeywordProposal?.id ?? 0}
              keyword={keyword}
              refetchKeywords={refetchKeywords}
              deleteNewKeyword={() => setShowAddKeyword(false)}
              onAddSuccess={() => setShowAddKeyword(false)}
              isRejected
            />
          ))}
          <PanelGroup>
            <PanelTop showBottomBorder>
              <Heading size="sm">
                <Flex>
                  <Text marginRight="10px">Summary</Text>
                  {isSavingSummary && <Loader />}
                </Flex>
              </Heading>
            </PanelTop>
            <PanelMiddle>
              <FormControl>
                <Textarea
                  id="summary"
                  value={summary}
                  onChange={handleChangeSummary}
                  onBlur={saveSummary}
                  resize="none"
                  placeholder="Summarize your strategy and choices for the client"
                />
              </FormControl>
            </PanelMiddle>
            <PanelBottom>
              <Stack isInline spacing={2} justifyContent="space-between" shouldWrapChildren>
                <PrimaryButton onClick={handleComplete}>Complete Task</PrimaryButton>
                <Box>
                  <DangerButton onClick={onAbandon}>Unassign Task</DangerButton>
                  <HelpText standardLeftMargin>
                    &quot;Unassign Task&quot; will unassign this task from you and return it to the queue.{" "}
                    <strong>This cannot be undone and may result in lost work.</strong>
                  </HelpText>
                </Box>
              </Stack>
            </PanelBottom>
          </PanelGroup>
          <WorkflowNotes taskId={taskId} />
        </Stack>
      </Grid>
      <AddMultipleKeywords
        customerKeywordProposalId={customerKeywordProposal?.id ?? 0}
        isOpen={showAddMultipleKeywords}
        onClose={() => setShowAddMultipleKeywords(false)}
        refetchKeywords={refetchKeywords}
      />
    </>
  );
}

import {
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { VendorSelector } from "../../common/vendor-selector";
import { AlertWarning } from "../../components/alerts";
import { CancelButton, PrimaryButton } from "../../components/buttons";
import { AdminTasks_TaskFragment } from "../../generated/graphql";

interface Props {
  isOpen: boolean;
  task: AdminTasks_TaskFragment | null;
  isProcessing: boolean;
  onCancel: () => void;
  onConfirm: (vendorId: number) => void;
}

export function ChangeVendorModal(props: Props) {
  const { task, isOpen, isProcessing, onCancel, onConfirm } = props;

  const [vendorId, setVendorId] = useState(task?.vendor?.id ?? 0);

  useEffect(() => {
    if (!!task && !!task.vendor) {
      setVendorId(task.vendor.id);
    }
  }, [task]);

  function handleVendorChange(newVendorId: number | null) {
    if (newVendorId) {
      setVendorId(newVendorId);
    }
  }

  function handleConfirm() {
    if (task && vendorId > 0) {
      onConfirm(vendorId);
    }
  }

  if (task === null) {
    return null;
  }

  return (
    <Modal onClose={onCancel} isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Vendor For Task</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <AlertWarning>
              Please be careful when changing vendors for a task, as this may place the task in a state where it is
              unable to be worked on.
            </AlertWarning>
            <FormControl>
              <FormLabel>New Vendor</FormLabel>
              <VendorSelector onVendorSelected={handleVendorChange} selectedVendorId={vendorId} isClearable={false} />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack spacing={2} isInline>
            <PrimaryButton onClick={handleConfirm} isLoading={isProcessing}>
              Change Vendor
            </PrimaryButton>
            <CancelButton onClick={onCancel}>Cancel</CancelButton>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import { Heading, HeadingProps } from "@chakra-ui/react";

interface Props extends HeadingProps {
  preventMarginY?: boolean;
}

export function PageHeading(props: Props) {
  const { preventMarginY, ...rest } = props;
  return <Heading size="lg" marginY={!preventMarginY ? 4 : undefined} {...rest} />;
}

import { useTrackUserEventMutation } from "../../generated/graphql";
import { useAuthContext } from "../../core/auth-manager/auth-manager-hook";

interface Options {
  entityId?: number;
  entityName?: string;
  eventType: string;
}

export function useTrackUser() {
  const authContext = useAuthContext();
  const [trackUserEventServer] = useTrackUserEventMutation();

  async function trackUserEvent(options: Options) {
    const { entityId, entityName, eventType } = options;
    if (authContext.viewer?.user?.isTracking) {
      await trackUserEventServer({
        variables: {
          input: {
            userId: authContext.viewer?.user?.id ?? 0,
            entityId,
            entityName: entityName ?? "",
            eventType,
          },
        },
      });
    }
  }

  return {
    trackUserEvent,
  };
}

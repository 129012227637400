import { useParams } from "react-router-dom";
import { groupBy } from "lodash";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";

import {
  usePublisherPaymentBatchReviewQuery,
  usePaymentBatchReviewApprovePayPalMutation,
} from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { PUBLISHER_PAYMENT_STATUS } from "../../constants/publisher-payment-status";

export function useAdminPublisherPublisherPaymentBatchReview() {
  const { id } = useParams<"id">();
  const paymentBatchId = parseInt(id ?? "");
  const queryResult = usePublisherPaymentBatchReviewQuery({
    fetchPolicy: "network-only",
    variables: {
      paymentBatchId,
    },
  });
  const [approvePaymentBatchFunding] = usePaymentBatchReviewApprovePayPalMutation();
  const [isAccepted, setIsAccepted] = useState(false);
  const toast = useToast();

  function onAcceptedChange(e: React.ChangeEvent<HTMLInputElement>) {
    setIsAccepted(e.target.checked);
  }

  const paymentBatch = queryResult.data?.paymentBatch ?? null;
  const payments = queryResult.data?.paymentBatch?.publisherPayments ?? [];
  const processor = payments?.[0]?.processor;
  const paymentStatus = payments?.[0]?.status?.id ?? "";

  const paymentsByPublisherId = groupBy(payments, "publisher.id");

  const paymentDetails = Object.keys(paymentsByPublisherId).map((key) => {
    const payments = paymentsByPublisherId[key];
    const publisher = payments[0].publisher;

    return {
      publisher,
      paymentAmount: payments.map((x) => x.amount).reduce((sum: number, amount: number) => sum + amount, 0),
      orderCount: payments.length,
      orderIds: payments.map((x) => x.orderId).join(","),
    };
  });

  async function onApprove() {
    try {
      const response = await approvePaymentBatchFunding({
        variables: {
          input: {
            paymentBatchId,
          },
        },
      });
      if (response.data?.approvePaymentBatchFundingPaypal.ok) {
        toast({
          title: "Funding Approved",
          description: "Funding Approve was successful. A bank transfer will be initiated momentarily.",
          status: "success",
        });
        queryResult.refetch();
      } else {
        toast({
          title: "Unable to Approve",
          description:
            response.data?.approvePaymentBatchFundingPaypal.error?.message ??
            "An unexpected error occurred. Please try again.",
          status: "error",
        });
      }
    } catch (e: any) {
      toast({ title: "Unable to Approve", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  function handleDownloadCSV() {
    window.open(`${process.env.REACT_APP_API_BASE}/files/publisher-payments/${paymentBatchId}`, "_blank");
  }

  return {
    paymentBatchId,
    loading: queryResult.loading,
    paymentBatch,
    payments,
    paymentDetails,
    processor,
    onAcceptedChange,
    isAccepted,
    onApprove,
    handleDownloadCSV,
    isPaymentSubmitted: paymentStatus === PUBLISHER_PAYMENT_STATUS.SUBMITTED.id,
  };
}

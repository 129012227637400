import { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as yup from "yup";

import { useContentIdeationTaskWorkspaceQuery, useSaveTaskContentIdeationMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useDebounce } from "../../util/use-debounce";

interface Options {
  taskId: number;
  onComplete: () => void;
}

interface FormValues {
  idea: string;
}

const validationSchema = yup.object().shape({
  idea: yup.string().label("Idea").required().nullable(false).min(1),
});

export function useTaskWorkspaceContentIdeation(options: Options) {
  const { onComplete, taskId } = options;
  const taskWorkspaceContentIdeationQueryResult = useContentIdeationTaskWorkspaceQuery({
    fetchPolicy: "no-cache",
    variables: {
      taskId,
    },
  });
  const [saveTaskContentIdeation] = useSaveTaskContentIdeationMutation();
  const toast = useToast();
  const [lastAutosavedAt, setLastAutosavedAt] = useState<Date | null>(null);
  const [contentIdeationValues, setContentIdeationValues] = useState<{ idea: string }>({
    idea: "",
  });

  const task = taskWorkspaceContentIdeationQueryResult.data?.task ?? null;
  const contentIdeation = task?.contentIdeation ?? null;

  const formik = useFormik<FormValues>({
    initialValues: {
      idea: contentIdeation?.idea ?? "",
    },
    enableReinitialize: true,
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSubmit: async (values, _helpers) => {
      try {
        await saveTaskContentIdeation({
          variables: {
            input: {
              taskId,
              idea: values.idea,
            },
          },
        });
        onComplete();
      } catch (e: any) {
        toast({ title: "Unable to Complete Task", description: getDisplayMessageForError(e), status: "error" });
      }
    },
  });

  const debouncedContentIdeationValues = useDebounce(contentIdeationValues, 2000);

  useEffect(() => {
    if (contentIdeation && contentIdeation.idea !== contentIdeationValues.idea) {
      setContentIdeationValues({
        idea: contentIdeation.idea,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentIdeation]);

  useEffect(() => {
    if (task) {
      saveTaskContentIdeation({
        variables: {
          input: {
            idea: debouncedContentIdeationValues.idea,
            taskId: task.id,
          },
        },
        // eslint-disable-next-line promise/prefer-await-to-then
      }).then(() => {
        setLastAutosavedAt(new Date());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedContentIdeationValues]);

  function onContentIdeationUpdate(idea: string) {
    setContentIdeationValues({ idea });
  }

  return {
    taskId,
    loading: taskWorkspaceContentIdeationQueryResult.loading,
    contentIdeation,
    formik,
    lastAutosavedAt,
    task,
    onContentIdeationUpdate,
  };
}

import { sortBy } from "lodash";
import { useCategoriesForSelectorQuery } from "../../generated/graphql";
import { SelectProps, Select } from "@chakra-ui/react";

interface Props extends SelectProps {
  selectedCategoryId?: number | null;
  onCategorySelected: (categoryId: number) => void;
  showNoCategoryOption?: boolean;
}

export function CategorySelector(props: Props) {
  const { selectedCategoryId, onCategorySelected, showNoCategoryOption, ...rest } = props;
  const categoriesQueryResult = useCategoriesForSelectorQuery({
    fetchPolicy: "cache-and-network",
  });

  const categories = sortBy(categoriesQueryResult.data?.categories.nodes ?? [], "name");

  return (
    <Select
      isDisabled={categoriesQueryResult.loading}
      value={selectedCategoryId?.toString() ?? ""}
      onChange={(e) => {
        onCategorySelected(parseInt(e.target.value));
      }}
      {...rest}
    >
      {showNoCategoryOption && (
        <option value="" disabled>
          No Category
        </option>
      )}
      {categories.map((category) => (
        <option key={category.id} value={category.id.toString()}>
          {category.name}
        </option>
      ))}
    </Select>
  );
}

import qs from "qs";

export const QS_STRINGIFY_OPTIONS: qs.IStringifyOptions = {
  encodeValuesOnly: true,
  indices: false,
};

export const QS_PARSE_OPTIONS: qs.IParseOptions = {
  ignoreQueryPrefix: true,
};

import { DateTime } from "luxon";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import DatePicker from "react-datepicker";
import { PrimaryButton, CancelButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useAddClientCampaignDrawerQuery, useCreateClientCampaignMutation } from "../../generated/graphql";
import { PartnerPackageSelector } from "../partner-package-selector";
import { NumberSelect } from "../../components/number-select";
import { DATE_PICKER_FORMAT } from "../../constants/constants";
import { CampaignCategorySelector } from "../campaign-category-selector";

interface Props {
  clientId: number;
  minStartDate?: Date;
  isOpen: boolean;
  onClose: () => void;
  onAdded: (clientCampaignId: number) => void;
  campaignCategoryId: string;
}

interface FormValues {
  name: string;
  startsAt: Date;
  month?: number;
  amount?: number;
  partnerPackageId: number | null;
}

const createClientCampaignSchema = yup.object().shape({
  name: yup.string().label("Name").required(),
  startsAt: yup.date().label("Starts At").required(),
  month: yup.number().integer().label("Month"),
  amount: yup.number().label("Amount"),
  partnerPackageId: yup.number().label("Partner Package").nullable(true),
});

export function AddClientCampaignDrawer(props: Props) {
  const { onClose, onAdded, isOpen, clientId, campaignCategoryId } = props;
  const [createClientCampaign] = useCreateClientCampaignMutation();
  const toast = useToast();
  const queryResult = useAddClientCampaignDrawerQuery({
    variables: {
      customerId: clientId,
    },
  });

  const minStartDate = props.minStartDate
    ? DateTime.min(DateTime.fromJSDate(props.minStartDate), DateTime.now().plus({ months: 6 })).startOf("day")
    : DateTime.now().toUTC().minus({ months: 1 }).plus({ days: 1 }).startOf("day");

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const endDate = DateTime.fromJSDate(values.startsAt)
        .plus({ months: values.month ?? 0 })
        .startOf("day")
        .minus({ milliseconds: 1 })
        .toJSDate();
      const response = await createClientCampaign({
        variables: {
          input: {
            name: values.name,
            customerId: clientId,
            startsAt: values.startsAt,
            endsAt: !values.partnerPackageId ? endDate : undefined,
            amount: !values.partnerPackageId ? values.amount : undefined,
            partnerPackageId: values.partnerPackageId,
            campaignCategoryId,
          },
        },
      });

      if (response.data?.createCustomerCampaign.customerCampaign.id) {
        toast({ title: "Success", description: "Client campaign created successfully!", status: "success" });
        formikHelpers.resetForm();
        onAdded(response.data?.createCustomerCampaign.customerCampaign.id);
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      startsAt: DateTime.max(minStartDate, DateTime.utc().startOf("day")).toJSDate(),
      month: 6,
      amount: 0,
      partnerPackageId: null,
    },
    validationSchema: createClientCampaignSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  function onStartDateChange(date: Date) {
    formik.setFieldValue("startsAt", date ? date : null);
  }

  function onPartnerPackageSelected(partnerPackageId: number | null) {
    formik.setFieldValue("partnerPackageId", partnerPackageId);
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="md" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add Campaign</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={!!formik.errors.name && !!formik.touched.name}>
              <FormLabel>Campaign Name</FormLabel>
              <Input autoFocus variant="outline" id="name" onChange={formik.handleChange} />
              {!!formik.errors.name && !!formik.touched.name && (
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Campaign Category</FormLabel>
              <CampaignCategorySelector variant="outline" selectedCampaignCategoryId={campaignCategoryId} isDisabled />
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.startsAt && !!formik.touched.startsAt} zIndex={10}>
              <FormLabel>Campaign Start</FormLabel>
              <Box>
                <DatePicker
                  onChange={onStartDateChange}
                  selected={formik.values.startsAt}
                  minDate={minStartDate.toJSDate()}
                  maxDate={DateTime.utc().plus({ months: 6 }).toJSDate()}
                  dateFormat={DATE_PICKER_FORMAT}
                  customInput={<Input variant="outline" width="100%" />}
                  disabledKeyboardNavigation
                  placeholderText="Select start date"
                />
              </Box>
              {!!formik.errors.startsAt && formik.touched.startsAt && (
                <FormErrorMessage>{formik.errors.startsAt as string}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={!!formik.errors.partnerPackageId && !!formik.touched.partnerPackageId}>
              <FormLabel>Package</FormLabel>
              <PartnerPackageSelector
                partnerId={queryResult.data?.customer?.partnerId ?? 0}
                variant="outline"
                onPartnerPackageSelected={onPartnerPackageSelected}
                selectedPartnerPackageId={formik.values.partnerPackageId}
              />
              {!!formik.errors.partnerPackageId && !!formik.touched.partnerPackageId && (
                <FormErrorMessage>{formik.errors.partnerPackageId}</FormErrorMessage>
              )}
            </FormControl>
            {!formik.values.partnerPackageId && (
              <Stack spacing={4}>
                <FormControl isInvalid={!!formik.errors.month && !!formik.touched.month}>
                  <FormLabel>Campaign Length (in months)</FormLabel>
                  <NumberSelect
                    variant="outline"
                    id="month"
                    onChange={formik.handleChange}
                    value={formik.values.month}
                    rangeStart={1}
                    rangeEnd={12}
                  />
                  <FormErrorMessage>{formik.errors.month}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.errors.amount && !!formik.touched.amount}>
                  <FormLabel>Amount</FormLabel>
                  <InputGroup>
                    <InputLeftElement color="gray.300" fontSize="1.2em">
                      $
                    </InputLeftElement>
                    <Input
                      placeholder="Enter amount"
                      variant="outline"
                      id="amount"
                      type="number"
                      onChange={formik.handleChange}
                    />
                  </InputGroup>
                  {!!formik.errors.amount && !!formik.touched.amount && (
                    <FormErrorMessage>{formik.errors.amount}</FormErrorMessage>
                  )}
                </FormControl>
              </Stack>
            )}
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <SimpleGrid columns={2} gridGap={2} width="100%">
            <PrimaryButton width="100%" onClick={formik.submitForm}>
              Save
            </PrimaryButton>
            <CancelButton width="100%" onClick={handleCancel}>
              Cancel
            </CancelButton>
          </SimpleGrid>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

import { faTasks } from "@fortawesome/free-solid-svg-icons";

import { useCategoriesForSelectorQuery } from "../../generated/graphql";
import { CustomMultiSelect } from "../custom-selector";

interface Props {
  selectedCategoryIds: number[];
  onCategorySelected: (categoryIds: number[]) => void;
  emptyValueLabel?: string;
  dontUsePortal?: true;
}

export function CategoryMultiSelector(props: Props) {
  const { selectedCategoryIds, onCategorySelected, emptyValueLabel, dontUsePortal } = props;

  const { data } = useCategoriesForSelectorQuery({ fetchPolicy: "cache-and-network" });

  const items =
    data?.categories?.nodes.map((node) => ({
      value: node.id.toString(),
      displayText: node.name,
    })) ?? [];

  return (
    <CustomMultiSelect
      onChange={(values) => onCategorySelected(values.map((value) => parseInt(value)))}
      value={selectedCategoryIds.map((value) => value.toString())}
      items={items}
      selectorIcon={faTasks}
      emptyValueTitle={emptyValueLabel}
      pluralizedItemName="Categories"
      dontUsePortal={dontUsePortal}
    />
  );
}

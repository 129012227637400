import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import ContentLoader from "react-content-loader";
import { Text, Flex, Skeleton, Stack } from "@chakra-ui/react";
import { AppColors } from "../core/custom-theme";

type LoaderType = "content" | "table" | "fullView" | "panel" | "block";

interface Props {
  large?: boolean;
  message?: string;
  type?: LoaderType;
}

interface FullViewLoaderProps {
  message?: string;
}

interface Style {
  opacity: number;
}

interface TableLoaderProps {
  key: number;
  style: Style;
}

const TableRow = (props: TableLoaderProps) => {
  const random = Math.random() * (1 - 0.7) + 0.7;
  return (
    <ContentLoader height={20} width={1060} speed={2} style={props.style}>
      <rect x="34" y="13" rx="3" ry="3" width={200 * random} height="5" />
      <rect x="300" y="13" rx="3" ry="3" width={78 * random} height="5" />
      <rect x="450" y="13" rx="3" ry="3" width={78 * random} height="5" />
      <rect x="603" y="13" rx="3" ry="3" width={78 * random} height="5" />
      <rect x="755" y="13" rx="3" ry="3" width={117 * random} height="5" />
      <rect x="938" y="13" rx="3" ry="3" width={83 * random} height="5" />
    </ContentLoader>
  );
};

const TableLoader = () => {
  return (
    <>
      {Array(5)
        .fill("")
        .map((e, i) => (
          <TableRow key={i} style={{ opacity: Number(2 / i + 1) }} />
        ))}
    </>
  );
};

const CardContentLoader = () => (
  <ContentLoader height={80} width={400} speed={2}>
    <rect x="0" y="0" rx="3" ry="3" width="250" height="3" />
    <rect x="20" y="10" rx="3" ry="3" width="220" height="3" />
    <rect x="20" y="20" rx="3" ry="3" width="170" height="3" />
    <rect x="0" y="30" rx="3" ry="3" width="250" height="3" />
    <rect x="20" y="40" rx="3" ry="3" width="200" height="3" />
    <rect x="20" y="50" rx="3" ry="3" width="80" height="3" />
  </ContentLoader>
);

const FullViewLoader = ({ message }: FullViewLoaderProps) => (
  <Flex
    position="absolute"
    width="100%"
    height="100vh"
    justifyContent="center"
    alignItems="center"
    backgroundColor="rgba(0,0,0,0.5)"
  >
    <Flex alignItems="center">
      <FontAwesomeIcon icon={faCircleNotch} spin size="3x" />
      {message && <Text marginLeft={6}>{message}</Text>}
    </Flex>
  </Flex>
);

export function Loader(props: Props) {
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldShow(true);
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!shouldShow) {
    return null;
  }

  const { large, message, type } = props;
  if (type === "table") {
    return <TableLoader />;
  }
  if (type === "content") {
    return <CardContentLoader />;
  }
  if (type === "fullView") {
    return <FullViewLoader message={message} />;
  }
  if (type === "panel") {
    return (
      <Stack spacing={4}>
        <Skeleton height="20px" startColor={AppColors.textGray} endColor={AppColors.silver} />
        <Skeleton height="20px" startColor={AppColors.textGray} endColor={AppColors.silver} />
        <Skeleton height="20px" startColor={AppColors.textGray} endColor={AppColors.silver} />
      </Stack>
    );
  }
  if (type === "block") {
    return (
      <Stack height="100%" width="100%">
        <Skeleton height="100%" width="100%" startColor={AppColors.textGray} endColor={AppColors.silver} />
      </Stack>
    );
  }
  return (
    <Text fontSize={large ? "lg" : "md"}>
      <FontAwesomeIcon icon={faCircleNotch} spin style={{ marginRight: ".5em" }} />
      {message}
    </Text>
  );
}

import { DateTime } from "luxon";
import { useState } from "react";
import { useAdminBillingQuery } from "../../generated/graphql";

export function useAdminBilling() {
  const [selectedRange, setSelectedRange] = useState("day");
  let startDate = DateTime.utc().plus({ days: 1 }).endOf("day").toJSDate();
  if (selectedRange === "week") {
    startDate = DateTime.utc().plus({ weeks: 1 }).endOf("day").toJSDate();
  } else if (selectedRange === "month") {
    startDate = DateTime.utc().plus({ months: 1 }).endOf("day").toJSDate();
  }
  const queryResult = useAdminBillingQuery({
    fetchPolicy: "network-only",
    variables: {
      startDate: {
        lte: startDate,
      },
    },
  });

  const partners = queryResult.data?.partners.nodes ?? [];
  const billablePartners = partners.filter((x) => x.billableOrders.nodes.length > 0);
  const totalBillableAmount = billablePartners
    .map((p) => p.billableOrders.nodes)
    .flat()
    .reduce((total, order) => total + order.totalWholesaleAmount, 0);

  function handleRangeChange(evt: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedRange(evt.target.value);
  }

  return {
    loading: queryResult.loading,
    partners,
    billablePartners,
    selectedRange,
    handleRangeChange,
    totalBillableAmount,
  };
}

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { FormattedDate } from "../../components/formatted-date";
import { Loader } from "../../components/loader";
import { RichContent } from "../../components/rich-content";
import { DATE_TIME_FORMAT } from "../../constants/constants";
import { useEmailMessageModalQuery } from "../../generated/graphql";

interface Props {
  emailMessageId: number | null;
  onClose: () => void;
}

export function EmailMessageModal(props: Props) {
  const { emailMessageId, onClose } = props;

  const queryResult = useEmailMessageModalQuery({
    fetchPolicy: "network-only",
    variables: {
      emailMessageId: emailMessageId ?? 0,
    },
    skip: !emailMessageId,
  });

  const emailMessage = queryResult.data?.emailMessage ?? null;
  const loading = queryResult.loading;

  return (
    <Modal onClose={onClose} isOpen={!!emailMessageId} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Email Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading && <Loader type="panel" />}
          {!loading && emailMessage && (
            <Box>
              <Text>
                <strong>Date</strong>:{" "}
                <FormattedDate date={emailMessage.sentAt ?? emailMessage.createdAt} format={DATE_TIME_FORMAT} />
              </Text>
              <Text>
                <strong>From</strong>: {emailMessage.fromName} &lt;{emailMessage.fromAddress}&gt;
              </Text>
              <Text>
                <strong>To</strong>: {emailMessage.toName} &lt;{emailMessage.toAddress}&gt;
              </Text>
              <Text>
                <strong>Subject</strong>: {emailMessage.subject}
              </Text>
              <RichContent content={emailMessage.content} />
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

import { Elements } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import { Config } from "../config";

interface Props {
  children: React.ReactNode;
}

// TODO: make this a build time env variable
if (!Config.stripePublishableKey) {
  console.error("Stripe publishable key not found.");
}
const stripePromise = loadStripe(Config.stripePublishableKey ?? "");

export function StripeProvider(props: Props) {
  const { children } = props;
  return <Elements stripe={stripePromise}>{children}</Elements>;
}

import React from "react";

interface Props {
  children: React.ReactNode;
}

export function TableFoot(props: Props) {
  const { children } = props;
  return <tfoot>{children}</tfoot>;
}

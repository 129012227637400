import { useState } from "react";
import { Box, Flex, Heading, Text, Textarea, useToast } from "@chakra-ui/react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import {
  useConversationDetails_CreateMessageMutation,
  useConversationDetailsQuery,
  useConversationDetails_UpdateConversationMutation,
} from "../../generated/graphql";
import { Loader } from "../../components/loader";
import AppIcon from "../../components/app-icon";
import AppLink from "../../components/app-link";
import { AppColors } from "../../core/custom-theme";
import ConversationMessageCard from "../../components/conversation-messages/conversation-message-card";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";
import ErrorCard from "../../components/error-card";
import NoDataAvailable from "../../components/no-data-available";
import { HelpText } from "../../components/help-text";

export function ConversationDetails() {
  const { conversationId } = useParams<"conversationId">();
  const { data, loading, error, refetch } = useConversationDetailsQuery({
    fetchPolicy: "network-only",
    variables: {
      conversationId: conversationId ?? "",
    },
  });
  const [content, setContent] = useState("");
  const [createMessageMutation] = useConversationDetails_CreateMessageMutation();
  const [updateConversationMutation, { loading: updateLoading }] = useConversationDetails_UpdateConversationMutation();
  const toast = useToast();

  if (loading) {
    return <Loader type="fullView" />;
  }

  if (error) {
    return <ErrorCard error={error} />;
  }

  const conversation = data?.customerConversation;
  const messages = data?.customerConversation?.customerMessages.nodes;
  if (!conversation || !messages) {
    return <NoDataAvailable />;
  }

  async function onPostReply() {
    try {
      await createMessageMutation({
        variables: {
          input: {
            content,
            conversationId: conversationId ?? "",
          },
        },
      });
      setContent("");
      refetch();
    } catch (e: any) {
      toast({ title: "Conversation Details", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const onMessageContentChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setContent(e.currentTarget.value);
  };

  const getHelp = async () => {
    let helpRequestedAt;
    if (conversation?.helpRequestedAt) {
      helpRequestedAt = null;
    } else {
      helpRequestedAt = new Date();
    }
    try {
      await updateConversationMutation({
        variables: {
          input: {
            customerConversationId: conversationId ?? "",
            helpRequestedAt,
          },
        },
      });
      if (helpRequestedAt) {
        toast({ title: "Conversation Details", description: "Help was requested.", status: "success" });
      }
    } catch (e: any) {
      toast({ title: "Conversation Details", description: getDisplayMessageForError(e), status: "error" });
    }
  };
  return (
    <>
      <AppLink to="/messages">
        <Text fontSize={12} fontWeight={600} color={AppColors.blue} mb={2} mt={2}>
          <AppIcon icon={faChevronLeft} wideRightMargin />
          Messages
        </Text>
      </AppLink>
      <Heading size="md" mb={4}>
        {conversation?.subject}
      </Heading>
      <Box mb={2} display="flex" flexDirection="column" alignItems="space-between">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          flexGrow={1}
        >
          {messages?.map((message) => (
            <ConversationMessageCard
              content={message.content}
              messageDate={message.createdAt}
              key={message.id}
              isFromCustomer={message.isFromCustomer}
            />
          ))}
        </Box>
        <Box
          display="flex"
          width="100%"
          backgroundColor={AppColors.white}
          border={`1px solid ${AppColors.paleBlue}`}
          flexDirection="column"
          padding={4}
        >
          <Text mb={2} fontWeight={600} fontSize={18}>
            Reply
          </Text>
          <Textarea placeholder="Message" mb={4} onChange={onMessageContentChange} />
          <Flex justifyContent="space-between">
            <PrimaryButton width={160} onClick={onPostReply}>
              Send
            </PrimaryButton>
            <Box>
              <SecondaryButton onClick={getHelp} isDisabled={updateLoading}>
                {conversation?.helpRequestedAt ? "Cancel Help Request" : "Need Help?"}
              </SecondaryButton>
              <HelpText standardLeftMargin>
                Request for a Red Canyon Media SEO Strategist to review the conversation and provide assistance in
                responding to the customer
              </HelpText>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
}

import { Box, Flex } from "@chakra-ui/react";

import { AddPublisherDrawer } from "../../common/add-publisher-drawer";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { SearchInput } from "../../components/search-input/search-input";
import { PublishersTable } from "./publishers-table";
import { usePublishers } from "./use-publishers";
import { MapPublisherLinkLocationDrawer } from "../../common/map-publisher-link-location-drawer";

export function AdminPublishersRoute() {
  const {
    onAddPublisher,
    onAddPublisherCancel,
    onPublisherAdded,
    showAddPublisherDrawer,
    handleSearchChange,
    onMapPublisherLinkLocation,
    showMapLinkLocation,
    onCloseMapPublisherLinkLocation,
  } = usePublishers();

  return (
    <LayoutBase pageTitle="Publishers">
      <MainNav />
      <AddPublisherDrawer isOpen={showAddPublisherDrawer} onAdded={onPublisherAdded} onClose={onAddPublisherCancel} />
      <MapPublisherLinkLocationDrawer isOpen={showMapLinkLocation} onClose={onCloseMapPublisherLinkLocation} />
      <Container centerHorizontally>
        <PageHeading>Publishers</PageHeading>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between">
              <Box>
                <SearchInput onChange={handleSearchChange} />
              </Box>
              <Flex>
                <Box marginRight={4}>
                  <SecondaryButton onClick={onMapPublisherLinkLocation}>Map Publisher Link Location</SecondaryButton>
                </Box>
                <Box>
                  <PrimaryButton onClick={onAddPublisher}>Add Publisher</PrimaryButton>
                </Box>
              </Flex>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <PublishersTable />
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}

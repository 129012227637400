export const RICH_CONTENT_ELEMENTS = {
  BLOCK_QUOTE: "block-quote",
  BULLETED_LIST: "bulleted-list",
  NUMBERED_LIST: "numbered-list",
  HEADING_1: "heading-1",
  HEADING_2: "heading-2",
  HEADING_3: "heading-3",
  HEADING_4: "heading-4",
  HEADING_5: "heading-5",
  HEADING_6: "heading-6",
  LIST_ITEM: "list-item",
  PARAGRAPH: "paragraph",
  LINK: "link",
  STRONG: "strong",
  SPAN: "span",
};

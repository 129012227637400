import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FormikHelpers, useFormik } from "formik";
import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import * as yup from "yup";
import AppIcon from "../../components/app-icon";
import { PrimaryButton, PrimaryLinkButton, SecondaryButton } from "../../components/buttons";
import { HelpText } from "../../components/help-text";
import { Loader } from "../../components/loader";
import { DATE_PICKER_FORMAT } from "../../constants/constants";
import { AppColors } from "../../core/custom-theme";
import {
  ProductForm_PartnerAvailableProductFragment,
  useAddPartnerProductDrawerPartnerProductsQuery,
  useAddPartnerProductMutation,
  useCreateChecklistDefinitionMutation,
} from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { BaseProductSelector } from "../base-product-selector";
import { ChecklistDefinitionSelector } from "../checklist-definition-selector";
import { VendorSelector } from "../vendor-selector";
import { ChecklistItem } from "./checklist-item";

interface Props {
  partnerId: number;
  isOpen: boolean;
  onClose: () => void;
  onAdded: (partnerProductId: number) => void;
}

interface FormValues {
  name: string;
  productId: string;
  activeAt?: Date;
  inactiveAt?: Date;
  initialPrice: number;
  vendorId?: number;
  checklistDefinitionId?: number;
  wholesalePrice?: number;
  isChecklistRequired?: boolean;
  isVendorRequired?: boolean;
  createAnother: boolean;
}

const createPartnerProductSchema = yup.object().shape({
  name: yup.string().label("Name").required(),
  productId: yup.string().label("Base Product Type").required(),
  activeAt: yup.date().label("Active At"),
  inactiveAt: yup.date().label("In Active At"),
  initialPrice: yup.number().label("Price").required().min(0),
  vendorId: yup.number().when("productId", {
    is: (x: string) => x === "custom",
    then: yup.number().label("Vendor ID").required().nullable(false),
    otherwise: yup
      .number()
      .label("Vendor ID")
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .transform((_x) => null)
      .notRequired(),
  }),
  checklistDefinitionId: yup.number().when("productId", {
    is: (x: string) => x === "custom",
    then: yup.number().label("Checklist Definition ID").required().nullable(false),
    otherwise: yup
      .number()
      .label("Checklist Definition ID")
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .transform((_x) => null)
      .notRequired(),
  }),
});

interface ChecklistItemValue {
  name: string;
  order: number;
}

interface ChecklistFormValues {
  name: string;
  items: ChecklistItemValue[];
}

const createChecklistSchema = yup.object().shape({
  name: yup.string().label("Checklist Name").required(),
  items: yup.object().shape({
    name: yup.string().label("Checklist Item").required(),
    order: yup.number().label("Checklist Item Order").required(),
  }),
});

export function AddPartnerProductDrawer(props: Props) {
  const { onClose, onAdded, isOpen, partnerId } = props;
  const [showAddChecklist, setShowAddChecklist] = useState(false);
  const { data, loading } = useAddPartnerProductDrawerPartnerProductsQuery({
    variables: {
      partnerId,
    },
    fetchPolicy: "network-only",
  });
  const [createPartnerProduct] = useAddPartnerProductMutation();
  const [createChecklist] = useCreateChecklistDefinitionMutation();
  const toast = useToast();
  const baseTypeRef = useRef<HTMLSelectElement>(null);
  const partnerProducts = data?.partnerProducts.nodes.map((partnerProduct) => partnerProduct.name) ?? [];

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await createPartnerProduct({
        variables: {
          input: {
            partnerId,
            name: values.name,
            productId: values.productId,
            activeAt: values.activeAt ?? new Date(),
            inactiveAt: values.inactiveAt ? values.inactiveAt : undefined,
            initialPrice: values.initialPrice,
            vendorId: values.vendorId,
            checklistDefinitionId: values.checklistDefinitionId,
          },
        },
      });

      if (response.data?.createPartnerProduct.partnerProduct?.id) {
        toast({
          title: "Product Type created.",
          description: "Product Type successfully created in the system.",
          status: "success",
        });
        formikHelpers.resetForm();
        onAdded(response.data?.createPartnerProduct.partnerProduct?.id);
      }

      if (values.createAnother) {
        formikHelpers.setSubmitting(false);
        baseTypeRef.current?.focus();
      } else {
        onClose();
      }
    } catch (e: any) {
      toast({ title: "Unable to create Product Type.", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      productId: "",
      activeAt: new Date(),
      inactiveAt: undefined,
      vendorId: undefined,
      checklistDefinitionId: undefined,
      initialPrice: 0,
      wholesalePrice: 0,
      createAnother: false,
    },
    validationSchema: createPartnerProductSchema,
    onSubmit: handleSubmit,
  });

  async function submitChecklist(values: ChecklistFormValues) {
    try {
      const response = await createChecklist({
        variables: {
          input: {
            partnerId,
            name: values.name,
            items: values.items,
          },
        },
      });

      if (response.data?.createChecklistDefinition.checklistDefinition?.id) {
        toast({
          title: "Checklist created.",
          description: "Checklist successfully created in the system.",
          status: "success",
        });
        formik.setFieldValue("checklistDefinitionId", response.data?.createChecklistDefinition.checklistDefinition?.id);
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const checklistFormik = useFormik<ChecklistFormValues>({
    initialValues: {
      name: "",
      items: [
        {
          name: "",
          order: 1,
        },
      ],
    },
    validationSchema: createChecklistSchema,
    onSubmit: submitChecklist,
  });

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  function validatePartnerProductName(name: string) {
    if (partnerProducts.includes(name)) {
      formik.setFieldError("name", "Name already exist");
    }
  }

  async function onBaseProductSelected(product: ProductForm_PartnerAvailableProductFragment | null) {
    if (!product) {
      return;
    }
    formik.setFieldValue("productId", product.product.id, false);
    formik.setFieldValue("name", product.product.name, false);
    formik.setFieldValue("wholesalePrice", product.wholesalePrice, false);
    formik.setFieldValue("isVendorRequired", product.product.isVendorRequired, false);
    formik.setFieldValue("isChecklistRequired", product.product.isChecklistRequired, false);
    await formik.validateForm();
    validatePartnerProductName(product.product.name);
  }

  function onPartnerProductNameBlur(event: React.ChangeEvent<HTMLInputElement>) {
    formik.handleBlur(event);
    validatePartnerProductName(event.target.value);
  }

  function onActiveDateChange(date: Date | null) {
    formik.setFieldValue("activeAt", date ?? new Date());
  }

  function onInActiveDateChange(date: Date | null) {
    formik.setFieldValue("inactiveAt", date ?? new Date());
  }

  function onVendorSelected(vendorId: number | null) {
    formik.setFieldValue("vendorId", vendorId);
  }

  function onAddChecklistItem() {
    const { items } = checklistFormik.values;
    items.push({
      name: "",
      order: items.length + 1,
    });
    checklistFormik.setFieldValue("items", items);
  }

  function onChecklistItemNameChange(index: number, event: React.ChangeEvent<HTMLInputElement>) {
    const { items } = checklistFormik.values;
    items[index].name = event.target.value;
    checklistFormik.setFieldValue("items", items);
  }

  function onMoveUp(index: number) {
    const { items } = checklistFormik.values;
    [items[index - 1], items[index]] = [items[index], items[index - 1]];
    checklistFormik.setFieldValue("items", items);
  }

  function onMoveDown(index: number) {
    const { items } = checklistFormik.values;
    [items[index + 1], items[index]] = [items[index], items[index + 1]];
    checklistFormik.setFieldValue("items", items);
  }

  function onDelete(index: number) {
    const { items } = checklistFormik.values;
    items.splice(index, 1);
    checklistFormik.setFieldValue("items", items);
  }

  async function handleSave(andCreateAnother: boolean) {
    formik.setFieldValue("createAnother", andCreateAnother);
    if (formik.values.isChecklistRequired) {
      // TODO: await checklistFormik.submitForm();
      if (showAddChecklist) {
        await submitChecklist(checklistFormik.values);
      }
      if (andCreateAnother) {
        checklistFormik.resetForm();
      }
      await formik.submitForm();
    } else {
      await formik.submitForm();
    }
  }

  function showChecklist() {
    setShowAddChecklist(true);
  }

  function hideChecklist() {
    setShowAddChecklist(false);
  }

  function onChecklistDefinitionSelected(checklistDefinitionId: number | null) {
    formik.setFieldValue("checklistDefinitionId", checklistDefinitionId);
  }

  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={handleCancel}
        placement="right"
        size="md"
        closeOnOverlayClick={false}
        isFullHeight
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Product Type</DrawerHeader>
          <DrawerBody>
            {loading && <Loader type="content" />}
            {!loading && (
              <Stack spacing={4}>
                <FormControl isRequired isInvalid={!!formik.errors.productId && !!formik.touched.productId}>
                  <FormLabel>Base Product Type</FormLabel>
                  <BaseProductSelector
                    partnerId={partnerId}
                    onBaseProductSelected={onBaseProductSelected}
                    selectedBaseProductId={formik.values.productId}
                    ref={baseTypeRef}
                    disableForSystemManaged
                  />
                  <FormErrorMessage>{formik.errors.productId}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={!!formik.errors.name}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    value={formik.values.name}
                    id="name"
                    onChange={formik.handleChange}
                    onBlur={onPartnerProductNameBlur}
                  />
                  <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={!!formik.errors.activeAt && !!formik.touched.activeAt}>
                  <FormLabel>Active</FormLabel>
                  <DatePicker
                    onChange={onActiveDateChange}
                    selected={formik.values.activeAt}
                    minDate={new Date()}
                    dateFormat={DATE_PICKER_FORMAT}
                    customInput={<Input width="100%" />}
                    placeholderText="Select active date"
                    disabledKeyboardNavigation
                  />
                  <FormErrorMessage>{formik.errors.activeAt}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.errors.inactiveAt && !!formik.touched.inactiveAt}>
                  <FormLabel>Inactive</FormLabel>
                  <DatePicker
                    onChange={onInActiveDateChange}
                    selected={formik.values.inactiveAt}
                    minDate={formik.values.activeAt ?? new Date()}
                    dateFormat={DATE_PICKER_FORMAT}
                    customInput={<Input width="100%" />}
                    placeholderText="Select inactive date (optional)"
                    disabledKeyboardNavigation
                  />
                  <FormErrorMessage>{formik.errors.inactiveAt}</FormErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel>
                    Wholesale Price
                    <HelpText standardLeftMargin>
                      The amount Red Canyon Media charges for the base product type.
                    </HelpText>
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement color={AppColors.textGray}>
                      <AppIcon icon={faDollarSign} />
                    </InputLeftElement>
                    <Input type="number" value={formik.values.wholesalePrice} isDisabled />
                  </InputGroup>
                </FormControl>
                <FormControl isInvalid={!!formik.errors.initialPrice && !!formik.touched.initialPrice}>
                  <FormLabel>
                    Retail Price
                    <HelpText standardLeftMargin>
                      The amount that is charged to the client when this product type is ordered.
                    </HelpText>
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement color={AppColors.textGray}>
                      <AppIcon icon={faDollarSign} />
                    </InputLeftElement>
                    <Input
                      placeholder="Enter amount"
                      id="initialPrice"
                      type="number"
                      value={formik.values.initialPrice}
                      onChange={formik.handleChange}
                    />
                  </InputGroup>
                  <FormErrorMessage>{formik.errors.initialPrice}</FormErrorMessage>
                </FormControl>
                {formik.values.isVendorRequired && (
                  <FormControl isRequired isInvalid={!!formik.errors.vendorId && !!formik.touched.vendorId}>
                    <FormLabel>Vendor</FormLabel>
                    <VendorSelector onVendorSelected={onVendorSelected} selectedVendorId={formik.values.vendorId} />
                    <FormErrorMessage>{formik.errors.vendorId}</FormErrorMessage>
                  </FormControl>
                )}
                {formik.values.isChecklistRequired && (
                  <FormControl
                    isRequired
                    isInvalid={!!formik.errors.checklistDefinitionId && !!formik.touched.checklistDefinitionId}
                  >
                    <FormLabel>
                      Checklist{" "}
                      <HelpText>
                        A checklist allows you to add the sub-level tasks associated with knowing a task is done (ex. H1
                        tag, H2 tag, focus keyword)
                      </HelpText>
                    </FormLabel>
                    {showAddChecklist ? (
                      <>
                        <FormControl
                          isRequired
                          isInvalid={!!checklistFormik.errors.name && !!checklistFormik.touched.name}
                        >
                          <FormLabel>Checklist Name</FormLabel>
                          <Input
                            value={checklistFormik.values.name}
                            name="name"
                            onChange={checklistFormik.handleChange}
                            onBlur={checklistFormik.handleBlur}
                          />
                          <FormErrorMessage>{checklistFormik.errors.name}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired>
                          <FormLabel>Checklist Items</FormLabel>
                          {checklistFormik.values.items.map((item, index, arr) => (
                            <ChecklistItem
                              key={index}
                              isDownDisabled={index === arr.length - 1}
                              isUpDisabled={index === 0}
                              name={item.name}
                              onMoveDown={onMoveDown.bind(null, index)}
                              onMoveUp={onMoveUp.bind(null, index)}
                              onDelete={onDelete.bind(null, index)}
                              onNameChange={onChecklistItemNameChange.bind(null, index)}
                            />
                          ))}
                        </FormControl>
                        <SimpleGrid columns={2} gridGap={4} width="100%">
                          <PrimaryLinkButton onClick={onAddChecklistItem}>Add Step</PrimaryLinkButton>
                          <PrimaryLinkButton onClick={hideChecklist}>Close</PrimaryLinkButton>
                        </SimpleGrid>
                      </>
                    ) : (
                      <>
                        <ChecklistDefinitionSelector
                          onChecklistDefinitionSelected={onChecklistDefinitionSelected}
                          selectedChecklistDefinitionId={formik.values.checklistDefinitionId}
                        />
                        <PrimaryLinkButton onClick={showChecklist}>Add Checklist</PrimaryLinkButton>
                      </>
                    )}
                    <FormErrorMessage>{formik.errors.checklistDefinitionId}</FormErrorMessage>
                  </FormControl>
                )}
              </Stack>
            )}
          </DrawerBody>
          <DrawerFooter>
            <SimpleGrid columns={2} gridGap={4} width="100%">
              <PrimaryButton width="100%" onClick={handleSave.bind(null, false)}>
                Save
              </PrimaryButton>
              <SecondaryButton width="100%" onClick={handleSave.bind(null, true)}>
                Save &amp; Add Another
              </SecondaryButton>
            </SimpleGrid>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

import { Box, Button, Flex, FormControl, FormLabel, Heading, HStack } from "@chakra-ui/react";
import { faBan } from "@fortawesome/free-solid-svg-icons";

import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { SearchInput } from "../../components/search-input/search-input";
import {
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TablePagination,
  TableRow,
} from "../../components/table";
import AppIcon from "../../components/app-icon";
import { PrimaryButton } from "../../components/buttons";
import AppLink from "../../components/app-link";
import { LinkLocationStatusSelector } from "../../common/link-location-status-selector";
import { useAdminPublisherLinkLocations } from "./use-admin-publisher-link-locations";
import { AddLinkLocationModal } from "../../common/add-link-location-modal";
import { CategoryMultiSelector } from "../../common/category-selector";
import { currencyFormatter } from "../../util/formatter";

export function AdminPublisherLinkLocations() {
  const {
    publisherId,
    onSearchChange,
    statusIds,
    onStatusIdsChange,
    hasFilters,
    clearFilters,
    loading,
    totalCount,
    publisherLinkLocations,
    showAddLinkLocation,
    onAddLinkLocation,
    onCloseAddLinkLocation,
    onAddedLinkLocation,
    offset,
    limit,
    onNextPage,
    onPreviousPage,
    updateLinkLocationCategories,
  } = useAdminPublisherLinkLocations();

  return (
    <>
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Box>
            <Heading size="md">Link Locations</Heading>
            <Flex justifyContent="space-between" alignItems="center" marginTop={4}>
              <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end">
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Search</FormLabel>
                  <SearchInput onChange={onSearchChange} placeholder="Search Sites" />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Statuses</FormLabel>
                  <LinkLocationStatusSelector
                    selectedStatusIds={statusIds as string[]}
                    onStatusSelected={onStatusIdsChange}
                    emptyValueLabel="All Statuses"
                    isMulti
                  />
                </FormControl>
                {hasFilters && (
                  <Button onClick={clearFilters}>
                    <AppIcon icon={faBan} standardRightMargin />
                    Clear Filters
                  </Button>
                )}
              </HStack>
              <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end">
                <PrimaryButton onClick={onAddLinkLocation}>Add Link Location</PrimaryButton>
              </HStack>
            </Flex>
          </Box>
        </PanelTop>
        <PanelBottom containsTable>
          <Table isEmpty={publisherLinkLocations.length === 0} isLoading={loading}>
            <TableHead>
              <TableRow key="head">
                <TableHeaderCell>Domain</TableHeaderCell>
                <TableHeaderCell>Domain Authority</TableHeaderCell>
                <TableHeaderCell>Authority Score</TableHeaderCell>
                <TableHeaderCell>Search Volume</TableHeaderCell>
                <TableHeaderCell>Quality</TableHeaderCell>
                <TableHeaderCell>Cost</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell>Categories</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {publisherLinkLocations.map((publisherLinkLocation) => {
                const linkLocation = publisherLinkLocation.linkLocation;
                return (
                  <TableRow key={publisherLinkLocation.id}>
                    <TableCell>
                      <AppLink to={`/outreach/link-locations/${linkLocation?.id ?? 0}`}>
                        {linkLocation?.domain ?? "-"}
                      </AppLink>
                    </TableCell>
                    <TableCell>{linkLocation?.domainAuthority ?? "-"}</TableCell>
                    <TableCell>{linkLocation?.authorityScore ?? "-"}</TableCell>
                    <TableCell>{linkLocation?.monthlyTraffic ?? "-"}</TableCell>
                    <TableCell>{linkLocation?.internalQuality ?? "-"}</TableCell>
                    <TableCell>{linkLocation?.cost ? currencyFormatter.format(linkLocation.cost) : "-"}</TableCell>
                    <TableCell>{linkLocation?.status.name ?? "-"}</TableCell>
                    <TableCell>
                      <CategoryMultiSelector
                        selectedCategoryIds={linkLocation?.linkLocationCategories?.map((c) => c.categoryId) ?? []}
                        onCategorySelected={(categoryIds) =>
                          updateLinkLocationCategories(linkLocation?.id ?? 0, categoryIds)
                        }
                        emptyValueLabel="No Category"
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFoot>
              <TablePagination
                totalCount={totalCount}
                offset={offset}
                limit={limit}
                onPreviousPage={onPreviousPage}
                onNextPage={onNextPage}
              />
            </TableFoot>
          </Table>
        </PanelBottom>
      </PanelGroup>
      <AddLinkLocationModal
        publisherId={publisherId}
        isOpen={showAddLinkLocation}
        onAdded={onAddedLinkLocation}
        onClose={onCloseAddLinkLocation}
      />
    </>
  );
}

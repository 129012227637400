import { useState } from "react";
import { useToast } from "@chakra-ui/react";

import {
  usePublishTitleMetaDescriptionTaskWorkspaceQuery,
  useUpdatePublishTitleMetaDescriptionMutation,
} from "../../generated/graphql";

interface Options {
  taskId: number;
  onComplete: () => void;
}

export function useTaskWorkspacePublishTitleMetaDescription(options: Options) {
  const { taskId, onComplete } = options;
  const [isTitleMetaDescriptionPublished, setIsTitleMetaDescriptionPublished] = useState<boolean>(false);
  const toast = useToast();

  const queryResult = usePublishTitleMetaDescriptionTaskWorkspaceQuery({
    variables: {
      taskId,
    },
    fetchPolicy: "network-only",
  });

  const [updateIsTitleMetaDescriptionPublished] = useUpdatePublishTitleMetaDescriptionMutation();

  const task = queryResult.data?.task ?? null;
  const customer = queryResult.data?.task?.customer ?? null;
  const orderTitleMetaDescriptionItem = queryResult.data?.task?.order?.orderTitleMetaDescriptionItem ?? null;
  const titleMetaDescriptionItemId = orderTitleMetaDescriptionItem?.titleMetaDescriptionItem.id ?? null;
  const titleMetaDescriptions = orderTitleMetaDescriptionItem?.titleMetaDescriptionItem?.titleMetaDescriptions ?? [];
  const canSubmit = isTitleMetaDescriptionPublished;

  async function handleComplete() {
    if (!task) {
      toast({
        title: "Task data can not be loaded",
        status: "error",
      });
      return;
    }

    try {
      const response = await updateIsTitleMetaDescriptionPublished({
        variables: {
          input: {
            orderTitleMetaDescriptionItemId: orderTitleMetaDescriptionItem?.id ?? 0,
            isPublished: isTitleMetaDescriptionPublished,
          },
        },
      });

      if (response.data?.updateOrderTitleMetaDescriptionItem.ok) {
        toast({
          title: "Success",
          description: "Title And Meta Description Published updated successfully!",
          status: "success",
        });
      } else {
        toast({
          title: "Error",
          description: "Update of title and meta description published failed!",
          status: "error",
        });
      }
      await queryResult.refetch();
    } catch (e: any) {
      toast({
        title: "Error",
        description: "Update of post published failed!",
        status: "error",
      });
    }
    onComplete();
  }

  function onChangePublishedTitleMetaDescription() {
    setIsTitleMetaDescriptionPublished(!isTitleMetaDescriptionPublished);
  }

  function onExport() {
    if (!titleMetaDescriptionItemId) {
      return;
    }
    window.open(
      `${process.env.REACT_APP_API_BASE}/files/title-meta-descriptions/${titleMetaDescriptionItemId}`,
      "_blank"
    );
  }

  return {
    taskId,
    customer,
    task,
    handleComplete,
    isTitleMetaDescriptionPublished,
    onChangePublishedTitleMetaDescription,
    titleMetaDescriptions,
    canSubmit,
    titleMetaDescriptionItemId,
    onExport,
  };
}

import { Grid, Heading, Stack } from "@chakra-ui/react";

import { Table, TableBody } from "../../components/table";
import { DataDisplayRow } from "../../components/data-display-row";
import { ExternalLink } from "../../components/external-link";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";

import { ContentStrategyItem } from "./content-strategy-item";
import { useContentStrategyV2 } from "./content-strategy-v2-hook";
import { Summary } from "./summary";
import { ReviewCommentsPanel } from "../review-comments-panel";

interface Props extends ReturnType<typeof useContentStrategyV2> {
  taskId: number;
  onAbandon: () => void;
  onComplete: (isApproved?: boolean, reviewComment?: string) => void;
  isQaReview?: boolean;
}

export function ContentStrategyWorkspace(props: Props) {
  const {
    customer,
    customerQuestionnaire,
    orderContentStrategies,
    handleComplete,
    customerProposal,
    refetch,
    reviewTasks,
  } = props;

  return (
    <Grid gridTemplateColumns="1fr 1.5fr" gap={4}>
      <Stack spacing={4}>
        <ReviewCommentsPanel reviewTasks={reviewTasks} />
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Heading size="md">Client Details</Heading>
          </PanelTop>
          <PanelBottom containsTable>
            <Table isEmpty={false} isLoading={false}>
              <TableBody>
                <DataDisplayRow label="Client Name" value={customer?.name ?? "-"} />
                <DataDisplayRow label="About Company" value={customerQuestionnaire?.businessDescription ?? "-"} />
                <DataDisplayRow
                  label="Website URL"
                  value={
                    customer?.website ? (
                      <ExternalLink href={customer.website} target="_blank">
                        {customer.website}
                      </ExternalLink>
                    ) : (
                      "-"
                    )
                  }
                />
                <DataDisplayRow label="Category" value={customer?.category?.name ?? "-"} />
                <DataDisplayRow label="Business Type" value={customerQuestionnaire?.customerMarketSize ?? "-"} />
                <DataDisplayRow label="Target Audience" value={customerQuestionnaire?.targetAudience ?? "-"} />
                <DataDisplayRow
                  label="Competitors/Not Permitted"
                  value={customerQuestionnaire?.competitorWebsites ?? ""}
                />
                <DataDisplayRow label="Client Instructions" value={customer?.instructions ?? "-"} />
              </TableBody>
            </Table>
          </PanelBottom>
        </PanelGroup>
      </Stack>
      <Stack width="100%">
        {orderContentStrategies.map((orderContentStrategy) => (
          <ContentStrategyItem
            key={orderContentStrategy.id}
            orderContentStrategy={orderContentStrategy}
            workspaceRefetch={refetch}
          />
        ))}
        <Summary
          customerProposalId={customerProposal?.id ?? 0}
          summary={customerProposal?.summary}
          onAbandon={props.onAbandon}
          isQaReview={props?.isQaReview}
          handleComplete={handleComplete}
          isDisabled={orderContentStrategies.length === 0}
        />
      </Stack>
    </Grid>
  );
}

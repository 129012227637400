import { Box, Checkbox, FormControl, FormLabel, Stack, Text } from "@chakra-ui/react";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { CustomSingleSelect } from "../../common/custom-selector";
import { CustomerSelector } from "../../common/customer-selector";
import AppIcon from "../../components/app-icon";
import { PrimaryButton } from "../../components/buttons";
import { FormattedDate } from "../../components/formatted-date";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { currencyFormatter } from "../../util/formatter";
import { useBillingPay } from "./billing-pay-hook";

interface Props {
  useBillingPayValue: ReturnType<typeof useBillingPay>;
}

export function BillingPaySelectOrders(props: Props) {
  const {
    partnerId,
    handleRangeChange,
    selectedRange,
    billableOrders,
    selectedOrderIds,
    toggleOrder,
    handleCheckAll,
    handleReviewClick,
    handleCustomerSelectorChange,
    selectedCustomerIds,
  } = props.useBillingPayValue;
  return (
    <Stack spacing={4}>
      <PanelGroup>
        <PanelTop>
          <Stack isInline spacing={2} shouldWrapChildren>
            <FormControl>
              <FormLabel fontSize="sm">Filter by Client</FormLabel>
              <CustomerSelector
                onCustomerSelected={handleCustomerSelectorChange}
                selectedCustomerIds={selectedCustomerIds}
                isMulti
                partnerIds={[partnerId]}
                emptyValueLabel="All Clients"
              />
            </FormControl>
            <FormControl>
              <FormLabel fontSize="sm">Show Unpaid Orders With Start Date</FormLabel>
              <CustomSingleSelect
                selectorIcon={faCalendar}
                items={[
                  { displayText: "In the Next Day (or earlier)", value: "day" },
                  { displayText: "In the Next Week (or earlier)", value: "week" },
                  { displayText: "In the Next Month (or earlier)", value: "month" },
                ]}
                onChange={(value) => {
                  handleRangeChange(value ?? "day");
                }}
                value={selectedRange}
              />
            </FormControl>
          </Stack>
        </PanelTop>
        <PanelBottom containsTable>
          <Table isEmpty={billableOrders.length === 0} isLoading={false}>
            <TableHead>
              <TableRow>
                <TableHeaderCell>
                  <Checkbox
                    isChecked={selectedOrderIds.length > 0}
                    isIndeterminate={selectedOrderIds.length > 0 && selectedOrderIds.length < billableOrders.length}
                    onChange={handleCheckAll}
                  />
                </TableHeaderCell>
                <TableHeaderCell>Order ID</TableHeaderCell>
                <TableHeaderCell>Start Date</TableHeaderCell>
                <TableHeaderCell>Customer</TableHeaderCell>
                <TableHeaderCell>Product</TableHeaderCell>
                <TableHeaderCell>Total Wholesale Amount</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {billableOrders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>
                    <Checkbox
                      isChecked={selectedOrderIds.includes(order.id)}
                      onChange={() => {
                        toggleOrder(order.id);
                      }}
                    />
                  </TableCell>
                  <TableCell>{order.id}</TableCell>
                  <TableCell>
                    <FormattedDate format={DATE_MONTH_FORMAT} date={order.startDate} />
                  </TableCell>
                  <TableCell>{order.customer.name}</TableCell>
                  <TableCell>
                    <Stack spacing={0}>
                      <Text>{order.partnerProduct.product.name}</Text>
                    </Stack>
                  </TableCell>
                  <TableCell>{currencyFormatter.format(order.totalWholesaleAmount)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PanelBottom>
      </PanelGroup>
      <Box textAlign="right">
        <PrimaryButton onClick={handleReviewClick} isDisabled={selectedOrderIds.length === 0}>
          Review
          <AppIcon icon={faChevronRight} standardLeftMargin />
        </PrimaryButton>
      </Box>
    </Stack>
  );
}

import { PanelGroup, PanelTop, PanelBottom, PanelMiddle } from "../../components/panels";
import {
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Box,
  FormHelperText,
  FormErrorMessage,
} from "@chakra-ui/react";
import { DangerLinkButton, PrimaryButton } from "../../components/buttons";
import { PageHeading } from "../../components/page-heading";
import { WorkflowNotes } from "../workflow-notes";
import { useTaskWorkspaceBusinessListing } from "./task-workspace-business-listing-hook";
import { Table, TableBody } from "../../components/table";
import { TruncatedText } from "../../components/truncated-text";
import { DataDisplayRow } from "../../components/data-display-row";

interface Props {
  taskId: number;
  onAbandon: () => void;
  onComplete: () => void;
}

export function TaskWorkspaceBusinessListing(props: Props) {
  const { taskId, onAbandon } = props;
  const { businessListing, task, formik } = useTaskWorkspaceBusinessListing(props);

  return (
    <>
      <PageHeading>{task?.order.partnerProduct.name ?? "Business Listing Task"}</PageHeading>
      <form onSubmit={formik.handleSubmit}>
        <Grid templateColumns={{ base: "1fr", xl: "1fr 1fr" }} gridGap={4}>
          <Stack spacing={4} gridColumn={1}>
            <PanelGroup isCollapsible>
              <PanelTop showBottomBorder>
                <Heading size="md">Client Details</Heading>
              </PanelTop>
              <PanelBottom containsTable>
                <Table isEmpty={false} isLoading={false}>
                  <TableBody>
                    <DataDisplayRow
                      label="Company Name"
                      value={task?.customer.name ?? ""}
                      copyValue={task?.customer.name ?? ""}
                    />
                    <DataDisplayRow
                      label="Company Description"
                      value={
                        <TruncatedText maxLength={50} marginRight={4} expandable>
                          {task?.customer.customerQuestionnaire?.businessDescription ?? ""}
                        </TruncatedText>
                      }
                      copyValue={task?.customer.customerQuestionnaire?.businessDescription ?? ""}
                    />
                    <DataDisplayRow
                      label="Website"
                      value={task?.customer.website ?? ""}
                      copyValue={task?.customer.website ?? ""}
                    />
                    <DataDisplayRow
                      label="Category"
                      value={task?.customer.category?.name ?? ""}
                      copyValue={task?.customer.category?.name ?? ""}
                    />
                    <DataDisplayRow
                      label="Do you have a physical storefront?"
                      value={businessListing?.hasPhysicalStorefront ? "Yes" : "No"}
                    />
                    <DataDisplayRow
                      label="Contact Name"
                      value={businessListing?.contactName ?? ""}
                      copyValue={businessListing?.contactName ?? ""}
                    />
                    <DataDisplayRow
                      label="Company Email"
                      value={businessListing?.email ?? ""}
                      copyValue={businessListing?.email ?? ""}
                    />
                    <DataDisplayRow
                      label="Address"
                      value={businessListing?.address ?? ""}
                      copyValue={businessListing?.address ?? ""}
                    />
                    <DataDisplayRow
                      label="City"
                      value={businessListing?.city ?? ""}
                      copyValue={businessListing?.city ?? ""}
                    />
                    <DataDisplayRow
                      label="State"
                      value={businessListing?.stateOrProvince ?? ""}
                      copyValue={businessListing?.stateOrProvince ?? ""}
                    />
                    <DataDisplayRow
                      label="Zip Code/Postal Code"
                      value={businessListing?.zipPostalCode ?? ""}
                      copyValue={businessListing?.zipPostalCode ?? ""}
                    />
                    <DataDisplayRow
                      label="Country"
                      value={businessListing?.country ?? ""}
                      copyValue={businessListing?.country ?? ""}
                    />
                    <DataDisplayRow
                      label="Phone Number"
                      value={businessListing?.phoneNumber ?? ""}
                      copyValue={businessListing?.phoneNumber ?? ""}
                    />
                    <DataDisplayRow label="Client Instructions" value={task?.customer?.instructions ?? ""} />
                  </TableBody>
                </Table>
              </PanelBottom>
            </PanelGroup>
          </Stack>
          <Stack spacing={4} gridColumn={{ base: 1, xl: 2 }}>
            <PanelGroup>
              <PanelTop showBottomBorder>
                <Heading size="md">Business Listing Information</Heading>
              </PanelTop>
              <PanelMiddle>
                <Stack spacing={4}>
                  <FormControl isInvalid={!!formik.touched.resultUrl && !!formik.errors.resultUrl}>
                    <FormLabel>Result URL</FormLabel>
                    <Input
                      id="resultUrl"
                      value={formik.values.resultUrl}
                      variant="outline"
                      onChange={formik.handleChange}
                    />
                    <FormErrorMessage>{formik.errors.resultUrl}</FormErrorMessage>
                    <FormHelperText>Must begin with http:// or https://</FormHelperText>
                  </FormControl>
                  <FormControl
                    isInvalid={!!formik.touched.resultUrlDomainAuthority && !!formik.errors.resultUrlDomainAuthority}
                  >
                    <FormLabel>Result URL Domain Authority</FormLabel>
                    <Input
                      variant="outline"
                      value={formik.values.resultUrlDomainAuthority ?? undefined}
                      name="resultUrlDomainAuthority"
                      type="number"
                      onChange={formik.handleChange}
                    />
                    <FormHelperText>Optional</FormHelperText>
                    <FormErrorMessage>{formik.errors.resultUrlDomainAuthority}</FormErrorMessage>
                  </FormControl>
                </Stack>
              </PanelMiddle>
              <PanelBottom showTopBorder>
                <Stack isInline spacing={4} justifyContent="flex-end" shouldWrapChildren alignItems="center">
                  <Box>
                    <DangerLinkButton onClick={onAbandon}>Unassign Task</DangerLinkButton>
                  </Box>
                  <PrimaryButton isDisabled={!formik.isValid} type="submit">
                    Complete Task
                  </PrimaryButton>
                </Stack>
              </PanelBottom>
            </PanelGroup>
            <WorkflowNotes taskId={taskId} />
          </Stack>
        </Grid>
      </form>
    </>
  );
}

import { Select, SelectProps } from "@chakra-ui/react";
import { usePartnerUserSelector } from "./use-partner-user-selector";

interface Props extends SelectProps {
  partnerId: number;
  userId: number | null;
  onSelectedUserChanged: (userId: number | null) => void;
  nullUserText?: string;
  preventNullUser?: boolean;
}

export function PartnerUserSelector(props: Props) {
  const { partnerId, userId, onSelectedUserChanged, nullUserText, preventNullUser, ...rest } = props;
  const { loading, partnerUsers } = usePartnerUserSelector(userId, partnerId);

  return (
    <Select
      isDisabled={loading}
      value={userId?.toString() ?? ""}
      onChange={(e) => {
        if (e.target.value === "") {
          onSelectedUserChanged(null);
        } else {
          onSelectedUserChanged(parseInt(e.target.value));
        }
      }}
      {...rest}
    >
      <option value="" disabled={preventNullUser}>
        {nullUserText ?? "Select a User"}
      </option>
      {partnerUsers.map((partnerUser) => (
        <option key={partnerUser.user.id} value={partnerUser.user.id.toString()}>
          {partnerUser.user.lastName}, {partnerUser.user.firstName}
        </option>
      ))}
    </Select>
  );
}

import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { Heading, Stack, Flex, Text } from "@chakra-ui/react";
import { Loader } from "../../components/loader";
import { useWorkflowNotes } from "./use-workflow-notes";
import { WorkflowNote } from "./workflow-note";
import { NewWorkflowNote } from "./new-workflow-note";
import { SecondaryButton } from "../../components/buttons";
import AppIcon from "../../components/app-icon";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface Props {
  taskId: number;
  preventNewNotes?: boolean;
}

export function WorkflowNotes(props: Props) {
  const { taskId, preventNewNotes } = props;
  const {
    loading,
    workflowNotes,
    onWorkflowNoteCreated,
    workflowId,
    showEditor,
    onAddNote,
    onAddNoteCancel,
    totalCount,
  } = useWorkflowNotes(taskId);
  return (
    <PanelGroup isCollapsible>
      <PanelTop showBottomBorder>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">Notes ({totalCount})</Heading>
          {!preventNewNotes && <SecondaryButton onClick={onAddNote}>Add Note</SecondaryButton>}
        </Flex>
      </PanelTop>
      <PanelBottom>
        {loading && <Loader type="content" />}
        {!loading && workflowNotes.length === 0 && !showEditor && (
          <Text>
            <AppIcon icon={faInfoCircle} standardRightMargin />
            No notes.
          </Text>
        )}
        {!loading && (
          <Stack spacing={4} shouldWrapChildren>
            {!preventNewNotes && (
              <NewWorkflowNote
                onCancel={onAddNoteCancel}
                showEditor={showEditor}
                workflowId={workflowId}
                onWorkflowNoteCreated={onWorkflowNoteCreated}
              />
            )}
            {workflowNotes.map((note) => (
              <WorkflowNote
                key={note.id}
                authorFullName={note.user.fullName}
                content={note.content}
                createdAt={note.createdAt}
              />
            ))}
          </Stack>
        )}
      </PanelBottom>
    </PanelGroup>
  );
}

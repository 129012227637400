import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useRef, useState } from "react";
import * as yup from "yup";
import { useCreateCustomerMutation } from "../../generated/graphql";
import { handleFormGraphQLError } from "../../util/error-helper";

interface Options {
  onClose: () => void;
  onAdded: (customerId: number, isAddingAnother: boolean) => void;
}

interface FormValues {
  partnerId: number;
  firstName: string;
  lastName: string;
  email: string;
  name: string;
  website: string;
  gbpUrl?: string | null;
  accountManagerUserId: number | null;
  categoryId: number;
  instructions: string | null;
  portal: string;
}

const createCustomerSchema = yup.object().shape({
  firstName: yup.string().label("First Name").required().max(255),
  lastName: yup.string().label("Last Name").required().max(255),
  email: yup.string().label("Email").required().email().max(255),
  name: yup.string().label("Company Name").required(),
  website: yup.string().label("Website").url().required(),
  gbpUrl: yup.string().url().label("Gbp Url").notRequired().nullable(true),
  categoryId: yup
    .number()
    .label("Business Category")
    .required()
    .nullable(false)
    .min(1, "Business Category is a required field"),
  portal: yup.string().label("Portal").notRequired().default("customer"),
});

export function useAddClientDrawer(options: Options) {
  const { onAdded, onClose } = options;
  const [createAnother, setCreateAnother] = useState(false);
  const [createCustomerMutation] = useCreateCustomerMutation();
  const toast = useToast();
  const nameFieldRef = useRef<HTMLInputElement>(null);

  const formik = useFormik<FormValues>({
    initialValues: {
      partnerId: 0,
      firstName: "",
      lastName: "",
      email: "",
      name: "",
      website: "",
      gbpUrl: "",
      accountManagerUserId: null,
      categoryId: 0,
      instructions: "",
      portal: "customer",
    },
    validationSchema: createCustomerSchema,
    onSubmit: async (values, helpers) => {
      try {
        const response = await createCustomerMutation({
          variables: {
            input: {
              partnerId: values.partnerId,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              name: values.name,
              accountManagerUserId: values.accountManagerUserId,
              website: values.website,
              gbpUrl: values.gbpUrl,
              categoryId: values.categoryId,
              instructions: values.instructions,
              portal: values.portal,
            },
          },
        });

        toast({ title: "Client added.", description: `${values.name} was added successfully.`, status: "success" });
        onAdded(response.data?.createCustomer.customer?.id ?? 0, createAnother);
        if (createAnother) {
          helpers.resetForm();
          helpers.setSubmitting(false);
          nameFieldRef.current?.focus();
        }
      } catch (e: any) {
        handleFormGraphQLError(e, "Unable to Save Client", toast, helpers.setErrors);
      }
    },
  });

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  function handleSave(andCreateAnother?: boolean) {
    setCreateAnother(andCreateAnother ?? false);
    formik.handleSubmit();
  }

  return {
    formik,
    handleCancel,
    handleSave,
    nameFieldRef,
  };
}

import React from "react";
import { useQueryParam, withDefault, StringParam, NumberParam, DelimitedNumericArrayParam } from "use-query-params";

import { PAGE_LIMIT } from "../../constants/constants";
import { PRODUCT_CATEGORY } from "../../constants/products";
import { OrdersSort, useOrdersQuery } from "../../generated/graphql";
import { CommaArrayParam } from "../../util/use-query-param/comma-array-param";

export function useOrders() {
  const [pageOffset, setPageOffset] = useQueryParam("pageOffset", withDefault(NumberParam, 0));
  const [search, setSearchParam] = useQueryParam("search", StringParam);
  const [sort, setSortParam] = useQueryParam("sort", withDefault(StringParam, OrdersSort.StartedAtDesc));
  const [statusIds, setStatusIdsParam] = useQueryParam("statusIds", withDefault(CommaArrayParam, []));
  const [clientIds, setClientIdsParam] = useQueryParam("clientIds", withDefault(DelimitedNumericArrayParam, []));
  const [productCategoryIds, setProductCategoryIds] = useQueryParam(
    "productCategoryIds",
    withDefault(CommaArrayParam, [])
  );

  const hasFilters = !!search || statusIds.length > 0 || clientIds.length > 0 || productCategoryIds.length > 0;

  const productIds = (productCategoryIds as string[]).reduce(
    (result, currentId) => [...result, ...PRODUCT_CATEGORY[currentId].productIds],
    [] as string[]
  );

  const ordersQueryResult = useOrdersQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: PAGE_LIMIT,
      offset: pageOffset,
      search,
      sort: sort as OrdersSort,
      statusIds: statusIds as string[],
      customerIds: clientIds as number[],
      productIds,
    },
  });

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam(event.target.value);
  }

  function onSortChange(value: any) {
    setSortParam(value as string);
  }

  function onOrderStatusSelected(orderStatusIds: string[]) {
    setStatusIdsParam(orderStatusIds);
    setPageOffset(0);
  }

  function onCustomerSelected(selectedCustomerId: number[]) {
    setClientIdsParam(selectedCustomerId);
    setPageOffset(0);
  }

  function onProductCategorySelected(selectedProductCategoryIds: string[]) {
    setProductCategoryIds(selectedProductCategoryIds);
    setPageOffset(0);
  }

  function onNextPage() {
    setPageOffset(pageOffset + PAGE_LIMIT);
  }

  function onPreviousPage() {
    setPageOffset(Math.max(pageOffset - PAGE_LIMIT, 0));
  }

  function clearFilters() {
    setSearchParam("");
    setSortParam(OrdersSort.StartedAtDesc);
    setStatusIdsParam(undefined);
    setClientIdsParam(undefined);
    setProductCategoryIds(undefined);
    setPageOffset(0);
  }

  return {
    ordersQueryResult,
    search,
    sort,
    statusIds: statusIds as string[],
    clientIds: clientIds as number[],
    productCategoryIds,
    hasFilters,
    onSearchChange,
    onSortChange,
    onOrderStatusSelected,
    onCustomerSelected,
    onProductCategorySelected,
    onNextPage,
    onPreviousPage,
    clearFilters,
  };
}

import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BillingOverview_PartnerInvoiceFragment,
  useBillingOverviewQuery,
  useBillingPartnersQuery,
} from "../../generated/graphql";
import { useAuthContext } from "../../core/auth-manager/auth-manager-hook";

export function useBilling() {
  const { partnerId: partnerIdRaw } = useParams<"partnerId">();
  const navigate = useNavigate();
  const authContext = useAuthContext();
  const [selectedPartnerInvoice, setSelectedPartnerInvoice] = useState<BillingOverview_PartnerInvoiceFragment | null>(
    null
  );

  const partnersQueryResult = useBillingPartnersQuery({
    fetchPolicy: "network-only",
  });
  let partnerId: number | null = null;
  if (partnerIdRaw) {
    partnerId = parseInt(partnerIdRaw, 10);
  }

  const partners = useMemo(() => partnersQueryResult.data?.partners.nodes ?? [], [partnersQueryResult]);

  const billingOverviewQueryResult = useBillingOverviewQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId: partnerId ?? 0,
    },
    skip: !partnerId,
  });

  const partner = billingOverviewQueryResult.data?.partner ?? null;
  const partnerInvoices = partner?.partnerInvoices.nodes ?? [];

  useEffect(() => {
    if (partners.length > 0 && !partnerId) {
      navigate(`/billing/${partners[0].id}`);
    }
  }, [partners, partnerId, navigate]);

  function handlePartnerChange(e: React.ChangeEvent<HTMLSelectElement>) {
    navigate(`/billing/${e.target.value}`);
  }

  function handlePaymentMethodAdded() {
    // no-op
  }

  function handlePaymentMethodRemoved() {
    // no-op
  }

  function handleDownloadPdf(partnerInvoiceId: number) {
    window.open(
      `${process.env.REACT_APP_API_BASE}/files/partners/${partnerId}/invoices/${partnerInvoiceId}?timezone="${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }"`,
      "_blank"
    );
  }

  function openRecordManualPayment(partnerInvoice: BillingOverview_PartnerInvoiceFragment) {
    setSelectedPartnerInvoice(partnerInvoice);
  }

  async function closeRecordManualPayment(refetch?: boolean) {
    setSelectedPartnerInvoice(null);
    if (refetch) {
      billingOverviewQueryResult.refetch();
    }
  }

  return {
    overviewLoading: billingOverviewQueryResult.loading,
    partnerId,
    partners,
    partner,
    handlePartnerChange,
    isPartnerInvoicesLoading: billingOverviewQueryResult.loading,
    partnerInvoices,
    handlePaymentMethodAdded,
    handlePaymentMethodRemoved,
    handleDownloadPdf,
    closeRecordManualPayment,
    openRecordManualPayment,
    selectedPartnerInvoice,
    isAdmin: authContext.isAdmin(),
  };
}

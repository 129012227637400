import { SelectProps, Select } from "@chakra-ui/react";
import { useCustomerKeywordSelectorQuery } from "../../generated/graphql";

interface Props extends SelectProps {
  customerId: number;
  allowNull?: boolean;
  keywordTypes: "primary" | "halo";
  includeInactive?: boolean;
  selectedCustomerKeywordId: number | null;
  onSelectedCustomerKeywordChange: (value: number | null) => void;
  nullText?: string;
}

export function CustomerKeywordSelector(props: Props) {
  const {
    customerId,
    allowNull,
    selectedCustomerKeywordId,
    onSelectedCustomerKeywordChange,
    keywordTypes,
    includeInactive,
    nullText,
    ...rest
  } = props;
  const queryResult = useCustomerKeywordSelectorQuery({
    fetchPolicy: "network-only",
    variables: {
      customerId,
      includeInactive,
      isPrimary: keywordTypes === undefined ? undefined : keywordTypes === "primary" ? true : false,
    },
  });

  if (queryResult.loading) {
    return <Select {...rest} placeholder="Loading..." isDisabled />;
  }

  const keywords = queryResult.data?.customer?.customerKeywords.nodes ?? [];

  return (
    <Select
      value={selectedCustomerKeywordId?.toString() ?? ""}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === "") {
          onSelectedCustomerKeywordChange(null);
        } else {
          onSelectedCustomerKeywordChange(parseInt(event.target.value));
        }
      }}
      {...rest}
    >
      {(allowNull || (!allowNull && !selectedCustomerKeywordId)) && (
        <option value="" disabled={!allowNull}>
          {nullText ?? "Not Selected"}
        </option>
      )}
      {keywords.map((keyword) => (
        <option key={keyword.id} value={keyword.id.toString()}>
          {keyword.displayName}
        </option>
      ))}
    </Select>
  );
}

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
  SimpleGrid,
  Stack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Textarea,
  useToast,
  FormHelperText,
  RadioGroup,
  Radio,
  InputRightElement,
} from "@chakra-ui/react";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import { useState } from "react";
import DatePicker from "react-datepicker";
import * as yup from "yup";

import { LinkLocationStatusSelector } from "../../common/link-location-status-selector";
import { LinkLocationTypeSelector } from "../../common/link-location-type-selector";
import AppIcon from "../../components/app-icon";
import { PrimaryButton, PrimaryLinkButton, SecondaryButton } from "../../components/buttons";
import { DATE_PICKER_FORMAT } from "../../constants/constants";
import { AppColors } from "../../core/custom-theme";
import {
  useCreateLinkLocationMutation,
  useCreatePublisherLinkLocationMutation,
  useSaveLinkLocationCredentialMutation,
} from "../../generated/graphql";
import { handleFormGraphQLError } from "../../util/error-helper";
import { LINK_LOCATION_TYPES } from "../../constants/link-location-type";
import { CategoryMultiSelector } from "../category-selector";
import { PublisherSelector } from "../publisher-selector";
import { AddPublisherDrawer } from "../add-publisher-drawer";

interface Props {
  isOpen: boolean;
  onAdded: () => void;
  onClose: () => void;
  publisherId?: number;
}

interface FormValues {
  domain: string;
  domainAuthority: number;
  statusId: string;
  typeId: string;
  categoryIds?: number[];
  notes?: string;
  minimumWordCount?: number;
  cost?: number;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  allowsOnlyOneLinkInContent?: boolean;
  allowsCbdContent?: boolean;
  spam?: number | null;
  createdAt?: Date;
  authorityScore?: number | null;
  internalQuality?: number | null;
  monthlyTraffic?: number | null;
  isRCMPublishing: boolean;
  adminUrl?: string;
  user?: string;
  password?: string;
  instructions?: string;
  publisherId?: number;
}

const requiredIfRCMPublishing = yup.string().when("isRCMPublishing", {
  is: true,
  then: yup.string().required(),
  otherwise: yup.string().notRequired(),
});

const createLinkLocationSchema = yup.object().shape({
  domain: yup.string().label("Domain").required().nullable(false),
  domainAuthority: yup.number().label("Domain Authority").required().nullable(false),
  statusId: yup.string().label("Status").required().nullable(false),
  typeId: yup.string().label("Type").required().nullable(false),
  categoryIds: yup.array().of(yup.number().required().label("Category ID")).notRequired().nullable(true),
  notes: yup.string().label("Notes").notRequired().nullable(true),
  minimumWordCount: yup.number().label("Minimum Word Count").notRequired().nullable(true),
  cost: yup.number().label("Cost").notRequired().nullable(false).min(0),
  contactName: yup.string().label("Contact Name").notRequired().nullable(true),
  contactEmail: yup.string().label("Contact Email").notRequired().nullable(true),
  allowsOnlyOneLinkInContent: yup.bool().label("Allows Only One Link In Content").notRequired().nullable(true),
  allowsCbdContent: yup.bool().label("Allows CBD Content").notRequired().nullable(true),
  spam: yup.number().label("Spam Score").notRequired().nullable(true),
  authorityScore: yup.number().label("Authority Score").notRequired().nullable(true),
  internalQuality: yup.number().label("Internal Quality").notRequired().nullable(true),
  monthlyTraffic: yup.number().label("Monthly Traffic").notRequired().nullable(true),
  contactPhone: yup
    .string()
    .label("Contact Phone")
    .transform((value) => (value ? value.replace(/[^\d]/g, "") : value))
    .min(10)
    .notRequired()
    .nullable(true),
  isRCMPublishing: yup.boolean().label("Is RCM Publishing").notRequired().default(false).nullable(false),
  adminUrl: requiredIfRCMPublishing,
  user: requiredIfRCMPublishing,
  password: requiredIfRCMPublishing,
  instructions: yup.string().label("Instructions").notRequired().nullable(true),
  publisherId: yup.number().label("Publisher").notRequired().nullable(true),
  createdAt: yup.date().label("Created At").notRequired().nullable(false),
});

export function AddLinkLocationModal(props: Props) {
  const { isOpen, onAdded, onClose, publisherId } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [showAddPublisher, setShowAddPublisher] = useState(false);
  const [refetchPublisher, setRefetchPublisher] = useState(false);
  const [createLinkLocation, { loading }] = useCreateLinkLocationMutation();
  const [createPublisherLinkLocation, { loading: publisherLinkLoading }] = useCreatePublisherLinkLocationMutation();
  const [saveLinkLocationCredential, { loading: credentialLoading }] = useSaveLinkLocationCredentialMutation();
  const toast = useToast();

  const formik = useFormik<FormValues>({
    initialValues: {
      domain: "",
      domainAuthority: 0,
      statusId: "",
      typeId: publisherId ? LINK_LOCATION_TYPES.OUTREACH.id : "",
      categoryIds: [],
      notes: undefined,
      minimumWordCount: undefined,
      cost: undefined,
      contactName: undefined,
      contactEmail: undefined,
      contactPhone: undefined,
      allowsOnlyOneLinkInContent: undefined,
      allowsCbdContent: undefined,
      spam: undefined,
      authorityScore: undefined,
      internalQuality: undefined,
      monthlyTraffic: undefined,
      isRCMPublishing: false,
      adminUrl: undefined,
      user: undefined,
      password: undefined,
      instructions: undefined,
      publisherId: publisherId ?? undefined,
      createdAt: undefined,
    },
    validationSchema: createLinkLocationSchema,
    onSubmit: async (values, helpers) => {
      try {
        const response = await createLinkLocation({
          variables: {
            input: {
              domain: values.domain,
              domainAuthority: values.domainAuthority,
              statusId: values.statusId,
              typeId: values.typeId,
              notes: values.notes,
              minimumWordCount: values.minimumWordCount,
              cost: values.cost,
              contactName: values.contactName,
              contactEmail: values.contactEmail,
              contactPhone: values.contactPhone,
              allowsOnlyOneLinkInContent: values.allowsOnlyOneLinkInContent,
              allowsCbdContent: values.allowsCbdContent,
              spam: values.spam,
              createdAt: values.createdAt,
              authorityScore: values.authorityScore,
              internalQuality: values.internalQuality,
              monthlyTraffic: values.monthlyTraffic,
              categoryIds: values.categoryIds,
            },
          },
        });
        if (response?.data?.createLinkLocation.ok) {
          const linkLocationId = response.data.createLinkLocation.linkLocation?.id;
          if (linkLocationId && values.publisherId) {
            const publisherLinkLocationResponse = await createPublisherLinkLocation({
              variables: {
                input: {
                  publisherId: values.publisherId,
                  linkLocationId,
                  isRCMPublishing: values.isRCMPublishing,
                },
              },
            });

            if (publisherLinkLocationResponse.data?.createPublisherLinkLocation.ok) {
              if (values.isRCMPublishing) {
                const linkLocationCredentialResponse = await saveLinkLocationCredential({
                  variables: {
                    input: {
                      linkLocationId,
                      adminUrl: values.adminUrl ?? "",
                      userName: values.user ?? "",
                      password: values.password ?? "",
                      instructions: values.instructions,
                    },
                  },
                });
                if (!linkLocationCredentialResponse.data?.saveLinkLocationCredential.ok) {
                  throw new Error(
                    linkLocationCredentialResponse.data?.saveLinkLocationCredential.error?.message ??
                      "An error occurred while trying to create the link location. Please try again."
                  );
                }
              }
            } else {
              throw new Error(
                publisherLinkLocationResponse.data?.createPublisherLinkLocation.error?.message ??
                  "An error occurred while trying to create the link location. Please try again."
              );
            }
          }

          toast({ title: "Link Location created successfully!", status: "success" });
          helpers.resetForm();
          helpers.setSubmitting(false);
          onAdded();
          onClose();
        } else {
          throw new Error(
            response.data?.createLinkLocation.error?.message ??
              "An error occurred while trying to create the link location. Please try again."
          );
        }
      } catch (e: any) {
        handleFormGraphQLError(e, "Unable to Add Link Location", toast, helpers.setErrors);
        helpers.setSubmitting(false);
      }
    },
  });

  function onStatusSelected(statusId: string) {
    formik.setFieldValue("statusId", statusId);
  }

  function onTypeSelected(typeId: string) {
    formik.setFieldValue("typeId", typeId);
  }

  function onCreatedAtDateChange(date: Date) {
    formik.setFieldValue("createdAt", date ? date : null);
  }

  function onPublisherSelected(publisherId?: number | null) {
    formik.setFieldValue("publisherId", publisherId ?? undefined);
  }

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  function onPasswordClick() {
    setShowPassword(!showPassword);
  }

  function onClickAddPublisher() {
    setShowAddPublisher(true);
  }

  function onCloseAddPublisher() {
    setShowAddPublisher(false);
  }

  function onPublisherAdded() {
    setRefetchPublisher(true);
    setShowAddPublisher(false);
  }

  function onPublisherRefetchDone() {
    setRefetchPublisher(false);
  }

  return (
    <>
      <AddPublisherDrawer isOpen={showAddPublisher} onClose={onCloseAddPublisher} onAdded={onPublisherAdded} />
      <form onSubmit={formik.handleSubmit} noValidate>
        <Modal onClose={handleCancel} isOpen={isOpen} size="xl" closeOnEsc={false} closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Link Location</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={4}>
                <FormControl isRequired isInvalid={!!formik.errors.domain && !!formik.touched.domain}>
                  <FormLabel>Domain</FormLabel>
                  <Input autoFocus variant="outline" type="input" id="domain" onChange={formik.handleChange} />
                  {formik.touched.domain && formik.errors.domain && (
                    <FormErrorMessage>{formik.errors.domain}</FormErrorMessage>
                  )}
                  <FormHelperText>{`Don't include https:// or www. to domain name`}</FormHelperText>
                </FormControl>
                <FormControl isRequired isInvalid={!!formik.touched.domainAuthority && !!formik.errors.domainAuthority}>
                  <FormLabel>Domain Authority</FormLabel>
                  <Input
                    variant="outline"
                    value={formik.values.domainAuthority ?? undefined}
                    name="domainAuthority"
                    type="number"
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>{formik.errors.domainAuthority}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={!!formik.touched.statusId && !!formik.errors.statusId}>
                  <FormLabel>Status</FormLabel>
                  <LinkLocationStatusSelector
                    onStatusSelected={onStatusSelected}
                    selectedStatusId={formik.values.statusId}
                    emptyValueLabel="None"
                    dontUsePortal
                  />
                  <FormErrorMessage>{formik.errors.statusId}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={!!formik.touched.typeId && !!formik.errors.typeId}>
                  <FormLabel>Type</FormLabel>
                  <LinkLocationTypeSelector
                    onTypeSelected={onTypeSelected}
                    selectedTypeId={formik.values.typeId}
                    emptyValueLabel="None"
                    dontUsePortal
                  />
                  <FormErrorMessage>{formik.errors.typeId}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.touched.typeId && !!formik.errors.typeId}>
                  <FormLabel>Categories</FormLabel>
                  <CategoryMultiSelector
                    selectedCategoryIds={formik.values.categoryIds ?? []}
                    onCategorySelected={(categoryIds) => formik.setFieldValue("categoryIds", categoryIds)}
                    emptyValueLabel="No Category"
                    dontUsePortal
                  />
                  <FormErrorMessage>{formik.errors.categoryIds}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.touched.notes && !!formik.errors.notes}>
                  <FormLabel>Notes</FormLabel>
                  <Textarea
                    id="notes"
                    value={formik.values.notes ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    resize="none"
                    placeholder=""
                    variant="outline"
                  />
                  <FormErrorMessage>{formik.errors.notes}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.errors.minimumWordCount && !!formik.touched.minimumWordCount}>
                  <FormLabel>Minimum Word Count</FormLabel>
                  <Input
                    type="number"
                    variant="outline"
                    value={formik.values.minimumWordCount}
                    id="minimumWordCount"
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>{formik.errors.minimumWordCount}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.touched.spam && !!formik.errors.spam}>
                  <FormLabel>Spam</FormLabel>
                  <Input
                    variant="outline"
                    value={formik.values.spam ?? undefined}
                    name="spam"
                    type="number"
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>{formik.errors.spam}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.touched.authorityScore && !!formik.errors.authorityScore}>
                  <FormLabel>Authority Score</FormLabel>
                  <Input
                    variant="outline"
                    value={formik.values.authorityScore ?? undefined}
                    name="authorityScore"
                    type="number"
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>{formik.errors.authorityScore}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.touched.internalQuality && !!formik.errors.internalQuality}>
                  <FormLabel>Internal Quality</FormLabel>
                  <Input
                    variant="outline"
                    value={formik.values.internalQuality ?? undefined}
                    name="internalQuality"
                    type="number"
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>{formik.errors.internalQuality}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.touched.monthlyTraffic && !!formik.errors.monthlyTraffic}>
                  <FormLabel>Monthly Traffic</FormLabel>
                  <Input
                    variant="outline"
                    value={formik.values.monthlyTraffic ?? undefined}
                    name="monthlyTraffic"
                    type="number"
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>{formik.errors.monthlyTraffic}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.errors.cost && !!formik.touched.cost}>
                  <FormLabel>Cost</FormLabel>
                  <InputGroup>
                    <InputLeftElement color={AppColors.textGray}>
                      <AppIcon icon={faDollarSign} />
                    </InputLeftElement>
                    <Input
                      placeholder="Enter amount"
                      id="cost"
                      type="number"
                      value={formik.values.cost}
                      onChange={formik.handleChange}
                    />
                  </InputGroup>
                  <FormErrorMessage>{formik.errors.cost}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.errors.contactName && !!formik.touched.contactName}>
                  <FormLabel>Contact Name</FormLabel>
                  <Input variant="outline" type="input" id="contactName" onChange={formik.handleChange} />
                  <FormErrorMessage>{formik.errors.contactName}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.errors.contactEmail && !!formik.touched.contactEmail}>
                  <FormLabel>Contact Email</FormLabel>
                  <Input variant="outline" type="input" id="contactEmail" onChange={formik.handleChange} />
                  <FormErrorMessage>{formik.errors.contactEmail}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.errors.contactPhone && !!formik.touched.contactPhone}>
                  <FormLabel>Contact Phone</FormLabel>
                  <Input variant="outline" type="input" id="contactPhone" onChange={formik.handleChange} />
                  <FormErrorMessage>{formik.errors.contactPhone}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!formik.errors.allowsOnlyOneLinkInContent && !!formik.touched.allowsOnlyOneLinkInContent}
                >
                  <FormLabel>Allows Only One Link In Content</FormLabel>
                  <Switch
                    id="allowsOnlyOneLinkInContent"
                    isChecked={formik.values.allowsOnlyOneLinkInContent}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>{formik.errors.allowsOnlyOneLinkInContent}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.errors.allowsCbdContent && !!formik.touched.allowsCbdContent}>
                  <FormLabel>Allows CBD Content</FormLabel>
                  <Switch
                    id="allowsCbdContent"
                    isChecked={formik.values.allowsCbdContent}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>{formik.errors.allowsCbdContent}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!formik.errors.publisherId} isDisabled={!!publisherId}>
                  <FormLabel>Publisher</FormLabel>
                  <PublisherSelector
                    onPublisherSelected={onPublisherSelected}
                    selectedPublisherId={formik.values.publisherId}
                    allowNull={true}
                    isDisabled={!!publisherId}
                    refetch={refetchPublisher}
                    onRefechDone={onPublisherRefetchDone}
                  />
                  <FormHelperText>
                    <PrimaryLinkButton onClick={onClickAddPublisher} isDisabled={!!publisherId}>
                      Add Publisher
                    </PrimaryLinkButton>
                  </FormHelperText>
                </FormControl>
                {!!formik.values.publisherId && (
                  <>
                    <FormControl isRequired isInvalid={!!formik.errors.isRCMPublishing}>
                      <FormLabel>Is RedCanyonMedia publishing onsite blogs?</FormLabel>
                      <RadioGroup
                        id="isRCMPublishing"
                        value={formik.values.isRCMPublishing ? "yes" : "no"}
                        onChange={(value) => {
                          formik.setFieldValue("isRCMPublishing", value === "yes");
                        }}
                      >
                        <Stack spacing={5} isInline>
                          <Radio value="yes">Yes</Radio>
                          <Radio value="no">No</Radio>
                        </Stack>
                      </RadioGroup>
                      <FormErrorMessage>{formik.errors.isRCMPublishing}</FormErrorMessage>
                    </FormControl>
                    {formik.values.isRCMPublishing && (
                      <>
                        <FormControl isRequired isInvalid={!!formik.errors.adminUrl}>
                          <FormLabel>Admin URL</FormLabel>
                          <Input
                            id="adminUrl"
                            value={formik.values.adminUrl}
                            onChange={formik.handleChange}
                            variant="outline"
                          />
                          <FormErrorMessage>{formik.errors.adminUrl}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!formik.errors.user}>
                          <FormLabel>User Name</FormLabel>
                          <Input
                            id="user"
                            value={formik.values.user}
                            onChange={formik.handleChange}
                            variant="outline"
                          />
                          <FormErrorMessage>{formik.errors.user}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired isInvalid={!!formik.errors.password}>
                          <FormLabel>Password</FormLabel>
                          <InputGroup size="md">
                            <Input
                              id="password"
                              type={showPassword ? "text" : "password"}
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              variant="outline"
                            />
                            <InputRightElement width="4.5rem">
                              <PrimaryLinkButton h="1.75rem" size="sm" onClick={onPasswordClick}>
                                {showPassword ? "Hide" : "Show"}
                              </PrimaryLinkButton>
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!formik.errors.instructions}>
                          <FormLabel>Instructions</FormLabel>
                          <Textarea
                            value={formik.values.instructions}
                            id="instructions"
                            name="instructions"
                            onChange={formik.handleChange}
                            resize="none"
                          />
                          <FormErrorMessage>{formik.errors.instructions}</FormErrorMessage>
                        </FormControl>
                      </>
                    )}
                  </>
                )}
                <FormControl isInvalid={!!formik.errors.createdAt && !!formik.touched.createdAt} zIndex={10}>
                  <FormLabel>Created At</FormLabel>
                  <Box>
                    <DatePicker
                      onChange={onCreatedAtDateChange}
                      selected={formik.values.createdAt}
                      dateFormat={DATE_PICKER_FORMAT}
                      customInput={<Input variant="outline" width="100%" />}
                      disabledKeyboardNavigation
                      placeholderText="Select Created At Date"
                    />
                  </Box>
                  <FormErrorMessage>{formik.errors.createdAt}</FormErrorMessage>
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <SimpleGrid columns={2} gridGap={4}>
                <PrimaryButton
                  width="100%"
                  type="submit"
                  isDisabled={formik.isSubmitting}
                  isLoading={formik.isSubmitting || loading || publisherLinkLoading || credentialLoading}
                  onClick={formik.submitForm}
                >
                  Save
                </PrimaryButton>
                <SecondaryButton width="100%" onClick={handleCancel}>
                  Cancel
                </SecondaryButton>
              </SimpleGrid>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </form>
    </>
  );
}

import {
  Checkbox,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

import { PartnerPaymentMethodSelector } from "../../common/partner-payment-method-selector";
import { CancelButton, PrimaryButton } from "../../components/buttons";
import { DataDisplayRow } from "../../components/data-display-row";
import { FormattedDate } from "../../components/formatted-date";
import { PanelBox } from "../../components/panels";
import { Table, TableBody } from "../../components/table";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { AppColors } from "../../core/custom-theme";
import { currencyFormatter } from "../../util/formatter";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isProcessing: boolean;
  partnerId: number;
  onSelectedPartnerPaymentMethodChanged: (value: number | null) => void;
  selectedPartnerPaymentMethodId: number | null;
  isTermsAccepted: boolean;
  onTermsAcceptedChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dueImmediatelyAmount: number;
  nextBillingDate: Date | null;
  nextBillingAmount: number | null;
}

export function AutomaticPaymentsConfirmModal(props: Props) {
  const {
    isOpen,
    onClose,
    onConfirm,
    isProcessing,
    onSelectedPartnerPaymentMethodChanged,
    partnerId,
    selectedPartnerPaymentMethodId,
    isTermsAccepted,
    onTermsAcceptedChange,
    dueImmediatelyAmount,
    nextBillingAmount,
    nextBillingDate,
  } = props;
  return (
    <Modal onClose={onClose} isOpen={isOpen} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enable Automatic Payments</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Payment Method</FormLabel>
              <PartnerPaymentMethodSelector
                onPartnerPaymentSelected={onSelectedPartnerPaymentMethodChanged}
                partnerId={partnerId}
                selectedPartnerPaymentMethodId={selectedPartnerPaymentMethodId}
                allowAddNewPaymentMethod
              />
            </FormControl>
            <PanelBox containsTable>
              <Table isEmpty={false} isLoading={false}>
                <TableBody>
                  <DataDisplayRow
                    label="To Be Paid Immediately"
                    value={currencyFormatter.format(dueImmediatelyAmount)}
                  />
                  <DataDisplayRow
                    label="Next Billing Date"
                    value={nextBillingDate ? <FormattedDate format={DATE_MONTH_FORMAT} date={nextBillingDate} /> : "-"}
                  />
                  <DataDisplayRow
                    label="Next Billing Amount"
                    value={nextBillingAmount ? currencyFormatter.format(nextBillingAmount) : "-"}
                  />
                </TableBody>
              </Table>
            </PanelBox>
            <Text fontSize="sm" color={AppColors.textGray}>
              Your next billing cycle date and amount are subject to change with the addition of any new clients and
              orders.
            </Text>
            {/* TODO: link */}
            {!!selectedPartnerPaymentMethodId && (
              <FormControl>
                <Checkbox isChecked={isTermsAccepted} onChange={onTermsAcceptedChange}>
                  I authorize Red Canyon Media to automatically process charges using the selected payment method in
                  accordance with the Red Canyon Media Terms and Conditions.
                </Checkbox>
              </FormControl>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack spacing={2} isInline>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <PrimaryButton
              onClick={onConfirm}
              isLoading={isProcessing}
              isDisabled={!isTermsAccepted || !selectedPartnerPaymentMethodId}
            >
              Confirm
            </PrimaryButton>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

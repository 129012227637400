import { lazy } from "react";
import { Route, Navigate, useParams, Routes } from "react-router-dom";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PublisherDetailsSecondaryNav } from "./publisher-details-secondary-nav";
import { AdminPublisherOverview } from "../admin-publisher-overview";
const AdminPublisherReport = lazy(() => import("../admin-publisher-report"));
const AdminPublisherUsers = lazy(() => import("../admin-publisher-users"));
const AdminPublisherEmails = lazy(() => import("../admin-publisher-emails"));

export function AdminPublisherDetailsRoute() {
  const { id, "*": section } = useParams<"id" | "*">();
  const publisherId = parseInt(id ?? "");
  if (!section || section === "") {
    return <Navigate to={`/outreach/publishers/${id}/overview`} />;
  }

  return (
    <LayoutBase pageTitle="Publisher Details">
      <MainNav hasSecondaryNav />
      <PublisherDetailsSecondaryNav publisherId={publisherId} activeSection={section} />
      <Container centerHorizontally>
        <Routes>
          <Route path="overview" element={<AdminPublisherOverview />} />
          <Route path="report" element={<AdminPublisherReport />} />
          <Route path="users" element={<AdminPublisherUsers />} />
          <Route path="emails" element={<AdminPublisherEmails />} />
        </Routes>
      </Container>
    </LayoutBase>
  );
}

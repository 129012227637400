import * as yup from "yup";
import {
  useCustomerDetailsOverviewQuery,
  useSaveCustomerQuestionnaireMutation,
  useUpdateCustomerMutation,
} from "../../generated/graphql";
import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import { handleFormGraphQLError } from "../../util/error-helper";
import { useClientDetailsQuestionnaire } from "./use-client-questionnaire";
import { FormHelpers } from "../../util/form-helpers";

interface DetailsFormValues {
  website: string | null;
  gbpUrl?: string | null;
  categoryId: number | null;
  customerMarketSize: string;
  businessDescription: string;
  productsOffered: string;
  serviceArea: string;
}

export function useClientDetailsOverview(customerId: number) {
  const customerOverviewQueryResult = useCustomerDetailsOverviewQuery({
    variables: {
      id: customerId,
    },
  });
  const {
    customerQuestionnaire,
    refetch: customerQuestionnaireRefetch,
    isQuestionnaireLoading,
  } = useClientDetailsQuestionnaire(customerId);
  const [updateCustomerMutation] = useUpdateCustomerMutation();
  const [saveCustomerQuestionnaireMutation] = useSaveCustomerQuestionnaireMutation();
  const toast = useToast();

  const customer = customerOverviewQueryResult.data?.customer ?? null;
  let website = customer?.website ?? null;
  if (website?.[website?.length - 1] === "/") {
    website = website.slice(0, website?.length - 1);
  }

  let gbpUrl = customer?.gbpUrl ?? null;
  if (gbpUrl?.[gbpUrl?.length - 1] === "/") {
    gbpUrl = gbpUrl.slice(0, gbpUrl?.length - 1);
  }

  const detailsFormik = useFormik<DetailsFormValues>({
    initialValues: {
      categoryId: customer?.category?.id ?? null,
      website: website,
      gbpUrl: gbpUrl,
      businessDescription: customerQuestionnaire?.businessDescription ?? "",
      customerMarketSize: customerQuestionnaire?.customerMarketSize ?? "",
      productsOffered: customerQuestionnaire?.productsOffered ?? "",
      serviceArea: customerQuestionnaire?.serviceArea ?? "",
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: yup.object().shape({
      website: yup.string().label("Company URL").required().nullable(false).url(),
      gbpUrl: yup.string().url().label("Gbp Url").notRequired().nullable(true),
      categoryId: yup
        .number()
        .label("Business Category")
        .required()
        .min(1, "Business Category is a required field")
        .nullable(false),
      businessDescription: yup.string().label("Business Description").required(),
      customerMarketSize: yup.string().label("Customer Market Size").required(),
      productsOffered: yup.string().label("Products Offered").required(),
      serviceArea: yup.string().label("Service Area").required(),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        const response = await updateCustomerMutation({
          variables: {
            input: {
              customerId,
              categoryId: values.categoryId ?? undefined,
              website: values.website !== "" ? values.website : null,
              gbpUrl: values.gbpUrl,
            },
          },
        });

        if (response.data?.updateCustomer.customer?.id) {
          await customerOverviewQueryResult.refetch();
        }

        const questionnaireResponse = await saveCustomerQuestionnaireMutation({
          variables: {
            input: {
              customerId,
              businessDescription: FormHelpers.processNullableString(values.businessDescription),
              customerMarketSize: FormHelpers.processNullableString(values.customerMarketSize),
              productsOffered: FormHelpers.processNullableString(values.productsOffered),
              serviceArea: FormHelpers.processNullableString(values.serviceArea),
            },
          },
        });

        if (questionnaireResponse.data?.saveCustomerQuestionnaire.customerQuestionnaire.id) {
          await customerQuestionnaireRefetch();
        }

        if (
          response.data?.updateCustomer.customer?.id &&
          questionnaireResponse.data?.saveCustomerQuestionnaire.customerQuestionnaire.id
        ) {
          toast({ title: "Client Updated", description: "Your updates were saved.", status: "success" });
        }
      } catch (e: any) {
        handleFormGraphQLError(e, "Unable to Save Updates", toast, formikHelpers.setErrors);
      }
    },
  });

  const scheduledStatusChange = customer?.customerStatusChanges.nodes.find((x) => new Date(x.effectiveAt) > new Date());

  return {
    isOverviewLoading: customerOverviewQueryResult.loading || isQuestionnaireLoading,
    scheduledStatusChange,
    detailsFormik,
  };
}

import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  useToast,
  FormErrorMessage,
  Input,
  Box,
  InputGroup,
  InputLeftElement,
  FormHelperText,
  SimpleGrid,
} from "@chakra-ui/react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import DatePicker from "react-datepicker";
import { PrimaryButton, CancelButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useUpdatePartnerProductMutation, useEditPartnerProductQuery } from "../../generated/graphql";
import { BaseProductSelector } from "../base-product-selector";
import { HelpText } from "../../components/help-text";
import { VendorSelector } from "../vendor-selector";
import { ChecklistDefinitionSelector } from "../checklist-definition-selector";
import { Loader } from "../../components/loader";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import AppIcon from "../../components/app-icon";
import { AppColors } from "../../core/custom-theme";
import { DATE_PICKER_FORMAT } from "../../constants/constants";

interface Props {
  partnerProductId: number;
  isOpen: boolean;
  onClose: () => void;
  onEdited: (partnerProductId: number) => void;
}

interface FormValues {
  name: string;
  activeAt?: Date;
  inactiveAt?: Date;
  vendorId?: number;
  checklistDefinitionId?: number;
}

const updatePartnerProductSchema = yup.object().shape({
  name: yup.string().label("Name").required(),
  activeAt: yup.date().label("Active At"),
  inactiveAt: yup.date().label("In Active At").nullable(true),
  vendorId: yup.number().label("Vendor ID"),
  checklistDefinitionId: yup.number().label("Checklist Definition ID"),
});

export function EditPartnerProductDrawer(props: Props) {
  const { onClose, onEdited, isOpen, partnerProductId } = props;
  const [updatePartnerProduct] = useUpdatePartnerProductMutation();
  const toast = useToast();
  const { data, loading } = useEditPartnerProductQuery({
    variables: {
      id: partnerProductId,
    },
    fetchPolicy: "cache-and-network",
    skip: !partnerProductId,
  });

  const partnerProduct = data?.partnerProduct;
  const baseProduct = partnerProduct?.product;
  const isActiveAtDisabled = !!partnerProduct?.activeAt && partnerProduct.activeAt < new Date();

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await updatePartnerProduct({
        variables: {
          input: {
            partnerProductId,
            name: values.name,
            activeAt: isActiveAtDisabled ? undefined : values.activeAt ?? new Date(),
            inactiveAt: values.inactiveAt ? values.inactiveAt : null,
            vendorId: values.vendorId,
            checklistDefinitionId: values.checklistDefinitionId,
          },
        },
      });

      if (response.data?.updatePartnerProduct?.partnerProduct?.id) {
        toast({
          title: "Product Type updated.",
          description: "Product Type was updated successfully.",
          status: "success",
        });
        onEdited(response.data?.updatePartnerProduct.partnerProduct?.id);
      }
      onClose();
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      name: partnerProduct?.name ?? "",
      activeAt: partnerProduct?.activeAt ?? new Date(),
      inactiveAt: partnerProduct?.inactiveAt ? partnerProduct.inactiveAt : undefined,
      vendorId: partnerProduct?.vendorId ?? undefined,
      checklistDefinitionId: partnerProduct?.checklistDefinitionId ?? undefined,
    },
    enableReinitialize: true,
    validationSchema: updatePartnerProductSchema,
    onSubmit: handleSubmit,
  });

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  function onActiveDateChange(date: Date) {
    formik.setFieldValue("activeAt", date ?? new Date());
  }

  function onInActiveDateChange(date: Date) {
    formik.setFieldValue("inactiveAt", date ?? null);
  }

  function onVendorSelected(vendorId: number | null) {
    formik.setFieldValue("vendorId", vendorId);
  }

  function onChecklistDefinitionSelected(checklistDefinitionId: number | null) {
    formik.setFieldValue("checklistDefinitionId", checklistDefinitionId);
  }

  return (
    <>
      {loading && <Loader type="content" />}
      <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="md" closeOnOverlayClick={false}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Edit Product Type</DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Base Product Type</FormLabel>
                <BaseProductSelector
                  partnerId={partnerProduct?.partnerId ?? 0}
                  variant="outline"
                  selectedBaseProductId={data?.partnerProduct?.product.id}
                  isDisabled
                />
              </FormControl>
              <FormControl isRequired isInvalid={!!formik.errors.name && !!formik.touched.name}>
                <FormLabel>Name</FormLabel>
                <Input
                  variant="outline"
                  value={formik.values.name}
                  id="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!formik.errors.activeAt && !!formik.touched.activeAt}>
                <FormLabel>Active</FormLabel>
                <Box mt={2}>
                  <DatePicker
                    onChange={onActiveDateChange}
                    selected={formik.values.activeAt}
                    minDate={new Date()}
                    maxDate={isActiveAtDisabled ? new Date() : undefined}
                    dateFormat={DATE_PICKER_FORMAT}
                    customInput={<Input variant="outline" width="100%" />}
                    placeholderText="Select active date"
                    disabledKeyboardNavigation
                    disabled={isActiveAtDisabled}
                  />
                  {isActiveAtDisabled ? <FormHelperText>The Product is already activated.</FormHelperText> : ""}
                </Box>
                <FormErrorMessage>{formik.errors.activeAt}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!formik.errors.inactiveAt && !!formik.touched.inactiveAt}>
                <FormLabel>Inactive</FormLabel>
                <Box>
                  <DatePicker
                    onChange={onInActiveDateChange}
                    selected={formik.values.inactiveAt}
                    minDate={formik.values.activeAt ?? new Date()}
                    isClearable
                    dateFormat={DATE_PICKER_FORMAT}
                    customInput={<Input variant="outline" width="100%" />}
                    placeholderText="Select inactive date (optional)"
                    disabledKeyboardNavigation
                  />
                </Box>
                <FormErrorMessage>{formik.errors.inactiveAt}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>
                  Wholesale Price
                  <HelpText standardLeftMargin>The amount Red Canyon Media charges for the base product type.</HelpText>
                </FormLabel>
                <InputGroup>
                  <InputLeftElement color={AppColors.textGray}>
                    <AppIcon icon={faDollarSign} />
                  </InputLeftElement>
                  <Input variant="outline" type="number" value={partnerProduct?.wholesalePrice ?? 0} isDisabled />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>
                  Retail Price
                  <HelpText standardLeftMargin>
                    The amount that is charged to the client when this product type is ordered.
                  </HelpText>
                </FormLabel>
                <InputGroup>
                  <InputLeftElement color={AppColors.textGray}>
                    <AppIcon icon={faDollarSign} />
                  </InputLeftElement>
                  <Input
                    variant="outline"
                    type="number"
                    value={partnerProduct?.currentPartnerProductPrice?.price ?? 0}
                    isDisabled
                  />
                </InputGroup>
              </FormControl>
              {baseProduct?.isVendorRequired && (
                <FormControl isInvalid={!!formik.errors.vendorId && !!formik.touched.vendorId}>
                  <FormLabel>Vendor</FormLabel>
                  <VendorSelector onVendorSelected={onVendorSelected} selectedVendorId={formik.values.vendorId} />
                  <FormErrorMessage>{formik.errors.vendorId}</FormErrorMessage>
                </FormControl>
              )}
              {baseProduct?.isChecklistRequired && (
                <FormControl
                  isInvalid={!!formik.errors.checklistDefinitionId && !!formik.touched.checklistDefinitionId}
                >
                  <FormLabel>
                    Checklist{" "}
                    <HelpText>
                      A checklist allows you to add the sub-level tasks associated with knowing a task is done (ex. H1
                      tag, H2 tag, focus keyword)
                    </HelpText>
                  </FormLabel>
                  <ChecklistDefinitionSelector
                    onChecklistDefinitionSelected={onChecklistDefinitionSelected}
                    selectedChecklistDefinitionId={formik.values.checklistDefinitionId}
                  />
                  <FormErrorMessage>{formik.errors.checklistDefinitionId}</FormErrorMessage>
                </FormControl>
              )}
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <SimpleGrid columns={2} gridGap={2} width="100%">
              <PrimaryButton width="100%" onClick={formik.submitForm}>
                Save
              </PrimaryButton>
              <CancelButton onClick={onClose}>Cancel</CancelButton>
            </SimpleGrid>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

import { Box, Checkbox, FormControl, Heading, Stack, Text } from "@chakra-ui/react";

import { PartnerPaymentMethodSelector } from "../../common/partner-payment-method-selector";
import { PrimaryButton, PrimaryLinkButton } from "../../components/buttons";
import { DataDisplayRow } from "../../components/data-display-row";
import { FormattedDate } from "../../components/formatted-date";
import { PanelBottom, PanelBox, PanelGroup, PanelTop } from "../../components/panels";
import { Table, TableBody, TableCell, TableFoot, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { AppColors } from "../../core/custom-theme";
import { currencyFormatter } from "../../util/formatter";
import { useBillingPay } from "./billing-pay-hook";

interface Props {
  useBillingPayValue: ReturnType<typeof useBillingPay>;
}

export function BillingPayReview(props: Props) {
  const {
    selectedPartnerPaymentMethodId,
    handlePartnerPaymentMethodChange,
    reviewItems,
    totalAmount,
    isPaymentProcesing,
    handleSubmitPayment,
    handleSummaryAcceptedChange,
    isSummaryAccepted,
    partnerId,
    handleChangeSelectedOrders,
  } = props.useBillingPayValue;
  return (
    <Stack spacing={4}>
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Stack>
            <Heading size="md">Selected Orders</Heading>
            <Box>
              <PrimaryLinkButton onClick={handleChangeSelectedOrders}>Change Selected Orders</PrimaryLinkButton>
            </Box>
          </Stack>
        </PanelTop>
        <PanelBottom>
          <Stack spacing={4}>
            {reviewItems.map((reviewItem) => (
              <Box key={reviewItem.customerId}>
                <Heading size="sm" marginBottom={2}>
                  {reviewItem.customer?.name ?? "Client"} (ID: {reviewItem.customerId})
                </Heading>
                <PanelBox containsTable>
                  <Table isEmpty={false} isLoading={false}>
                    <TableHead>
                      <TableRow>
                        <TableHeaderCell>Order ID</TableHeaderCell>
                        <TableHeaderCell>Start Date</TableHeaderCell>
                        <TableHeaderCell>Product</TableHeaderCell>
                        <TableHeaderCell>Total Wholesale Amount</TableHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reviewItem.orders.map((order) => (
                        <TableRow key={order.id}>
                          <TableCell>{order.id}</TableCell>
                          <TableCell>
                            <FormattedDate format={DATE_MONTH_FORMAT} date={order.startDate} />
                          </TableCell>
                          <TableCell>
                            <Text>{order.partnerProduct.name ?? "-"}</Text>
                            {order.activeOrderContentStrategy?.topic && (
                              <Text fontSize="sm" color={AppColors.textGray}>
                                {order.activeOrderContentStrategy?.topic}
                              </Text>
                            )}
                          </TableCell>
                          <TableCell>{currencyFormatter.format(order.totalWholesaleAmount)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFoot>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <strong>Client Total</strong>
                        </TableCell>
                        <TableCell>
                          <strong>{currencyFormatter.format(reviewItem.totalAmount)}</strong>
                        </TableCell>
                      </TableRow>
                    </TableFoot>
                  </Table>
                </PanelBox>
              </Box>
            ))}
          </Stack>
        </PanelBottom>
      </PanelGroup>
      <PanelBox width={{ base: "100%", lg: "50%" }}>
        <Stack spacing={4}>
          <Heading size="md">Payment Method</Heading>
          <FormControl>
            <PartnerPaymentMethodSelector
              onPartnerPaymentSelected={handlePartnerPaymentMethodChange}
              partnerId={partnerId}
              selectedPartnerPaymentMethodId={selectedPartnerPaymentMethodId}
              allowAddNewPaymentMethod
            />
          </FormControl>
          <Heading size="md">Summary</Heading>
          <PanelBox containsTable>
            <Table isEmpty={false} isLoading={false}>
              <TableBody>
                <DataDisplayRow label="Amount to Be Billed" value={currencyFormatter.format(totalAmount)} />
              </TableBody>
            </Table>
          </PanelBox>
          {selectedPartnerPaymentMethodId && (
            <FormControl>
              <Checkbox isChecked={isSummaryAccepted} onChange={handleSummaryAcceptedChange}>
                I agree to the Terms and Conditions and authorize Red Canyon Media to charge{" "}
                <strong>{currencyFormatter.format(totalAmount)}</strong> to the selected payment method immediately.
              </Checkbox>
            </FormControl>
          )}
          <Box textAlign="right">
            <PrimaryButton
              onClick={handleSubmitPayment}
              isLoading={isPaymentProcesing}
              isDisabled={!isSummaryAccepted || !selectedPartnerPaymentMethodId}
            >
              Submit Payment
            </PrimaryButton>
          </Box>
        </Stack>
      </PanelBox>
    </Stack>
  );
}

import { DateTime } from "luxon";
import {
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Skeleton,
  FormErrorMessage,
  Box,
  FormHelperText,
  Textarea,
} from "@chakra-ui/react";

import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { useTaskWorkspaceContentIdeation } from "./use-task-workspace-content-ideation";
import { ContentEditor } from "../../components/content-editor";
import { DangerButton, PrimaryButton } from "../../components/buttons";
import { PageHeading } from "../../components/page-heading";
import { HelpText } from "../../components/help-text";

interface Props {
  taskId: number;
  onAbandon: () => void;
  onComplete: () => void;
}

export function TaskWorkspaceContentIdeation(props: Props) {
  const { onAbandon } = props;
  const { loading, taskId, contentIdeation, formik, lastAutosavedAt, onContentIdeationUpdate, task } =
    useTaskWorkspaceContentIdeation(props);
  return (
    <>
      <PageHeading>Content Ideation Task</PageHeading>
      <Grid templateColumns={{ base: "1fr", xl: "2fr 1fr" }} gridGap={4}>
        <Stack spacing={4} gridColumn={1}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <PanelGroup>
              <PanelTop>
                <Stack spacing={4}>
                  <FormControl isRequired isInvalid={!!formik.touched.idea && !!formik.errors.idea}>
                    <FormLabel>Content Ideation</FormLabel>
                    {loading && <Skeleton height={50} />}
                    <ContentEditor
                      isInvalid={!!formik.touched.idea && !!formik.errors.idea}
                      initialValue={contentIdeation?.idea ?? ""}
                      onChange={(html) => {
                        formik.setFieldValue("idea", html);
                        if (html !== formik.values.idea) {
                          onContentIdeationUpdate(html);
                        }
                      }}
                    />
                    {lastAutosavedAt && (
                      <FormHelperText>Saved {DateTime.fromJSDate(lastAutosavedAt).toRelative()}</FormHelperText>
                    )}
                    <FormErrorMessage>{formik.errors.idea}</FormErrorMessage>
                  </FormControl>
                </Stack>
              </PanelTop>
              <PanelBottom showTopBorder>
                <Stack isInline spacing={2} justifyContent="space-between" shouldWrapChildren>
                  <PrimaryButton type="submit">Complete Task</PrimaryButton>
                  <Box>
                    <DangerButton onClick={onAbandon}>Unassign Task</DangerButton>
                    <HelpText standardLeftMargin>
                      &quot;Unassign Task&quot; will unassign this task from you and return it to the queue.{" "}
                      <strong>This cannot be undone and may result in lost work.</strong>
                    </HelpText>
                  </Box>
                </Stack>
              </PanelBottom>
            </PanelGroup>
          </form>
        </Stack>
        <Stack spacing={4} gridColumn={{ base: 1, xl: 2 }}>
          <PanelGroup isCollapsible>
            <PanelTop showBottomBorder>
              <Heading size="md">Information</Heading>
            </PanelTop>
            <PanelBottom>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Client Instructions</FormLabel>
                  <Textarea value={task?.customer?.instructions ?? "-"} variant="outline" resize="none" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Order Instructions</FormLabel>
                  <Textarea
                    variant="outline"
                    isDisabled
                    value={task?.order?.instructions ?? "-"}
                    resize="none"
                    isReadOnly
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Task ID</FormLabel>
                  <Input value={taskId} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Client</FormLabel>
                  <Input value={task?.customer.name ?? "-"} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Product Type</FormLabel>
                  <Input value={task?.order.partnerProduct.product.name ?? "-"} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Keyword</FormLabel>
                  <Input value={task?.keyword ?? "-"} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Topic</FormLabel>
                  <Input value={task?.order.topic ?? "-"} variant="outline" isReadOnly />
                </FormControl>
              </Stack>
            </PanelBottom>
          </PanelGroup>
        </Stack>
      </Grid>
    </>
  );
}

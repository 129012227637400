import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";

interface Props {
  children: React.ReactNode;
}

export function TaskWorkspaceWrapper(props: Props) {
  const { children } = props;
  return (
    <LayoutBase pageTitle="Task Workspace">
      <MainNav />
      <Container centerHorizontally>{children}</Container>
    </LayoutBase>
  );
}

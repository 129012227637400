import { forwardRef } from "react";
import { SelectProps, Select } from "@chakra-ui/react";
import {
  useProductsQuery,
  ProductForm_PartnerAvailableProductFragment,
  useProductTaskTypesForProductSelectorQuery,
} from "../../generated/graphql";

interface Props extends SelectProps {
  partnerId: number;
  taskTypeId?: string | null;
  selectedBaseProductId?: string | null;
  onBaseProductSelected?: (availableProduct: ProductForm_PartnerAvailableProductFragment | null) => void;
  isClearable?: boolean;
  disableForSystemManaged?: boolean;
}

export const BaseProductSelector = forwardRef<HTMLSelectElement, Props>(function BaseProductSelector(props, ref) {
  const {
    partnerId,
    taskTypeId,
    selectedBaseProductId,
    onBaseProductSelected,
    isClearable,
    disableForSystemManaged,
    ...rest
  } = props;
  const productsQueryResult = useProductsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      partnerId,
    },
  });

  const productTaskTypeQueryResult = useProductTaskTypesForProductSelectorQuery({
    variables: {
      taskTypeIds: taskTypeId ? [taskTypeId] : [],
    },
    skip: !taskTypeId,
    fetchPolicy: "network-only",
  });

  const availableProducts = productsQueryResult.data?.partner?.partnerAvailableProducts ?? [];
  const taskTypeProducts =
    productTaskTypeQueryResult.data?.productTaskTypes.nodes.map((productTaskType) => productTaskType.product.id) ?? [];

  let products = availableProducts;

  // Find intersection of products according to selected task type and available products
  if (taskTypeId) {
    products = [];
    const length = availableProducts?.length ?? 0;
    for (let i = 0; i < length; i++) {
      if (taskTypeProducts.includes(availableProducts[i].product.id)) {
        products.push(availableProducts[i]);
      }
    }
  }
  products = products.slice().sort((productA, productB) => productA.product.name.localeCompare(productB.product.name));

  function onChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { selectedIndex, options } = event.target;
    if (!options[selectedIndex].getAttribute("data-product")) {
      onBaseProductSelected?.(null);
      return;
    }
    onBaseProductSelected?.(JSON.parse(options[selectedIndex].getAttribute("data-product") ?? ""));
  }

  return (
    <Select
      isDisabled={productsQueryResult.loading || productTaskTypeQueryResult.loading || props.isDisabled}
      value={selectedBaseProductId ?? ""}
      onChange={onChange}
      ref={ref}
      {...rest}
    >
      <option value="" disabled={!isClearable}>
        Not Selected
      </option>
      {products.map((product) => (
        <option
          key={product.id}
          value={product.product.id}
          data-product={JSON.stringify(product)}
          disabled={disableForSystemManaged && product.product.isSystemManaged}
        >
          {product.product.name}
        </option>
      ))}
    </Select>
  );
});

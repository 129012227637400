import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useState } from "react";
import { useAuthContext } from "../../../core/auth-manager/auth-manager-hook";
import {
  useOrderDetailsContentStrategyQuery,
  useOrderDetails_UpdateOrderContentStrategyMutation,
} from "../../../generated/graphql";
import { handleGraphQLResponseError } from "../../../util/error-helper";
import { FormHelpers } from "../../../util/form-helpers";

interface Options {
  orderId: number;
}

interface FormValues {
  topic: string | null;
  customerKeywordId: number | null;
  haloKeywordIds: number[];
  purposeOfContent: string | null;
  linkTo: string | null;
  voice: string | null;
  focusPoints: string | null;
  webPage: string | null;
  pageType: string | null;
  sampleContent: string | null;
}

export function useOrderContentStrategy(options: Options) {
  const { orderId } = options;

  const authContext = useAuthContext();
  const isAdmin = authContext.isAdmin();
  const toast = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const [updateOrderContentStrategy] = useOrderDetails_UpdateOrderContentStrategyMutation();
  const queryResult = useOrderDetailsContentStrategyQuery({
    fetchPolicy: "network-only",
    variables: {
      orderId,
    },
    skip: !isAdmin,
  });

  const order = queryResult.data?.order ?? null;
  const orderContentStrategy = order?.activeOrderContentStrategy ?? null;

  const formik = useFormik<FormValues>({
    initialValues: {
      topic: orderContentStrategy?.topic ?? null,
      customerKeywordId: orderContentStrategy?.customerKeywordId ?? null,
      haloKeywordIds: orderContentStrategy?.orderHaloKeywords?.map((x) => x.customerKeyword.id) ?? [],
      purposeOfContent: orderContentStrategy?.purposeOfContent ?? null,
      linkTo: orderContentStrategy?.linkTo ?? null,
      voice: orderContentStrategy?.voice ?? null,
      focusPoints: orderContentStrategy?.focusPoints ?? null,
      webPage: orderContentStrategy?.webPage ?? null,
      pageType: orderContentStrategy?.pageType ?? null,
      sampleContent: orderContentStrategy?.sampleContent ?? null,
    },
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      if (!orderContentStrategy) {
        return;
      }
      try {
        const response = await updateOrderContentStrategy({
          variables: {
            input: {
              orderContentStrategyId: orderContentStrategy.id,
              customerKeywordId: values.customerKeywordId,
              focusPoints: FormHelpers.processNullableString(values.focusPoints ?? ""),
              linkTo: FormHelpers.processNullableString(values.linkTo ?? ""),
              pageType: FormHelpers.processNullableString(values.pageType ?? ""),
              purposeOfContent: FormHelpers.processNullableString(values.purposeOfContent ?? ""),
              topic: FormHelpers.processNullableString(values.topic ?? ""),
              voice: FormHelpers.processNullableString(values.voice ?? ""),
              webPage: FormHelpers.processNullableString(values.webPage ?? ""),
              sampleContent: FormHelpers.processNullableString(values.sampleContent ?? ""),
              orderHaloKeywords: values.haloKeywordIds,
            },
          },
        });

        if (!response.data?.updateOrderContentStrategy.ok) {
          throw new Error(
            response.data?.updateOrderContentStrategy.error?.message ??
              "Something went wrong while saving the Content Strategy. Please try again."
          );
        }
        // As we are creating order content strategy at time of creation of order
        // When it is complete according to backend we set isProposed to false
        await updateOrderContentStrategy({
          variables: {
            input: {
              orderContentStrategyId: orderContentStrategy.id,
              isProposed: !(response.data.updateOrderContentStrategy.orderContentStrategy?.isComplete === true),
            },
          },
        });
        setIsEditing(false);
        await queryResult.refetch();
      } catch (e: any) {
        handleGraphQLResponseError(e, toast, helpers.setErrors);
      }
    },
  });

  function onCancel() {
    formik.resetForm();
    setIsEditing(false);
  }

  function handleEdit() {
    setIsEditing(true);
  }

  return {
    loading: queryResult.loading,
    orderId,
    isEditing,
    handleEdit,
    onCancel,
    formik,
    orderContentStrategy,
    order,
    isAdmin,
  };
}

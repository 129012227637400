import { Stack, useToast, Box } from "@chakra-ui/react";
import { ContentEditor } from "../../components/content-editor";
import { useFormik } from "formik";
import { PrimaryButton, CancelButton } from "../../components/buttons";
import { useCreateCustomerNoteMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

interface Props {
  customerId: number;
  onNoteCreated: () => void;
  onCancel: () => void;
  showEditor: boolean;
}

interface FormValues {
  content: string;
}

export function NewCustomerNote(props: Props) {
  const { onNoteCreated, customerId, showEditor, onCancel } = props;
  const [createCustomerNoteMutation] = useCreateCustomerNoteMutation();
  const toast = useToast();

  const formik = useFormik<FormValues>({
    initialValues: {
      content: "",
    },
    onSubmit: async (values, helpers) => {
      try {
        await createCustomerNoteMutation({
          variables: {
            input: {
              content: values.content,
              customerId,
            },
          },
        });
        onNoteCreated();
        helpers.resetForm();
      } catch (e: any) {
        toast({ title: "Unable to Save Note", description: getDisplayMessageForError(e), status: "error" });
      }
    },
  });

  if (!showEditor) {
    return null;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={4}>
        <Box>
          <ContentEditor
            onChange={(c) => {
              formik.setFieldValue("content", c);
            }}
          />
        </Box>
        <Stack isInline spacing={2}>
          <PrimaryButton type="submit">Save</PrimaryButton>
          <CancelButton
            onClick={() => {
              formik.resetForm();
              onCancel();
            }}
          >
            Cancel
          </CancelButton>
        </Stack>
      </Stack>
    </form>
  );
}

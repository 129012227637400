import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Select,
  FormHelperText,
} from "@chakra-ui/react";
import { useUserTaskPermissionsDrawer } from "./use-user-task-permissions-drawer";
import { VendorSelector } from "../vendor-selector";
import { PermissionItem } from "./permission-item";
import { WildcardPermissionItem } from "./wildcard-permission-item";
import { PrimaryButton } from "../../components/buttons";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onPermissionsUpdated: () => void;
  userId: number;
  vendorId?: number;
  partnerId?: number | null;
}

export function UserTaskPermissionsDrawer(props: Props) {
  const { isOpen, ...options } = props;
  const {
    handleClose,
    vendorId,
    onVendorChange,
    productTaskTypes,
    userTaskPermissions,
    wildcardUserTaskPermissions,
    createUserTaskPermission,
    deleteUserTaskPermission,
    products,
    taskTypes,
    handleWildcardProductChange,
    handleWildcardTaskTypeChange,
    wildcardProductId,
    wildcardTaskTypeId,
    createWildcardUserPermission,
    user,
  } = useUserTaskPermissionsDrawer(options);

  return (
    <Drawer isOpen={isOpen} onClose={handleClose} placement="right" size="md" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Manage Task Permissions</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>User</FormLabel>
              <Input variant="outline" value={user?.fullName ?? ""} isDisabled />
            </FormControl>
            <FormControl>
              <FormLabel>Vendor</FormLabel>
              <VendorSelector
                variant="outline"
                partnerId={options.partnerId}
                selectedVendorId={vendorId}
                onVendorSelected={onVendorChange}
                isDisabled={!!options.vendorId}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Individual Task Permissions</FormLabel>
              <Stack
                spacing={1}
                maxHeight="25vh"
                overflowY="scroll"
                borderRadius={4}
                border={`1px solid #E2E8F0`}
                shouldWrapChildren
              >
                {productTaskTypes.map((productTaskType) => {
                  const wildcardTaskPermission = userTaskPermissions.find((x) => {
                    if (!x.product && (!x.taskType || x.taskType.id === productTaskType.taskType.id)) {
                      return true;
                    }
                    if (!x.taskType && x.product?.id === productTaskType.product.id) {
                      return true;
                    }
                    return false;
                  });
                  const isGrantedByWildcard = !!wildcardTaskPermission;
                  const taskPermission = userTaskPermissions.find(
                    (x) =>
                      (x.product?.id ?? 0) === productTaskType.product.id &&
                      (x.taskType?.id ?? 0) === productTaskType.taskType.id
                  );
                  const isGranted = !!taskPermission || !!wildcardTaskPermission;
                  return (
                    <PermissionItem
                      key={productTaskType.id}
                      isGranted={isGranted}
                      isGrantedByWildcard={isGrantedByWildcard}
                      product={productTaskType.product}
                      taskType={productTaskType.taskType}
                      onCreate={() => {
                        createUserTaskPermission(productTaskType.product.id, productTaskType.taskType.id);
                      }}
                      onDelete={() => {
                        if (taskPermission) {
                          deleteUserTaskPermission(taskPermission.id);
                        }
                      }}
                    />
                  );
                })}
              </Stack>
            </FormControl>
            <FormControl>
              <FormLabel>Wildcard Task Permissions</FormLabel>
              <FormHelperText>
                Wildcard Task Permissions allow for adding permissions for all task types and/or products (instead of
                adding them individually).
              </FormHelperText>
              <Stack spacing={1}>
                {wildcardUserTaskPermissions.map((permission) => (
                  <WildcardPermissionItem
                    key={permission.id}
                    product={permission.product}
                    taskType={permission.taskType}
                    onDelete={() => {
                      deleteUserTaskPermission(permission.id);
                    }}
                  />
                ))}
              </Stack>
              <Stack spacing={2}>
                <Stack spacing={1} isInline marginTop={4}>
                  <Select
                    variant="outline"
                    value={wildcardProductId}
                    onChange={(e) => {
                      handleWildcardProductChange(e.target.value);
                    }}
                  >
                    <option value="">Any Product</option>
                    {products.map((product) => (
                      <option key={product.id} value={product.id}>
                        {product.name}
                      </option>
                    ))}
                  </Select>
                  <Select
                    variant="outline"
                    value={wildcardTaskTypeId}
                    onChange={(e) => {
                      handleWildcardTaskTypeChange(e.target.value);
                    }}
                  >
                    <option value="">Any Task Type</option>
                    {taskTypes.map((taskType) => (
                      <option key={taskType.id} value={taskType.id}>
                        {taskType.name}
                      </option>
                    ))}
                  </Select>
                </Stack>
                <PrimaryButton onClick={createWildcardUserPermission}>Add Wildcard Permission</PrimaryButton>
              </Stack>
            </FormControl>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

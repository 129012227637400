import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useClipboard } from "use-clipboard-copy";
import { AppColors } from "../../core/custom-theme";
import AppIcon from "../app-icon";
import { PrimaryLinkButton } from "../buttons";

interface Props {
  valueToCopy: string;
}

export function CopyLinkButton(props: Props) {
  const { valueToCopy } = props;
  const clipboard = useClipboard();
  const [showCopied, setShowCopied] = useState(false);

  useEffect(() => {
    if (showCopied) {
      const timeout = setTimeout(() => {
        setShowCopied(false);
      }, 1500);
      return () => clearTimeout(timeout);
    }
  }, [showCopied]);

  function handleCopy() {
    clipboard.copy(valueToCopy);
    setShowCopied(true);
  }

  return (
    <PrimaryLinkButton
      onClick={handleCopy}
      color={showCopied ? AppColors.success : AppColors.blue}
      _focus={{ outline: 0 }}
      isDisabled={showCopied}
      width="70px"
    >
      {showCopied && (
        <span>
          <AppIcon icon={faCheck} standardRightMargin />
          Copied
        </span>
      )}
      {!showCopied && "Copy"}
    </PrimaryLinkButton>
  );
}

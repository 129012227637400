export const PARTNER_INVOICE_PAYMENT_STATUS = {
  PENDING: {
    id: "pending",
    name: "Pending",
    description: "The payment has been created but has not been processed yet.",
  },
  FAILED: {
    id: "failed",
    name: "Failed",
    description: "The attempt to process the payment has failed.",
  },
  CLEARED: {
    id: "cleared",
    name: "Cleared",
    description: "The payment was processed successfully.",
  },
};

import { useRef, useState } from "react";
import { useToast } from "@chakra-ui/react";
import {
  AdminUsers_UserFragment,
  AdminUsersQueryResult,
  useAdminUsers_UpdateUserMutation,
} from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

interface ToggleActive {
  id: number;
  isActive: boolean;
  userName: string;
}

interface ToggleAdmin {
  id: number;
  isAdmin: boolean;
  userName: string;
}

export function useAdminUsers(queryResult: AdminUsersQueryResult) {
  const [toggleActive, setToggleActive] = useState<ToggleActive | null>(null);
  const [toggleAdmin, setToggleAdmin] = useState<ToggleAdmin | null>(null);
  const [isActiveOpen, setIsActiveOpen] = useState(false);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [updateUser] = useAdminUsers_UpdateUserMutation();
  const toast = useToast();
  const activeCancelRef = useRef<HTMLButtonElement>(null);
  const adminCancelRef = useRef<HTMLButtonElement>(null);

  function onActiveClose() {
    setIsActiveOpen(false);
    setToggleActive(null);
  }

  function onAdminClose() {
    setIsAdminOpen(false);
    setToggleAdmin(null);
  }

  async function onActiveChange(user: AdminUsers_UserFragment) {
    setToggleActive({
      id: user.id,
      isActive: user.isActive,
      userName: user.fullName,
    });
    setIsActiveOpen(true);
  }

  async function onAdminChange(user: AdminUsers_UserFragment) {
    setToggleAdmin({
      id: user.id,
      isAdmin: user.isAdmin,
      userName: user.fullName,
    });
    setIsAdminOpen(true);
  }

  async function updateToggleActive() {
    if (!toggleActive) {
      return;
    }
    try {
      const response = await updateUser({
        variables: {
          input: {
            userId: toggleActive.id,
            isActive: !toggleActive.isActive,
          },
        },
      });

      if (response.data?.updateUser.user?.id) {
        toast({
          title: "Success",
          description: `"${toggleActive.userName}" has been ${
            toggleActive.isActive ? "deactivated" : "activated"
          } successfully.`,
          status: "success",
        });
        queryResult.refetch();
      }
    } catch (error: any) {
      toast({ title: "Error", description: getDisplayMessageForError(error), status: "error" });
    }
    setToggleActive(null);
    setIsActiveOpen(false);
  }

  async function updateToggleAdmin() {
    if (!toggleAdmin) {
      return;
    }
    try {
      const response = await updateUser({
        variables: {
          input: {
            userId: toggleAdmin.id,
            isAdmin: !toggleAdmin.isAdmin,
          },
        },
      });

      if (response.data?.updateUser.user?.id) {
        toast({
          title: "Success",
          description: `Admin access ${toggleAdmin.isAdmin ? "revoked from" : "granted to"} "${toggleAdmin.userName}".`,
          status: "success",
        });
        queryResult.refetch();
      }
    } catch (error: any) {
      toast({ title: "Error", description: getDisplayMessageForError(error), status: "error" });
    }
    setToggleAdmin(null);
    setIsAdminOpen(false);
  }

  return {
    isActiveOpen,
    isAdminOpen,
    onActiveClose,
    onAdminClose,
    onActiveChange,
    onAdminChange,
    updateToggleActive,
    updateToggleAdmin,
    toggleActive,
    toggleAdmin,
    activeCancelRef,
    adminCancelRef,
  };
}

import { Badge, BadgeProps } from "@chakra-ui/react";
import { isBetween } from "../../util/datetime-helpers";

interface Props extends BadgeProps {
  startDate: Date;
  endDate: Date;
}

export function BillingCycleStatusBadge(props: Props) {
  const { startDate, endDate, ...rest } = props;
  if (isBetween(new Date(), startDate, endDate)) {
    return (
      <Badge colorScheme="green" fontSize=".6em" {...rest}>
        Active
      </Badge>
    );
  } else if (new Date() < startDate) {
    return (
      <Badge colorScheme="yellow" fontSize=".6em" {...rest}>
        Scheduled
      </Badge>
    );
  } else {
    // Past Billing Cycle
    return null;
  }
}

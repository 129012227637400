import { useContentStrategyTaskWorkspaceQuery } from "../../generated/graphql";
import { useState } from "react";
import { DrawerProposedOrderTopic } from "./common";
import { useToast } from "@chakra-ui/react";

interface Options {
  taskId: number;
  onComplete: (isApproved?: boolean, reviewComment?: string) => void;
}

export function useTaskWorkspaceContentStrategy(options: Options) {
  const { taskId, onComplete } = options;
  const queryResult = useContentStrategyTaskWorkspaceQuery({
    fetchPolicy: "network-only",
    variables: {
      taskId,
    },
  });
  const [orderBeingEdited, setOrderBeingEdited] = useState<DrawerProposedOrderTopic | null>(null);
  const toast = useToast();

  const orders = queryResult.data?.task?.contentCalendarOrders ?? [];
  const proposedOrderTopics = queryResult.data?.task?.customerProposal?.proposedOrderTopics ?? [];

  const topicDefinedCount =
    orders.filter(
      (order) => !proposedOrderTopics.find((orderTopic) => order.id === orderTopic.orderId) && !!order.topic
    ).length + proposedOrderTopics.length;
  const keywordDefinedCount =
    orders.filter(
      (order) => !proposedOrderTopics.find((orderTopic) => order.id === orderTopic.orderId) && !!order.customerKeyword
    ).length + proposedOrderTopics.length;

  function editOrder(orderId: number) {
    const order = orders.find((x) => x.id === orderId);
    const proposedOrderTopic = proposedOrderTopics.find((x) => x.orderId === orderId);
    if (!order) {
      return;
    }
    setOrderBeingEdited({
      customerKeywordId: proposedOrderTopic?.customerKeyword.id ?? order.customerKeyword?.id ?? null,
      orderId: order.id,
      startDate: order.startDate,
      topic: proposedOrderTopic?.topic ?? order.topic ?? "",
      type: order.partnerProduct.product.name,
      rejectionComment: proposedOrderTopic?.rejectionComment ?? "",
    });
  }

  function cancelEditOrder() {
    setOrderBeingEdited(null);
  }

  function onOrderEdited() {
    setOrderBeingEdited(null);
    queryResult.refetch();
  }

  function handleComplete() {
    if (keywordDefinedCount !== orders.length || topicDefinedCount !== orders.length) {
      toast({
        title: "Unable to Complete Task",
        description: "All orders must have a topic and keyword defined.",
        status: "error",
      });
      return;
    }
    onComplete();
  }

  return {
    loading: queryResult.loading,
    task: queryResult.data?.task ?? null,
    orders,
    proposedOrderTopics,
    topicDefinedCount,
    keywordDefinedCount,
    editOrder,
    cancelEditOrder,
    onOrderEdited,
    orderBeingEdited,
    customerId: queryResult.data?.task?.customerId ?? 0,
    customerProposal: queryResult.data?.task?.customerProposal ?? null,
    handleComplete,
  };
}

import { Container } from "../container";
import { MAIN_NAV_HEIGHT } from "../main-nav-full";
import { Box, Tooltip, Stack, Text } from "@chakra-ui/react";
import { AppColors } from "../../../core/custom-theme";

interface Props {
  children?: React.ReactNode;
  title?: string;
}

export function SecondaryNav(props: Props) {
  const { children, title } = props;

  function renderNavTitle(title: string, maxLength: number) {
    if (title.length > maxLength) {
      return (
        <Tooltip hasArrow aria-label={title} label={title && title.length > 30 ? title : ""}>
          <Text
            paddingY={2}
            color={AppColors.textDarkGray}
            marginRight="2rem"
            fontWeight="bold"
            fontSize="12px"
            maxWidth="250px"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {title}
          </Text>
        </Tooltip>
      );
    } else {
      return (
        <Text
          paddingY={2}
          color={AppColors.textDarkGray}
          marginRight="2rem"
          fontWeight="bold"
          fontSize="12px"
          maxWidth="250px"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {title}
        </Text>
      );
    }
  }

  return (
    <Box
      width="100%"
      backgroundColor="white"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="40px"
      boxShadow="2px 0px 12px rgba(0, 0, 0, 0.6);"
      position="sticky"
      top={MAIN_NAV_HEIGHT}
      zIndex={998}
      marginBottom={2}
    >
      <Container display="flex" alignItems="center">
        {!!title && renderNavTitle(title, 30)}
        {children && (
          <Stack spacing={2} isInline style={{ overflowX: "auto" }}>
            {children}
          </Stack>
        )}
      </Container>
    </Box>
  );
}

export class FormHelpers {
  static processNullableString(value: string): string | null {
    if (value.trim() === "") {
      return null;
    } else {
      return value.trim();
    }
  }

  static processNullableInt(value?: string | number | null): number | null {
    if (value === null || value === undefined) {
      return null;
    }
    if (typeof value === "number") {
      return value;
    }
    if (value.trim() === "") {
      return null;
    } else {
      return parseInt(value, 10);
    }
  }
}

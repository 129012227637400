import { AppColors } from "../../core/custom-theme";

export type PanelStatus = "error" | "warning" | "success" | "info";

export function getStatusColor(status?: PanelStatus) {
  if (status === "error") {
    return {
      backgroundColor: AppColors.error,
      color: "white",
    };
  } else if (status === "warning") {
    return { backgroundColor: AppColors.warning, color: "white" };
  } else if (status === "success") {
    return { backgroundColor: AppColors.success, color: "white" };
  } else if (status === "info") {
    return { backgroundColor: AppColors.info, color: "white" };
  } else {
    return undefined;
  }
}

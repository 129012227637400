import { useState } from "react";
import { useQueryParam, StringParam } from "use-query-params";
import { usePublishersQuery } from "../../generated/graphql";
import { PAGE_LIMIT } from "../../constants/constants";
import { usePagination } from "../../util/use-pagination";

export function usePublishers() {
  const [search, setSearch] = useQueryParam("search", StringParam);
  const pagination = usePagination(PAGE_LIMIT);
  const publishersQueryResult = usePublishersQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      search: search && search.length > 0 ? search : undefined,
    },
  });
  const [showAddPublisherDrawer, setShowAddPublisherDrawer] = useState(false);
  const [showMapLinkLocation, setShowMapLinkLocation] = useState(false);

  function onAddPublisher() {
    setShowAddPublisherDrawer(true);
  }

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  }

  function onAddPublisherCancel() {
    setShowAddPublisherDrawer(false);
  }

  function onPublisherAdded() {
    setShowAddPublisherDrawer(false);
    publishersQueryResult.refetch();
  }

  function onMapPublisherLinkLocation() {
    setShowMapLinkLocation(true);
  }

  function onCloseMapPublisherLinkLocation() {
    setShowMapLinkLocation(false);
  }

  return {
    loading: publishersQueryResult.loading,
    publishers: publishersQueryResult.data?.publishers.nodes ?? [],
    publishersTableLimit: publishersQueryResult.variables?.limit ?? PAGE_LIMIT,
    publishersTableOffset: pagination.offset ?? 0,
    totalCount: publishersQueryResult.data?.publishers.totalCount ?? 0,
    onAddPublisher,
    handleSearchChange,
    showAddPublisherDrawer,
    onAddPublisherCancel,
    onPublisherAdded,
    pagination,
    onMapPublisherLinkLocation,
    onCloseMapPublisherLinkLocation,
    showMapLinkLocation,
  };
}

import { Badge, BadgeProps, Tooltip } from "@chakra-ui/react";
import { CUSTOMER_CAMPAIGN_INVOICE_CHARGE_STATUS } from "../../constants/customer-campaign-invoice-charge-status";

interface Props extends BadgeProps {
  statusId: string;
}

export function CustomerCampaignInvoiceChargeStatusBadge(props: Props) {
  const { statusId, ...rest } = props;
  if (statusId === CUSTOMER_CAMPAIGN_INVOICE_CHARGE_STATUS.CLEARED.id) {
    return (
      <Tooltip label="Campaign Paid" aria-label="Campaign Paid">
        <Badge colorScheme="green" fontSize=".6em" {...rest}>
          {CUSTOMER_CAMPAIGN_INVOICE_CHARGE_STATUS.CLEARED.name}
        </Badge>
      </Tooltip>
    );
  } else if (statusId === CUSTOMER_CAMPAIGN_INVOICE_CHARGE_STATUS.PENDING.id) {
    return (
      <Tooltip label="Campaign Pending" aria-label="Campaign Pending">
        <Badge colorScheme="red" fontSize=".6em" {...rest}>
          {CUSTOMER_CAMPAIGN_INVOICE_CHARGE_STATUS.PENDING.name}
        </Badge>
      </Tooltip>
    );
  } else {
    return null;
  }
}

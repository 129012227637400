import { useEffect } from "react";
import { sortBy } from "lodash";
import { SelectProps, Select } from "@chakra-ui/react";

import { useLinkLocationsSelectorQuery } from "../../generated/graphql";

interface Props extends SelectProps {
  typeIds?: string[];
  selectedLinkLocationId?: number | null;
  onLinkLocationSelected: (linkLocationId: number | null) => void;
  allowNull?: boolean;
  nullValueText?: string;
}

export function LinkLocationSelector(props: Props) {
  const { onLinkLocationSelected, selectedLinkLocationId, allowNull, nullValueText, typeIds, ...rest } = props;
  const queryResult = useLinkLocationsSelectorQuery({
    variables: {
      typeIds: typeIds ?? [],
    },
    fetchPolicy: "network-only",
  });

  const linkLocations = sortBy(queryResult.data?.linkLocations.nodes ?? [], "domain");

  useEffect(() => {
    if (linkLocations.length > 0 && (selectedLinkLocationId === 0 || selectedLinkLocationId === null) && !allowNull) {
      onLinkLocationSelected(linkLocations[0].id);
    }
  }, [linkLocations, selectedLinkLocationId, allowNull, onLinkLocationSelected]);

  return (
    <Select
      isDisabled={queryResult.loading}
      value={selectedLinkLocationId?.toString() ?? ""}
      onChange={(e) => {
        if (e.target.value === "") {
          onLinkLocationSelected(null);
        } else {
          onLinkLocationSelected(parseInt(e.target.value));
        }
      }}
      {...rest}
    >
      {allowNull && <option value="">{nullValueText ?? "No Link Location"}</option>}
      {linkLocations.map((linkLocation) => (
        <option key={linkLocation.id} value={linkLocation.id.toString()}>
          {`${linkLocation.domain} ${linkLocation.id}`}
        </option>
      ))}
    </Select>
  );
}

import { useMemo } from "react";
import { DateTime } from "luxon";
import { FormikHelpers, useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import { useCreateAmTaskMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { DATE_FORMAT } from "../../constants/constants";
import { FormHelpers } from "../../util/form-helpers";

interface FormValues {
  taskType: string;
  completedAt: Date;
  info?: string | null;
}

interface Props {
  customerId: number;
  onCancel: () => void;
  onAmTaskAdded: (taskId: number) => void;
  minimumDate?: Date | null;
  maximumDate?: Date | null;
}

export function useAddAmTaskDrawer(props: Props) {
  const { customerId, onAmTaskAdded, onCancel } = props;

  const [createAmTask] = useCreateAmTaskMutation();
  const toast = useToast();

  const dateRangeStart = useMemo(
    () =>
      props.minimumDate
        ? DateTime.max(DateTime.now(), DateTime.fromJSDate(props.minimumDate)).toJSDate()
        : DateTime.now().plus({ hours: 1 }).startOf("hour").toJSDate(),
    [props.minimumDate]
  );
  const dateRangeEnd = props.maximumDate ?? DateTime.now().plus({ years: 1 }).toJSDate();

  const initialValues: FormValues = useMemo(
    () => ({
      taskType: "",
      completedAt: dateRangeStart,
      info: "",
    }),
    [dateRangeStart]
  );

  async function onSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await createAmTask({
        variables: {
          input: {
            customerId,
            taskType: values.taskType,
            info: FormHelpers.processNullableString(values.info ?? ""),
            completedAt: DateTime.fromJSDate(values.completedAt).toFormat(DATE_FORMAT),
          },
        },
      });

      if (!response.data?.createAmTask.ok) {
        toast({
          title: "Add Am Task",
          description: getDisplayMessageForError(
            response.errors?.[0] ?? new Error("Something went wrong while creating am task.")
          ),
          status: "error",
        });
        formikHelpers.setSubmitting(false);
        return;
      }
      toast({
        title: "Add Am Task",
        description: "Task added successfully!",
        status: "success",
      });
      onAmTaskAdded(response.data.createAmTask.amTask?.id ?? 0);
      onCancel();
    } catch (e: any) {
      toast({ title: "Unable to create am task.", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues,
    enableReinitialize: false,
    onSubmit,
  });

  function handleCancel() {
    formik.resetForm();
    onCancel();
  }

  return {
    formik,
    handleCancel,
    dateRangeStart,
    dateRangeEnd,
  };
}

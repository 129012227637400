import { Box, Collapse, Flex, FormControl, FormLabel, Heading, Input, Select, Stack, Textarea } from "@chakra-ui/react";
import { PanelBottom, PanelBox, PanelGroup, PanelTop } from "../../../components/panels";
import { CancelButton, PrimaryButton } from "../../../components/buttons";
import { useOrderContentStrategy } from "./order-content-strategy-hook";
import { CustomerKeywordMultiSelector, CustomerKeywordSelector } from "../../../common/customer-keyword-selector";
import { BLOG_POST_PRODUCTS, GUEST_ARTICLE_PRODUCTS, WEB_COPY_PRODUCTS } from "../../../constants/products";
import { PURPOSE_OF_CONTENT_OPTIONS } from "../../../constants/purpose-of-content-options";
import { VOICE_OPTIONS } from "../../../constants/voice-options";
import { PAGE_TYPES } from "../../../constants/page-types";
import { Loader } from "../../../components/loader";

interface Props {
  orderId: number;
}

export function OrderContentStrategy(props: Props) {
  const { loading, formik, orderContentStrategy, onCancel, order, isAdmin } = useOrderContentStrategy(props);

  if (!isAdmin) {
    return null;
  }

  if (loading) {
    return (
      <PanelBox>
        <Loader type="panel" />
      </PanelBox>
    );
  }

  if (!orderContentStrategy || !order) {
    return <PanelBox>No content strategy found for order.</PanelBox>;
  }

  const isGuestArticle = GUEST_ARTICLE_PRODUCTS.includes(order.partnerProduct.product.id);
  const isBlogPost = BLOG_POST_PRODUCTS.includes(order.partnerProduct.product.id);
  const isWebCopy = WEB_COPY_PRODUCTS.includes(order.partnerProduct.product.id);

  return (
    <PanelGroup isCollapsible>
      <PanelTop showBottomBorder>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">Content Strategy</Heading>
        </Flex>
      </PanelTop>
      <PanelBottom>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Topic</FormLabel>
            <Input id="topic" value={formik.values.topic ?? ""} onChange={formik.handleChange} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Primary Keyword</FormLabel>
            <CustomerKeywordSelector
              keywordTypes="primary"
              customerId={order.customerId}
              selectedCustomerKeywordId={formik.values.customerKeywordId}
              onSelectedCustomerKeywordChange={(customerKeywordId) => {
                formik.setFieldValue("customerKeywordId", customerKeywordId);
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Halo Keywords</FormLabel>
            <Box>
              <CustomerKeywordMultiSelector
                keywordType="halo"
                customerId={order.customerId}
                customerKeywordIds={formik.values.haloKeywordIds}
                onCustomerKewyordsChanged={(keywordIds) => {
                  formik.setFieldValue("haloKeywordIds", keywordIds);
                }}
              />
            </Box>
          </FormControl>
          {(isBlogPost || isGuestArticle) && (
            <FormControl>
              <FormLabel>Purpose of Content</FormLabel>
              <Select id="purposeOfContent" value={formik.values.purposeOfContent ?? ""} onChange={formik.handleChange}>
                <option value="" disabled>
                  Not Selected
                </option>
                {PURPOSE_OF_CONTENT_OPTIONS.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          {(isBlogPost || isGuestArticle) && (
            <FormControl>
              <FormLabel>Call to Action Link</FormLabel>
              <Input id="linkTo" onChange={formik.handleChange} value={formik.values.linkTo ?? ""} />
            </FormControl>
          )}
          {(isBlogPost || isGuestArticle) && (
            <FormControl>
              <FormLabel>Voice</FormLabel>
              <Select id="voice" value={formik.values.voice ?? ""} onChange={formik.handleChange}>
                <option value="">Not Selected</option>
                {VOICE_OPTIONS.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl>
            <FormLabel>Sample Content</FormLabel>
            <Input id="sampleContent" onChange={formik.handleChange} value={formik.values.sampleContent ?? ""} />
          </FormControl>
          {(isBlogPost || isGuestArticle) && (
            <FormControl>
              <FormLabel>Focus Points</FormLabel>
              <Textarea
                id="focusPoints"
                onChange={formik.handleChange}
                value={formik.values.focusPoints ?? ""}
                resize="none"
              />
            </FormControl>
          )}
          {isWebCopy && (
            <FormControl>
              <FormLabel>Web Page</FormLabel>
              <Input id="webPage" onChange={formik.handleChange} value={formik.values.webPage ?? ""} />
            </FormControl>
          )}
          {isWebCopy && (
            <FormControl>
              <FormLabel>Page Type</FormLabel>
              <Select id="pageType" value={formik.values.pageType ?? ""} onChange={formik.handleChange}>
                <option value="">Not Selected</option>
                {PAGE_TYPES.map((option) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          <Collapse in={formik.dirty}>
            <Stack isInline spacing={4}>
              <PrimaryButton
                onClick={() => {
                  formik.handleSubmit();
                }}
                isLoading={formik.isSubmitting}
              >
                Save
              </PrimaryButton>
              <CancelButton onClick={onCancel}>Cancel</CancelButton>
            </Stack>
          </Collapse>
        </Stack>
      </PanelBottom>
    </PanelGroup>
  );
}

import { useFormik } from "formik";
import * as yup from "yup";
import { useToast } from "@chakra-ui/react";

import { DrawerProposedOrderTopic } from "./common";
import { useContentStrategy_SaveProposedOrderTopicMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

interface Options {
  onClose: () => void;
  onEdited: () => void;
  open: boolean;
  taskId: number;
  customerId: number;
  customerProposalId: number;
  orderTopic: DrawerProposedOrderTopic | null;
}

interface FormValues {
  customerKeywordId: number | null;
  topic: string;
}

const validationSchema = yup.object().shape({
  customerKeywordId: yup.mixed().label("Keyword").required().nullable(false),
  topic: yup.string().label("Topic").required().nullable(false),
});

export function useEditOrderTopicDrawer(options: Options) {
  const { onClose, onEdited, orderTopic, customerProposalId, taskId } = options;
  const [saveProposedOrderTopicMutation] = useContentStrategy_SaveProposedOrderTopicMutation();
  const toast = useToast();

  const formik = useFormik<FormValues>({
    initialValues: {
      customerKeywordId: orderTopic?.customerKeywordId ?? null,
      topic: orderTopic?.topic ?? "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!orderTopic || !values.customerKeywordId) {
        return;
      }
      try {
        await saveProposedOrderTopicMutation({
          variables: {
            input: {
              customerKeywordId: values.customerKeywordId,
              orderId: orderTopic.orderId,
              taskId,
              customerProposalId,
              topic: values.topic,
            },
          },
        });
        onEdited();
      } catch (e: any) {
        toast({ title: "Unable to Save", description: getDisplayMessageForError(e), status: "error" });
      }
    },
  });

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  return {
    handleCancel,
    formik,
  };
}

import { useState } from "react";
import * as yup from "yup";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { FormikHelpers, useFormik } from "formik";
import { Box, FormControl, FormErrorMessage, FormLabel, Grid, Heading, Input, Stack, useToast } from "@chakra-ui/react";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import AppLink from "../../components/app-link";
import AppIcon from "../../components/app-icon";
import { useSitePostDetailQuery, useUpdateSitePostMutation } from "../../generated/graphql";
import { PanelBottom, PanelGroup, PanelMiddle, PanelTop } from "../../components/panels";
import { getDisplayMessageForError } from "../../util/error-helper";
import { Loader } from "../../components/loader";
import { SiteSelector } from "../../common/site-selector";
import { CategorySelector } from "../../common/category-selector";
import { SitePostStatusSelector } from "../../common/site-post-status-selector";
import { ContentEditor } from "../../components/content-editor";
import { CancelButton, PrimaryButton } from "../../components/buttons";
import { DATE_MONTH_FORMAT } from "../../constants/constants";

interface FormValues {
  title: string;
  content: string;
  siteId?: string;
  categoryId?: number;
  statusId?: string;
}

const validationSchema = yup.object().shape({
  title: yup.string().label("Title").required().nullable(false).min(1),
  content: yup.string().label("Content").required().nullable(false).min(1),
  siteId: yup.string().label("Site Id"),
  categoryId: yup.number().label("Category Id"),
  statusId: yup.string().label("Status Id"),
});

export function AdminSitePostDetailsRoute() {
  const { id } = useParams<"id">();
  const sitePostId = parseInt(id ?? "", 10);
  const toast = useToast();
  const [editState, setEditState] = useState(false);

  const { data, loading, refetch } = useSitePostDetailQuery({
    variables: {
      id: sitePostId,
    },
    fetchPolicy: "network-only",
  });

  const sitePost = data?.sitePost;

  const [updateSitePost] = useUpdateSitePostMutation();

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await updateSitePost({
        variables: {
          input: {
            sitePostId,
            siteId: values.siteId,
            categoryId: values.categoryId,
            statusId: values.statusId,
            title: values.title,
            content: values.content,
          },
        },
      });

      if (response.data?.updateSitePost.sitePost.id) {
        toast({
          title: "Success",
          description: "Site Post saved successfully!",
          status: "success",
        });
        refetch();
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      content: sitePost?.content ?? "",
      title: sitePost?.title ?? "",
      siteId: sitePost?.siteId ?? undefined,
      categoryId: sitePost?.category?.id,
      statusId: sitePost?.status.id,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit,
  });

  function onContentChange(html: string) {
    formik.setFieldValue("content", html);
  }

  function onSiteSelected(siteId: string | null) {
    formik.setFieldValue("siteId", siteId ?? undefined);
  }

  function onCategorySelected(categoryId: number) {
    formik.setFieldValue("categoryId", categoryId);
  }

  function onSitePostStatusSelected(statusId: string | null) {
    formik.setFieldValue("statusId", statusId);
  }

  function showEditState() {
    setEditState(true);
  }

  function handleCancel() {
    formik.resetForm();
    setEditState(false);
  }

  return (
    <LayoutBase pageTitle="Site Post Details">
      <MainNav hasSecondaryNav />
      <Container centerHorizontally>
        <Box marginY={4}>
          <AppLink to="/admin/site-posts">
            <AppIcon icon={faAngleLeft} standardRightMargin />
            Site Posts
          </AppLink>
        </Box>
        <PageHeading>Site Post</PageHeading>
        <Grid templateColumns={{ base: "1fr", xl: "2fr 1fr" }} gridGap={4}>
          {loading && <Loader type="content" />}
          {!loading && !!sitePost && (
            <>
              <Stack spacing={4} gridColumn={1}>
                <PanelGroup>
                  {!editState && (
                    <PanelTop>
                      <PrimaryButton onClick={showEditState}>Edit</PrimaryButton>
                    </PanelTop>
                  )}
                  <PanelMiddle>
                    <Stack spacing={4}>
                      <FormControl isRequired isInvalid={!!formik.touched.title && !!formik.errors.title}>
                        <FormLabel>Title</FormLabel>
                        <Input
                          id="title"
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          isReadOnly={!editState}
                        />
                        <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
                      </FormControl>
                      <FormControl isRequired isInvalid={!!formik.touched.content && !!formik.errors.content}>
                        <FormLabel>Content</FormLabel>
                        <ContentEditor
                          isInvalid={!!formik.touched.content && !!formik.errors.content}
                          initialValue={sitePost.content ?? ""}
                          onChange={onContentChange}
                          readOnly={!editState}
                        />
                        <FormErrorMessage>{formik.errors.content}</FormErrorMessage>
                      </FormControl>
                    </Stack>
                  </PanelMiddle>
                  {editState && (
                    <PanelBottom>
                      <Stack isInline spacing={2} shouldWrapChildren>
                        <PrimaryButton
                          isLoading={formik.isSubmitting}
                          isDisabled={formik.isSubmitting}
                          onClick={formik.submitForm}
                        >
                          Save
                        </PrimaryButton>
                        <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                      </Stack>
                    </PanelBottom>
                  )}
                </PanelGroup>
              </Stack>
              <Stack spacing={4} gridColumn={{ base: 1, xl: 2 }}>
                <PanelGroup isCollapsible>
                  <PanelTop showBottomBorder>
                    <Heading size="md">Information</Heading>
                  </PanelTop>
                  <PanelBottom>
                    <Stack spacing={4}>
                      <FormControl isInvalid={!!formik.errors.siteId && !!formik.touched.siteId}>
                        <FormLabel>Site ID</FormLabel>
                        <SiteSelector
                          onSiteSelected={onSiteSelected}
                          isClearable
                          selectedSiteId={formik.values.siteId}
                          placeholder="Select a Site"
                          isDisabled={!editState}
                          variant="outline"
                        />
                        <FormErrorMessage>{formik.errors.siteId}</FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!formik.errors.categoryId && !!formik.touched.categoryId}>
                        <FormLabel>Category</FormLabel>
                        <CategorySelector
                          onCategorySelected={onCategorySelected}
                          selectedCategoryId={formik.values.categoryId}
                          showNoCategoryOption
                          isDisabled={!editState}
                          variant="outline"
                        />
                        <FormErrorMessage>{formik.errors.categoryId}</FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!formik.errors.statusId && !!formik.touched.statusId}>
                        <FormLabel>Status</FormLabel>
                        <SitePostStatusSelector
                          onStatusSelected={onSitePostStatusSelected}
                          selectedStatusId={formik.values.statusId}
                          placeholder="Select a Status"
                          isDisabled={!editState}
                          variant="outline"
                        />
                        <FormErrorMessage>{formik.errors.statusId}</FormErrorMessage>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Created At</FormLabel>
                        <Input
                          value={
                            sitePost.createdAt
                              ? DateTime.fromJSDate(sitePost.createdAt).toFormat(DATE_MONTH_FORMAT)
                              : "-"
                          }
                          isReadOnly
                          variant="outline"
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Created By</FormLabel>
                        <Input
                          value={sitePost.addedByUser ? sitePost.addedByUser.fullName : "-"}
                          isReadOnly
                          variant="outline"
                        />
                      </FormControl>
                    </Stack>
                  </PanelBottom>
                </PanelGroup>
              </Stack>
            </>
          )}
        </Grid>
      </Container>
    </LayoutBase>
  );
}

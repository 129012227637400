import * as yup from "yup";
import { useToast } from "@chakra-ui/react";
import { FormikHelpers, useFormik } from "formik";

import { useAddPublisherMutation } from "../../generated/graphql";
import { getDisplayMessageForError, handleGraphQLResponseError } from "../../util/error-helper";
import { FormHelpers } from "../../util/form-helpers";

interface Options {
  onClose: () => void;
  onAdded: (publisherId: number) => void;
}

interface FormValues {
  firstName: string;
  lastName: string;
  name: string;
  contactEmail: string;
  paymentMethod: string;
  paymentMethodId: string;
  isSlowPartner: boolean;
  isAutoPayEnabled: boolean;
  isInvoiceRequired: boolean;
  instructions: string;
}

const createPublisherSchema = yup.object().shape({
  firstName: yup.string().label("First Name").required().nullable(false).max(255),
  lastName: yup.string().label("Last Name").required().nullable(false).max(255),
  name: yup.string().label("Publisher Name").required(),
  contactEmail: yup.string().label("Contact Email").email().required().max(255),
  paymentMethod: yup.string().label("Payment Method").notRequired(),
  paymentMethodId: yup.string().label("Payment Method ID").notRequired(),
  isSlowPartner: yup.boolean().label("Is Slow Partner").notRequired(),
  isAutoPayEnabled: yup.boolean().label("Is Auto Pay Enabled").notRequired(),
  isInvoiceRequired: yup.boolean().label("Is Invoice Required").notRequired(),
  instructions: yup.string().label("Instructions").notRequired(),
});

export function useAddPublisherDrawer(options: Options) {
  const { onClose, onAdded } = options;

  const [addPublisher] = useAddPublisherMutation();
  const toast = useToast();

  async function onSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await addPublisher({
        variables: {
          input: {
            firstName: FormHelpers.processNullableString(values.firstName),
            lastName: FormHelpers.processNullableString(values.lastName),
            name: values.name,
            contactEmail: FormHelpers.processNullableString(values.contactEmail),
            paymentMethod: FormHelpers.processNullableString(values.paymentMethod),
            paymentMethodId: FormHelpers.processNullableString(values.paymentMethodId),
            isSlowPartner: values.isSlowPartner,
            isAutoPayEnabled: values.isAutoPayEnabled,
            isInvoiceRequired: values.isInvoiceRequired,
            instructions: FormHelpers.processNullableString(values.instructions),
          },
        },
      });

      if (response.data?.createPublisher?.ok) {
        toast({ title: "Success", description: "Publisher created successfully!", status: "success" });
        formikHelpers.resetForm();
        onAdded(response.data?.createPublisher.publisher?.id ?? 0);
      } else if (response.data?.createPublisher.error) {
        handleGraphQLResponseError(response.data.createPublisher.error, toast, formikHelpers.setErrors);
      } else {
        throw new Error("Something went wrong while creating publisher");
      }

      onClose();
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      name: "",
      contactEmail: "",
      paymentMethod: "",
      paymentMethodId: "",
      isSlowPartner: false,
      isAutoPayEnabled: false,
      isInvoiceRequired: false,
      instructions: "",
    },
    validationSchema: createPublisherSchema,
    onSubmit,
  });

  function handleSubmit() {
    formik.submitForm();
  }

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  return {
    formik,
    handleCancel,
    handleSubmit,
  };
}

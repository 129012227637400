import { useState } from "react";
import { InputProps } from "@chakra-ui/react";
import { AutoCompleteInput } from "../../components/auto-complete-input/auto-complete-input";
import { useSitesForSelectorQuery } from "../../generated/graphql";

interface Props extends InputProps {
  selectedSiteId?: string | null;
  onSiteSelected: (siteId: string | null) => void;
  placeholder?: string;
  isClearable?: boolean;
}

export function SiteSelector(props: Props) {
  const { selectedSiteId, onSiteSelected, placeholder, isClearable, ...rest } = props;
  const [search, setSearch] = useState("");

  const { data, loading } = useSitesForSelectorQuery({
    variables: {
      search,
    },
    fetchPolicy: "network-only",
  });

  const options = data?.sites.nodes.map((node) => ({
    value: `${node.id}`,
    label: `${node.id}`,
  }));

  function onSearchValueChange(value: string) {
    setSearch(value);
  }

  if (loading) {
    // TODO: we can show loader or pass it to autocomplete as placement of popper is not proper
    return null;
  }

  return (
    <AutoCompleteInput
      items={options ?? []}
      onSelectedValueChanged={onSiteSelected}
      onInputChanged={onSearchValueChange}
      selectedValue={selectedSiteId ? `${selectedSiteId}` : undefined}
      placeholder={placeholder ?? "Select Site"}
      isClearable={isClearable}
      {...rest}
    />
  );
}

import { useCustomerNotesQuery } from "../../generated/graphql";
import { useState } from "react";

interface Options {
  customerId: number;
}

const NOTES_PER_PAGE = 10;

export function useCustomerNotes(options: Options) {
  const { customerId } = options;
  const [offset, setOffset] = useState(0);
  const notesQueryResult = useCustomerNotesQuery({
    fetchPolicy: "network-only",
    variables: {
      customerId,
      limit: NOTES_PER_PAGE,
      offset,
    },
  });
  const [showEditor, setShowEditor] = useState(false);
  const notes = notesQueryResult.data?.customer?.notes.nodes ?? [];
  const totalCount = notesQueryResult.data?.customer?.notes.totalCount ?? 0;

  function onAddNote() {
    setShowEditor(true);
  }

  function onAddNoteCancel() {
    setShowEditor(false);
  }

  function onNoteAdded() {
    notesQueryResult.refetch();
    setShowEditor(false);
  }

  function onNextPage() {
    if (totalCount > offset + NOTES_PER_PAGE) {
      setOffset(offset + NOTES_PER_PAGE);
    }
  }

  function onPreviousPage() {
    if (offset > 0) {
      setOffset(Math.max(0, offset - NOTES_PER_PAGE));
    }
  }

  return {
    loading: notesQueryResult.loading,
    notes,
    totalCount: notesQueryResult.data?.customer?.notes.totalCount ?? 0,
    onAddNote,
    onAddNoteCancel,
    onNoteAdded,
    showEditor,
    offset,
    limit: NOTES_PER_PAGE,
    hasNextPage: offset < totalCount,
    hasPreviousPage: offset > 0,
    onNextPage,
    onPreviousPage,
  };
}

import { useContext } from "react";
import { BoxProps, Box, Collapse } from "@chakra-ui/react";
import { PanelContext } from "./context";

interface Props extends BoxProps {
  containsTable?: boolean;
  showTopBorder?: boolean;
}

export function PanelBottom(props: Props) {
  const { containsTable, showTopBorder, ...rest } = props;
  const context = useContext(PanelContext);
  const borderStyle = "1px solid #E3E8EF";

  if (context.isCollapsible) {
    return (
      <Collapse in={!context.isCollapsed}>
        <Box
          backgroundColor="white"
          borderTop={showTopBorder ? borderStyle : undefined}
          borderBottom={borderStyle}
          borderLeft={borderStyle}
          borderRight={borderStyle}
          borderRadius="0 0 4px 4px"
          padding={containsTable ? 0 : 4}
          {...rest}
        />
      </Collapse>
    );
  }

  return (
    <Box
      backgroundColor="white"
      borderTop={showTopBorder ? borderStyle : undefined}
      borderBottom={borderStyle}
      borderLeft={borderStyle}
      borderRight={borderStyle}
      borderRadius="0 0 4px 4px"
      padding={containsTable ? 0 : 4}
      {...rest}
    />
  );
}

import * as yup from "yup";
import { useFormik } from "formik";
import {
  useTaskWorkspaceExternalContentQuery,
  useTaskWorkspaceExternalContent_UpdateOrderDetailsMutation,
} from "../../generated/graphql";
import { handleFormGraphQLError } from "../../util/error-helper";
import { useToast } from "@chakra-ui/react";

interface Props {
  taskId: number;
  onAbandon: () => void;
  onComplete: () => void;
}

interface FormValues {
  resultUrl: string;
  resultUrlDomainAuthority: number | undefined;
  fulfillmentCompletionDate: Date;
}

export function useTaskWorkspaceExternalContent(props: Props) {
  const { taskId, onComplete } = props;

  const toast = useToast();

  const [updateOrderDetailsMutation] = useTaskWorkspaceExternalContent_UpdateOrderDetailsMutation();
  const queryResult = useTaskWorkspaceExternalContentQuery({
    fetchPolicy: "network-only",
    variables: { taskId },
  });

  const { order, customer, product } = queryResult.data?.task ?? {};

  const formik = useFormik<FormValues>({
    initialValues: {
      resultUrl: "",
      resultUrlDomainAuthority: undefined,
      fulfillmentCompletionDate: new Date(),
    },
    validationSchema: yup.object().shape({
      resultUrl: yup.string().label("Result URL").required().url(),
      resultUrlDomainAuthority: yup.number().label("Result URL Domain Authority"),
      fulfillmentCompletionDate: yup.date().label("Fulfillment Completion Date").required(),
    }),
    onSubmit: async (values, helpers) => {
      try {
        await updateOrderDetailsMutation({
          variables: {
            orderInput: {
              orderId: order?.id as number,
              resultUrl: values.resultUrl,
              resultUrlDomainAuthority: values.resultUrlDomainAuthority,
            },
            orderExternalContentSpecInput: {
              orderExternalContentSpecId: order?.orderExternalContentSpec?.id as number,
              completedAt: values.fulfillmentCompletionDate,
            },
          },
        });
        onComplete();
      } catch (e: any) {
        handleFormGraphQLError(e, "Unable to Complete Task", toast, helpers.setErrors);
      }
    },
  });

  return {
    customer,
    product,
    loading: queryResult.loading,
    formik,
    order,
  };
}

import { As } from "@chakra-ui/react";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";

import { CustomSingleSelect } from "../custom-selector";
import { useUserSelectorV2 } from "./use-user-selector-v2";

interface SingleProps {
  selectedUserId?: number | null;
  menuButtonAs?: As;
  onUserSelected: (userId: number | null) => void;
  showAll?: boolean;
  isAdminOrSuperAdmin?: boolean;
}

export function UserSelectorV2(props: SingleProps) {
  const { selectedUserId, menuButtonAs, ...rest } = props;
  const { userOptions, onUserChange } = useUserSelectorV2(rest);

  return (
    <CustomSingleSelect
      onChange={onUserChange}
      value={selectedUserId ? `${selectedUserId}` : null}
      items={userOptions}
      selectorIcon={faUserAlt}
      menuButtonAs={menuButtonAs}
      emptyValueTitle="All"
    />
  );
}

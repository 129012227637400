import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { AdminOnly } from "../../common/admin-only";
import { OrderDetailsOverview } from "./overview/overview";
import { WorkflowDetails } from "./workflow/workflow-details";
import { OrderContentItems } from "./content-item/order-content-items";
import { OrderContentStrategy } from "./content-strategy/order-content-strategy";
import { InvoiceDetails } from "./invoice/invoice-details";
import { ContentStrategyOrders } from "./content-strategy-orders";

export function OrderDetails() {
  const { id } = useParams<"id">();
  const orderId = parseInt(id ?? "", 10);

  return (
    <LayoutBase pageTitle="Order Detail">
      <MainNav hasSecondaryNav />
      <Container centerHorizontally>
        <PageHeading>Order Details (ID: {orderId})</PageHeading>
        <Tabs variant="soft-rounded" isLazy>
          <TabList>
            <Tab>Overview</Tab>
            <Tab>
              <AdminOnly>Billing</AdminOnly>
            </Tab>
            <Tab>
              <AdminOnly>Content Strategy</AdminOnly>
            </Tab>
            <Tab>
              <AdminOnly>Fulfillment</AdminOnly>
            </Tab>
          </TabList>
          <TabPanels marginTop={4}>
            <TabPanel padding={0}>
              <OrderDetailsOverview orderId={orderId} />
            </TabPanel>
            <TabPanel padding={0}>
              <AdminOnly>
                <InvoiceDetails orderId={orderId} />
              </AdminOnly>
            </TabPanel>
            <TabPanel padding={0}>
              <AdminOnly>
                <OrderContentStrategy orderId={orderId} />
              </AdminOnly>
            </TabPanel>
            <TabPanel padding={0}>
              <AdminOnly>
                <Stack spacing={4}>
                  <WorkflowDetails orderId={orderId} />
                  <OrderContentItems orderId={orderId} />
                  <ContentStrategyOrders orderId={orderId} />
                </Stack>
              </AdminOnly>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </LayoutBase>
  );
}

import { useOrderDetailsWorkflowQuery } from "../../../generated/graphql";

export function useOrderDetailsWorkflow(orderId: number) {
  const queryResult = useOrderDetailsWorkflowQuery({
    fetchPolicy: "network-only",
    variables: {
      orderId,
      taskIncludeDeleted: true,
    },
  });

  const order = queryResult.data?.order ?? null;
  const workflow = order?.workflow ?? null;

  return {
    orderId,
    loading: queryResult.loading,
    order,
    workflow,
  };
}

import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Flex, Box, Popover, PopoverTrigger, Image, PopoverContent, Switch, FormLabel, Stack } from "@chakra-ui/react";
import queryString from "qs";
import { PageHeading } from "../../components/page-heading";
import { Container } from "../../components/layout/container";
import { PanelGroup, PanelBottom, PanelTop } from "../../components/panels";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { useClientUsersQuery } from "../../generated/graphql";
import { UsersTable } from "./client-users-table";
import { AddClientUserDrawer } from "../../common/add-client-user-drawer";
import { SearchInput } from "../../components/search-input/search-input";
import { getQueryParams, setParams } from "../../util/query-param-helper";
import { PAGE_LIMIT } from "../../constants/constants";
import FilterSVG from "../../assets/filter.svg";

interface Props {
  clientId: number;
}

export function ClientDetailsUsers(props: Props) {
  const [showAddClientUserDrawer, setShowAddClientUserDrawer] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const { clientId } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = getQueryParams(location.search, queryString) || {};
  const searchValue = queryParams.search ?? "";
  const pageOffset = queryParams?.offset ? parseInt(queryParams.offset, 10) : 0;
  let includeInactiveFlag = false;
  if (queryParams && queryParams.includeInactive !== undefined) {
    includeInactiveFlag = queryParams.includeInactive === "true";
  }
  const clientUsersQueryResult = useClientUsersQuery({
    fetchPolicy: "network-only",
    variables: {
      id: clientId,
      search: searchValue,
      offset: pageOffset,
      limit: PAGE_LIMIT,
      includeInactive: includeInactiveFlag,
    },
  });

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { search, ...remainingQueryParams } = queryParams;
    let searchParams;
    const value = event.target.value;
    if (value) {
      searchParams = setParams(remainingQueryParams, "search", `${value ? value : ""}`);
    } else {
      searchParams = setParams(remainingQueryParams);
    }
    searchParams = setParams(searchParams, "offset", "0");
    navigate({ search: `?${searchParams}` });
  }

  function onNextPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = parseInt(offset ?? "0", 10) + PAGE_LIMIT;
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onPreviousPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = Math.max(parseInt(offset ?? "0", 10) - PAGE_LIMIT, 0);
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onSwitchChange() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { includeInactive, ...remainingQueryParams } = queryParams;
    let params = setParams(remainingQueryParams, "includeInactive", `${!includeInactiveFlag}`);
    params = setParams(params, "offset", "0");
    navigate({ search: `?${params}` });
  }

  function onUserAdded() {
    clientUsersQueryResult.refetch();
  }

  function onUserDrawerClose() {
    setShowAddClientUserDrawer(false);
  }

  return (
    <>
      <AddClientUserDrawer
        clientId={clientId}
        isOpen={showAddClientUserDrawer}
        onAdded={onUserAdded}
        onClose={onUserDrawerClose}
      />
      <Container centerHorizontally>
        <Flex alignItems="center">
          <PageHeading>Users</PageHeading>
        </Flex>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                <SearchInput onChange={onChange} />
              </Box>
              <Stack isInline spacing={2}>
                <Box>
                  <SecondaryButton
                    onClick={() => {
                      setShowFilter(true);
                    }}
                  >
                    Filter <Image src={FilterSVG} ml={2} />
                  </SecondaryButton>
                  <Popover isOpen={showFilter} onClose={() => setShowFilter(false)}>
                    <PopoverTrigger>
                      <Box top="-8px" position="relative" />
                    </PopoverTrigger>
                    <PopoverContent zIndex={4}>
                      <Flex justifyContent="space-between" padding={4}>
                        <FormLabel htmlFor="inactive-switch">Hide Inactive Users</FormLabel>
                        <Switch id="inactive-switch" isChecked={!includeInactiveFlag} onChange={onSwitchChange} />
                      </Flex>
                    </PopoverContent>
                  </Popover>
                </Box>
                <Box marginRight={2}>
                  <PrimaryButton
                    onClick={() => {
                      setShowAddClientUserDrawer(true);
                    }}
                  >
                    Add User
                  </PrimaryButton>
                </Box>
              </Stack>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <UsersTable
              clientUsersQueryResult={clientUsersQueryResult}
              onPreviousPage={onPreviousPage}
              onNextPage={onNextPage}
            />
          </PanelBottom>
        </PanelGroup>
      </Container>
    </>
  );
}

import { DateTime } from "luxon";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useClientDetailsOverview } from "./use-client-details-overview";
import { PageHeading } from "../../components/page-heading";
import { AlertWarning } from "../../components/alerts";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { ClientDetails } from "./client-details";
import { CompanyDetails } from "./company-details";
import { WebsiteDetails } from "./website-details";
// import { BusinessListingDetails } from "./business-listing-details";
import { ContentDetails } from "./content-details";
import { CustomerNotes } from "../../common/customer-notes";
// import { CompanyImages } from "./company-images";
// import { HoursOfOperation } from "./hours-of-operation";

interface Props {
  customerId: number;
}

export function ClientDetailsOverview(props: Props) {
  const { customerId } = props;
  const { scheduledStatusChange } = useClientDetailsOverview(customerId);
  return (
    <>
      <PageHeading>Overview</PageHeading>
      {scheduledStatusChange && (
        <AlertWarning>
          <Text>
            This account will be <strong>{scheduledStatusChange.status.name}</strong> on{" "}
            <strong>{DateTime.fromISO(scheduledStatusChange.effectiveAt).toFormat(DATE_MONTH_FORMAT)}</strong>.
          </Text>
        </AlertWarning>
      )}
      <SimpleGrid columns={{ base: 1, xl: 2 }} gridGap={4}>
        <Box>
          <ClientDetails customerId={customerId} />
          <CompanyDetails customerId={customerId} />
          {/* <BusinessListingDetails customerId={customerId} /> */}
          <ContentDetails customerId={customerId} />
          <WebsiteDetails customerId={customerId} />
        </Box>
        <Box>
          <CustomerNotes customerId={customerId} />
          {/* <CompanyImages customerId={customerId} /> */}
          {/* <HoursOfOperation customerId={customerId} /> */}
        </Box>
      </SimpleGrid>
    </>
  );
}

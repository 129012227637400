import { Text, Tooltip } from "@chakra-ui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../components/app-icon";
import { PARTNER_INVOICE_PAYMENT_STATUS } from "../../constants/partner-invoice-payment-status";
import { AppColors } from "../../core/custom-theme";

interface Props {
  status?: {
    id: string;
    name: string;
    description: string;
  } | null;
  errorMessage?: string | null;
}

export function PaymentStatus(props: Props) {
  const { errorMessage, status } = props;
  if (!status) {
    return <Text>-</Text>;
  }
  if (!!errorMessage && status.id === PARTNER_INVOICE_PAYMENT_STATUS.FAILED.id) {
    return (
      <Text>
        {status.name}
        <Tooltip label={errorMessage}>
          <span>
            <AppIcon icon={faExclamationTriangle} standardLeftMargin color={AppColors.error} />
          </span>
        </Tooltip>
      </Text>
    );
  } else {
    return <Text>{status.name}</Text>;
  }
}

import * as yup from "yup";
import { useForceDeleteOrderModal_DeleteOrderMutation } from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import { handleGraphQLResponseError } from "../../util/error-helper";
import { useFormik } from "formik";

interface Options {
  orderId: number | null;
  onCancel: () => void;
  onDeleted: () => void;
}

interface FormValues {
  confirmText: string;
}

export function useForceDeleteOrderModal(options: Options) {
  const { onCancel, onDeleted, orderId } = options;
  const toast = useToast();
  const [deleteOrderMutation] = useForceDeleteOrderModal_DeleteOrderMutation();

  const confirmPrompt = `Delete Order ${orderId}`;

  const formik = useFormik<FormValues>({
    initialValues: {
      confirmText: "",
    },
    validationSchema: yup.object().shape({
      confirmText: yup.string().label("Confirmation Text").required().equals([confirmPrompt]),
    }),
    validateOnMount: true,
    onSubmit: async () => {
      if (!orderId) {
        return;
      }
      try {
        const response = await deleteOrderMutation({
          variables: {
            input: {
              orderId,
              forceDelete: true,
            },
          },
        });

        if (response.data?.deleteOrder.isDeleted === true) {
          formik.resetForm();
          onDeleted();
        }
      } catch (e: any) {
        handleGraphQLResponseError(e, toast);
      }
    },
  });

  function handleCancel() {
    formik.resetForm();
    onCancel();
  }

  return {
    confirmPrompt,
    handleCancel,
    formik,
  };
}

import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Textarea,
  useToast,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { PrimaryButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useAddDomainsMutation } from "../../generated/graphql";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAdded: () => void;
}

interface FormValues {
  domains: string;
}

const createDomainsSchema = yup.object().shape({
  domains: yup.string().label("Domains").required().min(2),
});

export function AddDomainsDrawer(props: Props) {
  const { onClose, onAdded, isOpen } = props;
  const [addDomains] = useAddDomainsMutation();
  const toast = useToast();

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const ids = values.domains.split("\n");
      const response = await addDomains({
        variables: {
          input: {
            ids,
          },
        },
      });

      if (response.data?.createDomains.domains) {
        const createdDomains = response.data?.createDomains.domains ?? [];
        toast({
          title: "Success",
          description: `${createdDomains.length} domain${createdDomains.length > 1 ? "s" : ""} created successfully!`,
          status: "success",
        });
        formikHelpers.resetForm();
        onAdded();
        onClose();
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      domains: "",
    },
    validationSchema: createDomainsSchema,
    onSubmit: handleSubmit,
  });

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="sm" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add Domain(s)</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <FormHelperText>
              Provide domains to be added (one per line). Domains that have already been added in the system will not be
              added again.
            </FormHelperText>
            <FormControl isRequired isInvalid={!!formik.errors.domains && !!formik.touched.domains}>
              <FormLabel>Domains</FormLabel>
              <Textarea
                variant="outline"
                id="domains"
                resize="none"
                value={formik.values.domains}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormErrorMessage>{formik.errors.domains}</FormErrorMessage>
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <PrimaryButton width="100%" onClick={formik.submitForm} isDisabled={formik.isSubmitting}>
            Save
          </PrimaryButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

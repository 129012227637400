import { Badge, Tooltip } from "@chakra-ui/react";

import { PARTNER_INVOICE_STATUS } from "../../constants/partner-invoice-status";

interface Props {
  status?: {
    id: string;
    name: string;
    description: string;
  } | null;
}

export function PartnerInvoiceStatusIndicator(props: Props) {
  const { status } = props;
  let color = "gray";
  const label = status?.description ?? "No description available.";
  if (status?.id === PARTNER_INVOICE_STATUS.OPEN.id) {
    color = "blue";
  } else if (status?.id === PARTNER_INVOICE_STATUS.COMPLETED.id) {
    color = "green";
  }
  const indicator = (
    <Badge cursor="help" colorScheme={color}>
      {status?.name ?? "Unknown"}
    </Badge>
  );

  return <Tooltip label={label}>{indicator}</Tooltip>;
}

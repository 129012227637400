import { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useCreatePartnerPaymentMethodSetupIntentMutation } from "../../generated/graphql";
import { handleGraphQLResponseError } from "../../util/error-helper";
import { useToast } from "@chakra-ui/react";
import { Config } from "../../core/config";

interface Options {
  partnerId: number;
  onAdded: () => void;
}

export function useAddPaymentMethodModal(options: Options) {
  const [createPartnerPaymentMethodSetupIntentMutation] = useCreatePartnerPaymentMethodSetupIntentMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();

  async function handleSubmit(e: React.FormEvent<any>) {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      setIsSubmitting(true);

      const setupIntent = await createPartnerPaymentMethodSetupIntentMutation({
        variables: {
          input: {
            partnerId: options.partnerId,
          },
        },
      });

      if (
        !setupIntent.data?.createPartnerPaymentMethodSetupIntent.ok ||
        !setupIntent.data.createPartnerPaymentMethodSetupIntent.clientSecret
      ) {
        throw new Error(
          setupIntent.data?.createPartnerPaymentMethodSetupIntent.error?.message ??
            "Unable to add payment method at this time. Please try again."
        );
      }

      const card = elements.getElement(CardElement);
      if (!card) {
        throw new Error("Something went wrong while adding the card. Please try again.");
      }
      const result = await stripe.confirmCardSetup(
        setupIntent.data.createPartnerPaymentMethodSetupIntent.clientSecret,
        {
          payment_method: {
            card,
          },
        }
      );

      if (result.error) {
        console.error("Stripe Error", result.error);
        throw new Error(result.error.message);
      } else {
        options.onAdded();
      }
    } catch (e: any) {
      handleGraphQLResponseError(e, toast);
    } finally {
      setIsSubmitting(false);
    }
  }

  return {
    isSubmitting,
    handleSubmit,
    showTestData: !Config.isProduction,
  };
}

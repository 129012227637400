import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import { faCreditCard, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export const PAYMENT_METHOD_CARD_TYPES = [
  {
    id: "amex",
    name: "American Express",
    icon: faCcAmex,
  },
  {
    id: "diners",
    name: "Diners Club",
    icon: faCcDinersClub,
  },
  {
    id: "discover",
    name: "Discover",
    icon: faCcDiscover,
  },
  {
    id: "jcb",
    name: "JCB",
    icon: faCcJcb,
  },
  {
    id: "mastercard",
    name: "MasterCard",
    icon: faCcMastercard,
  },
  {
    id: "unionpay",
    name: "UnionPay",
    icon: faCreditCard,
  },
  {
    id: "unknown",
    name: "Unknown",
    icon: faQuestionCircle,
  },
  {
    id: "visa",
    name: "Visa",
    icon: faCcVisa,
  },
];

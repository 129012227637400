import { useOrderDetailsInvoiceQuery } from "../../../generated/graphql";

export function useOrderDetailsInvoice(orderId: number) {
  const queryResult = useOrderDetailsInvoiceQuery({
    fetchPolicy: "network-only",
    variables: {
      orderId,
    },
  });

  const order = queryResult.data?.order ?? null;
  const partnerInvoiceCharges = order?.partnerInvoiceCharges ?? [];

  return {
    orderId,
    loading: queryResult.loading,
    order,
    partnerInvoiceCharges,
  };
}

import { Box, Flex, Heading, HStack, Progress, Stack, Switch, Text } from "@chakra-ui/react";
import { faMinusCircle, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";

import { PrimaryButton, PrimaryIconButton } from "../../components/buttons";
import { PanelBottom, PanelGroup, PanelMiddle, PanelTop } from "../../components/panels";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { PartnerPackageDetails_PackageItemFragment } from "../../generated/graphql";
import { currencyFormatter } from "../../util/formatter";

interface Props {
  monthIndex: number;
  monthlySpend: number;
  items: PartnerPackageDetails_PackageItemFragment[];
  onQuantityChange: (packageItemId: number, quantity: number) => void;
  onIsRecurringChange: (packageItemId: number, isRecurring: boolean) => void;
  onAddItem: () => void;
  onDeleteItem: (partnerPackageItemId: number) => void;
}

export function PackageMonth(props: Props) {
  const { items, monthIndex, monthlySpend, onQuantityChange, onIsRecurringChange, onAddItem, onDeleteItem } = props;

  const total = items.map((x) => x.totalPrice).reduce((sum, item) => sum + item, 0);
  const percentAllocated = Math.round((total / monthlySpend) * 100);

  let progressColor = "blue";
  if (percentAllocated >= 95 && percentAllocated <= 105) {
    progressColor = "green";
  } else if (percentAllocated > 105) {
    progressColor = "red";
  } else if (percentAllocated < 80) {
    progressColor = "yellow";
  }

  return (
    <PanelGroup>
      <PanelTop showBottomBorder>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">Month {monthIndex + 1}</Heading>
          <PrimaryButton onClick={onAddItem}>Add Item</PrimaryButton>
        </Flex>
      </PanelTop>
      <PanelMiddle>
        <Stack spacing={2}>
          <Progress color={progressColor} value={Math.min(100, percentAllocated)} />
          <Box>
            <Text>
              {currencyFormatter.format(total)} / {currencyFormatter.format(monthlySpend)}
            </Text>
          </Box>
        </Stack>
      </PanelMiddle>
      <PanelBottom containsTable>
        <Table isEmpty={items.length === 0} isLoading={false}>
          <TableHead>
            <TableRow key="head">
              <TableHeaderCell>Product Type</TableHeaderCell>
              <TableHeaderCell>Quantity</TableHeaderCell>
              <TableHeaderCell>Is Recurring</TableHeaderCell>
              <TableHeaderCell>Unit Price</TableHeaderCell>
              <TableHeaderCell>Total Price</TableHeaderCell>
              <TableHeaderCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.partnerProduct.name}</TableCell>
                <TableCell>
                  <HStack spacing={2} alignItems="center">
                    <PrimaryIconButton
                      icon={faMinusCircle}
                      isDisabled={item.quantity <= 1}
                      actionName="Decrease Quantity"
                      onClick={() => {
                        onQuantityChange(item.id, Math.max(0, item.quantity - 1));
                      }}
                    />
                    <Text>{item.quantity}</Text>
                    <PrimaryIconButton
                      icon={faPlusCircle}
                      actionName="Increase Quantity"
                      onClick={() => {
                        onQuantityChange(item.id, item.quantity + 1);
                      }}
                    />
                  </HStack>
                </TableCell>
                <TableCell>
                  <Switch
                    id="isRecurring"
                    onChange={() => {
                      onIsRecurringChange(item.id, !item.isRecurring);
                    }}
                    isChecked={item.isRecurring}
                  />
                </TableCell>
                <TableCell>{currencyFormatter.format(item.unitPrice)}</TableCell>
                <TableCell>{currencyFormatter.format(item.totalPrice)}</TableCell>
                <TableCell>
                  <PrimaryIconButton
                    actionName="Remove"
                    icon={faTrash}
                    onClick={() => {
                      onDeleteItem(item.id);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PanelBottom>
    </PanelGroup>
  );
}

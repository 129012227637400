import { sortBy } from "lodash";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";

import { CustomMultiSelect } from "../custom-selector";
import { useProductSelectorQuery } from "../../generated/graphql";

interface Props {
  selectedProductIds: string[];
  onChange: (value: string[]) => void;
  showSelectAll?: boolean;
}

export function ProductMultiSelect(props: Props) {
  const queryResult = useProductSelectorQuery({ fetchPolicy: "network-only" });

  const products = sortBy(queryResult.data?.products.nodes ?? [], "name");

  const items = products.map((product) => ({ displayText: product.name, value: product.id }));

  return (
    <CustomMultiSelect
      items={items}
      onChange={props.onChange}
      value={props.selectedProductIds}
      pluralizedItemName="Products"
      emptyValueTitle="All"
      selectorIcon={faBoxOpen}
      showSelectAll={props.showSelectAll}
    />
  );
}

import { useAuthContext } from "../../core/auth-manager/auth-manager-hook";

interface Props {
  children: React.ReactNode;
  requiresSuperAdmin?: boolean;
  requiresPartnerAdmin?: boolean;
}

export function AdminOnly(props: Props) {
  const { children, requiresSuperAdmin, requiresPartnerAdmin } = props;
  const authContext = useAuthContext();

  if (requiresSuperAdmin && authContext.isSuperAdmin()) {
    return <>{children}</>;
  } else if (authContext.isAdmin()) {
    return <>{children}</>;
  } else if (requiresPartnerAdmin && authContext.hasPartnerUserAdmin()) {
    return <>{children}</>;
  }

  return null;
}

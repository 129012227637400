import { DateTime } from "luxon";
import { FormikHelpers, useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import { useAmTaskQuery, useUpdateAmTaskMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { DATE_FORMAT } from "../../constants/constants";
import { FormHelpers } from "../../util/form-helpers";

interface FormValues {
  taskType: string;
  completedAt: Date;
  info?: string | null;
}

interface Props {
  amTaskId: number;
  onCancel: () => void;
  onAmTaskEdited: () => void;
}

export function useEditAmTaskDrawer(props: Props) {
  const { amTaskId, onAmTaskEdited, onCancel } = props;

  const queryResult = useAmTaskQuery({
    variables: {
      amTaskId,
    },
    fetchPolicy: "network-only",
    skip: !amTaskId,
  });
  const [updateAmTask] = useUpdateAmTaskMutation();
  const toast = useToast();

  const amTask = queryResult.data?.amTask;

  async function onSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await updateAmTask({
        variables: {
          input: {
            amTaskId,
            taskType: values.taskType,
            info: FormHelpers.processNullableString(values.info ?? ""),
            completedAt: DateTime.fromJSDate(values.completedAt).toFormat(DATE_FORMAT),
          },
        },
      });

      if (!response.data?.updateAmTask.ok) {
        toast({
          title: "Update Am Task",
          description: getDisplayMessageForError(
            response.errors?.[0] ?? new Error("Something went wrong while updating am task.")
          ),
          status: "error",
        });
        formikHelpers.setSubmitting(false);
        return;
      }
      toast({
        title: "Update Am Task",
        description: "Task updated successfully!",
        status: "success",
      });
      onAmTaskEdited();
      onCancel();
    } catch (e: any) {
      toast({ title: "Unable to create am task.", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      taskType: amTask?.taskType ?? "",
      completedAt: amTask?.completedAt ?? new Date(),
      info: amTask?.info,
    },
    enableReinitialize: true,
    onSubmit,
  });

  function handleCancel() {
    formik.resetForm();
    onCancel();
  }

  return {
    formik,
    handleCancel,
  };
}

import { Select, FormLabel } from "@chakra-ui/react";

interface Props {
  timezone: string;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

function TimeZoneSelector(props: Props) {
  const { timezone, handleChange } = props;

  return (
    <>
      <FormLabel htmlFor="timeZoneId">Time Zone</FormLabel>
      <Select placeholder="Select a timezone" onChange={handleChange} value={timezone} id="timezone" variant="outline">
        <option key="America/Adak" value="America/Adak">
          America/Adak (HST)
        </option>
        <option key="America/Argentina/Salta" value="America/Argentina/Salta">
          America/Argentina/Salta (ART)
        </option>
        <option key="America/Anchorage" value="America/Anchorage">
          America/Anchorage (AKST)
        </option>
        <option key="America/Anguilla" value="America/Anguilla">
          America/Anguilla (AST)
        </option>
        <option key="America/Antigua" value="America/Antigua">
          America/Antigua (AST)
        </option>
        <option key="America/Araguaina" value="America/Araguaina">
          America/Araguaina (BRT)
        </option>
        <option key="America/Argentina/Buenos_Aires" value="America/Argentina/Buenos_Aires">
          America/Argentina/Buenos_Aires (ART)
        </option>
        <option key="America/Argentina/Catamarca" value="America/Argentina/Catamarca">
          America/Argentina/Catamarca (ART)
        </option>
        <option key="America/Argentina/Cordoba" value="America/Argentina/Cordoba">
          America/Argentina/Cordoba (ART)
        </option>
        <option key="America/Argentina/Jujuy" value="America/Argentina/Jujuy">
          America/Argentina/Jujuy (ART)
        </option>
        <option key="America/Argentina/La_Rioja" value="America/Argentina/La_Rioja">
          America/Argentina/La_Rioja (ART)
        </option>
        <option key="America/Argentina/Mendoza" value="America/Argentina/Mendoza">
          America/Argentina/Mendoza (ART)
        </option>
        <option key="America/Argentina/Rio_Gallegos" value="America/Argentina/Rio_Gallegos">
          America/Argentina/Rio_Gallegos (ART)
        </option>
        <option key="America/Argentina/San_Juan" value="America/Argentina/San_Juan">
          America/Argentina/San_Juan (ART)
        </option>
        <option key="America/Argentina/San_Luis" value="America/Argentina/San_Luis">
          America/Argentina/San_Luis (ART)
        </option>
        <option key="America/Argentina/Tucuman" value="America/Argentina/Tucuman">
          America/Argentina/Tucuman (ART)
        </option>
        <option key="America/Aruba" value="America/Aruba">
          America/Aruba (AST)
        </option>
        <option key="America/Argentina/Ushuaia" value="America/Argentina/Ushuaia">
          America/Argentina/Ushuaia (ART)
        </option>
        <option key="America/Asuncion" value="America/Asuncion">
          America/Asuncion (PYST)
        </option>
        <option key="America/Bahia_Banderas" value="America/Bahia_Banderas">
          America/Bahia_Banderas (CST)
        </option>
        <option key="America/Atikokan" value="America/Atikokan">
          America/Atikokan (EST)
        </option>
        <option key="America/Bahia" value="America/Bahia">
          America/Bahia (BRT)
        </option>
        <option key="America/Barbados" value="America/Barbados">
          America/Barbados (AST)
        </option>
        <option key="America/Belem" value="America/Belem">
          America/Belem (BRT)
        </option>
        <option key="America/Belize" value="America/Belize">
          America/Belize (CST)
        </option>
        <option key="America/Blanc-Sablon" value="America/Blanc-Sablon">
          America/Blanc-Sablon (AST)
        </option>
        <option key="America/Boa_Vista" value="America/Boa_Vista">
          America/Boa_Vista (AMT)
        </option>
        <option key="America/Bogota" value="America/Bogota">
          America/Bogota (COT)
        </option>
        <option key="America/Boise" value="America/Boise">
          America/Boise (MST)
        </option>
        <option key="America/Cambridge_Bay" value="America/Cambridge_Bay">
          America/Cambridge_Bay (MST)
        </option>
        <option key="America/Campo_Grande" value="America/Campo_Grande">
          America/Campo_Grande (AMST)
        </option>
        <option key="America/Cancun" value="America/Cancun">
          America/Cancun (EST)
        </option>
        <option key="America/Caracas" value="America/Caracas">
          America/Caracas (VET)
        </option>
        <option key="America/Cayenne" value="America/Cayenne">
          America/Cayenne (GFT)
        </option>
        <option key="America/Cayman" value="America/Cayman">
          America/Cayman (EST)
        </option>
        <option key="America/Chicago" value="America/Chicago">
          America/Chicago (CST)
        </option>
        <option key="America/Chihuahua" value="America/Chihuahua">
          America/Chihuahua (MST)
        </option>
        <option key="America/Coral_Harbour" value="America/Coral_Harbour">
          America/Coral_Harbour (EST)
        </option>
        <option key="America/Costa_Rica" value="America/Costa_Rica">
          America/Costa_Rica (CST)
        </option>
        <option key="America/Creston" value="America/Creston">
          America/Creston (MST)
        </option>
        <option key="America/Cuiaba" value="America/Cuiaba">
          America/Cuiaba (AMST)
        </option>
        <option key="America/Curacao" value="America/Curacao">
          America/Curacao (AST)
        </option>
        <option key="America/Danmarkshavn" value="America/Danmarkshavn">
          America/Danmarkshavn (GMT)
        </option>
        <option key="America/Dawson" value="America/Dawson">
          America/Dawson (PST)
        </option>
        <option key="America/Dawson_Creek" value="America/Dawson_Creek">
          America/Dawson_Creek (MST)
        </option>
        <option key="America/Denver" value="America/Denver">
          America/Denver (MST)
        </option>
        <option key="America/Detroit" value="America/Detroit">
          America/Detroit (EST)
        </option>
        <option key="America/Dominica" value="America/Dominica">
          America/Dominica (AST)
        </option>
        <option key="America/Edmonton" value="America/Edmonton">
          America/Edmonton (MST)
        </option>
        <option key="America/Eirunepe" value="America/Eirunepe">
          America/Eirunepe (ACT)
        </option>
        <option key="America/El_Salvador" value="America/El_Salvador">
          America/El_Salvador (CST)
        </option>
        <option key="America/Fortaleza" value="America/Fortaleza">
          America/Fortaleza (BRT)
        </option>
        <option key="America/Glace_Bay" value="America/Glace_Bay">
          America/Glace_Bay (AST)
        </option>
        <option key="America/Godthab" value="America/Godthab">
          America/Godthab (WGT)
        </option>
        <option key="America/Goose_Bay" value="America/Goose_Bay">
          America/Goose_Bay (AST)
        </option>
        <option key="America/Grand_Turk" value="America/Grand_Turk">
          America/Grand_Turk (AST)
        </option>
        <option key="America/Grenada" value="America/Grenada">
          America/Grenada (AST)
        </option>
        <option key="America/Guadeloupe" value="America/Guadeloupe">
          America/Guadeloupe (AST)
        </option>
        <option key="America/Guatemala" value="America/Guatemala">
          America/Guatemala (CST)
        </option>
        <option key="America/Guayaquil" value="America/Guayaquil">
          America/Guayaquil (ECT)
        </option>
        <option key="America/Guyana" value="America/Guyana">
          America/Guyana (GYT)
        </option>
        <option key="America/Halifax" value="America/Halifax">
          America/Halifax (AST)
        </option>
        <option key="America/Havana" value="America/Havana">
          America/Havana (CST)
        </option>
        <option key="America/Hermosillo" value="America/Hermosillo">
          America/Hermosillo (MST)
        </option>
        <option key="America/Indiana/Petersburg" value="America/Indiana/Petersburg">
          America/Indiana/Petersburg (EST)
        </option>
        <option key="America/Indiana/Tell_City" value="America/Indiana/Tell_City">
          America/Indiana/Tell_City (CST)
        </option>
        <option key="America/Indiana/Vevay" value="America/Indiana/Vevay">
          America/Indiana/Vevay (EST)
        </option>
        <option key="America/Indiana/Indianapolis" value="America/Indiana/Indianapolis">
          America/Indiana/Indianapolis (EST)
        </option>
        <option key="America/Indiana/Knox" value="America/Indiana/Knox">
          America/Indiana/Knox (CST)
        </option>
        <option key="America/Indiana/Marengo" value="America/Indiana/Marengo">
          America/Indiana/Marengo (EST)
        </option>
        <option key="America/Indiana/Vincennes" value="America/Indiana/Vincennes">
          America/Indiana/Vincennes (EST)
        </option>
        <option key="America/Indiana/Winamac" value="America/Indiana/Winamac">
          America/Indiana/Winamac (EST)
        </option>
        <option key="America/Inuvik" value="America/Inuvik">
          America/Inuvik (MST)
        </option>
        <option key="America/Iqaluit" value="America/Iqaluit">
          America/Iqaluit (EST)
        </option>
        <option key="America/La_Paz" value="America/La_Paz">
          America/La_Paz (BOT)
        </option>
        <option key="America/Jamaica" value="America/Jamaica">
          America/Jamaica (EST)
        </option>
        <option key="America/Juneau" value="America/Juneau">
          America/Juneau (AKST)
        </option>
        <option key="America/Kentucky/Louisville" value="America/Kentucky/Louisville">
          America/Kentucky/Louisville (EST)
        </option>
        <option key="America/Kentucky/Monticello" value="America/Kentucky/Monticello">
          America/Kentucky/Monticello (EST)
        </option>
        <option key="America/Kralendijk" value="America/Kralendijk">
          America/Kralendijk (AST)
        </option>
        <option key="America/Lima" value="America/Lima">
          America/Lima (PET)
        </option>
        <option key="America/Managua" value="America/Managua">
          America/Managua (CST)
        </option>
        <option key="America/Manaus" value="America/Manaus">
          America/Manaus (AMT)
        </option>
        <option key="America/Los_Angeles" value="America/Los_Angeles">
          America/Los_Angeles (PST)
        </option>
        <option key="America/Lower_Princes" value="America/Lower_Princes">
          America/Lower_Princes (AST)
        </option>
        <option key="America/Maceio" value="America/Maceio">
          America/Maceio (BRT)
        </option>
        <option key="America/Marigot" value="America/Marigot">
          America/Marigot (AST)
        </option>
        <option key="America/Martinique" value="America/Martinique">
          America/Martinique (AST)
        </option>
        <option key="America/Matamoros" value="America/Matamoros">
          America/Matamoros (CST)
        </option>
        <option key="America/Mazatlan" value="America/Mazatlan">
          America/Mazatlan (MST)
        </option>
        <option key="America/Menominee" value="America/Menominee">
          America/Menominee (CST)
        </option>
        <option key="America/Mexico_City" value="America/Mexico_City">
          America/Mexico_City (CST)
        </option>
        <option key="America/Merida" value="America/Merida">
          America/Merida (CST)
        </option>
        <option key="America/Metlakatla" value="America/Metlakatla">
          America/Metlakatla (PST)
        </option>
        <option key="America/Miquelon" value="America/Miquelon">
          America/Miquelon (PMST)
        </option>
        <option key="America/Moncton" value="America/Moncton">
          America/Moncton (AST)
        </option>
        <option key="America/Monterrey" value="America/Monterrey">
          America/Monterrey (CST)
        </option>
        <option key="America/Montevideo" value="America/Montevideo">
          America/Montevideo (UYT)
        </option>
        <option key="America/Montreal" value="America/Montreal">
          America/Montreal (EST)
        </option>
        <option key="America/Montserrat" value="America/Montserrat">
          America/Montserrat (AST)
        </option>
        <option key="America/Nassau" value="America/Nassau">
          America/Nassau (EST)
        </option>
        <option key="America/New_York" value="America/New_York">
          America/New_York (EST)
        </option>
        <option key="America/Nipigon" value="America/Nipigon">
          America/Nipigon (EST)
        </option>
        <option key="America/Nome" value="America/Nome">
          America/Nome (AKST)
        </option>
        <option key="America/Noronha" value="America/Noronha">
          America/Noronha (FNT)
        </option>
        <option key="America/North_Dakota/Beulah" value="America/North_Dakota/Beulah">
          America/North_Dakota/Beulah (CST)
        </option>
        <option key="America/North_Dakota/Center" value="America/North_Dakota/Center">
          America/North_Dakota/Center (CST)
        </option>
        <option key="America/North_Dakota/New_Salem" value="America/North_Dakota/New_Salem">
          America/North_Dakota/New_Salem (CST)
        </option>
        <option key="America/Ojinaga" value="America/Ojinaga">
          America/Ojinaga (MST)
        </option>
        <option key="America/Panama" value="America/Panama">
          America/Panama (EST)
        </option>
        <option key="America/Pangnirtung" value="America/Pangnirtung">
          America/Pangnirtung (EST)
        </option>
        <option key="America/Paramaribo" value="America/Paramaribo">
          America/Paramaribo (SRT)
        </option>
        <option key="America/Phoenix" value="America/Phoenix">
          America/Phoenix (MST)
        </option>
        <option key="America/Port-au-Prince" value="America/Port-au-Prince">
          America/Port-au-Prince (EST)
        </option>
        <option key="America/Port_of_Spain" value="America/Port_of_Spain">
          America/Port_of_Spain (AST)
        </option>
        <option key="America/Porto_Velho" value="America/Porto_Velho">
          America/Porto_Velho (AMT)
        </option>
        <option key="America/Puerto_Rico" value="America/Puerto_Rico">
          America/Puerto_Rico (AST)
        </option>
        <option key="America/Rainy_River" value="America/Rainy_River">
          America/Rainy_River (CST)
        </option>
        <option key="America/Rankin_Inlet" value="America/Rankin_Inlet">
          America/Rankin_Inlet (CST)
        </option>
        <option key="America/Recife" value="America/Recife">
          America/Recife (BRT)
        </option>
        <option key="America/Regina" value="America/Regina">
          America/Regina (CST)
        </option>
        <option key="America/Resolute" value="America/Resolute">
          America/Resolute (CST)
        </option>
        <option key="America/Rio_Branco" value="America/Rio_Branco">
          America/Rio_Branco (ACT)
        </option>
        <option key="America/Santa_Isabel" value="America/Santa_Isabel">
          America/Santa_Isabel (PST)
        </option>
        <option key="America/Santarem" value="America/Santarem">
          America/Santarem (BRT)
        </option>
        <option key="America/Santiago" value="America/Santiago">
          America/Santiago (CLT)
        </option>
        <option key="America/Santo_Domingo" value="America/Santo_Domingo">
          America/Santo_Domingo (AST)
        </option>
        <option key="America/Scoresbysund" value="America/Scoresbysund">
          America/Scoresbysund (EGT)
        </option>
        <option key="America/Sao_Paulo" value="America/Sao_Paulo">
          America/Sao_Paulo (BRST)
        </option>
        <option key="America/Sitka" value="America/Sitka">
          America/Sitka (AKST)
        </option>
        <option key="America/St_Barthelemy" value="America/St_Barthelemy">
          America/St_Barthelemy (AST)
        </option>
        <option key="America/St_Johns" value="America/St_Johns">
          America/St_Johns (NST)
        </option>
        <option key="America/Thule" value="America/Thule">
          America/Thule (AST)
        </option>
        <option key="America/St_Kitts" value="America/St_Kitts">
          America/St_Kitts (AST)
        </option>
        <option key="America/St_Lucia" value="America/St_Lucia">
          America/St_Lucia (AST)
        </option>
        <option key="America/St_Thomas" value="America/St_Thomas">
          America/St_Thomas (AST)
        </option>
        <option key="America/St_Vincent" value="America/St_Vincent">
          America/St_Vincent (AST)
        </option>
        <option key="America/Swift_Current" value="America/Swift_Current">
          America/Swift_Current (CST)
        </option>
        <option key="America/Tegucigalpa" value="America/Tegucigalpa">
          America/Tegucigalpa (CST)
        </option>
        <option key="America/Thunder_Bay" value="America/Thunder_Bay">
          America/Thunder_Bay (EST)
        </option>
        <option key="America/Tijuana" value="America/Tijuana">
          America/Tijuana (PST)
        </option>
        <option key="America/Toronto" value="America/Toronto">
          America/Toronto (EST)
        </option>
        <option key="America/Tortola" value="America/Tortola">
          America/Tortola (AST)
        </option>
        <option key="America/Vancouver" value="America/Vancouver">
          America/Vancouver (PST)
        </option>
        <option key="America/Whitehorse" value="America/Whitehorse">
          America/Whitehorse (PST)
        </option>
        <option key="America/Winnipeg" value="America/Winnipeg">
          America/Winnipeg (CST)
        </option>
        <option key="America/Yakutat" value="America/Yakutat">
          America/Yakutat (AKST)
        </option>
        <option key="America/Yellowknife" value="America/Yellowknife">
          America/Yellowknife (MST)
        </option>
        <option key="Europe/London" value="Europe/London">
          Europe/London (GMT)
        </option>
      </Select>
    </>
  );
}

export default TimeZoneSelector;

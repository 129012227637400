export const TASK_STATUS = {
  PENDING: {
    id: "pending",
    name: "Pending",
  },
  AVAILABLE: {
    id: "available",
    name: "Available",
  },
  ASSIGNED: {
    id: "assigned",
    name: "Assigned",
  },
  COMPLETED: {
    id: "completed",
    name: "Completed",
  },
  CANCELLED: {
    id: "cancelled",
    name: "Cancelled",
  },
};

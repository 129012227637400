import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  KeywordResearchTaskWorkspace_ProposedKeywordFragment,
  useCreateProposedCustomerKeywordMutation,
  useDeleteProposedCustomerKeywordMutation,
  useUpdateProposedCustomerKeywordMutation,
} from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { FormHelpers } from "../../util/form-helpers";

interface Options {
  customerKeywordProposalId: number;
  keyword: KeywordResearchTaskWorkspace_ProposedKeywordFragment;
  refetchKeywords: () => void;
  deleteNewKeyword: () => void;
  onAddSuccess: () => void;
}

const validationSchema = yup.object().shape({
  name: yup.string().label("Name").required().nullable(false).min(1).max(255),
  location: yup.string().label("Location").notRequired().nullable(false).max(255),
  country: yup.string().label("Country").notRequired().nullable(false),
  landingPageUrl: yup.string().label("Landing Page URL").url().notRequired().nullable(true),
  isPrimary: yup.boolean().label("Is Primary").notRequired().nullable(false),
});

export function useProposedKeywordTable(options: Options) {
  const { refetchKeywords, customerKeywordProposalId, keyword, deleteNewKeyword, onAddSuccess } = options;
  const [createProposedKeywordMutation] = useCreateProposedCustomerKeywordMutation();
  const [deleteProposedKeywordMutation] = useDeleteProposedCustomerKeywordMutation();
  const [updateProposedKeywordMutation] = useUpdateProposedCustomerKeywordMutation();

  const toast = useToast();

  const formik = useFormik<KeywordResearchTaskWorkspace_ProposedKeywordFragment>({
    initialValues: {
      location: keyword?.location ?? "",
      country: keyword?.country ?? "",
      name: keyword?.name ?? "",
      landingPageUrl: keyword?.landingPageUrl ?? "",
      isPrimary: keyword?.isPrimary ?? false,
      id: keyword?.id ?? 0,
      rejectionReason: keyword?.rejectionReason ?? "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => createOrUpdate(values),
  });

  async function createOrUpdate(values: KeywordResearchTaskWorkspace_ProposedKeywordFragment, isPrimary?: boolean) {
    if (
      keyword?.name === values.name &&
      keyword?.location === values.location &&
      keyword?.country === values.country &&
      keyword?.isPrimary === (isPrimary ?? values.isPrimary) &&
      keyword?.landingPageUrl === values.landingPageUrl
    ) {
      return;
    }
    try {
      if (values.id === 0) {
        if (!values.name) {
          return;
        }
        await createProposedKeywordMutation({
          variables: {
            input: {
              customerKeywordProposalId,
              isPrimary: isPrimary ?? values.isPrimary,
              name: values.name,
              location: FormHelpers.processNullableString(values.location ?? ""),
              country: FormHelpers.processNullableString(values.country ?? ""),
              landingPageUrl: FormHelpers.processNullableString(values.landingPageUrl ?? ""),
            },
          },
        });
      } else {
        await updateProposedKeywordMutation({
          variables: {
            input: {
              proposedCustomerKeywordId: values.id,
              isPrimary: isPrimary ?? values.isPrimary,
              name: values.name,
              location: FormHelpers.processNullableString(values.location ?? ""),
              country: FormHelpers.processNullableString(values.country ?? ""),
              landingPageUrl: FormHelpers.processNullableString(values.landingPageUrl ?? ""),
            },
          },
        });
      }
      refetchKeywords();
      onAddSuccess();
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  async function deleteKeyword(proposedKeywordId: number) {
    if (formik.values.id === 0) {
      formik.resetForm();
      deleteNewKeyword();
      return;
    }
    try {
      await deleteProposedKeywordMutation({
        variables: {
          input: {
            proposedCustomerKeywordId: proposedKeywordId,
          },
        },
      });
      refetchKeywords();
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  return {
    deleteKeyword,
    formik,
    createOrUpdate,
  };
}

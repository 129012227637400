import * as yup from "yup";
import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import {
  useTaskWorkspaceBusinessListingQuery,
  useTaskWorkspaceBusinessListing_UpdateOrderMutation,
} from "../../generated/graphql";
import { handleFormGraphQLError } from "../../util/error-helper";
import { FormHelpers } from "../../util/form-helpers";

interface Options {
  taskId: number;
  onAbandon: () => void;
  onComplete: () => void;
}

interface FormValues {
  resultUrl: string;
  resultUrlDomainAuthority?: number | null;
}

export function useTaskWorkspaceBusinessListing(options: Options) {
  const { taskId, onComplete } = options;
  const queryResult = useTaskWorkspaceBusinessListingQuery({
    fetchPolicy: "network-only",
    variables: {
      taskId,
    },
  });
  const [updateOrderMutation] = useTaskWorkspaceBusinessListing_UpdateOrderMutation();
  const toast = useToast();

  const task = queryResult.data?.task ?? null;
  const businessListing = queryResult.data?.task?.customer.customerBusinessListingSpec ?? null;

  const formik = useFormik<FormValues>({
    initialValues: {
      resultUrl: task?.order.resultUrl ?? "",
      resultUrlDomainAuthority: task?.order?.resultUrlDomainAuthority ?? null,
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      resultUrl: yup
        .string()
        .label("Result URL")
        .when("resultUrlDomainAuthority", {
          is: (resultUrlDomainAuthority: any) => Boolean(resultUrlDomainAuthority),
          then: yup.string().required("Result URL is required while adding Result URL Domain Authority.").url(),
          otherwise: yup.string().notRequired().nullable(true).url(),
        }),
      resultUrlDomainAuthority: yup.number().label("Result URL Domain Authority").notRequired().min(0).nullable(true),
    }),
    onSubmit: async (values, helpers) => {
      if (!task?.order) {
        return;
      }
      try {
        await updateOrderMutation({
          variables: {
            input: {
              orderId: task?.order.id,
              resultUrl: FormHelpers.processNullableString(values.resultUrl),
              resultUrlDomainAuthority: FormHelpers.processNullableInt(values.resultUrlDomainAuthority),
            },
          },
        });
        onComplete();
      } catch (e: any) {
        handleFormGraphQLError(e, "Unable to Complete Task", toast, helpers.setErrors);
      }
    },
  });

  return {
    loading: queryResult.loading,
    task,
    businessListing,
    formik,
  };
}

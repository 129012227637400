import { sortBy } from "lodash";
import { SelectProps, Select } from "@chakra-ui/react";
import { useCampaignCategoriesForSelectorQuery } from "../../generated/graphql";

interface Props extends SelectProps {
  selectedCampaignCategoryId?: string | null;
  onCampaignCategorySelected?: (campaignCategoryId: string) => void;
  showNoCampaignCategoryOption?: boolean;
}

export function CampaignCategorySelector(props: Props) {
  const { selectedCampaignCategoryId, onCampaignCategorySelected, showNoCampaignCategoryOption, ...rest } = props;
  const campaignCategoriesQueryResult = useCampaignCategoriesForSelectorQuery({
    fetchPolicy: "cache-and-network",
  });

  const campaignCategories = sortBy(campaignCategoriesQueryResult.data?.campaignCategories.nodes ?? [], "name");

  return (
    <Select
      isDisabled={campaignCategoriesQueryResult.loading}
      value={selectedCampaignCategoryId ?? ""}
      onChange={(e) => {
        onCampaignCategorySelected && onCampaignCategorySelected(e.target.value);
      }}
      {...rest}
    >
      {showNoCampaignCategoryOption && (
        <option value="" disabled>
          No Campaign Category
        </option>
      )}
      {campaignCategories.map((campaignCategory) => (
        <option key={campaignCategory.id} value={campaignCategory.id}>
          {campaignCategory.name}
        </option>
      ))}
    </Select>
  );
}

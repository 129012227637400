import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useBillingAutomaticPaymentsQuery, useBilling_UpdatePartnerMutation } from "../../generated/graphql";
import { handleGraphQLResponseError } from "../../util/error-helper";

export function useAutomaticPayments(partnerId: number) {
  const queryResult = useBillingAutomaticPaymentsQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId,
    },
  });
  const [selectedPartnerPaymentMethodId, setSelectedPartnerPaymentMethodId] = useState<number | null>(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const toast = useToast();

  const [updatePartnerMutation, { loading: isProcessing }] = useBilling_UpdatePartnerMutation();

  const partner = queryResult.data?.partner ?? null;

  useEffect(() => {
    if (partner?.autopayPartnerPaymentMethodId !== selectedPartnerPaymentMethodId) {
      setSelectedPartnerPaymentMethodId(partner?.autopayPartnerPaymentMethodId ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner]);

  function handlePartnerPaymentMethodSelected(value: number | null) {
    setSelectedPartnerPaymentMethodId(value);
  }

  async function handleEnabledClick() {
    if (partner?.isAutopayEnabled) {
      try {
        const response = await updatePartnerMutation({
          variables: {
            input: {
              partnerId,
              isAutopayEnabled: false,
              autopayPartnerPaymentMethodId: null,
            },
          },
        });
        if (!response.data?.updatePartner.partner) {
          throw new Error("Something went wrong, please try again.");
        }
        toast({
          title: "Automatic Payments Disabled",
          description: "Automatic payments have been disabled.",
          status: "info",
        });
        setTimeout(() => {
          queryResult.refetch();
        }, 1);
      } catch (e: any) {
        handleGraphQLResponseError(e, toast);
      }
    } else {
      setShowConfirm(true);
    }
  }

  async function handleEnableConfirm() {
    if (!selectedPartnerPaymentMethodId) {
      return;
    }

    try {
      const response = await updatePartnerMutation({
        variables: {
          input: {
            partnerId,
            isAutopayEnabled: !!selectedPartnerPaymentMethodId,
            autopayPartnerPaymentMethodId: selectedPartnerPaymentMethodId,
          },
        },
      });
      if (!response.data?.updatePartner.partner) {
        throw new Error("Something went wrong, please try again.");
      }
      toast({
        title: "Thank you!",
        description: "Automatic payments have been enabled.",
        status: "success",
      });
      queryResult.refetch();
      setShowConfirm(false);
    } catch (e: any) {
      handleGraphQLResponseError(e, toast);
    }
  }

  function handleEnableCancel() {
    setShowConfirm(false);
    setIsTermsAccepted(false);
    setSelectedPartnerPaymentMethodId(null);
  }

  function handleTermsAcceptedChange(e: React.ChangeEvent<HTMLInputElement>) {
    setIsTermsAccepted(e.target.checked);
  }

  return {
    partnerId,
    partner,
    showPaymentMethod: partner?.isAutopayEnabled,
    handleEnabledClick,
    handleEnableConfirm,
    handleEnableCancel,
    handlePartnerPaymentMethodSelected,
    selectedPartnerPaymentMethodId,
    showConfirm,
    isTermsAccepted,
    handleTermsAcceptedChange,
    isProcessing,
  };
}

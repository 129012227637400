import { Box, Switch, Tooltip, useToast } from "@chakra-ui/react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
  TablePagination,
  TableFoot,
} from "../../components/table";
import { CategoriesQueryResult, useUpdateCategoryMutation } from "../../generated/graphql";
import { PAGE_LIMIT } from "../../constants/constants";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { PrimaryIconButton } from "../../components/buttons";

interface Props {
  categoriesQueryResult: CategoriesQueryResult;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onEditCategory: (categoryId: number) => void;
}

export function AdminCategoriesTable(props: Props) {
  const { categoriesQueryResult, onNextPage, onPreviousPage, onEditCategory } = props;
  const totalCount = categoriesQueryResult.data?.categories.totalCount ?? 0;
  const categories = categoriesQueryResult.data?.categories.nodes ?? [];
  const [updateCategory] = useUpdateCategoryMutation();
  const toast = useToast();

  async function onSwitchChange(categoryId: number, name: string, event: React.ChangeEvent<HTMLInputElement>) {
    const isChecked = event.target.checked;
    const response = await updateCategory({
      variables: {
        input: {
          categoryId,
          isActive: isChecked,
        },
      },
    });

    if (response.data?.updateCategory.category.id) {
      toast({
        title: "Success",
        description: `"${name}" has been ${isChecked ? "activated" : "deactivated"} successfully.`,
        status: "success",
      });
      categoriesQueryResult.refetch();
    }
  }

  return (
    <Table isEmpty={categories.length === 0} isLoading={categoriesQueryResult.loading}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Active</TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {categories.map((category) => (
          <TableRow key={category.id}>
            <TableCell>{category.name}</TableCell>
            <TableCell>
              <Switch onChange={onSwitchChange.bind(null, category.id, category.name)} isChecked={category.isActive} />
            </TableCell>
            <TableCell>
              <Box textAlign="right">
                <Tooltip title="Edit" aria-label="Edit">
                  <PrimaryIconButton
                    onClick={() => {
                      onEditCategory(category.id);
                    }}
                    icon={faPen}
                    actionName="Edit Category"
                  />
                </Tooltip>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFoot>
        <TablePagination
          totalCount={totalCount}
          offset={categoriesQueryResult.variables?.offset ?? 0}
          limit={categoriesQueryResult.variables?.limit ?? PAGE_LIMIT}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
      </TableFoot>
    </Table>
  );
}

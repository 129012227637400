import { useEffect, useMemo } from "react";
import { DateTime } from "luxon";
import { SelectProps, Select } from "@chakra-ui/react";
import { useCustomerCampaignSelectorQuery } from "../../generated/graphql";
import { DATE_MONTH_FORMAT } from "../../constants/constants";

interface Props extends SelectProps {
  customerId: number;
  allowNull?: boolean;
  selectedCustomerCampaignId: number | null;
  onSelectedCustomerCampaignChange: (value: number | null) => void;
  nullText?: string;
  disableEndedCampaigns?: boolean;
}

export function CustomerCampaignSelector(props: Props) {
  const {
    customerId,
    allowNull,
    selectedCustomerCampaignId,
    onSelectedCustomerCampaignChange,
    nullText,
    disableEndedCampaigns,
    ...rest
  } = props;
  const queryResult = useCustomerCampaignSelectorQuery({
    fetchPolicy: "network-only",
    variables: {
      customerId,
    },
  });

  const campaigns = useMemo(() => queryResult.data?.customer?.customerCampaigns.nodes ?? [], [queryResult]);

  // Auto select the first campaign under specific conditions
  useEffect(() => {
    if (!allowNull && !selectedCustomerCampaignId && campaigns.length > 0) {
      onSelectedCustomerCampaignChange(campaigns[0].id);
    }
  }, [allowNull, campaigns, selectedCustomerCampaignId, onSelectedCustomerCampaignChange]);

  if (queryResult.loading) {
    return <Select {...rest} placeholder="Loading..." isDisabled />;
  }

  return (
    <Select
      value={selectedCustomerCampaignId?.toString() ?? ""}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === "") {
          onSelectedCustomerCampaignChange(null);
        } else {
          onSelectedCustomerCampaignChange(parseInt(event.target.value));
        }
      }}
      {...rest}
    >
      {(allowNull || (!allowNull && !selectedCustomerCampaignId)) && (
        <option value="" disabled={!allowNull}>
          {nullText ?? "Not Selected"}
        </option>
      )}
      {campaigns.map((campaign) => {
        const dateString = `${DateTime.fromJSDate(campaign.startsAt)
          .toUTC()
          .toFormat(DATE_MONTH_FORMAT)} - ${DateTime.fromJSDate(campaign.endsAt).toUTC().toFormat(DATE_MONTH_FORMAT)}`;
        return (
          <option
            key={campaign.id}
            value={campaign.id.toString()}
            disabled={disableEndedCampaigns && campaign.endsAt < new Date()}
          >
            {campaign.name} ({dateString})
          </option>
        );
      })}
    </Select>
  );
}

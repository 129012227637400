import { Route, Navigate, useParams, Routes } from "react-router-dom";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PartnerDetailsSecondaryNav } from "./partner-details-secondary-nav";
import { AdminPartnerOverview } from "../admin-partner-overview";

export function AdminPartnerDetailsRoute() {
  const { id, "*": section } = useParams<"id" | "*">();
  const partnerId = parseInt(id ?? "");
  if (!section || section === "") {
    return <Navigate to={`/admin/partners/${id}/overview`} />;
  }

  if (section === "configuration") {
    return <Navigate to={`/configuration/${partnerId}`} />;
  }

  return (
    <LayoutBase pageTitle="Partner Details">
      <MainNav hasSecondaryNav />
      <PartnerDetailsSecondaryNav partnerId={partnerId} activeSection={section} />
      <Container centerHorizontally>
        <Routes>
          <Route path="overview" element={<AdminPartnerOverview />} />
        </Routes>
      </Container>
    </LayoutBase>
  );
}

import { Badge } from "@chakra-ui/react";
import { CUSTOMER_STATUS } from "../../constants/customer-status";

interface Props {
  status: {
    id: string;
    name: string;
  };
}

export function CustomerStatusIndicator(props: Props) {
  const { status } = props;

  let color: string | undefined = undefined;
  if (status.id === CUSTOMER_STATUS.ACTIVE.id) {
    color = "green";
  } else if (status.id === CUSTOMER_STATUS.PAUSED.id) {
    color = "yellow";
  } else if (status.id === CUSTOMER_STATUS.CANCELLED.id) {
    color = "red";
  }
  return <Badge colorScheme={color}>{status.name}</Badge>;
}

import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { Heading, Box, Stack, FormControl, FormLabel, Input, Switch, FormHelperText } from "@chakra-ui/react";
import AppLink from "../../components/app-link";
import { usePartnerPackageDetails } from "./use-partner-package-details";
import AppIcon from "../../components/app-icon";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { PackageMonth } from "./package-month";
import { AddPackageItemDrawer } from "./add-package-item-drawer";
import { PrimaryButton, CancelButton } from "../../components/buttons";
import { LayoutBase, MainNav } from "../../components/layout";
import { ConfigurationSecondaryNav } from "../../common/configuration-secondary-nav";
import { Container } from "../../components/layout/container";

export function ConfigurationPartnerPackageDetails() {
  const {
    partnerId,
    partner,
    partnerPackage,
    partnerPackageMonths,
    onQuantityChanged,
    onIsRecurringChanged,
    addItemMonthIndex,
    onAddItem,
    onAddItemCancel,
    onItemAdded,
    partnerPackageId,
    onDeleteItem,
    formik,
  } = usePartnerPackageDetails();

  const prefixPartnerName = partner ? `${partner.name} ` : "";
  return (
    <LayoutBase pageTitle={`${prefixPartnerName}Package Details`}>
      <MainNav hasSecondaryNav />
      <ConfigurationSecondaryNav partnerId={partnerId.toString()} activeSection="packages" />
      <Container centerHorizontally>
        <AddPackageItemDrawer
          isOpen={addItemMonthIndex !== null}
          monthIndex={addItemMonthIndex ?? 0}
          onAdded={onItemAdded}
          onClose={onAddItemCancel}
          partnerId={partnerId}
          partnerPackageId={partnerPackageId}
        />
        <Box my={4}>
          <AppLink to={`/configuration/${partnerId}/packages`}>
            <AppIcon icon={faAngleLeft} standardRightMargin />
            Back to Packages
          </AppLink>
        </Box>
        <Stack isInline spacing={4} justifyContent="stretch" width="100%">
          <PanelGroup width={{ base: "100%", xl: "350px" }}>
            <PanelTop showBottomBorder>
              <Heading size="md">Details</Heading>
            </PanelTop>
            <PanelBottom>
              <form onSubmit={formik.handleSubmit}>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input variant="outline" value={formik.values.name} id="name" onChange={formik.handleChange} />
                  </FormControl>
                  <FormControl isDisabled>
                    <FormLabel>Monthly Spend</FormLabel>
                    <Input variant="outline" value={partnerPackage?.monthlySpend.toFixed(2) ?? ""} />
                  </FormControl>
                  <FormControl isDisabled>
                    <FormLabel>Months</FormLabel>
                    <Input variant="outline" value={partnerPackage?.months ?? ""} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Active</FormLabel>
                    <Box>
                      <Switch id="isActive" onChange={formik.handleChange} isChecked={formik.values.isActive} />
                    </Box>
                    <FormHelperText>
                      In order for this package to show up for selection when creating new campaigns, the Package must
                      be marked as &quot;Active&quot;.
                    </FormHelperText>
                  </FormControl>
                  {formik.dirty && (
                    <Stack spacing={2} isInline>
                      <PrimaryButton type="submit" isLoading={formik.isSubmitting}>
                        Save
                      </PrimaryButton>
                      <CancelButton
                        onClick={() => {
                          formik.resetForm();
                        }}
                        isDisabled={formik.isSubmitting}
                      >
                        Cancel
                      </CancelButton>
                    </Stack>
                  )}
                </Stack>
              </form>
            </PanelBottom>
          </PanelGroup>
          <Box flex={1}>
            <Stack spacing={4}>
              {partnerPackageMonths.map((partnerPackageMonth) => (
                <PackageMonth
                  key={partnerPackageMonth.monthIndex}
                  items={partnerPackageMonth.items}
                  monthIndex={partnerPackageMonth.monthIndex}
                  monthlySpend={partnerPackage?.monthlySpend ?? 1}
                  onQuantityChange={onQuantityChanged}
                  onIsRecurringChange={onIsRecurringChanged}
                  onAddItem={() => {
                    onAddItem(partnerPackageMonth.monthIndex);
                  }}
                  onDeleteItem={onDeleteItem}
                />
              ))}
            </Stack>
          </Box>
        </Stack>
      </Container>
    </LayoutBase>
  );
}

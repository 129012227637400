import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { PrimaryButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useUpdateCategoryMutation, useEditCategoryDrawerQuery } from "../../generated/graphql";
import { Loader } from "../../components/loader";

interface Props {
  categoryId: number;
  isOpen: boolean;
  onClose: () => void;
  onEdited: (categoryId: number) => void;
}

interface FormValues {
  name: string;
}

const updateCategorySchema = yup.object().shape({
  name: yup.string().label("Category Name").required(),
});

export function EditCategoryDrawer(props: Props) {
  const { onClose, onEdited, isOpen, categoryId } = props;
  const { data, loading } = useEditCategoryDrawerQuery({
    variables: {
      id: categoryId,
    },
    fetchPolicy: "network-only",
  });
  const [updateCategory] = useUpdateCategoryMutation();
  const toast = useToast();

  const categoryName = data?.category?.name ?? "";

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await updateCategory({
        variables: {
          input: {
            categoryId,
            name: values.name,
          },
        },
      });

      if (response.data?.updateCategory.category?.id) {
        toast({ title: "Success", description: "Category updated successfully!", status: "success" });
        formikHelpers.resetForm();
        onEdited(response.data.updateCategory.category.id);
        onClose();
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      name: categoryName,
    },
    enableReinitialize: true,
    validationSchema: updateCategorySchema,
    onSubmit: handleSubmit,
  });

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  return (
    <>
      {loading && <Loader type="content" />}
      <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="sm" closeOnOverlayClick={false}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Edit Category</DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <FormControl isRequired isInvalid={!!formik.errors.name && !!formik.touched.name}>
                <FormLabel>Name</FormLabel>
                <Input variant="outline" value={formik.values.name} id="name" onChange={formik.handleChange} />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              </FormControl>
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <PrimaryButton width="100%" onClick={formik.submitForm}>
              Save
            </PrimaryButton>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import {
  LinkLocationsSort,
  useLinkLocationsAvailableQuery,
  useUpdateLinkLocationMetricsMutation,
} from "../../generated/graphql";
import { PAGE_LIMIT } from "../../constants/constants";
import { LINK_LOCATION_STATUS } from "../../constants/link-location-status";

interface Options {
  refetch?: boolean;
  onRefetchDone?: () => void;
  customerId: number;
  minDA?: number;
  minSV?: number;
}

export function useLinkLocations(options: Options) {
  const { refetch, onRefetchDone, customerId, minDA, minSV } = options;
  const toast = useToast();
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [statusIds, setStatusIds] = useState<string[]>([LINK_LOCATION_STATUS.ACTIVE.id]);
  const [typeIds, setTypeIds] = useState<string[]>([]);
  const [minDa, setMinDa] = useState<number | undefined>(minDA);
  const [minTraffic, setMinTraffic] = useState<number | undefined>(minSV);
  const [sort, setSort] = useState<string | undefined>(LinkLocationsSort.CostAsc);
  const [categoryId, setCategoryId] = useState<number | undefined>(undefined);
  const [publisherId, setPublisherId] = useState<number | undefined | null>(undefined);

  const queryResult = useLinkLocationsAvailableQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: PAGE_LIMIT,
      offset,
      search,
      statusIds,
      typeIds,
      minDa,
      minTraffic,
      excludeCustomerId: customerId,
      categoryId,
      publisherId,
      sort: sort ? (sort as LinkLocationsSort) : undefined,
      secondSort: LinkLocationsSort.TrafficAsc,
    },
  });

  const [updateLinkLocationMetrics] = useUpdateLinkLocationMetricsMutation();

  useEffect(() => {
    if (refetch) {
      queryResult.refetch();
      onRefetchDone?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  useEffect(() => {
    setMinDa(minDA);
    setMinTraffic(minSV);
  }, [minDA, minSV]);

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  function onStatusSelected(statusIds: string[]) {
    setStatusIds(statusIds);
  }

  function onTypesSelected(typeIds: string[]) {
    setTypeIds(typeIds);
  }

  function clearFilters() {
    setSearch("");
    setStatusIds([]);
    setTypeIds([]);
  }

  function onNextPage() {
    setOffset(offset + PAGE_LIMIT);
  }

  function onPreviousPage() {
    setOffset(Math.max(offset - PAGE_LIMIT, 0));
  }

  function onMinDaChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const minDa = value ? parseInt(value, 10) : undefined;
    setMinDa(minDa);
  }

  function onMinTrafficChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const minTraffic = value ? parseInt(value, 10) : undefined;
    setMinTraffic(minTraffic);
  }

  function onSortChange(value: string) {
    setSort(value);
  }

  function onCategoryChange(categoryId: number) {
    setCategoryId(categoryId);
  }

  function onPublisherChange(publisherId?: number | null) {
    setPublisherId(publisherId);
  }

  async function linkLocationsOnRefreshMetrics(linkLocationId: number) {
    const response = await updateLinkLocationMetrics({
      variables: {
        input: {
          linkLocationId,
        },
      },
    });

    if (response.data?.updateLinkLocationMetrics.ok) {
      toast({
        title: "Link Location metrics refreshed successfully",
        status: "success",
      });
      queryResult.refetch();
    } else {
      toast({
        title: "Failed to refresh Link Location metrics",
        description:
          response.data?.updateLinkLocationMetrics.error?.message ??
          "Something went wrong while refreshing Link Location metrics",
        status: "error",
      });
    }
  }

  return {
    search,
    onSearchChange,
    statusIds,
    onStatusSelected,
    typeIds,
    onTypesSelected,
    clearFilters,
    offset,
    onNextPage,
    onPreviousPage,
    minDa,
    onMinDaChange,
    minTraffic,
    onMinTrafficChange,
    sort,
    onSortChange,
    categoryId,
    onCategoryChange,
    loading: queryResult.loading,
    linkLocationConnection: queryResult.data?.linkLocations,
    linkLocationsOnRefreshMetrics,
    publisherId,
    onPublisherChange,
  };
}

import { Box, Flex, FormControl, FormLabel, HStack } from "@chakra-ui/react";
import { faColumns, faSort } from "@fortawesome/free-solid-svg-icons";
import { AddPartnerDrawer } from "../../common/add-partner-drawer";
import { PrimaryButton } from "../../components/buttons";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { SearchInput } from "../../components/search-input/search-input";
import { PartnersTable } from "./partners-table";
import { usePartners } from "./use-partners";
import { CustomSingleSelect } from "../../common/custom-selector";

export function AdminPartnersRoute() {
  const {
    loading,
    onChange,
    onSortChange,
    onAddPartner,
    onAddPartnerCancel,
    onPartnerAdded,
    showAddPartnerDrawer,
    onNextPage,
    onPreviousPage,
    partners,
    partnersTableLimit,
    partnersTableOffset,
    totalCount,
    sort,
    partnerType,
    onPartnerTypeChange,
  } = usePartners();

  return (
    <LayoutBase pageTitle="Partners">
      <MainNav />
      <AddPartnerDrawer isOpen={showAddPartnerDrawer} onAdded={onPartnerAdded} onClose={onAddPartnerCancel} />
      <Container centerHorizontally>
        <PageHeading>Partners</PageHeading>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between">
              <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end">
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Filter</FormLabel>
                  <SearchInput onChange={onChange} />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Sort</FormLabel>
                  <CustomSingleSelect
                    onChange={(value) => {
                      if (!value) {
                        return null;
                      }
                      onSortChange(value);
                    }}
                    allowEmpty={false}
                    value={sort}
                    items={[
                      { displayText: "Oldest First", value: "CREATED_AT_ASC" },
                      { displayText: "Newest First", value: "CREATED_AT_DESC" },
                    ]}
                    selectorIcon={faSort}
                  />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Partner Type</FormLabel>
                  <CustomSingleSelect
                    onChange={onPartnerTypeChange}
                    value={partnerType}
                    items={[
                      { displayText: "RCM Partners", value: "partner" },
                      { displayText: "LMR Partners", value: "lmr" },
                    ]}
                    emptyValueTitle="All Partner Type"
                    selectorIcon={faColumns}
                    allowEmpty={true}
                  />
                </FormControl>
              </HStack>
              <Box>
                <PrimaryButton onClick={onAddPartner}>Add Partner</PrimaryButton>
              </Box>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <PartnersTable
              limit={partnersTableLimit}
              loading={loading}
              offset={partnersTableOffset}
              partners={partners}
              totalCount={totalCount}
              onNextPage={onNextPage}
              onPreviousPage={onPreviousPage}
            />
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}

import {
  Flex,
  SimpleGrid,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Box,
  FormErrorMessage,
  Select,
  Textarea,
  Switch,
} from "@chakra-ui/react";

import { PanelGroup, PanelTop, PanelBottom, PanelMiddle } from "../../components/panels";
import { PageHeading } from "../../components/page-heading";
import { Loader } from "../../components/loader";
import { useAdminPublisherOverview } from "./use-admin-publisher-overview";
import { PrimaryButton, CancelButton } from "../../components/buttons";
import { AdminPublisherLinkLocations } from "../admin-publisher-link-locations";

export function AdminPublisherOverview() {
  const { loading, publisher, formik } = useAdminPublisherOverview();

  return (
    <>
      {/* TODO: Add when prompt added to feature
      <Prompt when={formik.dirty} message={"Are you sure you want to discard your changes?"} />
      */}
      <PageHeading>{publisher?.name ?? "Publisher Details"}</PageHeading>
      <SimpleGrid columns={1} gridGap={4}>
        {loading && (
          <>
            <PanelGroup>
              <PanelTop showBottomBorder>
                <Heading size="md">Basic Information</Heading>
              </PanelTop>
              <PanelBottom>
                <Loader type="content" />
              </PanelBottom>
            </PanelGroup>
          </>
        )}
        {!loading && publisher && (
          <Box>
            <PanelGroup>
              <PanelTop showBottomBorder>
                <Flex>
                  <Heading size="md">Basic Information</Heading>
                </Flex>
              </PanelTop>
              <PanelBottom>
                <form onSubmit={formik.handleSubmit}>
                  <Stack spacing={4}>
                    <FormControl isRequired isInvalid={!!formik.errors.name && !!formik.touched.name}>
                      <FormLabel>Publisher Name</FormLabel>
                      <Input id="name" value={formik.values.name} onChange={formik.handleChange} />
                      <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.contactEmail && !!formik.touched.contactEmail}>
                      <FormLabel>Contact Email</FormLabel>
                      <Input
                        id="contactEmail"
                        type="email"
                        value={formik.values.contactEmail}
                        onChange={formik.handleChange}
                      />
                      <FormErrorMessage>{formik.errors.contactEmail}</FormErrorMessage>
                    </FormControl>
                    <FormControl isReadOnly>
                      <FormLabel>Created By User</FormLabel>
                      <Input value={formik.values.createdByUser ?? "-"} />
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.paymentMethod && !!formik.touched.paymentMethod}>
                      <FormLabel>Payment Method</FormLabel>
                      <Select id="paymentMethod" value={formik.values.paymentMethod} onChange={formik.handleChange}>
                        <option value="">Select Payment Method</option>
                        <option value="PayPal">PayPal</option>
                        <option value="Payoneer">Payoneer</option>
                      </Select>
                      <FormErrorMessage>{formik.errors.paymentMethod}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.paymentMethodId && !!formik.touched.paymentMethodId}>
                      <FormLabel>Payment Method Id</FormLabel>
                      <Input
                        id="paymentMethodId"
                        type="text"
                        value={formik.values.paymentMethodId}
                        onChange={formik.handleChange}
                      />
                      <FormErrorMessage>{formik.errors.paymentMethodId}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.isSlowPartner && !!formik.touched.isSlowPartner}>
                      <FormLabel>Is Slow Partner?</FormLabel>
                      <Switch
                        id="isSlowPartner"
                        isChecked={formik.values.isSlowPartner}
                        onChange={formik.handleChange}
                      />
                      <FormErrorMessage>{formik.errors.isSlowPartner}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.isAutoPayEnabled && !!formik.touched.isAutoPayEnabled}>
                      <FormLabel>Is Auto Pay Enabled?</FormLabel>
                      <Switch
                        id="isAutoPayEnabled"
                        isChecked={formik.values.isAutoPayEnabled}
                        onChange={formik.handleChange}
                        isDisabled={publisher.isAutoPayEnabled ?? false}
                      />
                      <FormErrorMessage>{formik.errors.isAutoPayEnabled}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.isInvoiceRequired && !!formik.touched.isInvoiceRequired}>
                      <FormLabel>Is Invoice Required?</FormLabel>
                      <Switch
                        id="isInvoiceRequired"
                        isChecked={formik.values.isInvoiceRequired}
                        onChange={formik.handleChange}
                      />
                      <FormErrorMessage>{formik.errors.isInvoiceRequired}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.errors.instructions && !!formik.touched.instructions}>
                      <FormLabel>Instructions</FormLabel>
                      <Textarea
                        id="instructions"
                        value={formik.values.instructions}
                        onChange={formik.handleChange}
                        resize="none"
                        placeholder="Instructions"
                      />
                      <FormErrorMessage>{formik.errors.instructions}</FormErrorMessage>
                    </FormControl>
                    {formik.dirty && (
                      <Stack isInline spacing={2}>
                        <PrimaryButton type="submit" isLoading={formik.isSubmitting}>
                          Save
                        </PrimaryButton>
                        <CancelButton
                          onClick={() => {
                            formik.resetForm();
                          }}
                        >
                          Cancel
                        </CancelButton>
                      </Stack>
                    )}
                  </Stack>
                </form>
              </PanelBottom>
            </PanelGroup>
            <PanelGroup>
              <PanelMiddle>
                <AdminPublisherLinkLocations />
              </PanelMiddle>
            </PanelGroup>
          </Box>
        )}
      </SimpleGrid>
    </>
  );
}

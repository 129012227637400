import { As } from "@chakra-ui/react";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import { useCampaignCategoriesForSelectorV2Query } from "../../generated/graphql";
import { CustomSingleSelect } from "../custom-selector";

interface SingleProps {
  selectedCampaignCategoryId?: string | null;
  onCampaignCategorySelected: (campaignCategoryId: string) => void;
  menuButtonAs?: As;
  showAll?: boolean;
}

export function CampaignCategorySelectorV2(props: SingleProps) {
  const { data } = useCampaignCategoriesForSelectorV2Query({
    fetchPolicy: "cache-and-network",
  });

  function onSelectedValueChange(values: string | null) {
    props.onCampaignCategorySelected(values as string);
  }

  const items =
    data?.campaignCategories.nodes.map((node) => ({
      value: `${node.id}`,
      displayText: `${node.name}`,
    })) ?? [];

  if (props.showAll) {
    items.unshift({
      value: "",
      displayText: "All",
    });
  }

  return (
    <CustomSingleSelect
      onChange={onSelectedValueChange}
      value={props.selectedCampaignCategoryId ?? null}
      items={items}
      selectorIcon={faListAlt}
      menuButtonAs={props.menuButtonAs}
    />
  );
}

import { useToast } from "@chakra-ui/react";
import { useContext, useState } from "react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useUserProfileQuery, useUpdateUserMutation, useChangePasswordMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { AuthContext } from "../../core/auth-manager";
import { EMAIL_PREFERENCE } from "../../constants/user-email-preferences";

interface FormValues {
  timezone: string;
  calendarUrl?: string | null;
  title?: string | null;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  emailPreference: string;
}

const validationSchema = yup.object().shape({
  currentPassword: yup.string(),
  newPassword: yup.string(),
  confirmPassword: yup.string().oneOf([yup.ref("newPassword")], "Passwords must match"),
  calendarUrl: yup.string().url().label("Calendar URL").notRequired().nullable(),
  title: yup.string().label("Title").notRequired().nullable(),
  emailPreference: yup.string().label("Email Preference").notRequired().nullable(false),
});

export function useUserProfile() {
  const context = useContext(AuthContext);
  const user = context.viewer?.user;
  const toast = useToast();
  const [showProfilePictureUpload, setShowProfilePictureUpload] = useState(false);
  const [changePassword] = useChangePasswordMutation();
  const [updateUser] = useUpdateUserMutation();

  const queryResult = useUserProfileQuery({
    fetchPolicy: "network-only",
    variables: {
      id: user?.id ?? 0,
    },
    skip: !user?.id,
  });

  const profileUser = queryResult.data?.user;

  function onProfilePictureUpload() {
    setShowProfilePictureUpload(true);
  }

  function onProfilePictureUploadCancel() {
    setShowProfilePictureUpload(false);
  }

  async function onProfilePictureUploaded(fileId: string | null) {
    if (!user?.id) {
      toast({ title: "Error", description: "User id is not available", status: "error" });
      return;
    }
    try {
      const response = await updateUser({
        variables: {
          input: {
            userId: user.id,
            profilePictureS3FileId: fileId,
          },
        },
      });

      if (response.data?.updateUser.user?.id) {
        toast({ title: "Profile Picture saved", status: "success" });
        await queryResult.refetch();
      }
      setShowProfilePictureUpload(false);
    } catch (e: any) {
      toast({ title: "Unable to Save Profile Picture", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  async function handleFormSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      if (!user?.id) {
        toast({ title: "Error", description: "User id is not available", status: "error" });
        return;
      }

      const response = await updateUser({
        variables: {
          input: {
            userId: user?.id,
            timezone: values.timezone,
            calendarUrl: values.calendarUrl,
            title: values.title,
            emailPreference: values.emailPreference,
          },
        },
      });

      if (response.data?.updateUser.user?.id) {
        toast({ title: "Success", description: "User preference updated successfully!", status: "success" });
        await queryResult.refetch();
      }

      if (!!values.currentPassword || !!values.newPassword) {
        if (!values.newPassword) {
          formikHelpers.setFieldError("newPassword", "New Password is required field to change password");
          return;
        }
        if (values.newPassword !== values.confirmPassword) {
          formikHelpers.setFieldError("confirmPassword", "Passwords must match");
          return;
        }

        const response = await changePassword({
          variables: {
            input: {
              currentPassword: values.currentPassword,
              newPassword: values.newPassword,
            },
          },
        });

        if (response.data?.changePassword.ok) {
          toast({ title: "Success", description: "Password changed successfully!", status: "success" });
          formikHelpers.resetForm();
        } else if (response.data?.changePassword.error) {
          toast({ title: "Error", description: "Something went wrong", status: "error" });
        }
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      timezone: user?.timezone ?? "",
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      calendarUrl: profileUser?.calendarUrl,
      title: profileUser?.title,
      emailPreference: profileUser?.emailPreference ?? EMAIL_PREFERENCE.ALL_EMAILS.id,
    },
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  return {
    loading: queryResult.loading,
    formik,
    user,
    profileUser,
    showProfilePictureUpload,
    onProfilePictureUpload,
    onProfilePictureUploadCancel,
    onProfilePictureUploaded,
  };
}

import React from "react";
import { DateTime } from "luxon";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Tag,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import { faBan, faSort } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../components/app-icon";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { SearchInput } from "../../components/search-input/search-input";
import {
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TablePagination,
  TableRow,
} from "../../components/table";
import { DATE_MONTH_FORMAT, PAGE_LIMIT } from "../../constants/constants";
import { LinkLocation_LinkLocationConnectionFragment, LinkLocationsSort } from "../../generated/graphql";
import { LinkLocationStatusSelector } from "../link-location-status-selector";
import { LinkLocationTypeSelector } from "../link-location-type-selector";
import { SecondaryButton } from "../../components/buttons";
import { currencyFormatter } from "../../util/formatter";
import { CategorySelector } from "../category-selector";
import { CustomSingleSelect } from "../custom-selector";
import { PublisherSelector } from "../publisher-selector";
import { noop } from "../../util/noop";
import { ExternalLink } from "../../components/external-link";

interface Props {
  heading: string;
  search: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  statusIds: string[];
  onStatusSelected: (statusIds: string[]) => void;
  typeIds: string[];
  onTypesSelected: (typeIds: string[]) => void;
  clearFilters: () => void;
  offset: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  loading: boolean;
  linkLocationConnection: LinkLocation_LinkLocationConnectionFragment | undefined;
  showSelect?: boolean;
  onSelected?: (domainName: string, linkLocationId: number, publisher: string) => void;
  isDisabled?: boolean;
  disableAvailableStatus?: boolean;
  showExtraInformation?: boolean;
  showFilter?: boolean;
  minDa?: number;
  onMinDaChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  minTraffic?: number;
  onMinTrafficChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  categoryId?: number;
  onCategorySelected?: (categoryId: number) => void;
  sort?: string;
  onSortChange: (value: string) => void;
  onRefreshMetrics?: (linkLocationId: number) => void;
  publisherId?: number | null;
  onPublisherChange?: (publisherId?: number | null) => void;
  hideTrafficSort?: boolean;
}

export function LinkLocations(props: Props) {
  const {
    heading,
    search,
    onSearchChange,
    statusIds,
    onStatusSelected,
    typeIds,
    onTypesSelected,
    clearFilters,
    offset,
    onNextPage,
    onPreviousPage,
    loading,
    linkLocationConnection,
    showSelect,
    onSelected,
    isDisabled,
    disableAvailableStatus,
    showExtraInformation,
    minDa,
    onMinDaChange,
    minTraffic,
    onMinTrafficChange,
    showFilter,
    categoryId,
    onCategorySelected,
    sort,
    onSortChange,
    onRefreshMetrics,
    publisherId,
    onPublisherChange = noop,
    hideTrafficSort,
  } = props;

  const filterCount = search.length + statusIds.length + typeIds.length;

  const { nodes, totalCount } = linkLocationConnection ?? { nodes: [], totalCount: 0 };

  const items = [
    { displayText: "Cost Increasing", value: LinkLocationsSort.CostAsc },
    { displayText: "Cost Decreasing", value: LinkLocationsSort.CostDesc },
    {
      displayText: "Da Increasing",
      value: LinkLocationsSort.DaAsc,
    },
    {
      displayText: "Da Decreasing",
      value: LinkLocationsSort.DaDesc,
    },
  ];

  if (!hideTrafficSort) {
    items.push({ displayText: "Traffic Increasing", value: LinkLocationsSort.TrafficAsc });
    items.push({ displayText: "Traffic Decreasing", value: LinkLocationsSort.TrafficDesc });
  }

  return (
    <PanelGroup>
      <PanelTop showBottomBorder>
        <Heading size="sm" marginBottom={4}>
          <Text>{heading}</Text>
        </Heading>
        <Flex justifyContent="space-between" alignItems="center">
          <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end">
            <FormControl width="inherit" isDisabled={isDisabled}>
              <FormLabel fontSize="xs">Filter</FormLabel>
              <SearchInput onChange={onSearchChange} value={search} />
            </FormControl>
            <FormControl width="inherit">
              <FormLabel fontSize="xs">Sort</FormLabel>
              <CustomSingleSelect
                onChange={(value) => {
                  if (!value) {
                    return null;
                  }
                  onSortChange(value);
                }}
                allowEmpty={true}
                value={sort ?? null}
                items={items}
                selectorIcon={faSort}
              />
            </FormControl>

            <FormControl width="inherit" isDisabled={isDisabled || disableAvailableStatus}>
              <FormLabel fontSize="xs">Status</FormLabel>
              <LinkLocationStatusSelector
                selectedStatusIds={statusIds}
                onStatusSelected={onStatusSelected}
                emptyValueLabel="All"
                isDisabled={disableAvailableStatus}
                isMulti
              />
            </FormControl>
            <FormControl width="inherit" isDisabled={isDisabled}>
              <FormLabel fontSize="xs">Type</FormLabel>
              <LinkLocationTypeSelector
                selectedTypeIds={typeIds}
                onTypeSelected={onTypesSelected}
                emptyValueLabel="All"
                isMulti
              />
            </FormControl>
            {showFilter && (
              <>
                <FormControl width="inherit" isDisabled={isDisabled}>
                  <FormLabel fontSize="xs">Publisher</FormLabel>
                  <PublisherSelector
                    onPublisherSelected={onPublisherChange}
                    selectedPublisherId={publisherId}
                    allowNull={true}
                    nullValueText="Select Publisher"
                  />
                </FormControl>
                <FormControl width="inherit" isDisabled={isDisabled} key="min-da">
                  <FormLabel fontSize="xs">Min DA</FormLabel>
                  <Input value={minDa ?? ""} onChange={onMinDaChange} type="number" />
                </FormControl>
                <FormControl width="inherit" isDisabled={isDisabled} key="min-traffic">
                  <FormLabel fontSize="xs">Min Traffic</FormLabel>
                  <Input value={minTraffic ?? ""} onChange={onMinTrafficChange} type="number" />
                </FormControl>
                <FormControl width="inherit" isDisabled={isDisabled} key="category">
                  <FormLabel fontSize="xs">Category</FormLabel>
                  <CategorySelector
                    onCategorySelected={
                      onCategorySelected ??
                      (() => {
                        /*DO Nothing */
                      })
                    }
                    showNoCategoryOption={true}
                    selectedCategoryId={categoryId}
                  />
                </FormControl>
              </>
            )}
            <Box>
              {filterCount > 0 && (
                <Button onClick={clearFilters}>
                  <AppIcon icon={faBan} standardRightMargin />
                  Clear Filters
                </Button>
              )}
            </Box>
          </HStack>
        </Flex>
      </PanelTop>
      <PanelBottom containsTable>
        <Table isEmpty={nodes.length === 0} isLoading={loading}>
          <TableHead>
            <TableRow key="head">
              <TableHeaderCell>Domain</TableHeaderCell>
              <TableHeaderCell>Cost</TableHeaderCell>
              <TableHeaderCell>Type</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell>Publisher</TableHeaderCell>
              {showExtraInformation && (
                <>
                  <TableHeaderCell>Information</TableHeaderCell>
                  <TableHeaderCell>Last Metrics Updated</TableHeaderCell>
                </>
              )}
              <TableHeaderCell>Categories</TableHeaderCell>
              {showSelect && <TableHeaderCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {nodes.map((linkLocation) => {
              return (
                <TableRow key={linkLocation.id}>
                  <TableCell>
                    <ExternalLink href={`//${linkLocation.domain}`} target="_blank">
                      {linkLocation.domain}
                    </ExternalLink>
                  </TableCell>
                  <TableCell>{currencyFormatter.format(linkLocation.cost ?? 0)}</TableCell>
                  <TableCell>{linkLocation.type.name ?? "-"}</TableCell>
                  <TableCell>{linkLocation.status.name ?? "-"}</TableCell>
                  <TableCell>{linkLocation?.publisherLinkLocation?.publisher?.name ?? "-"}</TableCell>
                  {showExtraInformation && (
                    <>
                      <TableCell>
                        <Text>DA: {linkLocation?.domainAuthority ?? "-"}</Text>
                        <Text>spam: {linkLocation.spam ?? "-"}</Text>
                        <Text>AS: {linkLocation?.authorityScore ?? "-"}</Text>
                        <Text>Quality: {linkLocation.internalQuality ?? "-"}</Text>
                        <Text>Monthly Traffic: {linkLocation.monthlyTraffic ?? "-"}</Text>
                      </TableCell>
                      <TableCell>
                        <Text>
                          {linkLocation.lastMetricsUpdatedAt
                            ? DateTime.fromJSDate(linkLocation.lastMetricsUpdatedAt).toUTC().toFormat(DATE_MONTH_FORMAT)
                            : "-"}
                        </Text>
                        <SecondaryButton onClick={onRefreshMetrics?.bind(null, linkLocation.id)}>
                          Refresh Metrics
                        </SecondaryButton>
                      </TableCell>
                    </>
                  )}

                  <TableCell>
                    <Stack spacing={2} isInline flexWrap="wrap" gridRowGap={1}>
                      {linkLocation.linkLocationCategories.map((linkLocationCategory) => (
                        <Tag
                          key={linkLocationCategory.category.id}
                          size="sm"
                          rounded="full"
                          variant="solid"
                          colorScheme="gray"
                        >
                          <TagLabel>{linkLocationCategory.category.name}</TagLabel>
                        </Tag>
                      ))}
                    </Stack>
                  </TableCell>
                  {showSelect && (
                    <TableCell>
                      <SecondaryButton
                        onClick={onSelected?.bind(
                          null,
                          linkLocation.domain,
                          linkLocation.id,
                          linkLocation.publisherLinkLocation?.publisher?.name ?? ""
                        )}
                        isDisabled={isDisabled || !linkLocation.publisherLinkLocation}
                      >
                        Select
                      </SecondaryButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
          <TableFoot>
            <TablePagination
              totalCount={totalCount}
              limit={PAGE_LIMIT}
              offset={offset}
              onNextPage={onNextPage}
              onPreviousPage={onPreviousPage}
            />
          </TableFoot>
        </Table>
      </PanelBottom>
    </PanelGroup>
  );
}

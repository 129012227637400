import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Flex,
  Box,
  Popover,
  PopoverTrigger,
  Image,
  PopoverContent,
  Stack,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import queryString from "qs";
import { PageHeading } from "../../components/page-heading";
import { Container } from "../../components/layout/container";
import { PanelGroup, PanelBottom, PanelTop } from "../../components/panels";
import { SecondaryButton } from "../../components/buttons";
import { useSitePostsQuery } from "../../generated/graphql";
import { AdminSitePostsTable } from "./admin-site-posts-table";
import { SearchInput } from "../../components/search-input/search-input";
import { getQueryParams, setParams } from "../../util/query-param-helper";
import { PAGE_LIMIT } from "../../constants/constants";
import FilterSVG from "../../assets/filter.svg";
import { LayoutBase, MainNav } from "../../components/layout";
import { SiteSelector } from "../../common/site-selector";
import { SitePostStatusSelector } from "../../common/site-post-status-selector";

export function AdminSitePostsRoute() {
  const [showFilter, setShowFilter] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = getQueryParams(location.search, queryString) || {};
  const searchValue = queryParams.search ?? "";
  const pageOffset = queryParams?.offset ? parseInt(queryParams.offset, 10) : 0;
  const statusIds = queryParams.statusId ? [queryParams.statusId] : [];
  const siteIds = queryParams.siteId ? [queryParams.siteId] : [];

  const sitePostsQueryResult = useSitePostsQuery({
    variables: {
      search: searchValue,
      offset: pageOffset,
      limit: PAGE_LIMIT,
      statusIds,
      siteIds,
    },
    fetchPolicy: "network-only",
  });

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { search, ...remainingQueryParams } = queryParams;
    let searchParams;
    const value = event.target.value;
    if (value) {
      searchParams = setParams(remainingQueryParams, "search", `${value ? value : ""}`);
    } else {
      searchParams = setParams(remainingQueryParams);
    }
    searchParams = setParams(searchParams, "offset", "0");
    navigate({ search: `?${searchParams}` });
  }

  function onSiteSelected(selectedSiteId: string | null) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { siteId, ...remainingQueryParams } = queryParams;
    let params;
    if (selectedSiteId) {
      params = setParams(remainingQueryParams, "siteId", `${selectedSiteId}`);
    } else {
      params = setParams(remainingQueryParams);
    }
    params = setParams(params, "offset", "0");
    navigate({ search: `?${params}` });
  }

  function onSitePostStatusSelected(sitePostStatusId: string | null) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { statusId, ...remainingQueryParams } = queryParams;
    let params;
    if (sitePostStatusId) {
      params = setParams(remainingQueryParams, "statusId", sitePostStatusId);
    } else {
      params = setParams(remainingQueryParams);
    }
    params = setParams(params, "offset", "0");
    navigate({ search: `?${params}` });
  }

  function onNextPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = parseInt(offset ?? "0", 10) + PAGE_LIMIT;
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onPreviousPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = Math.max(parseInt(offset ?? "0", 10) - PAGE_LIMIT, 0);
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function openFilter() {
    setShowFilter(true);
  }

  function closeFilter() {
    setShowFilter(false);
  }

  return (
    <LayoutBase pageTitle="Site Posts">
      <MainNav />
      {/*TODO: add site post*/}
      <Container centerHorizontally>
        <Flex alignItems="center">
          <PageHeading>Site Posts</PageHeading>
        </Flex>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                <SearchInput onChange={onChange} />
              </Box>
              <Box display="flex">
                <Box marginRight={2}>
                  <SecondaryButton onClick={openFilter}>
                    Filter <Image src={FilterSVG} ml={2} />
                  </SecondaryButton>
                  <Popover isOpen={showFilter} onClose={closeFilter}>
                    <PopoverTrigger>
                      <Box top="-8px" position="relative" />
                    </PopoverTrigger>
                    <PopoverContent zIndex={4} maxW={300} padding={4}>
                      <Stack spacing={4}>
                        <FormControl>
                          <FormLabel>Site</FormLabel>
                          <SiteSelector
                            onSiteSelected={onSiteSelected}
                            selectedSiteId={siteIds.length > 0 ? siteIds[0] : undefined}
                            placeholder="Select a Site"
                            variant="outline"
                            isClearable
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Status</FormLabel>
                          <SitePostStatusSelector
                            placeholder="Select a Status"
                            onStatusSelected={onSitePostStatusSelected}
                            selectedStatusId={statusIds.length > 0 ? statusIds[0] : undefined}
                            variant="outline"
                            isClearable
                          />
                        </FormControl>
                      </Stack>
                    </PopoverContent>
                  </Popover>
                </Box>
              </Box>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <AdminSitePostsTable
              sitePostsQueryResult={sitePostsQueryResult}
              onPreviousPage={onPreviousPage}
              onNextPage={onNextPage}
            />
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}

import { AuthContextValue } from "../../core/auth-manager/auth-manager";

interface MainNavOptions {
  authContext: AuthContextValue;
}

interface NavItemTopLevelDefinition extends NavItemDefinition {
  submenu?: NavItemDefinition[];
}

interface NavItemDefinition {
  name: string;
  to: string;
  visible: boolean;
  isExternal?: boolean;
}

export function getMainNavDefinition(options: MainNavOptions) {
  const { authContext } = options;

  const canViewPartner = authContext.isAdmin() || authContext.hasPartnerUser();
  const canViewPartnerAdmin = authContext.isAdmin() || authContext.hasPartnerUserAdmin();
  const canViewVendor = authContext.isAdmin() || authContext.hasVendorUser();
  const canViewInvoices = authContext.isAdmin() || authContext.hasSLPCustomer();

  const navItems: NavItemTopLevelDefinition[] = [
    {
      name: "Clients",
      to: "/clients",
      visible: canViewPartner,
    },
    {
      name: "Orders",
      to: "/orders",
      visible: canViewPartner,
    },
    {
      name: "Messages",
      to: "/messages",
      visible: canViewPartner,
    },
    {
      name: "Vendors",
      to: "/vendors",
      visible: canViewPartnerAdmin,
    },
    {
      name: "Configuration",
      to: "/configuration",
      visible: canViewPartnerAdmin,
    },
    {
      name: "Tasks",
      to: "/tasks",
      visible: canViewVendor,
    },
    {
      name: "Billing",
      to: "/billing",
      visible: canViewPartnerAdmin,
    },
    {
      name: "Invoices",
      to: "/invoices",
      visible: canViewInvoices,
    },
    {
      name: "Admin",
      to: "/admin",
      visible: authContext.isAdmin(),
      submenu: [
        {
          name: "Partners",
          to: "/admin/partners",
          visible: true,
        },
        {
          name: "Business Categories",
          to: "/admin/categories",
          visible: true,
        },
        {
          name: "Users",
          to: "/admin/users",
          visible: true,
        },
        {
          name: "Reports",
          to: "/admin/reports",
          visible: true,
        },
        {
          name: "Tasks",
          to: "/admin/tasks",
          visible: true,
        },
        {
          name: "Tasks Edit",
          to: "/admin/tasks-edit",
          visible: true,
        },
        {
          name: "Billing",
          to: "/admin/billing",
          visible: true,
        },
        {
          name: "Invoices",
          to: "/admin/invoices",
          visible: true,
        },
        {
          name: "Block Domains",
          to: "/admin/block-domains-customer",
          visible: true,
        },
      ],
    },
    {
      name: "Outreach",
      to: "/outreach",
      visible: authContext.isAdmin(),
      submenu: [
        {
          name: "Guest Article",
          to: "/outreach/guest-article",
          visible: true,
        },
        {
          name: "Publishers",
          to: "/outreach/publishers",
          visible: true,
        },
        {
          name: "Link Locations",
          to: "/outreach/link-locations",
          visible: true,
        },
        {
          name: "Published Content",
          to: "/outreach/published-content",
          visible: true,
        },
        {
          name: "Autopay Publisher Payments",
          to: "/outreach/auto-pay-publisher-payments",
          visible: true,
        },
      ],
    },
    {
      name: "Tools",
      to: "/tools/domain-metrics",
      visible: true,
      submenu: [
        {
          name: "Domain Metrics",
          to: "/tools/domain-metrics",
          visible: true,
        },
        {
          name: "Page SEO Audit",
          to: "//pagespeed.web.dev/",
          isExternal: true,
          visible: true,
        },
        {
          name: "Create Schema",
          to: "//www.google.com/webmasters/markup-helper/u/0/",
          isExternal: true,
          visible: true,
        },
        {
          name: "Validate Schema",
          to: "//developers.google.com/search/docs/appearance/structured-data",
          isExternal: true,
          visible: true,
        },
        {
          name: "Keyword Research",
          to: "/tools/keyword-research",
          visible: true,
        },
        {
          name: "Topic Generator",
          to: "/tools/topic-generator",
          visible: true,
        },
      ],
    },
  ];

  return navItems;
}

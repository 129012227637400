export const COUNTRY_OPTIONS = [
  {
    location_name: "United States",
    country_iso_code: "US",
  },
  {
    location_name: "Canada",
    country_iso_code: "CA",
  },
  {
    location_name: "United Kingdom",
    country_iso_code: "GB",
  },
  {
    location_name: "Australia",
    country_iso_code: "AU",
  },
  {
    location_name: "South Africa",
    country_iso_code: "ZA",
  },
];

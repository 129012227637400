import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Text,
  Badge,
  Heading,
  List,
  ListItem,
} from "@chakra-ui/react";
import { AlertWarning } from "../../components/alerts";
import { CancelButton, DangerButton } from "../../components/buttons";
import { useForceDeleteOrderModal } from "./force-delete-order-modal-hook";
import AppIcon from "../../components/app-icon";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";

interface Props {
  orderId: number | null;
  onDeleted: () => void;
  onCancel: () => void;
}

export function ForceDeleteOrderModal(props: Props) {
  const { orderId } = props;
  const { confirmPrompt, formik, handleCancel } = useForceDeleteOrderModal(props);

  return (
    <Modal isOpen={!!orderId} onClose={handleCancel} size="xl">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>
            <Stack spacing={1}>
              <Heading size="md">Confirm Delete Order</Heading>
              <Text fontSize="md">
                <Badge colorScheme="orange">
                  <AppIcon icon={faShieldAlt} standardRightMargin />
                  Super Admin Only
                </Badge>
              </Text>
            </Stack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <AlertWarning>
                <strong>THIS CANNOT BE UNDONE</strong>
              </AlertWarning>
              <Text>
                This order would normally not be eligible to be deleted, but because you are a Super Admin, you can
                force this order to be deleted. If present, the following order-related data will be deleted as well:
                <List listStyleType="inherit" listStylePosition="inside" mt={2}>
                  <ListItem>Workflows</ListItem>
                  <ListItem>Tasks</ListItem>
                  <ListItem>Creatives Scale User Payments</ListItem>
                  <ListItem>Client Conversations/Messages</ListItem>
                  <ListItem>Content Strategies</ListItem>
                  <ListItem>Content Items</ListItem>
                  <ListItem>Partner Invoice Charges</ListItem>
                </List>
              </Text>
              <FormControl isRequired isInvalid={!!formik.touched.confirmText && !!formik.errors.confirmText}>
                <FormLabel>Confirm Order Deletion (type &quot;{confirmPrompt}&quot;)</FormLabel>
                <Input
                  id="confirmText"
                  value={formik.values.confirmText}
                  onChange={formik.handleChange}
                  placeholder={confirmPrompt}
                />
                <FormErrorMessage>{formik.errors.confirmText}</FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Stack spacing={2} isInline>
              <DangerButton
                type="submit"
                isLoading={formik.isSubmitting}
                isDisabled={!formik.isValid || formik.isSubmitting}
              >
                Delete Order
              </DangerButton>
              <CancelButton onClick={handleCancel}>Cancel</CancelButton>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

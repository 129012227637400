import { useState } from "react";
import axios from "axios";
import {
  useSiteAuditTaskWorkspaceQuery,
  useSiteAuditTaskWorkspace_CreateCustomerSiteAuditMutation,
  useSiteAuditTaskWorkspace_GenerateUrlForFileUploadMutation,
} from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import { getDisplayMessageForError } from "../../util/error-helper";

interface Options {
  taskId: number;
  onComplete: () => void;
}

export function useTaskWorkspaceSiteAudit(options: Options) {
  const { taskId, onComplete } = options;
  const [auditFile, setAuditFile] = useState<File | null>(null);
  const [summary, setSummary] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const toast = useToast();

  const queryResult = useSiteAuditTaskWorkspaceQuery({
    fetchPolicy: "network-only",
    variables: {
      taskId,
    },
  });
  const [generateUrlForUploadMutation] = useSiteAuditTaskWorkspace_GenerateUrlForFileUploadMutation();
  const [createSiteAuditMutation] = useSiteAuditTaskWorkspace_CreateCustomerSiteAuditMutation();

  const task = queryResult.data?.task ?? null;
  const customer = queryResult.data?.task?.customer ?? null;

  async function handleComplete() {
    if (!task) {
      return;
    }
    setIsProcessing(true);
    let fileId: string | null = null;
    if (auditFile) {
      const response = await generateUrlForUploadMutation({
        variables: {
          input: {
            contentType: auditFile.type,
            name: auditFile.name,
            sizeInBytes: auditFile.size,
          },
        },
      });

      const url = response.data?.generateUrlForFileUpload.url;
      fileId = response.data?.generateUrlForFileUpload.fileId ?? null;

      if (!url || !fileId) {
        toast({
          title: "There was a problem uploading the file. Please try again.",
          status: "error",
        });
        return;
      }

      try {
        const options = {
          headers: {
            "Content-Type": auditFile.type,
          },
        };

        await axios.put(url, auditFile, options);
      } catch (e: any) {
        toast({ title: "Unable to Upload", description: getDisplayMessageForError(e), status: "error" });
        setIsProcessing(false);
        return;
      }
    }

    try {
      const siteAuditResponse = await createSiteAuditMutation({
        variables: {
          input: {
            customerId: task.customer.id,
            fileId,
            summary,
            orderId: task.order.id,
          },
        },
      });
      if (!siteAuditResponse.data?.createCustomerSiteAudit.ok) {
        throw new Error(
          siteAuditResponse.data?.createCustomerSiteAudit.error?.message ??
            "Something went wrong saving the Audit File."
        );
      }
    } catch (e: any) {
      toast({ title: "Unable to Upload", description: getDisplayMessageForError(e), status: "error" });
      setIsProcessing(false);
      return;
    }

    onComplete();
  }

  function onFileSelected(file: File) {
    setAuditFile(file);
  }

  function removeFile() {
    setAuditFile(null);
  }

  function onChangeSummary(content: string) {
    setSummary(content);
  }

  return {
    taskId,
    task,
    customer,
    handleComplete,
    auditFile,
    onFileSelected,
    summary,
    onChangeSummary,
    removeFile,
    isProcessing,
  };
}

import { useState } from "react";
import { CONTENT_PRODUCTS } from "../../constants/products";
import {
  OrdersSort,
  useContentStrategyV2_OrdersQuery,
  useContentStrategyV2_OrderStatusesQuery,
} from "../../generated/graphql";
import { PAGE_LIMIT } from "../../constants/constants";

interface Props {
  customerId: number;
}

export function useContentStrategyV2Roadmap(props: Props) {
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [offset, setOffset] = useState(0);
  const [statusIds, setStatusIds] = useState([] as string[]);
  const [sort, setSort] = useState(OrdersSort.StartedAtAsc);
  const customerIds = [props.customerId];
  const productIds = CONTENT_PRODUCTS;

  const ordersQueryResult = useContentStrategyV2_OrdersQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: PAGE_LIMIT,
      offset,
      search,
      sort,
      statusIds,
      customerIds,
      productIds,
    },
  });

  const orderStatusesQueryResult = useContentStrategyV2_OrderStatusesQuery({
    fetchPolicy: "network-only",
  });

  const orderStatusOptions = orderStatusesQueryResult?.data?.orderStatuses.map((node) => ({
    value: node.id,
    label: node.name,
  }));

  const orders = ordersQueryResult?.data?.orders?.nodes ?? [];
  const totalCount = ordersQueryResult?.data?.orders?.totalCount ?? 0;

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value === "") {
      setSearch(undefined);
    } else {
      setSearch(event.target.value);
    }
  }

  function onSortChange(sort: any) {
    setSort(sort);
  }

  function onOrderStatusSelected(orderStatusId: any) {
    if (statusIds[0] === orderStatusId) {
      setStatusIds([]);
    } else {
      setStatusIds([orderStatusId]);
    }
    setOffset(0);
  }

  function clearFilters() {
    setStatusIds([]);
  }

  function onNextPage() {
    setOffset(offset + PAGE_LIMIT);
  }

  function onPreviousPage() {
    setOffset(Math.max(offset - PAGE_LIMIT, 0));
  }

  return {
    onSearchChange,
    onSortChange,
    onOrderStatusSelected,
    onNextPage,
    onPreviousPage,
    clearFilters,
    totalCount,
    orders,
    sort,
    ordersQueryResult,
    statusIds,
    orderStatusOptions,
  };
}

import { ButtonProps, Button } from "@chakra-ui/react";
import Color from "color";
import { AppColors } from "../../core/custom-theme";

export function PrimaryButton(props: ButtonProps) {
  return (
    <Button
      variant="solid"
      bg={AppColors.blue}
      color="white"
      _hover={{
        bg: Color(AppColors.blue).darken(0.2).hex(),
      }}
      _active={{
        bg: Color(AppColors.blue).darken(0.3).hex(),
      }}
      fontSize="14px"
      paddingX="30px"
      paddingY="10px"
      {...props}
    />
  );
}

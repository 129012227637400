import * as yup from "yup";
import { useCustomerDetailsOverviewQuery, useUpdateCustomerMutation } from "../../generated/graphql";
import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import { handleFormGraphQLError } from "../../util/error-helper";
import { useState } from "react";
import { useClientDetailsQuestionnaire } from "./use-client-questionnaire";
import { FormHelpers } from "../../util/form-helpers";
import { useAuthContext } from "../../core/auth-manager/auth-manager-hook";

interface DetailsFormValues {
  name: string;
  accountManagerUserId: number | null;
  rcmAccountManagerUserId: number | null;
  analyticsReportingInlineFrameUrl: string;
  instructions: string | null;
}

export function useClientDetails(customerId: number) {
  const authContext = useAuthContext();
  const customerOverviewQueryResult = useCustomerDetailsOverviewQuery({
    variables: {
      id: customerId,
    },
  });
  const { isQuestionnaireLoading } = useClientDetailsQuestionnaire(customerId);
  const [updateCustomerMutation] = useUpdateCustomerMutation();
  const [isEditStatusOpen, setIsEditStatusOpen] = useState(false);
  const toast = useToast();

  const customer = customerOverviewQueryResult.data?.customer ?? null;

  const detailsFormik = useFormik<DetailsFormValues>({
    initialValues: {
      name: customer?.name ?? "",
      accountManagerUserId: customer?.accountManagerUser?.id ?? null,
      analyticsReportingInlineFrameUrl: customer?.analyticsReportingInlineFrameUrl ?? "",
      instructions: customer?.instructions ?? "",
      rcmAccountManagerUserId: customer?.rcmAccountManagerUser?.id ?? null,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: yup.object().shape({
      name: yup.string().label("Company Name").required().nullable(false).max(255),
      analyticsReportingInlineFrameUrl: yup.string().label("Analytics Reporting Inline Frame URL").notRequired().url(),
      instructions: yup.string().label("Instructions").notRequired().nullable(true),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        const response = await updateCustomerMutation({
          variables: {
            input: {
              customerId,
              name: values.name,
              accountManagerUserId: values.accountManagerUserId,
              analyticsReportingInlineFrameUrl: FormHelpers.processNullableString(
                values.analyticsReportingInlineFrameUrl
              ),
              instructions: values.instructions,
              rcmAccountManagerUserId: authContext.isAdmin() ? values.rcmAccountManagerUserId : undefined,
            },
          },
        });

        if (response.data?.updateCustomer.customer?.id) {
          await customerOverviewQueryResult.refetch();
        }

        if (response.data?.updateCustomer.customer?.id) {
          toast({ title: "Client Updated", description: "Your updates were saved.", status: "success" });
        }
      } catch (e: any) {
        handleFormGraphQLError(e, "Unable to Save Updates", toast, formikHelpers.setErrors);
      }
    },
  });

  function openEditStatusDrawer() {
    setIsEditStatusOpen(true);
  }

  function closeEditStatusDrawer() {
    setIsEditStatusOpen(false);
  }

  function onUserSelected(userId: number | null) {
    detailsFormik.setFieldValue("accountManagerUserId", userId);
  }

  function onRCMAccountManagerUserSelected(userId: number | null) {
    detailsFormik.setFieldValue("rcmAccountManagerUserId", userId);
  }

  function onSave() {
    detailsFormik.submitForm();
  }

  function onCancel() {
    detailsFormik.resetForm();
    setTimeout(async () => {
      await detailsFormik.validateForm();
    }, 0);
  }

  return {
    customer,
    isOverviewLoading: customerOverviewQueryResult.loading || isQuestionnaireLoading,
    detailsFormik,
    isEditStatusOpen,
    openEditStatusDrawer,
    closeEditStatusDrawer,
    refetch: customerOverviewQueryResult.refetch,
    onUserSelected,
    onRCMAccountManagerUserSelected,
    onSave,
    onCancel,
  };
}

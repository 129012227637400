import { DateTime } from "luxon";

export function getRelativeTimeDisplayText(input: Date): string {
  let dateDisplay = "";
  if (input > DateTime.now().startOf("day").toJSDate()) {
    dateDisplay = `Today at ${DateTime.fromJSDate(input).toFormat("h:mm a")}`;
  } else {
    dateDisplay = DateTime.fromJSDate(input).toRelative() as string;
  }

  return dateDisplay;
}

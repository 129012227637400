import { Stack, useToast, Box } from "@chakra-ui/react";
import { ContentEditor } from "../../components/content-editor";
import { useFormik } from "formik";
import { PrimaryButton, CancelButton } from "../../components/buttons";
import { useCreateWorkflowNoteMutation } from "../../generated/graphql";

interface Props {
  workflowId: number;
  onWorkflowNoteCreated: (noteId: number) => void;
  onCancel: () => void;
  showEditor: boolean;
}

interface FormValues {
  content: string;
}

export function NewWorkflowNote(props: Props) {
  const { onWorkflowNoteCreated, workflowId, showEditor, onCancel } = props;
  const [createWorkflowNoteMutation] = useCreateWorkflowNoteMutation();
  const toast = useToast();

  const formik = useFormik<FormValues>({
    initialValues: {
      content: "",
    },
    onSubmit: async (values) => {
      const response = await createWorkflowNoteMutation({
        variables: {
          input: {
            content: values.content,
            workflowId,
          },
        },
      });

      if (response.data?.createWorkflowNote.ok) {
        onWorkflowNoteCreated(response.data.createWorkflowNote.note?.id ?? 0);
        formik.resetForm();
      } else {
        toast({
          title: "Unable to Create Note",
          description: response.data?.createWorkflowNote.error?.message ?? "Something went wrong. Please try again.",
          status: "error",
        });
      }
    },
  });

  if (!showEditor) {
    return null;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={4}>
        <Box>
          <ContentEditor
            onChange={(c) => {
              formik.setFieldValue("content", c);
            }}
          />
        </Box>
        <Stack isInline spacing={2}>
          <PrimaryButton type="submit">Save</PrimaryButton>
          <CancelButton
            onClick={() => {
              formik.resetForm();
              onCancel();
            }}
          >
            Cancel
          </CancelButton>
        </Stack>
      </Stack>
    </form>
  );
}

import { Checkbox, Heading, Text } from "@chakra-ui/react";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../components/app-icon";
import AppLink from "../../components/app-link";
import { ExternalLink } from "../../components/external-link";
import { FormattedDate } from "../../components/formatted-date";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import {
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TablePagination,
  TableRow,
} from "../../components/table";
import { TruncatedText } from "../../components/truncated-text";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { usePublicationManagement } from "./publication-management-hook";

export function AdminReportsPublicationManagementRoute() {
  const {
    loading,
    orders,
    pagination,
    totalCount,
    treatReportingSmartsResultUrlAsNull,
    onChangeTreatReportingSmartsResultUrlAsNull,
  } = usePublicationManagement();

  return (
    <LayoutBase pageTitle="Admin Reports">
      <MainNav />
      <Container centerHorizontally>
        <PageHeading>
          <span>Publication Management</span>
          <Text fontSize="md" fontWeight="normal">
            In <AppLink to="/admin/reports">Admin Reports</AppLink>
          </Text>
        </PageHeading>
        <PanelGroup>
          <PanelTop display="flex" justifyContent="space-between">
            <Heading size="md">Orders Needing Result URL</Heading>
            <Checkbox
              checked={treatReportingSmartsResultUrlAsNull}
              onChange={onChangeTreatReportingSmartsResultUrlAsNull}
            >
              Include Reporting Smarts URLs
            </Checkbox>
          </PanelTop>
          <PanelBottom containsTable>
            <Table isEmpty={orders.length === 0} isLoading={loading}>
              <TableHead>
                <TableRow key="head">
                  <TableHeaderCell>Order</TableHeaderCell>
                  <TableHeaderCell>Client</TableHeaderCell>
                  <TableHeaderCell>Topic</TableHeaderCell>
                  <TableHeaderCell>Order Date</TableHeaderCell>
                  <TableHeaderCell>Deliverable URL</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => {
                  const deliverableUrl = order.orderContentItems[0]?.contentItemDelivery?.publicUrl ?? "";
                  const hasDeliverableUrl = deliverableUrl !== "";
                  return (
                    <TableRow key={order.id}>
                      <TableCell>
                        <AppLink to={`/orders/${order.id}`}>{order.id}</AppLink>
                        <TruncatedText maxLength={30} fontSize="sm">
                          {order.partnerProduct.product.name}
                        </TruncatedText>
                      </TableCell>
                      <TableCell>
                        <AppLink to={`/clients/${order.customer.id}`}>{order.customer.name}</AppLink>
                        <TruncatedText maxLength={30} fontSize="sm">
                          {order.customer.partner.name}
                        </TruncatedText>
                      </TableCell>
                      <TableCell>
                        <TruncatedText maxLength={30} fontSize="sm">
                          {order.topic ?? ""}
                        </TruncatedText>
                      </TableCell>
                      <TableCell>
                        <Text>
                          <strong>Started</strong>: <FormattedDate format={DATE_MONTH_FORMAT} date={order.startDate} />
                        </Text>
                        <Text>
                          <strong>Completed</strong>:{" "}
                          <FormattedDate format={DATE_MONTH_FORMAT} date={order.completedAt} />
                        </Text>
                      </TableCell>
                      <TableCell>
                        {hasDeliverableUrl && (
                          <ExternalLink href={deliverableUrl}>
                            <AppIcon icon={faExternalLinkAlt} standardRightMargin />
                            Deliverable
                          </ExternalLink>
                        )}
                        {!hasDeliverableUrl && "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFoot>
                <TablePagination totalCount={totalCount} {...pagination} />
              </TableFoot>
            </Table>
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}

import { TaskWorkspace_TaskFragment } from "../../generated/graphql";
import { PanelGroup, PanelTop } from "../../components/panels";
import { Heading } from "@chakra-ui/react";
import { PageHeading } from "../../components/page-heading";
import AppIcon from "../../components/app-icon";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { AppColors } from "../../core/custom-theme";
import { WorkflowNotes } from "../../common/workflow-notes";

interface Props {
  task: TaskWorkspace_TaskFragment;
}

export function TaskCompleted(props: Props) {
  const { task } = props;
  return (
    <>
      <PageHeading>{task.taskType.name}</PageHeading>
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Heading size="md">
            <AppIcon color={AppColors.success} icon={faCheck} standardRightMargin />
            Task Completed
          </Heading>
        </PanelTop>
      </PanelGroup>
      <WorkflowNotes preventNewNotes taskId={task.id} />
    </>
  );
}

import { Flex, SimpleGrid, Heading, Stack, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { useVendorDetailsOverviewQuery } from "../../generated/graphql";
import { PageHeading } from "../../components/page-heading";
import { Loader } from "../../components/loader";

interface Props {
  vendorId: number;
}

export function VendorDetailsOverview(props: Props) {
  const { vendorId } = props;
  const { data, loading } = useVendorDetailsOverviewQuery({
    variables: {
      id: vendorId,
    },
    fetchPolicy: "network-only",
  });

  const vendorDetails = data?.vendor;

  return (
    <>
      <PageHeading>Vendor Details</PageHeading>
      <SimpleGrid columns={{ base: 1, xl: 2 }} gridGap={4}>
        {loading && (
          <>
            <PanelGroup>
              <PanelTop showBottomBorder>
                <Heading size="md">Basic Information</Heading>
              </PanelTop>
              <PanelBottom>
                <Loader type="content" />
              </PanelBottom>
            </PanelGroup>
          </>
        )}
        {!loading && !!vendorDetails && (
          <>
            <PanelGroup>
              <PanelTop showBottomBorder>
                <Flex>
                  <Heading size="md">Basic Information</Heading>
                </Flex>
              </PanelTop>
              <PanelBottom paddingBottom={12}>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel>Vendor Name</FormLabel>
                    <Input variant="outline" value={vendorDetails.name} isReadOnly />
                  </FormControl>
                </Stack>
              </PanelBottom>
            </PanelGroup>
          </>
        )}
      </SimpleGrid>
    </>
  );
}

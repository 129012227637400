import * as yup from "yup";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FormikHelpers, useFormik } from "formik";

import {
  TasksSort,
  useGuestArticle_CreatePublisherPaymentMutation,
  useGuestArticle_UpdatePublisherPaymentMutation,
  useGuestArticle_UpdateOrderMutation,
  useGuestArticleWorkspace_TaskQuery,
  useUpdateSubTaskMutation,
  useGuestArticleTaskWorkspace_SaveOrderContentItemMutation,
} from "../../generated/graphql";
import { FormHelpers } from "../../util/form-helpers";
import { getDisplayMessageForError } from "../../util/error-helper";
import { TASK_STATUS } from "../../constants/task-status";
import { POLL_INTERVAL, TIMEOUT_INTERVAL } from "../../constants/time-intervals";
import { PUBLISHER_PAYMENT_STATUS } from "../../constants/publisher-payment-status";

interface Options {
  taskId: number;
}

interface FormValues {
  resultUrl?: string;
}

const validationSchema = yup.object().shape({
  resultUrl: yup.string().label("Result URL").notRequired().nullable(true),
});

interface PublisherPayFormValues {
  amount: number;
  processor?: string;
  processorReferenceId?: string;
  statusId: string;
}

const publisherPayValidationSchema = yup.object().shape({
  amount: yup.number().label("Amount").required(),
  processor: yup.string().label("Processor").notRequired().nullable(true),
  processorReferenceId: yup.string().label("Processor Reference ID").notRequired().nullable(true),
});

interface ContentFormValues {
  title: string;
  content: string;
}

const contentValidationSchema = yup.object().shape({
  title: yup.string().label("Title").required().nullable(false).min(1),
  content: yup.string().label("Content").required().nullable(false).min(1),
});

export function useTaskWorkspaceGuestArticleOutreach(options: Options) {
  const { taskId } = options;
  const toast = useToast();
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState(false);
  const [updateSubTaskMutation] = useUpdateSubTaskMutation();
  const [updateOrder] = useGuestArticle_UpdateOrderMutation();
  const [createPublisherPayment] = useGuestArticle_CreatePublisherPaymentMutation();
  const [updatePublisherPayment] = useGuestArticle_UpdatePublisherPaymentMutation();
  const [saveOrderContentItem] = useGuestArticleTaskWorkspace_SaveOrderContentItemMutation();

  const queryResult = useGuestArticleWorkspace_TaskQuery({
    variables: {
      taskId,
      subTasksSort: TasksSort.IdAsc,
      statusIds: [
        PUBLISHER_PAYMENT_STATUS.PENDING.id,
        PUBLISHER_PAYMENT_STATUS.SUBMITTED.id,
        PUBLISHER_PAYMENT_STATUS.COMPLETED.id,
      ],
    },
    pollInterval: POLL_INTERVAL,
    fetchPolicy: "network-only",
  });

  const task = queryResult.data?.task ?? null;

  async function onSubTaskComplete(isApproved?: boolean, reviewComment?: string) {
    try {
      await updateSubTaskMutation({
        variables: {
          input: {
            taskId: task?.currentSubTask?.id ?? 0,
            status: "completed",
            isApproved,
            reviewComment: FormHelpers.processNullableString(reviewComment ?? ""),
            ignoreUserTaskPermission: true,
          },
        },
      });
      toast({ title: "Success", description: "Sub Task completed.", status: "success" });
      setTimeout(async () => {
        if (task?.status?.id === TASK_STATUS.COMPLETED.id) {
          navigate("/tasks");
        }
      }, TIMEOUT_INTERVAL);
    } catch (e: any) {
      toast({ title: "Unable to Complete", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function onSubmit(values: FormValues, _helpers: FormikHelpers<FormValues>) {
    try {
      // Currently, we are updating result url at order level
      // we may have to do it at task level after approval we can update it at order level
      const response = await updateOrder({
        variables: {
          input: {
            orderId: task?.order?.id ?? 0,
            resultUrl: FormHelpers.processNullableString(values.resultUrl ?? ""),
          },
        },
      });
      if (!response.data?.updateOrder.order.id) {
        throw new Error("Something went wrong while updating order");
      }
      await onSubTaskComplete();
    } catch (e: any) {
      toast({ title: "Unable to update order", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  async function saveContentItem(values: ContentFormValues) {
    try {
      await saveOrderContentItem({
        variables: {
          input: {
            content: values.content,
            orderId: task?.order?.id ?? 0,
            title: values.title,
          },
        },
      });
      toast({ title: "Success", description: "Content updated.", status: "success" });
    } catch (e: any) {
      toast({ title: "Unable to Update Content", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const contentFormik = useFormik<ContentFormValues>({
    initialValues: {
      content: task?.order?.orderContentItems?.[0]?.contentItem.content ?? "",
      title: task?.order?.orderContentItems?.[0]?.contentItem.title ?? "",
    },
    enableReinitialize: true,
    validationSchema: contentValidationSchema,
    onSubmit: saveContentItem,
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      resultUrl: task?.order?.resultUrl ?? undefined,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  async function onVerifyLinkComplete() {
    await formik.submitForm();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function onPublisherPaySubmit(values: PublisherPayFormValues, _helpers: FormikHelpers<PublisherPayFormValues>) {
    try {
      if (!task?.order.publisherPayment) {
        const response = await createPublisherPayment({
          variables: {
            input: {
              amount: values.amount,
              orderId: task?.order?.id ?? 0,
              publisherId: task?.order.targetLinkLocation?.publisherLinkLocation?.publisher?.id ?? null,
              processor: FormHelpers.processNullableString(values.processor ?? ""),
              processorReferenceId: FormHelpers.processNullableString(values.processorReferenceId ?? ""),
              statusId: values.statusId,
            },
          },
        });
        if (!response.data?.createPublisherPayment.ok) {
          throw new Error("Something went wrong while creating publisher payment");
        }
      } else {
        const response = await updatePublisherPayment({
          variables: {
            input: {
              publisherPaymentId: task?.order.publisherPayment?.id ?? 0,
              amount: values.amount,
              processor: FormHelpers.processNullableString(values.processor ?? ""),
              processorReferenceId: FormHelpers.processNullableString(values.processorReferenceId ?? ""),
              statusId: values.statusId,
            },
          },
        });
        if (!response.data?.updatePublisherPayment.ok) {
          throw new Error("Something went wrong while creating publisher payment");
        }
      }
      await onSubTaskComplete();
    } catch (e: any) {
      toast({
        title: "Unable to create publisher payment",
        description: getDisplayMessageForError(e),
        status: "error",
      });
    }
  }

  const publisherPayFormik = useFormik<PublisherPayFormValues>({
    initialValues: {
      statusId: task?.order.publisherPayment?.status?.id ?? PUBLISHER_PAYMENT_STATUS.COMPLETED.id,
      amount: task?.order.publisherPayment?.amount ?? task?.order.targetLinkLocation?.cost ?? 0,
      processor:
        task?.order.publisherPayment?.processor ??
        task?.order.targetLinkLocation?.publisherLinkLocation?.publisher?.paymentMethod ??
        undefined,
      processorReferenceId:
        task?.order.publisherPayment?.processorReferenceId ??
        task?.order.targetLinkLocation?.publisherLinkLocation?.publisher?.paymentMethodId ??
        undefined,
    },
    enableReinitialize: true,
    validationSchema: publisherPayValidationSchema,
    onSubmit: onPublisherPaySubmit,
  });

  async function onPublisherPayComplete() {
    await publisherPayFormik.submitForm();
  }

  function onOpenContent() {
    setShowContent(true);
  }

  function onCloseContent() {
    setShowContent(false);
  }

  return {
    taskId,
    task,
    onSubTaskComplete,
    formik,
    onVerifyLinkComplete,
    publisherPayFormik,
    onPublisherPayComplete,
    onOpenContent,
    onCloseContent,
    showContent,
    isPublisherPaymentExist: !!task?.order.publisherPayment,
    contentFormik,
    loading: queryResult.loading,
  };
}

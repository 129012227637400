import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { sortBy } from "lodash";

import { useVendorsForSelectorQuery } from "../../generated/graphql";
import { CustomMultiSelect } from "../custom-selector";

interface Props {
  partnerId?: number;
  selectedVendorIds: number[];
  onChange: (value: number[]) => void;
}

export function VendorMultiSelect(props: Props) {
  const { onChange, partnerId, selectedVendorIds } = props;
  const queryResult = useVendorsForSelectorQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId,
    },
  });

  const vendors = sortBy(queryResult.data?.vendors.nodes ?? [], "name");

  function handleVendorChanged(value: string[]) {
    onChange(value.map((x) => parseInt(x, 10)));
  }

  return (
    <CustomMultiSelect
      items={vendors.map((vendor) => ({ displayText: vendor.name, value: vendor.id.toString() }))}
      onChange={handleVendorChanged}
      value={selectedVendorIds.map((p) => p.toString())}
      pluralizedItemName="Vendors"
      emptyValueTitle="All"
      selectorIcon={faHandshake}
    />
  );
}

import { Badge } from "@chakra-ui/react";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../app-icon";

export function AdminBadge() {
  return (
    <Badge colorScheme="orange">
      <AppIcon icon={faShieldAlt} standardRightMargin />
      Admin Only
    </Badge>
  );
}

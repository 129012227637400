import { useState } from "react";
import { usePartnerPaymentMethodSelectorQuery } from "../../generated/graphql";

interface Options {
  partnerId: number;
  allowAddNewPaymentMethod?: boolean;
  selectedPartnerPaymentMethodId: number | null;
  onPartnerPaymentSelected: (partnerPaymentMethodId: number | null) => void;
}

export function usePartnerPaymentMethodSelector(options: Options) {
  const { partnerId, selectedPartnerPaymentMethodId, onPartnerPaymentSelected } = options;
  const [internalSelectedPartnerPaymentId, setInternalSelectedPartnerPaymentId] = useState<number | null>(
    selectedPartnerPaymentMethodId
  );
  const [showAddModal, setShowAddModal] = useState(false);
  const [shouldPoll, setShouldPoll] = useState(false);

  const queryResult = usePartnerPaymentMethodSelectorQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId,
    },
    pollInterval: shouldPoll ? 5 * 1000 : undefined,
  });

  const partnerPaymentMethods = queryResult.data?.partner?.partnerPaymentMethods.nodes ?? [];

  function handleSelectedPartnerPaymentChanged(e: React.ChangeEvent<HTMLSelectElement>) {
    const newValue = parseInt(e.target.value);
    if (newValue === -1) {
      setInternalSelectedPartnerPaymentId(null);
      onPartnerPaymentSelected(null);
      setShowAddModal(true);
    } else {
      setInternalSelectedPartnerPaymentId(newValue);
      onPartnerPaymentSelected(newValue);
    }
  }

  function handleAddCancel() {
    setShowAddModal(false);
  }

  function handleAdded() {
    setShowAddModal(false);
    setShouldPoll(true);
  }

  return {
    partnerPaymentMethods,
    internalSelectedPartnerPaymentId,
    handleSelectedPartnerPaymentChanged,
    showAddModal,
    handleAddCancel,
    handleAdded,
    shouldPoll,
  };
}

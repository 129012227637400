import { Route, Navigate, useParams, Routes } from "react-router-dom";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { AdminVendorDetailsSecondaryNav } from "./admin-vendor-details-secondary-nav";
import { VendorDetailsOverview } from "../vendor-details-overview";
import { VendorDetailsUsers } from "../vendor-details-users";

export function AdminVendorDetailsRoute() {
  const { id, "*": section } = useParams<"id" | "*">();
  const vendorId = parseInt(id ?? "");

  return (
    <LayoutBase pageTitle="Vendor Details">
      <MainNav hasSecondaryNav />
      <AdminVendorDetailsSecondaryNav vendorId={vendorId} activeSection={section} />
      <Container centerHorizontally>
        <Routes>
          <Route path="/" element={<Navigate to={`/admin/vendors/${id}/overview`} />} />
          <Route path="overview" element={<VendorDetailsOverview vendorId={vendorId} />} />
          <Route path="users" element={<VendorDetailsUsers vendorId={vendorId} />} />
        </Routes>
      </Container>
    </LayoutBase>
  );
}

/**
 * Check if a Date is between two other dates exclusively, the order of sequence of the firstDate and secondDate does not matter
 * @param middleDate The date you want to check if it is in-between the two following dates
 * @param firstDate The first date to compare
 * @param secondDate The second date to compare
 */
export function isBetween(middleDate: Date | number, firstDate: Date | number, secondDate: Date | number): boolean {
  return (firstDate > middleDate && middleDate > secondDate) || (secondDate > middleDate && middleDate > firstDate);
}

/**
 * Check if a Date is the same as or between two other dates
 * @param middleDate The date to compare
 * @param firstDate The earlier date
 * @param secondDate The later date
 */
export function isSameOrBetween(date: Date | number, firstDate: Date | number, secondDate: Date | number): boolean {
  const dateTimestamp = date instanceof Date ? date.getTime() : date;
  const firstDateTimestamp = firstDate instanceof Date ? firstDate.getTime() : firstDate;
  const secondDateTimestamp = secondDate instanceof Date ? secondDate.getTime() : secondDate;
  return dateTimestamp >= firstDateTimestamp && dateTimestamp <= secondDateTimestamp;
}

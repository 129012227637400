import {
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Skeleton,
  FormErrorMessage,
  Box,
  Textarea,
  Flex,
  Text,
  Image,
  Select,
} from "@chakra-ui/react";
import { faInfoCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";

import { PanelGroup, PanelTop, PanelBottom, PanelMiddle } from "../../../components/panels";
import { useQaContent } from "./use-qa-content";
import { ContentEditor } from "../../../components/content-editor";
import { DangerButton, PrimaryButton, PrimaryIconButton, SecondaryButton } from "../../../components/buttons";
import { AppColors } from "../../../core/custom-theme";
import { ReviewCommentsPanel } from "../../review-comments-panel";
import { UploadFileDialog } from "../../upload-file-modal";
import AppIcon from "../../../components/app-icon";
import { htmlDecode } from "../../../util/decode";
import { HelpText } from "../../../components/help-text";
import { DATE_MONTH_FORMAT } from "../../../constants/constants";

interface Props {
  taskId: number;
  onComplete: (isApproved?: boolean, reviewComment?: string) => void;
  onAbandon: () => void;
  completedAt?: Date;
  isDisabled?: boolean;
}

export function QaContent(props: Props) {
  const { onAbandon, completedAt, isDisabled } = props;
  const {
    loading,
    taskId,
    contentItem,
    formik,
    task,
    reviewTasks,
    reviewTargetTask,
    showThumbnailUpload,
    onThumbnailUpload,
    onThumbnailUploadCancel,
    onThumbnailUploaded,
    onThumbnailRemove,
  } = useQaContent(props);

  return (
    <PanelGroup isCollapsible isCollapsedByDefault={isDisabled}>
      <UploadFileDialog
        isOpen={showThumbnailUpload}
        onFileUploaded={onThumbnailUploaded}
        onCancel={onThumbnailUploadCancel}
        instructionText="Thumbnail must be a png or jpeg. Recommended size: 600px x 400px or smaller."
      />
      <PanelTop>
        <Flex justifyContent="space-between">
          <Heading as="h4" size="md">
            Qa Content
          </Heading>
          {!!completedAt && <Text>Completed At {DateTime.fromJSDate(completedAt).toFormat(DATE_MONTH_FORMAT)}</Text>}
        </Flex>
      </PanelTop>
      <PanelMiddle>
        <Grid templateColumns={{ base: "1fr", xl: "2fr 1fr" }} gridGap={4}>
          <Stack spacing={4} gridColumn={1}>
            <ReviewCommentsPanel reviewTasks={reviewTasks} />
            <form noValidate onSubmit={formik.handleSubmit}>
              <PanelGroup>
                <PanelTop>
                  <Stack spacing={4}>
                    <FormControl
                      isRequired
                      isInvalid={!!formik.touched.title && !!formik.errors.title}
                      isDisabled={isDisabled}
                    >
                      <FormLabel>Title</FormLabel>
                      <Input id="title" value={formik.values.title} onChange={formik.handleChange} />
                      <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isRequired
                      isInvalid={!!formik.touched.content && !!formik.errors.content}
                      isDisabled={isDisabled}
                    >
                      <FormLabel>Content</FormLabel>
                      {loading && <Skeleton height={50} />}
                      {!loading && (
                        <ContentEditor
                          isInvalid={!!formik.touched.content && !!formik.errors.content}
                          initialValue={contentItem?.content ?? ""}
                          onChange={(html) => {
                            formik.setFieldValue("content", html);
                          }}
                          readOnly={isDisabled}
                        />
                      )}
                      <FormErrorMessage>{formik.errors.content}</FormErrorMessage>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Meta Title</FormLabel>
                      <Input value={reviewTargetTask?.contentItem?.metaTitle ?? ""} isReadOnly />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Meta Description</FormLabel>
                      <Textarea
                        value={htmlDecode(reviewTargetTask?.contentItem?.metaDescription ?? "") ?? ""}
                        isReadOnly
                      />
                    </FormControl>
                    <Box>
                      <PanelTop>
                        <Flex justifyContent="space-between" alignItems="center">
                          <Box>
                            <Heading size="md">Thumbnail</Heading>
                            <Text fontSize="sm" color={AppColors.textGray}>
                              Recommended Size: 600px x 400px
                            </Text>
                          </Box>
                          <SecondaryButton onClick={onThumbnailUpload} isDisabled={isDisabled}>
                            Upload
                          </SecondaryButton>
                        </Flex>
                      </PanelTop>
                      <PanelBottom>
                        {formik.values.file && (
                          <Box display="inline-flex" position="relative">
                            <Image
                              htmlHeight={400}
                              htmlWidth={600}
                              objectFit="cover"
                              src={formik.values.file.url}
                              alt={formik.values.file.name}
                              crossOrigin=""
                            />
                            <PrimaryIconButton
                              position="absolute"
                              top="-7px"
                              right="-15px"
                              justifyContent="flex-end"
                              paddingLeft={0}
                              paddingRight={0}
                              marginLeft={0}
                              marginRight={0}
                              height="auto"
                              backgroundColor="white"
                              outline="none"
                              minWidth="auto"
                              borderRadius="50%"
                              icon={faTimesCircle}
                              actionName="Remove Image"
                              onClick={onThumbnailRemove}
                              zIndex={1}
                              isDisabled={isDisabled}
                            />
                          </Box>
                        )}
                        {!formik.values.file && (
                          <Text>
                            <AppIcon icon={faInfoCircle} standardRightMargin />
                            Not provided.
                          </Text>
                        )}
                      </PanelBottom>
                    </Box>
                  </Stack>
                </PanelTop>
                <PanelBottom>
                  <Stack isInline spacing={2} justifyContent="space-between" shouldWrapChildren>
                    <PrimaryButton onClick={formik.submitForm} isLoading={formik.isSubmitting} isDisabled={isDisabled}>
                      Complete Task
                    </PrimaryButton>
                    <Box>
                      <DangerButton onClick={onAbandon} isDisabled={formik.isSubmitting || isDisabled}>
                        Unassign Task
                      </DangerButton>
                      <HelpText standardLeftMargin>
                        {`"Unassign Task" will unassign this task from you and return it to the queue. `}
                        <strong>This cannot be undone and may result in lost work.</strong>
                      </HelpText>
                    </Box>
                  </Stack>
                </PanelBottom>
              </PanelGroup>
            </form>
          </Stack>
          <Stack spacing={4} gridColumn={{ base: 1, xl: 2 }}>
            <PanelGroup isCollapsible>
              <PanelTop showBottomBorder>
                <Heading size="md">Information</Heading>
              </PanelTop>
              <PanelBottom>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel>Review Task ID</FormLabel>
                    <Input variant="outline" isReadOnly value={reviewTargetTask?.id ?? "-"} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Client Instructions</FormLabel>
                    <Textarea value={task?.customer?.instructions ?? "-"} variant="outline" resize="none" isReadOnly />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Writer Name</FormLabel>
                    <Input variant="outline" isReadOnly value={reviewTargetTask?.assignedUser?.fullName ?? "-"} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Writer Email</FormLabel>
                    <Input variant="outline" isReadOnly value={reviewTargetTask?.assignedUser?.email ?? "-"} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Task ID</FormLabel>
                    <Input value={taskId} variant="outline" isReadOnly />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Client</FormLabel>
                    <Input value={task?.customer.name ?? "-"} variant="outline" isReadOnly />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Product Type</FormLabel>
                    <Input value={task?.order.partnerProduct.product.name ?? "-"} variant="outline" isReadOnly />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Keyword</FormLabel>
                    <Input value={task?.keyword ?? "-"} variant="outline" isReadOnly />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Topic</FormLabel>
                    <Input value={task?.order.topic ?? "-"} variant="outline" isReadOnly />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Would you like a featured image provided with onsite content?</FormLabel>
                    <Select
                      id="isAddImage"
                      value={
                        task?.customer?.customerQuestionnaire?.isAddImage
                          ? task.customer.customerQuestionnaire.isAddImage
                            ? "1"
                            : "0"
                          : ""
                      }
                      placeholder="Not provided"
                      variant="outline"
                      isDisabled
                    >
                      <option value="1" key="true">
                        Yes
                      </option>
                      <option value="0" key="false">
                        No
                      </option>
                    </Select>
                  </FormControl>
                </Stack>
              </PanelBottom>
            </PanelGroup>
          </Stack>
        </Grid>
      </PanelMiddle>
    </PanelGroup>
  );
}

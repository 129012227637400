import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Flex, Box, Popover, PopoverTrigger, Image, PopoverContent, Switch, FormLabel, Stack } from "@chakra-ui/react";
import queryString from "qs";

import { PageHeading } from "../../components/page-heading";
import { Container } from "../../components/layout/container";
import { PanelGroup, PanelBottom, PanelTop } from "../../components/panels";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { useVendorUsersQuery } from "../../generated/graphql";
import { UsersTable } from "./vendor-users-table";
import { SearchInput } from "../../components/search-input/search-input";
import { getQueryParams, setParams } from "../../util/query-param-helper";
import { PAGE_LIMIT } from "../../constants/constants";
import FilterSVG from "../../assets/filter.svg";
import { AddVendorUserDrawer } from "../../common/add-vendor-user-drawer";
import { UserTaskPermissionsDrawer } from "../../common/user-task-permissions-drawer";

interface Props {
  vendorId: number;
}

export function VendorDetailsUsers({ vendorId }: Props) {
  const [showFilter, setShowFilter] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = getQueryParams(location.search, queryString) || {};
  const searchValue = queryParams.search ?? "";
  const pageOffset = queryParams?.offset ? parseInt(queryParams.offset, 10) : 0;
  const [isAddingUser, setIsAddingUser] = useState(false);
  let includeInactiveFlag = false;
  if (queryParams && queryParams.includeInactive !== undefined) {
    includeInactiveFlag = queryParams.includeInactive === "true";
  }
  const vendorUsersQueryResult = useVendorUsersQuery({
    fetchPolicy: "network-only",
    variables: {
      id: vendorId,
      search: searchValue,
      offset: pageOffset,
      limit: PAGE_LIMIT,
      includeInactive: includeInactiveFlag,
    },
  });
  const [taskPermissionsUserId, setTaskPermissionsUserId] = useState<number | null>(null);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { search, ...remainingQueryParams } = queryParams;
    let searchParams;
    const value = event.target.value;
    if (value) {
      searchParams = setParams(remainingQueryParams, "search", `${value ? value : ""}`);
    } else {
      searchParams = setParams(remainingQueryParams);
    }
    searchParams = setParams(searchParams, "offset", "0");
    navigate({ search: `?${searchParams}` });
  }

  function onNextPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = parseInt(offset ?? "0", 10) + PAGE_LIMIT;
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onPreviousPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = Math.max(parseInt(offset ?? "0", 10) - PAGE_LIMIT, 0);
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onSwitchChange() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { includeInactive, ...remainingQueryParams } = queryParams;
    let params = setParams(remainingQueryParams, "includeInactive", `${!includeInactiveFlag}`);
    params = setParams(params, "offset", "0");
    navigate({ search: `?${params}` });
  }

  function onManageTaskPermissions(userId: number) {
    setTaskPermissionsUserId(userId);
  }

  return (
    <>
      <AddVendorUserDrawer
        isOpen={isAddingUser}
        onAdded={() => {
          vendorUsersQueryResult.refetch();
          setIsAddingUser(false);
        }}
        onClose={() => {
          setIsAddingUser(false);
        }}
        vendorId={vendorId}
      />
      <UserTaskPermissionsDrawer
        isOpen={!!taskPermissionsUserId}
        onClose={() => {
          setTaskPermissionsUserId(null);
        }}
        onPermissionsUpdated={() => {
          vendorUsersQueryResult.refetch();
        }}
        userId={taskPermissionsUserId ?? 0}
        vendorId={vendorId}
        partnerId={vendorUsersQueryResult.data?.vendor?.partnerId ?? null}
      />
      <Container centerHorizontally>
        <Flex alignItems="center">
          <PageHeading>Vendor Users</PageHeading>
        </Flex>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                <SearchInput onChange={onChange} />
              </Box>
              <Stack isInline spacing={2}>
                <Box>
                  <SecondaryButton
                    onClick={() => {
                      setShowFilter(true);
                    }}
                  >
                    Filter <Image src={FilterSVG} ml={2} />
                  </SecondaryButton>
                  <Popover isOpen={showFilter} onClose={() => setShowFilter(false)}>
                    <PopoverTrigger>
                      <Box top="-8px" position="relative" />
                    </PopoverTrigger>
                    <PopoverContent zIndex={4}>
                      <Flex justifyContent="space-between" padding={4}>
                        <FormLabel htmlFor="inactive-switch">Hide Inactive Users</FormLabel>
                        <Switch id="inactive-switch" isChecked={!includeInactiveFlag} onChange={onSwitchChange} />
                      </Flex>
                    </PopoverContent>
                  </Popover>
                </Box>
                <Box marginRight={2}>
                  <PrimaryButton
                    onClick={() => {
                      setIsAddingUser(true);
                    }}
                  >
                    Add User
                  </PrimaryButton>
                </Box>
              </Stack>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <UsersTable
              vendorUsersQueryResult={vendorUsersQueryResult}
              onManageTaskPermissions={onManageTaskPermissions}
              onPreviousPage={onPreviousPage}
              onNextPage={onNextPage}
            />
          </PanelBottom>
        </PanelGroup>
      </Container>
    </>
  );
}

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Switch,
} from "@chakra-ui/react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
  TablePagination,
  TableFoot,
} from "../../components/table";
import { AdminUsersQueryResult } from "../../generated/graphql";
import { PAGE_LIMIT } from "../../constants/constants";
import { useAdminUsers } from "./admin-users-hook";
import { CancelButton, PrimaryButton } from "../../components/buttons";

interface Props {
  adminUsersQueryResult: AdminUsersQueryResult;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

export function AdminUsersTable(props: Props) {
  const { adminUsersQueryResult, onNextPage, onPreviousPage } = props;
  const totalCount = adminUsersQueryResult.data?.users.totalCount ?? 0;
  const users = adminUsersQueryResult.data?.users.nodes ?? [];

  const {
    isActiveOpen,
    isAdminOpen,
    onActiveChange,
    onActiveClose,
    onAdminChange,
    onAdminClose,
    updateToggleActive,
    updateToggleAdmin,
    toggleActive,
    toggleAdmin,
    activeCancelRef,
    adminCancelRef,
  } = useAdminUsers(adminUsersQueryResult);

  return (
    <>
      <Table isEmpty={users.length === 0} isLoading={adminUsersQueryResult.loading}>
        <TableHead>
          <TableRow key="head">
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Email</TableHeaderCell>
            <TableHeaderCell>Active</TableHeaderCell>
            <TableHeaderCell>Admin</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.fullName}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                <Switch isChecked={user.isActive} onChange={onActiveChange.bind(null, user)} />
              </TableCell>
              <TableCell>
                <Switch isChecked={user.isAdmin} onChange={onAdminChange.bind(null, user)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFoot>
          <TablePagination
            totalCount={totalCount}
            offset={adminUsersQueryResult.variables?.offset ?? 0}
            limit={adminUsersQueryResult.variables?.limit ?? PAGE_LIMIT}
            onPreviousPage={onPreviousPage}
            onNextPage={onNextPage}
          />
        </TableFoot>
      </Table>
      <AlertDialog isOpen={isActiveOpen} onClose={onActiveClose} leastDestructiveRef={activeCancelRef}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{toggleActive?.isActive ? "Deactivate" : "Activate"} User</AlertDialogHeader>
          <AlertDialogBody>
            {toggleActive &&
              `Are you sure you want to ${toggleActive?.isActive ? "deactivate" : "activate"} "${
                toggleActive?.userName
              }"?`}
          </AlertDialogBody>
          <AlertDialogFooter>
            <CancelButton onClick={onActiveClose} ref={activeCancelRef}>
              Cancel
            </CancelButton>
            <PrimaryButton ml={3} onClick={updateToggleActive}>
              {toggleActive?.isActive ? "Deactivate" : "Activate"}
            </PrimaryButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog isOpen={isAdminOpen} onClose={onAdminClose} leastDestructiveRef={adminCancelRef}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Modify User</AlertDialogHeader>
          <AlertDialogBody>
            {toggleAdmin
              ? `Are you sure you want to ${
                  toggleAdmin.isAdmin ? "revoke admin access from" : "grant admin access to"
                } "${toggleAdmin.userName}"?`
              : ""}
          </AlertDialogBody>
          <AlertDialogFooter>
            <CancelButton onClick={onAdminClose} ref={adminCancelRef}>
              Cancel
            </CancelButton>
            <PrimaryButton ml={3} onClick={updateToggleAdmin}>
              Confirm
            </PrimaryButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { DateTime } from "luxon";

import DatePicker from "react-datepicker";
import { DangerButton, PrimaryButton } from "../../components/buttons";
import { HelpText } from "../../components/help-text";
import { Loader } from "../../components/loader";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelMiddle, PanelTop } from "../../components/panels";
import { DATE_MONTH_FORMAT, DATE_PICKER_FORMAT } from "../../constants/constants";
import { currencyFormatter } from "../../util/formatter";
import { VendorSelector } from "../vendor-selector";
import { useContentFulfillmentDecision } from "./use-task-workspace-content-fulfillment-decision";
import { CopyLinkButton } from "../../components/copy-link-button";
import { FULFILLMENT_OPTIONS } from "../../constants/fulfillment-options";
import { LinkLocation } from "../link-locations";

interface Props {
  taskId: number;
  onAbandon: () => void;
  onComplete: () => void;
}

export function TaskWorkspaceContentFulfillmentDecision(props: Props) {
  const { onAbandon } = props;

  const {
    task,
    order,
    customer,
    customerQuestionnaire,
    formik,
    loading,
    handleSubmit,
    onSubmittedDateChange,
    onVendorSelected,
  } = useContentFulfillmentDecision(props);

  const customerName = customer ? `${customer.name} (ID: ${customer.id})` : "";

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <PageHeading>Content Workflow Decision</PageHeading>
      </Box>
      <Grid templateColumns={{ base: "1fr", xl: "1fr 1.5fr" }} gridGap={4}>
        <Stack spacing={4}>
          {loading && <Loader type="panel" />}
          {!loading && (
            <PanelGroup>
              <PanelTop showBottomBorder>
                <Heading size="md">Client Details</Heading>
              </PanelTop>
              <PanelMiddle>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel>Order ID</FormLabel>
                    <Input value={order?.id ?? 0} variant="outline" isReadOnly />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Client</FormLabel>
                    <Tooltip aria-label={customerName} label={customerName}>
                      <Input value={customerName} variant="outline" isReadOnly />
                    </Tooltip>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Partner</FormLabel>
                    <Input value={customer?.partner?.name ?? ""} variant="outline" isReadOnly />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Client Website</FormLabel>
                    <Textarea value={customer?.website ?? "Not provided"} variant="outline" isReadOnly resize="none" />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Call to Action Link</FormLabel>
                    <Textarea
                      value={
                        order?.activeOrderContentStrategy?.linkTo ??
                        customerQuestionnaire?.callToActionUrls ??
                        "Not provided"
                      }
                      variant="outline"
                      isReadOnly
                      resize="none"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Product</FormLabel>
                    <Input value={order?.partnerProduct?.product?.name ?? ""} variant="outline" isReadOnly />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Total Wholesale Amount</FormLabel>
                    <Input
                      value={order?.totalWholesaleAmount ? currencyFormatter.format(order.totalWholesaleAmount) : ""}
                      variant="outline"
                      isReadOnly
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Order Start Date</FormLabel>
                    <Input
                      value={DateTime.fromJSDate(order?.startDate ?? new Date()).toFormat(DATE_MONTH_FORMAT)}
                      variant="outline"
                      isReadOnly
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Business Category</FormLabel>
                    <Input value={customer?.category?.name ?? "Not Provided"} variant="outline" isReadOnly />
                  </FormControl>
                  <FormControl>
                    <FormLabel>About Company</FormLabel>
                    <Textarea
                      value={customerQuestionnaire?.businessDescription ?? "Not Provided"}
                      variant="outline"
                      isReadOnly
                      resize="none"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Topic</FormLabel>
                    <Input value={order?.topic ?? "Not Provided"} variant="outline" isReadOnly />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Primary Keyword</FormLabel>
                    <Input value={task?.keyword ?? "Not Provided"} variant="outline" isReadOnly />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Client Instructions</FormLabel>
                    <Textarea
                      value={customer?.instructions ?? "Not Provided"}
                      variant="outline"
                      resize="none"
                      isReadOnly
                    />
                  </FormControl>
                </Stack>
              </PanelMiddle>
              <PanelBottom>
                <Stack>
                  <Box display="flex" alignItems="center">
                    <CopyLinkButton
                      valueToCopy={`OrderId: ${order?.id ?? "-"}\n URL: ${
                        customerQuestionnaire?.callToActionUrls ?? customer?.website ?? "-"
                      }\n Keyword Focus: ${task?.keyword ?? "-"}\n Suggested Topic: ${order?.topic ?? "-"}`}
                    />
                    <HelpText>This button will copy OrderId, Client Website, Topic & Primary Keyword</HelpText>
                  </Box>
                </Stack>
              </PanelBottom>
            </PanelGroup>
          )}
        </Stack>
        <Stack spacing={4}>
          <PanelGroup>
            <PanelTop showBottomBorder>
              <Heading size="sm">
                <Text>Fulfillment Details</Text>
              </Heading>
            </PanelTop>
            <PanelMiddle>
              <Stack spacing={4}>
                <FormControl
                  isRequired
                  isInvalid={!!formik.touched.fulFillmentMethod && !!formik.errors.fulFillmentMethod}
                >
                  <FormLabel>Fulfillment Method</FormLabel>
                  <Select
                    variant="outline"
                    value={formik.values.fulFillmentMethod}
                    onChange={formik.handleChange}
                    id="fulFillmentMethod"
                  >
                    {Object.values(FULFILLMENT_OPTIONS).map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.display}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formik.errors.fulFillmentMethod}</FormErrorMessage>
                </FormControl>
                {formik.values.fulFillmentMethod === FULFILLMENT_OPTIONS.INTERNAL_FULFILLMENT.id && (
                  <FormControl isInvalid={!!formik.touched.targetResultUrl && !!formik.errors.targetResultUrl}>
                    <FormLabel>Target Result URL</FormLabel>
                    <Input
                      type="text"
                      variant="outline"
                      id="targetResultUrl"
                      value={formik.values.targetResultUrl}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormErrorMessage>{formik.errors.targetResultUrl}</FormErrorMessage>
                  </FormControl>
                )}
                {formik.values.fulFillmentMethod === FULFILLMENT_OPTIONS.EXTERNAL_FULFILLMENT.id && (
                  <>
                    <FormControl isRequired isInvalid={!!formik.touched.vendorId && !!formik.errors.vendorId}>
                      <FormLabel>External Vendor</FormLabel>
                      <VendorSelector
                        onVendorSelected={onVendorSelected}
                        selectedVendorId={formik.values.vendorId}
                        isExternal={true}
                      />
                      <FormErrorMessage>{formik.errors.vendorId}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isRequired
                      isInvalid={!!formik.touched.externalOrderId && !!formik.errors.externalOrderId}
                    >
                      <FormLabel>External Order ID</FormLabel>
                      <Input
                        type="text"
                        variant="outline"
                        id="externalOrderId"
                        value={formik.values.externalOrderId}
                        onChange={formik.handleChange}
                      />
                      <FormErrorMessage>{formik.errors.externalOrderId}</FormErrorMessage>
                      <FormHelperText>
                        The invoice or order identifier to lookup the order in the vendor’s system
                      </FormHelperText>
                    </FormControl>
                    <FormControl isRequired isInvalid={!!formik.touched.submittedAt && !!formik.errors.submittedAt}>
                      <FormLabel>Order Submitted Date</FormLabel>
                      <DatePicker
                        onChange={onSubmittedDateChange}
                        selected={formik.values.submittedAt}
                        minDate={DateTime.now().minus({ weeks: 2 }).toJSDate()}
                        dateFormat={DATE_PICKER_FORMAT}
                        customInput={<Input />}
                        placeholderText="Select order submitted date"
                      />
                      <FormErrorMessage>{formik.errors.submittedAt}</FormErrorMessage>
                      <FormHelperText>The date the order was submitted to the external vendor</FormHelperText>
                    </FormControl>
                    <FormControl isRequired isInvalid={!!formik.touched.cost && !!formik.errors.cost}>
                      <FormLabel>Cost</FormLabel>
                      <InputGroup>
                        <InputLeftElement color="gray.300" fontSize="1.2em">
                          $
                        </InputLeftElement>
                        <Input
                          placeholder="Enter Cost"
                          type="number"
                          variant="outline"
                          id="cost"
                          value={formik.values.cost ?? ""}
                          onChange={formik.handleChange}
                        />
                      </InputGroup>
                      <FormErrorMessage>{formik.errors.cost}</FormErrorMessage>
                      <FormHelperText>The vendor provided cost to fulfill the order</FormHelperText>
                    </FormControl>
                    <FormControl isInvalid={!!formik.touched.domainAuthority && !!formik.errors.domainAuthority}>
                      <FormLabel>Domain Authority</FormLabel>
                      <Input
                        placeholder="Enter Domain Authority"
                        type="number"
                        variant="outline"
                        id="domainAuthority"
                        value={formik.values.domainAuthority ?? ""}
                        onChange={formik.handleChange}
                      />
                      <FormErrorMessage>{formik.errors.domainAuthority}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formik.touched.trustFlow && !!formik.errors.trustFlow}>
                      <FormLabel>Trust Flow</FormLabel>
                      <Input
                        placeholder="Enter Trust Flow"
                        type="number"
                        variant="outline"
                        id="trustFlow"
                        value={formik.values.trustFlow ?? ""}
                        onChange={formik.handleChange}
                      />
                      <FormErrorMessage>{formik.errors.trustFlow}</FormErrorMessage>
                    </FormControl>
                  </>
                )}
              </Stack>
            </PanelMiddle>
            <PanelBottom>
              <Stack isInline spacing={2} justifyContent="space-between" shouldWrapChildren>
                <PrimaryButton onClick={handleSubmit} isLoading={formik.isSubmitting}>
                  Confirm Fulfillment Method
                </PrimaryButton>
                <Box>
                  <DangerButton onClick={onAbandon} isDisabled={formik.isSubmitting}>
                    Unassign Task
                  </DangerButton>
                  <HelpText standardLeftMargin>
                    &quot;Unassign Task&quot; will unassign this task from you and return it to the queue.{" "}
                    <strong>This cannot be undone and may result in lost work.</strong>
                  </HelpText>
                </Box>
              </Stack>
            </PanelBottom>
          </PanelGroup>
          {formik.values.fulFillmentMethod === FULFILLMENT_OPTIONS.INTERNAL_FULFILLMENT.id && (
            <LinkLocation customerId={customer?.id ?? 0} />
          )}
        </Stack>
      </Grid>
    </>
  );
}

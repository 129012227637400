import * as yup from "yup";
import { useFormik } from "formik";

interface Options {
  orderId: number | null;
  onCancel: () => void;
  onReCreate: () => void;
}

interface FormValues {
  confirmText: string;
}

export function useConfirmOutreach(options: Options) {
  const { onCancel, onReCreate, orderId } = options;

  const confirmPrompt = `Re Create Guest Task for Order ${orderId}`;

  const formik = useFormik<FormValues>({
    initialValues: {
      confirmText: "",
    },
    validationSchema: yup.object().shape({
      confirmText: yup.string().label("Confirmation Text").required().equals([confirmPrompt]),
    }),
    validateOnMount: true,
    onSubmit: async () => {
      if (!orderId) {
        return;
      }
      onReCreate();
      onCancel();
    },
  });

  function handleCancel() {
    formik.resetForm();
    onCancel();
  }

  return {
    confirmPrompt,
    handleCancel,
    formik,
  };
}

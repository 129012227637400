import { DateTime } from "luxon";
import { Comment_ReviewTaskFragment } from "../../generated/graphql";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { Heading, Stack, Box, Text } from "@chakra-ui/react";
import AppIcon from "../../components/app-icon";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { AppColors } from "../../core/custom-theme";
import { DATE_TIME_FORMAT } from "../../constants/constants";
import { RichContent } from "../../components/rich-content";
import { TASK_TYPES } from "../../constants/task-types";
import { FormHelpers } from "../../util/form-helpers";

interface Props {
  reviewTasks: Comment_ReviewTaskFragment[];
}

export function ReviewCommentsPanel(props: Props) {
  const { reviewTasks } = props;

  if (reviewTasks.length === 0) {
    return null;
  }

  return (
    <PanelGroup isCollapsible>
      <PanelTop showBottomBorder>
        <Heading size="md">Review Comments</Heading>
      </PanelTop>
      <PanelBottom>
        <Stack spacing={4}>
          {reviewTasks.map((reviewTask) => {
            if ((reviewTask.reviewComment ?? "") === "") {
              return null;
            }
            return (
              <Box key={reviewTask.id}>
                <Stack isInline shouldWrapChildren spacing={2} alignItems="flex-end">
                  <Text>
                    <AppIcon icon={faUserCircle} />
                  </Text>
                  <Text fontWeight="bold">
                    {reviewTask.taskTypeId === TASK_TYPES.CUSTOMER_CONTENT_REVIEW ? "Client" : "Reviewer"}
                  </Text>
                  <Text fontSize="sm" mb="2px" color={AppColors.textGray}>
                    {DateTime.fromJSDate(reviewTask.completedAt ?? new Date()).toFormat(DATE_TIME_FORMAT)}
                  </Text>
                </Stack>
                <Box mt={2}>
                  <RichContent
                    content={FormHelpers.processNullableString(reviewTask.reviewComment ?? "") ?? "No comment."}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
      </PanelBottom>
    </PanelGroup>
  );
}

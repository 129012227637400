import { SimpleGrid, Text } from "@chakra-ui/react";

import { AddPaymentMethodModal } from "../../common/add-payment-method-modal";
import { ConfirmDangerModal } from "../../common/confirm-danger-modal";
import { PrimaryButtonLink } from "../../components/buttons";
import { Loader } from "../../components/loader";
import { PanelBox } from "../../components/panels";
import { PaymentMethodItem } from "./payment-method-item";
import { usePaymentMethods } from "./payment-methods-hook";

interface Props {
  partnerId: number;
  onPaymentMethodAdded: () => void;
  onPaymentMethodRemoved: () => void;
}

export function PaymentMethods(props: Props) {
  const {
    partnerId,
    handleAdd,
    handleClose,
    handleOnAdded,
    isAdding,
    isLoading,
    partnerPaymentMethods,
    handleConfirmRemove,
    handleConfirmRemoveClose,
    handleRemove,
    partnerPaymentMethodIdToRemove,
  } = usePaymentMethods(props);

  if (isLoading) {
    return <Loader type="panel" />;
  }

  return (
    <>
      <AddPaymentMethodModal partnerId={partnerId} isOpen={isAdding} onClose={handleClose} onAdded={handleOnAdded} />
      <ConfirmDangerModal
        isOpen={!!partnerPaymentMethodIdToRemove}
        isProcessing={false}
        onCancel={handleConfirmRemoveClose}
        onConfirm={handleConfirmRemove}
        title="Remove Payment Method"
        dangerButtonText="Remove"
      >
        <Text>Are you sure you want to remove this payment method?</Text>
      </ConfirmDangerModal>
      <SimpleGrid columns={2} spacing={4}>
        {partnerPaymentMethods.map((partnerPaymentMethod) => (
          <PaymentMethodItem
            key={partnerPaymentMethod.id}
            partnerPaymentMethod={partnerPaymentMethod}
            onRemove={() => {
              handleRemove(partnerPaymentMethod.id);
            }}
          />
        ))}
        <PanelBox height="120px" display="flex" justifyContent="center" alignItems="center">
          <PrimaryButtonLink onClick={handleAdd}>Add a payment method</PrimaryButtonLink>
        </PanelBox>
      </SimpleGrid>
    </>
  );
}

import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  Portal,
  Stack,
  Text,
} from "@chakra-ui/react";
import AppIcon from "../../components/app-icon";
import { faCaretDown, faColumns } from "@fortawesome/free-solid-svg-icons";
import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  items: {
    icon?: IconProp;
    iconColor?: string;
    displayText: string;
    value: string;
  }[];
  value: string[];
  onChange: (value: string[]) => void;
  pluralizedItemName?: string;
  emptyValueTitle?: string;
  selectorIcon?: IconDefinition;
  showSelectAll?: boolean;
  dontUsePortal?: boolean;
  isDisabled?: boolean;
}

export function CustomMultiSelect(props: Props) {
  const {
    items,
    onChange,
    value,
    pluralizedItemName,
    emptyValueTitle,
    selectorIcon,
    showSelectAll = false,
    dontUsePortal = false,
    isDisabled,
  } = props;

  function handleSelect(selectedValue: string) {
    if (value.includes(selectedValue)) {
      onChange(value.filter((x) => x !== selectedValue));
    } else {
      onChange([...value, selectedValue]);
    }
  }

  function handleSelectAll() {
    onChange(items.map((item) => item.value));
  }

  function handleUnSelectAll() {
    onChange([]);
  }

  const selectedItems = items.filter((x) => value.includes(x.value));

  const menuList = (
    <MenuList maxH={200} overflowY="scroll">
      {items.length === 0 && <MenuItem isFocusable={false}>No items.</MenuItem>}
      {items.length > 0 && showSelectAll && (
        <>
          <MenuItemOption fontSize="sm" key="select-all" value="select-all" onClick={handleSelectAll}>
            Select All
          </MenuItemOption>
          <MenuItemOption fontSize="sm" key="unselect-all" value="unselect-all" onClick={handleUnSelectAll}>
            Unselect All
          </MenuItemOption>
          <MenuDivider />
        </>
      )}
      {items.map((item, index) => (
        <MenuItemOption
          fontSize="sm"
          key={index}
          value={item.value}
          isChecked={value.includes(item.value)}
          onClick={() => {
            handleSelect(item.value);
          }}
        >
          {item.icon && <AppIcon icon={item.icon} standardRightMargin />}
          {item.displayText}
        </MenuItemOption>
      ))}
    </MenuList>
  );

  return (
    <Menu closeOnSelect={false} isLazy preventOverflow>
      <MenuButton as={Button} isDisabled={isDisabled}>
        <Stack isInline spacing={2}>
          <AppIcon icon={selectorIcon ? selectorIcon : faColumns} standardRightMargin />
          <Text isTruncated>
            {value.length === 0 && (emptyValueTitle ?? "None")}
            {value.length === 1 && (selectedItems?.[0]?.displayText ?? "")}
            {value.length > 1 && `${selectedItems.length} ${pluralizedItemName ?? "Items"}`}
          </Text>
          <AppIcon icon={faCaretDown} standardLeftMargin />
        </Stack>
      </MenuButton>
      {dontUsePortal ? menuList : <Portal>{menuList}</Portal>}
    </Menu>
  );
}

import { useChecklistTaskWorkspaceQuery, useChecklistTaskWorkspace_UpdateOrderMutation } from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import * as yup from "yup";
import { useFormik } from "formik";
import { getDisplayMessageForError } from "../../util/error-helper";
import { FormHelpers } from "../../util/form-helpers";

interface Options {
  taskId: number;
  onComplete: () => void;
}

interface FormValues {
  resultUrl: string;
  resultUrlDomainAuthority?: number | null;
}

export function useTaskWorkspaceChecklist(options: Options) {
  const { taskId } = options;

  const queryResult = useChecklistTaskWorkspaceQuery({
    fetchPolicy: "network-only",
    variables: {
      taskId,
    },
  });
  const toast = useToast();
  const [updateOrderMutation] = useChecklistTaskWorkspace_UpdateOrderMutation();

  const task = queryResult.data?.task ?? null;
  const checklist = queryResult.data?.task?.checklist ?? null;
  const checklistItems = queryResult.data?.task?.checklist?.checklistItems ?? [];
  const customer = queryResult.data?.task?.customer ?? null;
  const completedCount = checklistItems.filter((x) => !!x.completedAt).length;
  const completedPercentage = Math.round((completedCount / checklistItems.length) * 100);

  const formik = useFormik<FormValues>({
    initialValues: {
      resultUrl: task?.order.resultUrl ?? "",
      resultUrlDomainAuthority: task?.order?.resultUrlDomainAuthority ?? null,
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      resultUrl: yup
        .string()
        .label("Order Result URL")
        .when("resultUrlDomainAuthority", {
          is: (resultUrlDomainAuthority: any) => Boolean(resultUrlDomainAuthority),
          then: yup.string().required("Order Result URL is required while adding Result URL Domain Authority.").url(),
          otherwise: yup.string().notRequired().nullable(true).url(),
        }),
      resultUrlDomainAuthority: yup.number().label("Result URL Domain Authority").notRequired().min(0).nullable(true),
    }),
    onSubmit: async (values) => {
      if (!task) {
        return;
      }
      try {
        await updateOrderMutation({
          variables: {
            input: {
              orderId: task.order.id,
              resultUrl: FormHelpers.processNullableString(values.resultUrl),
              resultUrlDomainAuthority: FormHelpers.processNullableInt(values.resultUrlDomainAuthority),
            },
          },
        });
        toast({ title: "Order Result URL updated.", status: "success" });
      } catch (e: any) {
        toast({ title: "Unable to Save Order Result URL", description: getDisplayMessageForError(e), status: "error" });
      }
    },
  });

  function onChecklistItemEdited() {
    queryResult.refetch();
  }

  function onComplete() {
    if (completedCount < checklistItems.length) {
      toast({
        title: "Unable to Complete Task",
        description: "All checklist items must be completed before completing the task.",
        status: "error",
      });
      return;
    }

    options.onComplete();
  }

  function onOrderResultUrlFormCancel() {
    formik.resetForm();
  }

  return {
    taskId,
    loading: queryResult.loading,
    task,
    checklist,
    checklistItems,
    completedCount,
    completedPercentage,
    customer,
    onChecklistItemEdited,
    onComplete,
    formik,
    onOrderResultUrlFormCancel,
  };
}

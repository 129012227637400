import * as yup from "yup";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";

import {
  File,
  useQaContentSendTaskWorkspaceQuery,
  useQaContentSendTaskWorkspace_SaveTaskContentItemMutation,
} from "../../../generated/graphql";
import { getDisplayMessageForError } from "../../../util/error-helper";
import { UploadFile } from "../../upload-file-modal/use-upload-file-modal";

type ServerFile = Pick<File, "id" | "name" | "url">;

interface Options {
  taskId: number;
  onComplete: (isApproved?: boolean, reviewComment?: string) => void;
}

interface FormValues {
  title: string;
  content: string;
  isApproved: boolean;
  comments: string;
  file?: ServerFile | null;
}

const validationSchema = yup.object().shape({
  title: yup.string().label("Title").required().nullable(false).min(1),
  content: yup.string().label("Content").required().nullable(false).min(1),
});

export function useQaContent(options: Options) {
  const { onComplete, taskId } = options;
  const [showThumbnailUpload, setShowThumbnailUpload] = useState(false);
  const taskWorkspaceContentReviewQueryResult = useQaContentSendTaskWorkspaceQuery({
    fetchPolicy: "no-cache",
    variables: {
      taskId,
    },
  });
  const [saveTaskContentItemMutation] = useQaContentSendTaskWorkspace_SaveTaskContentItemMutation();
  const toast = useToast();

  const task = taskWorkspaceContentReviewQueryResult.data?.task ?? null;
  const reviewTargetTask = task?.reviewTargetTask ?? null;
  const contentItem = task?.reviewTargetTask?.contentItem ?? null;
  const reviewTasks = (task?.reviewTargetTask?.reviewTasks.nodes ?? []).filter(
    (reviewTask) => !!reviewTask.reviewComment
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleSaveTask(values: FormValues, _helpers: any) {
    if (!reviewTargetTask) {
      toast({ title: "Unable to Complete Task", status: "error" });
      return;
    }
    try {
      await saveTaskContentItemMutation({
        variables: {
          input: {
            content: values.content,
            taskId: reviewTargetTask.id,
            title: values.title,
            thumbnailS3FileId: values.file?.id ?? null,
            ignoreContentTaskPermission: true,
          },
        },
      });
      onComplete();
    } catch (e: any) {
      toast({ title: "Unable to Complete Task", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      content: contentItem?.content ?? "",
      title: contentItem?.title ?? "",
      file: contentItem?.thumbnailFile,
      isApproved: true,
      comments: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSaveTask,
  });

  function onThumbnailUpload() {
    setShowThumbnailUpload(true);
  }

  function onThumbnailUploadCancel() {
    setShowThumbnailUpload(false);
  }

  function onThumbnailUploaded(fileId: string | null, file?: UploadFile) {
    if (fileId && file) {
      formik.setFieldValue("file", { id: fileId, url: file.url, name: file.name });
    }
    setShowThumbnailUpload(false);
  }

  function onThumbnailRemove() {
    formik.setFieldValue("file", null);
  }

  return {
    taskId,
    loading: taskWorkspaceContentReviewQueryResult.loading,
    reviewTargetTask,
    contentItem,
    formik,
    task,
    reviewTasks,
    showThumbnailUpload,
    onThumbnailUpload,
    onThumbnailUploaded,
    onThumbnailUploadCancel,
    onThumbnailRemove,
  };
}

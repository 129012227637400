import { Box, Text } from "@chakra-ui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../components/app-icon";

export const ApiDown = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box p={4} mt={1} maxW="30%" shadow="md" borderWidth="1px" borderRadius={4}>
        <Text as="h4" fontSize="3xl">
          Oops...
        </Text>
        <Box margin="10px">
          <Box w="25%" display="inline-block">
            <AppIcon icon={faExclamationTriangle} size="4x" />
          </Box>
          <Box w="75%" display="inline-block">
            <Text fontSize="md">
              We&apos;re having some technical difficulties at the moment. As soon as things are resolved, this page
              will automatically refresh.
            </Text>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

import { Box, Divider, Flex, Grid, Heading, Image, Stack, Text } from "@chakra-ui/react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import RcmLogo from "../../assets/red-canyon-media-logo-horizontal-gray@2x.png";
import AppIcon from "../../components/app-icon";
import AppLink from "../../components/app-link";
import { FormattedDate } from "../../components/formatted-date";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { Loader } from "../../components/loader";
import { PageHeading } from "../../components/page-heading";
import { PanelBox } from "../../components/panels";
import { Table, TableBody, TableCell, TableFoot, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { AppColors } from "../../core/custom-theme";
import { currencyFormatter } from "../../util/formatter";
import { useBillingInvoice } from "./billing-invoice-hook";
import { PaymentStatus } from "./payment-status";
import { MakePayment } from "./make-payment";
import { PrimaryButton } from "../../components/buttons";

export function BillingInvoiceRoute() {
  const {
    partnerId,
    partnerInvoiceId,
    partnerInvoice,
    isLoading,
    chargesByCustomer,
    invoicePayments,
    chargesTotal,
    clearedPaymentsTotal,
    totalDue,
    handlePartnerPaymentMethodChange,
    handleSubmitPayment,
    isPaymentProcessing,
    selectedPartnerPaymentMethodId,
    handlePaymentTermsAcceptedChange,
    isPaymentTermsAccepted,
    handleDownloadPdf,
  } = useBillingInvoice();

  return (
    <LayoutBase pageTitle="Invoice Details">
      <MainNav />
      <Container centerHorizontally>
        <Stack spacing={4} marginTop={4}>
          <AppLink to={`/billing/${partnerId}`}>
            <AppIcon icon={faChevronLeft} standardRightMargin />
            Billing Dashboard
          </AppLink>
          <PageHeading>Invoice (ID: {partnerInvoiceId})</PageHeading>
          <Box>
            <PrimaryButton onClick={handleDownloadPdf}>Download PDF</PrimaryButton>
          </Box>
          {isLoading && <Loader />}
          {!isLoading && partnerInvoice && (
            <Stack spacing={4}>
              {totalDue > 0 && (
                <MakePayment
                  handlePartnerPaymentMethodChange={handlePartnerPaymentMethodChange}
                  handleSubmitPayment={handleSubmitPayment}
                  handlePaymentTermsAcceptedChange={handlePaymentTermsAcceptedChange}
                  isPaymentProcessing={isPaymentProcessing}
                  isPaymentTermsAccepted={isPaymentTermsAccepted}
                  partnerId={partnerId}
                  selectedPartnerPaymentMethodId={selectedPartnerPaymentMethodId}
                  totalAmount={totalDue}
                />
              )}
              <PanelBox padding={8}>
                <Stack spacing={4}>
                  <Flex justifyContent="space-between" marginBottom={4}>
                    <Stack spacing={2}>
                      <Image src={RcmLogo} maxW="345px" objectFit="contain" alt="Red Canyon Media" />
                      <Text>
                        Red Canyon Media
                        <br />
                        2901 W Bluegrass Blvd. Suite 200-85
                        <br />
                        Lehi, Utah 84043
                        <br />
                        USA
                      </Text>
                      <Box>
                        <Text fontSize="sm" color={AppColors.textGray}>
                          Bill To
                        </Text>
                        <Text>{partnerInvoice.partner.name}</Text>
                        {partnerInvoice.partner.billingEmail && <Text>{partnerInvoice.partner.billingEmail}</Text>}
                      </Box>
                    </Stack>
                    <Stack spacing={8}>
                      <Box textAlign="right">
                        <Text fontWeight="bold" fontSize="lg">
                          INVOICE
                        </Text>
                      </Box>
                      <Stack isInline spacing={4}>
                        <Stack spacing={2} textAlign="right">
                          <Text>Invoice ID</Text>
                          <Text>Invoice Date</Text>
                          <Text>Due Date</Text>
                        </Stack>
                        <Divider orientation="vertical" />
                        <Stack spacing={2}>
                          <Text>{partnerInvoice.id}</Text>
                          <Text>
                            <FormattedDate format={DATE_MONTH_FORMAT} date={partnerInvoice.createdAt} />
                          </Text>
                          <Text>
                            <FormattedDate format={DATE_MONTH_FORMAT} date={partnerInvoice.createdAt} />
                          </Text>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Flex>
                  <Grid gridTemplateColumns="30% 1fr" gridColumnGap={4}>
                    <Box>
                      <Heading size="md" marginBottom={2}>
                        Summary
                      </Heading>
                      <PanelBox containsTable>
                        <Table isEmpty={false} isLoading={false}>
                          <TableBody>
                            <TableRow>
                              <TableCell>Total Charges</TableCell>
                              <TableCell>
                                <Text fontWeight="bold">{currencyFormatter.format(chargesTotal)}</Text>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Total Payments</TableCell>
                              <TableCell>
                                <Text fontWeight="bold">{currencyFormatter.format(clearedPaymentsTotal)}</Text>
                              </TableCell>
                            </TableRow>
                            {partnerInvoice.totalRefundsAmount > 0 && (
                              <TableRow>
                                <TableCell>Total Refunds</TableCell>
                                <TableCell>
                                  <Text fontWeight="bold">
                                    {currencyFormatter.format(partnerInvoice.totalRefundsAmount)}
                                  </Text>
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell>Total Due</TableCell>
                              <TableCell>
                                <Text fontWeight="bold">{currencyFormatter.format(totalDue)}</Text>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </PanelBox>
                    </Box>
                    <Box>
                      <Heading size="md" marginBottom={2}>
                        Payments
                      </Heading>
                      {invoicePayments.length === 0 && <PanelBox>No payments have been made.</PanelBox>}
                      {invoicePayments.length > 0 && (
                        <PanelBox containsTable>
                          <Table isEmpty={invoicePayments.length === 0} isLoading={false}>
                            <TableHead>
                              <TableRow>
                                <TableHeaderCell>Date</TableHeaderCell>
                                <TableHeaderCell>Payment Method</TableHeaderCell>
                                <TableHeaderCell>Status</TableHeaderCell>
                                <TableHeaderCell>Amount</TableHeaderCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {invoicePayments.map((invoicePayment) => (
                                <TableRow key={invoicePayment.id}>
                                  <TableCell>
                                    <FormattedDate format={DATE_MONTH_FORMAT} date={invoicePayment.createdAt} />
                                  </TableCell>
                                  <TableCell>{invoicePayment.paymentMethodDescription ?? "Payment"}</TableCell>
                                  <TableCell>
                                    <PaymentStatus
                                      status={invoicePayment.status}
                                      errorMessage={invoicePayment.errorMessage}
                                    />
                                  </TableCell>
                                  <TableCell>{currencyFormatter.format(invoicePayment.amount)}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </PanelBox>
                      )}
                    </Box>
                  </Grid>
                  <Box>
                    <Heading size="md" marginBottom={4}>
                      Details
                    </Heading>
                    <Stack spacing={8}>
                      {chargesByCustomer.map((customerItem) => (
                        <Stack key={customerItem.customerId} spacing={2}>
                          <Heading size="sm">
                            {customerItem.customer?.name ?? "Client"} (ID: {customerItem.customer?.id ?? ""})
                          </Heading>
                          <PanelBox containsTable>
                            <Table isEmpty={false} isLoading={false}>
                              <TableHead>
                                <TableRow>
                                  <TableHeaderCell>Order ID</TableHeaderCell>
                                  <TableHeaderCell>Description</TableHeaderCell>
                                  <TableHeaderCell>Start Date</TableHeaderCell>
                                  <TableHeaderCell>Amount</TableHeaderCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {customerItem.items.map((invoiceCharge) => (
                                  <TableRow key={invoiceCharge.id}>
                                    <TableCell>
                                      {invoiceCharge.order && (
                                        <AppLink to={`/orders/${invoiceCharge.order.id}`}>
                                          {invoiceCharge.order.id}
                                        </AppLink>
                                      )}
                                      {!invoiceCharge.order && "-"}
                                    </TableCell>
                                    <TableCell>
                                      <Text>{invoiceCharge.order?.partnerProduct.product.name ?? "-"}</Text>
                                      {invoiceCharge.order?.activeOrderContentStrategy?.topic && (
                                        <Text fontSize="sm" color={AppColors.textGray}>
                                          {invoiceCharge.order?.activeOrderContentStrategy?.topic}
                                        </Text>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <FormattedDate
                                        format={DATE_MONTH_FORMAT}
                                        date={invoiceCharge.order?.startDate ?? new Date()}
                                      />
                                    </TableCell>
                                    <TableCell width="150px">
                                      <Text>{currencyFormatter.format(invoiceCharge.amount)}</Text>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                              <TableFoot>
                                <TableRow>
                                  <TableCell colSpan={3}>
                                    <Box>
                                      <strong>Client Total</strong>
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <strong>{currencyFormatter.format(customerItem.totalAmount)}</strong>
                                  </TableCell>
                                </TableRow>
                              </TableFoot>
                            </Table>
                          </PanelBox>
                        </Stack>
                      ))}
                    </Stack>
                  </Box>
                </Stack>
              </PanelBox>
            </Stack>
          )}
        </Stack>
      </Container>
    </LayoutBase>
  );
}

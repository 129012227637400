import { usePartnerPackagesQuery } from "../../generated/graphql";
import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";

export function usePartnerPackages() {
  const { partnerId: partnerIdRaw } = useParams<"partnerId">();
  const partnerId = parseInt(partnerIdRaw ?? "", 10);
  const partnerPackagesQueryResult = usePartnerPackagesQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId,
    },
  });
  const navigate = useNavigate();
  const [isAdding, setIsAdding] = useState(false);

  function onAdded(partnerPackageId: number) {
    navigate(`/configuration/${partnerId}/packages/${partnerPackageId}`);
  }

  function onAddPackage() {
    setIsAdding(true);
  }

  function onAddPackageCancel() {
    setIsAdding(false);
  }

  return {
    loading: partnerPackagesQueryResult.loading,
    packages: partnerPackagesQueryResult.data?.partnerPackages.nodes ?? [],
    partner: partnerPackagesQueryResult.data?.partner ?? null,
    partnerId,
    isAdding,
    onAddPackage,
    onAddPackageCancel,
    onAdded,
  };
}

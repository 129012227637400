import { useState } from "react";
import { PAGE_LIMIT } from "../../constants/constants";
import { useAdminReports_PublicationManagementQuery } from "../../generated/graphql";
import { usePagination } from "../../util/use-pagination";

export function usePublicationManagement() {
  const [treatReportingSmartsResultUrlAsNull, setTreatReportingSmartsResultUrlAsNull] = useState(false);
  const pagination = usePagination(PAGE_LIMIT);
  const queryResult = useAdminReports_PublicationManagementQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      treatReportingSmartsResultUrlAsNull,
    },
  });

  function onChangeTreatReportingSmartsResultUrlAsNull() {
    setTreatReportingSmartsResultUrlAsNull(!treatReportingSmartsResultUrlAsNull);
  }

  const orders = queryResult.data?.adminReports.publicationManagement.ordersNeedingResultUrl.nodes ?? [];
  const totalCount = queryResult.data?.adminReports.publicationManagement.ordersNeedingResultUrl.totalCount ?? 0;

  return {
    loading: queryResult.loading,
    totalCount,
    orders,
    pagination,
    treatReportingSmartsResultUrlAsNull,
    onChangeTreatReportingSmartsResultUrlAsNull,
  };
}

import { SelectProps, Select } from "@chakra-ui/react";

import { useUserSelector } from "./use-user-selector";

interface Props extends SelectProps {
  selectedUserId: number | null;
  onUserChanged: (userId: number | null) => void;
  nullUserText?: string;
  preventNullUser?: boolean;
  isAdminOrSuperAdmin?: boolean;
}

export function UserSelector(props: Props) {
  const { selectedUserId, onUserChanged, nullUserText, preventNullUser, isAdminOrSuperAdmin, ...rest } = props;

  const { loading, users, onUserChange } = useUserSelector({
    onUserChanged,
    isAdminOrSuperAdmin,
  });

  return (
    <Select isDisabled={loading} value={selectedUserId?.toString() ?? ""} onChange={onUserChange} {...rest}>
      <option key={0} value="" disabled={preventNullUser}>
        {nullUserText ?? "Select a User"}
      </option>
      {users.map((user) => (
        <option key={user.id} value={user.id.toString()}>
          {user.fullName}
        </option>
      ))}
    </Select>
  );
}

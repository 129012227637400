import { Text, Tooltip } from "@chakra-ui/react";
import {
  faExclamationTriangle,
  faThumbsUp,
  faTimesCircle,
  faQuestionCircle,
  faCheckCircle,
  faHourglassHalf,
} from "@fortawesome/free-solid-svg-icons";
import AppIcon from "../../components/app-icon";
import { DOMAIN_STATUS } from "../../constants/domain-status";

interface Props {
  id: string;
  name: string;
  message?: string | null;
}

export function DomainStatusIndicator(props: Props) {
  const { id, name, message } = props;
  switch (id) {
    case DOMAIN_STATUS.AVAILABLE.id:
      return (
        <Text color="blue">
          <AppIcon icon={faThumbsUp} standardRightMargin />
          {name}
        </Text>
      );
    case DOMAIN_STATUS.UNAVAILABLE.id:
      return (
        <Text color="red">
          <AppIcon icon={faTimesCircle} standardRightMargin />
          {name}
        </Text>
      );
    case DOMAIN_STATUS.UNKNOWN.id:
      return (
        <Text>
          <AppIcon icon={faQuestionCircle} standardRightMargin />
          {name}
        </Text>
      );
    case DOMAIN_STATUS.PURCHASED.id:
      return (
        <Text color="green">
          <AppIcon icon={faCheckCircle} standardRightMargin />
          {name}
        </Text>
      );
    case DOMAIN_STATUS.PURCHASE_REQUESTED.id:
      return (
        <Text color="orange">
          <AppIcon icon={faHourglassHalf} standardRightMargin />
          {name}
        </Text>
      );
    case DOMAIN_STATUS.PURCHASE_ERROR.id:
      return (
        <Tooltip label={message ?? "See logs"} aria-label={message ?? "See logs"}>
          <Text color="red">
            <AppIcon icon={faExclamationTriangle} standardRightMargin />
            {name}
          </Text>
        </Tooltip>
      );

    default:
      return (
        <Text>
          <AppIcon icon={faExclamationTriangle} standardRightMargin />
          {props.name}
        </Text>
      );
  }
}

import {
  Box,
  Text,
  Select,
  Input,
  FormControl,
  FormLabel,
  Stack,
  Heading,
  Textarea,
  FormHelperText,
  FormErrorMessage,
  Flex,
} from "@chakra-ui/react";
import { CategorySelector } from "../../common/category-selector";
import { PrimaryButton, CancelButton } from "../../components/buttons";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { useClientDetailsOverview } from "./use-client-details-overview";
import { Loader } from "../../components/loader";
import { QuestionnaireStatusIndicator } from "../../common/questionnaire-status-indicator/questionnaire-status-indicator";
import { BUSINESS_TYPE_OPTIONS } from "../../constants/business-type-options";

interface Props {
  customerId: number;
}

export function CompanyDetails(props: Props) {
  const { customerId } = props;
  const { isOverviewLoading, detailsFormik } = useClientDetailsOverview(customerId);

  function onCategorySelected(categoryId: number | null) {
    detailsFormik.setFieldValue("categoryId", categoryId);
  }

  function onSave() {
    detailsFormik.submitForm();
  }

  function onCancel() {
    detailsFormik.resetForm();
    setTimeout(async () => {
      await detailsFormik.validateForm();
    }, 0);
  }

  return (
    <PanelGroup isCollapsible>
      <PanelTop showBottomBorder justifyContent="stretch">
        <Flex justifyContent="space-between">
          <Stack spacing={2} shouldWrapChildren>
            <Heading size="md">Company Details</Heading>
            <Text fontSize="sm" color="#828282">
              Takes about 6 minutes
            </Text>
          </Stack>
          <Box>
            <QuestionnaireStatusIndicator values={detailsFormik.values} isValid={detailsFormik.isValid} />
          </Box>
        </Flex>
      </PanelTop>
      <PanelBottom>
        {isOverviewLoading && <Loader type="content" />}
        {!isOverviewLoading && (
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={!!detailsFormik.errors.website}>
              <FormLabel>Company URL</FormLabel>
              <Input
                variant="outline"
                id="website"
                value={detailsFormik.values.website ?? ""}
                onChange={detailsFormik.handleChange}
                placeholder="Full website URL is required"
              />
              <FormErrorMessage>{detailsFormik.errors.website}</FormErrorMessage>
              <FormHelperText>Must begin with http:// or https://</FormHelperText>
            </FormControl>
            <FormControl isInvalid={!!detailsFormik.errors.gbpUrl}>
              <FormLabel>GBP URL</FormLabel>
              <Input
                variant="outline"
                id="gbpUrl"
                value={detailsFormik.values.gbpUrl ?? ""}
                onChange={detailsFormik.handleChange}
                placeholder="GBP URL"
              />
              <FormErrorMessage>{detailsFormik.errors.gbpUrl}</FormErrorMessage>
              <FormHelperText>Must begin with http:// or https://</FormHelperText>
            </FormControl>
            <FormControl isRequired isInvalid={!!detailsFormik.errors.categoryId}>
              <FormLabel>Business Category</FormLabel>
              <CategorySelector
                showNoCategoryOption
                variant="outline"
                selectedCategoryId={detailsFormik.values.categoryId}
                onCategorySelected={onCategorySelected}
              />
              <FormErrorMessage>{detailsFormik.errors.categoryId}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!detailsFormik.errors.customerMarketSize}>
              <FormLabel>Business Type</FormLabel>
              <Select
                id="customerMarketSize"
                value={detailsFormik.values.customerMarketSize}
                onChange={detailsFormik.handleChange}
                placeholder="Select One"
                variant="outline"
              >
                {BUSINESS_TYPE_OPTIONS.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{detailsFormik.errors.customerMarketSize}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!detailsFormik.errors.businessDescription}>
              <FormLabel>About Company</FormLabel>
              <Textarea
                id="businessDescription"
                value={detailsFormik.values.businessDescription}
                onChange={detailsFormik.handleChange}
                resize="none"
                variant="outline"
              />
              <FormErrorMessage>{detailsFormik.errors.businessDescription}</FormErrorMessage>
              <FormHelperText>
                In your own words, how would you describe your business? What are the most notable features of your
                business? What are you known for that differentiates you from similar businesses.
              </FormHelperText>
            </FormControl>
            <FormControl isRequired isInvalid={!!detailsFormik.errors.productsOffered}>
              <FormLabel>What products or services do you offer?</FormLabel>
              <Textarea
                id="productsOffered"
                value={detailsFormik.values.productsOffered}
                onChange={detailsFormik.handleChange}
                resize="none"
                variant="outline"
              />
              <FormErrorMessage>{detailsFormik.errors.productsOffered}</FormErrorMessage>
              <FormHelperText>
                If there are too many to list, we understand. Please list the products and services you are focusing on
                at this time.
              </FormHelperText>
            </FormControl>
            <FormControl isRequired isInvalid={!!detailsFormik.errors.serviceArea}>
              <FormLabel>Service Area</FormLabel>
              <Textarea
                id="serviceArea"
                value={detailsFormik.values.serviceArea}
                onChange={detailsFormik.handleChange}
                resize="none"
                variant="outline"
              />
              <FormErrorMessage>{detailsFormik.errors.serviceArea}</FormErrorMessage>
              <FormHelperText>What cities and states do you serve your customers? List top focus areas</FormHelperText>
            </FormControl>
            {detailsFormik.dirty && (
              <Box>
                <PrimaryButton onClick={onSave} marginRight="8px">
                  Save
                </PrimaryButton>
                <CancelButton onClick={onCancel}>Cancel</CancelButton>
              </Box>
            )}
          </Stack>
        )}
      </PanelBottom>
    </PanelGroup>
  );
}

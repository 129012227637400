import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { CancelButton, PrimaryButton } from "../../components/buttons";
import { useAddPartnerBillingContactModal } from "./add-partner-billing-contact-modal-hook";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAdded: () => void;
  partnerId: number;
}

export function AddPartnerBillingContactModal(props: Props) {
  const { isOpen, onClose } = props;
  const { formik, isSubmitting } = useAddPartnerBillingContactModal(props);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal onClose={onClose} isOpen={isOpen} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Billing Contact</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl isRequired isInvalid={!!formik.touched.firstName && !!formik.errors.firstName}>
                <FormLabel>First Name</FormLabel>
                <Input value={formik.values.firstName} id="firstName" onChange={formik.handleChange} />
                <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!formik.touched.lastName && !!formik.errors.lastName}>
                <FormLabel>Last Name</FormLabel>
                <Input value={formik.values.lastName} id="lastName" onChange={formik.handleChange} />
                <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!formik.touched.email && !!formik.errors.email}>
                <FormLabel>Email</FormLabel>
                <Input value={formik.values.email} id="email" onChange={formik.handleChange} />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Stack spacing={2} isInline>
              <PrimaryButton onClick={() => formik.handleSubmit()} type="submit" isLoading={isSubmitting}>
                Save
              </PrimaryButton>
              <CancelButton onClick={onClose}>Cancel</CancelButton>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </form>
  );
}

import { Box, Flex, Stack, Text, Tooltip } from "@chakra-ui/react";
import { faDollarSign, faLock, faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";

import { AddPartnerProductDrawer } from "../../common/add-partner-product-drawer";
import { ConfigurationSecondaryNav } from "../../common/configuration-secondary-nav";
import { EditPartnerProductDrawer } from "../../common/edit-partner-product-drawer";
import { ManagePartnerProductPricesDrawer } from "../../common/manage-partner-product-prices-drawer/manage-partner-product-prices-drawer";
import AppIcon from "../../components/app-icon";
import { PrimaryButton, PrimaryIconButton } from "../../components/buttons";
import { HelpText } from "../../components/help-text";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { SearchInput } from "../../components/search-input/search-input";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { currencyFormatter } from "../../util/formatter";
import { useConfigurationProductTypes } from "./use-configuration-product-types";

export function ConfigurationProductTypesRoute() {
  const {
    loading,
    partnerId,
    partner,
    partnerProducts,
    setSearchValue,
    idBeingEdited,
    isAdding,
    onAdd,
    onAddCancel,
    onAdded,
    onEdit,
    onEditCancel,
    onEdited,
    managePriceId,
    onManagePrice,
    onManagePriceCancel,
    onDelete,
  } = useConfigurationProductTypes();

  const prefixPartnerName = partner ? `${partner.name} ` : "";

  return (
    <LayoutBase pageTitle={`${prefixPartnerName}Product Types`}>
      <MainNav hasSecondaryNav />
      <ConfigurationSecondaryNav partnerId={partnerId.toString()} activeSection="product-types" />
      <Container centerHorizontally>
        <AddPartnerProductDrawer isOpen={isAdding} onAdded={onAdded} onClose={onAddCancel} partnerId={partnerId} />
        <EditPartnerProductDrawer
          isOpen={!!idBeingEdited}
          onClose={onEditCancel}
          onEdited={onEdited}
          partnerProductId={idBeingEdited ?? 0}
        />
        <ManagePartnerProductPricesDrawer
          isOpen={!!managePriceId}
          onClose={onManagePriceCancel}
          partnerProductId={managePriceId ?? 0}
        />
        <PageHeading>{prefixPartnerName}Product Types</PageHeading>
        <PanelGroup>
          <PanelTop>
            <Flex justifyContent="space-between">
              <Box>
                <SearchInput
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </Box>
              <Box>
                <PrimaryButton onClick={onAdd}>Add Product Type</PrimaryButton>
              </Box>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <Table isEmpty={partnerProducts.length === 0} isLoading={loading} emptyText="No product types.">
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Active Date</TableHeaderCell>
                  <TableHeaderCell>Inactive Date</TableHeaderCell>
                  <TableHeaderCell>Base Product Type</TableHeaderCell>
                  <TableHeaderCell>
                    Price
                    <HelpText standardLeftMargin>
                      <Stack spacing={4}>
                        <Text>
                          <strong>Retail</strong> is the amount the client is being charged.
                        </Text>
                        <Text>
                          <strong>Wholesale</strong> is the amount Red Canyon Media is charging to fulfill the order
                          (the client will not see this).
                        </Text>
                      </Stack>
                    </HelpText>
                  </TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {partnerProducts.map((partnerProduct) => (
                  <TableRow key={partnerProduct.id}>
                    <TableCell>{partnerProduct.name}</TableCell>
                    <TableCell>{DateTime.fromJSDate(partnerProduct.activeAt).toFormat(DATE_MONTH_FORMAT)}</TableCell>
                    <TableCell>
                      {partnerProduct.inactiveAt
                        ? DateTime.fromJSDate(partnerProduct.inactiveAt).toFormat(DATE_MONTH_FORMAT)
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {partnerProduct.product.name}
                      <HelpText standardLeftMargin>{partnerProduct.product.description}</HelpText>
                    </TableCell>
                    <TableCell>
                      <Text fontSize="sm">
                        Retail:&nbsp;
                        <strong>
                          {currencyFormatter.format(partnerProduct.currentPartnerProductPrice?.price ?? 0)}
                        </strong>
                      </Text>
                      <Text fontSize="sm">
                        Wholesale: <strong>{currencyFormatter.format(partnerProduct.wholesalePrice ?? 0)}</strong>
                      </Text>
                    </TableCell>
                    <TableCell>
                      {partnerProduct.product.isSystemManaged && (
                        <Box textAlign="center">
                          <Tooltip
                            shouldWrapChildren
                            label="This product type is managed by the system."
                            aria-label="This product type is managed by the system."
                          >
                            <AppIcon icon={faLock} />
                          </Tooltip>
                        </Box>
                      )}
                      {!partnerProduct.product.isSystemManaged && (
                        <Stack isInline spacing={2} justifyContent="flex-end">
                          <PrimaryIconButton
                            onClick={() => {
                              onManagePrice(partnerProduct.id);
                            }}
                            icon={faDollarSign}
                            actionName="Manage Price"
                          />
                          <PrimaryIconButton
                            onClick={() => {
                              onEdit(partnerProduct.id);
                            }}
                            icon={faPencilAlt}
                            actionName="Edit"
                          />
                          <PrimaryIconButton
                            onClick={() => {
                              onDelete(partnerProduct.id);
                            }}
                            icon={faTrashAlt}
                            actionName="Delete"
                            isDisabled={!partnerProduct.canDelete}
                          />
                        </Stack>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}

import { Flex, Heading, Text, Tooltip } from "@chakra-ui/react";
import { faExclamationTriangle, faTrash } from "@fortawesome/free-solid-svg-icons";

import { ConfirmDangerModal } from "../../../common/confirm-danger-modal";
import { AlertWarning } from "../../../components/alerts";
import AppIcon from "../../../components/app-icon";
import AppLink from "../../../components/app-link";
import { PrimaryIconButton } from "../../../components/buttons";
import { FormattedDateUTC } from "../../../components/formatted-date-utc";
import { PanelBottom, PanelGroup, PanelTop } from "../../../components/panels";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../../components/table";
import { DATE_MONTH_FORMAT } from "../../../constants/constants";
import { ORDER_STATUS } from "../../../constants/order-status";
import { AppColors } from "../../../core/custom-theme";
import { useContentStrategyOrders } from "./content-strategy-orders-hook";

interface Props {
  orderId: number;
}

export function ContentStrategyOrders({ orderId }: Props) {
  const {
    loading,
    orderContentStrategies,
    handleConfirmDelete,
    handleDelete,
    handleDeleteCancel,
    orderContentStrategyToDelete,
    isDeleting,
  } = useContentStrategyOrders(orderId);

  if (orderContentStrategies.length === 0) {
    return null;
  }

  return (
    <>
      <ConfirmDangerModal
        dangerButtonText="Delete"
        isOpen={!!orderContentStrategyToDelete}
        isProcessing={isDeleting}
        onCancel={handleDeleteCancel}
        onConfirm={handleConfirmDelete}
        title="Delete Order Content Strategy"
      >
        <AlertWarning>
          <Text>
            This will delete data in any of the already completed fields for the content strategy for this order.
          </Text>
        </AlertWarning>
        <Text>
          Are you sure you want to delete the Content Strategy for Order{" "}
          {orderContentStrategyToDelete?.order?.id ?? "(unknown)"}?
        </Text>
      </ConfirmDangerModal>
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Heading size="md" textAlign="left">
            Orders Associated to Content Strategy
          </Heading>
        </PanelTop>
        <PanelBottom containsTable>
          <Table isEmpty={orderContentStrategies.length === 0} isLoading={loading}>
            <TableHead>
              <TableRow>
                <TableHeaderCell>Order ID</TableHeaderCell>
                <TableHeaderCell>Product</TableHeaderCell>
                <TableHeaderCell>Start Date</TableHeaderCell>
                <TableHeaderCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {orderContentStrategies.map((orderContentStrategy) => {
                const canDelete = orderContentStrategy.order?.status.id === ORDER_STATUS.SCHEDULED.id;
                return (
                  <TableRow key={orderContentStrategy.id}>
                    <TableCell>
                      <AppLink to={`/orders/${orderContentStrategy.order?.id ?? 0}`}>
                        {orderContentStrategy.order?.id ?? "?"}
                      </AppLink>
                    </TableCell>
                    <TableCell>{orderContentStrategy.order?.partnerProduct.product.name ?? "?"}</TableCell>
                    <TableCell>
                      <FormattedDateUTC
                        date={orderContentStrategy.order?.startDate}
                        format={DATE_MONTH_FORMAT}
                        nullText="-"
                      />
                    </TableCell>
                    <TableCell>
                      <Flex alignItems="center" justifyContent="flex-end">
                        {!canDelete && (
                          <Tooltip label="Unable to delete because order has already started.">
                            <span>
                              <AppIcon color={AppColors.warning} icon={faExclamationTriangle} standardRightMargin />
                            </span>
                          </Tooltip>
                        )}
                        <PrimaryIconButton
                          actionName="Delete"
                          icon={faTrash}
                          isDisabled={!canDelete}
                          onClick={() => {
                            handleDelete(orderContentStrategy.id);
                          }}
                        />
                      </Flex>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </PanelBottom>
      </PanelGroup>
    </>
  );
}

import {
  Box,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { faLock } from "@fortawesome/free-solid-svg-icons";

import DatePicker from "react-datepicker";
import { AlertInfo } from "../../components/alerts";
import AppIcon from "../../components/app-icon";
import { CancelButton, PrimaryButton } from "../../components/buttons";
import { DATE_PICKER_FORMAT } from "../../constants/constants";
import { currencyFormatter } from "../../util/formatter";
import { CustomerCampaignSelector } from "../customer-campaign-selector";
import { PartnerProductSelector } from "../partner-product-selector";
import { useAddOrderDrawer } from "./use-add-order-drawer";

interface Props {
  open: boolean;
  customerId: number;
  customerCampaignId: number;
  campaignCategoryId: string;
  onCancel: () => void;
  onAdded: (orderId: number) => void;
  minimumDate?: Date | null;
  maximumDate?: Date | null;
}

export function AddOrderDrawer(props: Props) {
  const { open, onCancel, onAdded, customerId, customerCampaignId, campaignCategoryId, minimumDate, maximumDate } =
    props;

  const {
    formik,
    handleCancel,
    dateRangeStart,
    dateRangeEnd,
    price,
    canStartImmediately,
    partnerId,
    isAccountManagementProductSelected,
  } = useAddOrderDrawer({
    customerId,
    onCancel,
    onOrderAdded: onAdded,
    minimumDate,
    maximumDate,
    customerCampaignId,
    campaignCategoryId,
  });

  return (
    <Drawer onClose={handleCancel} isOpen={open} placement="right" size="md" closeOnOverlayClick={false} isFullHeight>
      <DrawerOverlay />
      <form onSubmit={formik.handleSubmit}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Order</DrawerHeader>
          <DrawerBody>
            <Box padding={4} minWidth={400}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Product Type</FormLabel>
                  <PartnerProductSelector
                    onPartnerProductSelected={(partnerProductId) => {
                      formik.setFieldValue("partnerProductId", partnerProductId);
                    }}
                    selectedPartnerProductId={formik.values.partnerProductId}
                    partnerId={partnerId}
                    campaignCategoryId={campaignCategoryId}
                    variant="outline"
                    disableForSystemOrdered
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Campaign</FormLabel>
                  <CustomerCampaignSelector
                    customerId={customerId}
                    onSelectedCustomerCampaignChange={(customerCampaignId) => {
                      formik.setFieldValue("customerCampaignId", customerCampaignId);
                    }}
                    selectedCustomerCampaignId={formik.values.customerCampaignId}
                    allowNull={false}
                    variant="outline"
                    isDisabled
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Start Date</FormLabel>
                  {isAccountManagementProductSelected && (
                    <AlertInfo>
                      <Text>
                        Account Management will begin on the first day of the billing cycle and will automatically
                        complete when the billing cycle ends.
                      </Text>
                    </AlertInfo>
                  )}
                  {!isAccountManagementProductSelected && (
                    <Box>
                      <DatePicker
                        onChange={(date: Date) => {
                          formik.setFieldValue("startDate", date ?? dateRangeStart);
                        }}
                        isClearable={false}
                        selected={formik.values.startDate}
                        minDate={dateRangeStart}
                        maxDate={dateRangeEnd}
                        dateFormat={DATE_PICKER_FORMAT}
                        customInput={<Input variant="outline" width="100%" />}
                        disabledKeyboardNavigation
                        placeholderText="Select start date"
                      />
                    </Box>
                  )}
                </FormControl>
                {canStartImmediately && (
                  <FormControl>
                    <Checkbox
                      isChecked={formik.values.startImmediately}
                      onChange={formik.handleChange}
                      id="startImmediately"
                    >
                      Start Immediately
                    </Checkbox>
                  </FormControl>
                )}
                <FormControl isDisabled>
                  <FormLabel>Price</FormLabel>
                  <InputGroup>
                    <Input variant="outline" value={currencyFormatter.format(price)} />
                    <InputRightElement>
                      <AppIcon icon={faLock} />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Instructions</FormLabel>
                  <Textarea
                    value={formik.values.instructions}
                    id="instructions"
                    name="instructions"
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Stack>
            </Box>
          </DrawerBody>
          <DrawerFooter>
            <SimpleGrid columns={2} gridGap={4} width="100%">
              <PrimaryButton width="100%" type="submit">
                Save
              </PrimaryButton>
              <CancelButton onClick={handleCancel} width="100%">
                Cancel
              </CancelButton>
            </SimpleGrid>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
}

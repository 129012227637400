import React, { useEffect, useState } from "react";

import { LinkLocationsSort, useLinkLocationsUsedQuery } from "../../generated/graphql";
import { PAGE_LIMIT } from "../../constants/constants";

interface Options {
  customerId: number;
  search?: string;
}

export function useLinkLocationsCustomer(options: Options) {
  const { customerId } = options;

  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState(options.search ?? "");
  const [statusIds, setStatusIds] = useState<string[]>([]);
  const [typeIds, setTypeIds] = useState<string[]>([]);
  const [minDa, setMinDa] = useState<number | undefined>(undefined);
  const [minTraffic, setMinTraffic] = useState<number | undefined>(undefined);
  const [sort, setSort] = useState<string | undefined>(undefined);

  const [pendingOffset, setPendingOffset] = useState(0);
  const [pendingSearch, setPendingSearch] = useState(options.search ?? "");
  const [pendingTypeIds, setPendingTypeIds] = useState<string[]>([]);
  const [pendingSort, setPendingSort] = useState<string | undefined>(undefined);

  const [rejectedOffset, setRejectedOffset] = useState(0);
  const [rejectedSearch, setRejectedSearch] = useState(options.search ?? "");
  const [rejectedTypeIds, setRejectedTypeIds] = useState<string[]>([]);
  const [rejectedSort, setRejectedSort] = useState<string | undefined>(undefined);

  useEffect(() => {
    setOffset(0);
  }, [search, statusIds, typeIds, minDa, minTraffic]);

  useEffect(() => {
    if (options.search) {
      setSearch(options.search);
      setRejectedSearch(options.search);
      setPendingSearch(options.search);
    }
  }, [options.search]);

  const queryResult = useLinkLocationsUsedQuery({
    fetchPolicy: "network-only",
    variables: {
      customerId,
      limit: PAGE_LIMIT,
      offset,
      search,
      statusIds,
      typeIds,
      sort: sort ? (sort as LinkLocationsSort) : undefined,
      pendingLimit: PAGE_LIMIT,
      pendingOffset,
      pendingSearch,
      pendingTypeIds,
      pendingSort: pendingSort ? (pendingSort as LinkLocationsSort) : undefined,
      rejectedLimit: PAGE_LIMIT,
      rejectedOffset,
      rejectedSearch,
      rejectedTypeIds,
      rejectedSort: rejectedSort ? (rejectedSort as LinkLocationsSort) : undefined,
    },
    skip: !customerId,
    pollInterval: 1000 * 60 * 2,
  });

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  function onStatusSelected(statusIds: string[]) {
    setStatusIds(statusIds);
  }

  function onTypesSelected(typeIds: string[]) {
    setTypeIds(typeIds);
  }

  function clearFilters() {
    setSearch("");
    setStatusIds([]);
    setTypeIds([]);
    setMinDa(undefined);
    setMinTraffic(undefined);
  }

  function onNextPage() {
    setOffset(offset + PAGE_LIMIT);
  }

  function onPreviousPage() {
    setOffset(Math.max(offset - PAGE_LIMIT, 0));
  }

  function onMinDaChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const minDa = value ? parseInt(value, 10) : undefined;
    setMinDa(minDa);
  }

  function onMinTrafficChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const minTraffic = value ? parseInt(value, 10) : undefined;
    setMinTraffic(minTraffic);
  }

  function onSortChange(value: string) {
    setSort(value);
  }

  function onPendingSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPendingSearch(event.target.value);
  }

  function onPendingTypesSelected(typeIds: string[]) {
    setPendingTypeIds(typeIds);
  }

  function onPendingNextPage() {
    setPendingOffset(offset + PAGE_LIMIT);
  }

  function onPendingPreviousPage() {
    setPendingOffset(Math.max(offset - PAGE_LIMIT, 0));
  }

  function onPendingSortChange(value: string) {
    setPendingSort(value);
  }

  function pendingClearFilters() {
    setPendingSearch("");
    setPendingTypeIds([]);
    setPendingOffset(0);
  }

  function onRejectedSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setRejectedSearch(event.target.value);
  }

  function onRejectedTypesSelected(typeIds: string[]) {
    setRejectedTypeIds(typeIds);
  }

  function onRejectedNextPage() {
    setRejectedOffset(offset + PAGE_LIMIT);
  }

  function onRejectedPreviousPage() {
    setRejectedOffset(Math.max(offset - PAGE_LIMIT, 0));
  }

  function onRejectedSortChange(value: string) {
    setRejectedSort(value);
  }

  function rejectedClearFilters() {
    setRejectedSearch("");
    setRejectedTypeIds([]);
    setRejectedOffset(0);
  }

  return {
    search,
    onSearchChange,
    statusIds,
    onStatusSelected,
    typeIds,
    onTypesSelected,
    clearFilters,
    offset,
    onNextPage,
    onPreviousPage,
    minDa,
    onMinDaChange,
    minTraffic,
    onMinTrafficChange,
    sort,
    onSortChange,
    loading: queryResult.loading,
    linkLocationConnection: queryResult.data?.customer?.linkLocationsUsed,
    pendingSearch,
    onPendingSearchChange,
    pendingTypeIds,
    onPendingTypesSelected,
    pendingSort,
    onPendingSortChange,
    pendingOffset,
    onPendingNextPage,
    onPendingPreviousPage,
    pendingClearFilters,
    pendingLinkLocationConnection: queryResult.data?.customer?.linkLocationsPending,
    rejectedSearch,
    onRejectedSearchChange,
    rejectedTypeIds,
    onRejectedTypesSelected,
    rejectedSort,
    onRejectedSortChange,
    rejectedOffset,
    onRejectedPreviousPage,
    onRejectedNextPage,
    rejectedClearFilters,
    rejectedLinkLocationConnection: queryResult.data?.customer?.linkLocationsRejected,
  };
}

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

import { TaskWorkspace_TaskFragment, useTaskWorkspace_UpdateTaskMutation } from "../../generated/graphql";
import { AuthContext } from "../../core/auth-manager";
import { getDisplayMessageForError } from "../../util/error-helper";
import { FormHelpers } from "../../util/form-helpers";

export function useTaskWorkspace(task: TaskWorkspace_TaskFragment) {
  const authContext = useContext(AuthContext);
  const [updateTaskMutation] = useTaskWorkspace_UpdateTaskMutation();
  const toast = useToast();
  const navigate = useNavigate();

  const isAssignedToViewer = (task.assignedUser?.id ?? 0) === (authContext.viewer?.user?.id ?? null);

  async function onComplete(isApproved?: boolean, reviewComment?: string) {
    try {
      await updateTaskMutation({
        variables: {
          input: {
            taskId: task.id,
            status: "completed",
            isApproved,
            reviewComment: FormHelpers.processNullableString(reviewComment ?? ""),
          },
        },
      });
      toast({ title: "Success", description: "Task completed.", status: "success" });
      navigate("/tasks");
    } catch (e: any) {
      toast({ title: "Unable to Complete", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  async function onAbandon() {
    try {
      await updateTaskMutation({
        variables: {
          input: {
            taskId: task.id,
            assignedUserId: null,
          },
        },
      });
      toast({
        title: "Task Unassigned",
        description: "The task has been unassigned and returned to the queue.",
        status: "info",
      });
      navigate(-1);
    } catch (e: any) {
      toast({ title: "Unable to Unassign Task", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  return {
    isAssignedToViewer,
    onComplete,
    onAbandon,
  };
}

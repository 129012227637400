import { useParams } from "react-router-dom";
import { groupBy } from "lodash";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";

import { usePaymentBatchReviewQuery, usePaymentBatchReviewApproveMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

export function useAdminPaymentBatchReview() {
  const { id } = useParams<"id">();
  const paymentBatchId = parseInt(id ?? "");
  const queryResult = usePaymentBatchReviewQuery({
    fetchPolicy: "network-only",
    variables: {
      paymentBatchId,
    },
  });
  const [approvePaymentBatchFundingMutation, { loading: approveLoading }] = usePaymentBatchReviewApproveMutation();
  const [isAccepted, setIsAccepted] = useState(false);
  const toast = useToast();

  function onAcceptedChange(e: React.ChangeEvent<HTMLInputElement>) {
    setIsAccepted(e.target.checked);
  }

  const paymentBatch = queryResult.data?.paymentBatch ?? null;
  const payments = queryResult.data?.paymentBatch?.creativesScaleUserPayments ?? [];

  const paymentsByCsUserId = groupBy(payments, "creativesScaleUserId");

  const paymentDetails = Object.keys(paymentsByCsUserId).map((key) => {
    const payments = paymentsByCsUserId[key];
    const user = payments[0].creativesScaleUser.user;

    return {
      id: payments[0].creativesScaleUser.id,
      user,
      paymentAmount: payments.reduce((sum, item) => sum + item.amount, 0),
      taskCount: payments.length,
    };
  });

  async function onApprove() {
    try {
      const response = await approvePaymentBatchFundingMutation({
        variables: {
          input: {
            paymentBatchId,
          },
        },
      });
      if (response.data?.approvePaymentBatchFunding.ok) {
        toast({
          title: "Funding Approved",
          description: "Funding Approve was successful. A bank transfer will be initiated momentarily.",
          status: "success",
        });
        queryResult.refetch();
      } else {
        toast({
          title: "Unable to Approve",
          description:
            response.data?.approvePaymentBatchFunding.error?.message ??
            "An unexpected error occurred. Please try again.",
          status: "error",
        });
      }
    } catch (e: any) {
      toast({ title: "Unable to Approve", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  return {
    paymentBatchId,
    loading: queryResult.loading,
    approveLoading,
    paymentBatch,
    payments,
    paymentDetails,
    onAcceptedChange,
    isAccepted,
    onApprove,
  };
}

import { useEffect } from "react";
import { sortBy } from "lodash";
import { SelectProps, Select } from "@chakra-ui/react";

import { usePartnerProductSelectorQuery } from "../../generated/graphql";

interface Props extends SelectProps {
  partnerId: number;
  selectedPartnerProductId?: number | null;
  onPartnerProductSelected: (partnerProductId: number | null) => void;
  allowNull?: boolean;
  nullValueText?: string;
  disableForSystemOrdered?: boolean;
  campaignCategoryId?: string;
}

export function PartnerProductSelector(props: Props) {
  const {
    partnerId,
    onPartnerProductSelected,
    selectedPartnerProductId,
    allowNull,
    nullValueText,
    disableForSystemOrdered,
    campaignCategoryId,
    ...rest
  } = props;
  const queryResult = usePartnerProductSelectorQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId,
      campaignCategoryId,
    },
  });

  const partnerProducts = sortBy(queryResult.data?.partnerProducts.nodes ?? [], "name");

  useEffect(() => {
    if (
      partnerProducts.length > 0 &&
      (selectedPartnerProductId === 0 || selectedPartnerProductId === null) &&
      !allowNull
    ) {
      onPartnerProductSelected(partnerProducts[0].id);
    }
  }, [partnerProducts, selectedPartnerProductId, allowNull, onPartnerProductSelected]);

  return (
    <Select
      isDisabled={queryResult.loading || partnerProducts.length === 0}
      value={selectedPartnerProductId?.toString() ?? ""}
      onChange={(e) => {
        if (e.target.value === "") {
          onPartnerProductSelected(null);
        } else {
          onPartnerProductSelected(parseInt(e.target.value));
        }
      }}
      {...rest}
    >
      {allowNull && <option value="">{nullValueText ?? "No Product"}</option>}
      {partnerProducts.map((partnerProduct) => (
        <option
          key={partnerProduct.id}
          value={partnerProduct.id.toString()}
          disabled={disableForSystemOrdered && partnerProduct.product.isSystemOrdered}
        >
          {partnerProduct.name}
        </option>
      ))}
    </Select>
  );
}

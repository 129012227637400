import { DateTime } from "luxon";
import { Text } from "@chakra-ui/react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
  TablePagination,
  TableFoot,
} from "../../components/table";
import { TasksQueryResult } from "../../generated/graphql";
import { DATE_MONTH_FORMAT, PAGE_LIMIT } from "../../constants/constants";
import { TaskRowAction } from "./task-row-action";
import { TaskStatusIndicator } from "../../common/task-status-indicator";
import { TruncatedText } from "../../components/truncated-text";

interface Props {
  tasksQueryResult: TasksQueryResult;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

export function TasksTable(props: Props) {
  const { tasksQueryResult, onPreviousPage, onNextPage } = props;

  const totalCount = tasksQueryResult.data?.tasks?.totalCount ?? 0;
  const tasks = tasksQueryResult.data?.tasks.nodes ?? [];

  return (
    <Table isEmpty={tasks.length === 0} isLoading={tasksQueryResult.loading}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>Type</TableHeaderCell>
          <TableHeaderCell>Client</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>First Available</TableHeaderCell>
          <TableHeaderCell>Vendor</TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {tasks.map((task) => (
          <TableRow key={task.id}>
            <TableCell>
              {task.taskType.name}
              <Text fontSize="sm">
                Order {task.order.id} ({task.order.partnerProduct.name})
              </Text>
              {!!task.topic && (
                <TruncatedText maxLength={30} fontSize="sm">
                  {task.topic}
                </TruncatedText>
              )}
            </TableCell>
            <TableCell>
              <Text>{task.customer.name}</Text>
              {!!task.customer.partner && (
                <TruncatedText maxLength={30} fontSize="sm">
                  {task.customer.partner.name}
                </TruncatedText>
              )}
            </TableCell>
            <TableCell>
              <TaskStatusIndicator status={task.status} assignedUser={task.assignedUser} />
            </TableCell>
            <TableCell>{DateTime.fromJSDate(task.availableAt).toFormat(DATE_MONTH_FORMAT)}</TableCell>
            <TableCell>{task.vendor?.name ?? "-"}</TableCell>
            <TableCell>
              <TaskRowAction task={task} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFoot>
        <TablePagination
          totalCount={totalCount}
          offset={tasksQueryResult.variables?.offset ?? 0}
          limit={tasksQueryResult.variables?.limit ?? PAGE_LIMIT}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
      </TableFoot>
    </Table>
  );
}

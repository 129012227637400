import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  SimpleGrid,
  FormErrorMessage,
  Textarea,
} from "@chakra-ui/react";
import * as yup from "yup";
import { useFormik } from "formik";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useCreateCustomerConversationMutation } from "../../generated/graphql";

interface Props {
  customerId: number;
  isOpen: boolean;
  onMessageSent: () => void;
  onClose: () => void;
}

interface FormValues {
  subject: string;
  message: string;
}

const createCustomerSchema = yup.object().shape({
  subject: yup.string().label("Subject").required().max(255),
  message: yup.string().label("Message").required(),
});

export function AddConversationDrawer(props: Props) {
  const { isOpen, onClose, customerId, onMessageSent } = props;
  const [createCustomerConversation, { loading }] = useCreateCustomerConversationMutation();
  const toast = useToast();

  const formik = useFormik<FormValues>({
    initialValues: {
      subject: "",
      message: "",
    },
    validationSchema: createCustomerSchema,
    onSubmit: async (values, helpers) => {
      try {
        const response = await createCustomerConversation({
          variables: {
            input: {
              customerId,
              subject: values.subject,
              content: values.message,
            },
          },
        });
        if (response?.data?.createCustomerConversation?.customerConversation?.id) {
          toast({ title: "Client conversation created successfully!", status: "success" });
          helpers.resetForm();
          helpers.setSubmitting(false);
          onMessageSent();
          onClose();
        }
      } catch (e: any) {
        toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
        helpers.setSubmitting(false);
      }
    },
  });

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="md" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <form onSubmit={formik.handleSubmit} noValidate>
          <DrawerCloseButton />
          <DrawerHeader>New Message</DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <FormControl isRequired isInvalid={!!formik.errors.subject && !!formik.touched.subject}>
                <FormLabel>Subject</FormLabel>
                <Input autoFocus variant="outline" type="input" id="subject" onChange={formik.handleChange} />
                {formik.touched.subject && formik.errors.subject && (
                  <FormErrorMessage>{formik.errors.subject}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={!!formik.errors.message && !!formik.touched.message}>
                <FormLabel>Message</FormLabel>
                <Textarea variant="outline" id="message" onChange={formik.handleChange} />
                {formik.touched.message && formik.errors.message && (
                  <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
                )}
              </FormControl>
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <SimpleGrid columns={2} gridGap={4} width="100%">
              <PrimaryButton
                width="100%"
                type="submit"
                isDisabled={formik.isSubmitting}
                isLoading={formik.isSubmitting || loading}
              >
                Save
              </PrimaryButton>
              <SecondaryButton width="100%" onClick={handleCancel}>
                Cancel
              </SecondaryButton>
            </SimpleGrid>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
}

import { usePartnerPackagesForSelectorQuery } from "../../generated/graphql";
import { PAGE_LIMIT } from "../../constants/constants";
import { currencyFormatter } from "../../util/formatter";
import { SelectProps, Select } from "@chakra-ui/react";

interface Props extends SelectProps {
  partnerId: number;
  selectedPartnerPackageId?: number | null;
  onPartnerPackageSelected: (partnerPackageId: number | null) => void;
}

export function PartnerPackageSelector(props: Props) {
  const { partnerId, selectedPartnerPackageId, onPartnerPackageSelected, ...rest } = props;

  const { data, loading } = usePartnerPackagesForSelectorQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      partnerId,
      limit: PAGE_LIMIT,
      offset: 0,
    },
  });

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === "") {
      onPartnerPackageSelected(null);
      return;
    }
    const selected = parseInt(event.target.value, 10);
    onPartnerPackageSelected(selected);
  }

  const packages = data?.partnerPackages.nodes ?? [];

  return (
    <Select {...rest} isDisabled={loading} value={selectedPartnerPackageId?.toString() ?? ""} onChange={handleChange}>
      <option value="">Custom</option>
      {packages.map((item) => (
        <option key={item.id} value={item.id.toString()}>
          {item.name} ({currencyFormatter.format(item.monthlySpend)}/mo, {item.months} months)
        </option>
      ))}
    </Select>
  );
}

import { useParams } from "react-router-dom";
import { usePartnerConfigurationQuery } from "../../generated/graphql";
import { useQueryParamString } from "../../util/use-query-param";

export function useConfiguration() {
  const { partnerId } = useParams<"partnerId">();
  const [search, setSearch] = useQueryParamString("search");
  const queryResult = usePartnerConfigurationQuery({
    fetchPolicy: "network-only",
    variables: {
      search,
    },
  });

  const partners = queryResult.data?.partners.nodes ?? [];

  function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  }

  return {
    loading: queryResult.loading,
    partners,
    partnerId,
    search,
    onSearchChange,
  };
}

import { useToast } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import {
  useKeywordResearchTaskWorkspaceQuery,
  useUpdateCustomerKeywordProposalMutation,
  useClearProposedCustomerKeywordsRejectionMutation,
} from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

interface Options {
  taskId: number;
  onComplete: (isApproved?: boolean, reviewComment?: string) => void;
}

export function useTaskWorkspaceKeywordResearch(options: Options) {
  const { taskId } = options;

  const toast = useToast();

  const [updateCustomerKeywordProposal, { loading }] = useUpdateCustomerKeywordProposalMutation();
  const [clearRejection, { loading: clearRejectionLoading }] = useClearProposedCustomerKeywordsRejectionMutation();
  const keywordResearchTaskQueryResult = useKeywordResearchTaskWorkspaceQuery({
    fetchPolicy: "network-only",
    variables: {
      taskId,
    },
  });

  const customerKeywordProposal = keywordResearchTaskQueryResult.data?.task?.customerKeywordProposal ?? null;
  const rejectedKeywords = customerKeywordProposal?.rejectedProposedKeywords ?? [];
  const keywords = customerKeywordProposal?.keywords ?? [];

  const [summary, setSummary] = useState(customerKeywordProposal?.summary ?? "");

  useEffect(() => {
    setSummary(customerKeywordProposal?.summary ?? "");
  }, [customerKeywordProposal?.summary]);

  async function saveSummary() {
    let success = true;
    if (customerKeywordProposal?.id) {
      try {
        const response = await updateCustomerKeywordProposal({
          variables: {
            input: {
              id: customerKeywordProposal.id,
              summary,
            },
          },
        });

        const savedSummary = response.data?.updateCustomerKeywordProposal.customerKeywordProposal?.summary ?? "";
        if (savedSummary) {
          setSummary(savedSummary);
        }
      } catch (e: any) {
        success = false;
        toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      }
    }

    return { success };
  }

  function handleChangeSummary(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setSummary(e.target.value);
  }

  async function clearRejectedKeywords() {
    let isRejectionCleared = true;
    if (rejectedKeywords?.length > 0 && customerKeywordProposal?.id) {
      try {
        const response = await clearRejection({
          variables: {
            input: {
              customerKeywordProposalId: customerKeywordProposal.id,
            },
          },
        });
        if (!response.data?.clearProposedCustomerKeywordsRejection?.ok) {
          isRejectionCleared = false;
        }
      } catch (e: any) {
        isRejectionCleared = false;
        toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      }
    }
    return { isRejectionCleared };
  }

  async function handleComplete() {
    const { success } = await saveSummary();
    const { isRejectionCleared } = await clearRejectedKeywords();

    if (success && isRejectionCleared) {
      options.onComplete();
    }
  }

  return {
    taskId,
    loading: keywordResearchTaskQueryResult.loading,
    customerKeywordProposal,
    rejectedKeywords,
    keywords,
    summary,
    saveSummary,
    handleChangeSummary,
    isSavingSummary: loading || clearRejectionLoading,
    task: keywordResearchTaskQueryResult.data?.task ?? null,
    refetchKeywords: keywordResearchTaskQueryResult.refetch,
    handleComplete,
  };
}

import { usePartnerUserSelectorQuery } from "../../generated/graphql";
import { sortBy } from "lodash";

export function usePartnerUserSelector(userId: number | null, partnerId: number) {
  const partnerUserSelectorQueryResult = usePartnerUserSelectorQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId: partnerId,
    },
  });

  const partnerUsers = partnerUserSelectorQueryResult.data?.partner?.partnerUsers.nodes ?? [];
  const selectedPartnerUser = userId ? partnerUsers.find((x) => x.user.id === userId) ?? null : null;

  return {
    loading: partnerUserSelectorQueryResult.loading,
    selectedPartnerUser,
    partnerUsers: sortBy(partnerUsers, ["user.lastName", "user.firstName"]),
  };
}

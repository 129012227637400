import { useRef } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  FormErrorMessage,
  Switch,
  SimpleGrid,
} from "@chakra-ui/react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useAddPartnerUserMutation } from "../../generated/graphql";

interface Props {
  partnerId: number;
  isOpen: boolean;
  onClose: () => void;
  onAdded: (userId: number) => void;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  isAdmin: boolean;
  createAnother: boolean;
}

const createPartnerUserSchema = yup.object().shape({
  firstName: yup.string().label("First Name").required().min(2).max(255),
  lastName: yup.string().label("Last Name").required().min(2).max(255),
  email: yup.string().label("Email").required().email().max(255),
  isAdmin: yup.boolean().label("Is Admin"),
});

export function AddPartnerUserDrawer(props: Props) {
  const { onClose, onAdded, isOpen, partnerId } = props;
  const [createPartnerUser] = useAddPartnerUserMutation();
  const toast = useToast();
  const nameFieldRef = useRef<HTMLInputElement>(null);

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await createPartnerUser({
        variables: {
          input: {
            partnerId,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            isAdmin: values.isAdmin,
          },
        },
      });

      if (response.data?.createPartnerUser.partnerUser?.id) {
        toast({ title: "Success", description: "Partner user created successfully!", status: "success" });
        formikHelpers.resetForm();
        onAdded(response.data.createPartnerUser.partnerUser.id);
      }

      if (values.createAnother) {
        formikHelpers.setSubmitting(false);
        nameFieldRef.current?.focus();
      } else {
        onClose();
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      isAdmin: false,
      createAnother: false,
    },
    validationSchema: createPartnerUserSchema,
    onSubmit: handleSubmit,
  });

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  function handleSave(andCreateAnother: boolean) {
    formik.setFieldValue("createAnother", andCreateAnother);
    formik.submitForm();
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="sm" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add User</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={!!formik.errors.firstName && !!formik.touched.firstName}>
              <FormLabel>First Name</FormLabel>
              <Input
                type="text"
                variant="outline"
                id="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                ref={nameFieldRef}
              />
              <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.lastName && !!formik.touched.lastName}>
              <FormLabel>Last Name</FormLabel>
              <Input
                type="text"
                variant="outline"
                id="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.email && !!formik.touched.email}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                variant="outline"
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formik.errors.isAdmin && !!formik.touched.isAdmin}>
              <FormLabel>Admin</FormLabel>
              <Switch id="isAdmin" isChecked={formik.values.isAdmin} onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.isAdmin}</FormErrorMessage>
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <SimpleGrid columns={2} gridGap={4} width="100%">
            <PrimaryButton width="100%" onClick={handleSave.bind(null, false)}>
              Save
            </PrimaryButton>
            <SecondaryButton width="100%" onClick={handleSave.bind(null, true)}>
              Save &amp; Add Another
            </SecondaryButton>
          </SimpleGrid>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

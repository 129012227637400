import { useState } from "react";
import { DateTime } from "luxon";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  Box,
  Text,
  useToast,
  SimpleGrid,
  FormControl,
  FormLabel,
  Heading,
} from "@chakra-ui/react";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { CancelButton, PrimaryButton, PrimaryIconButton } from "../../components/buttons";
import {
  PartnerProductDetailPriceFragment,
  usePartnerProductDetailPricesQuery,
  useDeletePartnerProductPriceMutation,
} from "../../generated/graphql";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { Loader } from "../../components/loader";
import NoDataAvailable from "../../components/no-data-available";
import { AppColors } from "../../core/custom-theme";
import { currencyFormatter } from "../../util/formatter";
import { CreatePartnerProductPrice } from "./create-partner-product-price";
import { UpdatePartnerProductPrice } from "./update-partner-product-price";
import { LockedInput } from "../../components/locked-input";

interface Props {
  partnerProductId: number;
  isOpen: boolean;
  onClose: () => void;
}

export function ManagePartnerProductPricesDrawer(props: Props) {
  const { onClose, isOpen, partnerProductId } = props;

  const [showNewPrice, setShowNewPrice] = useState(false);
  const [editPrice, setEditPrice] = useState<PartnerProductDetailPriceFragment>();
  const [submitNewPrice, setSubmitNewPrice] = useState(false);
  const [submitUpdatePrice, setSubmitUpdatePrice] = useState(false);
  const toast = useToast();

  const [deleteProductPrice, { loading: deleteLoading }] = useDeletePartnerProductPriceMutation();

  const { data, loading, refetch } = usePartnerProductDetailPricesQuery({
    variables: {
      partnerProductId,
    },
    fetchPolicy: "network-only",
    skip: !partnerProductId,
  });

  const partnerProductPrices = data?.partnerProduct?.partnerProductPrices.nodes ?? [];

  function closeNewPrice() {
    setShowNewPrice(false);
  }

  function closeEditPrice() {
    setEditPrice(undefined);
  }

  function openNewPrice() {
    setShowNewPrice(true);
    closeEditPrice();
  }

  function onEditPrice(price: PartnerProductDetailPriceFragment) {
    setEditPrice(price);
    closeNewPrice();
  }

  function onNewPriceSave() {
    setSubmitNewPrice(true);
  }

  function enableNewPrice() {
    setSubmitNewPrice(false);
  }

  function onUpdatePriceSave() {
    setSubmitUpdatePrice(true);
  }

  function enableUpdatePrice() {
    setSubmitUpdatePrice(false);
  }

  async function onDelete(partnerProductPriceId: number) {
    const response = await deleteProductPrice({
      variables: {
        input: {
          partnerProductPriceId,
        },
      },
    });

    if (response.data?.deletePartnerProductPrice.isDeleted) {
      toast({
        title: "Price removed.",
        description: "Price was removed successfully.",
        status: "success",
      });
      refetch();
    }
  }

  return (
    <>
      {loading && <Loader type="content" />}
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md" closeOnOverlayClick={false} isFullHeight>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Manage Prices</DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Product Type</FormLabel>
                <LockedInput variant="outline" value={data?.partnerProduct?.name ?? ""} />
              </FormControl>
              <FormControl>
                <FormLabel>Wholesale Price</FormLabel>
                <LockedInput
                  variant="outline"
                  value={currencyFormatter.format(data?.partnerProduct?.wholesalePrice ?? 0)}
                />
              </FormControl>
              <Heading size="sm">Retail Price</Heading>
              {partnerProductPrices.length === 0 ? (
                <NoDataAvailable />
              ) : (
                partnerProductPrices.map((productPrice) => {
                  const startsAtDate = productPrice.startsAt;
                  const startedBeforeNow = startsAtDate < new Date();
                  const startedAfterNow = startsAtDate > new Date();
                  const startsAtStr = DateTime.fromJSDate(startsAtDate).toFormat(DATE_MONTH_FORMAT);

                  return (
                    <Box
                      key={productPrice.id}
                      display="flex"
                      justifyContent="space-between"
                      color={startedBeforeNow && !productPrice.isCurrent ? AppColors.silver : AppColors.textNormal}
                    >
                      <Text fontWeight={productPrice.isCurrent ? "bold" : undefined}>
                        {currencyFormatter.format(productPrice.price)}
                      </Text>
                      {startedBeforeNow && !productPrice.isCurrent && <Text>{`Started At ${startsAtStr}`}</Text>}
                      {productPrice.isCurrent && <Text>Current</Text>}
                      {startedAfterNow && (
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Text>{startsAtStr}</Text>
                          <PrimaryIconButton
                            actionName="Edit"
                            icon={faPencilAlt}
                            onClick={onEditPrice.bind(null, productPrice)}
                          />
                          <PrimaryIconButton
                            actionName="Delete"
                            icon={faTrash}
                            onClick={onDelete.bind(null, productPrice.id)}
                            isDisabled={deleteLoading}
                          />
                        </Box>
                      )}
                    </Box>
                  );
                })
              )}
              {showNewPrice && (
                <CreatePartnerProductPrice
                  partnerProductId={partnerProductId}
                  onClose={closeNewPrice}
                  onCreated={refetch}
                  submitForm={submitNewPrice}
                  enableSubmit={enableNewPrice}
                />
              )}
              {!!editPrice && (
                <UpdatePartnerProductPrice
                  partnerProductPrice={editPrice}
                  onClose={closeEditPrice}
                  onUpdated={refetch}
                  submitForm={submitUpdatePrice}
                  enableSubmit={enableUpdatePrice}
                />
              )}
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <SimpleGrid columns={2} gridGap={2} width="100%">
              {!editPrice && !showNewPrice && (
                <PrimaryButton width="100%" onClick={openNewPrice}>
                  Add New Price
                </PrimaryButton>
              )}
              {showNewPrice && (
                <>
                  <PrimaryButton width="100%" onClick={onNewPriceSave} isDisabled={submitNewPrice}>
                    Save
                  </PrimaryButton>
                  <CancelButton onClick={closeNewPrice} isDisabled={submitNewPrice}>
                    Cancel
                  </CancelButton>
                </>
              )}
              {!!editPrice && (
                <>
                  <PrimaryButton width="100%" onClick={onUpdatePriceSave} isDisabled={submitUpdatePrice}>
                    Save
                  </PrimaryButton>
                  <CancelButton onClick={closeEditPrice} isDisabled={submitUpdatePrice}>
                    Cancel
                  </CancelButton>
                </>
              )}
            </SimpleGrid>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

import { useState } from "react";
import { Flex, Box, HStack } from "@chakra-ui/react";
import { last } from "lodash";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { DateTime } from "luxon";

import { LayoutBase } from "../../components/layout";
import { PageHeading } from "../../components/page-heading";
import { Container } from "../../components/layout/container";
import { PanelGroup, PanelBottom, PanelTop } from "../../components/panels";
import { PrimaryButton } from "../../components/buttons";
import { ClientDetailsClientCampaignFragment, useClientDetailsClientCampaignsQuery } from "../../generated/graphql";
import { CampaignsTable } from "./campaigns-table";
import { AddClientCampaignDrawer } from "../../common/add-client-campaign-drawer";
import { HelpText } from "../../components/help-text/help-text";
import { EditClientCampaignDrawer } from "../../common/edit-client-campaign-drawer";
import { CUSTOMER_STATUS } from "../../constants/customer-status";
import { CAMPAIGN_CATEGORIES } from "../../constants/campaign-category";
import { CampaignCategorySelectorV2 } from "../../common/campaign-category-selector-v2";

interface Props {
  clientId: number;
}

export function ClientDetailsCampaigns(props: Props) {
  const { clientId } = props;
  const [showAddClientCampaignDrawer, setShowAddClientCampaignDrawer] = useState(false);
  const [editClientCampaign, setEditClientCampaign] = useState<ClientDetailsClientCampaignFragment | null>(null);
  const [campaignCategoryId, setCampaignCategoryId] = useState(CAMPAIGN_CATEGORIES.SEO.id);
  const clientCampaignsQueryResult = useClientDetailsClientCampaignsQuery({
    fetchPolicy: "network-only",
    variables: {
      clientId,
      campaignCategoryId,
    },
  });

  function onAddCampaignClicked() {
    setShowAddClientCampaignDrawer(true);
  }

  function onCampaignAdded() {
    clientCampaignsQueryResult.refetch();
    setShowAddClientCampaignDrawer(false);
  }

  function onAddCampaignDrawerClose() {
    setShowAddClientCampaignDrawer(false);
  }

  function onEditClientCampaignClicked(campaign: ClientDetailsClientCampaignFragment) {
    setEditClientCampaign(campaign);
  }

  function onCampaignEdited() {
    clientCampaignsQueryResult.refetch();
    setEditClientCampaign(null);
  }

  function onEditCampaignDrawerClose() {
    setEditClientCampaign(null);
  }

  function onCampaignCategorySelected(campaignCategoryId: string) {
    setCampaignCategoryId(campaignCategoryId);
  }

  const campaigns = clientCampaignsQueryResult?.data?.customer?.customerCampaigns.nodes ?? [];

  let latestEndAt = last(campaigns.map((x) => x.endsAt).sort((a, b) => (b > a ? -1 : 1)));

  if (latestEndAt) {
    latestEndAt = DateTime.fromJSDate(latestEndAt).minus({ milliseconds: 1 }).toJSDate();
  }

  const isCustomerActive = clientCampaignsQueryResult?.data?.customer?.status.id === CUSTOMER_STATUS.ACTIVE.id;
  const canCreateNewCampaign =
    isCustomerActive &&
    (!latestEndAt || Math.round(DateTime.fromJSDate(latestEndAt).diff(DateTime.now(), "months").months) < 6);

  return (
    <LayoutBase pageTitle="Campaign">
      {canCreateNewCampaign && (
        <AddClientCampaignDrawer
          clientId={clientId}
          isOpen={showAddClientCampaignDrawer}
          onAdded={onCampaignAdded}
          onClose={onAddCampaignDrawerClose}
          minStartDate={latestEndAt}
          campaignCategoryId={campaignCategoryId}
        />
      )}
      {!!editClientCampaign && (
        <EditClientCampaignDrawer
          campaign={editClientCampaign}
          isOpen={!!editClientCampaign}
          onClose={onEditCampaignDrawerClose}
          onEdited={onCampaignEdited}
        />
      )}
      <Container centerHorizontally>
        <Flex alignItems="center">
          <PageHeading>Campaign</PageHeading>
          <HelpText standardLeftMargin>
            A campaign defines a long-term action to promote a service or product. (Ex: SEO, Website, Social Media.)
          </HelpText>
        </Flex>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between" alignItems="center">
              <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end">
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Campaign Category</FormLabel>
                  <CampaignCategorySelectorV2
                    onCampaignCategorySelected={onCampaignCategorySelected}
                    selectedCampaignCategoryId={campaignCategoryId}
                  />
                </FormControl>
              </HStack>
              <Box>
                <PrimaryButton isDisabled={!canCreateNewCampaign} onClick={onAddCampaignClicked}>
                  Add Campaign
                </PrimaryButton>
              </Box>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <CampaignsTable
              clientCampaignsQueryResult={clientCampaignsQueryResult}
              showClientCampaignEditDrawer={onEditClientCampaignClicked}
            />
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}

import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOrderDetailsOverviewQuery, useReCreateGuestOutreachMutation } from "../../../generated/graphql";
import { getDisplayMessageForError } from "../../../util/error-helper";

export function useOrderDetailsOverview(orderId: number) {
  const queryResult = useOrderDetailsOverviewQuery({
    fetchPolicy: "network-only",
    variables: {
      orderId,
    },
  });
  const [reCreateGuestOutreach] = useReCreateGuestOutreachMutation();
  const [showDelete, setShowDelete] = useState(false);
  const [showWarningGuestOutreach, setShowWarningGuestOutreach] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const order = queryResult.data?.order ?? null;

  function handleDelete() {
    setShowDelete(true);
  }

  function handleDeleteCancel() {
    setShowDelete(false);
  }

  function handleOnDeleted() {
    toast({
      title: "Order Deleted",
      description: "The order was deleted successfully.",
      status: "info",
    });
    navigate("/orders");
  }

  async function onReCreateGuestOutreach() {
    try {
      const response = await reCreateGuestOutreach({
        variables: {
          input: {
            orderId,
          },
        },
      });

      if (response.data?.reCreateGuestOutreachTask.ok) {
        toast({
          title: "Guest Outreach Task Created",
          description: "The guest outreach task was created successfully.",
          status: "success",
        });
      } else {
        throw new Error(response.data?.reCreateGuestOutreachTask.error?.message ?? "Something went wrong.");
      }
    } catch (e: any) {
      toast({
        title: "Error",
        description: getDisplayMessageForError(e),
        status: "error",
      });
    }
  }

  function onClickOutreach() {
    setShowWarningGuestOutreach(true);
  }

  function onCloseGuestOutreach() {
    setShowWarningGuestOutreach(false);
  }

  return {
    orderId,
    loading: queryResult.loading,
    error: queryResult.error,
    refetch: queryResult.refetch,
    order,
    showDelete,
    handleDelete,
    handleDeleteCancel,
    handleOnDeleted,
    onReCreateGuestOutreach,
    onClickOutreach,
    onCloseGuestOutreach,
    showWarningGuestOutreach,
  };
}

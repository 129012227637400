/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { sortBy } from "lodash";
import { SelectProps, Select } from "@chakra-ui/react";

import { usePublisherSelectorQuery } from "../../generated/graphql";

interface Props extends SelectProps {
  selectedPublisherId?: number | null;
  onPublisherSelected: (publisherId: number | null) => void;
  allowNull?: boolean;
  nullValueText?: string;
  refetch?: boolean;
  onRefechDone?: () => void;
}

export function PublisherSelector(props: Props) {
  const { onPublisherSelected, selectedPublisherId, allowNull, nullValueText, refetch, onRefechDone, ...rest } = props;
  const queryResult = usePublisherSelectorQuery({
    fetchPolicy: "network-only",
  });

  const publishers = sortBy(queryResult.data?.publishers.nodes ?? [], "name");

  useEffect(() => {
    if (publishers.length > 0 && (selectedPublisherId === 0 || selectedPublisherId === null) && !allowNull) {
      onPublisherSelected(publishers[0].id);
    }
  }, [publishers, selectedPublisherId, allowNull, onPublisherSelected]);

  useEffect(() => {
    if (refetch) {
      queryResult.refetch();
      onRefechDone?.();
    }
  }, [refetch]);

  return (
    <Select
      isDisabled={queryResult.loading}
      value={selectedPublisherId?.toString() ?? ""}
      onChange={(e) => {
        if (e.target.value === "") {
          onPublisherSelected(null);
        } else {
          onPublisherSelected(parseInt(e.target.value));
        }
      }}
      {...rest}
    >
      {allowNull && <option value="">{nullValueText ?? "No Publisher"}</option>}
      {publishers.map((publisher) => (
        <option key={publisher.id} value={publisher.id.toString()}>
          {publisher.name}
        </option>
      ))}
    </Select>
  );
}

import { useContext } from "react";
import { AuthContext } from "../../../core/auth-manager";
import AppIcon from "../../app-icon";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import AppLink from "../../app-link";
import { Box, Menu, MenuButton, Button, MenuList, MenuItem } from "@chakra-ui/react";
import Color from "color";

export function MainNavProfile() {
  const { viewer, logout } = useContext(AuthContext);
  async function signOut() {
    logout();
  }

  return (
    <Box paddingRight={2} textAlign="right" fontSize="14px">
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Menu>
          <MenuButton
            as={Button}
            backgroundColor="transparent"
            _hover={{ backgroundColor: Color("#333333").lighten(0.1).hex() }}
            _active={{ backgroundColor: "transparent" }}
          >
            <span style={{ fontWeight: 600 }}>{viewer?.user?.fullName ?? "Not Logged In"}</span>
            <AppIcon icon={faEllipsisV} color="white" wideLeftMargin />
          </MenuButton>
          <MenuList color="black">
            <MenuItem as={AppLink} {...{ to: `/profile` }}>
              Profile
            </MenuItem>
            <MenuItem as={AppLink} onClick={signOut} {...{ to: "/" }}>
              Sign out
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Box>
  );
}

export const LINK_LOCATION_STATUS = {
  PENDING: {
    id: "pending",
    name: "Pending",
  },
  PENDING_NEW: {
    id: "pending-new",
    name: "Pending (New)",
  },
  ACTIVE: {
    id: "active",
    name: "Active",
  },
  INACTIVE: {
    id: "inactive",
    name: "Inactive",
  },
};

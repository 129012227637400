import { useToast } from "@chakra-ui/react";
import { groupBy } from "lodash";
import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useBillingOneTimePaymentQuery,
  useBillingOneTimePaymentReviewQuery,
  useBillingOneTimePayment_CreatePartnerInvoiceMutation,
  useBillingOneTimePayment_PayPartnerInvoiceMutation,
} from "../../generated/graphql";

const VIEWS = {
  SELECT_ORDERS: 0,
  REVIEW: 1,
  CONFIRMATION: 2,
};

export function useBillingPay() {
  const { partnerId: partnerIdRaw } = useParams<"partnerId">();
  const partnerId = parseInt(partnerIdRaw ?? "", 10);
  const navigate = useNavigate();
  const [selectedRange, setSelectedRange] = useState("day");
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<number[]>([]);
  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([]);
  const [currentView, setCurrentView] = useState(VIEWS.SELECT_ORDERS);
  const [selectedPartnerPaymentMethodId, setSelectedPartnerPaymentMethodId] = useState<number | null>(null);
  const [createPartnerInvoiceMutation, { loading: isCreateInvoiceProcessing }] =
    useBillingOneTimePayment_CreatePartnerInvoiceMutation();
  const [payPartnerInvoiceMutation, { loading: isPayInvoiceProcessing }] =
    useBillingOneTimePayment_PayPartnerInvoiceMutation();
  const toast = useToast();
  const [isSummaryAccepted, setIsSummaryAccepted] = useState(false);

  let startDate = DateTime.utc().plus({ days: 1 }).endOf("day").toJSDate();
  if (selectedRange === "week") {
    startDate = DateTime.utc().plus({ weeks: 1 }).endOf("day").toJSDate();
  } else if (selectedRange === "month") {
    startDate = DateTime.utc().plus({ months: 1 }).endOf("day").toJSDate();
  }

  const queryResult = useBillingOneTimePaymentQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId,
      startDate: {
        lte: startDate,
      },
    },
  });

  const reviewQueryResult = useBillingOneTimePaymentReviewQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId,
      orderIds: selectedOrderIds,
    },
    skip: selectedOrderIds.length === 0 || currentView !== VIEWS.REVIEW,
  });

  const partner = queryResult.data?.partner ?? null;
  const allBillableOrders = partner?.billableOrders.nodes ?? [];
  const billableOrders =
    selectedCustomerIds.length > 0
      ? allBillableOrders.filter((x) => selectedCustomerIds.includes(x.customer.id))
      : allBillableOrders;
  const reviewOrders = reviewQueryResult.data?.orders.nodes ?? [];

  const reviewOrdersGroupedByCustomerId = groupBy(reviewOrders, "customer.id");
  const reviewItems = Object.keys(reviewOrdersGroupedByCustomerId).map((key) => ({
    customerId: parseInt(key, 10),
    customer: reviewOrdersGroupedByCustomerId[key].find((x) => !!x.customer)?.customer,
    orders: reviewOrdersGroupedByCustomerId[key],
    totalAmount: reviewOrdersGroupedByCustomerId[key].reduce((total, item) => total + item.totalWholesaleAmount, 0),
  }));
  const totalAmount = reviewOrders.reduce((total, order) => total + order.totalWholesaleAmount, 0);

  function handleRangeChange(value: string) {
    setSelectedRange(value);
  }

  function toggleOrder(orderId: number) {
    const order = billableOrders.find((x) => x.id === orderId);
    if (!order) {
      return;
    }
    if (selectedOrderIds.includes(orderId)) {
      setSelectedOrderIds(selectedOrderIds.filter((x) => x !== orderId));
    } else {
      setSelectedOrderIds([...selectedOrderIds, orderId]);
    }
    setIsSummaryAccepted(false);
  }

  function handleCheckAll() {
    if (selectedOrderIds.length === 0) {
      setSelectedOrderIds(billableOrders.map((x) => x.id));
    } else {
      setSelectedOrderIds([]);
    }
  }

  function handleReviewClick() {
    setCurrentView(VIEWS.REVIEW);
  }

  function handlePartnerChange(newPartnerId: number | null) {
    if (!!newPartnerId && newPartnerId !== partnerId) {
      navigate(`/billing/${newPartnerId}/pay`);
    }
  }

  function handlePartnerPaymentMethodChange(value: number | null) {
    setSelectedPartnerPaymentMethodId(value);
    setIsSummaryAccepted(false);
  }

  async function createPartnerInvoice(): Promise<number> {
    const response = await createPartnerInvoiceMutation({
      variables: {
        input: {
          orderIds: selectedOrderIds,
          partnerId,
        },
      },
    });
    if (response.data?.createPartnerInvoice.ok && response.data.createPartnerInvoice.partnerInvoice?.id) {
      return response.data.createPartnerInvoice.partnerInvoice.id;
    } else {
      throw new Error(
        response.data?.createPartnerInvoice.error?.message ?? "Unable to generate invoice. Please try again."
      );
    }
  }

  async function handleSubmitPayment() {
    if (!selectedPartnerPaymentMethodId) {
      return;
    }

    let partnerInvoiceId: number | null = null;

    try {
      partnerInvoiceId = await createPartnerInvoice();
      const response = await payPartnerInvoiceMutation({
        variables: {
          input: {
            partnerInvoiceId,
            partnerPaymentMethodId: selectedPartnerPaymentMethodId,
          },
        },
      });
      if (response.data?.payPartnerInvoice.ok && response.data.payPartnerInvoice.wasPaymentSuccessful) {
        toast({
          title: "Thank you!",
          description: "Your payment was processed successfully.",
          status: "success",
        });
      } else {
        throw new Error(
          response.data?.payPartnerInvoice.error?.message ??
            "We were unable to successfully process your payment. Please review the errors and try submitting again."
        );
      }
    } catch (e: any) {
      toast({
        title: "Something needs your attention",
        description:
          "We were unable to successfully process your payment. Please review the errors and try submitting again.",
        status: "error",
      });
    }
    if (partnerInvoiceId) {
      navigate(`/billing/${partnerId}/invoices/${partnerInvoiceId}`);
    }
  }

  function handleSummaryAcceptedChange(e: React.ChangeEvent<HTMLInputElement>) {
    setIsSummaryAccepted(e.target.checked);
  }

  function handleCustomerSelectorChange(customerIds: number[]) {
    setSelectedCustomerIds(customerIds);
  }

  function handleChangeSelectedOrders() {
    setCurrentView(VIEWS.SELECT_ORDERS);
  }

  return {
    partnerId,
    handlePartnerChange,
    currentView,
    partner,
    billableOrders,
    selectedRange,
    handleRangeChange,
    selectedOrderIds,
    toggleOrder,
    handleCheckAll,
    handleReviewClick,
    VIEWS,
    selectedPartnerPaymentMethodId,
    handlePartnerPaymentMethodChange,
    reviewItems,
    totalAmount,
    handleSubmitPayment,
    isPaymentProcesing: isCreateInvoiceProcessing || isPayInvoiceProcessing,
    isSummaryAccepted,
    handleSummaryAcceptedChange,
    selectedCustomerIds,
    handleCustomerSelectorChange,
    handleChangeSelectedOrders,
  };
}

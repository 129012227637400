import { Badge } from "@chakra-ui/react";

interface Props {
  customerQuestionnaire?: {
    id: number;
    completedAt?: Date | null;
  } | null;
}

export function CustomerQuestionnaireStatusIndicator(props: Props) {
  const { customerQuestionnaire } = props;

  let color: string | undefined = undefined;
  let status = "";
  if (customerQuestionnaire?.completedAt) {
    color = "green";
    status = "Completed";
  } else if (customerQuestionnaire?.id) {
    color = "yellow";
    status = "In Progress";
  } else {
    color = "red";
    status = "Not Started";
  }
  return <Badge colorScheme={color}>{status}</Badge>;
}

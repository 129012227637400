import { AppColors } from "../../core/custom-theme";
import Color from "color";
import AppLink from "../app-link";
import { ButtonProps } from "@chakra-ui/react";
import { PrimaryButton } from "./primary-button";

interface Props extends ButtonProps {
  to: string;
  isExternal?: boolean;
}

export function PrimaryButtonAppLink(props: Props) {
  const { to, isExternal, ...rest } = props;
  return (
    <PrimaryButton
      as={AppLink}
      bg={AppColors.blue}
      borderRadius={4}
      color="white"
      _hover={{
        bg: Color(AppColors.blue).darken(0.2).hex(),
      }}
      _active={{
        bg: Color(AppColors.blue).darken(0.4).hex(),
      }}
      fontSize="14px"
      paddingX="30px"
      paddingY="10px"
      {...{ to, isExternal }}
      {...rest}
    />
  );
}

import { useParams } from "react-router-dom";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { DomainDetailsOverview } from "./admin-domain-details-overview";
import { PageHeading } from "../../components/page-heading";
import AppLink from "../../components/app-link";
import AppIcon from "../../components/app-icon";
import { useDomainDetailsQuery } from "../../generated/graphql";
import { DomainDetailsCategories } from "./admin-domain-details-categories";

export function AdminDomainDetailsRoute() {
  const { id } = useParams<"id">();

  const domainDetailsQueryResult = useDomainDetailsQuery({
    variables: {
      id: id ?? "",
    },
    fetchPolicy: "network-only",
    skip: !id,
  });

  return (
    <LayoutBase pageTitle="Domain Details">
      <MainNav hasSecondaryNav />
      <Container centerHorizontally>
        <Box marginY={4}>
          <AppLink to="/admin/domains">
            <AppIcon icon={faAngleLeft} standardRightMargin />
            Domains
          </AppLink>
        </Box>
        <PageHeading>Domain Details</PageHeading>
        <SimpleGrid columns={{ base: 1, xl: 2 }} gridGap={4}>
          <DomainDetailsOverview domainDetailsQueryResult={domainDetailsQueryResult} />
          <DomainDetailsCategories domainDetailsQueryResult={domainDetailsQueryResult} />
        </SimpleGrid>
      </Container>
    </LayoutBase>
  );
}

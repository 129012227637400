import { lazy } from "react";
import { Heading, Stack, FormControl, FormLabel, Input, Grid, Box, Flex, Checkbox, Textarea } from "@chakra-ui/react";

import { PanelGroup, PanelTop, PanelBottom, PanelBox } from "../../components/panels";
import { DangerButton, PrimaryButton, SecondaryButton } from "../../components/buttons";
import { PageHeading } from "../../components/page-heading";
import { WorkflowNotes } from "../workflow-notes";
import { HelpText } from "../../components/help-text";
import { useTaskWorkspacePublishTitleMetaDescription } from "./use-publish-title-meta-description";
const TitleMetaDescriptionRow = lazy(() => import("../title-meta-description"));

interface Props {
  taskId: number;
  onAbandon: () => void;
  onComplete: () => void;
}

export function TaskWorkspacePublishTitleMetaDescription(props: Props) {
  const { taskId, onAbandon } = props;
  const {
    task,
    customer,
    handleComplete,
    isTitleMetaDescriptionPublished,
    onChangePublishedTitleMetaDescription,
    titleMetaDescriptions,
    canSubmit,
    titleMetaDescriptionItemId,
    onExport,
  } = useTaskWorkspacePublishTitleMetaDescription(props);
  return (
    <>
      <PageHeading>Publish Title And Meta Description</PageHeading>
      <Grid templateColumns={{ base: "1fr", xl: "1fr 1.5fr" }} gridGap={4}>
        <Stack spacing={4} gridColumn={1}>
          <PanelGroup>
            <PanelTop showBottomBorder>
              <Heading size="md">Client Details</Heading>
            </PanelTop>
            <PanelBottom>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Client Name</FormLabel>
                  <Input variant="outline" value={customer?.name ?? ""} isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Website URL</FormLabel>
                  <Input variant="outline" isReadOnly value={customer?.website ?? ""} />
                </FormControl>
                <FormControl>
                  <FormLabel>Order Instructions</FormLabel>
                  <Textarea
                    value={task?.order?.instructions ?? ""}
                    isReadOnly
                    resize="none"
                    rows={7}
                    overflowY="scroll"
                    scrollBehavior="smooth"
                    overscrollBehaviorY="contain"
                  />
                </FormControl>
              </Stack>
            </PanelBottom>
          </PanelGroup>
          <WorkflowNotes taskId={taskId} />
        </Stack>
        <Stack spacing={4} gridColumn={{ base: 1, xl: 2 }}>
          <PanelBox>
            {titleMetaDescriptions.map((titleMetaDescription: any) => (
              <TitleMetaDescriptionRow
                key={titleMetaDescription.id}
                titleTag={titleMetaDescription.titleTag}
                metaDescription={titleMetaDescription.metaDescription}
                url={titleMetaDescription.url}
                isReadOnly={true}
              />
            ))}
          </PanelBox>
          <PanelBox>
            <Stack spacing={4}>
              <FormControl>
                <Checkbox checked={isTitleMetaDescriptionPublished} onChange={onChangePublishedTitleMetaDescription}>
                  Is Title And Meta Descriptions Published
                </Checkbox>
              </FormControl>
              <SecondaryButton
                onClick={onExport}
                isDisabled={!titleMetaDescriptionItemId || titleMetaDescriptions.length === 0}
              >
                Export as CSV
              </SecondaryButton>
            </Stack>
          </PanelBox>
          <Flex justifyContent="space-between">
            <Box>
              <DangerButton onClick={onAbandon}>Unassign Task</DangerButton>
              <HelpText standardLeftMargin>
                &quot;Unassign Task&quot; will unassign this task from you and return it to the queue.{" "}
                <strong>This cannot be undone and may result in lost work.</strong>
              </HelpText>
            </Box>
            <PrimaryButton isDisabled={!canSubmit} onClick={handleComplete}>
              Complete
            </PrimaryButton>
          </Flex>
        </Stack>
      </Grid>
    </>
  );
}

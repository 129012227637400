import { Stack, Tooltip } from "@chakra-ui/react";
import { DateTime } from "luxon";

import { OrderBillingStatusIndicator } from "../../common/order-billing-status-indicator";
import { OrderIssuesIndicator } from "../../common/order-issues-indicator/order-issues-indicator";
import { OrderStatusIndicator } from "../../common/order-status-indicator/order-status-indicator";
import { useAccessiblePartnerCount } from "../../common/use-accessible-partner-count";
import AppLink from "../../components/app-link";
import {
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TablePagination,
  TableRow,
} from "../../components/table";
import { TruncatedText } from "../../components/truncated-text";
import { DATE_MONTH_FORMAT, DATE_TIME_FORMAT, PAGE_LIMIT } from "../../constants/constants";
import { OrdersQueryResult } from "../../generated/graphql";

interface Props {
  ordersQueryResult: OrdersQueryResult;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

export function OrdersTable(props: Props) {
  const { ordersQueryResult, onPreviousPage, onNextPage } = props;
  const accessiblePartnerCount = useAccessiblePartnerCount();

  const totalCount = ordersQueryResult.data?.orders?.totalCount ?? 0;
  const orders = ordersQueryResult.data?.orders.nodes ?? [];

  return (
    <Table isEmpty={orders.length === 0} isLoading={ordersQueryResult.loading}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>ID</TableHeaderCell>
          <TableHeaderCell>Product Type</TableHeaderCell>
          <TableHeaderCell>Topic</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Client</TableHeaderCell>
          <TableHeaderCell>Start Date</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orders.map((order) => (
          <TableRow key={order.id}>
            <TableCell>
              <AppLink to={`/orders/${order.id}`}>{order.id}</AppLink>
            </TableCell>
            <TableCell>
              {order.partnerProduct.id ? (
                <AppLink to={`/configuration/${order.customer.partner.id}/product-types`}>
                  {order.partnerProduct.name}
                </AppLink>
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>
              <TruncatedText maxLength={30}>{order.topic ?? "-"}</TruncatedText>
            </TableCell>
            <TableCell>
              <Stack isInline spacing={2} alignItems="center">
                <OrderStatusIndicator status={order.status} startDate={order.startDate} />
                <OrderBillingStatusIndicator
                  startDate={order.startDate}
                  billingStatus={order.billingStatus}
                  isEnforced={order.customer.partner.isBillingEnforced}
                />
                <OrderIssuesIndicator orderIssues={order.orderIssues} />
              </Stack>
            </TableCell>
            <TableCell>
              {order.customer.id ? <AppLink to={`/clients/${order.customer.id}`}>{order.customer.name}</AppLink> : "-"}
              {accessiblePartnerCount > 1 && (
                <TruncatedText maxLength={30} fontSize="sm">
                  {order.customer.partner.name}
                </TruncatedText>
              )}
            </TableCell>
            <TableCell>
              <Tooltip
                label={DateTime.fromJSDate(order.startDate).toFormat(DATE_TIME_FORMAT)}
                aria-label={DateTime.fromJSDate(order.startDate).toFormat(DATE_TIME_FORMAT)}
              >
                <span>{DateTime.fromJSDate(order.startDate).toFormat(DATE_MONTH_FORMAT)}</span>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFoot>
        <TablePagination
          totalCount={totalCount}
          offset={ordersQueryResult.variables?.offset ?? 0}
          limit={ordersQueryResult.variables?.limit ?? PAGE_LIMIT}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
      </TableFoot>
    </Table>
  );
}

export const getQueryParams = (searchString: string, queryString: any) => {
  let queryParams;
  if (searchString) {
    const queryParamsString = searchString.split("?");
    if (queryParamsString && queryParamsString.length > 1) {
      queryParams = queryString.parse(queryParamsString[1]);
    }
  }
  return queryParams;
};

export const setParams = (queryParams: any, key?: string, value?: string) => {
  const searchParams = new URLSearchParams(queryParams);
  if (key && value !== undefined) {
    searchParams.set(key, value);
  }
  return searchParams.toString();
};

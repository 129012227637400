import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
} from "@chakra-ui/react";

import { PanelBottom, PanelBox, PanelGroup, PanelTop } from "../../../components/panels";
import { RichContent } from "../../../components/rich-content";
import { useOrderDetailsContentItem } from "./content-item-hook";
import { Loader } from "../../../components/loader";

interface Props {
  orderId: number;
}

export function OrderContentItems({ orderId }: Props) {
  const { orderContentItems, loading } = useOrderDetailsContentItem(orderId);

  if (loading) {
    return (
      <PanelBox>
        <Loader type="panel" />
      </PanelBox>
    );
  }

  if (orderContentItems?.length === 0) {
    return <PanelBox>No content found for order.</PanelBox>;
  }

  return (
    <PanelGroup>
      <PanelTop showBottomBorder>
        <Heading size="md" textAlign="left">
          Content
        </Heading>
      </PanelTop>
      <PanelBottom containsTable>
        {!loading && orderContentItems?.length !== 0 && (
          <Accordion allowMultiple>
            {orderContentItems.map((orderContentItem) => (
              <AccordionItem key={orderContentItem.id}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    {orderContentItem.contentItem.title}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <Box padding={4}>
                    <RichContent content={orderContentItem.contentItem.content} />
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </PanelBottom>
    </PanelGroup>
  );
}

import { Stack, Text } from "@chakra-ui/react";

import AppIcon from "../../components/app-icon";
import { DangerLinkButton } from "../../components/buttons";
import { PanelBox } from "../../components/panels";
import { PAYMENT_METHOD_CARD_TYPES } from "../../constants/payment-method-card-types";
import { AppColors } from "../../core/custom-theme";
import { Billing_PartnerPaymentMethodFragment } from "../../generated/graphql";

interface Props {
  partnerPaymentMethod: Billing_PartnerPaymentMethodFragment;
  onRemove: () => void;
}

export function PaymentMethodItem(props: Props) {
  const { partnerPaymentMethod, onRemove } = props;
  const { partnerPaymentMethodCard } = partnerPaymentMethod;

  if (partnerPaymentMethod.type === "card" && partnerPaymentMethodCard) {
    const helper = PAYMENT_METHOD_CARD_TYPES.find((x) => x.id === partnerPaymentMethodCard.brand);
    if (!helper) {
      return null;
    }
    return (
      <PanelBox height="120px" display="flex" justifyContent="space-between" alignItems="center" padding={8}>
        <Stack spacing={8} isInline>
          <AppIcon icon={helper.icon} size="3x" color={AppColors.textDarkGray} />
          <Stack spacing={1}>
            <Text>
              {partnerPaymentMethodCard.brandDisplayName} ending <strong>{partnerPaymentMethodCard.last4Digits}</strong>
            </Text>
            <Text>
              Expires {partnerPaymentMethodCard.expirationMonth}/{partnerPaymentMethodCard.expirationYear}
            </Text>
          </Stack>
        </Stack>
        <DangerLinkButton onClick={onRemove}>Remove</DangerLinkButton>
      </PanelBox>
    );
  }
  return null;
}

import { useContext } from "react";
import LogoImage from "../../../assets/red-canyon-media-logo-horizontal-white@2x.png";
import { MainNavLink } from "./main-nav-link";
import { Container } from "../container";
import AppLink from "../../app-link";
import { AuthContext } from "../../../core/auth-manager";
import { useLocation } from "react-router-dom";
import { MainNavDropdownMenu } from "./main-nav-dropdown-menu";
import { getMainNavDefinition } from "../main-nav-definition";
import { MainNavProfile } from "./main-nav-profile";
import { Box, MenuItem, Stack } from "@chakra-ui/react";

export const MAIN_NAV_HEIGHT = "64px";

interface Props {
  hasSecondaryNav?: boolean;
}

export function MainNavFull(props: Props) {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const { hasSecondaryNav } = props;

  const navDefinition = getMainNavDefinition({ authContext });

  return (
    <Box
      height="64px"
      backgroundColor="#333333"
      display="grid"
      gridTemplateColumns="minmax(0px, 1fr) minmax(300px, 1120px) minmax(0px, 1fr)"
      justifyContent="stretch"
      alignItems="center"
      position="sticky"
      top="0"
      zIndex={999}
      marginBottom={hasSecondaryNav === true ? 0 : 2}
      boxShadow={hasSecondaryNav === true ? undefined : "2px 0px 12px rgba(0, 0, 0, 0.6)"}
    >
      <Box gridColumn="1">
        <Box paddingLeft={2}>
          <AppLink to="/">
            <img src={LogoImage} alt="Red Canyon Media" style={{ maxHeight: 50 }} />
          </AppLink>
        </Box>
      </Box>
      <Container gridColumn="2">
        <Stack isInline spacing={8} display="flex" alignItems="center">
          {navDefinition.map((navItem) => {
            if (!navItem.visible) {
              return null;
            }
            if (navItem.submenu) {
              return (
                <MainNavDropdownMenu
                  key={navItem.name}
                  title={navItem.name}
                  isActive={location.pathname.startsWith(navItem.to)}
                >
                  {navItem.submenu.map((navSubItem) => {
                    if (!navSubItem.visible) {
                      return null;
                    }
                    return (
                      <MenuItem
                        key={navSubItem.name}
                        as={AppLink}
                        {...{ to: navSubItem.to, isExternal: navSubItem.isExternal }}
                      >
                        {navSubItem.name}
                      </MenuItem>
                    );
                  })}
                </MainNavDropdownMenu>
              );
            } else {
              return (
                <MainNavLink key={navItem.name} to={navItem.to} isActive={location.pathname.startsWith(navItem.to)}>
                  {navItem.name}
                </MainNavLink>
              );
            }
          })}
        </Stack>
      </Container>
      <Box color="white" gridColumn="3">
        <MainNavProfile />
      </Box>
    </Box>
  );
}

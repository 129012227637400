import React, { useState } from "react";
import { useToast } from "@chakra-ui/react";
import * as yup from "yup";

import { TASK_STATUS } from "../../../constants/task-status";
import {
  useProposalReviewTaskWorkspaceQuery,
  useSubmitProposalReviewMutation,
  useUpdateOrderProposalReviewMutation,
} from "../../../generated/graphql";
import { handleFormGraphQLError } from "../../../util/error-helper";
import { TASK_REVIEW_STATUS } from "../../../constants/task-review-status";

interface Props {
  taskId: number;
}

const schema = yup.object().shape({
  resultUrl: yup.string().url().required(),
});

export function useProposalReview(props: Props) {
  const { taskId } = props;
  const toast = useToast();

  const [showApprove, setShowApprove] = useState(false);
  const [validateResultUrl, setValidateResultUrl] = useState(true);
  const [resultUrl, setResultUrl] = useState("");
  const [showRevise, setShowRevise] = useState(false);
  const [rejectionComments, setRejectionComments] = useState<string | null>(null);

  const [submitProposalReview, { loading: isSubmitting }] = useSubmitProposalReviewMutation();
  const [updateOrder, { loading: isUpdating }] = useUpdateOrderProposalReviewMutation();
  const { data, loading, error } = useProposalReviewTaskWorkspaceQuery({
    variables: {
      taskId,
    },
    fetchPolicy: "network-only",
  });

  const task = data?.task ?? null;

  async function onSubmit(reviewStatusId: string) {
    if (showApprove) {
      const validatedResultUrl = schema.isValidSync({ resultUrl });
      if (!validatedResultUrl) {
        toast({
          title: "Url is not valid",
          description: "Please enter correct url",
          status: "warning",
        });
      }

      try {
        const response = await updateOrder({
          variables: {
            input: {
              resultUrl,
              validateResultUrl,
              orderId: task?.order.id ?? 0,
            },
          },
        });

        if (!response.data?.updateOrder.order.id) {
          throw new Error("Unable to update order");
        }
      } catch (e: any) {
        handleFormGraphQLError(e, "Unable to update order", toast);
        return;
      }
    }

    let reviewComment: string | null = null;

    if (reviewStatusId === TASK_REVIEW_STATUS.REVISED.id) {
      reviewComment = rejectionComments;
    } else if (reviewStatusId === TASK_REVIEW_STATUS.REJECTED.id) {
      reviewComment = "Publisher has rejected proposal";
    }

    try {
      const response = await submitProposalReview({
        variables: {
          input: {
            taskId,
            reviewStatusId,
            status: TASK_STATUS.COMPLETED.id,
            reviewComment,
          },
        },
      });
      if (!response.data?.submitProposalReview.ok) {
        throw new Error(
          response.data?.submitProposalReview.error?.message ?? "Unable to submit proposal review. Please try again."
        );
      } else {
        toast({
          title: "Completed",
          description: "Proposal Review was submitted successfully.",
          status: "success",
        });
      }
    } catch (e: any) {
      handleFormGraphQLError(e, "Unable to submit proposal review", toast);
      return;
    }
  }

  function onRevise() {
    setShowRevise(true);
  }

  function onApprove() {
    setShowApprove(true);
  }

  function onCancel() {
    setShowRevise(false);
    setShowApprove(false);
    setRejectionComments("");
    setResultUrl("");
  }

  function onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setRejectionComments(event.target.value);
  }

  function onChangeUrl(event: React.ChangeEvent<HTMLInputElement>) {
    setResultUrl(event.target.value);
  }

  function onValidateUrlChange() {
    setValidateResultUrl(!validateResultUrl);
  }

  return {
    loading,
    error,
    task,
    isSubmitting,
    isUpdating,
    onSubmit,
    showRevise,
    showApprove,
    rejectionComments: rejectionComments ?? "",
    onApprove,
    onRevise,
    onCancel,
    onChange,
    onChangeUrl,
    resultUrl,
    validateResultUrl,
    onValidateUrlChange,
  };
}

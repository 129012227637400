import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { PrimaryButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useAddVendorUserMutation } from "../../generated/graphql";

interface Props {
  vendorId: number;
  isOpen: boolean;
  onClose: () => void;
  onAdded: (vendorUserId: number) => void;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}

const createVendorUserSchema = yup.object().shape({
  firstName: yup.string().label("First Name").required().min(2).max(255),
  lastName: yup.string().label("Last Name").required().min(2).max(255),
  email: yup.string().label("Email").required().email().max(255),
});

export function AddVendorUserDrawer(props: Props) {
  const { onClose, onAdded, isOpen, vendorId } = props;
  const [createVendorUser] = useAddVendorUserMutation();
  const toast = useToast();

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await createVendorUser({
        variables: {
          input: {
            vendorId,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
          },
        },
      });

      if (response.data?.createVendorUser.vendorUser?.id) {
        toast({ title: "Success", description: "Vendor user created successfully!", status: "success" });
        formikHelpers.resetForm();
        onAdded(response.data.createVendorUser.vendorUser.id);
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: createVendorUserSchema,
    onSubmit: handleSubmit,
  });

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="sm" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add User</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={!!formik.errors.firstName && !!formik.touched.firstName}>
              <FormLabel>First Name</FormLabel>
              <Input
                type="text"
                variant="outline"
                id="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.lastName && !!formik.touched.lastName}>
              <FormLabel>Last Name</FormLabel>
              <Input
                type="text"
                variant="outline"
                id="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.email && !!formik.touched.email}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                variant="outline"
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <PrimaryButton width="100%" onClick={formik.submitForm}>
            Save
          </PrimaryButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

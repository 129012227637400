import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import {
  useAdminPublisherOverviewQuery,
  useAdminPublisherOverview_UpdatePublisherMutation,
} from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

interface FormValues {
  name: string;
  contactEmail: string;
  paymentMethod: string;
  paymentMethodId: string;
  isSlowPartner: boolean;
  isAutoPayEnabled: boolean;
  isInvoiceRequired: boolean;
  instructions: string;
  createdByUser?: string | null;
}

export function useAdminPublisherOverview() {
  const { id } = useParams<"id">();
  const publisherId = parseInt(id ?? "");

  const queryResult = useAdminPublisherOverviewQuery({
    fetchPolicy: "network-only",
    variables: {
      id: publisherId,
    },
  });
  const [updatePublisherMutation] = useAdminPublisherOverview_UpdatePublisherMutation();
  const toast = useToast();
  const publisher = queryResult.data?.publisher ?? null;

  const formik = useFormik<FormValues>({
    initialValues: {
      name: publisher?.name ?? "",
      contactEmail: publisher?.contactEmail ?? "",
      paymentMethod: publisher?.paymentMethod ?? "",
      paymentMethodId: publisher?.paymentMethodId ?? "",
      isSlowPartner: publisher?.isSlowPartner ?? false,
      isAutoPayEnabled: publisher?.isAutoPayEnabled ?? false,
      isInvoiceRequired: publisher?.isInvoiceRequired ?? false,
      instructions: publisher?.instructions ?? "",
      createdByUser: publisher?.createdByUser?.fullName ?? null,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const response = await updatePublisherMutation({
          variables: {
            input: {
              publisherId: publisherId,
              name: values.name,
              contactEmail: values.contactEmail,
              paymentMethod: values.paymentMethod,
              paymentMethodId: values.paymentMethodId,
              isSlowPartner: values.isSlowPartner,
              isAutoPayEnabled: values.isAutoPayEnabled,
              isInvoiceRequired: values.isInvoiceRequired,
              instructions: values.instructions,
            },
          },
        });

        if (!response.data?.updatePublisher.ok) {
          throw new Error(
            response.data?.updatePublisher.error?.message ?? "Something went wrong while updating publisher"
          );
        }

        queryResult.refetch();
      } catch (e: any) {
        toast({ title: "Unable to Save", description: getDisplayMessageForError(e), status: "error" });
      }
    },
    validationSchema: yup.object().shape({
      name: yup.string().label("Publisher Name").required().min(1),
      contactEmail: yup.string().label("Contact Email").notRequired().email().max(255),
      paymentMethod: yup.string().label("Payment Method").notRequired().nullable(true),
      paymentMethodId: yup.string().label("Payment Method Id").notRequired().nullable(true),
      isSlowPartner: yup.boolean().label("Slow Partner").notRequired().nullable(false),
      isAutoPayEnabled: yup.boolean().label("Is AutoPay Enabled").notRequired().nullable(false),
      isInvoiceRequired: yup.boolean().label("Is Invoice Required").notRequired().nullable(false),
      instructions: yup.string().label("Instructions").notRequired().nullable(true),
    }),
  });

  return {
    publisherId,
    loading: queryResult.loading,
    publisher,
    formik,
  };
}

import { DateTime } from "luxon";
import { useFormik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import { useEditOrderDrawerQuery, useEditOrderDrawer_UpdateOrderMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { Loader } from "../../components/loader";
import {
  useToast,
  Drawer,
  Box,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormHelperText,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  SimpleGrid,
  FormErrorMessage,
} from "@chakra-ui/react";
import { PrimaryButton, CancelButton } from "../../components/buttons";
import { ORDER_STATUS } from "../../constants/order-status";
import { CustomerCampaignSelector } from "../customer-campaign-selector";
import { DATE_FORMAT } from "../../constants/constants";
import { FormHelpers } from "../../util/form-helpers";
import { useAuthContext } from "../../core/auth-manager/auth-manager-hook";

interface Props {
  open: boolean;
  orderId: number;
  onCancel: () => void;
  onEdited: () => void;
}

interface FormValues {
  quantity: number;
  startDate: Date;
  instructions: string;
  resultUrl: string;
  resultUrlDomainAuthority: number | null;
}

export function EditOrderDrawer(props: Props) {
  const { open, orderId, onCancel, onEdited } = props;
  const toast = useToast();
  const authContext = useAuthContext();

  const orderDetailsResult = useEditOrderDrawerQuery({
    variables: {
      orderId,
    },
    fetchPolicy: "network-only",
    skip: orderId <= 0,
  });

  const [updateOrderMutation] = useEditOrderDrawer_UpdateOrderMutation();

  const order = orderDetailsResult.data?.order;

  const isSuperAdmin = authContext.viewer?.user?.isSuperAdmin;
  const isNotScheduled = order?.status.id !== ORDER_STATUS.SCHEDULED.id;
  const dateRangeStart = isSuperAdmin ? undefined : DateTime.utc().plus({ hours: 1 }).startOf("hour").toJSDate();
  const dateRangeEnd = DateTime.utc().plus({ years: 1 }).toJSDate();

  const formik = useFormik<FormValues>({
    initialValues: {
      quantity: order?.quantity ?? 1,
      resultUrl: order?.resultUrl ?? "",
      resultUrlDomainAuthority: order?.resultUrlDomainAuthority ?? null,
      instructions: order?.instructions ?? "",
      startDate: order?.startDate
        ? order.startDate
        : dateRangeStart ?? DateTime.utc().plus({ hours: 1 }).startOf("hour").toJSDate(),
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      resultUrl: yup
        .string()
        .label("Result URL")
        .when("resultUrlDomainAuthority", {
          is: (resultUrlDomainAuthority: any) => Boolean(resultUrlDomainAuthority),
          then: yup.string().required("Result URL is required while adding Result URL Domain Authority.").url(),
          otherwise: yup.string().notRequired().nullable(true).url(),
        }),
      resultUrlDomainAuthority: yup.number().label("Result URL Domain Authority").notRequired().nullable(true),
    }),
    onSubmit: async (values, helpers) => {
      if (!order) {
        return;
      }
      try {
        await updateOrderMutation({
          variables: {
            input: {
              orderId,
              quantity: (order?.partnerProduct.product.maxOrderQuantity ?? 1) > 1 ? values.quantity : undefined,
              instructions: FormHelpers.processNullableString(values.instructions),
              resultUrl: FormHelpers.processNullableString(values.resultUrl),
              resultUrlDomainAuthority: FormHelpers.processNullableInt(values.resultUrlDomainAuthority),
              startDate:
                !isSuperAdmin && isNotScheduled
                  ? undefined
                  : DateTime.fromJSDate(values.startDate).toUTC().toFormat(DATE_FORMAT),
            },
          },
        });
        onEdited();
        toast({
          title: "Order updated.",
          description: "The changes to the order have been saved successfully.",
          status: "success",
        });
      } catch (e: any) {
        toast({ title: "Edit Order", description: getDisplayMessageForError(e), status: "error" });
        helpers.setSubmitting(false);
      }
    },
  });

  if (!order) {
    return null;
  }

  function handleCancel() {
    formik.resetForm();
    onCancel();
  }

  if (orderDetailsResult.loading) {
    return <Loader type="fullView" />;
  }

  if (orderDetailsResult.error) {
    toast({ title: "Edit Order", description: getDisplayMessageForError(orderDetailsResult.error), status: "error" });
    handleCancel();
    return null;
  }

  if (!order) {
    toast({ title: "Edit Order", description: "Something went wrong trying to edit the order.", status: "error" });
    handleCancel();
    return null;
  }

  return (
    <Drawer onClose={handleCancel} isOpen={open} placement="right" size="md" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <form onSubmit={formik.handleSubmit}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Edit Order</DrawerHeader>
          <DrawerBody>
            <Box padding={4} minWidth={400}>
              <Stack spacing={4}>
                <FormControl isDisabled>
                  <FormLabel>Order ID</FormLabel>
                  <Input variant="outline" value={order.id} />
                </FormControl>
                <FormControl isDisabled>
                  <FormLabel>Campaign</FormLabel>
                  <CustomerCampaignSelector
                    customerId={order.customer.id}
                    onSelectedCustomerCampaignChange={() => {
                      // no-op
                    }}
                    selectedCustomerCampaignId={order.customerCampaignId ?? 0}
                    variant="outline"
                  />
                </FormControl>
                <FormControl isDisabled>
                  <FormLabel>Product</FormLabel>
                  <Input variant="outline" value={order.partnerProduct.name} isDisabled />
                </FormControl>
                <FormControl isDisabled={!isSuperAdmin && isNotScheduled}>
                  <FormLabel>Start Date</FormLabel>
                  <Box>
                    <DatePicker
                      onChange={(date: Date) => {
                        formik.setFieldValue("startDate", date ?? dateRangeStart);
                      }}
                      isClearable={false}
                      selected={formik.values.startDate}
                      minDate={dateRangeStart}
                      maxDate={dateRangeEnd}
                      dateFormat="MMM d, yyyy"
                      customInput={<Input variant="outline" width="100%" />}
                      disabledKeyboardNavigation
                      placeholderText="Select start date"
                      disabled={!isSuperAdmin && isNotScheduled}
                    />
                  </Box>
                  <FormHelperText>
                    Changing the start date will result in changes to billing. Orders are billed in the month that they
                    are scheduled to start.
                  </FormHelperText>
                </FormControl>
                <FormControl>
                  <FormLabel>Instructions</FormLabel>
                  <Textarea
                    value={formik.values.instructions}
                    id="instructions"
                    name="instructions"
                    onChange={formik.handleChange}
                  />
                </FormControl>
                {order.partnerProduct.product.maxOrderQuantity > 1 && (
                  <FormControl isDisabled={isNotScheduled}>
                    <FormLabel>Quantity</FormLabel>
                    <Input
                      variant="outline"
                      value={formik.values.quantity}
                      id="quantity"
                      name="quantity"
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                )}
                <FormControl isInvalid={!!formik.touched.resultUrl && !!formik.errors.resultUrl}>
                  <FormLabel>Result URL</FormLabel>
                  <Input
                    variant="outline"
                    value={formik.values.resultUrl}
                    name="resultUrl"
                    onChange={formik.handleChange}
                  />
                  <FormHelperText>Optional, must begin with http:// or https://</FormHelperText>
                  <FormErrorMessage>{formik.errors.resultUrl}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!formik.touched.resultUrlDomainAuthority && !!formik.errors.resultUrlDomainAuthority}
                >
                  <FormLabel>Result URL Domain Authority</FormLabel>
                  <Input
                    variant="outline"
                    value={formik.values.resultUrlDomainAuthority ?? undefined}
                    name="resultUrlDomainAuthority"
                    type="number"
                    onChange={formik.handleChange}
                  />
                  <FormHelperText>Optional</FormHelperText>
                  <FormErrorMessage>{formik.errors.resultUrlDomainAuthority}</FormErrorMessage>
                </FormControl>
              </Stack>
            </Box>
          </DrawerBody>
          <DrawerFooter>
            <SimpleGrid columns={2} gridGap={4} width="100%">
              <PrimaryButton width="100%" type="submit">
                Save
              </PrimaryButton>
              <CancelButton width="100%" onClick={onCancel}>
                Cancel
              </CancelButton>
            </SimpleGrid>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
}

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, useToast } from "@chakra-ui/react";

import { TasksTable_TaskFragment, useTasksTable_UpdateTaskMutation } from "../../generated/graphql";
import { AuthContext } from "../../core/auth-manager";
import { getDisplayMessageForError } from "../../util/error-helper";
import { PrimaryButton, PrimaryButtonAppLink } from "../../components/buttons";
import { TASK_TYPES } from "../../constants/task-types";
import { TASK_STATUS } from "../../constants/task-status";

const COMPLETABLE_TASK_TYPES = [
  TASK_TYPES.BUSINESS_LISTING,
  TASK_TYPES.CONTENT,
  TASK_TYPES.CONTENT_CALENDAR,
  TASK_TYPES.CONTENT_STRATEGY,
  TASK_TYPES.CONTENT_REVIEW,
  TASK_TYPES.CONTENT_STRATEGY_QA_REVIEW,
  TASK_TYPES.CHECKLIST,
  TASK_TYPES.BLOG_CONTENT,
  TASK_TYPES.BLOG_CONTENT_REVIEW,
  TASK_TYPES.KEYWORD_RESEARCH,
  TASK_TYPES.SITE_AUDIT,
  TASK_TYPES.WAITING_FOR_EXTERNAL_CONTENT,
  TASK_TYPES.CONTENT_FULFILLMENT_DECISION,
  TASK_TYPES.PUBLISH_POST,
  TASK_TYPES.GUEST_ARTICLE_OUTREACH,
  TASK_TYPES.CONTENT_IDEATION,
  TASK_TYPES.REVIEW_TITLE_TAG_META_DESCRIPTION,
  TASK_TYPES.PUBLISH_TITLE_META_DESCRIPTION,
  TASK_TYPES.PUBLISH_WEB_COPY_POST,
  TASK_TYPES.LINK_BUILDING,
  TASK_TYPES.PUBLISH_GBP_UPDATE,
];

interface Props {
  task: TasksTable_TaskFragment;
}

export function TaskRowAction(props: Props) {
  const { task } = props;
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const toast = useToast();

  const [updateTaskMutation, { loading }] = useTasksTable_UpdateTaskMutation();

  async function onClaimTask() {
    if (!authContext.viewer?.user) {
      return;
    }
    try {
      const response = await updateTaskMutation({
        variables: {
          input: {
            taskId: task.id,
            assignedUserId: authContext.viewer.user.id,
            status: TASK_STATUS.ASSIGNED.id,
          },
        },
      });
      const updatedTask = response.data?.updateTask.task;
      if (
        !!updatedTask &&
        !!updatedTask.assignedUser &&
        updatedTask.assignedUser.id === authContext.viewer.user.id &&
        updatedTask.status.id === TASK_STATUS.ASSIGNED.id
      ) {
        navigate(`/tasks/${updatedTask.id}`);
      }
    } catch (e: any) {
      toast({ description: getDisplayMessageForError(e), status: "error" });
    }
  }

  if (task.status.id === TASK_STATUS.AVAILABLE.id) {
    const canClaim = COMPLETABLE_TASK_TYPES.includes(task.taskType.id);
    let tooltip = "Although the task is available, you are unable to claim and work on it here.";
    if (canClaim) {
      tooltip = "Click to assign the task to yourself and start working on it.";
    }
    return (
      <Tooltip label={tooltip} aria-label={tooltip}>
        <span>
          <PrimaryButton onClick={onClaimTask} isDisabled={!canClaim} isLoading={loading}>
            Claim
          </PrimaryButton>
        </span>
      </Tooltip>
    );
  }

  if (task.isAssignedToViewer && task.status && task.status.id !== TASK_STATUS.COMPLETED.id) {
    return <PrimaryButtonAppLink to={`/tasks/${task.id}`}>Open</PrimaryButtonAppLink>;
  }

  return null;
}

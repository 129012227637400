import { DateTime } from "luxon";
import { PanelGroup, PanelTop, PanelBottom, PanelMiddle } from "../../components/panels";
import { Heading, Stack, Box, Text, Tooltip, Flex } from "@chakra-ui/react";
import { DangerButton, PrimaryButton, PrimaryIconButton } from "../../components/buttons";
import { PageHeading } from "../../components/page-heading";
import { WorkflowNotes } from "../workflow-notes";
import { HelpText } from "../../components/help-text/help-text";
import { useTaskWorkspaceContentStrategy } from "./use-task-workspace-content-strategy";
import { Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell } from "../../components/table";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { faPencilAlt, faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import AppIcon from "../../components/app-icon";
import { AppColors } from "../../core/custom-theme";
import { TruncatedText } from "../../components/truncated-text";
import { EditOrderTopicDrawer } from "./edit-order-topic-drawer";

interface Props {
  taskId: number;
  onAbandon: () => void;
  onComplete: (isApproved?: boolean, reviewComment?: string) => void;
}

export function TaskWorkspaceContentStrategy(props: Props) {
  const { taskId, onAbandon } = props;
  const {
    loading,
    orders,
    proposedOrderTopics,
    keywordDefinedCount,
    topicDefinedCount,
    customerId,
    customerProposal,
    editOrder,
    cancelEditOrder,
    onOrderEdited,
    orderBeingEdited,
    handleComplete,
  } = useTaskWorkspaceContentStrategy(props);
  return (
    <>
      <EditOrderTopicDrawer
        customerId={customerId}
        customerProposalId={customerProposal?.id ?? 0}
        onClose={cancelEditOrder}
        onEdited={onOrderEdited}
        open={!!orderBeingEdited}
        orderTopic={orderBeingEdited}
        taskId={taskId}
      />
      <PageHeading>Content Strategy Task</PageHeading>
      <Stack spacing={4}>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Heading size="md">Content Orders</Heading>
          </PanelTop>
          <PanelMiddle containsTable>
            <Table isEmpty={orders.length === 0} isLoading={loading}>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Product Type</TableHeaderCell>
                  <TableHeaderCell>Start Date</TableHeaderCell>
                  <TableHeaderCell>Keyword</TableHeaderCell>
                  <TableHeaderCell>Topic</TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => {
                  const proposedOrderTopic = proposedOrderTopics.find((x) => x.orderId === order.id);
                  const canEdit = order.customerKeyword === null || order.topic === null;
                  const currentTopic = proposedOrderTopic?.topic ?? order.topic ?? null;
                  const currentCustomerKeyword = proposedOrderTopic?.customerKeyword ?? order.customerKeyword ?? null;
                  return (
                    <TableRow key={order.id}>
                      <TableCell>
                        <TruncatedText maxLength={30}>{order.partnerProduct.product.name}</TruncatedText>
                      </TableCell>
                      <TableCell>{DateTime.fromJSDate(order.startDate).toFormat(DATE_MONTH_FORMAT)}</TableCell>
                      <TableCell>
                        {currentCustomerKeyword && (
                          <Flex alignItems="center">
                            <TruncatedText maxLength={30}>{currentCustomerKeyword.displayName}</TruncatedText>
                            <AppIcon icon={faCheckCircle} color={AppColors.success} standardLeftMargin />
                          </Flex>
                        )}
                        {!currentCustomerKeyword && (
                          <Text>
                            -
                            <Tooltip
                              aria-label="A Keyword is required but has not been set."
                              label="A Keyword is required but has not been set."
                            >
                              <span>
                                <AppIcon icon={faExclamationTriangle} color={AppColors.warning} standardLeftMargin />
                              </span>
                            </Tooltip>
                          </Text>
                        )}
                      </TableCell>
                      <TableCell>
                        {currentTopic && (
                          <Flex alignItems="center">
                            <TruncatedText maxLength={30}>{currentTopic}</TruncatedText>
                            <AppIcon icon={faCheckCircle} color={AppColors.success} standardLeftMargin />
                          </Flex>
                        )}
                        {!currentTopic && (
                          <Text>
                            -
                            <Tooltip
                              aria-label="A Topic is required but has not been set."
                              label="A Topic is required but has not been set."
                            >
                              <span>
                                <AppIcon icon={faExclamationTriangle} color={AppColors.warning} standardLeftMargin />
                              </span>
                            </Tooltip>
                          </Text>
                        )}
                      </TableCell>
                      <TableCell>
                        <Box textAlign="right">
                          {proposedOrderTopic?.rejectionComment && (
                            <Tooltip
                              aria-label="This keyword/topic was rejected by the client."
                              label="This keyword/topic was rejected by the client."
                            >
                              <span style={{ color: AppColors.error }}>
                                <AppIcon icon={faExclamationTriangle} standardRightMargin />
                              </span>
                            </Tooltip>
                          )}
                          <PrimaryIconButton
                            onClick={() => {
                              editOrder(order.id);
                            }}
                            icon={faPencilAlt}
                            actionName="Edit Order"
                            isDisabled={!canEdit}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </PanelMiddle>
          <PanelBottom>
            <Stack spacing={4}>
              <Stack isInline spacing={2} justifyContent="space-between" shouldWrapChildren>
                <Stack isInline spacing={4} justifyContent="flex-start" alignItems="center">
                  <PrimaryButton onClick={handleComplete}>Complete Task</PrimaryButton>
                  <Box>
                    Keywords Defined:{" "}
                    <strong>
                      {keywordDefinedCount} of {orders.length}
                    </strong>
                    {keywordDefinedCount === orders.length && (
                      <AppIcon icon={faCheckCircle} standardLeftMargin color={AppColors.success} />
                    )}
                    {keywordDefinedCount !== orders.length && (
                      <AppIcon icon={faExclamationTriangle} standardLeftMargin color={AppColors.warning} />
                    )}
                  </Box>
                  <Box>
                    Topics Defined:{" "}
                    <strong>
                      {topicDefinedCount} of {orders.length}
                    </strong>
                    {topicDefinedCount === orders.length && (
                      <AppIcon icon={faCheckCircle} standardLeftMargin color={AppColors.success} />
                    )}
                    {topicDefinedCount !== orders.length && (
                      <AppIcon icon={faExclamationTriangle} standardLeftMargin color={AppColors.warning} />
                    )}
                  </Box>
                </Stack>

                <Box>
                  <DangerButton onClick={onAbandon}>Unassign Task</DangerButton>
                  <HelpText standardLeftMargin>
                    &quot;Unassign Task&quot; will unassign this task from you and return it to the queue.{" "}
                    <strong>This cannot be undone and may result in lost work.</strong>
                  </HelpText>
                </Box>
              </Stack>
            </Stack>
          </PanelBottom>
        </PanelGroup>
        <WorkflowNotes taskId={taskId} />
      </Stack>
    </>
  );
}

import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "qs";
import { Box, Flex } from "@chakra-ui/react";

import { LayoutBase, MainNav } from "../../components/layout";
import { PageHeading } from "../../components/page-heading";
import { Container } from "../../components/layout/container";
import { PanelGroup, PanelBottom, PanelTop } from "../../components/panels";
import { useVendorsQuery } from "../../generated/graphql";
import { VendorsTable } from "./vendors-table";
import { PAGE_LIMIT } from "../../constants/constants";
import { setParams } from "../../util/query-param-helper";
import { getQueryParams } from "../../util/query-param-helper";
import { SearchInput } from "../../components/search-input/search-input";
import { PrimaryButton } from "../../components/buttons";
import { AddVendorDrawer } from "../../common/add-vendor-drawer";
import { EditVendorDrawer } from "../../common/edit-vendor-drawer";

export function VendorsRoute() {
  const [showAddVendorDrawer, setShowAddVendorDrawer] = useState(false);
  const [editVendorId, setEditVendorId] = useState<number | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = getQueryParams(location.search, queryString) || {};
  const searchValue = queryParams.search ?? "";
  const pageOffset = queryParams?.offset ? parseInt(queryParams.offset, 10) : 0;

  const vendorsQueryResult = useVendorsQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: PAGE_LIMIT,
      offset: pageOffset,
      search: searchValue,
    },
  });

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { search, ...remainingQueryParams } = queryParams;
    let searchParams;
    const value = event.target.value;
    if (value) {
      searchParams = setParams(remainingQueryParams, "search", `${value ? value : ""}`);
    } else {
      searchParams = setParams(remainingQueryParams);
    }
    searchParams = setParams(searchParams, "offset", "0");
    navigate({ search: `?${searchParams}` });
  }

  function onNextPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = parseInt(offset ?? "0", 10) + PAGE_LIMIT;
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onPreviousPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = Math.max(parseInt(offset ?? "0", 10) - PAGE_LIMIT, 0);
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onVendorAdded() {
    vendorsQueryResult.refetch();
  }

  function openAddVendorDrawer() {
    setShowAddVendorDrawer(true);
  }

  function closeAddVendorDrawer() {
    setShowAddVendorDrawer(false);
  }

  function onEditVendor(vendorId: number) {
    setEditVendorId(vendorId);
  }

  function onCloseEditVendor() {
    setEditVendorId(null);
  }

  function onVendorEdited() {
    vendorsQueryResult.refetch();
  }

  return (
    <LayoutBase pageTitle="Vendors">
      <MainNav />
      <AddVendorDrawer isOpen={showAddVendorDrawer} onClose={closeAddVendorDrawer} onAdded={onVendorAdded} />
      <EditVendorDrawer
        vendorId={editVendorId ?? 0}
        isOpen={!!editVendorId}
        onClose={onCloseEditVendor}
        onEdited={onVendorEdited}
      />
      <Container centerHorizontally>
        <Flex alignItems="center">
          <PageHeading>Vendors</PageHeading>
        </Flex>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                <SearchInput onChange={onChange} />
              </Box>
              <Box display="flex">
                <PrimaryButton onClick={openAddVendorDrawer}>Add Vendor</PrimaryButton>
              </Box>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <VendorsTable
              vendorsQueryResult={vendorsQueryResult}
              onNextPage={onNextPage}
              onPreviousPage={onPreviousPage}
              onEditVendor={onEditVendor}
            />
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}

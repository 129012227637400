import { Heading, Stack, Text } from "@chakra-ui/react";

import { PrimaryButtonAppLink } from "../../components/buttons";
import { PanelBox } from "../../components/panels";

interface Props {
  partnerId: number;
}

export function OneTimePaymentPanel(props: Props) {
  const { partnerId } = props;
  return (
    <PanelBox display="flex" justifyContent="space-between" alignItems="center">
      <Stack spacing={2}>
        <Heading size="md">One Time Payment</Heading>
        <Text>
          Select specific orders for which you would like to make a payment. This option is only available if automatic
          payments have not been enabled.
        </Text>
      </Stack>
      <PrimaryButtonAppLink to={`/billing/${partnerId}/pay`}>Pay Now</PrimaryButtonAppLink>
    </PanelBox>
  );
}

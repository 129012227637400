import React, { useState } from "react";
import { Box, Collapse, LinkProps } from "@chakra-ui/react";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../app-icon";
import { MainNavSmallLink } from "./main-nav-small-link";

interface Props extends LinkProps {
  title: string;
  children: React.ReactNode;
  isActive: boolean;
}

/* AppLink is a wrapper adding custom styling to React Routers Link component */
export function MainNavSmallDropdownMenu(props: Props) {
  const { title, children, isActive, ...rest } = props;
  const [isOpen, setIsOpen] = useState(isActive);

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <Box marginBottom={2}>
      <Box marginBottom={isOpen ? 2 : 0}>
        <MainNavSmallLink onClick={toggleOpen} {...rest}>
          {title}
          <AppIcon icon={isOpen ? faAngleUp : faAngleDown} standardLeftMargin />
        </MainNavSmallLink>
      </Box>
      <Collapse in={isOpen}>{children}</Collapse>
    </Box>
  );
}

import { lazy } from "react";
import { Route, Navigate, useParams, Routes } from "react-router-dom";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { ClientDetailsConversationRoute } from "../client-details-conversation-details";
import { ClientDetailsSecondaryNav } from "./client-details-secondary-nav";
import { CustomerMessages } from "../client-details-messages";
import { ClientDetailsRoadmap } from "../client-details-roadmap";
import { ClientDetailsOverview } from "../client-details-overview";
import { ClientDetailsUsers } from "../client-details-users";
import { ClientDetailsCampaigns } from "../client-details-campaigns";
import { ClientDetailsKeywords } from "../client-details-keywords";
import { useClientDetailsQuery } from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import { ClientDetailsEmails } from "../client-details-emails";
const ClientDetailsCampaignInvoices = lazy(() => import("../client-details-campaign-invoices"));
const ClientDetailsCampaignInvoiceDetails = lazy(() => import("../client-details-campaign-invoice-details"));
const ClientDetailsKeywordDetails = lazy(() => import("../client-details-keyword-details"));
const ClientDetailsLocations = lazy(() => import("../client-details-locations"));
const ClientDetailsLocationReviews = lazy(() => import("../client-details-location-reviews"));

export function ClientDetailsRoute() {
  const { id, "*": section } = useParams<"id" | "*">();
  const customerId = parseInt(id ?? "");
  const queryResult = useClientDetailsQuery({
    fetchPolicy: "network-only",
    variables: {
      customerId,
    },
  });
  const toast = useToast();
  const activeSection = section?.split("/")[0] ?? "overview";

  const customer = queryResult.data?.customer ?? null;
  if (!queryResult.loading && !customer) {
    toast({ title: "Invalid Client", description: "The specified Client could not be found.", status: "warning" });
    return <Navigate to="/clients" />;
  }

  let isReviewEnabled = false;
  for (const customerBusinessListingSpec of customer?.customerBusinessListingSpecs?.nodes ?? []) {
    if (customerBusinessListingSpec.isReviewEnabled) {
      isReviewEnabled = true;
    }
  }

  return (
    <LayoutBase pageTitle="Client Details">
      <MainNav hasSecondaryNav />
      <ClientDetailsSecondaryNav
        customerId={customerId}
        customerName={customer?.name ?? "Client"}
        activeSection={activeSection}
        isBillingVisible={customer?.isBillingVisible ?? false}
        isReviewEnabled={isReviewEnabled}
      />
      <Container centerHorizontally>
        <Routes>
          <Route path="/" element={<Navigate to={`/clients/${id}/overview`} />} />
          <Route path="overview" element={<ClientDetailsOverview customerId={customerId} />} />
          <Route path="campaigns" element={<ClientDetailsCampaigns clientId={customerId} />} />
          <Route path="roadmap" element={<ClientDetailsRoadmap customerId={customerId} />} />
          <Route path="messages" element={<CustomerMessages customerId={customerId} />} />
          <Route path="messages/:conversationId" element={<ClientDetailsConversationRoute />} />
          <Route path="keywords" element={<ClientDetailsKeywords customerId={customerId} />} />
          <Route path="keywords/:keywordId" element={<ClientDetailsKeywordDetails />} />
          <Route path="users" element={<ClientDetailsUsers clientId={customerId} />} />
          <Route path="emails" element={<ClientDetailsEmails clientId={customerId} />} />
          <Route path="invoices" element={<ClientDetailsCampaignInvoices customerId={customerId} />} />
          <Route path="invoices/:invoiceId" element={<ClientDetailsCampaignInvoiceDetails />} />
          <Route path="locations" element={<ClientDetailsLocations customerId={customerId} />} />
          {isReviewEnabled && <Route path="google-reviews" element={<ClientDetailsLocationReviews />} />}
        </Routes>
      </Container>
    </LayoutBase>
  );
}

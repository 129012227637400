import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Text,
  Tag,
  Stack,
  ModalFooter,
} from "@chakra-ui/react";
import prettyBytes from "pretty-bytes";
import { AlertInfo } from "../../components/alerts";
import { UploadFile, useUploadFileModal } from "./use-upload-file-modal";
import AppIcon from "../../components/app-icon";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { PrimaryButton, CancelButton, SecondaryButton } from "../../components/buttons";

interface Props {
  isOpen: boolean;
  instructionText?: string;
  onFileUploaded: (fileId: string, file?: UploadFile) => void;
  onCancel: () => void;
}

export function UploadFileDialog(props: Props) {
  const { instructionText, isOpen } = props;
  const { onCancel, selectedFile, isUploading, onFileSelected, onUpload } = useUploadFileModal(props);

  return (
    <Modal isOpen={isOpen} onClose={onCancel} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload File</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            {instructionText && <AlertInfo>{instructionText}</AlertInfo>}
            {selectedFile && (
              <Box marginBottom={2}>
                <Text>
                  <AppIcon icon={faFile} standardRightMargin />
                  <span style={{ marginRight: ".5rem" }}>{selectedFile.name}</span>
                  <Tag>{prettyBytes(selectedFile.size)}</Tag>
                </Text>
              </Box>
            )}
            <Box>
              <input
                accept="image/*"
                id="file-input"
                type="file"
                style={{ display: "none" }}
                onChange={onFileSelected}
              />
              <label htmlFor="file-input">
                <SecondaryButton as="span" cursor="pointer">
                  Select {selectedFile ? "A Different" : ""} File
                </SecondaryButton>
              </label>
            </Box>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack spacing={2} isInline>
            <PrimaryButton onClick={onUpload} isLoading={isUploading} isDisabled={isUploading}>
              Upload
            </PrimaryButton>
            <CancelButton onClick={onCancel}>Cancel</CancelButton>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

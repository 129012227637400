import { FormHelperText, Select, SelectProps } from "@chakra-ui/react";

import { AddPaymentMethodModal } from "../add-payment-method-modal";
import { usePartnerPaymentMethodSelector } from "./partner-payment-method-selector-hook";

interface Props extends Pick<SelectProps, "variant" | "isDisabled"> {
  partnerId: number;
  allowAddNewPaymentMethod?: boolean;
  selectedPartnerPaymentMethodId: number | null;
  onPartnerPaymentSelected: (partnerPaymentMethodId: number | null) => void;
}

export function PartnerPaymentMethodSelector(props: Props) {
  const { partnerId, allowAddNewPaymentMethod, ...rest } = props;
  const {
    handleSelectedPartnerPaymentChanged,
    internalSelectedPartnerPaymentId,
    partnerPaymentMethods,
    handleAddCancel,
    showAddModal,
    handleAdded,
    shouldPoll,
  } = usePartnerPaymentMethodSelector(props);

  return (
    <>
      <AddPaymentMethodModal
        isOpen={showAddModal}
        onAdded={handleAdded}
        onClose={handleAddCancel}
        partnerId={partnerId}
      />
      <Select value={internalSelectedPartnerPaymentId ?? 0} onChange={handleSelectedPartnerPaymentChanged} {...rest}>
        <option value={0}>Not Selected</option>
        {partnerPaymentMethods
          .filter((x) => !!x.partnerPaymentMethodCard)
          .map((partnerPaymentMethod) => {
            const card = partnerPaymentMethod.partnerPaymentMethodCard;
            return (
              <option key={partnerPaymentMethod.id} value={partnerPaymentMethod.id}>
                {card?.brandDisplayName ?? ""} ending in {card?.last4Digits ?? ""}, Expires{" "}
                {card?.expirationMonth ?? "?"}/{card?.expirationYear ?? "?"}
              </option>
            );
          })}
        {allowAddNewPaymentMethod && <option value={-1}>Add New Payment Method</option>}
      </Select>
      {shouldPoll && (
        <FormHelperText>
          Newly added payment methods must be verified and may take up to a minute to be shown in the list
        </FormHelperText>
      )}
    </>
  );
}

import { useToast } from "@chakra-ui/react";
import { Navigate } from "react-router-dom";
import { lazy } from "react";

import { TaskWorkspace_TaskFragment } from "../../generated/graphql";
import { TASK_STATUS } from "../../constants/task-status";
import { TaskCompleted } from "./task-completed";
import { useTaskWorkspace } from "./use-task-workspace";
import { TaskAlreadyAssigned } from "./task-already-assigned";
import { TASK_TYPES } from "../../constants/task-types";
import { TaskWorkspaceContent } from "../../common/task-workspace-content";
import { TaskWorkspaceKeywordResearch } from "../../common/task-workspace-keyword-research";
import { TaskWorkspaceContentReview } from "../../common/task-workspace-content-review";
import { TaskWorkspaceContentStrategy } from "../../common/task-workspace-content-strategy";
import { TaskWorkspaceChecklist } from "../../common/task-workspace-checklist";
import { TaskWorkspaceBusinessListing } from "../../common/task-workspace-business-listing";
import { TaskWorkspaceSiteAudit } from "../../common/task-workspace-site-audit";
import { TaskWorkspaceContentStrategyV2 } from "../../common/task-workspace-content-strategy-v2";
import { TaskWorkspaceExternalContent } from "../../common/task-workspace-external-content";
import { TaskWorkspaceContentFulfillmentDecision } from "../../common/task-workspace-content-fulfillment-decision";
import { TaskWorkspacePublishPost } from "../../common/task-workspace-publish-post";
import { TaskWorkspaceGuestArticleOutreach } from "../../common/task-workspace-guest-article-outreach";
import TaskWorkspaceContentIdeation from "../../common/task-workspace-content-ideation";
import TaskWorkspacePublishTitleMetaDescription from "../../common/task-workspace-publish-title-meta-description";
const TaskWorkSpacePublishWebCopyPost = lazy(() => import("../../common/task-workspace-publish-web-copy"));
const TaskWorkSpaceReviewPublishTitleMetaDescription = lazy(
  () => import("../../common/task-workspace-review-title-meta-description")
);
const TaskWorkspaceLinkBuild = lazy(() => import("../../common/task-workspace-link-build"));
const TaskWorkspacePublishGbp = lazy(() => import("../../common/task-workspace-publish-gbp"));

interface Props {
  task: TaskWorkspace_TaskFragment;
}

export function TaskWorkspace(props: Props) {
  const { task } = props;
  const { isAssignedToViewer, onAbandon, onComplete } = useTaskWorkspace(task);
  const toast = useToast();

  if (task.status.id === TASK_STATUS.COMPLETED.id) {
    return <TaskCompleted task={task} />;
  }

  if (task.status.id === TASK_STATUS.ASSIGNED.id && !isAssignedToViewer) {
    return <TaskAlreadyAssigned task={task} />;
  }

  const commonProps = {
    taskId: task.id,
    onAbandon,
    onComplete,
  };

  switch (task.taskType.id) {
    case TASK_TYPES.BUSINESS_LISTING:
      return <TaskWorkspaceBusinessListing {...commonProps} />;
    case TASK_TYPES.CONTENT:
      return <TaskWorkspaceContent {...commonProps} />;
    case TASK_TYPES.CONTENT_REVIEW:
      return <TaskWorkspaceContentReview {...commonProps} />;
    case TASK_TYPES.KEYWORD_RESEARCH:
      return <TaskWorkspaceKeywordResearch {...commonProps} />;
    case TASK_TYPES.CONTENT_CALENDAR:
      return <TaskWorkspaceContentStrategy {...commonProps} />;
    case TASK_TYPES.CONTENT_STRATEGY:
      return <TaskWorkspaceContentStrategyV2 {...commonProps} />;
    case TASK_TYPES.CONTENT_STRATEGY_QA_REVIEW:
      return <TaskWorkspaceContentStrategyV2 {...commonProps} isQaReview={true} />;
    case TASK_TYPES.CHECKLIST:
      return <TaskWorkspaceChecklist {...commonProps} />;
    case TASK_TYPES.SITE_AUDIT:
      return <TaskWorkspaceSiteAudit {...commonProps} />;
    case TASK_TYPES.WAITING_FOR_EXTERNAL_CONTENT:
      return <TaskWorkspaceExternalContent {...commonProps} />;
    case TASK_TYPES.CONTENT_FULFILLMENT_DECISION:
      return <TaskWorkspaceContentFulfillmentDecision {...commonProps} />;
    case TASK_TYPES.PUBLISH_POST:
      return <TaskWorkspacePublishPost {...commonProps} />;
    case TASK_TYPES.PUBLISH_WEB_COPY_POST:
      return <TaskWorkSpacePublishWebCopyPost {...commonProps} />;
    case TASK_TYPES.GUEST_ARTICLE_OUTREACH:
      return <TaskWorkspaceGuestArticleOutreach {...commonProps} />;
    case TASK_TYPES.CONTENT_IDEATION:
      return <TaskWorkspaceContentIdeation {...commonProps} />;
    case TASK_TYPES.REVIEW_TITLE_TAG_META_DESCRIPTION:
      return <TaskWorkSpaceReviewPublishTitleMetaDescription {...commonProps} />;
    case TASK_TYPES.PUBLISH_TITLE_META_DESCRIPTION:
      return <TaskWorkspacePublishTitleMetaDescription {...commonProps} />;
    case TASK_TYPES.LINK_BUILDING:
      return <TaskWorkspaceLinkBuild {...commonProps} />;
    case TASK_TYPES.PUBLISH_GBP_UPDATE:
      return <TaskWorkspacePublishGbp {...commonProps} />;
    default:
      toast({
        title: "Unable to Open Task",
        description: "Unable to determine a workspace for the specified task.",
        status: "warning",
      });
      return <Navigate to="/tasks" />;
  }

  return null;
}

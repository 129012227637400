import { useEffect } from "react";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import DatePicker from "react-datepicker";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useCreatePartnerProductPriceMutation } from "../../generated/graphql";
import { HelpText } from "../../components/help-text";
import { DATE_PICKER_FORMAT } from "../../constants/constants";

interface Props {
  partnerProductId: number;
  onClose: () => void;
  onCreated: () => void;
  enableSubmit: () => void;
  submitForm: boolean;
}

interface FormValues {
  price: number;
  startsAt: Date;
}

const createPartnerProductPriceSchema = yup.object().shape({
  price: yup.number().label("Price").required().min(0),
  startsAt: yup.date().label("Starts At").required(),
});

export function CreatePartnerProductPrice(props: Props) {
  const { onClose, onCreated, partnerProductId, submitForm, enableSubmit } = props;
  const [createPartnerProductPrice] = useCreatePartnerProductPriceMutation();
  const toast = useToast();

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await createPartnerProductPrice({
        variables: {
          input: {
            partnerProductId,
            price: values.price,
            startsAt: values.startsAt,
          },
        },
      });

      if (response.data?.createPartnerProductPrice.partnerProductPrice.id) {
        toast({ title: "Success", description: "Partner Product Price created successfully!", status: "success" });
        formikHelpers.resetForm();
        onCreated();
        onClose();
        enableSubmit();
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
      enableSubmit();
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      price: 0,
      startsAt: new Date(),
    },
    validationSchema: createPartnerProductPriceSchema,
    onSubmit: handleSubmit,
  });

  function onStartDateChange(date: Date) {
    formik.setFieldValue("startsAt", date ?? new Date());
  }

  useEffect(() => {
    if (submitForm) {
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitForm]);

  return (
    <Stack spacing={4}>
      <FormControl isRequired isInvalid={!!formik.errors.price && !!formik.touched.price}>
        <FormLabel>New Price</FormLabel>
        <InputGroup>
          <InputLeftElement color="gray.300" fontSize="1.2em">
            $
          </InputLeftElement>
          <Input
            placeholder="Enter Price"
            variant="outline"
            id="price"
            type="number"
            value={formik.values.price}
            onChange={formik.handleChange}
          />
        </InputGroup>
        <FormErrorMessage>{formik.errors.price}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={!!formik.errors.startsAt && !!formik.touched.startsAt} zIndex={10}>
        <FormLabel>
          Start Date <HelpText standardLeftMargin>Changes will go into effect at 12:00 AM UTC</HelpText>
        </FormLabel>
        <DatePicker
          onChange={onStartDateChange}
          selected={formik.values.startsAt}
          minDate={new Date()}
          dateFormat={DATE_PICKER_FORMAT}
          customInput={<Input variant="outline" width="100%" />}
          disabledKeyboardNavigation
          placeholderText="Select start date"
        />
        <FormErrorMessage>{formik.errors.startsAt as string}</FormErrorMessage>
      </FormControl>
    </Stack>
  );
}

import { useOrderDetailsContentItemQuery } from "../../../generated/graphql";

export function useOrderDetailsContentItem(orderId: number) {
  const queryResult = useOrderDetailsContentItemQuery({
    fetchPolicy: "network-only",
    variables: {
      orderId,
    },
  });

  const order = queryResult.data?.order ?? null;
  const orderContentItems = order?.orderContentItems ?? [];

  return {
    orderId,
    loading: queryResult.loading,
    order,
    orderContentItems,
  };
}

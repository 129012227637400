import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import {
  Billing_PartnerBillingContactFragment,
  usePartnerBillingContactsQuery,
  useDeletePartnerBillingContactMutation,
} from "../../generated/graphql";
import { handleGraphQLResponseError } from "../../util/error-helper";

export function useBillingContacts(partnerId: number) {
  const [isAdding, setIsAdding] = useState(false);
  const [billingContactBeingEdited, setBillingContactBeingEdited] =
    useState<Billing_PartnerBillingContactFragment | null>(null);
  const [deletePartnerBillingContactMutation] = useDeletePartnerBillingContactMutation();
  const toast = useToast();

  const queryResult = usePartnerBillingContactsQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId,
    },
  });

  const partnerBillingContacts = queryResult.data?.partner?.partnerBillingContacts.nodes ?? [];

  function handleAdd() {
    setIsAdding(true);
  }

  function handleAddCancel() {
    setIsAdding(false);
  }

  function handleAdded() {
    setIsAdding(false);
    queryResult.refetch();
  }

  function handleEdit(partnerBillingContact: Billing_PartnerBillingContactFragment) {
    setBillingContactBeingEdited(partnerBillingContact);
  }

  function handleEditClose() {
    setBillingContactBeingEdited(null);
  }

  async function handleRemove(partnerBillingContactId: number) {
    try {
      const response = await deletePartnerBillingContactMutation({
        variables: {
          input: {
            partnerBillingContactId,
          },
        },
      });
      if (response.data?.deletePartnerBillingContact.ok) {
        queryResult.refetch();
      } else {
        throw new Error(
          response.data?.deletePartnerBillingContact.error?.message ?? "Unable to delete billing contact."
        );
      }
    } catch (e: any) {
      handleGraphQLResponseError(e, toast);
    }
  }

  return {
    partnerBillingContacts,
    isAdding,
    handleAdd,
    handleAddCancel,
    handleAdded,
    billingContactBeingEdited,
    handleEdit,
    handleEditClose,
    handleRemove,
  };
}

import { Box, Button, Flex, FormControl, FormLabel, HStack } from "@chakra-ui/react";
import { faBan, faSort } from "@fortawesome/free-solid-svg-icons";

import { CustomerConversationsSort } from "../../generated/graphql";
import { CustomerSelector } from "../../common/customer-selector";
import { CustomSingleSelect } from "../../common/custom-selector";
import AppIcon from "../../components/app-icon";
import { HelpText } from "../../components/help-text";
import { PageHeading } from "../../components/page-heading";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { SearchInput } from "../../components/search-input/search-input";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";

import { useMessages } from "./use-messages-hook";
import ConversationsTable from "./conversations-table";

export function MessagesRoute() {
  const {
    sort,
    customerId,
    hasFilters,
    queryResult,
    handleSort,
    onCustomerSelected,
    onNextPage,
    onPreviousPage,
    onChange,
    clearFilters,
  } = useMessages();

  return (
    <LayoutBase pageTitle="Messages">
      <MainNav />
      <Container centerHorizontally>
        <Flex alignItems="center">
          <PageHeading>Messages</PageHeading>
          <HelpText standardLeftMargin>All recent messages from all clients.</HelpText>
        </Flex>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between">
              <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end">
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Search</FormLabel>
                  <SearchInput onChange={onChange} />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Sort</FormLabel>
                  <CustomSingleSelect
                    onChange={handleSort}
                    allowEmpty={false}
                    value={sort ?? null}
                    emptyValueTitle="Newest First"
                    items={[
                      { displayText: "Newest First", value: CustomerConversationsSort.CreatedAtDesc },
                      { displayText: "Oldest First", value: CustomerConversationsSort.CreatedAtAsc },
                    ]}
                    selectorIcon={faSort}
                  />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Client</FormLabel>
                  <CustomerSelector
                    onCustomerSelected={onCustomerSelected}
                    selectedCustomerId={customerId ?? null}
                    emptyValueLabel="All Clients"
                  />
                </FormControl>
                <Box>
                  {hasFilters && (
                    <Button onClick={clearFilters}>
                      <AppIcon icon={faBan} standardRightMargin />
                      Clear Filters
                    </Button>
                  )}
                </Box>
              </HStack>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <ConversationsTable queryResult={queryResult} onNextPage={onNextPage} onPreviousPage={onPreviousPage} />
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}

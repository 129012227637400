import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Textarea,
  Badge,
  Skeleton,
  Switch,
} from "@chakra-ui/react";
import { faBookOpen, faDollarSign } from "@fortawesome/free-solid-svg-icons";

import { useTaskWorkspaceGuestArticleOutreach } from "./use-guest-article-outreach";
import { PageHeading } from "../../components/page-heading";
import { SUB_TASK_TYPES } from "../../constants/task-types";
import { WebsiteSelection } from "./website-selection/website-selection";
import { ChecklistTask } from "./checklist-task/checklist-task";
import { QaContent } from "./qa-content/qa-content";
import { PanelGroup, PanelMiddle, PanelTop } from "../../components/panels";
import { PrimaryButton, PrimaryIconButton } from "../../components/buttons";
import { AppColors } from "../../core/custom-theme";
import AppIcon from "../../components/app-icon";
import { ContentEditor } from "../../components/content-editor";
import { ProposalReview } from "./proposal-review";
import { PUBLISHER_PAYMENT_STATUS } from "../../constants/publisher-payment-status";

interface Props {
  taskId: number;
  onAbandon: () => void;
  onComplete: () => void;
}

export function TaskWorkspaceGuestArticleOutreach(props: Props) {
  const { onAbandon } = props;
  const {
    task,
    onSubTaskComplete,
    formik,
    onVerifyLinkComplete,
    publisherPayFormik,
    onPublisherPayComplete,
    onOpenContent,
    onCloseContent,
    showContent,
    isPublisherPaymentExist,
    contentFormik,
    loading,
  } = useTaskWorkspaceGuestArticleOutreach({
    taskId: props.taskId,
  });

  function getChecklistItem(taskTypeId: string) {
    const checklistObj = {
      [SUB_TASK_TYPES.VERIFY_PUBLISHED_LINK]: ["Verify Published Link"],
      [SUB_TASK_TYPES.PUBLISHER_PAY]: ["Publisher Pay"],
    };

    return checklistObj[taskTypeId] ?? [];
  }

  function renderSubTask(taskTypeId: string, taskId: number, isDisabled?: boolean, completedAt?: Date) {
    const renderObj = {
      [SUB_TASK_TYPES.WEBSITE_SELECTION]: (
        <WebsiteSelection
          customerId={task?.customer?.id ?? 0}
          taskId={taskId}
          onComplete={onSubTaskComplete}
          onAbandon={onAbandon}
          isDisabled={isDisabled}
          completedAt={completedAt}
        />
      ),
      [SUB_TASK_TYPES.PROPOSAL_REVIEW]: <ProposalReview taskId={taskId} isDisabled={isDisabled} />,
      [SUB_TASK_TYPES.CONTENT_SEND]: (
        <ChecklistTask
          heading="Publisher Content Send"
          taskId={taskId}
          checklistItems={getChecklistItem(taskTypeId)}
          onComplete={onSubTaskComplete}
          showApprove={false}
          onAbandon={onAbandon}
          isDisabled={isDisabled}
          completedAt={completedAt}
        />
      ),
      [SUB_TASK_TYPES.PUBLISHER_CONTENT_REVIEW]: (
        <ChecklistTask
          heading="Publisher Content Review"
          taskId={taskId}
          checklistItems={getChecklistItem(taskTypeId)}
          onComplete={onSubTaskComplete}
          onAbandon={onAbandon}
          isDisabled={isDisabled}
          completedAt={completedAt}
        />
      ),
      [SUB_TASK_TYPES.QA_CONTENT_UPDATE]: (
        <QaContent
          taskId={taskId}
          onComplete={onSubTaskComplete}
          onAbandon={onAbandon}
          isDisabled={isDisabled}
          completedAt={completedAt}
        />
      ),
      [SUB_TASK_TYPES.VERIFY_PUBLISHED_LINK]: (
        <ChecklistTask
          heading="Verify Published Link"
          taskId={taskId}
          checklistItems={getChecklistItem(taskTypeId)}
          onComplete={onVerifyLinkComplete}
          showApprove={false}
          onAbandon={onAbandon}
          isDisabled={isDisabled}
          completedAt={completedAt}
        >
          <FormControl isInvalid={!!formik.touched.resultUrl && !!formik.errors.resultUrl} isDisabled={isDisabled}>
            <FormLabel>Result URL</FormLabel>
            <Input
              type="text"
              variant="outline"
              id="resultUrl"
              value={formik.values.resultUrl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <FormErrorMessage>{formik.errors.resultUrl}</FormErrorMessage>
          </FormControl>
        </ChecklistTask>
      ),
      [SUB_TASK_TYPES.PUBLISHER_PAY]: (
        <ChecklistTask
          heading="Publisher Pay"
          taskId={taskId}
          checklistItems={getChecklistItem(taskTypeId)}
          onComplete={onPublisherPayComplete}
          showApprove={false}
          onAbandon={onAbandon}
          isDisabled={isDisabled || isPublisherPaymentExist}
          completedAt={completedAt}
          hideUnassign={true}
        >
          <FormControl>
            <FormLabel>Order ID</FormLabel>
            <Input variant="outline" id="orderId" value={task?.order?.id ?? "-"} isDisabled={true} />
          </FormControl>
          <FormControl>
            <FormLabel>Result URL</FormLabel>
            <Input
              type="text"
              variant="outline"
              id="resultUrl"
              value={task?.order?.resultUrl ?? "-"}
              isDisabled={true}
            />
            <FormErrorMessage>{formik.errors.resultUrl}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!publisherPayFormik.errors.amount && !!publisherPayFormik.touched.amount}>
            <FormLabel>
              Amount{" "}
              {task?.order.targetLinkLocation?.publisherLinkLocation?.publisher?.isAutoPayEnabled ? (
                <Badge colorScheme="red">Auto Pay</Badge>
              ) : (
                ""
              )}
            </FormLabel>
            <InputGroup>
              <InputLeftElement color="gray.300" fontSize="1.2em">
                $
              </InputLeftElement>
              <Input
                placeholder="Enter amount"
                variant="outline"
                id="amount"
                type="number"
                value={publisherPayFormik.values.amount}
                onChange={publisherPayFormik.handleChange}
                isDisabled={isPublisherPaymentExist}
              />
            </InputGroup>
            {!!publisherPayFormik.errors.amount && !!publisherPayFormik.touched.amount && (
              <FormErrorMessage>{publisherPayFormik.errors.amount}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!publisherPayFormik.errors.processor && !!publisherPayFormik.touched.processor}>
            <FormLabel>Processor</FormLabel>
            <Select
              variant="outline"
              id="processor"
              value={publisherPayFormik.values.processor}
              onChange={publisherPayFormik.handleChange}
              isDisabled={isPublisherPaymentExist}
            >
              <option value="" key="none">
                Select Processor
              </option>
              <option value="PayPal" key="PayPal">
                PayPal
              </option>
              <option value="Payoneer" key="Payoneer">
                Payoneer
              </option>
            </Select>
            {!!publisherPayFormik.errors.processor && !!publisherPayFormik.touched.processor && (
              <FormErrorMessage>{publisherPayFormik.errors.processor}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isInvalid={
              !!publisherPayFormik.errors.processorReferenceId && !!publisherPayFormik.touched.processorReferenceId
            }
          >
            <FormLabel>Processor Reference ID</FormLabel>
            <Input
              placeholder="Enter processor reference id"
              variant="outline"
              id="processorReferenceId"
              value={publisherPayFormik.values.processorReferenceId}
              onChange={publisherPayFormik.handleChange}
              isDisabled={isPublisherPaymentExist}
            />
            {!!publisherPayFormik.errors.processorReferenceId && !!publisherPayFormik.touched.processorReferenceId && (
              <FormErrorMessage>{publisherPayFormik.errors.processorReferenceId}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Payment Batch ID</FormLabel>
            <Input
              variant="outline"
              id="processorReferenceId"
              value={task?.order.publisherPayment?.paymentBatchId ?? "-"}
              isDisabled={isPublisherPaymentExist}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Payment Method ID</FormLabel>
            <Input
              variant="outline"
              id="paymentMethodId"
              value={task?.order.targetLinkLocation?.publisherLinkLocation?.publisher?.paymentMethodId ?? "-"}
              isDisabled={true}
            />
          </FormControl>
          <FormControl isInvalid={!!publisherPayFormik.errors.statusId && !!publisherPayFormik.touched.statusId}>
            <FormLabel>Status</FormLabel>
            <Select
              variant="outline"
              id="statusId"
              value={publisherPayFormik.values.statusId}
              onChange={publisherPayFormik.handleChange}
              isDisabled={isPublisherPaymentExist}
            >
              <option value="" key="none">
                Select Payment Status
              </option>
              <option value={PUBLISHER_PAYMENT_STATUS.PENDING.id} key={PUBLISHER_PAYMENT_STATUS.PENDING.id}>
                {PUBLISHER_PAYMENT_STATUS.PENDING.name}
              </option>
              <option value={PUBLISHER_PAYMENT_STATUS.SUBMITTED.id} key={PUBLISHER_PAYMENT_STATUS.SUBMITTED.id}>
                {PUBLISHER_PAYMENT_STATUS.SUBMITTED.name}
              </option>
              <option value={PUBLISHER_PAYMENT_STATUS.COMPLETED.id} key={PUBLISHER_PAYMENT_STATUS.COMPLETED.id}>
                {PUBLISHER_PAYMENT_STATUS.COMPLETED.name}
              </option>
            </Select>
            {!!publisherPayFormik.errors.processor && !!publisherPayFormik.touched.processor && (
              <FormErrorMessage>{publisherPayFormik.errors.processor}</FormErrorMessage>
            )}
          </FormControl>
        </ChecklistTask>
      ),
    };
    return renderObj[taskTypeId];
  }

  return (
    <>
      <Modal isOpen={showContent} onClose={onCloseContent} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Content</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ContentEditor
              height={500}
              min_height={500}
              initialValue={task?.order?.orderContentItems?.[0]?.contentItem?.content ?? ""}
              readOnly={true}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <PageHeading>Guest Article Outreach Task</PageHeading>
      <PanelGroup isCollapsible={true} isCollapsedByDefault={true} marginBottom={8}>
        <PanelTop>
          <Heading>Client Information</Heading>
        </PanelTop>
        <PanelMiddle>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Client</FormLabel>
              <Input value={task?.customer.name ?? "-"} variant="outline" isReadOnly />
            </FormControl>
            <FormControl>
              <FormLabel>Website</FormLabel>
              <Input value={task?.customer.website ?? "-"} isReadOnly />
            </FormControl>
            <FormControl>
              <FormLabel>Category</FormLabel>
              <Input value={task?.customer.category?.name ?? "-"} isReadOnly />
            </FormControl>
            <FormControl>
              <FormLabel>Client Instructions</FormLabel>
              <Textarea
                id="instructions"
                value={task?.customer?.instructions ?? ""}
                resize="none"
                placeholder="Instructions"
                variant="outline"
                isReadOnly
              />
            </FormControl>
            <FormControl>
              <FormLabel>Product</FormLabel>
              <Input value={task?.order?.partnerProduct?.product?.name ?? ""} isReadOnly />
            </FormControl>
            <FormControl>
              <FormLabel>Keyword</FormLabel>
              <Input value={task?.keyword ?? "-"} isReadOnly />
            </FormControl>
            <FormControl>
              <FormLabel>Landing Page URL</FormLabel>
              <Input isReadOnly value={task?.landingPageUrl ?? task?.customer.website ?? "-"} />
            </FormControl>
            <FormControl isRequired isInvalid={!!contentFormik.touched.title && !!contentFormik.errors.title}>
              <FormLabel>Title</FormLabel>
              <Input id="title" value={contentFormik.values.title} onChange={contentFormik.handleChange} />
              <FormErrorMessage>{contentFormik.errors.title}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!contentFormik.touched.content && !!contentFormik.errors.content}>
              <FormLabel>Content</FormLabel>
              {loading && <Skeleton height={50} />}
              {!loading && (
                <ContentEditor
                  isInvalid={!!contentFormik.touched.content && !!contentFormik.errors.content}
                  initialValue={task?.order?.orderContentItems[0]?.contentItem?.content ?? ""}
                  onChange={(html) => {
                    contentFormik.setFieldValue("content", html);
                  }}
                />
              )}
              <FormErrorMessage>{contentFormik.errors.content}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <PrimaryButton onClick={contentFormik.submitForm}>Save Content</PrimaryButton>
            </FormControl>
            <FormControl isReadOnly>
              <FormLabel>Content</FormLabel>
              <Stack isInline spacing={2}>
                <PrimaryIconButton icon={faBookOpen} actionName="Open Content" onClick={onOpenContent} />
              </Stack>
            </FormControl>
          </Stack>
        </PanelMiddle>
      </PanelGroup>
      <PanelGroup isCollapsible={true} isCollapsedByDefault={true} marginBottom={8}>
        <PanelTop>
          <Heading>Publisher Information</Heading>
        </PanelTop>
        <PanelMiddle>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Domain</FormLabel>
              <Input value={task?.order.targetLinkLocation?.domain ?? "-"} variant="outline" isReadOnly />
            </FormControl>
            <FormControl>
              <FormLabel>Domain Authority</FormLabel>
              <Input value={task?.order.targetLinkLocation?.domainAuthority ?? "-"} isReadOnly />
            </FormControl>
            <FormControl>
              <FormLabel>Contact Name</FormLabel>
              <Input
                value={task?.order.targetLinkLocation?.publisherLinkLocation?.publisher?.contactName ?? "-"}
                isReadOnly
              />
            </FormControl>
            <FormControl>
              <FormLabel>Contact Email</FormLabel>
              <Input
                value={task?.order.targetLinkLocation?.publisherLinkLocation?.publisher?.contactEmail ?? "-"}
                isReadOnly
              />
            </FormControl>
            <FormControl>
              <FormLabel>
                Cost{" "}
                {task?.order.targetLinkLocation?.publisherLinkLocation?.publisher?.isAutoPayEnabled ? (
                  <Badge colorScheme="red">Auto Pay</Badge>
                ) : (
                  ""
                )}
              </FormLabel>
              <InputGroup>
                <InputLeftElement color={AppColors.textGray}>
                  <AppIcon icon={faDollarSign} />
                </InputLeftElement>
                <Input type="number" value={task?.order.targetLinkLocation?.cost ?? 0} isReadOnly />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel>Invoice Required</FormLabel>
              <Switch
                id="isInvoiceRequired"
                isChecked={task?.order.targetLinkLocation?.publisherLinkLocation?.publisher?.isInvoiceRequired ?? false}
                isReadOnly
              />
            </FormControl>
            <FormControl>
              <FormLabel>Publisher</FormLabel>
              <Input value={task?.order.targetLinkLocation?.publisherLinkLocation?.publisher?.name ?? "-"} isReadOnly />
            </FormControl>
            <FormControl>
              <FormLabel>Payment Method</FormLabel>
              <Input
                value={task?.order.targetLinkLocation?.publisherLinkLocation?.publisher?.paymentMethod ?? "-"}
                isReadOnly
              />
            </FormControl>
            <FormControl>
              <FormLabel>Payment Method Id</FormLabel>
              <Input
                value={task?.order.targetLinkLocation?.publisherLinkLocation?.publisher?.paymentMethodId ?? "-"}
                isReadOnly
              />
            </FormControl>
            <FormControl>
              <FormLabel>Instructions</FormLabel>
              <Textarea
                value={task?.order.targetLinkLocation?.publisherLinkLocation?.publisher?.instructions ?? "Not Provided"}
                variant="outline"
                isReadOnly
              />
            </FormControl>
          </Stack>
        </PanelMiddle>
      </PanelGroup>
      {renderSubTask(task?.currentSubTask?.taskTypeId ?? "", task?.currentSubTask?.id ?? 0)}
      {(task?.subTasks?.nodes ?? []).map((task) =>
        renderSubTask(task.taskTypeId, task.id, true, task.completedAt ?? undefined)
      )}
    </>
  );
}

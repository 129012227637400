import React from "react";

export interface TableContextValue {
  columnCount: number;
  setColumnCount: (columns: number) => void;
  isLoading: boolean;
  isEmpty: boolean;
  emptyText?: string;
}

export const TableContext = React.createContext<TableContextValue>({
  columnCount: 0,
  setColumnCount: () => {
    // Do nothing
  },
  isLoading: true,
  isEmpty: true,
  emptyText: undefined,
});

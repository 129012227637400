import {
  Box,
  FormControl,
  Heading,
  HStack,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

import { AdminOnly } from "../../common/admin-only";
import { PartnerInvoiceStatusIndicator } from "../../common/partner-invoice-status-indicator";
import { AlertInfo } from "../../components/alerts";
import AppLink from "../../components/app-link";
import { PrimaryButtonAppLink, PrimaryIconButton, SecondaryButton } from "../../components/buttons";
import { FormattedDate } from "../../components/formatted-date";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { Loader } from "../../components/loader";
import { PageHeading } from "../../components/page-heading";
import { PanelBox } from "../../components/panels";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { currencyFormatter } from "../../util/formatter";
import { AutomaticPayments } from "./automatic-payments";
import { BillingContacts } from "./billing-contacts";
import { useBilling } from "./billing-hook";
import { OneTimePaymentPanel } from "./one-time-payment";
import { PaymentMethods } from "./payment-methods";
import { RecordManualPaymentModal } from "./record-manual-payment-modal";
import { PARTNER_INVOICE_STATUS } from "../../constants/partner-invoice-status";
// import { BillingStats } from "./billing-stats";

export function BillingRoute() {
  const {
    partnerId,
    partners,
    handlePartnerChange,
    partnerInvoices,
    isPartnerInvoicesLoading,
    handlePaymentMethodAdded,
    handlePaymentMethodRemoved,
    partner,
    handleDownloadPdf,
    overviewLoading,
    openRecordManualPayment,
    closeRecordManualPayment,
    selectedPartnerInvoice,
    isAdmin,
  } = useBilling();
  return (
    <LayoutBase pageTitle="Billing">
      <MainNav hasSecondaryNav />
      <Container centerHorizontally>
        <PageHeading>Billing</PageHeading>
        {partners.length > 1 && (
          <Box maxW={300} mb={4}>
            <FormControl>
              <Select backgroundColor="white" onChange={handlePartnerChange} value={partnerId ?? partners[0].id}>
                {partners.map((partner) => (
                  <option key={partner.id} value={partner.id}>
                    {partner.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        {overviewLoading && <Loader type="panel" />}
        {!overviewLoading && !partner?.isBillingEnforced && (
          <Stack spacing={4}>
            <AlertInfo>Billing has not yet been enabled for {partner?.name ?? "this partner"}.</AlertInfo>
            <AdminOnly>
              <Box>
                <PrimaryButtonAppLink to={`/admin/partners/${partner?.id}`}>
                  View Settings for {partner?.name ?? "Partner"}
                </PrimaryButtonAppLink>
              </Box>
            </AdminOnly>
          </Stack>
        )}
        {partner?.isBillingEnforced && (
          <Tabs variant="soft-rounded" isLazy>
            <TabList>
              <Tab>Overview</Tab>
              <Tab>Settings</Tab>
            </TabList>
            <TabPanels marginTop={4}>
              <TabPanel padding={0}>
                <Stack spacing={4} shouldWrapChildren>
                  {isAdmin && partner?.isUpfrontPaymentRequired && !partner.isAutopayEnabled && (
                    <OneTimePaymentPanel partnerId={partner.id} />
                  )}
                  {/* <BillingStats /> */}
                  <Heading size="md">Invoices</Heading>
                  <PanelBox containsTable>
                    <Table
                      isEmpty={partnerInvoices.length === 0}
                      isLoading={isPartnerInvoicesLoading}
                      emptyText="No invoices generated yet."
                    >
                      <TableHead>
                        <TableRow>
                          <TableHeaderCell>Invoice ID</TableHeaderCell>
                          <TableHeaderCell>Invoice Date</TableHeaderCell>
                          <TableHeaderCell>Amount</TableHeaderCell>
                          <TableHeaderCell>Status</TableHeaderCell>
                          <TableHeaderCell></TableHeaderCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {partnerInvoices.map((partnerInvoice) => (
                          <TableRow key={partnerInvoice.id}>
                            <TableCell>
                              <AppLink to={`/billing/${partnerId}/invoices/${partnerInvoice.id}`}>
                                {partnerInvoice.id}
                              </AppLink>
                            </TableCell>
                            <TableCell>
                              <FormattedDate format={DATE_MONTH_FORMAT} date={partnerInvoice.createdAt} />
                            </TableCell>
                            <TableCell>{currencyFormatter.format(partnerInvoice.totalChargesAmount)}</TableCell>
                            <TableCell>
                              <PartnerInvoiceStatusIndicator status={partnerInvoice.status} />
                            </TableCell>
                            <TableCell>
                              <HStack spacing={2} justifyContent="flex-end">
                                <AdminOnly>
                                  {partnerInvoice.status?.id === PARTNER_INVOICE_STATUS.OPEN.id && (
                                    <SecondaryButton onClick={openRecordManualPayment.bind(null, partnerInvoice)}>
                                      Record Payment
                                    </SecondaryButton>
                                  )}
                                </AdminOnly>
                                <PrimaryIconButton
                                  onClick={() => {
                                    handleDownloadPdf(partnerInvoice.id);
                                  }}
                                  icon={faFileDownload}
                                  actionName="Download PDF"
                                />
                              </HStack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </PanelBox>
                </Stack>
              </TabPanel>
              <TabPanel padding={0}>
                <Stack spacing={8}>
                  <Stack spacing={4}>
                    <Heading size="md">Automatic Payments</Heading>
                    <AutomaticPayments partnerId={partnerId ?? 0} />
                  </Stack>
                  <Stack spacing={4}>
                    <Heading size="md">Payment Methods</Heading>
                    <PaymentMethods
                      partnerId={partnerId ?? 0}
                      onPaymentMethodAdded={handlePaymentMethodAdded}
                      onPaymentMethodRemoved={handlePaymentMethodRemoved}
                    />
                  </Stack>
                  <Stack spacing={4}>
                    <Heading size="md">Billing Contacts</Heading>
                    <BillingContacts partnerId={partnerId ?? 0} />
                  </Stack>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
        <RecordManualPaymentModal
          isOpen={!!selectedPartnerInvoice}
          onClose={closeRecordManualPayment}
          partnerInvoice={selectedPartnerInvoice}
        />
      </Container>
    </LayoutBase>
  );
}

import { Helmet } from "react-helmet";
import { Box } from "@chakra-ui/react";
import { Global } from "@emotion/react";

interface Props {
  children: React.ReactNode;
  pageTitle: string;
}

export function LayoutBase(props: Props) {
  const { children, pageTitle } = props;
  return (
    <Box as="main" marginBottom={12}>
      <Global styles={{ body: { backgroundColor: "#F5F6F8" } }} />
      <Helmet>
        <title>{pageTitle} - Red Canyon Media</title>
      </Helmet>
      {children}
    </Box>
  );
}

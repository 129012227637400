import { SecondaryNav, SecondaryNavLink } from "../../components/layout";
import { usePartnerDetailsSecondaryNavQuery } from "../../generated/graphql";

interface Props {
  partnerId: number;
  activeSection?: string;
}

const NAV_ITEMS = [
  {
    section: "overview",
    displayName: "Overview",
  },
  {
    section: "configuration",
    displayName: "Configuration",
  },
];

export function PartnerDetailsSecondaryNav(props: Props) {
  const { partnerId, activeSection } = props;
  const partnerDetailsSecondaryNavQueryResult = usePartnerDetailsSecondaryNavQuery({
    variables: {
      partnerId: partnerId,
    },
  });
  return (
    <SecondaryNav title={partnerDetailsSecondaryNavQueryResult.data?.partner?.name ?? "Partner"}>
      {NAV_ITEMS.map((item) => (
        <SecondaryNavLink
          key={item.section}
          isActive={activeSection === item.section}
          to={`/admin/partners/${partnerId}/${item.section}`}
        >
          {item.displayName}
        </SecondaryNavLink>
      ))}
    </SecondaryNav>
  );
}

import { DateTime } from "luxon";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { useTaskWorkspaceContent } from "./use-task-workspace-content";
import { ContentEditor } from "../../components/content-editor";
import {
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Skeleton,
  FormErrorMessage,
  Box,
  FormHelperText,
  Textarea,
} from "@chakra-ui/react";
import { DangerButton, PrimaryButton } from "../../components/buttons";
import { PageHeading } from "../../components/page-heading";
import { ReviewCommentsPanel } from "../review-comments-panel";
import { WorkflowNotes } from "../workflow-notes";
import { HelpText } from "../../components/help-text";

interface Props {
  taskId: number;
  onAbandon: () => void;
  onComplete: (isApproved?: boolean, reviewComment?: string) => void;
}

export function TaskWorkspaceContent(props: Props) {
  const { onAbandon } = props;
  const { loading, taskId, contentItem, formik, reviewTasks, lastAutosavedAt, onContentItemUpdate, task } =
    useTaskWorkspaceContent(props);
  return (
    <>
      <PageHeading>Content Task</PageHeading>
      <Grid templateColumns={{ base: "1fr", xl: "2fr 1fr" }} gridGap={4}>
        <Stack spacing={4} gridColumn={1}>
          <ReviewCommentsPanel reviewTasks={reviewTasks} />
          <form noValidate onSubmit={formik.handleSubmit}>
            <PanelGroup>
              <PanelTop>
                <Stack spacing={4}>
                  <FormControl isRequired isInvalid={!!formik.touched.title && !!formik.errors.title}>
                    <FormLabel>Title</FormLabel>
                    <Input
                      id="title"
                      value={formik.values.title}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formik.handleChange(e);
                        onContentItemUpdate(e.target.value, formik.values.content);
                      }}
                    />
                    <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired isInvalid={!!formik.touched.content && !!formik.errors.content}>
                    <FormLabel>Content</FormLabel>
                    {loading && <Skeleton height={50} />}
                    <ContentEditor
                      isInvalid={!!formik.touched.content && !!formik.errors.content}
                      initialValue={contentItem?.content ?? ""}
                      onChange={(html) => {
                        formik.setFieldValue("content", html);
                        if (html !== formik.values.content) {
                          onContentItemUpdate(formik.values.title, html);
                        }
                      }}
                    />
                    {lastAutosavedAt && (
                      <FormHelperText>Saved {DateTime.fromJSDate(lastAutosavedAt).toRelative()}</FormHelperText>
                    )}
                    <FormErrorMessage>{formik.errors.content}</FormErrorMessage>
                  </FormControl>
                </Stack>
              </PanelTop>
              <PanelBottom showTopBorder>
                <Stack isInline spacing={2} justifyContent="space-between" shouldWrapChildren>
                  <PrimaryButton type="submit">Complete Task</PrimaryButton>
                  <Box>
                    <DangerButton onClick={onAbandon}>Unassign Task</DangerButton>
                    <HelpText standardLeftMargin>
                      &quot;Unassign Task&quot; will unassign this task from you and return it to the queue.{" "}
                      <strong>This cannot be undone and may result in lost work.</strong>
                    </HelpText>
                  </Box>
                </Stack>
              </PanelBottom>
            </PanelGroup>
          </form>
        </Stack>
        <Stack spacing={4} gridColumn={{ base: 1, xl: 2 }}>
          <PanelGroup isCollapsible>
            <PanelTop showBottomBorder>
              <Heading size="md">Information</Heading>
            </PanelTop>
            <PanelBottom>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Client Instructions</FormLabel>
                  <Textarea value={task?.customer?.instructions ?? "-"} variant="outline" resize="none" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Task ID</FormLabel>
                  <Input value={taskId} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Client</FormLabel>
                  <Input value={task?.customer.name ?? "-"} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Product Type</FormLabel>
                  <Input value={task?.order.partnerProduct.product.name ?? "-"} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Keyword</FormLabel>
                  <Input value={task?.keyword ?? "-"} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight={700}>Landing Page URL</FormLabel>
                  <Input value={task?.landingPageUrl ?? task?.customer?.website ?? "-"} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Topic</FormLabel>
                  <Input value={task?.order.topic ?? "-"} variant="outline" isReadOnly />
                </FormControl>
              </Stack>
            </PanelBottom>
          </PanelGroup>
          <WorkflowNotes taskId={taskId} />
        </Stack>
      </Grid>
    </>
  );
}

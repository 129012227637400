import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useBilling_DeletePartnerPaymentMethodMutation, usePartnerPaymentMethodsQuery } from "../../generated/graphql";
import { handleGraphQLResponseError } from "../../util/error-helper";

interface Options {
  partnerId: number;
  onPaymentMethodAdded: () => void;
  onPaymentMethodRemoved: () => void;
}

export function usePaymentMethods(options: Options) {
  const { partnerId } = options;
  const [isAdding, setIsAdding] = useState(false);
  const [partnerPaymentMethodIdToRemove, setPartnerPaymentMethodIdToRemove] = useState<number | null>(null);
  const toast = useToast();
  const [deletePartnerPaymentMethodMutation] = useBilling_DeletePartnerPaymentMethodMutation();

  const queryResult = usePartnerPaymentMethodsQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId,
    },
  });

  const partnerPaymentMethods = queryResult.data?.partner?.partnerPaymentMethods.nodes ?? [];

  function handleAdd() {
    setIsAdding(true);
  }

  function handleClose() {
    setIsAdding(false);
  }

  function handleOnAdded() {
    setIsAdding(false);
    toast({
      title: "Payment Method Added",
      description: "The payment method was added successfully.",
      status: "success",
    });
    queryResult.refetch();
  }

  function handleRemove(partnerPaymentMethodId: number) {
    setPartnerPaymentMethodIdToRemove(partnerPaymentMethodId);
  }

  function handleConfirmRemoveClose() {
    setPartnerPaymentMethodIdToRemove(null);
  }

  async function handleConfirmRemove() {
    if (!partnerPaymentMethodIdToRemove) {
      return;
    }
    try {
      const response = await deletePartnerPaymentMethodMutation({
        variables: {
          input: { partnerPaymentMethodId: partnerPaymentMethodIdToRemove },
        },
      });

      if (!response.data?.deletePartnerPaymentMethod.ok) {
        throw new Error(
          response.data?.deletePartnerPaymentMethod.error?.message ??
            "Something went wrong while removing the payment method. Please try again."
        );
      }
    } catch (e: any) {
      handleGraphQLResponseError(e, toast);
    }
    queryResult.refetch();
    handleConfirmRemoveClose();
  }

  return {
    partnerId,
    isLoading: queryResult.loading,
    partnerPaymentMethods,
    handleAdd,
    handleClose,
    handleOnAdded,
    isAdding,
    partnerPaymentMethodIdToRemove,
    handleRemove,
    handleConfirmRemoveClose,
    handleConfirmRemove,
  };
}

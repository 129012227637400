import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PAGE_LIMIT } from "../../constants/constants";
import { useCustomerDetailConversationsQuery } from "../../generated/graphql";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { Box, Flex } from "@chakra-ui/react";
import ConversationsTable from "./customer-conversation-table";
import { getQueryParams, setParams } from "../../util/query-param-helper";
import queryString from "qs";
import { PrimaryButton } from "../../components/buttons";
import { AddConversationDrawer } from "./add-conversation-drawer";
interface Props {
  customerId: number;
}

export function CustomerMessages(props: Props) {
  const navigate = useNavigate();
  const queryParams = getQueryParams(location.search, queryString) || {};
  const [showNewMessage, setShowNewMessage] = useState(false);
  const { customerId } = props;

  let page = 0;
  if (queryParams && queryParams.page) {
    page = parseInt(queryParams.page, 10);
  }

  const queryResult = useCustomerDetailConversationsQuery({
    variables: {
      customerId,
      limit: PAGE_LIMIT,
      offset: page * PAGE_LIMIT,
      messageLimit: PAGE_LIMIT,
      messageOffset: 0,
    },
  });

  function onNewMessage() {
    setShowNewMessage(true);
  }

  function onClose() {
    setShowNewMessage(false);
  }

  function onMessageSent() {
    queryResult.refetch();
  }

  function onNextPage() {
    const { page, ...remainingQueryParams } = queryParams;
    const newPage = parseInt(page ?? "0", 10) + 1;
    const params = setParams(remainingQueryParams, "page", newPage.toString());
    navigate({ search: `?${params}` });
  }

  function onPreviousPage() {
    const { page, ...remainingQueryParams } = queryParams;
    const newPage = Math.max(parseInt(page ?? "0", 10) - 1, 0);
    const params = setParams(remainingQueryParams, "page", newPage.toString());
    navigate({ search: `?${params}` });
  }

  return (
    <>
      <PageHeading>Messages</PageHeading>
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Flex justifyContent="flex-end">
            <Box>
              <PrimaryButton onClick={onNewMessage}>New Message</PrimaryButton>
            </Box>
          </Flex>
        </PanelTop>
        <PanelBottom containsTable>
          <ConversationsTable
            customerId={customerId}
            queryResult={queryResult}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
          />
        </PanelBottom>
      </PanelGroup>
      <AddConversationDrawer
        customerId={customerId}
        isOpen={showNewMessage}
        onMessageSent={onMessageSent}
        onClose={onClose}
      />
    </>
  );
}

import { useState, useEffect } from "react";
import { InputProps, InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import AppIcon from "../app-icon";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export function SearchInput(props: InputProps) {
  const { onChange, value, ...rest } = props;
  const [inputValue, setInputValue] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState<any>(null);

  // watch for when `value` changes by the parent and reflect that here in local state
  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.persist();
    const value = event.target.value;

    setInputValue(value);

    if (!props.onChange) {
      return;
    }

    if (value.length > 1 || value === "") {
      clearTimeout(debounceTimeout);
      setDebounceTimeout(
        setTimeout(() => {
          onChange?.(event);
        }, 250)
      );
    }
  }

  return (
    <InputGroup minWidth="7em">
      <InputLeftElement>
        <AppIcon icon={faSearch} standardRightMargin />
      </InputLeftElement>
      <Input placeholder="Search" onChange={handleOnChange} value={inputValue} {...rest} />
    </InputGroup>
  );
}

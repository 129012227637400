import { Box, useTheme, BoxProps } from "@chakra-ui/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import AppIcon from "../../components/app-icon";

export function AlertInfo(props: BoxProps) {
  const { children, ...rest } = props;
  const theme = useTheme();
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      p={4}
      backgroundColor="#CEEDFF"
      borderLeft={`5px solid ${theme.colors.blue[400]}`}
      my={2}
      {...rest}
    >
      <AppIcon icon={faInfoCircle} color={theme.colors.blue[400]} standardRightMargin />
      {children}
    </Box>
  );
}

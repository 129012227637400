import { useState } from "react";
import {
  useQueryParam,
  NumberParam,
  StringParam,
  ArrayParam,
  withDefault,
  DelimitedNumericArrayParam,
} from "use-query-params";

import { useLinkLocationsQuery, useUpdateLinkLocationCategoriesMutation } from "../../generated/graphql";

const pageLimit = 50;

export function useAdminLinkLocations() {
  const [pageOffset, setPageOffset] = useQueryParam("pageOffset", withDefault(NumberParam, 0));
  const [search, setSearchParam] = useQueryParam("search", StringParam);
  const [statusIds, setStatusIds] = useQueryParam("statusIds", withDefault(ArrayParam, []));
  const [typeIds, setTypeIds] = useQueryParam("typeIds", withDefault(ArrayParam, []));
  const [categoryIds, setCategoryIds] = useQueryParam("categoryId", withDefault(DelimitedNumericArrayParam, []));

  const [isCreatingLinkLocation, setIsCreatingLinkLocation] = useState(false);

  const hasFilters = !!search || statusIds.length > 0 || typeIds.length > 0 || categoryIds.length > 0;

  const linkLocationsQueryResult = useLinkLocationsQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: pageLimit,
      offset: pageOffset,
      search,
      statusIds: statusIds as string[],
      typeIds: typeIds as string[],
      categoryIds: categoryIds as number[],
    },
  });

  const [updateLinkLocation] = useUpdateLinkLocationCategoriesMutation();

  function onAdd() {
    linkLocationsQueryResult.refetch();
    setIsCreatingLinkLocation(false);
  }

  function onAddClose() {
    setIsCreatingLinkLocation(false);
  }

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam(event.target.value);
  }

  function onStatusIdsChange(newStatusIds: string[]) {
    setStatusIds(newStatusIds);
  }

  function onTypeIdsChange(newTypeIds: string[]) {
    setTypeIds(newTypeIds);
  }

  function onCategoryIdsChange(categoryIds: number[]) {
    setCategoryIds(categoryIds);
  }

  function onNextPage() {
    setPageOffset(pageOffset + pageLimit);
  }

  function onPreviousPage() {
    setPageOffset(Math.max(pageOffset - pageLimit, 0));
  }

  function clearFilters() {
    setSearchParam("");
    setStatusIds([]);
    setTypeIds([]);
    setPageOffset(0);
    setCategoryIds([]);
  }

  async function updateLinkLocationCategories(linkLocationId: number, categoryIds: number[]) {
    const { data } = await updateLinkLocation({
      variables: {
        input: {
          linkLocationId,
          categoryIds,
        },
      },
    });

    if (data?.updateLinkLocationCategories.ok && linkLocationsQueryResult) {
      linkLocationsQueryResult.refetch();
    }
  }

  function onDownloadLinkLocations() {
    const statusIdsParam = statusIds.join(",");
    const typeIdsParam = typeIds.join(",");
    const categoryIdsParam = categoryIds.join(",");
    window.open(
      `${process.env.REACT_APP_API_BASE}/files/link-locations?statusIds=${statusIdsParam}&typeIds=${typeIdsParam}&categoryIds=${categoryIdsParam}`,
      "_blank"
    );
  }

  return {
    linkLocationsQueryResult,
    search,
    statusIds,
    typeIds,
    hasFilters,
    isCreatingLinkLocation,
    onAddClose,
    onAdd,
    setIsCreatingLinkLocation,
    onSearchChange,
    onStatusIdsChange,
    onTypeIdsChange,
    onNextPage,
    onPreviousPage,
    clearFilters,
    updateLinkLocationCategories,
    categoryIds,
    onCategoryIdsChange,
    onDownloadLinkLocations,
  };
}

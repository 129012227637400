import {
  Box,
  Heading,
  Stack,
  Text,
  useToast,
  Textarea,
  Input,
  FormHelperText,
  FormControl,
  FormLabel,
  Checkbox,
} from "@chakra-ui/react";
import { Navigate } from "react-router-dom";
import { DateTime } from "luxon";

import { CancelButton, PrimaryButton, DangerButton, SecondaryButton } from "../../../components/buttons";
import { ContentEditor } from "../../../components/content-editor";
import { Loader } from "../../../components/loader";
import { PanelGroup, PanelMiddle, PanelTop } from "../../../components/panels";
import { useProposalReview } from "./use-proposal-review";
import { TASK_REVIEW_STATUS } from "../../../constants/task-review-status";
import { TASK_STATUS } from "../../../constants/task-status";
import { currencyFormatter } from "../../../util/formatter";
import { DATE_MONTH_FORMAT } from "../../../constants/constants";

interface Props {
  taskId: number;
  completedAt?: Date;
  isDisabled?: boolean;
}

export function ProposalReview(props: Props) {
  const { isDisabled, completedAt } = props;

  const {
    loading,
    error,
    isSubmitting,
    isUpdating,
    onCancel,
    onChange,
    onRevise,
    onSubmit,
    task,
    rejectionComments,
    showRevise,
    showApprove,
    onApprove,
    onChangeUrl,
    resultUrl,
    validateResultUrl,
    onValidateUrlChange,
  } = useProposalReview(props);
  const toast = useToast();

  const isCompleted = task?.status.id === TASK_STATUS.COMPLETED.id;
  const cost = task?.order.targetLinkLocation?.cost ?? 0;

  if (loading) {
    return <Loader type="fullView" message="Loading Task" />;
  }

  if (error) {
    toast({
      title: "Error",
      description: error.message,
      status: "error",
      isClosable: true,
    });
    return <Navigate to="/" />;
  }

  return (
    <PanelGroup isCollapsible isCollapsedByDefault={isDisabled}>
      <PanelTop showBottomBorder>
        <Heading size="md" fontWeight="bold">
          Proposal Review
        </Heading>
        {!!completedAt && <Text>Completed At {DateTime.fromJSDate(completedAt).toFormat(DATE_MONTH_FORMAT)}</Text>}
      </PanelTop>
      <PanelMiddle>
        {!showRevise && !isCompleted && !showApprove && (
          <Stack isInline spacing={2}>
            <PrimaryButton onClick={onApprove}>Approve</PrimaryButton>
            <SecondaryButton onClick={onRevise}>Revise</SecondaryButton>
            <DangerButton onClick={onSubmit.bind(null, TASK_REVIEW_STATUS.REJECTED.id)}>Reject</DangerButton>
          </Stack>
        )}
        {showRevise && (
          <Box>
            <Box>
              <Textarea
                onChange={onChange}
                value={rejectionComments}
                resize="none"
                placeholder="Revision(s) you would like made to the content"
                autoFocus
              />
            </Box>
            <Box mt={2} textAlign="right">
              <Stack isInline spacing={2} justifyContent="flex-end">
                <PrimaryButton onClick={onSubmit.bind(null, TASK_REVIEW_STATUS.REVISED.id)} isLoading={isSubmitting}>
                  Submit
                </PrimaryButton>
                <CancelButton onClick={onCancel}>Cancel</CancelButton>
              </Stack>
            </Box>
          </Box>
        )}
        {showApprove && (
          <Box>
            <FormControl>
              <FormLabel>Result URL</FormLabel>
              <Input onChange={onChangeUrl} value={resultUrl} placeholder="Enter URL of post" autoFocus />
              <FormHelperText>Must begin with http:// or https://</FormHelperText>
            </FormControl>
            <FormControl>
              <Checkbox isChecked={validateResultUrl} onChange={onValidateUrlChange} id="validateResultUrl">
                Validate Result URL
              </Checkbox>
            </FormControl>
            <Box mt={2} textAlign="right">
              <Stack isInline spacing={2} justifyContent="flex-end">
                <PrimaryButton
                  onClick={onSubmit.bind(null, TASK_REVIEW_STATUS.APPROVED.id)}
                  isLoading={isSubmitting || isUpdating}
                >
                  Submit
                </PrimaryButton>
                <CancelButton onClick={onCancel}>Cancel</CancelButton>
              </Stack>
            </Box>
          </Box>
        )}
      </PanelMiddle>
      <PanelMiddle>
        <Text fontSize="sm">Target Result URL</Text>
        <Text fontSize="md">{task?.order?.targetResultUrl ?? "-"}</Text>
      </PanelMiddle>
      <PanelMiddle>
        <Text fontSize="sm">Publisher</Text>
        <Text fontSize="md">{task?.order?.targetLinkLocation?.publisherLinkLocation?.publisher?.name ?? "-"}</Text>
      </PanelMiddle>
      <PanelMiddle>
        <Text fontSize="sm">Paid to Publisher</Text>
        <Text fontSize="md">{currencyFormatter.format(cost)}</Text>
      </PanelMiddle>
      <PanelMiddle>
        <Text fontSize="sm">Title</Text>
        <Heading size="md" fontWeight="bold">
          {task?.reviewTargetTask?.contentItem?.title ?? ""}
        </Heading>
      </PanelMiddle>
      <PanelMiddle>
        <ContentEditor initialValue={task?.reviewTargetTask?.contentItem?.content ?? ""} height={300} readOnly />
      </PanelMiddle>
    </PanelGroup>
  );
}

import { useRef } from "react";
import {
  Flex,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialog,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { PageHeading } from "../../components/page-heading";
import { HelpText } from "../../components/help-text";
import { RoadmapBillingCycle } from "./roadmap-billing-cycle";
import { useClientDetailsRoadmap } from "./hooks/use-client-details-roadmap";
import { SecondaryButton, PrimaryButtonAppLink, CancelButton, DangerButton } from "../../components/buttons";
import AppIcon from "../../components/app-icon";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { EditOrderDrawer } from "../../common/edit-order-drawer";
import { AddOrderDrawer } from "../../common/add-order-drawer";
import { AlertInfo } from "../../components/alerts";
import { Loader } from "../../components/loader";
import { ForceDeleteOrderModal } from "../../common/force-delete-order-modal";
import { isBetween } from "../../util/datetime-helpers";
import { CAMPAIGN_CATEGORIES } from "../../constants/campaign-category";
import AddAmTaskDrawer from "../../common/add-am-task-drawer";
import EditAmTaskDrawer from "../../common/edit-am-task-drawer";

interface Props {
  customerId: number;
}

export function ClientDetailsRoadmap(props: Props) {
  const { customerId } = props;
  const {
    customerCampaignsLoading,
    customerCampaigns,
    selectedCustomerCampaign,
    selectCustomerCampaign,
    selectedCustomerCampaignBillingCycles,
    editOrder,
    orderIdBeingEdited,
    cancelEditOrder,
    onOrderEdited,
    deleteOrder,
    addOrder,
    cancelAddOrder,
    addOrderDateRange,
    onOrderAdded,
    customer,
    cancelForceDeleteRequest,
    orderIdForForceDelete,
    handleOrderForceDeleted,
    isSuperAdmin,
    isAdmin,
    addContentStrategy,
    addAmTaskDateRange,
    addAmTask,
    onAmTaskAdded,
    cancelAddAmTask,
    onEditAmTask,
    amTaskBeingEdited,
    onAmTaskEdited,
    onCancelEditAmTask,
    onDeleteAmTask,
    deleteAmTask,
    onCancelDeleteAmTask,
    amTaskForDelete,
  } = useClientDetailsRoadmap(customerId);

  const activeCancelRefTask = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <PageHeading>Roadmap</PageHeading>
          <HelpText standardLeftMargin>
            The Roadmap presents all the orders associated with a campaign and allows for management and scheduling of
            orders.
          </HelpText>
        </Flex>
        <Stack alignItems="flex-end" spacing={4} isInline>
          <Menu>
            <MenuButton>
              <SecondaryButton>
                {selectedCustomerCampaign?.name ?? "Select Campaign"}
                <AppIcon icon={faAngleDown} standardLeftMargin />
              </SecondaryButton>
            </MenuButton>
            <MenuList>
              {customerCampaigns.length === 0 && <MenuItem isDisabled>No campaigns.</MenuItem>}
              {customerCampaigns.map((customerCampaign) => (
                <MenuItem
                  key={customerCampaign.id}
                  onClick={() => {
                    selectCustomerCampaign(customerCampaign.id);
                  }}
                >
                  {customerCampaign.name}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Stack>
      </Flex>
      <Stack spacing={8}>
        {customerCampaignsLoading && <Loader type="panel" />}
        {!customerCampaignsLoading && customerCampaigns.length === 0 && (
          <AlertInfo>
            <Flex justifyContent="space-between" alignItems="center" width="100%">
              <Text>No campaigns have been created yet.</Text>
              <Text>
                <PrimaryButtonAppLink to={`/clients/${customerId}/campaigns`}>Go to Campaigns</PrimaryButtonAppLink>
              </Text>
            </Flex>
          </AlertInfo>
        )}
        {selectedCustomerCampaign &&
          selectedCustomerCampaignBillingCycles.map((billingCycle, index) => (
            <RoadmapBillingCycle
              key={billingCycle.startsAt.toISOString()}
              allowedAmount={selectedCustomerCampaign.amount}
              campaignName={selectedCustomerCampaign.name}
              endDate={billingCycle.endsAt}
              monthIndex={index}
              startDate={billingCycle.startsAt}
              orders={billingCycle.orders}
              amTasks={billingCycle.amTasks}
              onEditOrder={editOrder}
              onDeleteOrder={deleteOrder}
              onEditAmTask={onEditAmTask}
              amTaskForDelete={amTaskForDelete}
              onDeleteAmTask={onDeleteAmTask}
              deleteAmTask={deleteAmTask}
              onCancelDeleteAmTask={onCancelDeleteAmTask}
              customer={customer}
              customerCampaignInvoiceCharge={billingCycle.customerCampaignInvoiceCharge}
              onAddOrder={() => {
                const startsAt = isBetween(new Date(), billingCycle.startsAt, billingCycle.endsAt)
                  ? DateTime.fromJSDate(new Date()).plus({ days: 1 }).toJSDate()
                  : DateTime.fromJSDate(billingCycle.startsAt).plus({ days: 1 }).toJSDate();
                addOrder(startsAt, billingCycle.endsAt);
              }}
              isSuperAdmin={isSuperAdmin}
              isAdmin={isAdmin}
              addContentStrategy={addContentStrategy}
              onAddAmTask={() => {
                const startsAt = isBetween(new Date(), billingCycle.startsAt, billingCycle.endsAt)
                  ? DateTime.fromJSDate(new Date()).plus({ days: 1 }).toJSDate()
                  : DateTime.fromJSDate(billingCycle.startsAt).plus({ days: 1 }).toJSDate();
                addAmTask(startsAt, billingCycle.endsAt);
              }}
            />
          ))}
      </Stack>
      <AddOrderDrawer
        customerId={customerId}
        customerCampaignId={selectedCustomerCampaign?.id ?? 0}
        campaignCategoryId={CAMPAIGN_CATEGORIES.SEO.id}
        onAdded={onOrderAdded}
        onCancel={cancelAddOrder}
        minimumDate={addOrderDateRange?.[0] ?? undefined}
        maximumDate={addOrderDateRange?.[1] ?? undefined}
        open={!!addOrderDateRange}
      />
      <EditOrderDrawer
        open={!!orderIdBeingEdited}
        onCancel={cancelEditOrder}
        orderId={orderIdBeingEdited ?? 0}
        onEdited={onOrderEdited}
      />
      <ForceDeleteOrderModal
        orderId={orderIdForForceDelete}
        onCancel={cancelForceDeleteRequest}
        onDeleted={handleOrderForceDeleted}
      />
      <AddAmTaskDrawer
        open={!!addAmTaskDateRange}
        customerId={customerId}
        onCancel={cancelAddAmTask}
        onAdded={onAmTaskAdded}
        minimumDate={addAmTaskDateRange?.[0]}
        maximumDate={addAmTaskDateRange?.[1]}
      />
      <EditAmTaskDrawer
        open={!!amTaskBeingEdited}
        amTaskId={amTaskBeingEdited ?? 0}
        onCancel={onCancelEditAmTask}
        onEdited={onAmTaskEdited}
      />
      <AlertDialog isOpen={!!amTaskForDelete} onClose={onCancelDeleteAmTask} leastDestructiveRef={activeCancelRefTask}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Confirm Delete Am Task</AlertDialogHeader>
          <AlertDialogBody>{`Are you sure you want to delete this Am Task (ID: ${amTaskForDelete})?`}</AlertDialogBody>
          <AlertDialogFooter>
            <CancelButton onClick={onCancelDeleteAmTask} ref={activeCancelRefTask}>
              Cancel
            </CancelButton>
            <DangerButton ml={3} onClick={deleteAmTask}>
              Delete
            </DangerButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

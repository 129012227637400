import React from "react";

export function useMediaQuery(queryInput: string) {
  const query = queryInput.replace(/^@media( ?)/m, "");
  const [match, setMatch] = React.useState(false);

  React.useEffect(() => {
    let active = true;
    const queryList = window.matchMedia(query);
    const updateMatch = () => {
      if (active) {
        setMatch(queryList.matches);
      }
    };
    updateMatch();
    queryList.addListener(updateMatch);
    return () => {
      active = false;
      queryList.removeListener(updateMatch);
    };
  }, [query]);

  return match;
}

import { Heading } from "@chakra-ui/react";

import { OrderBillingStatusIndicator } from "../../../common/order-billing-status-indicator";
import AppLink from "../../../components/app-link";
import { FormattedDate } from "../../../components/formatted-date";
import { PanelBottom, PanelGroup, PanelTop } from "../../../components/panels";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../../components/table";
import { DATE_MONTH_FORMAT } from "../../../constants/constants";
import { currencyFormatter } from "../../../util/formatter";
import { useOrderDetailsInvoice } from "./invoice-details-hook";

interface Props {
  orderId: number;
}

export function InvoiceDetails(props: Props) {
  const { loading, order, partnerInvoiceCharges } = useOrderDetailsInvoice(props.orderId);

  if (!order) {
    return null;
  }

  return (
    <PanelGroup>
      <PanelTop showBottomBorder>
        <Heading size="md">Billing Details</Heading>
        <OrderBillingStatusIndicator
          billingStatus={order?.billingStatus}
          startDate={order.startDate}
          isEnforced={order.customer.partner.isBillingEnforced}
        />
      </PanelTop>
      <PanelBottom containsTable>
        <Table
          isEmpty={partnerInvoiceCharges.length === 0}
          isLoading={loading}
          emptyText="No charges have been processed for this order."
        >
          <TableHead>
            <TableRow>
              <TableHeaderCell>Date</TableHeaderCell>
              <TableHeaderCell>Invoice ID</TableHeaderCell>
              <TableHeaderCell>Amount</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partnerInvoiceCharges.map((charge) => (
              <TableRow key={charge.id}>
                <TableCell>
                  <FormattedDate format={DATE_MONTH_FORMAT} date={charge.createdAt} />
                </TableCell>
                <TableCell>
                  <AppLink to={`/billing/${charge.partnerId}/invoices/${charge.partnerInvoiceId}`}>
                    {charge.partnerInvoiceId}
                  </AppLink>
                </TableCell>
                <TableCell>{currencyFormatter.format(charge.amount)}</TableCell>
                <TableCell>{charge.status?.name ?? "-"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PanelBottom>
    </PanelGroup>
  );
}

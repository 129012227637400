import { SecondaryNav, SecondaryNavLink } from "../../components/layout";
import { usePublisherDetailsSecondaryNavQuery } from "../../generated/graphql";

interface Props {
  publisherId: number;
  activeSection?: string;
}

const NAV_ITEMS = [
  {
    section: "overview",
    displayName: "Overview",
  },
  {
    section: "report",
    displayName: "Report",
  },
  {
    section: "users",
    displayName: "Users",
  },
  {
    section: "emails",
    displayName: "Emails",
  },
];

export function PublisherDetailsSecondaryNav(props: Props) {
  const { publisherId, activeSection } = props;
  const publisherDetailsSecondaryNavQueryResult = usePublisherDetailsSecondaryNavQuery({
    variables: {
      publisherId: publisherId,
    },
  });
  return (
    <SecondaryNav title={publisherDetailsSecondaryNavQueryResult.data?.publisher?.name ?? "Publisher"}>
      {NAV_ITEMS.map((item) => (
        <SecondaryNavLink
          key={item.section}
          isActive={activeSection === item.section}
          to={`/outreach/publishers/${publisherId}/${item.section}`}
        >
          {item.displayName}
        </SecondaryNavLink>
      ))}
    </SecondaryNav>
  );
}

import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import { faBan, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { ConfirmDangerModal } from "../../common/confirm-danger-modal";
import { CustomMultiSelect } from "../../common/custom-selector";
import { TaskStatusIndicator } from "../../common/task-status-indicator";
import { TaskTypeMultiSelect } from "../../common/task-type-selector-v2";
import { VendorMultiSelect } from "../../common/vendor-selector";
import { AdminBadge } from "../../components/admin-badge";
import { AlertWarning } from "../../components/alerts";
import AppIcon from "../../components/app-icon";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import {
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TablePagination,
  TableRow,
} from "../../components/table";
import { TruncatedText } from "../../components/truncated-text";
import { DATE_PICKER_FORMAT, DATE_TIME_FORMAT } from "../../constants/constants";
import { TASK_STATUS } from "../../constants/task-status";
import { useAdminTasks } from "./admin-tasks-hook";
import { ChangeVendorModal } from "./change-vendor-modal";
import { UserAutoComplete } from "../../common/user-auto-complete";

export function AdminTasksRoute() {
  const {
    loading,
    tasks,
    handleStatusIdsChange,
    statusIds,
    filterCount,
    clearFilters,
    pagination,
    totalCount,
    handleVendorIdsChange,
    vendorIds,
    taskTypeIds,
    handleTaskTypeIdsChange,
    handleSearchChange,
    search,
    handleUnassignCancel,
    handleUnassignConfirm,
    handleUnassignTask,
    isUpdatingTask,
    taskIdToUnassign,
    handleChangeTaskVendor,
    handleChangeTaskVendorCancel,
    handleChangeTaskVendorConfirm,
    taskForVendorChange,
    onRangeStartChange,
    onRangeEndChange,
    startsAt,
    endsAt,
    onUserSelected,
    userId,
  } = useAdminTasks();
  return (
    <LayoutBase pageTitle="Tasks (Admin)">
      <MainNav />
      <Container centerHorizontally>
        <Stack spacing={2} marginY={4}>
          <PageHeading preventMarginY>Tasks</PageHeading>
          <Box>
            <AdminBadge />
          </Box>
        </Stack>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between" flexDirection="column">
              <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end" shouldWrapChildren>
                <FormControl>
                  <FormLabel fontSize="sm">Search (Task ID)</FormLabel>
                  <Input value={search} onChange={handleSearchChange} minW={150} />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm">Status</FormLabel>
                  <CustomMultiSelect
                    items={Object.entries(TASK_STATUS).map((taskStatus) => ({
                      displayText: taskStatus[1].name,
                      value: taskStatus[1].id,
                    }))}
                    onChange={handleStatusIdsChange}
                    value={statusIds}
                    emptyValueTitle="All"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Vendor</FormLabel>
                  <VendorMultiSelect onChange={handleVendorIdsChange} selectedVendorIds={vendorIds} />
                </FormControl>
                <FormControl>
                  <FormLabel>Task Type</FormLabel>
                  <TaskTypeMultiSelect selectedTaskTypeIds={taskTypeIds} onChange={handleTaskTypeIdsChange} />
                </FormControl>
                <FormControl position="static">
                  <FormLabel fontSize="sm">Assigned Date</FormLabel>
                  <HStack zIndex={400}>
                    <DatePicker
                      onChange={onRangeStartChange}
                      selected={startsAt}
                      dateFormat={DATE_PICKER_FORMAT}
                      customInput={<Input variant="outline" />}
                      disabledKeyboardNavigation
                      placeholderText="Select range start date"
                    />
                    <DatePicker
                      onChange={onRangeEndChange}
                      selected={endsAt}
                      dateFormat={DATE_PICKER_FORMAT}
                      minDate={startsAt}
                      customInput={<Input variant="outline" />}
                      disabledKeyboardNavigation
                      placeholderText="Select range end date"
                    />
                  </HStack>
                </FormControl>
                <FormControl position="relative">
                  <FormLabel fontSize="xs">Users</FormLabel>
                  <UserAutoComplete selectedUserId={userId} onUserChanged={onUserSelected} isClearable={true} />
                </FormControl>
                {filterCount > 0 && (
                  <Box>
                    <Button onClick={clearFilters}>
                      <AppIcon icon={faBan} standardRightMargin />
                      Clear Filters
                    </Button>
                  </Box>
                )}
              </HStack>
              <Box marginTop={4}>
                <Stat>
                  <StatLabel>Tasks Found</StatLabel>
                  <StatNumber>{totalCount}</StatNumber>
                </Stat>
              </Box>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <Table isEmpty={tasks.length === 0} isLoading={loading}>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>ID</TableHeaderCell>
                  <TableHeaderCell>Type</TableHeaderCell>
                  <TableHeaderCell>Client</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell>Vendor</TableHeaderCell>
                  <TableHeaderCell>Dates</TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks.map((task) => {
                  return (
                    <TableRow key={task.id}>
                      <TableCell>{task.id}</TableCell>
                      <TableCell>
                        <Stack spacing={1}>
                          <Text>{task.taskType.name}</Text>
                          <Text fontSize="sm">{task.product.name}</Text>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack spacing={1}>
                          <Text>{task.customer.name}</Text>
                          <Text fontSize="sm">Order {task.order.id}</Text>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <TaskStatusIndicator status={task.status} assignedUser={task.assignedUser} />
                      </TableCell>
                      <TableCell>
                        <TruncatedText maxLength={30}>{task.vendor?.name ?? "-"}</TruncatedText>
                      </TableCell>
                      <TableCell>
                        <Stack spacing={1}>
                          <Text fontSize="sm">
                            Created: {DateTime.fromJSDate(task.createdAt).toFormat(DATE_TIME_FORMAT)}
                          </Text>
                          <Text fontSize="sm">
                            Available: {DateTime.fromJSDate(task.availableAt).toFormat(DATE_TIME_FORMAT)}
                          </Text>
                          {!!task.assignedAt && (
                            <Text fontSize="sm">
                              Assigned: {DateTime.fromJSDate(task.assignedAt).toFormat(DATE_TIME_FORMAT)}
                            </Text>
                          )}
                          {!!task.completedAt && (
                            <Text fontSize="sm">
                              Completed: {DateTime.fromJSDate(task.completedAt).toFormat(DATE_TIME_FORMAT)}
                            </Text>
                          )}
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Menu>
                          <MenuButton as={Button}>
                            <AppIcon icon={faEllipsisH} />
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                handleUnassignTask(task.id);
                              }}
                            >
                              Unassign
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleChangeTaskVendor(task);
                              }}
                            >
                              Change Vendor
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFoot>
                <TablePagination {...pagination} totalCount={totalCount} />
              </TableFoot>
            </Table>
          </PanelBottom>
        </PanelGroup>
      </Container>
      <ConfirmDangerModal
        dangerButtonText="Unassign"
        isOpen={!!taskIdToUnassign}
        isProcessing={isUpdatingTask}
        onCancel={handleUnassignCancel}
        onConfirm={handleUnassignConfirm}
        title="Confirm Unassign"
      >
        <Text>
          Are you sure you want to unassign <strong>Task #{taskIdToUnassign}</strong>?
        </Text>
        <AlertWarning>
          <strong>This cannot be undone.</strong>
        </AlertWarning>
      </ConfirmDangerModal>
      <ChangeVendorModal
        isOpen={!!taskForVendorChange}
        isProcessing={isUpdatingTask}
        onCancel={handleChangeTaskVendorCancel}
        onConfirm={handleChangeTaskVendorConfirm}
        task={taskForVendorChange}
      />
    </LayoutBase>
  );
}

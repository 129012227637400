import * as yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";

interface Options {
  noOfChecklistItems: number;
  onComplete: (isApproved?: boolean, reviewComment?: string) => void;
  isDisabled?: boolean;
}

interface FormValues {
  isApproved: boolean;
  comments: string;
}

const validationSchema = yup.object().shape({
  isApproved: yup.bool().label("Is Approved").required().nullable(false),
  comments: yup.string().when("isApproved", {
    is: true,
    then: yup.string().label("Comments").notRequired().nullable(true),
    otherwise: yup.string().label("Comments").required().nullable(false).min(1),
  }),
});

export function useChecklistTask(options: Options) {
  const { noOfChecklistItems, onComplete, isDisabled } = options;

  const [isChecked, setIsChecked] = useState<boolean[]>(new Array(noOfChecklistItems).fill(!!isDisabled));

  const canSubmit = !isChecked.includes(false);

  function onCheckClick(i: number) {
    isChecked[i] = !isChecked[i];
    setIsChecked([...isChecked]);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function onSubmit(values: FormValues, _helpers: any) {
    onComplete(values.isApproved, values.comments);
    isChecked.fill(false);
    setIsChecked([...isChecked]);
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      isApproved: true,
      comments: "",
    },
    validationSchema,
    onSubmit,
  });

  return {
    isChecked,
    onCheckClick,
    formik,
    canSubmit,
  };
}

import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  useSaveTaskContentItemMutation,
  useContentReviewTaskWorkspaceQuery,
  File,
  useCreateFeedbackContentItemMutation,
  useUpdateFeedbackContentItemMutation,
} from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { UploadFile } from "../upload-file-modal/use-upload-file-modal";

type ServerFile = Pick<File, "id" | "name" | "url">;

interface Options {
  taskId: number;
  onComplete: (isApproved?: boolean, reviewComment?: string) => void;
}

interface FormValues {
  title: string;
  content: string;
  isApproved: boolean;
  comments: string;
  file?: ServerFile | null;
}

interface FeedbackFormValues {
  rating: number;
  feedback: string;
}

const validationSchema = yup.object().shape({
  title: yup.string().label("Title").required().nullable(false).min(1),
  content: yup.string().label("Content").required().nullable(false).min(1),
  isApproved: yup.bool().label("Is Approved").required().nullable(false),
  comments: yup.string().when("isApproved", {
    is: true,
    then: yup.string().label("Comments").notRequired().nullable(true),
    otherwise: yup.string().label("Comments").required().nullable(false).min(1),
  }),
});

const feedbackValidationSchema = yup.object().shape({
  rating: yup.number().label("Rating").min(0).max(5).required().nullable(false),
  feedback: yup.string().label("Feedback").notRequired().nullable(true),
});

export function useTaskWorkspaceContentReview(options: Options) {
  const { onComplete, taskId } = options;
  const [showThumbnailUpload, setShowThumbnailUpload] = useState(false);
  const taskWorkspaceContentReviewQueryResult = useContentReviewTaskWorkspaceQuery({
    fetchPolicy: "no-cache",
    variables: {
      taskId,
    },
  });
  const [saveTaskContentItemMutation] = useSaveTaskContentItemMutation();
  const [createFeedbackContentItemMutation] = useCreateFeedbackContentItemMutation();
  const [updateFeedbackContentItemMutation] = useUpdateFeedbackContentItemMutation();
  const toast = useToast();

  const task = taskWorkspaceContentReviewQueryResult.data?.task ?? null;
  const reviewTargetTask = task?.reviewTargetTask ?? null;
  const contentItem = task?.reviewTargetTask?.contentItem ?? null;
  const reviewTasks = (task?.reviewTargetTask?.reviewTasks.nodes ?? []).filter(
    (reviewTask) => !!reviewTask.reviewComment
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleSaveTask(values: FormValues, _helpers: any) {
    if (!reviewTargetTask) {
      toast({ title: "Unable to Complete Task", status: "error" });
      return;
    }
    try {
      await saveTaskContentItemMutation({
        variables: {
          input: {
            content: values.content,
            taskId: reviewTargetTask.id,
            title: values.title,
            thumbnailS3FileId: values.file?.id ?? null,
            ignoreContentTaskPermission: true,
          },
        },
      });
      if (
        values.isApproved &&
        (feedbackContentItemFormik.values.rating > 0 || feedbackContentItemFormik.values.feedback)
      ) {
        feedbackContentItemFormik.handleSubmit();
      }
      onComplete(values.isApproved, values.comments);
    } catch (e: any) {
      toast({ title: "Unable to Complete Task", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      content: contentItem?.content ?? "",
      title: contentItem?.title ?? "",
      file: contentItem?.thumbnailFile,
      isApproved: true,
      comments: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSaveTask,
  });

  function onThumbnailUpload() {
    setShowThumbnailUpload(true);
  }

  function onThumbnailUploadCancel() {
    setShowThumbnailUpload(false);
  }

  function onThumbnailUploaded(fileId: string | null, file?: UploadFile) {
    if (fileId && file) {
      formik.setFieldValue("file", { id: fileId, url: file.url, name: file.name });
    }
    setShowThumbnailUpload(false);
  }

  function onThumbnailRemove() {
    formik.setFieldValue("file", null);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleSubmitFeedback(values: FeedbackFormValues, _helpers: any) {
    if (!reviewTargetTask) {
      toast({ title: "Unable to Complete Task", status: "error" });
      return;
    }
    try {
      if (contentItem?.feedbackContentItems && contentItem?.feedbackContentItems.length > 0) {
        await updateFeedbackContentItemMutation({
          variables: {
            input: {
              feedbackContentItemId: contentItem?.feedbackContentItems[0].id ?? 0,
              rating: values.rating,
              feedback: values.feedback,
            },
          },
        });
      } else {
        await createFeedbackContentItemMutation({
          variables: {
            input: {
              feedback: values.feedback,
              rating: values.rating,
              contentItemId: contentItem?.id ?? 0,
            },
          },
        });
      }
    } catch (e: any) {
      toast({ title: "Unable to Log Feedback for Task", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const feedbackContentItemFormik = useFormik<FeedbackFormValues>({
    initialValues: {
      rating: contentItem?.feedbackContentItems?.[0]?.rating ?? 0,
      feedback: contentItem?.feedbackContentItems?.[0]?.feedback ?? "",
    },
    enableReinitialize: true,
    validationSchema: feedbackValidationSchema,
    onSubmit: handleSubmitFeedback,
  });

  return {
    taskId,
    loading: taskWorkspaceContentReviewQueryResult.loading,
    reviewTargetTask,
    contentItem,
    formik,
    task,
    reviewTasks,
    showThumbnailUpload,
    onThumbnailUpload,
    onThumbnailUploaded,
    onThumbnailUploadCancel,
    onThumbnailRemove,
    feedbackContentItemFormik,
  };
}

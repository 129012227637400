import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useCreateBulkProposedCustomerKeywordsMutation } from "../../generated/graphql";
import { PrimaryButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";
import { AppColors } from "../../core/custom-theme";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  customerKeywordProposalId: number;
  refetchKeywords: () => void;
}
function AddMultipleKeywords(props: Props) {
  const { isOpen, onClose, customerKeywordProposalId, refetchKeywords } = props;
  const [keywords, setKeywords] = useState("");
  const [createBulkProposedKeywords, { loading }] = useCreateBulkProposedCustomerKeywordsMutation();
  const toast = useToast();

  async function onSend() {
    try {
      if (!keywords) {
        return;
      }
      const keywordsData = new Set(
        keywords
          .replace(/\n/g, ",")
          .split(",")
          .map((item) => item.trim())
      );
      await createBulkProposedKeywords({
        variables: {
          input: {
            customerKeywordProposalId,
            keywords: [...keywordsData],
          },
        },
      });
      refetchKeywords();
      handleClose();
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  function handleClose() {
    setKeywords("");
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="xl">Add Multiple Keywords</Text>
          <Text fontSize="sm" color={AppColors.silver}>
            Add multiple keywords separated by a comma or line.
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Textarea
            rows={6}
            height="auto"
            value={keywords}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setKeywords(event.target.value)}
            my={1}
            size="md"
            autoFocus
          />
        </ModalBody>
        <ModalFooter>
          <PrimaryButton
            size="sm"
            variant="solid"
            isDisabled={loading || keywords.trim().length === 0}
            onClick={onSend}
          >
            Add All Keywords
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddMultipleKeywords;

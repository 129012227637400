import { ChecklistTaskWorkspace_ChecklistItemFragment, useUpdateChecklistItemMutation } from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import { getDisplayMessageForError } from "../../util/error-helper";

interface Options {
  checklistItem: ChecklistTaskWorkspace_ChecklistItemFragment;
  onEdited: () => void;
}

export function useChecklistItem(options: Options) {
  const { checklistItem, onEdited } = options;
  const [updateChecklistItemMutation] = useUpdateChecklistItemMutation();
  const toast = useToast();

  async function onCheckClick() {
    try {
      await updateChecklistItemMutation({
        variables: {
          input: {
            checklistItemId: checklistItem.id,
            completedAt: checklistItem.completedAt ? null : new Date(),
          },
        },
      });
      onEdited();
    } catch (e: any) {
      toast({ title: "Unable to update Checklist Item", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  return {
    onCheckClick,
  };
}

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
  TablePagination,
  TableFoot,
} from "../../components/table";
import { VendorsQueryResult } from "../../generated/graphql";
import { PAGE_LIMIT } from "../../constants/constants";
import { Box, Text, Tooltip } from "@chakra-ui/react";
import { PrimaryIconButton } from "../../components/buttons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import AppLink from "../../components/app-link";
import { TruncatedText } from "../../components/truncated-text";
import { useAccessiblePartnerCount } from "../../common/use-accessible-partner-count";

interface Props {
  vendorsQueryResult: VendorsQueryResult;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onEditVendor: (vendorId: number) => void;
}

export function VendorsTable(props: Props) {
  const { vendorsQueryResult, onPreviousPage, onNextPage, onEditVendor } = props;
  const totalCount = vendorsQueryResult.data?.vendors?.totalCount ?? 0;
  const vendors = vendorsQueryResult.data?.vendors.nodes ?? [];
  const accessiblePartnerCount = useAccessiblePartnerCount();

  return (
    <Table isEmpty={vendors.length === 0} isLoading={vendorsQueryResult.loading}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {vendors.map((vendor) => (
          <TableRow key={vendor.id}>
            <TableCell>
              <AppLink to={`/vendors/${vendor.id}`}>{vendor.name}</AppLink>
              {accessiblePartnerCount > 1 && !!vendor.partner && (
                <TruncatedText maxLength={30} fontSize="sm">
                  {vendor.partner.name}
                </TruncatedText>
              )}
              {!vendor.partner && <Text fontSize="sm">SYSTEM {vendor.isExternal && "(external)"}</Text>}
            </TableCell>
            <TableCell>
              <Box textAlign="right">
                <Tooltip title="Edit" aria-label="Edit">
                  <PrimaryIconButton
                    onClick={onEditVendor.bind(null, vendor.id)}
                    icon={faPen}
                    actionName="Edit Vendor"
                  />
                </Tooltip>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFoot>
        <TablePagination
          totalCount={totalCount}
          offset={vendorsQueryResult.variables?.offset ?? 0}
          limit={vendorsQueryResult.variables?.limit ?? PAGE_LIMIT}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
      </TableFoot>
    </Table>
  );
}

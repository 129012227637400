import { useToast } from "@chakra-ui/react";
import { useContentStrategyV2TaskWorkspaceQuery } from "../../generated/graphql";

interface Options {
  taskId: number;
  onAbandon: () => void;
  onComplete: (isApproved?: boolean, reviewComment?: string) => void;
}

export function useContentStrategyV2(options: Options) {
  const { taskId, onComplete } = options;

  const queryResult = useContentStrategyV2TaskWorkspaceQuery({
    fetchPolicy: "network-only",
    variables: {
      taskId,
    },
  });
  const toast = useToast();

  const task = queryResult.data?.task ?? null;
  const customer = task?.customer ?? null;
  const customerQuestionnaire = customer?.customerQuestionnaire ?? null;
  const customerProposal = task?.customerProposal ?? null;
  const orderContentStrategies = customerProposal?.orderContentStrategies ?? [];
  const reviewTasks = (task?.reviewTargetTask?.reviewTasks.nodes ?? []).filter(
    (reviewTask) => !!reviewTask.reviewComment
  );

  const canSubmit = orderContentStrategies.every((x) => x.isComplete);

  function handleComplete(isApproved?: boolean, reviewComment?: string) {
    if (!canSubmit) {
      toast({
        title: "Unable to Submit for Review",
        description: "All content strategies must be complete in order to submit for review.",
        status: "error",
      });
      return;
    }
    onComplete(isApproved, reviewComment);
  }

  return {
    isLoading: queryResult.loading,
    task,
    customer,
    customerQuestionnaire,
    customerProposal,
    orderContentStrategies,
    reviewTasks,
    canSubmit,
    handleComplete,
    refetch: queryResult.refetch,
  };
}

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { CancelButton, PrimaryButton } from "../../components/buttons";
import { PartnerSelector } from "../partner-selector";
import { useAddPartnerPackage } from "./use-add-partner-package";

interface Props {
  partnerId: number;
  isOpen: boolean;
  onClose: () => void;
  onAdded: (partnerPackageId: number) => void;
}

export function AddPartnerPackageDrawer(props: Props) {
  const { isOpen, ...options } = props;
  const { formik, onCancel } = useAddPartnerPackage(options);

  return (
    <Drawer isOpen={isOpen} onClose={onCancel} placement="right" size="sm" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <form onSubmit={formik.handleSubmit}>
          <DrawerCloseButton />
          <DrawerHeader>Add Package</DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Partner</FormLabel>
                <PartnerSelector
                  variant="outline"
                  onPartnerSelected={() => {
                    // no-op
                  }}
                  isDisabled
                  selectedPartnerId={options.partnerId}
                  allowNull={false}
                />
              </FormControl>
              <FormControl isRequired isInvalid={!!formik.errors.name && !!formik.touched.name}>
                <FormLabel>Name</FormLabel>
                <Input
                  autoFocus
                  variant="outline"
                  value={formik.values.name}
                  id="name"
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!formik.errors.months && !!formik.touched.months}>
                <FormLabel>Months</FormLabel>
                <Select
                  variant="outline"
                  value={formik.values.months}
                  id="months"
                  onChange={(e) => {
                    formik.setFieldValue("months", parseInt(e.target.value, 10));
                  }}
                >
                  {[...Array(12).keys()].map((item) => (
                    <option key={item + 1} value={item + 1}>
                      {item + 1}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{formik.errors.months}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!formik.errors.monthlySpend && !!formik.touched.monthlySpend}>
                <FormLabel>Monthly Spend</FormLabel>
                <Input
                  type="number"
                  variant="outline"
                  value={formik.values.monthlySpend}
                  id="monthlySpend"
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.monthlySpend}</FormErrorMessage>
              </FormControl>
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <SimpleGrid columns={2} gridGap={4} width="100%">
              <PrimaryButton width="100%" type="submit">
                Save
              </PrimaryButton>
              <CancelButton onClick={onCancel}>Cancel</CancelButton>
            </SimpleGrid>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
}

import { useState } from "react";
import { ImportInvoicesResult, useAdmin_ImportInvoicesMutation } from "../../generated/graphql";

type ResultsType = Pick<ImportInvoicesResult, "ok" | "orderId" | "message">[];

export function useAdminImportInvoices() {
  const [input, setInput] = useState("");
  const [results, setResults] = useState<ResultsType>([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [importInvoicesMutation, { loading }] = useAdmin_ImportInvoicesMutation();

  function handleInputChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setInput(e.target.value);
  }

  async function handleStartImport() {
    setErrorMessage("");
    setResults([]);
    const result = await importInvoicesMutation({
      variables: {
        input: {
          content: input,
        },
      },
    });

    if (
      result.data?.importInvoices.ok &&
      result.data?.importInvoices.results &&
      result.data.importInvoices.results.length > 0
    ) {
      setResults(result.data.importInvoices.results);
    } else if (result.data?.importInvoices.error?.message) {
      setErrorMessage(result.data.importInvoices.error.message);
    }
  }

  return {
    input,
    handleInputChange,
    handleStartImport,
    loading,
    results,
    errorMessage,
  };
}

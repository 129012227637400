import { ButtonProps, Button, Tooltip } from "@chakra-ui/react";
import Color from "color";
import { AppColors } from "../../core/custom-theme";
import AppIcon from "../app-icon";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

interface Props extends Omit<ButtonProps, "children"> {
  icon: IconProp;
  actionName: string;
  iconSize?: SizeProp;
}

export function DangerIconButton(props: Props) {
  const { icon, actionName, iconSize, ...rest } = props;
  return (
    <Tooltip aria-label={actionName} label={actionName}>
      <Button
        variant="ghost"
        color={AppColors.error}
        _hover={{
          color: Color(AppColors.error).darken(0.2).hex(),
        }}
        _active={{
          color: Color(AppColors.error).darken(0.3).hex(),
        }}
        fontSize="14px"
        size="sm"
        {...rest}
      >
        <AppIcon icon={icon} size={iconSize} />
      </Button>
    </Tooltip>
  );
}

import { GraphQLError } from "graphql";
import { ApolloError } from "@apollo/client";
import { UseToastOptions } from "@chakra-ui/react";
import { FormikErrors } from "formik";
import { ValidationError } from "yup";
import { isEmpty } from "lodash";

export function getDisplayMessageForError(err: Error | GraphQLError): string {
  console.dir(err);
  const errorMessages: string[] = [];

  if ((err as any).extensions) {
    const graphQLError = err as GraphQLError;
    const validationErrors = (graphQLError.extensions?.exception as any)?.errors;
    if (validationErrors && validationErrors.length > 0) {
      errorMessages.push(...validationErrors);
    } else if (graphQLError.message) {
      errorMessages.push(graphQLError.message);
    }
  } else if ((err as any).graphQLErrors) {
    const graphQLError = err as ApolloError;
    const validationErrors = (graphQLError.graphQLErrors[0]?.extensions?.exception as any)?.errors;
    if (validationErrors && validationErrors.length > 0) {
      errorMessages.push(...validationErrors);
    } else if (graphQLError.graphQLErrors[0]?.message) {
      errorMessages.push(graphQLError.graphQLErrors[0].message);
    }
  }

  if (errorMessages.length === 0) {
    return err.message;
  } else {
    return errorMessages.join(" and ");
  }
}

export function getDisplayMessageForErrors(errors: (Error | GraphQLError)[]): string {
  return errors.map(getDisplayMessageForError).join(" and ");
}

function getValidationErrorMessages(error: ApolloError): { [field: string]: string } {
  const fieldValidationErrors: { [field: string]: string } = {};
  error.graphQLErrors.forEach((error) => {
    const validationErrors =
      (error.extensions?.exception as any)?.inner?.filter((x: Error) => x.name === "ValidationError") ?? [];
    validationErrors.forEach((err: ValidationError) => {
      fieldValidationErrors[err.path ?? err.value] = err.message;
    });
  });
  return fieldValidationErrors;
}

export function handleFormGraphQLError(
  error: Error | ApolloError,
  toastErrorTitle: string,
  toast: (props: UseToastOptions) => void,
  formikSetErrors?: (errors: FormikErrors<unknown>) => void
) {
  if ((error as ApolloError).graphQLErrors) {
    if (formikSetErrors !== undefined) {
      const validationFields = getValidationErrorMessages(error as ApolloError);
      if (!isEmpty(validationFields)) {
        formikSetErrors(validationFields);
        toast({
          title: toastErrorTitle,
          description: `Please fix the invalid fields and try again.`,
          status: "error",
        });
        return;
      }
    }

    toast({
      title: toastErrorTitle,
      description: getDisplayMessageForError(error),
      status: "error",
    });
    return;
  }

  toast({
    title: toastErrorTitle,
    description: getDisplayMessageForError(error),
    status: "error",
  });
}

interface GraphQLResponseError {
  message: string;
  inputFieldErrors: GraphQLResponseInputFieldError[];
}

interface GraphQLResponseInputFieldError {
  fieldName: string;
  message: string;
}

export function handleGraphQLResponseError(
  responseError: GraphQLResponseError,
  toast: (props: UseToastOptions) => void,
  formikSetErrors?: (errors: FormikErrors<unknown>) => void
): void {
  if (!formikSetErrors || responseError.inputFieldErrors.length === 0) {
    toast({ title: "Error", description: responseError.message, status: "error" });
    return;
  }
  const formikErrors = responseError.inputFieldErrors.reduce(
    (prev, inputFieldError) => ({ ...prev, [inputFieldError.fieldName]: inputFieldError.message }),
    {}
  );
  formikSetErrors(formikErrors);
}

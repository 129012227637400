import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Flex,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import { useCallback, useRef, useState } from "react";
import { OrderBillingStatusIndicator } from "../../common/order-billing-status-indicator";
import { OrderIssuesIndicator } from "../../common/order-issues-indicator/order-issues-indicator";
import { OrderStatusIndicator } from "../../common/order-status-indicator/order-status-indicator";
import AppLink from "../../components/app-link";
import {
  CancelButton,
  DangerButton,
  PrimaryButton,
  PrimaryIconButton,
  SecondaryButton,
} from "../../components/buttons";
import { FormattedDateUTC } from "../../components/formatted-date-utc";
import { HelpText } from "../../components/help-text";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { TruncatedText } from "../../components/truncated-text";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { CUSTOMER_STATUS } from "../../constants/customer-status";
import { ORDER_STATUS } from "../../constants/order-status";
import { AppColors } from "../../core/custom-theme";
import {
  CustomerCampaignInvoiceChargeFragment,
  RoadmapAmTaskFragment,
  RoadmapCustomerFragment,
  RoadmapOrderFragment,
} from "../../generated/graphql";
import { currencyFormatter } from "../../util/formatter";
import { BillingCycleStatusBadge } from "./billing-cycle-status-badge";
import { isBetween } from "../../util/datetime-helpers";
import { CustomerCampaignInvoiceChargeStatusBadge } from "./customer-campaign-invoice-charge-status-badge";

interface Props {
  monthIndex: number;
  campaignName: string;
  startDate: Date;
  endDate: Date;
  allowedAmount: number;
  orders: RoadmapOrderFragment[];
  onEditOrder: (orderId: number) => void;
  onDeleteOrder: (orderId: number) => void;
  onAddOrder: () => void;
  customer: RoadmapCustomerFragment | null;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  addContentStrategy: () => void;
  customerCampaignInvoiceCharge?: CustomerCampaignInvoiceChargeFragment;
  onAddAmTask: () => void;
  amTasks: RoadmapAmTaskFragment[];
  onEditAmTask: (amTaskId: number) => void;
  onDeleteAmTask: (amTaskId: number) => void;
  deleteAmTask: () => void;
  onCancelDeleteAmTask: () => void;
  amTaskForDelete: number | null;
}

export function RoadmapBillingCycle(props: Props) {
  const {
    monthIndex,
    startDate,
    endDate,
    campaignName,
    allowedAmount,
    orders,
    onEditOrder,
    onDeleteOrder,
    onAddOrder,
    customer,
    isSuperAdmin,
    isAdmin,
    addContentStrategy,
    customerCampaignInvoiceCharge,
    onAddAmTask,
    amTasks,
    onEditAmTask,
    onDeleteAmTask,
  } = props;

  const allocatedAmount = orders.map((x) => x.totalRetailAmount).reduce((total, amt) => total + amt, 0);
  const amountColor = allocatedAmount > allowedAmount ? AppColors.error : "green";
  const canAddOrders = startDate > DateTime.now().minus({ months: 1 }).toJSDate();

  const [scheduledOrderIdToDelete, setScheduledOrderIdToDelete] = useState<number | null>(null);
  const activeCancelRef = useRef<HTMLButtonElement>(null);
  const isCustomerActive = customer?.status.id === CUSTOMER_STATUS.ACTIVE.id;

  const handleScheduledOrderDelete = useCallback(() => {
    if (scheduledOrderIdToDelete !== null) {
      onDeleteOrder(scheduledOrderIdToDelete);
    }
    setScheduledOrderIdToDelete(null);
  }, [scheduledOrderIdToDelete, onDeleteOrder]);

  const isActive = isBetween(new Date(), startDate, endDate);

  return (
    <PanelGroup isCollapsible isCollapsedByDefault={!isActive}>
      <PanelTop>
        <Flex justifyContent="space-between">
          <Stack spacing={2}>
            <Heading size="md">
              Month {monthIndex + 1} - ({campaignName})
              <BillingCycleStatusBadge startDate={startDate} endDate={endDate} marginLeft={2} />
              {customerCampaignInvoiceCharge && (
                <CustomerCampaignInvoiceChargeStatusBadge
                  statusId={customerCampaignInvoiceCharge.statusId}
                  marginLeft={2}
                />
              )}
            </Heading>
            <Text fontSize="sm" color={AppColors.textGray}>
              <FormattedDateUTC date={startDate} format={DATE_MONTH_FORMAT} />
              {" - "}
              <FormattedDateUTC date={endDate} format={DATE_MONTH_FORMAT} />
            </Text>
            <Text fontSize="sm" color={AppColors.textGray}>
              <span style={{ color: amountColor }}>{currencyFormatter.format(allocatedAmount)}</span> /{" "}
              {currencyFormatter.format(allowedAmount)} planned
            </Text>
          </Stack>
          <Stack spacing={2} isInline>
            {isAdmin && isActive && (
              <SecondaryButton color={AppColors.blue} variant="outline" onClick={addContentStrategy}>
                Add Content Strategy
              </SecondaryButton>
            )}
            {canAddOrders && (
              <SecondaryButton onClick={onAddAmTask} isDisabled={!isCustomerActive}>
                Add Am Task
              </SecondaryButton>
            )}
            {canAddOrders && (
              <PrimaryButton onClick={onAddOrder} isDisabled={!isCustomerActive}>
                Add Order
              </PrimaryButton>
            )}
          </Stack>
        </Flex>
      </PanelTop>
      <PanelBottom containsTable>
        <Table isEmpty={orders.length === 0 && amTasks.length === 0} isLoading={false}>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Product Type</TableHeaderCell>
              <TableHeaderCell>Topic</TableHeaderCell>
              <TableHeaderCell>Keyword</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell>
                Price
                <HelpText standardLeftMargin>
                  <Stack spacing={4}>
                    <Text>
                      <strong>Retail</strong> is the amount the client is being charged.
                    </Text>
                    <Text>
                      <strong>Wholesale</strong> is the amount Red Canyon Media is charging to fulfill the order (the
                      client will not see this).
                    </Text>
                  </Stack>
                </HelpText>
              </TableHeaderCell>
              <TableHeaderCell>Order ID</TableHeaderCell>
              <TableHeaderCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell>
                  <TruncatedText maxLength={30}>{order.partnerProduct.name}</TruncatedText>
                </TableCell>
                <TableCell>
                  <TruncatedText maxLength={30}>{order.topic ?? "-"}</TruncatedText>
                </TableCell>
                <TableCell>
                  <TruncatedText maxLength={30}>{order.customerKeyword?.name ?? "-"}</TruncatedText>
                </TableCell>
                <TableCell>
                  <Stack isInline spacing={2} alignItems="center">
                    <Stack spacing={0} shouldWrapChildren>
                      <OrderStatusIndicator status={order.status} startDate={order.startDate} />
                      <OrderBillingStatusIndicator
                        startDate={order.startDate}
                        billingStatus={order.billingStatus}
                        isEnforced={customer?.partner.isBillingEnforced ?? false}
                      />
                    </Stack>
                    <OrderIssuesIndicator orderIssues={order.orderIssues} />
                  </Stack>
                </TableCell>
                <TableCell>
                  <Text fontSize="sm">
                    Retail: <strong>{currencyFormatter.format(order.totalRetailAmount ?? 0)}</strong>
                  </Text>
                  <Text fontSize="sm">
                    Wholesale: <strong>{currencyFormatter.format(order.totalWholesaleAmount ?? 0)}</strong>
                  </Text>
                </TableCell>
                <TableCell>
                  <AppLink to={`/orders/${order.id}`}>{order.id}</AppLink>
                </TableCell>
                <TableCell>
                  <Stack isInline spacing={2} alignItems="center" shouldWrapChildren>
                    <PrimaryIconButton
                      onClick={() => {
                        onEditOrder(order.id);
                      }}
                      icon={faPencilAlt}
                      actionName="Edit Order"
                    />
                    <PrimaryIconButton
                      onClick={() => {
                        if (order.status.id === ORDER_STATUS.SCHEDULED.id) {
                          setScheduledOrderIdToDelete(order.id);
                        } else {
                          onDeleteOrder(order.id);
                        }
                      }}
                      icon={faTrash}
                      actionName="Remove"
                      isDisabled={!order.canDelete && !isSuperAdmin}
                    />
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
            {amTasks.map((amTask) => (
              <TableRow key={amTask.id}>
                <TableCell>
                  <TruncatedText maxLength={30}>{amTask.taskType}</TruncatedText>
                </TableCell>
                <TableCell>
                  <TruncatedText maxLength={30}>{amTask.info ?? "-"}</TruncatedText>
                </TableCell>
                <TableCell>
                  <TruncatedText maxLength={30}>{"-"}</TruncatedText>
                </TableCell>
                <TableCell>
                  <Badge cursor="help" colorScheme="green">
                    Completed
                  </Badge>
                </TableCell>
                <TableCell>{"-"}</TableCell>
                <TableCell>{"-"}</TableCell>
                <TableCell>
                  <Stack isInline spacing={2} alignItems="center" shouldWrapChildren>
                    <PrimaryIconButton
                      onClick={() => {
                        onEditAmTask(amTask.id);
                      }}
                      icon={faPencilAlt}
                      actionName="Edit Am Task"
                    />
                    <PrimaryIconButton
                      onClick={() => {
                        onDeleteAmTask(amTask.id);
                      }}
                      icon={faTrash}
                      actionName="Remove"
                    />
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PanelBottom>
      <AlertDialog
        isOpen={!!scheduledOrderIdToDelete}
        onClose={() => setScheduledOrderIdToDelete(null)}
        leastDestructiveRef={activeCancelRef as any}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Confirm Delete Order</AlertDialogHeader>
          <AlertDialogBody>{`Are you sure you want to delete this Order (ID: ${scheduledOrderIdToDelete})?`}</AlertDialogBody>
          <AlertDialogFooter>
            <CancelButton onClick={() => setScheduledOrderIdToDelete(null)} ref={activeCancelRef}>
              Cancel
            </CancelButton>
            <DangerButton ml={3} onClick={handleScheduledOrderDelete}>
              Delete
            </DangerButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </PanelGroup>
  );
}

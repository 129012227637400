import {
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Box,
  Flex,
  Checkbox,
  InputRightAddon,
  InputGroup,
  Textarea,
} from "@chakra-ui/react";

import { PanelGroup, PanelTop, PanelBottom, PanelBox } from "../../components/panels";
import { DangerButton, PrimaryButton } from "../../components/buttons";
import { PageHeading } from "../../components/page-heading";
import { HelpText } from "../../components/help-text";
import { useTaskWorkspacePublishPost } from "./publish-post-hook";
import { CopyLinkButton } from "../../components/copy-link-button";

interface Props {
  taskId: number;
  onAbandon: () => void;
  onComplete: () => void;
}

export function TaskWorkspacePublishPost(props: Props) {
  const { onAbandon } = props;
  const { task, customer, handleComplete, isPostsPublished, handleChangePublishedPost, publishPostOrders, canSubmit } =
    useTaskWorkspacePublishPost(props);
  return (
    <>
      <PageHeading>{task?.order.partnerProduct.product.name ?? "Publish Post"}</PageHeading>
      <Grid templateColumns={{ base: "1fr", xl: "1fr 1.5fr" }} gridGap={4}>
        <Stack spacing={4} gridColumn={1}>
          <PanelGroup>
            <PanelTop showBottomBorder>
              <Heading size="md">Client Details</Heading>
            </PanelTop>
            <PanelBottom>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Client Name</FormLabel>
                  <Input variant="outline" value={customer?.name ?? ""} isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Admin URL</FormLabel>
                  <Input variant="outline" value={customer?.customerSiteCredential?.adminUrl ?? ""} isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>User</FormLabel>
                  <Input variant="outline" value={customer?.customerSiteCredential?.user ?? ""} isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <Input variant="outline" value={customer?.customerSiteCredential?.password ?? ""} isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Site Instructions</FormLabel>
                  <Textarea
                    variant="outline"
                    value={customer?.customerSiteCredential?.instructions ?? ""}
                    isReadOnly
                    rows={7}
                    resize="none"
                  />
                </FormControl>
              </Stack>
            </PanelBottom>
          </PanelGroup>
        </Stack>
        <Stack spacing={4} gridColumn={{ base: 1, xl: 2 }}>
          {publishPostOrders.map((publishPostOrder, index) => {
            const orderContentItem = publishPostOrder?.orderContentItems?.[0];
            return (
              <PanelBox key={publishPostOrder.id}>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel>Order ID</FormLabel>
                    <Input variant="outline" value={publishPostOrder?.id ?? ""} isReadOnly />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Deliverable URL</FormLabel>
                    <InputGroup>
                      <Input
                        variant="outline"
                        value={orderContentItem?.contentItemDelivery?.publicUrl ?? ""}
                        isReadOnly
                      />
                      <InputRightAddon>
                        <CopyLinkButton valueToCopy={orderContentItem?.contentItemDelivery?.publicUrl ?? ""} />
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <Checkbox checked={isPostsPublished[index]} onChange={handleChangePublishedPost.bind(null, index)}>
                      Is Post Published
                    </Checkbox>
                  </FormControl>
                </Stack>
              </PanelBox>
            );
          })}
          <Flex justifyContent="space-between">
            <Box>
              <DangerButton onClick={onAbandon}>Unassign Task</DangerButton>
              <HelpText standardLeftMargin>
                &quot;Unassign Task&quot; will unassign this task from you and return it to the queue.{" "}
                <strong>This cannot be undone and may result in lost work.</strong>
              </HelpText>
            </Box>
            <PrimaryButton isDisabled={!canSubmit} onClick={handleComplete}>
              Complete
            </PrimaryButton>
          </Flex>
        </Stack>
      </Grid>
    </>
  );
}

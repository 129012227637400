import { Box, FormControl, FormErrorMessage, FormLabel, Stack, Textarea } from "@chakra-ui/react";
import { Switch } from "@chakra-ui/react";
import { CancelButton, DangerButton, PrimaryButton } from "../../components/buttons";
import { ContentEditor } from "../../components/content-editor";
import { HelpText } from "../../components/help-text";
import { PanelBottom, PanelGroup, PanelMiddle } from "../../components/panels";
import { AppColors } from "../../core/custom-theme";
import { useSummary } from "./summary-hook";

interface Props {
  customerProposalId: number;
  summary?: string | null;
  onAbandon: () => void;
  handleComplete: (isApproved?: boolean, reviewComment?: string) => void;
  isDisabled: boolean;
  isQaReview?: boolean;
}

export function Summary(props: Props) {
  const { formik, handleCancel, onSubmit } = useSummary(props);
  const { isDisabled, isQaReview } = props;
  return (
    <PanelGroup>
      <PanelMiddle>
        {isQaReview && (
          <Stack spacing={4}>
            <FormControl>
              <Stack spacing={2} isInline>
                <Switch
                  color="green"
                  id="isApproved"
                  isChecked={formik.values.isApproved}
                  onChange={formik.handleChange}
                />
                <FormLabel color={formik.values.isApproved ? undefined : AppColors.error}>
                  {formik.values.isApproved ? "Approved" : "Rejected"}
                </FormLabel>
              </Stack>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.touched.comments && !!formik.errors.comments}>
              <FormLabel>Comments</FormLabel>
              <ContentEditor
                isInvalid={!!formik.touched.comments && !!formik.errors.comments}
                onChange={(html) => {
                  formik.setFieldValue("comments", html);
                }}
              />
              <FormErrorMessage>{formik.errors.comments}</FormErrorMessage>
            </FormControl>
          </Stack>
        )}
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={4}>
            <FormControl isDisabled={isDisabled}>
              <FormLabel>
                Summary (for Client)
                <HelpText standardLeftMargin>Summarize your strategy and choices for the client</HelpText>{" "}
              </FormLabel>
              <Textarea id="summary" value={formik.values.summary} onChange={formik.handleChange} />
            </FormControl>
            {formik.dirty && (
              <Stack isInline spacing={2}>
                <PrimaryButton isLoading={formik.isSubmitting} type="submit">
                  Save
                </PrimaryButton>
                <CancelButton onClick={handleCancel}>Cancel</CancelButton>
              </Stack>
            )}
          </Stack>
        </form>
      </PanelMiddle>
      <PanelBottom showTopBorder>
        <Stack isInline spacing={4} justifyContent="space-between" shouldWrapChildren alignItems="center">
          <Box textAlign="right">
            <PrimaryButton onClick={onSubmit} isDisabled={isDisabled}>
              Submit
            </PrimaryButton>
          </Box>
          <Box>
            <DangerButton onClick={props.onAbandon}>Unassign Task</DangerButton>
            <HelpText standardLeftMargin>
              &quot;Unassign Task&quot; will unassign this task from you and return it to the queue.{" "}
              <strong>This cannot be undone and may result in lost work.</strong>
            </HelpText>
          </Box>
        </Stack>
      </PanelBottom>
    </PanelGroup>
  );
}

import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Text,
  Badge,
  Heading,
  List,
  ListItem,
} from "@chakra-ui/react";
import { AlertWarning } from "../../../../components/alerts";
import { CancelButton, DangerButton } from "../../../../components/buttons";
import { useConfirmOutreach } from "./use-confirm-outreach";
import AppIcon from "../../../../components/app-icon";

interface Props {
  orderId: number | null;
  onReCreate: () => void;
  onCancel: () => void;
}

export function ConfirmOutreachModal(props: Props) {
  const { orderId } = props;
  const { confirmPrompt, formik, handleCancel } = useConfirmOutreach(props);

  return (
    <Modal isOpen={!!orderId} onClose={handleCancel} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack spacing={1}>
            <Heading size="md">Confirm Re Create Guest Outreach Task</Heading>
            <Text fontSize="md">
              <Badge colorScheme="orange">
                <AppIcon icon={faShieldAlt} standardRightMargin />
                Super Admin Only
              </Badge>
            </Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <AlertWarning>
              <strong>THIS CANNOT BE UNDONE</strong>
            </AlertWarning>
            <Text>
              This order is completed, but because you are a Super Admin, you can force this order to be in progress
              again. Following data will be affected:
              <List listStyleType="inherit" listStylePosition="inside" mt={2}>
                <ListItem>Order</ListItem>
                <ListItem>Workflow</ListItem>
                <ListItem>Tasks</ListItem>
                <ListItem>Publisher Payment</ListItem>
              </List>
            </Text>
            <FormControl isRequired isInvalid={!!formik.touched.confirmText && !!formik.errors.confirmText}>
              <FormLabel>Confirm Re creation of task (type &quot;{confirmPrompt}&quot;)</FormLabel>
              <Input
                id="confirmText"
                value={formik.values.confirmText}
                onChange={formik.handleChange}
                placeholder={confirmPrompt}
              />
              <FormErrorMessage>{formik.errors.confirmText}</FormErrorMessage>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack spacing={2} isInline>
            <DangerButton
              onClick={formik.submitForm}
              isLoading={formik.isSubmitting}
              isDisabled={!formik.isValid || formik.isSubmitting}
            >
              Re Create Task
            </DangerButton>
            <CancelButton onClick={handleCancel}>Cancel</CancelButton>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  Heading,
  Input,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";

import DatePicker from "react-datepicker";
import AppIcon from "../../components/app-icon";
import AppLink from "../../components/app-link";
import { PrimaryButton } from "../../components/buttons";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { DATE_MONTH_FORMAT, DATE_PICKER_FORMAT } from "../../constants/constants";
import { useTaskWorkspaceExternalContent } from "./task-workspace-external-content-hook";

interface Props {
  taskId: number;
  onAbandon: () => void;
  onComplete: () => void;
}

export function TaskWorkspaceExternalContent(props: Props) {
  const { customer, formik, order, product } = useTaskWorkspaceExternalContent(props);

  return (
    <>
      <Stack>
        <Box py={3}>
          <AppLink to="/tasks">
            <AppIcon icon={faChevronLeft} standardRightMargin />
            Tasks
          </AppLink>
        </Box>
        <PageHeading size="md">External Content Fulfillment</PageHeading>
      </Stack>
      <Grid gridTemplateColumns="1fr 1.5fr" gap={4}>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Heading fontSize="lg">Details</Heading>
          </PanelTop>
          <PanelBottom>
            <Stack spacing={4}>
              <FormControl isReadOnly>
                <FormLabel>Order ID</FormLabel>
                <Input variant="outline" value={order?.id ?? "-"} />
              </FormControl>
              <FormControl isReadOnly>
                <FormLabel>Client</FormLabel>
                <Input variant="outline" value={customer?.name ?? "-"} />
              </FormControl>
              <FormControl isReadOnly>
                <FormLabel>Partner</FormLabel>
                <Input variant="outline" value={customer?.partner.name ?? "-"} />
              </FormControl>
              <FormControl isReadOnly>
                <FormLabel>Client Website</FormLabel>
                <Input variant="outline" value={customer?.website ?? "-"} />
              </FormControl>
              <FormControl isReadOnly>
                <FormLabel>Product</FormLabel>
                <Input variant="outline" value={product?.name ?? "-"} />
              </FormControl>
              <FormControl isReadOnly>
                <FormLabel>Order Start Date</FormLabel>
                <Input
                  variant="outline"
                  value={order?.startDate ? DateTime.fromJSDate(order.startDate).toFormat(DATE_MONTH_FORMAT) : "-"}
                />
              </FormControl>
              <FormControl isReadOnly>
                <FormLabel>Topic</FormLabel>
                <Input variant="outline" value={order?.topic ?? "-"} />
              </FormControl>
              <FormControl isReadOnly>
                <FormLabel>Primary Keyword</FormLabel>
                <Input variant="outline" value={order?.customerKeyword?.name ?? "-"} />
              </FormControl>
              <FormControl isReadOnly>
                <FormLabel>External Vendor</FormLabel>
                <Input variant="outline" value={order?.orderExternalContentSpec?.vendor.name ?? "-"} />
              </FormControl>
              <FormControl isReadOnly>
                <FormLabel>External Vendor Order ID</FormLabel>
                <Input variant="outline" value={order?.orderExternalContentSpec?.externalOrderId ?? "-"} />
              </FormControl>
              <FormControl isReadOnly>
                <FormLabel>Client Instructions</FormLabel>
                <Textarea value={customer?.instructions ?? "-"} variant="outline" resize="none" />
              </FormControl>
            </Stack>
          </PanelBottom>
        </PanelGroup>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Heading fontSize="lg">Fulfillment Details</Heading>
          </PanelTop>
          <PanelBottom>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={4}>
                <FormControl isRequired isInvalid={!!formik.errors.resultUrl && !!formik.touched.resultUrl}>
                  <FormLabel fontWeight="bold">Result URL</FormLabel>
                  <Input
                    variant="outline"
                    id="resultUrl"
                    value={formik.values.resultUrl}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <FormErrorMessage>{formik.errors.resultUrl}</FormErrorMessage>
                  <FormHelperText>The URL of the published content.</FormHelperText>
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="bold">Result URL Domain Authority</FormLabel>
                  <Input
                    variant="outline"
                    id="resultUrlDomainAuthority"
                    value={formik.values.resultUrlDomainAuthority}
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <FormErrorMessage>{formik.errors.resultUrlDomainAuthority}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel fontWeight="bold">Fulfillment Completion Date</FormLabel>
                  <DatePicker
                    id="fulfillmentCompletionDate"
                    onChange={(date: Date) => formik.setFieldValue("fulfillmentCompletionDate", date ?? new Date())}
                    isClearable={false}
                    selected={formik.values.fulfillmentCompletionDate}
                    minDate={
                      order?.startDate ? DateTime.fromJSDate(order.startDate).minus({ weeks: 1 }).toJSDate() : undefined
                    }
                    maxDate={new Date()}
                    dateFormat={DATE_PICKER_FORMAT}
                    customInput={<Input variant="outline" width="100%" />}
                    disabledKeyboardNavigation
                    placeholderText="Select start date"
                  />
                  <FormErrorMessage>{formik.errors.fulfillmentCompletionDate as string}</FormErrorMessage>
                  <FormHelperText>The date the order was completed according to the external vendor.</FormHelperText>
                </FormControl>
                <Box>
                  <PrimaryButton disabled={!formik.isValid} isLoading={formik.isSubmitting} type="submit">
                    Complete Order
                  </PrimaryButton>
                </Box>
              </Stack>
            </form>
          </PanelBottom>
        </PanelGroup>
      </Grid>
    </>
  );
}

import { SelectProps, Select } from "@chakra-ui/react";
import { useVendorsForSelectorQuery } from "../../generated/graphql";
import { groupBy } from "lodash";

interface Props extends SelectProps {
  partnerId?: number | null;
  selectedVendorId?: number | null;
  onVendorSelected: (vendorId: number | null) => void;
  isClearable?: boolean;
  isExternal?: boolean;
}

export function VendorSelector(props: Props) {
  const { selectedVendorId, onVendorSelected, isClearable, partnerId, isExternal, ...rest } = props;

  const { data, loading } = useVendorsForSelectorQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId: partnerId ?? undefined,
      showExternalVendors: isExternal,
    },
  });

  const vendors = data?.vendors.nodes ?? [];

  const vendorsGroupedByPartner = groupBy(vendors, "partner.name");

  function renderOptions() {
    if (partnerId) {
      vendors.map((vendor) => (
        <option key={vendor.id} value={vendor.id.toString()}>
          {vendor.name}
        </option>
      ));
    } else {
      return Object.keys(vendorsGroupedByPartner).map((partnerName) => {
        if (partnerName === "undefined") {
          const systemVendors = vendorsGroupedByPartner[partnerName].filter((x) => !x.isExternal);
          const externalVendors = vendorsGroupedByPartner[partnerName].filter((x) => x.isExternal);
          return (
            <>
              {!isExternal && renderOptionGroup(systemVendors, "System")}
              {renderOptionGroup(externalVendors, "External")}
            </>
          );
        } else {
          return renderOptionGroup(vendorsGroupedByPartner[partnerName], partnerName);
        }
      });
    }
  }

  function renderOptionGroup(groupedVendors: typeof vendors, label: string) {
    return (
      <optgroup key={label} label={label}>
        {groupedVendors.map((vendor) => (
          <option key={vendor.id} value={vendor.id.toString()}>
            {vendor.partner && `${vendor.partner.name} / `}
            {vendor.name}
          </option>
        ))}
      </optgroup>
    );
  }

  return (
    <Select
      isDisabled={loading}
      value={selectedVendorId?.toString() ?? ""}
      onChange={(e) => {
        const value = e.target.value === "" ? null : parseInt(e.target.value);
        onVendorSelected(value);
      }}
      {...rest}
    >
      <option key="Default" value="" disabled={!isClearable}>
        Select a Vendor
      </option>
      {renderOptions()}
    </Select>
  );
}

import { LinkProps, Link } from "@chakra-ui/react";
import { AppColors } from "../../core/custom-theme";
import Color from "color";

export function PrimaryButtonLink(props: LinkProps) {
  return (
    <Link
      bg={AppColors.blue}
      borderRadius={4}
      fontWeight="bold"
      color="white"
      _hover={{
        bg: Color(AppColors.blue).darken(0.2).hex(),
      }}
      _active={{
        bg: Color(AppColors.blue).darken(0.4).hex(),
      }}
      fontSize="13px"
      paddingX="30px"
      paddingY="10px"
      {...props}
    />
  );
}

import { InputProps } from "@chakra-ui/react";
import { AutoCompleteInput } from "../../components/auto-complete-input/auto-complete-input";
import { useSitePostStatusesQuery } from "../../generated/graphql";

interface Props extends InputProps {
  selectedStatusId?: string | null;
  onStatusSelected: (statusId: string | null) => void;
  isClearable?: boolean;
}

export function SitePostStatusSelector(props: Props) {
  const { selectedStatusId, onStatusSelected, placeholder, isClearable, ...rest } = props;

  const { data, loading } = useSitePostStatusesQuery({
    fetchPolicy: "network-only",
  });

  const options = data?.sitePostStatuses.map((node) => ({
    value: `${node.id}`,
    label: `${node.name}`,
  }));

  if (loading) {
    // TODO: we can show loader or pass it to autocomplete as placement of popper is not proper
    return null;
  }

  return (
    <AutoCompleteInput
      items={options ?? []}
      onSelectedValueChanged={onStatusSelected}
      selectedValue={selectedStatusId ? `${selectedStatusId}` : undefined}
      placeholder={placeholder ?? "Select Site Post Status"}
      isClearable={isClearable}
      {...rest}
    />
  );
}

import { SecondaryNav, SecondaryNavLink } from "../../components/layout";
import { useAdminVendorDetailsSecondaryNavQuery } from "../../generated/graphql";

interface Props {
  vendorId: number;
  activeSection?: string;
}

const NAV_ITEMS = [
  {
    section: "overview",
    displayName: "Overview",
  },
  {
    section: "users",
    displayName: "Users",
  },
];

export function AdminVendorDetailsSecondaryNav(props: Props) {
  const { vendorId, activeSection } = props;
  const vendorDetailsNavQueryResult = useAdminVendorDetailsSecondaryNavQuery({
    variables: {
      vendorId,
    },
  });
  return (
    <SecondaryNav title={vendorDetailsNavQueryResult.data?.vendor?.name ?? "Vendor"}>
      {NAV_ITEMS.map((item) => (
        <SecondaryNavLink
          key={item.section}
          isActive={activeSection === item.section}
          to={`/admin/vendors/${vendorId}/${item.section}`}
        >
          {item.displayName}
        </SecondaryNavLink>
      ))}
    </SecondaryNav>
  );
}

export const CUSTOMER_CAMPAIGN_INVOICE_CHARGE_STATUS = {
  PENDING: {
    id: "pending",
    name: "Pending",
    description: "The item has been created and is in a draft state. It has not cleared yet.",
  },
  CLEARED: {
    id: "cleared",
    name: "Cleared",
    description: "The item is considered finalized.",
  },
};

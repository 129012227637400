import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";

import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";

import { useContentStrategyV2 } from "./content-strategy-v2-hook";
import { ContentStrategyRoadmap } from "./content-strategy-v2-roadmap";
import { ContentStrategyWorkspace } from "./content-strategy-v2-workspace";

interface Props {
  taskId: number;
  onAbandon: () => void;
  onComplete: (isApproved?: boolean, reviewComment?: string) => void;
  isQaReview?: boolean;
}

export function TaskWorkspaceContentStrategyV2(props: Props) {
  const contentStrategyV2 = useContentStrategyV2(props);

  return (
    <Container>
      <PageHeading>Content Strategy</PageHeading>
      <Tabs variant="soft-rounded" p={0}>
        <TabList>
          <Tab>Strategy</Tab>
          <Tab>Roadmap</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <ContentStrategyWorkspace {...props} {...contentStrategyV2} />
          </TabPanel>
          <TabPanel px={0}>
            <ContentStrategyRoadmap customerId={contentStrategyV2.customer?.id ?? 0} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
}

import { useContext } from "react";
import { useToast } from "@chakra-ui/react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "../core/auth-manager";
import { Loader } from "./loader";

interface Props {
  children: React.ReactNode;
  requiresAdmin?: boolean;
  requiresPartnerAdmin?: boolean;
}

export function RouteAuthorization(props: Props) {
  const { requiresAdmin, requiresPartnerAdmin } = props;
  const { partnerId: partnerIdString } = useParams<"partnerId">();
  const authContext = useContext(AuthContext);
  const toast = useToast();
  const navigate = useNavigate();

  if (authContext.isLoading) {
    return <Loader type="fullView" />;
  }

  if (!authContext.isLoggedIn || !authContext.viewer) {
    toast({ title: "Not Logged In", description: "You must be logged in to proceed.", status: "warning" });
    return <Navigate to="/" />;
  }

  let partnerIdInRoute: number | null = null;
  if (partnerIdString) {
    partnerIdInRoute = parseInt(partnerIdString);
  }

  if (requiresAdmin && !authContext.isAdmin()) {
    toast({
      title: "Additional Permissions Required",
      description: "You must be an admin to proceed.",
      status: "warning",
    });
    navigate(-1);
    return <Loader type="fullView" />;
  }

  if (
    requiresPartnerAdmin &&
    !authContext.isAdmin() &&
    !authContext.hasPartnerUserAdmin(partnerIdInRoute ?? undefined)
  ) {
    toast({
      title: "Additional Permissions Required",
      description: "You must be an admin for the partner in order to proceed.",
      status: "warning",
    });
    navigate(-1);
    return <Loader type="fullView" />;
  }

  return <>{props.children}</>;
}

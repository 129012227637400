import { Tooltip, Badge } from "@chakra-ui/react";
import { TASK_STATUS } from "../../constants/task-status";

interface Props {
  status: {
    id: string;
    name: string;
  };
  assignedUser?: {
    id: number;
    fullName: string;
  } | null;
}

const COLORS = {
  [TASK_STATUS.AVAILABLE.id]: "blue",
  [TASK_STATUS.ASSIGNED.id]: "orange",
  [TASK_STATUS.CANCELLED.id]: "red",
  [TASK_STATUS.COMPLETED.id]: "green",
  [TASK_STATUS.PENDING.id]: "blue",
};

export function TaskStatusIndicator(props: Props) {
  const { status, assignedUser } = props;
  const color = COLORS[status.id];

  if (assignedUser) {
    const tooltipLabel = `Assigned to ${assignedUser?.fullName ?? "Unassigned"}`;
    return (
      <Tooltip label={tooltipLabel} aria-label={tooltipLabel}>
        <Badge colorScheme={color} height="15px">
          {status.name}
        </Badge>
      </Tooltip>
    );
  }

  return (
    <Badge colorScheme={color} height="15px">
      {status.name}
    </Badge>
  );
}

import { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as yup from "yup";

import { useContentTaskWorkspaceQuery, useSaveTaskContentItemMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useDebounce } from "../../util/use-debounce";

interface Options {
  taskId: number;
  onComplete: (isApproved?: boolean, reviewComment?: string) => void;
}

interface FormValues {
  title: string;
  content: string;
}

const validationSchema = yup.object().shape({
  title: yup.string().label("Title").required().nullable(false).min(1),
  content: yup.string().label("Content").required().nullable(false).min(1),
});

export function useTaskWorkspaceContent(options: Options) {
  const { onComplete, taskId } = options;
  const taskWorkspaceContentQueryResult = useContentTaskWorkspaceQuery({
    fetchPolicy: "no-cache",
    variables: {
      taskId,
    },
  });
  const [saveTaskContentItemMutation] = useSaveTaskContentItemMutation();
  const toast = useToast();
  const [lastAutosavedAt, setLastAutosavedAt] = useState<Date | null>(null);
  const [contentItemValues, setContentItemValues] = useState<{ title: string; content: string }>({
    title: "",
    content: "",
  });

  const task = taskWorkspaceContentQueryResult.data?.task ?? null;
  const reviewTasks = task?.reviewTasks.nodes ?? [];
  const contentItem = task?.contentItem ?? null;

  const formik = useFormik<FormValues>({
    initialValues: {
      content: contentItem?.content ?? "",
      title: contentItem?.title ?? "",
    },
    enableReinitialize: true,
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSubmit: async (values, _helpers) => {
      try {
        await saveTaskContentItemMutation({
          variables: {
            input: {
              content: values.content,
              taskId,
              title: values.title,
            },
          },
        });
        onComplete();
      } catch (e: any) {
        toast({ title: "Unable to Complete Task", description: getDisplayMessageForError(e), status: "error" });
      }
    },
  });

  const debouncedContentItemValues = useDebounce(contentItemValues, 2000);

  useEffect(() => {
    if (
      contentItem &&
      (contentItem.title !== contentItemValues.title || contentItem.content !== contentItemValues.content)
    ) {
      setContentItemValues({
        title: contentItem.title,
        content: contentItem.content,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentItem]);

  useEffect(() => {
    if (task) {
      saveTaskContentItemMutation({
        variables: {
          input: {
            content: debouncedContentItemValues.content,
            title: debouncedContentItemValues.title,
            taskId: task.id,
          },
        },
        // eslint-disable-next-line promise/prefer-await-to-then
      }).then(() => {
        setLastAutosavedAt(new Date());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedContentItemValues]);

  function onContentItemUpdate(title: string, content: string) {
    setContentItemValues({ title, content });
  }

  return {
    taskId,
    loading: taskWorkspaceContentQueryResult.loading,
    reviewTasks,
    contentItem,
    formik,
    lastAutosavedAt,
    task,
    onContentItemUpdate,
  };
}

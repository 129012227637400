import { useToast } from "@chakra-ui/react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";

import {
  useContentFulfillment_UpdateOrderMutation,
  useContentFulfillmentDecisionTaskWorkspaceQuery,
  useCreateOrderExternalContentSpecMutation,
} from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { FormHelpers } from "../../util/form-helpers";
import { FULFILLMENT_OPTIONS } from "../../constants/fulfillment-options";

interface Options {
  taskId: number;
  onComplete: () => void;
}

interface FormValues {
  fulFillmentMethod: string;
  vendorId?: number;
  externalOrderId?: string;
  submittedAt?: Date;
  cost?: number;
  domainAuthority?: number;
  trustFlow?: number;
  targetResultUrl?: string;
}

const requiredIfExternalVendorString = yup.string().when("fulFillmentMethod", {
  is: FULFILLMENT_OPTIONS.EXTERNAL_FULFILLMENT.id,
  then: yup.string().required(),
  otherwise: yup.string().notRequired(),
});

const requiredIfExternalVendorNumber = yup.number().when("fulFillmentMethod", {
  is: FULFILLMENT_OPTIONS.EXTERNAL_FULFILLMENT.id,
  then: yup.number().required(),
  otherwise: yup.number().notRequired(),
});

const requiredIfExternalVendorDate = yup.date().when("fulFillmentMethod", {
  is: FULFILLMENT_OPTIONS.EXTERNAL_FULFILLMENT.id,
  then: yup.date().required(),
  otherwise: yup.date().notRequired(),
});

const validationSchema = yup.object().shape({
  fulFillmentMethod: yup.string().label("Fulfillment Method").required().nullable(false),
  vendorId: requiredIfExternalVendorNumber.label("Vendor Id"),
  externalOrderId: requiredIfExternalVendorString.label("External Order Id"),
  submittedAt: requiredIfExternalVendorDate.label("Submitted Date"),
  cost: requiredIfExternalVendorNumber.label("Cost").min(0),
  domainAuthority: yup.number().integer().min(0).label("Domain Authority").notRequired().nullable(false),
  trustFlow: yup.number().integer().min(0).label("Trust Flow").notRequired().nullable(false),
  targetResultUrl: yup.string().label("Target Result URL").notRequired().nullable(true),
});

export function useContentFulfillmentDecision(options: Options) {
  const { taskId, onComplete } = options;
  const toast = useToast();
  const queryResult = useContentFulfillmentDecisionTaskWorkspaceQuery({
    fetchPolicy: "network-only",
    variables: {
      taskId,
    },
  });
  const [createOrderExternalContentSpec] = useCreateOrderExternalContentSpecMutation();
  const [updateOrder] = useContentFulfillment_UpdateOrderMutation();

  const task = queryResult.data?.task ?? null;
  const customer = task?.customer ?? null;
  const customerQuestionnaire = customer?.customerQuestionnaire ?? null;
  const order = task?.order ?? null;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function onSubmit(values: FormValues, _helpers: FormikHelpers<FormValues>) {
    try {
      if (values.fulFillmentMethod === FULFILLMENT_OPTIONS.EXTERNAL_FULFILLMENT.id) {
        const response = await createOrderExternalContentSpec({
          variables: {
            input: {
              orderId: order?.id ?? 0,
              vendorId: values.vendorId ?? 0,
              externalOrderId: values.externalOrderId ?? "",
              submittedAt: values.submittedAt ?? new Date(),
              cost: values.cost ?? 0,
              domainAuthority: FormHelpers.processNullableInt(values.domainAuthority),
              trustFlow: FormHelpers.processNullableInt(values.trustFlow),
            },
          },
        });
        if (!response.data?.createOrderExternalContentSpec.ok) {
          throw new Error(
            response.data?.createOrderExternalContentSpec.error?.message ??
              "Something went wrong while creating external content spec"
          );
        }
      } else {
        const response = await updateOrder({
          variables: {
            input: {
              orderId: order?.id ?? 0,
              targetResultUrl: FormHelpers.processNullableString(values.targetResultUrl ?? ""),
            },
          },
        });
        if (!response.data?.updateOrder.order.id) {
          throw new Error("Something went wrong while updating order");
        }
      }
      onComplete();
    } catch (e: any) {
      toast({ title: "Unable to Complete Task", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      fulFillmentMethod: FULFILLMENT_OPTIONS.INTERNAL_FULFILLMENT.id,
      vendorId: undefined,
      externalOrderId: "",
      submittedAt: undefined,
      cost: undefined,
      domainAuthority: undefined,
      trustFlow: undefined,
      targetResultUrl: undefined,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  function onVendorSelected(vendorId: number | null) {
    formik.setFieldValue("vendorId", vendorId);
  }

  function onSubmittedDateChange(date: Date | null) {
    formik.setFieldValue("submittedAt", date ?? new Date());
  }

  function handleSubmit() {
    formik.handleSubmit();
  }

  return {
    loading: queryResult.loading,
    task,
    customer,
    customerQuestionnaire,
    order,
    formik,
    onVendorSelected,
    onSubmittedDateChange,
    handleSubmit,
  };
}

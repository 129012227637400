import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  SimpleGrid,
  Stack,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { FormikHelpers, useFormik } from "formik";
import * as yup from "yup";
import { CancelButton, PrimaryButton } from "../../components/buttons";
import { useAddPartnerMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAdded: (partnerId: number) => void;
}

interface FormValues {
  name: string;
  billingEmail: string;
  isUpfrontPaymentRequired: boolean;
  isBillingEnforced: boolean;
}

const createPartnerSchema = yup.object().shape({
  name: yup.string().label("Partner Name").required(),
  billingEmail: yup.string().label("Billing Email").required().email(),
  isUpfrontPaymentRequired: yup.bool().label("Is Upfront Payment Required").required(),
  isBillingEnforced: yup.bool().label("Is Billing Enforced").required(),
});

export function AddPartnerDrawer(props: Props) {
  const { onClose, onAdded, isOpen } = props;
  const [addPartner] = useAddPartnerMutation();
  const toast = useToast();

  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      billingEmail: "",
      isUpfrontPaymentRequired: true,
      isBillingEnforced: false,
    },
    validationSchema: createPartnerSchema,
    onSubmit: async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      try {
        const response = await addPartner({
          variables: {
            input: {
              name: values.name,
              isUpfrontPaymentRequired: values.isUpfrontPaymentRequired,
              billingEmail: values.billingEmail,
              isBillingEnforced: values.isBillingEnforced,
            },
          },
        });

        if (response.data?.createPartner.partner?.id) {
          toast({ title: "Success", description: "Partner created successfully!", status: "success" });
          formikHelpers.resetForm();
          onAdded(response.data.createPartner.partner.id);
        }

        onClose();
      } catch (e: any) {
        toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
        formikHelpers.setSubmitting(false);
      }
    },
  });

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="sm" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add Partner</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={!!formik.errors.name && !!formik.touched.name}>
              <FormLabel>Name</FormLabel>
              <Input value={formik.values.name} id="name" onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.billingEmail && !!formik.touched.billingEmail}>
              <FormLabel>Billing Email</FormLabel>
              <Input value={formik.values.billingEmail} id="billingEmail" onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.billingEmail}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.isBillingEnforced && !!formik.touched.isBillingEnforced}>
              <FormLabel>Is Billing Enforced</FormLabel>
              <Switch
                isChecked={formik.values.isBillingEnforced}
                id="isBillingEnforced"
                onChange={formik.handleChange}
              />
              <FormErrorMessage>{formik.errors.isBillingEnforced}</FormErrorMessage>
              {formik.values.isBillingEnforced && (
                <FormHelperText>
                  Show billing information for this partner and enable billing logic when activating orders.
                </FormHelperText>
              )}
              {!formik.values.isBillingEnforced && (
                <FormHelperText>
                  Prevent showing billing information for this partner and ignore billing when activating orders.
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              isRequired
              isInvalid={!!formik.errors.isUpfrontPaymentRequired && !!formik.touched.isUpfrontPaymentRequired}
            >
              <FormLabel>Is Upfront Payment Required</FormLabel>
              <Switch
                isChecked={formik.values.isUpfrontPaymentRequired}
                id="isUpfrontPaymentRequired"
                onChange={formik.handleChange}
              />
              <FormErrorMessage>{formik.errors.isUpfrontPaymentRequired}</FormErrorMessage>
              {formik.values.isUpfrontPaymentRequired && (
                <FormHelperText>Orders must be paid for before fulfillment work begins.</FormHelperText>
              )}
              {!formik.values.isUpfrontPaymentRequired && (
                <FormHelperText>
                  Fulfillment will begin on start date of order and will be billed by invoice at the end of the month.
                </FormHelperText>
              )}
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <SimpleGrid columns={2} gridGap={4} width="100%">
            <PrimaryButton
              type="submit"
              width="100%"
              isLoading={formik.isSubmitting}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Save
            </PrimaryButton>
            <CancelButton width="100%" onClick={handleCancel}>
              Cancel
            </CancelButton>
          </SimpleGrid>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

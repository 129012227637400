import { DateTime } from "luxon";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Stack,
  FormControl,
  FormLabel,
  Badge,
  Input,
  Box,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { CancelButton, PrimaryButton, PrimaryIconButton, PrimaryLinkButton } from "../../components/buttons";
import { LockedInput } from "../../components/locked-input";
import { Table, TableBody, TableCell, TableFoot, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { faPencilAlt, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import AppIcon from "../../components/app-icon";
import { Props } from "./common";
import { useEditPartnerAvailableProduct } from "./edit-partner-available-product-hook";
import { FormattedDate } from "../../components/formatted-date";
import { currencyFormatter } from "../../util/formatter";
import { DATE_MONTH_FORMAT, DATE_PICKER_FORMAT } from "../../constants/constants";

export function EditPartnerAvailableProductDrawer(props: Props) {
  const { isOpen } = props;
  const {
    handleCancel,
    loading,
    partnerAvailableProduct,
    partnerProductWholesalePrices,
    wholesalePriceForEdit,
    onWholesalePriceEdit,
    onWholesalePriceEditCancel,
    isAdding,
    onAdd,
    onAddCancel,
    addFormik,
    earliestNewEffectiveDate,
    onDelete,
    editFormik,
  } = useEditPartnerAvailableProduct(props);

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="sm" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit Available Base Product Type</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Base Product Type</FormLabel>
              <LockedInput variant="outline" value={partnerAvailableProduct?.product?.name ?? ""} />
            </FormControl>
            <FormControl>
              <FormLabel>Wholesale Price</FormLabel>
              <Table isEmpty={partnerProductWholesalePrices.length === 0} isLoading={loading}>
                <TableHead>
                  <TableRow key="head">
                    <TableHeaderCell>Effective Date</TableHeaderCell>
                    <TableHeaderCell>Amount</TableHeaderCell>
                    <TableHeaderCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {partnerProductWholesalePrices.map((wholesalePrice) => {
                    if (wholesalePrice.id === wholesalePriceForEdit?.id) {
                      return (
                        <TableRow key={wholesalePrice.id}>
                          <TableCell colSpan={3}>
                            <Stack spacing={4}>
                              <FormControl
                                isInvalid={!!editFormik.touched.effectiveAt && !!editFormik.errors.effectiveAt}
                              >
                                <FormLabel>Effective Date</FormLabel>
                                <Box>
                                  <DatePicker
                                    onChange={(date) => {
                                      editFormik.setFieldValue("effectiveAt", date);
                                    }}
                                    selected={editFormik.values.effectiveAt}
                                    minDate={earliestNewEffectiveDate}
                                    maxDate={DateTime.now().plus({ years: 2 }).toJSDate()}
                                    dateFormat={DATE_PICKER_FORMAT}
                                    customInput={<Input variant="outline" width="100%" />}
                                    disabledKeyboardNavigation
                                    placeholderText="Select effective date"
                                  />
                                  <FormHelperText>*At Midnight/12am (America/Denver)</FormHelperText>
                                </Box>
                                <FormErrorMessage>{editFormik.errors.effectiveAt as string}</FormErrorMessage>
                              </FormControl>
                              <FormControl
                                isInvalid={!!editFormik.touched.wholesaleAmount && !!editFormik.errors.wholesaleAmount}
                              >
                                <FormLabel>Amount</FormLabel>
                                <InputGroup>
                                  <InputLeftElement color="gray.300" fontSize="1.2em">
                                    $
                                  </InputLeftElement>
                                  <Input
                                    placeholder="Enter Wholesale Amount"
                                    type="number"
                                    variant="outline"
                                    id="wholesaleAmount"
                                    value={editFormik.values.wholesaleAmount}
                                    onChange={editFormik.handleChange}
                                  />
                                </InputGroup>
                                <FormErrorMessage>{editFormik.errors.wholesaleAmount}</FormErrorMessage>
                              </FormControl>
                              <Stack isInline spacing={2}>
                                <PrimaryButton
                                  isDisabled={editFormik.isSubmitting}
                                  onClick={() => editFormik.submitForm()}
                                >
                                  Save
                                </PrimaryButton>
                                <CancelButton onClick={onWholesalePriceEditCancel}>Cancel</CancelButton>
                              </Stack>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return (
                      <TableRow key={wholesalePrice.id}>
                        <TableCell>
                          <Text fontWeight={wholesalePrice.isCurrent ? "bold" : undefined}>
                            <FormattedDate date={wholesalePrice.effectiveAt} format={DATE_MONTH_FORMAT} />
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Text fontWeight={wholesalePrice.isCurrent ? "bold" : undefined}>
                            {currencyFormatter.format(wholesalePrice.wholesaleAmount)}
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Stack justifyContent="flex-end" alignItems="center" isInline spacing={1}>
                            {wholesalePrice.isCurrent && <Badge colorScheme="green">Current</Badge>}
                            {!wholesalePrice.isCurrent && new Date() > wholesalePrice.effectiveAt && (
                              <Badge>Inactive</Badge>
                            )}
                            {!wholesalePrice.isCurrent && wholesalePrice.canDelete && (
                              <PrimaryIconButton
                                actionName="Edit"
                                icon={faPencilAlt}
                                onClick={() => {
                                  onWholesalePriceEdit(wholesalePrice.id);
                                }}
                              />
                            )}
                            {!wholesalePrice.isCurrent && wholesalePrice.canDelete && (
                              <PrimaryIconButton
                                actionName="Remove"
                                icon={faTrash}
                                onClick={() => {
                                  onDelete(wholesalePrice.id);
                                }}
                              />
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFoot>
                  {!isAdding && !wholesalePriceForEdit && (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <PrimaryLinkButton onClick={onAdd}>
                          <AppIcon icon={faPlus} standardRightMargin />
                          Add New Wholesale Price
                        </PrimaryLinkButton>
                      </TableCell>
                    </TableRow>
                  )}
                  {isAdding && (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Stack spacing={4}>
                          <FormControl isInvalid={!!addFormik.touched.effectiveAt && !!addFormik.errors.effectiveAt}>
                            <FormLabel>Effective Date</FormLabel>
                            <Box>
                              <DatePicker
                                onChange={(date) => {
                                  addFormik.setFieldValue("effectiveAt", date);
                                }}
                                selected={addFormik.values.effectiveAt}
                                minDate={earliestNewEffectiveDate}
                                maxDate={DateTime.now().plus({ years: 2 }).toJSDate()}
                                dateFormat={DATE_PICKER_FORMAT}
                                customInput={<Input variant="outline" width="100%" />}
                                disabledKeyboardNavigation
                                placeholderText="Select effective date"
                              />
                              <FormHelperText>*At Midnight/12am (America/Denver)</FormHelperText>
                            </Box>
                            <FormErrorMessage>{addFormik.errors.effectiveAt as string}</FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={!!addFormik.touched.wholesaleAmount && !!addFormik.errors.wholesaleAmount}
                          >
                            <FormLabel>Amount</FormLabel>
                            <InputGroup>
                              <InputLeftElement color="gray.300" fontSize="1.2em">
                                $
                              </InputLeftElement>
                              <Input
                                placeholder="Enter Wholesale Amount"
                                type="number"
                                variant="outline"
                                id="wholesaleAmount"
                                value={addFormik.values.wholesaleAmount}
                                onChange={addFormik.handleChange}
                              />
                            </InputGroup>
                            <FormErrorMessage>{addFormik.errors.wholesaleAmount}</FormErrorMessage>
                          </FormControl>
                          <Stack isInline spacing={2}>
                            <PrimaryButton isDisabled={addFormik.isSubmitting} onClick={() => addFormik.submitForm()}>
                              Save
                            </PrimaryButton>
                            <CancelButton onClick={onAddCancel}>Cancel</CancelButton>
                          </Stack>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableFoot>
              </Table>
            </FormControl>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

import { useMemo } from "react";

import { CustomerKeywordDetailsFragment, useKeywordRankingsQuery } from "../../generated/graphql";

interface Options {
  keyword?: CustomerKeywordDetailsFragment;
  searchEngine: string;
}

export function useKeywordRankings(options: Options) {
  const { keyword, searchEngine } = options;

  const createdAt = useMemo(() => {
    return {
      gte: keyword?.startRank?.createdAt,
      lte: new Date(),
    };
  }, [keyword]);

  const keywordRankingsQueryResult = useKeywordRankingsQuery({
    variables: {
      createdAt,
      customerKeywordId: keyword?.id ?? 0,
      searchEngine,
    },
    fetchPolicy: "network-only",
    skip: !keyword,
  });

  return {
    keywordRankingsQueryResult,
  };
}

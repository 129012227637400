import * as yup from "yup";
import { useAddPackageItemDataQuery, useCreatePartnerPackageItemMutation } from "../../generated/graphql";
import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import { getDisplayMessageForError } from "../../util/error-helper";

interface Options {
  monthIndex: number;
  partnerId: number;
  partnerPackageId: number;
  onAdded: () => void;
  onClose: () => void;
}

interface FormValues {
  partnerProductId: number;
  quantity: number;
  isRecurring: boolean;
}

const validationSchema = yup.object().shape({
  partnerProductId: yup.number().label("Product Type").required().nullable(false),
  quantity: yup.number().label("Quantity").required().nullable(false).min(1),
});

export function useAddPackageItemDrawer(options: Options) {
  const { partnerId, onClose, monthIndex, partnerPackageId, onAdded } = options;
  const toast = useToast();
  const [createPartnerPackageItemMutation] = useCreatePartnerPackageItemMutation();
  const addPackageItemQueryResult = useAddPackageItemDataQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId,
    },
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      partnerProductId: 0,
      quantity: 1,
      isRecurring: false,
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        const response = await createPartnerPackageItemMutation({
          variables: {
            input: {
              monthIndex,
              partnerPackageId,
              partnerProductId: values.partnerProductId,
              quantity: values.quantity,
              isRecurring: values.isRecurring,
            },
          },
        });

        if (response.data?.createPartnerPackageItem.ok) {
          onAdded();
          onClose();
          helpers.resetForm();
        } else {
          toast({
            title: "Package Item Not Added",
            description:
              response.data?.createPartnerPackageItem.error?.message ?? "Something went wrong. Please try again.",
            status: "error",
          });
        }
      } catch (e: any) {
        toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
        helpers.setSubmitting(false);
      }
    },
  });

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  function handleSave(andCreateAnother?: boolean) {
    formik.setFieldValue("createAnother", andCreateAnother);
    formik.handleSubmit();
  }

  return {
    loading: addPackageItemQueryResult.loading,
    partnerProducts: addPackageItemQueryResult.data?.partner?.partnerProducts.nodes ?? [],
    formik,
    handleCancel,
    handleSave,
  };
}

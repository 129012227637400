import { DateTime } from "luxon";
import { Box, Text, Stack } from "@chakra-ui/react";
import { DATE_TIME_FORMAT } from "../../constants/constants";
import { RichContent } from "../../components/rich-content";
import { AppColors } from "../../core/custom-theme";
import AppIcon from "../../components/app-icon";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

interface Props {
  content: string;
  authorFullName: string;
  createdAt: Date;
}

export function WorkflowNote(props: Props) {
  const { authorFullName, content, createdAt } = props;
  return (
    <Box>
      <Stack isInline shouldWrapChildren spacing={2} alignItems="flex-end">
        <Text>
          <AppIcon icon={faUserCircle} />
        </Text>
        <Text fontWeight="bold">{authorFullName}</Text>
        <Text fontSize="sm" mb="2px" color={AppColors.textGray}>
          {DateTime.fromJSDate(createdAt).toFormat(DATE_TIME_FORMAT)}
        </Text>
      </Stack>
      <Box mt={2}>
        <RichContent content={content} />
      </Box>
    </Box>
  );
}

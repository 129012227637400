import {
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  FormErrorMessage,
  FormHelperText,
  Input,
  Heading,
} from "@chakra-ui/react";

import { CancelButton, PrimaryButton } from "../../components/buttons";
import { useRecordManualPayment } from "./record-manual-payment-hook";
import { BillingOverview_PartnerInvoiceFragment } from "../../generated/graphql";
import { currencyFormatter } from "../../util/formatter";

interface Props {
  isOpen: boolean;
  onClose: (refetch?: boolean) => void;
  partnerInvoice: BillingOverview_PartnerInvoiceFragment | null;
}

export function RecordManualPaymentModal(props: Props) {
  const { isOpen, onClose, partnerInvoice } = props;

  const { formik, handleSubmit, amountDue } = useRecordManualPayment({
    partnerInvoice,
    closeRecordPaymentModal: onClose,
  });

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack>
            <Heading size="md">Record Payment</Heading>
            {partnerInvoice && (
              <Heading size="sm">
                Invoice #{partnerInvoice.id}, {currencyFormatter.format(amountDue)} due
              </Heading>
            )}
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={formik.touched.processor && !!formik.errors.processor}>
              <FormLabel>Processor</FormLabel>
              <Input
                id="processor"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.processor}
              />
              <FormErrorMessage>{formik.errors.processor}</FormErrorMessage>
              <FormHelperText>The name of the company that processed the payment</FormHelperText>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={formik.touched.processorReferenceId && !!formik.errors.processorReferenceId}
            >
              <FormLabel>Processor Reference ID</FormLabel>
              <Input
                id="processorReferenceId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.processorReferenceId}
              />
              <FormErrorMessage>{formik.errors.processorReferenceId}</FormErrorMessage>
              <FormHelperText>The identifier of the payment from the processor</FormHelperText>
            </FormControl>
            <FormControl isRequired isInvalid={formik.touched.amount && !!formik.errors.amount}>
              <FormLabel>Payment Amount</FormLabel>
              <Input
                type="number"
                id="amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.amount}
              />
              <FormErrorMessage>{formik.errors.amount}</FormErrorMessage>
              <FormHelperText>The amount of the payment</FormHelperText>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack spacing={2} isInline>
            <CancelButton onClick={onClose.bind(null, false)}>Cancel</CancelButton>
            <PrimaryButton onClick={handleSubmit} isLoading={formik.isSubmitting} isDisabled={!formik.isValid}>
              Save
            </PrimaryButton>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import AppLink, { AppLinkProps } from "../../app-link";
import { AppColors } from "../../../core/custom-theme";

interface Props extends AppLinkProps {
  isActive?: boolean;
}

export function MainNavSmallLink(props: Props) {
  const { isActive, ...rest } = props;
  return (
    <AppLink
      color={isActive ? AppColors.blue : AppColors.textDarkGray}
      textDecoration="none"
      fontSize="16px"
      transition="200ms ease-in"
      fontWeight={isActive ? "bold" : "normal"}
      _hover={{
        color: isActive ? AppColors.textGray : AppColors.textDarkGray,
        textDecoration: "none",
        cursor: "pointer",
      }}
      {...rest}
    />
  );
}

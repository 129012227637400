import { useToast } from "@chakra-ui/toast";
import { useState } from "react";
import {
  useOrderDetails_ContentStrategyOrdersQuery,
  useOrderDetails_DeleteOrderContentStrategyMutation,
} from "../../../generated/graphql";
import { handleGraphQLResponseError } from "../../../util/error-helper";

export function useContentStrategyOrders(orderId: number) {
  const queryResult = useOrderDetails_ContentStrategyOrdersQuery({
    fetchPolicy: "network-only",
    variables: {
      orderId,
    },
  });
  const [deleteOrderContentStrategyMutation, { loading: isDeleting }] =
    useOrderDetails_DeleteOrderContentStrategyMutation();
  const [orderContentStrategyIdToDelete, setOrderContentStrategyIdToDelete] = useState<number | null>(null);
  const toast = useToast();

  async function handleDelete(orderContentStrategyId: number) {
    setOrderContentStrategyIdToDelete(orderContentStrategyId);
  }

  async function handleDeleteCancel() {
    setOrderContentStrategyIdToDelete(null);
  }

  async function handleConfirmDelete() {
    if (!orderContentStrategyIdToDelete) {
      return;
    }
    try {
      const response = await deleteOrderContentStrategyMutation({
        variables: {
          input: {
            orderContentStrategyId: orderContentStrategyIdToDelete,
          },
        },
      });
      if (!response.data?.deleteOrderContentStrategy.ok) {
        throw new Error(
          response.data?.deleteOrderContentStrategy.error?.message ?? "Unable to delete Content Strategy for Order."
        );
      }

      queryResult.refetch();
      setOrderContentStrategyIdToDelete(null);
    } catch (e: any) {
      handleGraphQLResponseError(e, toast);
    }
  }
  const orderContentStrategies = queryResult.data?.order?.customerProposal?.orderContentStrategies ?? [];

  const orderContentStrategyToDelete = orderContentStrategies.find((x) => x.id === orderContentStrategyIdToDelete);

  return {
    loading: queryResult.loading,
    orderId,
    orderContentStrategies,
    orderContentStrategyToDelete,
    isDeleting,
    handleDelete,
    handleDeleteCancel,
    handleConfirmDelete,
  };
}

import { useState } from "react";
import { useClientDetailsEmailsQuery } from "../../generated/graphql";

export function useClientDetailsEmails(customerId: number) {
  const [selectedEmailMessageId, setSelectedEmailMessageId] = useState<number | null>(null);
  const queryResult = useClientDetailsEmailsQuery({
    fetchPolicy: "network-only",
    variables: {
      customerId,
    },
  });

  function onEmailSelected(emailMessageId: number) {
    setSelectedEmailMessageId(emailMessageId);
  }

  function onEmailMessageDetailClose() {
    setSelectedEmailMessageId(null);
  }

  const emailMessages = queryResult.data?.customer?.emailMessages?.nodes ?? [];
  return {
    loading: queryResult.loading,
    customerId,
    emailMessages,
    selectedEmailMessageId,
    onEmailSelected,
    onEmailMessageDetailClose,
  };
}

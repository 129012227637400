import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Flex,
  Box,
  Popover,
  PopoverTrigger,
  Image,
  PopoverContent,
  Stack,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import queryString from "qs";
import { PageHeading } from "../../components/page-heading";
import { Container } from "../../components/layout/container";
import { PanelGroup, PanelBottom, PanelTop } from "../../components/panels";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { useDomainsQuery } from "../../generated/graphql";
import { AdminDomainsTable } from "./admin-domains-table";
import { SearchInput } from "../../components/search-input/search-input";
import { getQueryParams, setParams } from "../../util/query-param-helper";
import { PAGE_LIMIT } from "../../constants/constants";
import FilterSVG from "../../assets/filter.svg";
import { LayoutBase, MainNav } from "../../components/layout";
import { DomainStatusSelector } from "../../common/domain-status-selector";
import { CategorySelector } from "../../common/category-selector";
import { AddDomainsDrawer } from "../../common/add-domains-drawer";

export function AdminDomainsRoute() {
  const [showAddDomainsDrawer, setShowAddDomainsDrawer] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = getQueryParams(location.search, queryString) || {};
  const searchValue = queryParams.search ?? "";
  const pageOffset = queryParams?.offset ? parseInt(queryParams.offset, 10) : 0;
  const statusIds = queryParams.statusId ? [queryParams.statusId] : [];
  const categoryIds = queryParams.categoryId ? [parseInt(queryParams.categoryId, 10)] : [];

  const domainsQueryResult = useDomainsQuery({
    fetchPolicy: "network-only",
    variables: {
      search: searchValue,
      offset: pageOffset,
      limit: PAGE_LIMIT,
      statusIds,
      categoryIds,
    },
  });

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { search, ...remainingQueryParams } = queryParams;
    let searchParams;
    const value = event.target.value;
    if (value) {
      searchParams = setParams(remainingQueryParams, "search", `${value ? value : ""}`);
    } else {
      searchParams = setParams(remainingQueryParams);
    }
    searchParams = setParams(searchParams, "offset", "0");
    navigate({ search: `?${searchParams}` });
  }

  function onDomainStatusSelected(domainStatusId: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { statusId, ...remainingQueryParams } = queryParams;
    let params;
    if (domainStatusId) {
      params = setParams(remainingQueryParams, "statusId", domainStatusId);
    } else {
      params = setParams(remainingQueryParams);
    }
    params = setParams(params, "offset", "0");
    navigate({ search: `?${params}` });
  }

  function onCategorySelected(selectedCategoryId: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { categoryId, ...remainingQueryParams } = queryParams;
    let params;
    if (selectedCategoryId) {
      params = setParams(remainingQueryParams, "categoryId", `${selectedCategoryId}`);
    } else {
      params = setParams(remainingQueryParams);
    }
    params = setParams(params, "offset", "0");
    navigate({ search: `?${params}` });
  }

  function onNextPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = parseInt(offset ?? "0", 10) + PAGE_LIMIT;
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onPreviousPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = Math.max(parseInt(offset ?? "0", 10) - PAGE_LIMIT, 0);
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onDomainsAdded() {
    domainsQueryResult.refetch();
  }

  function openAddDomainsDrawer() {
    setShowAddDomainsDrawer(true);
  }

  function closeAddDomainsDrawer() {
    setShowAddDomainsDrawer(false);
  }

  function openFilter() {
    setShowFilter(true);
  }

  function closeFilter() {
    setShowFilter(false);
  }

  return (
    <LayoutBase pageTitle="Google Reviews">
      <MainNav />
      <AddDomainsDrawer isOpen={showAddDomainsDrawer} onAdded={onDomainsAdded} onClose={closeAddDomainsDrawer} />
      <Container centerHorizontally>
        <Flex alignItems="center">
          <PageHeading>Domains</PageHeading>
        </Flex>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                <SearchInput onChange={onChange} />
              </Box>
              <Box display="flex">
                <Box marginRight={2}>
                  <SecondaryButton onClick={openFilter}>
                    Filter <Image src={FilterSVG} ml={2} />
                  </SecondaryButton>
                  <Popover isOpen={showFilter} onClose={closeFilter}>
                    <PopoverTrigger>
                      <Box top="-8px" position="relative" />
                    </PopoverTrigger>
                    <PopoverContent zIndex={4} maxW={300} padding={4}>
                      <Stack spacing={4}>
                        <FormControl>
                          <FormLabel>Domain</FormLabel>
                          <DomainStatusSelector
                            onDomainStatusSelected={onDomainStatusSelected}
                            selectedDomainStatusId={statusIds.length > 0 ? statusIds[0] : undefined}
                            variant="outline"
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Category</FormLabel>
                          <CategorySelector
                            placeholder="Select a Category"
                            onCategorySelected={onCategorySelected}
                            selectedCategoryId={categoryIds.length > 0 ? categoryIds[0] : undefined}
                            variant="outline"
                          />
                        </FormControl>
                      </Stack>
                    </PopoverContent>
                  </Popover>
                </Box>
                <Box>
                  <PrimaryButton onClick={openAddDomainsDrawer}>Add Domains</PrimaryButton>
                </Box>
              </Box>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <AdminDomainsTable
              domainsQueryResult={domainsQueryResult}
              onPreviousPage={onPreviousPage}
              onNextPage={onNextPage}
            />
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}

import { useState } from "react";

import { useTrackShowEventMutation } from "../../generated/graphql";
import { USER_EVENTS } from "../../constants/user-events";
import { useAuthContext } from "../../core/auth-manager/auth-manager-hook";

interface Options {
  entityId?: number;
  entityName?: string;
}

export function useShowPasswordTrack(options: Options) {
  const { entityId, entityName } = options;
  const authContext = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);
  const [trackUserEvent] = useTrackShowEventMutation();

  async function onPasswordClick() {
    const newValue = !showPassword;
    setShowPassword(newValue);
    if (newValue && entityId && entityName) {
      await trackUserEvent({
        variables: {
          input: {
            userId: authContext.viewer?.user?.id ?? 0,
            entityId: entityId,
            entityName: entityName,
            eventType: USER_EVENTS.CLICKED,
          },
        },
      });
    }
  }

  return {
    onPasswordClick,
    showPassword,
  };
}

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { useRef } from "react";
import { CancelButton, DangerButton } from "../../components/buttons";

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
  title: string;
  onCancel: () => void;
  onConfirm: () => void;
  isProcessing: boolean;
  dangerButtonText: string;
}

export function ConfirmDangerModal(props: Props) {
  const { isOpen, children, title, onCancel, onConfirm, isProcessing, dangerButtonText } = props;
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onCancel}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{children}</AlertDialogBody>

          <AlertDialogFooter>
            <CancelButton ref={cancelRef} onClick={onCancel}>
              Cancel
            </CancelButton>
            <DangerButton colorScheme="red" onClick={onConfirm} ml={3} isLoading={isProcessing}>
              {dangerButtonText}
            </DangerButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useCreatePartnerBillingContactMutation } from "../../generated/graphql";
import { handleFormGraphQLError } from "../../util/error-helper";

interface Options {
  partnerId: number;
  onAdded: () => void;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
}

export function useAddPartnerBillingContactModal(options: Options) {
  const { partnerId, onAdded } = options;
  const [createPartnerBillingContactMutation, { loading: isSubmitting }] = useCreatePartnerBillingContactMutation();
  const toast = useToast();

  const formik = useFormik<FormValues>({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
    },
    onSubmit: async (values, helpers) => {
      try {
        const response = await createPartnerBillingContactMutation({
          variables: {
            input: {
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              partnerId,
            },
          },
        });
        if (response.data?.createPartnerBillingContact.ok) {
          formik.resetForm();
          onAdded();
        } else {
          throw new Error(
            response.data?.createPartnerBillingContact.error?.message ??
              "Something went wrong creating the billing contact. Please try again."
          );
        }
      } catch (e: any) {
        handleFormGraphQLError(e, "Unable to Add Billing Contact", toast, helpers.setErrors);
      }
    },
  });

  return {
    isSubmitting,
    formik,
  };
}

import { DateTime } from "luxon";
import {
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useClipboard } from "use-clipboard-copy";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";
import {
  useCustomerDetails_CreateCustomerUserSsoCodeMutation,
  useCustomerDetails_CustomerUserSsoCodesQuery,
} from "../../generated/graphql";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { DATE_TIME_FORMAT } from "../../constants/constants";

interface Props {
  customerUserId: number;
  isOpen: boolean;
  onClose: () => void;
}

export function SSOManagementModal(props: Props) {
  const { onClose, customerUserId, isOpen } = props;
  const toast = useToast();
  const clipboard = useClipboard();
  const { data, loading, error, refetch } = useCustomerDetails_CustomerUserSsoCodesQuery({
    variables: {
      customerUserId,
    },
  });
  const [createCustomerUserSsoCode] = useCustomerDetails_CreateCustomerUserSsoCodeMutation();
  if (error) {
    toast({ title: "Error", description: "Unable to open Single Sign On details.", status: "error" });
    return null;
  }

  async function createSsoCode() {
    try {
      await createCustomerUserSsoCode({
        variables: {
          input: {
            customerUserId,
            expiresAt: null,
          },
        },
      });
      refetch();
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  function onCopyClick(url: string) {
    clipboard.copy(url);
    toast({ title: "Success", description: "Copied!", status: "success" });
  }

  const ssoCodes = data?.customerUser?.customerUserSsoCodes.nodes ?? [];

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>SSO Management</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table isLoading={loading} isEmpty={ssoCodes.length === 0}>
            <TableHead>
              <TableRow>
                <TableHeaderCell>URL</TableHeaderCell>
                <TableHeaderCell>Created</TableHeaderCell>
                <TableHeaderCell>Expiration</TableHeaderCell>
                <TableHeaderCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {ssoCodes.map((ssoCode) => (
                <TableRow key={ssoCode.id}>
                  <TableCell>{ssoCode.url}</TableCell>
                  <TableCell>{DateTime.fromJSDate(ssoCode.createdAt).toUTC().toFormat(DATE_TIME_FORMAT)}</TableCell>
                  <TableCell>
                    {ssoCode.expiresAt
                      ? DateTime.fromJSDate(ssoCode.expiresAt).toUTC().toFormat(DATE_TIME_FORMAT)
                      : "Does not expire"}
                  </TableCell>
                  <TableCell>
                    <SecondaryButton onClick={onCopyClick.bind(null, ssoCode.url)}>Copy Link</SecondaryButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ModalBody>
        <ModalFooter justifyContent="flex-start">
          <PrimaryButton onClick={createSsoCode}>Create New SSO Link</PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

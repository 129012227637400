import { useParams } from "react-router-dom";
import { groupBy } from "lodash";
import { useState } from "react";
import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";

import {
  usePartnerPackageDetailsQuery,
  useUpdatePartnerPackageItemMutation,
  useUpdatePartnerPackageMutation,
} from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

interface PartnerPackageMonth {
  monthIndex: number;
  items: any[];
}

interface FormValues {
  name: string;
  isActive: boolean;
}

export function usePartnerPackageDetails() {
  const { partnerId: partnerIdRaw, packageId } = useParams<"partnerId" | "packageId">();
  const partnerId = parseInt(partnerIdRaw ?? "", 10);
  const partnerPackageId = parseInt(packageId ?? "", 10);

  //TODO: Check why build failed
  const packageDetailsQueryResult = usePartnerPackageDetailsQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerPackageId,
      partnerId,
    },
  });
  const [updatePartnerPackageMutation] = useUpdatePartnerPackageMutation();
  const [updatePartnerPackageItemMutation] = useUpdatePartnerPackageItemMutation();
  const [addItemMonthIndex, setAddItemMonthIndex] = useState<number | null>(null);
  const toast = useToast();

  const partner = packageDetailsQueryResult.data?.partner ?? null;
  const partnerPackage = packageDetailsQueryResult.data?.partnerPackage ?? null;

  const partnerPackageItems = partnerPackage?.partnerPackageItems ?? [];
  const partnerPackageMonths: PartnerPackageMonth[] = [];
  const groupedByMonth = groupBy(partnerPackageItems, "monthIndex");
  if (partnerPackage) {
    [...Array(partnerPackage.months).keys()].map((month) => {
      partnerPackageMonths.push({
        items: groupedByMonth[month] ?? [],
        monthIndex: month,
      });
    });
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      name: partnerPackage?.name ?? "",
      isActive: partnerPackage?.isActive ?? false,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await updatePartnerPackageMutation({
          variables: {
            input: {
              partnerPackageId,
              isActive: values.isActive,
              name: values.name,
            },
          },
        });
        toast({
          title: "Packaged Updated",
          status: "success",
        });
        packageDetailsQueryResult.refetch();
      } catch (e: any) {
        toast({ title: "Unable to Save", description: getDisplayMessageForError(e), status: "error" });
      }
    },
  });

  async function onQuantityChanged(packageItemId: number, quantity: number) {
    await updatePartnerPackageItemMutation({
      variables: {
        input: {
          partnerPackageItemId: packageItemId,
          quantity,
        },
      },
    });
    await packageDetailsQueryResult.refetch();
  }

  async function onIsRecurringChanged(packageItemId: number, isRecurring: boolean) {
    await updatePartnerPackageItemMutation({
      variables: {
        input: {
          partnerPackageItemId: packageItemId,
          isRecurring,
        },
      },
    });
    await packageDetailsQueryResult.refetch();
  }

  function onAddItem(monthIndex: number) {
    setAddItemMonthIndex(monthIndex);
  }

  function onAddItemCancel() {
    setAddItemMonthIndex(null);
  }

  function onItemAdded() {
    packageDetailsQueryResult.refetch();
  }

  async function onDeleteItem(partnerPackageItemId: number) {
    await updatePartnerPackageItemMutation({
      variables: {
        input: {
          partnerPackageItemId,
          quantity: 0,
        },
      },
    });
    await packageDetailsQueryResult.refetch();
  }

  return {
    partnerId,
    partner,
    partnerPackageId,
    loading: packageDetailsQueryResult.loading,
    partnerPackage,
    partnerPackageMonths,
    onQuantityChanged,
    onIsRecurringChanged,
    addItemMonthIndex,
    onAddItem,
    onAddItemCancel,
    onItemAdded,
    onDeleteItem,
    formik,
  };
}

export const DOMAIN_STATUS = {
  UNKNOWN: {
    id: "unknown",
    name: "Unknown",
  },
  AVAILABLE: {
    id: "available",
    name: "Available",
  },
  PURCHASED: {
    id: "purchased",
    name: "Purchased",
  },
  UNAVAILABLE: {
    id: "unavailable",
    name: "Unavailable",
  },
  PURCHASE_REQUESTED: {
    id: "purchase-requested",
    name: "Purchase Requested",
  },
  PURCHASE_ERROR: {
    id: "purchase-error",
    name: "Purchase Error",
  },
};

import { Heading } from "@chakra-ui/react";

import AppLink from "../../components/app-link";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";

export function AdminReportsRoute() {
  return (
    <LayoutBase pageTitle="Admin Reports">
      <MainNav />
      <Container centerHorizontally>
        <PageHeading>Admin Reports</PageHeading>
        <PanelGroup>
          <PanelTop>
            <Heading size="md">Available Reports</Heading>
          </PanelTop>
          <PanelBottom containsTable>
            <Table isEmpty={false} isLoading={false}>
              <TableHead>
                <TableRow key="head">
                  <TableHeaderCell>Report</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="publication-management">
                  <TableCell>
                    <AppLink to="/admin/reports/publication-management">Publication Management</AppLink>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}

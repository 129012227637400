import { useToast } from "@chakra-ui/react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";

import { BillingOverview_PartnerInvoiceFragment, useRecordManualPaymentMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

interface Options {
  partnerInvoice: BillingOverview_PartnerInvoiceFragment | null;
  closeRecordPaymentModal: (refetch?: boolean) => void;
}

interface FormValues {
  processor: string;
  processorReferenceId: string;
  amount: number;
}

const validationSchema = yup.object().shape({
  processor: yup.string().label("Processor").required().nullable(false),
  processorReferenceId: yup.string().label("Processor Reference ID").required().nullable(false),
  amount: yup.number().label("Amount").required().nullable(false),
});

export function useRecordManualPayment(options: Options) {
  const { partnerInvoice, closeRecordPaymentModal } = options;
  const toast = useToast();
  const [recordManualPayment] = useRecordManualPaymentMutation();

  const amountDue = (partnerInvoice?.totalChargesAmount ?? 0) - (partnerInvoice?.totalPaymentsAmount ?? 0);

  async function onSubmit(values: FormValues, helpers: FormikHelpers<FormValues>) {
    try {
      const response = await recordManualPayment({
        variables: {
          input: {
            partnerInvoiceId: partnerInvoice?.id ?? 0,
            processor: values.processor,
            processorReferenceId: values.processorReferenceId,
            amount: values.amount,
          },
        },
      });
      if (!response.data?.recordPartnerInvoicePayment.ok) {
        throw new Error(
          response.data?.recordPartnerInvoicePayment.error?.message ?? "Something went wrong while recording payment"
        );
      }
      toast({
        title: "Record Payment",
        description: "Payment recorded successfully!",
        status: "success",
      });
      closeRecordPaymentModal(true);
      helpers.resetForm();
    } catch (e: any) {
      toast({ title: "Unable to Record Payment", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      processor: "",
      processorReferenceId: "",
      amount: amountDue,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  function handleSubmit() {
    formik.handleSubmit();
  }

  return {
    formik,
    amountDue,
    handleSubmit,
  };
}

import { useParams } from "react-router-dom";
import { usePartnerInvoiceDetailsQuery, usePartnerInvoice_PayPartnerInvoiceMutation } from "../../generated/graphql";
import { groupBy } from "lodash";
import { PARTNER_INVOICE_PAYMENT_STATUS } from "../../constants/partner-invoice-payment-status";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { handleGraphQLResponseError } from "../../util/error-helper";

function sum(values: number[]) {
  return values.reduce((total, value) => total + value, 0);
}

export function useBillingInvoice() {
  const { partnerId: partnerIdRaw, partnerInvoiceId: partnerInvoiceIdRaw } = useParams<
    "partnerId" | "partnerInvoiceId"
  >();
  const partnerId = parseInt(partnerIdRaw ?? "", 10);
  const partnerInvoiceId = parseInt(partnerInvoiceIdRaw ?? "", 10);
  const [selectedPartnerPaymentMethodId, setSelectedPartnerPaymentMethodId] = useState<number | null>(null);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [isPaymentTermsAccepted, setIsPaymentTermsAccepted] = useState(false);
  const queryResult = usePartnerInvoiceDetailsQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerInvoiceId,
    },
  });
  const [payPartnerInvoiceMutation] = usePartnerInvoice_PayPartnerInvoiceMutation();
  const toast = useToast();

  const partnerInvoice = queryResult.data?.partnerInvoice ?? null;
  const invoiceCharges = partnerInvoice?.partnerInvoiceCharges.nodes ?? [];
  const invoicePayments = partnerInvoice?.partnerInvoicePayments.nodes ?? [];
  const invoiceRefunds = partnerInvoice?.partnerInvoiceRefunds.nodes ?? [];

  // TODO: probably want to filter out payments and refunds from the list items per customer

  const groupedItems = groupBy(invoiceCharges, "customer.id");
  console.log("grouped items", { groupedItems });
  const chargesByCustomer = Object.keys(groupedItems).map((key) => ({
    customerId: parseInt(key, 10),
    customer: groupedItems[key].find((x) => !!x.customer)?.customer,
    items: groupedItems[key].filter((x) => !!x.order),
    totalAmount: groupedItems[key].reduce((total, item) => total + item.amount, 0),
  }));

  const chargesTotal = sum(invoiceCharges.map((x) => x.amount));
  const clearedPaymentsTotal = sum(
    invoicePayments.filter((x) => x.status?.id === PARTNER_INVOICE_PAYMENT_STATUS.CLEARED.id).map((x) => x.amount)
  );

  const totalDue = chargesTotal - clearedPaymentsTotal;

  function handlePartnerPaymentMethodChange(value: number | null) {
    setSelectedPartnerPaymentMethodId(value);
  }

  function handlePaymentTermsAcceptedChange(e: React.ChangeEvent<HTMLInputElement>) {
    setIsPaymentTermsAccepted(e.target.checked);
  }

  async function handleSubmitPayment() {
    if (!selectedPartnerPaymentMethodId) {
      return;
    }
    setIsPaymentProcessing(true);

    // TODO: process payment
    try {
      const response = await payPartnerInvoiceMutation({
        variables: {
          input: {
            partnerInvoiceId,
            partnerPaymentMethodId: selectedPartnerPaymentMethodId,
          },
        },
      });

      if (!response.data?.payPartnerInvoice.ok) {
        throw new Error(
          response.data?.payPartnerInvoice.error?.message ??
            "Something went wrong while trying to submit payment. Please try again."
        );
      }
      if (response.data.payPartnerInvoice.wasPaymentSuccessful) {
        toast({
          title: "Thank you!",
          description:
            "The payment was successful. A copy of the paid invoice has been emailed to the billing contacts.",
          status: "success",
        });
      }
    } catch (e: any) {
      handleGraphQLResponseError(e, toast);
    } finally {
      setIsPaymentProcessing(false);
      queryResult.refetch();
    }
  }

  function handleDownloadPdf() {
    window.open(
      `${process.env.REACT_APP_API_BASE}/files/partners/${partnerId}/invoices/${partnerInvoiceId}?timezone="${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }"`,
      "_blank"
    );
  }

  return {
    partnerId,
    partnerInvoiceId,
    isLoading: queryResult.loading,
    partnerInvoice,
    chargesByCustomer,
    invoicePayments,
    invoiceRefunds,
    totalDue,
    chargesTotal,
    clearedPaymentsTotal,
    selectedPartnerPaymentMethodId,
    handlePartnerPaymentMethodChange,
    handleSubmitPayment,
    handlePaymentTermsAcceptedChange,
    isPaymentProcessing,
    isPaymentTermsAccepted,
    handleDownloadPdf,
  };
}

import AppLink, { AppLinkProps } from "../../app-link";

interface Props extends AppLinkProps {
  isActive?: boolean;
}

/* AppLink is a wrapper adding custom styling to React Routers Link component */
export function SecondaryNavLink(props: Props) {
  const { isActive, ...rest } = props;
  return (
    <AppLink
      color={isActive ? "black" : "#BCBCBC"}
      textDecoration="none"
      fontWeight="semibold"
      fontSize={12}
      borderBottom={isActive ? "2px solid black" : undefined}
      transition="200ms ease-in"
      paddingX={4}
      paddingTop="12px"
      paddingBottom="8px"
      _hover={{
        color: isActive ? "black" : "#d5d5d5",
      }}
      _focus={{
        outline: "none",
      }}
      {...rest}
    />
  );
}

import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useContentStrategyV2_UpdateCustomerProposalMutation } from "../../generated/graphql";
import { handleGraphQLResponseError } from "../../util/error-helper";
import { FormHelpers } from "../../util/form-helpers";

interface Options {
  customerProposalId: number;
  summary?: string | null;
  handleComplete: (isApproved?: boolean, reviewComment?: string) => void;
}

interface FormValues {
  summary: string;
  isApproved?: boolean;
  comments?: string;
}

const validationSchema = yup.object().shape({
  isApproved: yup.bool().label("Is Approved").required().nullable(false),
  comments: yup.string().when("isApproved", {
    is: true,
    then: yup.string().label("Comments").notRequired().nullable(true),
  }),
});

export function useSummary(options: Options) {
  const { customerProposalId, summary, handleComplete } = options;

  const [updateCustomerProposal] = useContentStrategyV2_UpdateCustomerProposalMutation();
  const toast = useToast();
  const formik = useFormik<FormValues>({
    initialValues: {
      summary: summary ?? "",
      isApproved: true,
      comments: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        const response = await updateCustomerProposal({
          variables: {
            input: {
              customerProposalId,
              summary: FormHelpers.processNullableString(values.summary),
            },
          },
        });
        if (!response.data?.updateCustomerProposal.ok) {
          throw new Error(
            response.data?.updateCustomerProposal.error?.message ??
              "Something went wrong while saving. Please try again."
          );
        }
      } catch (e: any) {
        handleGraphQLResponseError(e, toast, helpers.setErrors);
      }
    },
  });

  function handleCancel() {
    formik.resetForm();
  }

  function onSubmit() {
    handleComplete(formik.values.isApproved, formik.values.comments);
  }

  return {
    customerProposalId,
    formik,
    handleCancel,
    onSubmit,
  };
}

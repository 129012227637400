import { useToast } from "@chakra-ui/react";
import { DateTime, Interval } from "luxon";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useAuthContext } from "../../../core/auth-manager/auth-manager-hook";
import {
  CustomerCampaignInvoiceChargeFragment,
  RoadmapAmTaskFragment,
  RoadmapOrderFragment,
  useCustomerRoadmapCampaignsQuery,
  useRoadmapDeleteOrderMutation,
  useRoadmapDeleteAmTaskMutation,
} from "../../../generated/graphql";
import { isSameOrBetween } from "../../../util/datetime-helpers";
import { CAMPAIGN_CATEGORIES } from "../../../constants/campaign-category";

interface BillingCycle {
  startsAt: Date;
  endsAt: Date;
  orders: RoadmapOrderFragment[];
  amTasks: RoadmapAmTaskFragment[];
  customerCampaignInvoiceCharge?: CustomerCampaignInvoiceChargeFragment;
}

export function useClientDetailsRoadmap(customerId: number) {
  const [selectedCampaignId, setSelectedCampaignId] = useState<number | null>(null);
  const [orderIdBeingEdited, setOrderIdBeingEdited] = useState<number | null>(null);
  const [addOrderDateRange, setAddOrderDateRange] = useState<[Date, Date] | null>(null);
  const [addAmTaskDateRange, setAddAmTaskDateRange] = useState<[Date, Date] | null>(null);
  const [orderIdForForceDelete, setOrderIdForForceDelete] = useState<number | null>(null);
  const [amTaskBeingEdited, setAmTaskBeingEdited] = useState<number | null>(null);
  const [amTaskForDelete, setAmTaskForDelete] = useState<number | null>(null);
  const customerCampaignsQueryResult = useCustomerRoadmapCampaignsQuery({
    fetchPolicy: "network-only",
    variables: {
      customerId,
      campaignCategoryId: CAMPAIGN_CATEGORIES.SEO.id,
    },
  });
  const [deleteOrderMutation] = useRoadmapDeleteOrderMutation();
  const [deleteAmTaskMutation] = useRoadmapDeleteAmTaskMutation();
  const toast = useToast();
  const { isSuperAdmin, isAdmin } = useAuthContext();

  const customerCampaigns = useMemo(
    () => customerCampaignsQueryResult.data?.customer?.customerCampaigns.nodes ?? [],
    [customerCampaignsQueryResult]
  );
  const campaignOrders =
    customerCampaignsQueryResult.data?.orders.nodes.filter((x) => x.customerCampaignId === selectedCampaignId ?? 0) ??
    [];

  const campaign = customerCampaigns.find((x) => x.id === selectedCampaignId);
  const startDate = campaign?.startsAt ?? new Date();
  const endDate = campaign?.endsAt ?? new Date();

  const campaignAmTasks =
    customerCampaignsQueryResult.data?.amTasks.nodes.filter((x) => {
      return isSameOrBetween(x.completedAt, startDate, endDate);
    }) ?? [];

  useEffect(() => {
    if (!selectedCampaignId && !customerCampaignsQueryResult.loading && customerCampaigns.length > 0) {
      const campaign = customerCampaigns.find((campaign) => campaign.isCurrent);
      setSelectedCampaignId(campaign?.id ?? customerCampaigns[customerCampaigns.length - 1].id);
    }
  }, [customerCampaigns, selectedCampaignId, customerCampaignsQueryResult.loading]);

  function selectCustomerCampaign(customerCampaignId: number) {
    setSelectedCampaignId(customerCampaignId);
  }

  function editOrder(orderId: number) {
    setOrderIdBeingEdited(orderId);
  }

  function cancelEditOrder() {
    setOrderIdBeingEdited(null);
  }

  function onOrderEdited() {
    customerCampaignsQueryResult.refetch();
    setOrderIdBeingEdited(null);
  }

  function addOrder(billingCycleStartDate: Date, billingCycleEndDate: Date) {
    cancelEditOrder();
    setAddOrderDateRange([billingCycleStartDate, billingCycleEndDate]);
  }

  function cancelAddOrder() {
    setAddOrderDateRange(null);
  }

  function onOrderAdded() {
    setAddOrderDateRange(null);
    customerCampaignsQueryResult.refetch();
  }

  function addAmTask(billingCycleStartDate: Date, billingCycleEndDate: Date) {
    setAddAmTaskDateRange([billingCycleStartDate, billingCycleEndDate]);
  }

  function cancelAddAmTask() {
    setAddAmTaskDateRange(null);
  }

  function onAmTaskAdded() {
    setAddAmTaskDateRange(null);
    customerCampaignsQueryResult.refetch();
  }

  async function requestForceDelete(orderId: number) {
    setOrderIdForForceDelete(orderId);
  }

  async function cancelForceDeleteRequest() {
    setOrderIdForForceDelete(null);
  }

  async function handleOrderForceDeleted() {
    setOrderIdForForceDelete(null);
    customerCampaignsQueryResult.refetch();
  }

  async function addContentStrategy() {
    if (!isAdmin()) {
      return null;
    }
    await axios.get(`${process.env.REACT_APP_API_BASE}/admin/jobs/process-needed-content-strategies`, {
      withCredentials: true,
    });
    toast({ title: "Add Content Strategy", description: "Need Content strategy job created.", status: "success" });
  }

  async function deleteOrder(orderId: number) {
    const matchingOrder = campaignOrders.find((x) => x.id === orderId);
    if (!matchingOrder) {
      return;
    }
    if (!matchingOrder.canDelete && isSuperAdmin()) {
      setOrderIdForForceDelete(orderId);
      return;
    }
    const response = await deleteOrderMutation({
      variables: {
        input: {
          orderId,
        },
      },
    });

    if (response.data?.deleteOrder.isDeleted ?? false) {
      toast({ title: "Order Deleted", description: `Order ${orderId} was deleted.`, status: "info" });
      await customerCampaignsQueryResult.refetch();
    }
  }

  function onEditAmTask(amTaskId: number) {
    setAmTaskBeingEdited(amTaskId);
  }

  function onAmTaskEdited() {
    setAmTaskBeingEdited(null);
    customerCampaignsQueryResult.refetch();
  }

  function onCancelEditAmTask() {
    setAmTaskBeingEdited(null);
  }

  function onDeleteAmTask(amTaskId: number) {
    setAmTaskForDelete(amTaskId);
  }

  async function deleteAmTask() {
    try {
      const response = await deleteAmTaskMutation({
        variables: {
          input: {
            amTaskId: amTaskForDelete ?? 0,
          },
        },
      });

      if (response.data?.deleteAmTask.ok) {
        toast({ title: "Am Task Deleted", description: `Am Task ${amTaskForDelete} was deleted.`, status: "success" });
        setAmTaskForDelete(null);
        await customerCampaignsQueryResult.refetch();
      } else {
        throw new Error(response.data?.deleteAmTask.error?.message ?? "Something went wrong while deleting Am Task");
      }
    } catch (e: any) {
      toast({ title: "Am Task Delete", description: e, status: "error" });
    }
  }

  function onCancelDeleteAmTask() {
    setAmTaskForDelete(null);
  }

  const selectedCustomerCampaign = selectedCampaignId
    ? customerCampaigns.find((x) => x.id === selectedCampaignId)
    : null;

  const billingCycles: BillingCycle[] = [];
  if (selectedCustomerCampaign) {
    const campaignInterval = Interval.fromDateTimes(selectedCustomerCampaign.startsAt, selectedCustomerCampaign.endsAt);
    const monthsInCampaign = Math.round(campaignInterval.length("months"));

    for (let i = 0; i < monthsInCampaign; i++) {
      const startsAt = DateTime.fromJSDate(selectedCustomerCampaign.startsAt).toUTC().plus({ months: i });
      const endsAt = startsAt.plus({ months: 1 }).minus({ days: 1 }).endOf("day");

      billingCycles.push({
        startsAt: startsAt.toJSDate(),
        endsAt: endsAt.toJSDate(),
        orders: campaignOrders.filter((order) => {
          return isSameOrBetween(order.startDate, startsAt.toJSDate(), endsAt.toJSDate());
        }),
        amTasks: campaignAmTasks.filter((amTask) => {
          return isSameOrBetween(amTask.completedAt, startsAt.toJSDate(), endsAt.toJSDate());
        }),
        customerCampaignInvoiceCharge: selectedCustomerCampaign.customerCampaignInvoiceCharges?.find(
          (x) => x.monthIndex === i
        ),
      });
    }
  }

  return {
    customerCampaignsLoading: customerCampaignsQueryResult.loading,
    customerCampaigns,
    customer: customerCampaignsQueryResult.data?.customer ?? null,
    orders: campaignOrders,
    selectedCustomerCampaign,
    selectCustomerCampaign,
    selectedCustomerCampaignBillingCycles: billingCycles,
    editOrder,
    orderIdBeingEdited,
    cancelEditOrder,
    onOrderEdited,
    deleteOrder,
    addOrder,
    addOrderDateRange,
    cancelAddOrder,
    onOrderAdded,
    orderIdForForceDelete,
    requestForceDelete,
    cancelForceDeleteRequest,
    handleOrderForceDeleted,
    isSuperAdmin: isSuperAdmin(),
    isAdmin: isAdmin(),
    addContentStrategy,
    addAmTaskDateRange,
    addAmTask,
    onAmTaskAdded,
    cancelAddAmTask,
    amTaskBeingEdited,
    onEditAmTask,
    onAmTaskEdited,
    onCancelEditAmTask,
    amTaskForDelete,
    onDeleteAmTask,
    deleteAmTask,
    onCancelDeleteAmTask,
  };
}

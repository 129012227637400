import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  Select,
  SimpleGrid,
  Input,
  Text,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";

import { PrimaryButton } from "../../components/buttons";
import { DATE_PICKER_FORMAT } from "../../constants/constants";
import { Loader } from "../../components/loader";
import { DangerButton } from "../../components/buttons";
import { CUSTOMER_STATUS } from "../../constants/customer-status";
import { AlertWarning } from "../../components/alerts";
import { useClientStatus } from "./use-client-status";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onEdited: () => void;
  customerId: number;
}

export function ClientStatusDrawer(props: Props) {
  const { onClose, onEdited, isOpen, customerId } = props;

  const {
    loading,
    deleteClientStatusLoading,
    currentStatus,
    customer,
    formik,
    handleDateChange,
    handleRemoveStatus,
    getScheduleChangeDate,
    scheduledStatusChange,
  } = useClientStatus({
    onEdited,
    customerId,
  });

  return (
    <>
      {loading && <Loader type="content" />}
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md" closeOnOverlayClick={false}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Edit Client Status</DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <FormControl isInvalid={!!formik.errors.status && !!formik.touched.status}>
                <FormLabel>Status</FormLabel>
                <Select
                  id="status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  variant="outline"
                  isDisabled={!!scheduledStatusChange}
                >
                  <option value="">Select a Status</option>
                  {currentStatus?.id !== CUSTOMER_STATUS.ACTIVE.id && (
                    <option value={CUSTOMER_STATUS.ACTIVE.id}>
                      {`${CUSTOMER_STATUS.ACTIVE.name} ${getScheduleChangeDate()}`}
                    </option>
                  )}
                  {currentStatus?.id !== CUSTOMER_STATUS.PAUSED.id && (
                    <option value={CUSTOMER_STATUS.PAUSED.id}>
                      {`${CUSTOMER_STATUS.PAUSED.name} ${getScheduleChangeDate()}`}
                    </option>
                  )}
                  {currentStatus?.id !== CUSTOMER_STATUS.CANCELLED.id && (
                    <option value={CUSTOMER_STATUS.CANCELLED.id}>
                      {`${CUSTOMER_STATUS.CANCELLED.name} ${getScheduleChangeDate()}`}
                    </option>
                  )}
                </Select>
                <FormErrorMessage>{formik.errors.status}</FormErrorMessage>
              </FormControl>
              {customer?.partner.isBillingEnforced && formik.values.status === CUSTOMER_STATUS.PAUSED.id && (
                <AlertWarning>
                  <Stack spacing={1} shouldWrapChildren>
                    <Text>
                      <strong>NOTE</strong>
                    </Text>
                    <Text>Paid orders will not be refunded.</Text>
                  </Stack>
                </AlertWarning>
              )}
              {customer?.partner.isBillingEnforced && formik.values.status === CUSTOMER_STATUS.CANCELLED.id && (
                <AlertWarning>
                  <Stack spacing={1} shouldWrapChildren>
                    <Text>
                      <strong>NOTE</strong>
                    </Text>
                    <Text>Paid, unstarted orders will be refunded within 7-14 days.</Text>
                  </Stack>
                </AlertWarning>
              )}
              <FormControl isInvalid={!!formik.errors.effectiveAt && !!formik.touched.effectiveAt}>
                <FormLabel>Effective Date</FormLabel>
                <DatePicker
                  onChange={handleDateChange}
                  selected={formik.values.effectiveAt}
                  minDate={DateTime.utc().plus({ days: 2 }).startOf("day").toJSDate()}
                  dateFormat={DATE_PICKER_FORMAT}
                  customInput={<Input variant="outline" width="100%" />}
                  placeholderText="Select effective date"
                  disabledKeyboardNavigation
                />
                <FormErrorMessage>{formik.errors.effectiveAt}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!formik.errors.reason && !!formik.touched.reason}>
                <FormLabel>Reason</FormLabel>
                <Textarea
                  id="reason"
                  value={formik.values.reason}
                  onChange={formik.handleChange}
                  resize="none"
                  placeholder="Reason"
                />
                <FormErrorMessage>{formik.errors.reason}</FormErrorMessage>
              </FormControl>
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <SimpleGrid columns={2} gridGap={4} width="100%">
              <PrimaryButton width="100%" onClick={formik.submitForm} isDisabled={formik.isSubmitting}>
                Save
              </PrimaryButton>
              {!!scheduledStatusChange && (
                <DangerButton width="100%" onClick={handleRemoveStatus} isDisabled={deleteClientStatusLoading}>
                  Remove Change
                </DangerButton>
              )}
            </SimpleGrid>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

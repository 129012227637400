import { useAddPartnerPackageDrawer_CreatePartnerPackageMutation } from "../../generated/graphql";
import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import { getDisplayMessageForError } from "../../util/error-helper";
import * as yup from "yup";

interface Options {
  partnerId: number;
  onAdded: (partnerPackageId: number) => void;
  onClose: () => void;
}

interface FormValues {
  name: string;
  months: number;
  monthlySpend: number;
}

const validationSchema = yup.object().shape({
  name: yup.string().label("Partner Package Name").required(),
  monthlySpend: yup.number().label("Monthly Spend").required().min(0),
  months: yup.number().label("Months").required().min(1),
});

export function useAddPartnerPackage(options: Options) {
  const { onAdded, onClose, partnerId } = options;
  const [addPartnerPackageMutation] = useAddPartnerPackageDrawer_CreatePartnerPackageMutation();
  const toast = useToast();

  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      monthlySpend: 1000,
      months: 6,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await addPartnerPackageMutation({
          variables: {
            input: {
              monthlySpend: values.monthlySpend,
              months: values.months,
              name: values.name,
              partnerId,
            },
          },
        });
        if (response.data?.createPartnerPackage.partnerPackage) {
          onAdded(response.data.createPartnerPackage.partnerPackage.id);
        }
      } catch (e: any) {
        toast({ title: "Unable to Add Partner Package", description: getDisplayMessageForError(e), status: "error" });
      }
    },
  });

  function onCancel() {
    formik.resetForm();
    onClose();
  }

  return {
    formik,
    onCancel,
  };
}

import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import AppIcon from "../../components/app-icon";
import { SecondaryNav, SecondaryNavLink } from "../../components/layout";
import { useAuthContext } from "../../core/auth-manager/auth-manager-hook";

interface Props {
  partnerId: string;
  activeSection?: string;
}

const NAV_ITEMS = [
  {
    section: "product-types",
    displayName: "Product Types",
    isAdminRequired: false,
  },
  {
    section: "available-product-types",
    displayName: "Available Product Types",
    isAdminRequired: true,
  },
  {
    section: "packages",
    displayName: "Packages",
    isAdminRequired: false,
  },
  {
    section: "users",
    displayName: "Users",
    isAdminRequired: false,
  },
  {
    section: "admin",
    displayName: "Admin Settings",
    isAdminRequired: true,
  },
];

export function ConfigurationSecondaryNav(props: Props) {
  const { partnerId, activeSection } = props;
  const authContext = useAuthContext();
  return (
    <SecondaryNav title="Configuration">
      {NAV_ITEMS.map((item) => {
        if (item.isAdminRequired && !authContext.isAdmin()) {
          return null;
        }
        return (
          <SecondaryNavLink
            key={item.section}
            isActive={activeSection === item.section}
            to={`/configuration/${partnerId}/${item.section}`}
          >
            {item.isAdminRequired && <AppIcon icon={faShieldAlt} standardRightMargin />}
            {item.displayName}
          </SecondaryNavLink>
        );
      })}
    </SecondaryNav>
  );
}

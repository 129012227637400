import AppLink from "../../components/app-link";
import { FormattedDate } from "../../components/formatted-date";
import {
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TablePagination,
  TableRow,
} from "../../components/table";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { usePublishers } from "./use-publishers";

export function PublishersTable() {
  const { pagination, publishers, totalCount, loading } = usePublishers();

  return (
    <Table isEmpty={publishers.length === 0} isLoading={loading}>
      <TableHead>
        <TableRow>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Email</TableHeaderCell>
          <TableHeaderCell>Created</TableHeaderCell>
          <TableHeaderCell>Number of Link Locations</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {publishers.map((publisher) => (
          <TableRow key={publisher.id}>
            <TableCell>
              <AppLink to={`/outreach/publishers/${publisher.id}`}>{publisher.name}</AppLink>
            </TableCell>
            <TableCell>{publisher.contactEmail}</TableCell>
            <TableCell>
              <FormattedDate format={DATE_MONTH_FORMAT} date={publisher.createdAt} />
            </TableCell>
            <TableCell>{publisher?.publisherLinkLocations?.totalCount ?? 0}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFoot>
        <TablePagination totalCount={totalCount} {...pagination} />
      </TableFoot>
    </Table>
  );
}

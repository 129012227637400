import { useState } from "react";
import { Text, BoxProps, Tooltip, Stack } from "@chakra-ui/react";
import { PrimaryLinkButton } from "../buttons";

interface Props extends Omit<BoxProps, "children"> {
  maxLength: number;
  children: string;
  expandable?: boolean;
}

export function TruncatedText(props: Props) {
  const { children, maxLength, expandable, ...rest } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  function expand() {
    setIsExpanded(true);
  }

  function collapse() {
    setIsExpanded(false);
  }

  if (children.length < maxLength) {
    return <Text {...rest}>{children}</Text>;
  } else if (isExpanded) {
    return (
      <Text>
        <Tooltip hasArrow label={children} aria-label={children}>
          <Text {...rest}>{children}</Text>
        </Tooltip>
        <PrimaryLinkButton onClick={collapse} size="sm">
          Show less
        </PrimaryLinkButton>
      </Text>
    );
  } else {
    return (
      <Stack spacing={1}>
        <Tooltip label={children} aria-label={children}>
          <Text {...rest}>{children.substring(0, maxLength)}&hellip;</Text>
        </Tooltip>
        {expandable && (
          <Text>
            <PrimaryLinkButton onClick={expand} size="sm">
              Show more
            </PrimaryLinkButton>
          </Text>
        )}
      </Stack>
    );
  }
}

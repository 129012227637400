import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
  TablePagination,
  TableFoot,
} from "../../components/table";
import { DateTime } from "luxon";
import { Text } from "@chakra-ui/react";
import AppLink from "../../components/app-link";
import { CustomerDetailConversationsQueryResult } from "../../generated/graphql";
import { AppColors } from "../../core/custom-theme";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { DATE_TIME_FORMAT, PAGE_LIMIT } from "../../constants/constants";
import AppIcon from "../../components/app-icon";

interface Props {
  queryResult: CustomerDetailConversationsQueryResult;
  onPreviousPage: () => void;
  onNextPage: () => void;
  customerId: number;
}

function ConversationsTable(props: Props) {
  const { queryResult, onNextPage, onPreviousPage, customerId } = props;
  const { data, loading } = queryResult;

  const totalCount = data?.customerConversations?.totalCount ?? 0;
  const customerConversations = data?.customerConversations.nodes ?? [];

  return (
    <Table isEmpty={customerConversations.length === 0} isLoading={loading}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>Subject</TableHeaderCell>
          <TableHeaderCell>Created At</TableHeaderCell>
          <TableHeaderCell>Last Client Message</TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {customerConversations?.map((conversation) => {
          const message = conversation.customerMessages.nodes.find((message) => message.isFromCustomer);
          return (
            <TableRow key={conversation.id}>
              <TableCell>
                <AppLink to={`/clients/${customerId}/messages/${conversation.id}`}>{conversation.subject}</AppLink>
              </TableCell>
              <TableCell>{DateTime.fromJSDate(conversation.createdAt).toFormat(DATE_TIME_FORMAT)}</TableCell>
              <TableCell>{message ? DateTime.fromJSDate(message.createdAt).toRelative() : "-"}</TableCell>
              <TableCell>
                {conversation.helpRequestedAt && (
                  <Text color={AppColors.textNormal}>
                    <AppIcon icon={faExclamationTriangle} standardRightMargin color="#FF8541" />
                    Help Requested
                  </Text>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <TableFoot>
        <TablePagination
          totalCount={totalCount}
          offset={queryResult.variables?.offset ?? 0}
          limit={queryResult.variables?.limit ?? PAGE_LIMIT}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
      </TableFoot>
    </Table>
  );
}

export default ConversationsTable;

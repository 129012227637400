export const CUSTOMER_STATUS = {
  ACTIVE: {
    id: "active",
    name: "Active",
  },
  PAUSED: {
    id: "paused",
    name: "Paused",
  },
  CANCELLED: {
    id: "cancelled",
    name: "Cancelled",
  },
};

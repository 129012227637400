import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Flex, Heading, Stack, Text, useToast } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { PanelBottom, PanelGroup, PanelMiddle, PanelTop } from "../../../components/panels";
import { DangerButton, SecondaryButton } from "../../../components/buttons";
import { AppColors } from "../../../core/custom-theme";
import { AlertWarning } from "../../../components/alerts";
import { Table, TableBody } from "../../../components/table";
import { DataDisplayRow } from "../../../components/data-display-row";
import { DATE_TIME_FORMAT } from "../../../constants/constants";
import { HelpText } from "../../../components/help-text";
import { useOrderDetailsOverview } from "./overview-hook";
import { Loader } from "../../../components/loader";
import { getDisplayMessageForError } from "../../../util/error-helper";
import { Navigate } from "react-router-dom";
import { EditOrderDrawer } from "../../../common/edit-order-drawer";
import AppLink from "../../../components/app-link";
import { ForceDeleteOrderModal } from "../../../common/force-delete-order-modal";
import { AdminOnly } from "../../../common/admin-only";
import { FULFILLMENT_OPTIONS } from "../../../constants/fulfillment-options";
import { ConfirmOutreachModal } from "./confirm-outreach-modal";

interface Props {
  orderId: number;
}

export function OrderDetailsOverview({ orderId }: Props) {
  const toast = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const {
    loading,
    order,
    error,
    refetch,
    showDelete,
    handleDeleteCancel,
    handleOnDeleted,
    handleDelete,
    onReCreateGuestOutreach,
    showWarningGuestOutreach,
    onClickOutreach,
    onCloseGuestOutreach,
  } = useOrderDetailsOverview(orderId);

  if (loading) {
    return <Loader type="panel" />;
  }
  if (error) {
    toast({ title: "Unable to View Order Details", description: getDisplayMessageForError(error), status: "error" });
    return <Navigate to="/orders" />;
  }
  if (!order) {
    toast({ title: "Invalid Order", description: `Invalid order (ID: ${orderId}) specified.`, status: "error" });
    return <Navigate to="/orders" />;
  }

  return (
    <>
      <EditOrderDrawer
        open={isEditing}
        orderId={order.id}
        onCancel={() => setIsEditing(false)}
        onEdited={() => {
          refetch();
          setIsEditing(false);
        }}
      />
      {showDelete && (
        <ForceDeleteOrderModal onCancel={handleDeleteCancel} onDeleted={handleOnDeleted} orderId={orderId} />
      )}
      {showWarningGuestOutreach && (
        <ConfirmOutreachModal orderId={orderId} onCancel={onCloseGuestOutreach} onReCreate={onReCreateGuestOutreach} />
      )}
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Flex justifyContent="space-between" alignItems="center">
            <Heading size="md">Basic Information</Heading>
            <Stack isInline spacing={2}>
              <SecondaryButton color={AppColors.blue} variant="outline" onClick={() => setIsEditing(true)}>
                Edit
              </SecondaryButton>
              <AdminOnly requiresSuperAdmin>
                <DangerButton onClick={handleDelete}>Delete</DangerButton>
              </AdminOnly>
              <AdminOnly requiresSuperAdmin>
                <DangerButton onClick={onClickOutreach}>Re Create Guest Outreach Task</DangerButton>
              </AdminOnly>
            </Stack>
          </Flex>
        </PanelTop>
        {order.orderIssues.length > 0 && (
          <PanelMiddle>
            {order.orderIssues.map((orderIssue, index) => (
              <AlertWarning key={index}>{orderIssue.message}</AlertWarning>
            ))}
          </PanelMiddle>
        )}
        <PanelBottom containsTable>
          <Table isEmpty={false} isLoading={loading}>
            <TableBody>
              <DataDisplayRow label="Order ID" value={order.id} copyValue={order.id.toString()} />
              <DataDisplayRow
                label="Start Date"
                value={DateTime.fromJSDate(order.startDate).toFormat(DATE_TIME_FORMAT)}
              />
              <DataDisplayRow label="Status" value={order.status ? order.status.name : ""} />
              <DataDisplayRow
                label="Client"
                value={
                  <AppLink to={`/clients/${order.customer.id}`}>
                    {order.customer.name} (ID: {order.customerId})
                  </AppLink>
                }
              />
              <DataDisplayRow
                label="Partner Product"
                value={
                  <Text>
                    {order.partnerProduct.name}
                    <HelpText standardLeftMargin>
                      Base Product Type: <strong>{order.partnerProduct.product.name}</strong>
                    </HelpText>
                  </Text>
                }
              />
              <DataDisplayRow label="Created By User" value={`${order.user.firstName} ${order.user.lastName}`} />
              <DataDisplayRow label="Result URL" value={order.resultUrl ?? "-"} copyValue={order.resultUrl ?? ""} />
              <DataDisplayRow label="Result URL Domain Authority" value={order.resultUrlDomainAuthority ?? "-"} />
              <DataDisplayRow label="Target Result URL" value={order.targetResultUrl ?? "-"} />
              <DataDisplayRow
                label="Fulfillment Method"
                value={
                  order.orderExternalContentSpec
                    ? FULFILLMENT_OPTIONS.EXTERNAL_FULFILLMENT.display
                    : FULFILLMENT_OPTIONS.INTERNAL_FULFILLMENT.display
                }
              />
              {!!order.orderExternalContentSpec && (
                <DataDisplayRow label="External Vendor" value={order.orderExternalContentSpec.vendor.name} />
              )}
              {!!order.orderExternalContentSpec && (
                <DataDisplayRow label="External Order ID" value={order.orderExternalContentSpec.externalOrderId} />
              )}
              {!!order.instructions && (
                <DataDisplayRow label="Instructions" value={<ReactMarkdown>{order.instructions}</ReactMarkdown>} />
              )}
            </TableBody>
          </Table>
        </PanelBottom>
      </PanelGroup>
    </>
  );
}

import { BoxProps, Text } from "@chakra-ui/react";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { AppColors } from "../../core/custom-theme";
import AppIcon from "../app-icon";

interface Props extends BoxProps {
  changeAmount: number | null;
  absoluteValue?: number | null;
  hideDownValue?: boolean;
}

export function ChangeIndicatorAbsoluteValue(props: Props) {
  const { changeAmount, absoluteValue, hideDownValue, ...rest } = props;

  if (changeAmount === null || absoluteValue === null || absoluteValue === undefined) {
    return <Text {...rest}>-</Text>;
  }

  if (changeAmount === 0 || (hideDownValue && changeAmount < 0)) {
    return <Text {...rest}>{absoluteValue.toFixed(0)}</Text>;
  }

  let icon = faArrowUp;
  let color = AppColors.green;
  if (changeAmount < 0) {
    color = AppColors.error;
    icon = faArrowDown;
  }

  return (
    <Text color={color} {...rest}>
      <AppIcon icon={icon} standardRightMargin />
      {absoluteValue.toFixed(0)}
    </Text>
  );
}

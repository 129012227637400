import { Badge } from "@chakra-ui/react";
import { QUESTIONNAIRE_STATUS } from "../../constants/questionnaire-status";

interface Props {
  values: { [index: string]: any };
  isValid: boolean;
}

export function QuestionnaireStatusIndicator(props: Props) {
  const { values, isValid } = props;

  const formValues = Object.entries(values);
  const notNullCount = formValues.filter((value: any[]) => !!value[1]).length;
  const length = formValues.length;
  let status = QUESTIONNAIRE_STATUS.PENDING;
  if (length > 0) {
    if (notNullCount === 0) {
      status = QUESTIONNAIRE_STATUS.PENDING;
    } else if (isValid) {
      status = QUESTIONNAIRE_STATUS.COMPLETED;
    } else {
      status = QUESTIONNAIRE_STATUS.IN_PROGRESS;
    }
  }

  let color: string | undefined = undefined;
  let name = "";
  if (status === QUESTIONNAIRE_STATUS.COMPLETED) {
    color = "green";
    name = "Completed";
  } else if (status === QUESTIONNAIRE_STATUS.IN_PROGRESS) {
    color = "yellow";
    name = "In Progress";
  } else if (status === QUESTIONNAIRE_STATUS.PENDING) {
    color = "red";
    name = "Not Started";
  }
  return <Badge colorScheme={color}>{name}</Badge>;
}

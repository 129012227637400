import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrayParam,
  DelimitedNumericArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import { useToast } from "@chakra-ui/react";

import { AdminTasks_TaskFragment, useAdminTasksQuery, useAdminTasks_UpdateTaskMutation } from "../../generated/graphql";
import { usePagination } from "../../util/use-pagination";
import { PAGE_LIMIT } from "../../constants/constants";
import { handleGraphQLResponseError } from "../../util/error-helper";

export function useAdminTasks() {
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [statusIds, setStatusIds] = useQueryParam("statusIds", withDefault(ArrayParam, []));
  const [taskTypeIds, setTaskTypeIds] = useQueryParam("taskTypeIds", withDefault(ArrayParam, []));
  const [vendorIds, setVendorIds] = useQueryParam("vendorIds", withDefault(DelimitedNumericArrayParam, []));
  const [customerId, setCustomerIdParam] = useQueryParam("customerId", NumberParam);
  const [userId, setUserId] = useQueryParam("userId", withDefault(NumberParam, null));
  const [taskIdToUnassign, setTaskIdToUnassign] = useState<number | null>(null);
  const [taskForVendorChange, setTaskForVendorChange] = useState<AdminTasks_TaskFragment | null>(null);
  const [startsAt, setStartsAt] = useState(DateTime.now().minus({ month: 1 }).toJSDate());
  const [endsAt, setEndsAt] = useState(DateTime.now().toJSDate());
  const navigate = useNavigate();
  const pagination = usePagination(PAGE_LIMIT);
  const queryResult = useAdminTasksQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      statusIds: statusIds as string[],
      customerIds: customerId ? [customerId] : [],
      vendorIds: vendorIds as number[],
      taskTypeIds: taskTypeIds as string[],
      search: search && search.length > 0 ? search : undefined,
      assignedAt: {
        gte: startsAt,
        lte: endsAt,
      },
      assignedUserIds: userId ? [userId] : [],
    },
  });
  const [updateTaskMutation, { loading: isUpdatingTask }] = useAdminTasks_UpdateTaskMutation();
  const toast = useToast();
  let filterCount = 0;
  if (search && search.length > 0) {
    filterCount++;
  }
  if (statusIds.length > 0) {
    filterCount++;
  }
  if (customerId) {
    filterCount++;
  }
  if (vendorIds.length > 0) {
    filterCount++;
  }
  if (taskTypeIds.length > 0) {
    filterCount++;
  }
  if (userId) {
    filterCount++;
  }
  if (startsAt && endsAt) {
    filterCount++;
  }

  const tasks = queryResult.data?.tasks.nodes ?? [];

  function handleStatusIdsChange(newValue: string[]) {
    setStatusIds(newValue);
    pagination.resetOffset();
  }

  function onCustomerIdChange(customerId: number | null) {
    setCustomerIdParam(customerId ?? undefined);
    pagination.resetOffset();
  }

  function clearFilters() {
    navigate({ search: "" });
  }

  function handleVendorIdsChange(newVendorIds: number[]) {
    setVendorIds(newVendorIds);
    pagination.resetOffset();
  }

  function handleTaskTypeIdsChange(newValue: string[]) {
    setTaskTypeIds(newValue);
    pagination.resetOffset();
  }

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
    pagination.resetOffset();
  }

  function handleUnassignTask(taskId: number) {
    setTaskIdToUnassign(taskId);
  }

  function handleUnassignCancel() {
    setTaskIdToUnassign(null);
  }

  function onUserSelected(value: number | null) {
    setUserId(value);
    pagination.resetOffset();
  }

  function onRangeStartChange(startsAt: Date) {
    startsAt.setHours(0, 0, 0, 0);
    setStartsAt(startsAt);
  }

  function onRangeEndChange(endsAt: Date) {
    endsAt.setHours(0, 0, 0, 0);
    setEndsAt(endsAt);
  }

  async function handleUnassignConfirm() {
    if (!taskIdToUnassign) {
      return;
    }

    try {
      const response = await updateTaskMutation({
        variables: {
          input: {
            taskId: taskIdToUnassign,
            assignedUserId: null,
          },
        },
      });

      if (!response.data?.updateTask.task.id) {
        throw new Error("Unable to unassign task. Please refresh and try again.");
      }
      queryResult.refetch();
      setTaskIdToUnassign(null);
    } catch (e: any) {
      handleGraphQLResponseError(e, toast);
    }
  }

  function handleChangeTaskVendor(task: AdminTasks_TaskFragment) {
    setTaskForVendorChange(task);
  }

  function handleChangeTaskVendorCancel() {
    setTaskForVendorChange(null);
  }

  async function handleChangeTaskVendorConfirm(vendorId: number) {
    if (!taskForVendorChange) {
      return;
    }

    try {
      const response = await updateTaskMutation({
        variables: {
          input: {
            taskId: taskForVendorChange.id,
            vendorId,
          },
        },
      });

      if (!response.data?.updateTask.task.id) {
        throw new Error("Unable to change vendor for task. Please refresh and try again.");
      }
      queryResult.refetch();
      setTaskForVendorChange(null);
    } catch (e: any) {
      handleGraphQLResponseError(e, toast);
    }
  }

  return {
    loading: queryResult.loading,
    tasks,
    name,
    statusIds: statusIds as string[],
    handleStatusIdsChange,
    onCustomerIdChange,
    customerId,
    filterCount,
    clearFilters,
    pagination,
    totalCount: queryResult.data?.tasks.totalCount ?? 0,
    vendorIds: vendorIds as number[],
    handleVendorIdsChange,
    taskTypeIds: taskTypeIds as string[],
    handleTaskTypeIdsChange,
    handleSearchChange,
    search: search ?? "",
    taskIdToUnassign,
    handleUnassignTask,
    handleUnassignCancel,
    handleUnassignConfirm,
    isUpdatingTask,
    taskForVendorChange,
    handleChangeTaskVendor,
    handleChangeTaskVendorCancel,
    handleChangeTaskVendorConfirm,
    startsAt,
    endsAt,
    onRangeStartChange,
    onRangeEndChange,
    userId,
    onUserSelected,
  };
}

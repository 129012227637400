export const BUSINESS_TYPE_OPTIONS = [
  {
    value: "Local",
    name: "Local (Specific Service Area)",
  },
  {
    value: "National",
    name: "National (U.S. Based)",
  },
  {
    value: "International",
    name: "International",
  },
  {
    value: "Ecommerce Local",
    name: "Ecommerce Local (Specific Service Area)",
  },
  {
    value: "Ecommerce National",
    name: "Ecommerce National (U.S. Based)",
  },
  {
    value: "Ecommerce International",
    name: "Ecommerce International",
  },
];

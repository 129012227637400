import { useState } from "react";
import { InputProps } from "@chakra-ui/react";
import { AutoCompleteInput } from "../../components/auto-complete-input/auto-complete-input";
import { useChecklistDefinitionsForProductQuery } from "../../generated/graphql";
import { PAGE_LIMIT } from "../../constants/constants";

interface Props extends InputProps {
  selectedChecklistDefinitionId?: number | null;
  onChecklistDefinitionSelected: (checklistDefinitionId: number | null) => void;
}

export function ChecklistDefinitionSelector(props: Props) {
  const { selectedChecklistDefinitionId, onChecklistDefinitionSelected, ...rest } = props;
  const [search, setSearch] = useState("");

  const { data } = useChecklistDefinitionsForProductQuery({
    variables: {
      search,
      offset: 0,
      limit: PAGE_LIMIT,
    },
  });

  const options = data?.checklistDefinitions.nodes.map((checklistDefinition) => ({
    value: `${checklistDefinition.id}`,
    label: `${checklistDefinition.name}`,
  }));

  function onSelectedValueChange(value: string | null) {
    if (value) {
      onChecklistDefinitionSelected(parseInt(value, 10));
    } else {
      onChecklistDefinitionSelected(null);
    }
  }

  function onSearchValueChange(value: string) {
    setSearch(value);
  }

  return (
    <AutoCompleteInput
      items={options ?? []}
      onSelectedValueChanged={onSelectedValueChange}
      onInputChanged={onSearchValueChange}
      selectedValue={selectedChecklistDefinitionId ? `${selectedChecklistDefinitionId}` : undefined}
      isClearable
      {...rest}
    />
  );
}

import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import {
  ContentStrategyV2_OrderContentStrategyFragment,
  useResolveOrderContentStrategyReviewMutation,
} from "../../generated/graphql";
import { handleGraphQLResponseError } from "../../util/error-helper";

interface Options {
  orderContentStrategy: ContentStrategyV2_OrderContentStrategyFragment;
}

export function useContentStrategyItem(options: Options) {
  const { orderContentStrategy } = options;
  const [isEditing, setIsEditing] = useState(false);
  const [resolveOrderContentStrategyReviewMutation] = useResolveOrderContentStrategyReviewMutation();
  const toast = useToast();

  const reviews = orderContentStrategy.orderContentStrategyReviews;
  const unresolvedRevisionRequests = reviews.filter((x) => !x.resolvedAt);
  const isComplete = orderContentStrategy.isComplete;

  function onEdit() {
    setIsEditing(true);
  }

  function onStopEdit() {
    setIsEditing(false);
  }

  async function resolveReview(orderContentStrategyReviewId: number) {
    try {
      const response = await resolveOrderContentStrategyReviewMutation({
        variables: {
          input: {
            orderContentStrategyReviewId,
          },
        },
      });
      if (!response.data?.resolveOrderContentStrategyReview.ok) {
        throw new Error(
          response.data?.resolveOrderContentStrategyReview.error?.message ??
            "Unable to resolve client revision request. Please try again."
        );
      }
    } catch (e: any) {
      handleGraphQLResponseError(e, toast);
    }
  }

  return {
    isEditing,
    reviews,
    unresolvedRevisionRequests,
    isComplete,
    onEdit,
    onStopEdit,
    resolveReview,
  };
}

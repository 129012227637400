import { useLocation, useNavigate } from "react-router-dom";
import queryString from "qs";
import { getQueryParams, setParams } from "../../util/query-param-helper";
import { usePartnersQuery } from "../../generated/graphql";
import { PAGE_LIMIT } from "../../constants/constants";
import { useState } from "react";

export function usePartners() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = getQueryParams(location.search, queryString) || {};
  const searchValue = queryParams.search ?? "";
  const pageOffset = queryParams?.offset ? parseInt(queryParams.offset, 10) : 0;
  const sort = queryParams?.sort ?? "CREATED_AT_DESC";
  const partnerType = queryParams?.partnerType ?? undefined;
  const partnersQueryResult = usePartnersQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: PAGE_LIMIT,
      offset: pageOffset,
      search: searchValue,
      sort,
      portal: partnerType,
    },
  });
  const [showAddPartnerDrawer, setShowAddPartnerDrawer] = useState(false);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { search, ...remainingQueryParams } = queryParams;
    let searchParams;
    const value = event.target.value;
    if (value) {
      searchParams = setParams(remainingQueryParams, "search", `${value ? value : ""}`);
    } else {
      searchParams = setParams(remainingQueryParams);
    }
    searchParams = setParams(searchParams, "offset", "0");
    navigate({ search: `?${searchParams}` });
  }

  function onNextPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = parseInt(offset ?? "0", 10) + PAGE_LIMIT;
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onPreviousPage() {
    const { offset, ...remainingQueryParams } = queryParams;
    const newOffset = Math.max(parseInt(offset ?? "0", 10) - PAGE_LIMIT, 0);
    const params = setParams(remainingQueryParams, "offset", newOffset.toString());
    navigate({ search: `?${params}` });
  }

  function onSortChange(newSort: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sort, ...remainingQueryParams } = queryParams;
    let sortParams;
    if (newSort) {
      sortParams = setParams(remainingQueryParams, "sort", newSort);
    }
    sortParams = setParams(sortParams, "offset", "0");
    navigate({ search: `?${sortParams}` });
  }

  function onPartnerTypeChange(value: string | null) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { partnerType, ...remainingQueryParams } = queryParams;
    let partnerTypeParams;
    if (value) {
      partnerTypeParams = setParams(remainingQueryParams, "partnerType", value.toString());
    } else {
      partnerTypeParams = setParams(remainingQueryParams);
    }
    partnerTypeParams = setParams(partnerTypeParams, "offset", "0");
    navigate({ search: `?${partnerTypeParams}` });
  }

  function onAddPartner() {
    setShowAddPartnerDrawer(true);
  }

  function onAddPartnerCancel() {
    setShowAddPartnerDrawer(false);
  }

  function onPartnerAdded() {
    setShowAddPartnerDrawer(false);
    partnersQueryResult.refetch();
  }

  return {
    loading: partnersQueryResult.loading,
    partners: partnersQueryResult.data?.partners.nodes ?? [],
    partnersTableLimit: partnersQueryResult.variables?.limit ?? PAGE_LIMIT,
    partnersTableOffset: partnersQueryResult.variables?.offset ?? 0,
    totalCount: partnersQueryResult.data?.partners.totalCount ?? 0,
    onChange,
    onSortChange,
    onNextPage,
    onPreviousPage,
    onAddPartner,
    showAddPartnerDrawer,
    onAddPartnerCancel,
    onPartnerAdded,
    sort,
    partnerType,
    onPartnerTypeChange,
  };
}

import { MainNavFull } from "./main-nav-full";
import { MainNavSmall } from "./main-nav-small";
import { useMediaQuery } from "../../util/use-media-query";

interface Props {
  hasSecondaryNav?: boolean;
}

export function MainNav(props: Props) {
  const shouldUseFullNav = useMediaQuery("(min-width: 1565px)");
  if (shouldUseFullNav) {
    return <MainNavFull {...props} />;
  }
  return <MainNavSmall {...props} />;
}

import { useRef, useState } from "react";
import {
  Switch,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
} from "@chakra-ui/react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
  TablePagination,
  TableFoot,
} from "../../components/table";
import { useUpdateVendorUserMutation, VendorUsersQueryResult } from "../../generated/graphql";
import { PAGE_LIMIT } from "../../constants/constants";
import { CancelButton, PrimaryButton, PrimaryLinkButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";

interface Props {
  vendorUsersQueryResult: VendorUsersQueryResult;
  onManageTaskPermissions: (userId: number) => void;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

interface ToggleActive {
  id: number;
  isActive: boolean;
  userName: string;
}

export function UsersTable(props: Props) {
  const { vendorUsersQueryResult, onNextPage, onPreviousPage, onManageTaskPermissions } = props;
  const totalCount = vendorUsersQueryResult.data?.vendor?.vendorUsers.totalCount ?? 0;
  const vendorsUsers = vendorUsersQueryResult.data?.vendor?.vendorUsers.nodes ?? [];
  const [updateVendorUser] = useUpdateVendorUserMutation();
  const [toggleActive, setToggleActive] = useState<ToggleActive | null>(null);
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(false);
    setToggleActive(null);
  };
  const cancelRef = useRef<HTMLButtonElement>(null);

  async function onSwitchChange(vendorUser: any) {
    setToggleActive({
      id: vendorUser.id,
      isActive: vendorUser.isActive,
      userName: `${vendorUser.user.firstName} ${vendorUser.user.lastName}`,
    });
    setIsOpen(true);
  }

  async function updateToggleActive() {
    if (!toggleActive) {
      return;
    }
    try {
      const response = await updateVendorUser({
        variables: {
          input: {
            vendorUserId: toggleActive.id,
            isActive: !toggleActive.isActive,
          },
        },
      });

      if (response.data?.updateVendorUser.vendorUser.id) {
        toast({
          title: "Success",
          description: `"${toggleActive.userName}" has been ${
            toggleActive.isActive ? "deactivated" : "activated"
          } successfully.`,
          status: "success",
        });
        vendorUsersQueryResult.refetch();
      }
    } catch (error: any) {
      toast({ title: "Success", description: getDisplayMessageForError(error), status: "error" });
    }
    setToggleActive(null);
    setIsOpen(false);
  }

  return (
    <>
      <Table isEmpty={vendorsUsers.length === 0} isLoading={vendorUsersQueryResult.loading}>
        <TableHead>
          <TableRow key="head">
            <TableHeaderCell>Id</TableHeaderCell>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Email</TableHeaderCell>
            <TableHeaderCell>Task Permissions</TableHeaderCell>
            <TableHeaderCell>Active</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vendorsUsers.map((vendorUser) => (
            <TableRow key={vendorUser.id}>
              <TableCell>{vendorUser.user.id}</TableCell>
              <TableCell>{`${vendorUser.user.firstName} ${vendorUser.user.lastName}`}</TableCell>
              <TableCell>{vendorUser.user.email}</TableCell>
              <TableCell>
                <PrimaryLinkButton
                  onClick={() => {
                    onManageTaskPermissions(vendorUser.userId);
                  }}
                >
                  {vendorUser.userTaskPermissions.length} permission
                  {vendorUser.userTaskPermissions.length !== 1 ? "s" : ""}
                </PrimaryLinkButton>
              </TableCell>
              <TableCell>
                <Switch onChange={onSwitchChange.bind(null, vendorUser)} isChecked={vendorUser.isActive} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFoot>
          <TablePagination
            totalCount={totalCount}
            offset={vendorUsersQueryResult.variables?.offset ?? 0}
            limit={vendorUsersQueryResult.variables?.limit ?? PAGE_LIMIT}
            onPreviousPage={onPreviousPage}
            onNextPage={onNextPage}
          />
        </TableFoot>
      </Table>
      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef as any}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{toggleActive?.isActive ? "Deactivate" : "Activate"} User</AlertDialogHeader>
          <AlertDialogBody>
            {toggleActive &&
              `Are you sure you want to ${toggleActive?.isActive ? "deactivate" : "activate"} "${
                toggleActive?.userName
              }"?`}
          </AlertDialogBody>
          <AlertDialogFooter>
            <CancelButton onClick={onClose} ref={cancelRef}>
              Cancel
            </CancelButton>
            <PrimaryButton ml={3} onClick={updateToggleActive}>
              {toggleActive?.isActive ? "Deactivate" : "Activate"}
            </PrimaryButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

import * as yup from "yup";
import { Box, Flex, useToast, Image, FormControl, FormLabel, Input, FormErrorMessage, Text } from "@chakra-ui/react";
import { useFormik, FormikHelpers } from "formik";
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";

import Logo from "../../assets/red-canyon-media-logo-horizontal-gray@2x.png";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useResetPasswordMutation } from "../../generated/graphql";
import { AppColors } from "../../core/custom-theme";
import { PrimaryButton } from "../../components/buttons";

const resetPasswordSchema = yup.object().shape({
  password: yup.string().label("Password").required(),
  confirmPassword: yup
    .string()
    .label("Confirm Password")
    .required()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

interface FormValues {
  password: string;
  confirmPassword: string;
}

export function ResetPassword() {
  const [resetPassword] = useResetPasswordMutation();
  const toast = useToast();
  const navigate = useNavigate();
  const { id } = useParams<"id">();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleFormSubmit(values: FormValues, _formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await resetPassword({
        variables: {
          input: {
            id: id ?? "",
            password: values.password,
          },
        },
      });
      if (response?.data?.resetPassword?.wasReset) {
        navigate("/login");
      } else {
        throw new Error("There was a problem resetting password. Please try again.");
      }
    } catch (e: any) {
      toast({ title: "Reset Password", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validateOnBlur: true,
    validationSchema: resetPasswordSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <>
      <Helmet title="Request Password Reset" />
      <Box
        bg={AppColors.body}
        display="flex"
        justifyContent="center"
        alignItems={{ xs: "flex-start", lg: "center" }}
        height="100vh"
      >
        <Box p={4} mt={1} width={{ xs: 350, lg: 414 }} borderRadius={4}>
          <Flex justifyContent="center" marginBottom={4}>
            <Image
              justifyContent="center"
              src={Logo}
              alt="Creatives Scale"
              width="250px"
              height="100px"
              objectFit="contain"
            />
          </Flex>
          <Box
            padding={8}
            marginBottom={2}
            textAlign="left"
            bg={AppColors.white}
            borderRadius={4}
            border={`1px solid ${AppColors.whiteSmoke}`}
          >
            <Text fontWeight={600} color={AppColors.textNormal} fontSize={24} mb={8}>
              Password Reset
            </Text>
            <form onSubmit={formik.handleSubmit} noValidate={true}>
              <FormControl isInvalid={!!formik.errors.password && formik.touched.password} mb={2}>
                <FormLabel fontSize={14} fontWeight="normal">
                  New Password
                </FormLabel>
                <Input
                  type="password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  autoFocus
                />
                {formik.touched.password && formik.errors.password && (
                  <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!formik.errors.confirmPassword && formik.touched.confirmPassword}>
                <FormLabel fontSize={14} fontWeight="normal">
                  Confirm New Password
                </FormLabel>
                <Input
                  type="password"
                  id="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
                )}
              </FormControl>
              <Box mt={8}>
                <PrimaryButton
                  width="100%"
                  bg={AppColors.textNormal}
                  _hover={{ bg: AppColors.textNormal }}
                  _active={{ bg: AppColors.textNormal }}
                  type="submit"
                  isDisabled={formik.isSubmitting}
                  isLoading={formik.isSubmitting}
                  loadingText="Resetting Password..."
                >
                  Set Password
                </PrimaryButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
}

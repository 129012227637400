import { PageHeading } from "../../components/page-heading";
import { usePartnerPackages } from "./use-partner-packages";
import { Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell } from "../../components/table";
import { PartnerPackageStatusIndicator } from "../../common/partner-package-status-indicator";
import { currencyFormatter } from "../../util/formatter";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { Flex } from "@chakra-ui/react";
import { PrimaryButton } from "../../components/buttons";
import AppLink from "../../components/app-link";
import { AddPartnerPackageDrawer } from "../../common/add-partner-package-drawer";
import { LayoutBase, MainNav } from "../../components/layout";
import { ConfigurationSecondaryNav } from "../../common/configuration-secondary-nav";
import { Container } from "../../components/layout/container";

export function ConfigurationPartnerPackages() {
  const { loading, packages, partnerId, partner, isAdding, onAddPackage, onAddPackageCancel, onAdded } =
    usePartnerPackages();

  const prefixPartnerName = partner ? `${partner.name} ` : "";
  return (
    <LayoutBase pageTitle={`${prefixPartnerName}Packages`}>
      <MainNav hasSecondaryNav />
      <ConfigurationSecondaryNav partnerId={partnerId.toString()} activeSection="packages" />
      <Container centerHorizontally>
        <AddPartnerPackageDrawer
          isOpen={isAdding}
          onAdded={onAdded}
          onClose={onAddPackageCancel}
          partnerId={partnerId}
        />
        <PageHeading>{prefixPartnerName}Packages</PageHeading>
        <PanelGroup>
          <PanelTop>
            <Flex justifyContent="flex-end">
              <PrimaryButton onClick={onAddPackage}>Add Package</PrimaryButton>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <Table isLoading={loading} isEmpty={packages.length === 0}>
              <TableHead>
                <TableRow key="head">
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell>Monthly Spend</TableHeaderCell>
                  <TableHeaderCell>Months</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {packages.map((partnerPackage) => (
                  <TableRow key={partnerPackage.id}>
                    <TableCell>
                      <AppLink to={`/configuration/${partnerId}/packages/${partnerPackage.id}`}>
                        {partnerPackage.name}
                      </AppLink>
                    </TableCell>
                    <TableCell>
                      <PartnerPackageStatusIndicator isActive={partnerPackage.isActive} />
                    </TableCell>
                    <TableCell>{currencyFormatter.format(partnerPackage.monthlySpend)}</TableCell>
                    <TableCell>{partnerPackage.months}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}

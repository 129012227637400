import { SelectProps, Select } from "@chakra-ui/react";

interface Props extends SelectProps {
  rangeStart: number;
  rangeEnd: number;
}

export function NumberSelect(props: Props) {
  const { rangeEnd, rangeStart, ...rest } = props;
  return (
    <Select {...rest}>
      {[...Array(rangeEnd - rangeStart + 1).keys()].map((item) => (
        <option key={item} value={item + rangeStart}>
          {item + rangeStart}
        </option>
      ))}
    </Select>
  );
}

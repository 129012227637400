export const VOICE_OPTIONS = [
  {
    value: "Casual Professional",
    name: "Casual Professional",
  },
  {
    value: "Business Professional",
    name: "Business Professional",
  },
  {
    value: "Always write as if I wrote the content myself",
    name: "Always write as if I wrote the content myself",
  },
  {
    value: "You choose what is best for the content",
    name: "You choose what is best for the content",
  },
];

import { BoxProps, Box } from "@chakra-ui/react";

interface Props extends BoxProps {
  containsTable?: boolean;
}

export function PanelBox(props: Props) {
  const { containsTable, ...rest } = props;
  const borderStyle = "1px solid #E3E8EF";

  return (
    <Box
      backgroundColor="white"
      border={borderStyle}
      borderRadius="4px"
      padding={containsTable ? undefined : 4}
      {...rest}
    />
  );
}

import { DateTime } from "luxon";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
  TablePagination,
  TableFoot,
} from "../../components/table";
import { SitePostsQueryResult } from "../../generated/graphql";
import { PAGE_LIMIT, DATE_MONTH_FORMAT } from "../../constants/constants";
import AppLink from "../../components/app-link";

interface Props {
  sitePostsQueryResult: SitePostsQueryResult;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

export function AdminSitePostsTable(props: Props) {
  const { sitePostsQueryResult, onNextPage, onPreviousPage } = props;
  const totalCount = sitePostsQueryResult.data?.sitePosts.totalCount ?? 0;
  const sitePosts = sitePostsQueryResult.data?.sitePosts.nodes ?? [];

  return (
    <Table isEmpty={sitePosts.length === 0} isLoading={sitePostsQueryResult.loading}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>ID</TableHeaderCell>
          <TableHeaderCell>Site</TableHeaderCell>
          <TableHeaderCell>Title</TableHeaderCell>
          <TableHeaderCell>Create Date</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Task ID</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sitePosts.map((sitePost) => (
          <TableRow key={sitePost.id}>
            <TableCell>
              <AppLink to={`/admin/site-posts/${sitePost.id}`}>{sitePost.id}</AppLink>
            </TableCell>
            <TableCell>
              {sitePost.siteId ? <AppLink to={`/admin/sites/${sitePost.siteId}`}>{sitePost.siteId}</AppLink> : "-"}
            </TableCell>
            <TableCell>{sitePost.title}</TableCell>
            <TableCell>
              {sitePost.createdAt ? DateTime.fromJSDate(sitePost.createdAt).toFormat(DATE_MONTH_FORMAT) : "-"}
            </TableCell>
            <TableCell>{sitePost.status.name}</TableCell>
            <TableCell>{sitePost.taskId ?? "-"}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFoot>
        <TablePagination
          totalCount={totalCount}
          offset={sitePostsQueryResult.variables?.offset ?? 0}
          limit={sitePostsQueryResult.variables?.limit ?? PAGE_LIMIT}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
      </TableFoot>
    </Table>
  );
}

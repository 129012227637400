export const PUBLISHER_PAYMENT_STATUS = {
  PENDING: {
    id: "pending",
    name: "Pending",
  },
  SUBMITTED: {
    id: "submitted",
    name: "Submitted",
  },
  COMPLETED: {
    id: "completed",
    name: "Completed",
  },
};

import { useState } from "react";
import { useLinkLocationsCustomer } from "./use-link-locations-customer";
import { useLinkLocations } from "./use-link-locations";
import { LinkLocations } from "./link-locations";
import { noop } from "../../util/noop";

interface Props {
  customerId: number;
  refetch?: boolean;
  onRefetchDone?: () => void;
  showSelect?: boolean;
  onSelected?: (domainName: string, linkLocationId: number, publisher: string) => void;
  isDisabled?: boolean;
  disableAvailableStatus?: boolean;
  showExtraInformation?: boolean;
  minDA?: number;
  minSV?: number;
}

export function LinkLocation(props: Props) {
  const {
    customerId,
    refetch,
    onRefetchDone,
    showSelect,
    onSelected,
    isDisabled,
    disableAvailableStatus,
    showExtraInformation,
    minDA,
    minSV,
  } = props;
  const [selectedDomain, setSelectedDomain] = useState<string>("");

  function onDominSelected(domain: string, linkLocationId: number, publisher: string) {
    setSelectedDomain(domain);
    onSelected?.(domain, linkLocationId, publisher);
  }

  const {
    search: linkLocationUsedSearch,
    onSearchChange: linkLocationUsedOnSearchChange,
    statusIds: linkLocationUsedStatusIds,
    onStatusSelected: linkLocationUsedOnStatusSelected,
    typeIds: linkLocationUsedTypeIds,
    onTypesSelected: linkLocationUsedOnTypesSelected,
    clearFilters: linkLocationUsedClearFilters,
    offset: linkLocationUsedOffset,
    onNextPage: linkLocationUsedOnNextPage,
    onPreviousPage: linkLocationUsedOnPreviousPage,
    loading: linkLocationUsedLoading,
    linkLocationConnection: linkLocationUsedLinkLocationConnection,
    sort: linkLocationsUsedSort,
    onSortChange: linkLocationsUsedOnSortChange,
    pendingSearch,
    onPendingSearchChange,
    pendingTypeIds,
    onPendingTypesSelected,
    pendingSort,
    onPendingSortChange,
    pendingOffset,
    onPendingNextPage,
    onPendingPreviousPage,
    pendingLinkLocationConnection,
    pendingClearFilters,
    rejectedSearch,
    onRejectedSearchChange,
    rejectedTypeIds,
    onRejectedTypesSelected,
    rejectedSort,
    onRejectedSortChange,
    rejectedOffset,
    onRejectedPreviousPage,
    onRejectedNextPage,
    rejectedLinkLocationConnection,
    rejectedClearFilters,
  } = useLinkLocationsCustomer({ customerId, search: selectedDomain });

  const {
    search: linkLocationsSearch,
    onSearchChange: linkLocationsOnSearchChange,
    statusIds: linkLocationsStatusIds,
    onStatusSelected: linkLocationsOnStatusSelected,
    typeIds: linkLocationsTypeIds,
    onTypesSelected: linkLocationsOnTypesSelected,
    clearFilters: linkLocationsClearFilters,
    offset: linkLocationsOffset,
    onNextPage: linkLocationsOnNextPage,
    onPreviousPage: linkLocationsOnPreviousPage,
    loading: linkLocationsLoading,
    linkLocationConnection: linkLocationsLinkLocationConnection,
    minDa: linkLocationsMinDa,
    onMinDaChange: linkLocationsOnMinDaChange,
    minTraffic: linkLocationsMinTraffic,
    onMinTrafficChange: linkLocationsOnMinTrafficChange,
    categoryId: linkLocationsCategoryId,
    onCategoryChange: linkLocationsOnCategoryChange,
    sort: linkLocationsSort,
    onSortChange: linkLocationsOnSortChange,
    linkLocationsOnRefreshMetrics: onRefreshMetrics,
    publisherId,
    onPublisherChange,
  } = useLinkLocations({ refetch, onRefetchDone, customerId, minDA, minSV });

  return (
    <>
      <LinkLocations
        heading="Link Locations Pending"
        search={pendingSearch}
        onSearchChange={onPendingSearchChange}
        statusIds={[]}
        onStatusSelected={noop}
        typeIds={pendingTypeIds}
        onTypesSelected={onPendingTypesSelected}
        clearFilters={pendingClearFilters}
        offset={pendingOffset}
        onNextPage={onPendingNextPage}
        onPreviousPage={onPendingPreviousPage}
        loading={linkLocationUsedLoading}
        linkLocationConnection={pendingLinkLocationConnection}
        sort={pendingSort}
        onSortChange={onPendingSortChange}
        key="linklocation-pending"
      />
      <LinkLocations
        heading="Link Locations Used By Client"
        search={linkLocationUsedSearch}
        onSearchChange={linkLocationUsedOnSearchChange}
        statusIds={linkLocationUsedStatusIds}
        onStatusSelected={linkLocationUsedOnStatusSelected}
        typeIds={linkLocationUsedTypeIds}
        onTypesSelected={linkLocationUsedOnTypesSelected}
        clearFilters={linkLocationUsedClearFilters}
        offset={linkLocationUsedOffset}
        onNextPage={linkLocationUsedOnNextPage}
        onPreviousPage={linkLocationUsedOnPreviousPage}
        loading={linkLocationUsedLoading}
        linkLocationConnection={linkLocationUsedLinkLocationConnection}
        sort={linkLocationsUsedSort}
        onSortChange={linkLocationsUsedOnSortChange}
        key="linklocation-used"
      />
      <LinkLocations
        heading="Link Locations Rejected"
        search={rejectedSearch}
        onSearchChange={onRejectedSearchChange}
        statusIds={[]}
        onStatusSelected={noop}
        typeIds={rejectedTypeIds}
        onTypesSelected={onRejectedTypesSelected}
        clearFilters={rejectedClearFilters}
        offset={rejectedOffset}
        onNextPage={onRejectedNextPage}
        onPreviousPage={onRejectedPreviousPage}
        loading={linkLocationUsedLoading}
        linkLocationConnection={rejectedLinkLocationConnection}
        sort={rejectedSort}
        onSortChange={onRejectedSortChange}
        key="linklocation-rejected"
      />
      <LinkLocations
        heading="Available Link Locations"
        search={linkLocationsSearch}
        onSearchChange={linkLocationsOnSearchChange}
        statusIds={linkLocationsStatusIds}
        onStatusSelected={linkLocationsOnStatusSelected}
        typeIds={linkLocationsTypeIds}
        onTypesSelected={linkLocationsOnTypesSelected}
        clearFilters={linkLocationsClearFilters}
        offset={linkLocationsOffset}
        onNextPage={linkLocationsOnNextPage}
        onPreviousPage={linkLocationsOnPreviousPage}
        loading={linkLocationsLoading}
        linkLocationConnection={linkLocationsLinkLocationConnection}
        showSelect={showSelect}
        onSelected={onDominSelected}
        isDisabled={isDisabled}
        disableAvailableStatus={disableAvailableStatus}
        showExtraInformation={showExtraInformation}
        minDa={linkLocationsMinDa}
        onMinDaChange={linkLocationsOnMinDaChange}
        minTraffic={linkLocationsMinTraffic}
        onMinTrafficChange={linkLocationsOnMinTrafficChange}
        showFilter={true}
        sort={linkLocationsSort}
        onSortChange={linkLocationsOnSortChange}
        categoryId={linkLocationsCategoryId}
        onCategorySelected={linkLocationsOnCategoryChange}
        onRefreshMetrics={onRefreshMetrics}
        publisherId={publisherId}
        onPublisherChange={onPublisherChange}
        hideTrafficSort={true}
        key="linklocation-available"
      />
    </>
  );
}

import { forwardRef } from "react";
import { ButtonProps, Button } from "@chakra-ui/react";
import Color from "color";
import { AppColors } from "../../core/custom-theme";

export const SecondaryButton = forwardRef<HTMLButtonElement, ButtonProps>(function SecondaryButton(props, ref) {
  return (
    <Button
      variant="outline"
      borderColor={AppColors.blue}
      color={AppColors.blue}
      _hover={{
        bg: Color(AppColors.blue).lighten(0.6).hex(),
      }}
      _active={{
        bg: Color(AppColors.blue).lighten(0.4).hex(),
      }}
      fontSize="14px"
      paddingX="30px"
      paddingY="10px"
      ref={ref}
      {...props}
    />
  );
});

import { Navigate } from "react-router-dom";
import AppLink from "../../components/app-link";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { SearchInput } from "../../components/search-input/search-input";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { useConfiguration } from "./configuration-hook";

export function ConfigurationRoute() {
  const { loading, partners, partnerId, search, onSearchChange } = useConfiguration();

  if (!loading && partners.length === 1 && !search) {
    return <Navigate to={`/configuration/${partners[0].id}/product-types`} />;
  }

  if (partnerId) {
    return <Navigate to={`/configuration/${partnerId}/product-types`} />;
  }

  return (
    <LayoutBase pageTitle="Partner Configuration">
      <MainNav />
      <Container centerHorizontally>
        <PageHeading>Partner Configuration</PageHeading>
        <PanelGroup>
          <PanelTop>
            <SearchInput autoFocus onChange={onSearchChange} maxW={300} />
          </PanelTop>
          <PanelBottom containsTable>
            <Table isEmpty={partners.length === 0} isLoading={loading}>
              <TableHead>
                <TableRow key="head">
                  <TableHeaderCell>Partner</TableHeaderCell>
                  <TableHeaderCell>Product Types</TableHeaderCell>
                  <TableHeaderCell>Users</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partners.map((partner) => (
                  <TableRow key={partner.id}>
                    <TableCell>
                      <AppLink to={`/configuration/${partner.id}`}>{partner.name}</AppLink>
                    </TableCell>
                    <TableCell>
                      <AppLink to={`/configuration/${partner.id}/product-types`}>
                        {partner.partnerProducts.totalCount}
                      </AppLink>
                    </TableCell>
                    <TableCell>
                      <AppLink to={`/configuration/${partner.id}/users`}>{partner.partnerUsers.totalCount}</AppLink>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}

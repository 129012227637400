import React from "react";
import * as Sentry from "@sentry/react";

import { AuthManager_ViewerFragment, useLogoutUserMutation, useAuthManagerQuery } from "../../generated/graphql";
import { ConnectivityContext } from "../connectivity-monitor";

export interface AuthContextValue {
  isLoading: boolean;
  isLoggedIn: boolean;
  refresh: () => Promise<any>;
  logout: () => Promise<any>;
  viewer: AuthManager_ViewerFragment | null;
  isAdmin: () => boolean;
  isSuperAdmin: () => boolean;
  hasPartnerUser: (partnerId?: number) => boolean;
  hasPartnerUserAdmin: (partnerId?: number) => boolean;
  hasVendorUser: () => boolean;
  hasSLPCustomer: () => boolean;
}

interface Props {
  children: React.ReactNode;
}

export const AuthContext = React.createContext<AuthContextValue>({
  isLoading: true,
  isLoggedIn: false,
  refresh: async () => {
    // Do nothing
  },
  logout: async () => {
    // Do nothing
  },
  viewer: null,
  hasPartnerUser: () => false,
  hasPartnerUserAdmin: () => false,
  hasVendorUser: () => false,
  isAdmin: () => false,
  isSuperAdmin: () => false,
  hasSLPCustomer: () => false,
});

export function AuthManager(props: Props) {
  const [logoutUserMutation] = useLogoutUserMutation();
  const queryResult = useAuthManagerQuery({ fetchPolicy: "network-only" });
  const contextValue: AuthContextValue = {
    isLoading: queryResult.loading,
    isLoggedIn: false,
    refresh: queryResult.refetch,
    logout: async () => {
      await logoutUserMutation();
      await queryResult.refetch();
      Sentry.setUser(null);
    },
    viewer: null,
    hasPartnerUser: (partnerId?: number) => {
      if (partnerId) {
        return !!queryResult.data?.viewer.user?.partnerUsers.find((x) => x.partnerId === partnerId);
      } else {
        return (queryResult.data?.viewer.user?.partnerUsers ?? []).length > 0;
      }
    },
    hasPartnerUserAdmin: (partnerId?: number) => {
      if (partnerId) {
        return !!queryResult.data?.viewer.user?.partnerUsers.find((x) => x.partnerId === partnerId && x.isAdmin);
      } else {
        return !!queryResult.data?.viewer.user?.partnerUsers.find((x) => x.isAdmin);
      }
    },
    hasVendorUser: () => {
      return (queryResult.data?.viewer.user?.vendorUsers ?? []).length > 0;
    },
    isAdmin: () => {
      return (
        (queryResult.data?.viewer.user?.isAdmin ?? false) || (queryResult.data?.viewer.user?.isSuperAdmin ?? false)
      );
    },
    isSuperAdmin: () => {
      return queryResult.data?.viewer.user?.isSuperAdmin ?? false;
    },
    hasSLPCustomer: () => {
      return queryResult.data?.viewer.hasSLPCustomer ?? false;
    },
  };

  if (!queryResult.loading) {
    const { data, error } = queryResult;
    if (data) {
      contextValue.isLoggedIn = !error && !!data.viewer.user;
      contextValue.viewer = data.viewer;
      if (data.viewer.user) {
        Sentry.setUser({
          email: data.viewer.user.email,
          id: data.viewer.user.id.toString(),
          username: data.viewer.user.fullName,
        });
      }
    }
  }

  function renderContent(contextValue: AuthContextValue, isConnected: boolean) {
    const { children } = props;
    if (!contextValue) {
      return null;
    }
    if (!isConnected) {
      // If API connectivity goes down, prevent redirects for an improved user experience.
      return null;
    }
    return children;
  }

  return (
    <AuthContext.Provider value={contextValue}>
      <ConnectivityContext.Consumer>
        {(connectivityContext) => renderContent(contextValue, connectivityContext.isConnected)}
      </ConnectivityContext.Consumer>
    </AuthContext.Provider>
  );
}

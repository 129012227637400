import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { useConfiguration_PartnerProductsQuery, useDeletePartnerProductMutation } from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

export function useConfigurationProductTypes() {
  const { partnerId } = useParams<"partnerId">();
  const parsedPartnerId = parseInt(partnerId ?? "");
  const [searchValue, setSearchValue] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [idBeingEdited, setIdBeingEdited] = useState<number | null>(null);
  const [managePriceId, setManagePriceId] = useState<number | null>(null);

  const partnerProductsQueryResult = useConfiguration_PartnerProductsQuery({
    fetchPolicy: "network-only",
    variables: {
      search: searchValue,
      partnerId: parsedPartnerId,
    },
  });
  const [deletePartnerProductMutation] = useDeletePartnerProductMutation();
  const toast = useToast();

  const partner = partnerProductsQueryResult.data?.partner ?? null;
  const partnerProducts = partnerProductsQueryResult.data?.partnerProducts.nodes ?? [];

  function onAdd() {
    setIsAdding(true);
  }

  function onAddCancel() {
    setIsAdding(false);
  }

  function onAdded() {
    partnerProductsQueryResult.refetch();
  }

  function onEdit(partnerProductId: number) {
    setIdBeingEdited(partnerProductId);
  }

  function onEditCancel() {
    setIdBeingEdited(null);
  }

  function onEdited() {
    setIdBeingEdited(null);
    setManagePriceId(null);
    partnerProductsQueryResult.refetch();
  }

  function onManagePrice(partnerProductId: number) {
    setManagePriceId(partnerProductId);
  }

  function onManagePriceCancel() {
    setManagePriceId(null);
  }

  async function onDelete(partnerProductId: number) {
    try {
      const response = await deletePartnerProductMutation({
        variables: {
          input: {
            partnerProductId,
          },
        },
      });
      if (response.data?.deletePartnerProduct.error?.message) {
        throw new Error(response.data.deletePartnerProduct.error.message);
      }

      toast({
        title: "Product Type was removed.",
        status: "success",
      });

      partnerProductsQueryResult.refetch();
    } catch (e: any) {
      toast({
        title: "Unable to Delete",
        description: getDisplayMessageForError(e),
        status: "error",
      });
    }
  }

  return {
    loading: partnerProductsQueryResult.loading,
    partnerId: parsedPartnerId,
    partner,
    partnerProducts,
    setSearchValue,
    isAdding,
    onAdd,
    onAddCancel,
    onAdded,
    idBeingEdited,
    onEdit,
    onEditCancel,
    onEdited,
    managePriceId,
    onManagePrice,
    onManagePriceCancel,
    onDelete,
  };
}

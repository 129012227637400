import { Text } from "@chakra-ui/react";
import { ApolloError } from "@apollo/client";

interface Props {
  error?: ApolloError | Error;
}

const ErrorCard = (props: Props) => {
  if (props.error && props.error.message) {
    return <Text textAlign="center">{props.error.message}</Text>;
  } else {
    return <Text textAlign="center">An unexpected error occurred. Please try again.</Text>;
  }
};

export default ErrorCard;

import { Box, ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
// React date picker styles
import "./components/react-datepicker/react-datepicker.css";
import { CustomApolloProvider } from "./core/apollo-provider";
import { AuthManager } from "./core/auth-manager";
import { ConnectivityMonitor } from "./core/connectivity-monitor";
import customTheme from "./core/custom-theme";
import { StripeProvider } from "./core/stripe-provider";
import { Routes } from "./routes";

Sentry.init({
  dsn: "https://d501a17b65fe4faa87476c4b64a706d3@o949542.ingest.sentry.io/5898685",
  integrations: [new Integrations.BrowserTracing()],

  release: `rcm-ui-partner-v2@${process.env.REACT_APP_VERSION}`,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  enabled: process.env.REACT_APP_ENVIRONMENT_PREFIX !== "local",
  environment: process.env.REACT_APP_ENVIRONMENT_PREFIX,
});

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<Box>An error has occurred</Box>}>
      <ChakraProvider theme={customTheme}>
        <ConnectivityMonitor>
          <CustomApolloProvider>
            <StripeProvider>
              <AuthManager>
                <Router>
                  <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <Routes />
                  </QueryParamProvider>
                </Router>
              </AuthManager>
            </StripeProvider>
          </CustomApolloProvider>
        </ConnectivityMonitor>
      </ChakraProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;

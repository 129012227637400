import { DateTime } from "luxon";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Heading,
  Box,
  Flex,
  Text,
  FormHelperText,
} from "@chakra-ui/react";

import { PanelBottom, PanelGroup, PanelMiddle, PanelTop } from "../../../components/panels";
import { useWebsiteSelection } from "./use-website-selection";
import { DangerButton, PrimaryButton, PrimaryLinkButton } from "../../../components/buttons";
import { LinkLocation } from "../../link-locations";
import { HelpText } from "../../../components/help-text";
import { AddLinkLocationModal } from "../../add-link-location-modal";
import { DATE_MONTH_FORMAT } from "../../../constants/constants";

interface Props {
  customerId: number;
  taskId: number;
  onComplete: (isApproved?: boolean, reviewComment?: string) => Promise<void>;
  onAbandon: () => void;
  completedAt?: Date;
  isDisabled?: boolean;
}

export function WebsiteSelection(props: Props) {
  const { customerId, taskId, onComplete, onAbandon, isDisabled, completedAt } = props;

  const {
    formik,
    showAddLinkLocation,
    onClickAddLinkLocation,
    onCloseAddLinkLocation,
    onLinkLocationAdded,
    onRefetchDone,
    refetch,
    onLinkLocationSelected,
    product,
  } = useWebsiteSelection({
    taskId,
    onComplete,
  });

  return (
    <PanelGroup isCollapsible isCollapsedByDefault={isDisabled}>
      <PanelTop>
        <Flex justifyContent="space-between">
          <Heading as="h4" size="md">
            Website Selection
          </Heading>
          {!!completedAt && <Text>Completed At {DateTime.fromJSDate(completedAt).toFormat(DATE_MONTH_FORMAT)}</Text>}
        </Flex>
      </PanelTop>
      <PanelMiddle>
        <Stack spacing={4}>
          <FormControl
            isInvalid={!!formik.touched.targetResultUrl && !!formik.errors.targetResultUrl}
            isDisabled={isDisabled}
          >
            <FormLabel>Target Result URL</FormLabel>
            <Input
              type="text"
              variant="outline"
              id="targetResultUrl"
              value={formik.values.targetResultUrl}
              isReadOnly
            />
            <FormErrorMessage>{formik.errors.targetResultUrl}</FormErrorMessage>
            <FormHelperText>Select from available link location</FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel>Publisher Name</FormLabel>
            <Input type="text" variant="outline" value={formik.values.publisher} isReadOnly />
          </FormControl>
          <Stack isInline spacing={2} justifyContent="space-between" shouldWrapChildren>
            <PrimaryButton
              width={160}
              isLoading={formik.isSubmitting}
              onClick={formik.submitForm}
              isDisabled={isDisabled}
            >
              Complete Task
            </PrimaryButton>
            <Box>
              <DangerButton onClick={onAbandon} isDisabled={formik.isSubmitting || isDisabled}>
                Unassign Task
              </DangerButton>
              <HelpText standardLeftMargin>
                {`"Unassign Task" will unassign this task from you and return it to the queue. `}
                <strong>This cannot be undone and may result in lost work.</strong>
              </HelpText>
            </Box>
          </Stack>
        </Stack>
      </PanelMiddle>
      <PanelBottom>
        <Stack spacing={4}>
          <Stack isInline spacing={2} alignItems="center">
            <PrimaryLinkButton width={160} onClick={onClickAddLinkLocation} isDisabled={isDisabled}>
              Add Link Location
            </PrimaryLinkButton>
          </Stack>
          <LinkLocation
            customerId={customerId}
            refetch={refetch}
            onRefetchDone={onRefetchDone}
            showSelect={true}
            showExtraInformation={true}
            onSelected={onLinkLocationSelected}
            isDisabled={isDisabled}
            disableAvailableStatus={true}
            minDA={product?.minDA ?? 0}
            minSV={product?.minSV ?? 0}
          />
        </Stack>
      </PanelBottom>
      <AddLinkLocationModal
        isOpen={showAddLinkLocation}
        onAdded={onLinkLocationAdded}
        onClose={onCloseAddLinkLocation}
      />
    </PanelGroup>
  );
}

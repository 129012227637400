import { Badge, Box, Stack, Text } from "@chakra-ui/react";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";

import { AddPartnerAvailableProductDrawer } from "../../common/add-partner-available-product";
import { ConfigurationSecondaryNav } from "../../common/configuration-secondary-nav";
import { EditPartnerAvailableProductDrawer } from "../../common/edit-partner-available-product";
import { AdminBadge } from "../../components/admin-badge";
import { PrimaryIconButton } from "../../components/buttons";
import { HelpText } from "../../components/help-text";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { PanelBox } from "../../components/panels";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { currencyFormatter } from "../../util/formatter";
import { useConfigurationAvailableBaseProductTypes } from "./configuration-available-base-product-types-hook";

export function ConfigurationAvailableBaseProductTypes() {
  const {
    partnerId,
    availableProducts,
    loading,
    partner,
    products,
    onAdd,
    onAddClose,
    onEdit,
    onEditClose,
    productToAdd,
    availableProductToEdit,
    onAdded,
    onEdited,
  } = useConfigurationAvailableBaseProductTypes();

  return (
    <LayoutBase pageTitle={`${partner?.name ?? "Partner"} Available Base Product Types`}>
      <MainNav hasSecondaryNav />
      <ConfigurationSecondaryNav partnerId={partnerId.toString()} activeSection="available-product-types" />
      <Container centerHorizontally>
        <Stack spacing={1} marginY={4}>
          <PageHeading preventMarginY>{partner?.name ?? "Partner"} Available Base Product Types</PageHeading>
          <Text>
            <AdminBadge />
          </Text>
          <Text>
            This screen describes the Base Product Types that are available to {partner?.name ?? "loading"} and at what
            wholesale price. These are different than the Product Types that are chosen when creating orders, which are
            created from the Product Types screen in the Configuration for the Partner.
          </Text>
        </Stack>
        <PanelBox containsTable>
          <Table isEmpty={products.length === 0} isLoading={loading}>
            <TableHead>
              <TableRow key="head">
                <TableHeaderCell>Base Product Type</TableHeaderCell>
                <TableHeaderCell>
                  Wholesale Price
                  <HelpText standardLeftMargin>
                    The price that will be charged to the partner by RCM each time an order is created.
                  </HelpText>
                </TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => {
                const matchingPartnerAvailableProduct = availableProducts.find((x) => x.productId === product.id);
                return (
                  <TableRow key={product.id}>
                    <TableCell>
                      <Box maxW={500}>
                        <Text>
                          {product.name}
                          {product.deprecatedAt && (
                            <Badge marginLeft={1} colorScheme="orange">
                              Deprecated
                            </Badge>
                          )}
                        </Text>
                        <Text fontSize="sm">{product.description ?? "No description provided."}</Text>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {matchingPartnerAvailableProduct && (
                        <Text fontWeight="bold">
                          {currencyFormatter.format(matchingPartnerAvailableProduct.wholesalePrice)}
                        </Text>
                      )}
                      {!matchingPartnerAvailableProduct && (
                        <Text>Default: {currencyFormatter.format(product.defaultWholesalePrice)}</Text>
                      )}
                    </TableCell>
                    <TableCell>
                      {matchingPartnerAvailableProduct && <Badge colorScheme="green">Active</Badge>}
                      {!matchingPartnerAvailableProduct && <span>-</span>}
                    </TableCell>
                    <TableCell>
                      {matchingPartnerAvailableProduct && (
                        <PrimaryIconButton
                          icon={faPencilAlt}
                          isDisabled={product.isSystemManaged}
                          actionName={product.isSystemManaged ? "Managed by the System" : "Edit"}
                          onClick={onEdit.bind(null, matchingPartnerAvailableProduct)}
                        />
                      )}
                      {!matchingPartnerAvailableProduct && !product.deprecatedAt && (
                        <PrimaryIconButton
                          icon={faPlus}
                          isDisabled={product.isSystemManaged}
                          actionName={product.isSystemManaged ? "Managed by the System" : "Add"}
                          onClick={onAdd.bind(null, product)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </PanelBox>
      </Container>
      <AddPartnerAvailableProductDrawer
        partnerId={partnerId}
        product={productToAdd}
        isOpen={!!productToAdd}
        onClose={onAddClose}
        onAdded={onAdded}
      />
      <EditPartnerAvailableProductDrawer
        partnerAvailableProductId={availableProductToEdit?.id ?? null}
        isOpen={!!availableProductToEdit}
        onClose={onEditClose}
        onEdited={onEdited}
      />
    </LayoutBase>
  );
}

export const EMAIL_PREFERENCE = {
  ALL_EMAILS: {
    id: "all",
    name: "All Emails",
  },
  ONLY_ACTION_EMAILS: {
    id: "action",
    name: "Only action required emails",
  },
  NO_EMAILS: {
    id: "no",
    name: "No emails",
  },
};

import { useProposedKeywordTable } from "./use-proposed-keyword-table";
import { PanelBox, PanelTop, PanelGroup, PanelMiddle, PanelBottom } from "../../components/panels";
import { FormControl, FormErrorMessage, Input, Switch, FormLabel, Box, Heading, Stack, Text } from "@chakra-ui/react";
import { DangerLinkButton } from "../../components/buttons";
import { KeywordResearchTaskWorkspace_ProposedKeywordFragment } from "../../generated/graphql";
import { AppColors } from "../../core/custom-theme";
import { KeywordLocationSelector } from "../keyword-location-selector";

interface Props {
  customerKeywordProposalId: number;
  refetchKeywords: () => void;
  keyword: KeywordResearchTaskWorkspace_ProposedKeywordFragment;
  deleteNewKeyword: () => void;
  onAddSuccess: () => void;
  isRejected: boolean;
}

export function ProposedKeywordRow(props: Props) {
  const { isRejected, ...remainingProps } = props;
  const { formik, deleteKeyword, createOrUpdate } = useProposedKeywordTable(remainingProps);
  const Container = isRejected ? PanelGroup : PanelBox;
  const PanelForm = isRejected ? PanelMiddle : Box;
  return (
    <Container key={props.keyword.id}>
      {isRejected && (
        <>
          <PanelTop showBottomBorder backgroundColor={AppColors.error} color={AppColors.white}>
            <Heading size="sm">
              <Stack>
                <Text>Revision Requested</Text>
              </Stack>
            </Heading>
          </PanelTop>
          {props.keyword.rejectionReason && (
            <PanelMiddle>
              <Stack>
                <Text fontSize="md">Client Comments</Text>
                <Text fontSize="md">{props.keyword.rejectionReason}</Text>
              </Stack>
            </PanelMiddle>
          )}
        </>
      )}
      <PanelForm>
        <form>
          <Box display="grid" gridTemplateColumns="3fr 1fr 1fr">
            <Box>
              <FormControl isInvalid={!!formik.touched.name && !!formik.errors.name} marginBottom={2}>
                <FormLabel>Keyword</FormLabel>
                <Input
                  id="name"
                  value={formik.values.name}
                  onBlur={formik.submitForm}
                  onChange={formik.handleChange}
                  placeholder="Name"
                />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              </FormControl>
              <FormControl marginBottom={2} isInvalid={!!formik.touched.name && !!formik.errors.name}>
                <KeywordLocationSelector
                  location={formik.values.location ?? ""}
                  onLocationChange={(value) => formik.setFieldValue("location", value)}
                  onLocationBlur={formik.submitForm}
                  country={formik.values.country ?? ""}
                  onCountryChange={(value) => formik.setFieldValue("country", value)}
                  onCountryBlur={formik.submitForm}
                />
                <FormErrorMessage>{formik.errors.location}</FormErrorMessage>
              </FormControl>
              <FormControl
                marginBottom={2}
                isInvalid={!!formik.touched.landingPageUrl && !!formik.errors.landingPageUrl}
              >
                <FormLabel>Landing Page URL</FormLabel>
                <Input
                  id="landingPageUrl"
                  value={formik.values.landingPageUrl ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.submitForm}
                  placeholder="Landing Page URL"
                />
                <FormErrorMessage>{formik.errors.landingPageUrl}</FormErrorMessage>
              </FormControl>
            </Box>
            <Box justifyContent="center" display="grid">
              <FormControl textAlign="center">
                <FormLabel htmlFor={`isPrimary`}>Primary</FormLabel>
                <Switch
                  id={`isPrimary`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => createOrUpdate(formik.values, e.target.checked)}
                  isChecked={formik.values.isPrimary ?? false}
                />
              </FormControl>
            </Box>
            <Box justifyContent="center" display="grid" alignItems="flex-start" mt={8}>
              <DangerLinkButton onClick={() => deleteKeyword(props.keyword.id)}>Delete</DangerLinkButton>
            </Box>
          </Box>
        </form>
      </PanelForm>
      {isRejected && <PanelBottom />}
    </Container>
  );
}

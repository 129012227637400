import { faTasks } from "@fortawesome/free-solid-svg-icons";

import { useLinkLocationTypesQuery } from "../../generated/graphql";
import { CustomMultiSelect, CustomSingleSelect } from "../custom-selector";

interface CommonProps {
  emptyValueLabel?: string;
  isMulti?: true;
  dontUsePortal?: true;
}

interface MultiProps extends CommonProps {
  selectedTypeIds: string[];
  onTypeSelected: (selectedTypeIds: string[]) => void;
}

interface SingleProps extends CommonProps {
  selectedTypeId?: string | null;
  onTypeSelected: (selectedTypeId: string) => void;
}

/** Defaults to SingleSelect */
export function LinkLocationTypeSelector(props: MultiProps | SingleProps) {
  // type-guard
  function isMulti(props: SingleProps | MultiProps): props is MultiProps {
    return !!props.isMulti;
  }

  const { data } = useLinkLocationTypesQuery({ fetchPolicy: "network-only" });

  function onSelectedValueChange(values: string[] | string | null) {
    if (isMulti(props)) {
      props.onTypeSelected(values as string[]);
    } else {
      props.onTypeSelected(values as string);
    }
  }

  const items =
    data?.linkLocationTypes.map((node) => ({
      value: node.id,
      displayText: node.name,
    })) ?? [];

  if (isMulti(props)) {
    return (
      <CustomMultiSelect
        onChange={onSelectedValueChange}
        value={props.selectedTypeIds}
        items={items}
        selectorIcon={faTasks}
        emptyValueTitle={props.emptyValueLabel}
        pluralizedItemName="Types"
      />
    );
  } else {
    return (
      <CustomSingleSelect
        onChange={onSelectedValueChange}
        value={props.selectedTypeId ?? null}
        items={items}
        selectorIcon={faTasks}
        emptyValueTitle={props.emptyValueLabel}
        allowEmpty
        dontUsePortal={props.dontUsePortal}
      />
    );
  }
}

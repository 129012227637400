import { Flex, Heading, Stack, FormControl, FormLabel, Tooltip, Box, useToast } from "@chakra-ui/react";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  DomainDetailsQueryResult,
  useCreateDomainCategoryMutation,
  useDeleteDomainCategoryMutation,
} from "../../generated/graphql";
import { CategorySelector } from "../../common/category-selector";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { PrimaryIconButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";

interface Props {
  domainDetailsQueryResult: DomainDetailsQueryResult;
}

export function DomainDetailsCategories(props: Props) {
  const { domainDetailsQueryResult } = props;

  const { data, loading, refetch } = domainDetailsQueryResult;
  const domainCategories = data?.domain?.domainCategories.nodes ?? [];
  const toast = useToast();

  const [createDomainCategory, { loading: createLoading }] = useCreateDomainCategoryMutation();
  const [deleteDomainCategory, { loading: deleteLoading }] = useDeleteDomainCategoryMutation();

  async function onCategorySelected(categoryId: number) {
    const domainId = data?.domain?.id;
    if (!domainId) {
      toast({
        title: "Error",
        description: "Domain id is required",
        status: "error",
      });
      return;
    }

    const selectedCategory = domainCategories.find((domainCategory) => domainCategory.category?.id === categoryId);
    if (selectedCategory) {
      toast({
        title: "Category Already Added",
        description: "Category has already been added for this domain.",
        status: "warning",
      });
      return;
    }

    try {
      const response = await createDomainCategory({
        variables: {
          input: {
            categoryId,
            domainId,
          },
        },
      });

      if (response.data?.createDomainCategory.domainCategory?.id) {
        toast({
          title: "Success",
          description: "Domain category created successfully!",
          status: "success",
        });
        refetch();
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  async function onDelete(id: number) {
    try {
      const response = await deleteDomainCategory({
        variables: {
          input: {
            id,
          },
        },
      });

      if (response.data?.deleteDomainCategory.isDeleted) {
        toast({
          title: "Success",
          description: "Domain category deleted successfully!",
          status: "success",
        });
        refetch();
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  return (
    <>
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Flex>
            <Heading size="md">Categories</Heading>
          </Flex>
        </PanelTop>
        <PanelBottom paddingBottom={12}>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Business Category</FormLabel>
              <CategorySelector
                variant="outline"
                onCategorySelected={onCategorySelected}
                isDisabled={createLoading}
                placeholder="Select a Category to Add"
              />
            </FormControl>
            <Table isEmpty={domainCategories.length === 0} isLoading={loading}>
              <TableHead>
                <TableRow key="head">
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {domainCategories.map((domainCategory) => (
                  <TableRow key={domainCategory.id}>
                    <TableCell>
                      {domainCategory.category && domainCategory.category.name ? domainCategory.category.name : ""}
                    </TableCell>
                    <TableCell>
                      <Box textAlign="right">
                        <Tooltip title="Delete" aria-label="Delete">
                          <PrimaryIconButton
                            onClick={onDelete.bind(null, domainCategory.id)}
                            icon={faTrash}
                            actionName="Delete Category"
                            isDisabled={deleteLoading}
                          />
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        </PanelBottom>
      </PanelGroup>
    </>
  );
}

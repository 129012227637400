import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  PartnerAvailableProducts_PartnerAvailableProductFragment,
  PartnerAvailableProducts_ProductFragment,
  usePartnerAvailableProductsQuery,
} from "../../generated/graphql";

export function useConfigurationAvailableBaseProductTypes() {
  const { partnerId } = useParams<"partnerId">();
  const parsedPartnerId = parseInt(partnerId ?? "");
  const [productToAdd, setProductToAdd] = useState<PartnerAvailableProducts_ProductFragment | null>(null);
  const [availableProductToEdit, setAvailableProductToEdit] =
    useState<PartnerAvailableProducts_PartnerAvailableProductFragment | null>(null);

  const availableProductsQueryResult = usePartnerAvailableProductsQuery({
    variables: {
      id: parsedPartnerId,
    },
    fetchPolicy: "network-only",
  });

  const partner = availableProductsQueryResult.data?.partner ?? null;
  const products = availableProductsQueryResult.data?.products.nodes ?? [];
  const availableProducts = partner?.partnerAvailableProducts ?? [];

  function onAdd(product: PartnerAvailableProducts_ProductFragment) {
    setProductToAdd(product);
  }

  function onAddClose() {
    setProductToAdd(null);
  }

  function onAdded() {
    setProductToAdd(null);
    availableProductsQueryResult.refetch();
  }

  function onEdit(product: PartnerAvailableProducts_PartnerAvailableProductFragment) {
    setAvailableProductToEdit(product);
  }

  function onEditClose() {
    setAvailableProductToEdit(null);
  }

  function onEdited() {
    setAvailableProductToEdit(null);
    availableProductsQueryResult.refetch();
  }

  return {
    partnerId: parsedPartnerId,
    loading: availableProductsQueryResult.loading,
    partner,
    products,
    availableProducts,
    productToAdd,
    onAdd,
    onAddClose,
    onAdded,
    availableProductToEdit,
    onEdit,
    onEditClose,
    onEdited,
  };
}

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { CancelButton, PrimaryButton } from "../../components/buttons";
import { DATE_PICKER_FORMAT } from "../../constants/constants";
import { useAddAmTaskDrawer } from "./use-add-am-task-drawer";

interface Props {
  open: boolean;
  customerId: number;
  onCancel: () => void;
  onAdded: (taskId: number) => void;
  minimumDate?: Date | null;
  maximumDate?: Date | null;
}

export function AddAmTaskDrawer(props: Props) {
  const { open, onCancel, onAdded, customerId, minimumDate, maximumDate } = props;

  const { formik, handleCancel, dateRangeStart, dateRangeEnd } = useAddAmTaskDrawer({
    customerId,
    onCancel,
    minimumDate,
    maximumDate,
    onAmTaskAdded: onAdded,
  });

  return (
    <Drawer onClose={handleCancel} isOpen={open} placement="right" size="md" closeOnOverlayClick={false} isFullHeight>
      <DrawerOverlay />
      <form onSubmit={formik.handleSubmit}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Am Task</DrawerHeader>
          <DrawerBody>
            <Box padding={4} minWidth={400}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Task Type</FormLabel>
                  <Input
                    variant="outline"
                    value={formik.values.taskType}
                    onChange={formik.handleChange}
                    id="taskType"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Completed Date</FormLabel>
                  <DatePicker
                    onChange={(date: Date) => {
                      formik.setFieldValue("completedAt", date);
                    }}
                    isClearable={false}
                    selected={formik.values.completedAt}
                    dateFormat={DATE_PICKER_FORMAT}
                    customInput={<Input variant="outline" width="100%" />}
                    disabledKeyboardNavigation
                    placeholderText="Select completed date"
                    minDate={dateRangeStart}
                    maxDate={dateRangeEnd}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Info</FormLabel>
                  <Textarea value={formik.values.info ?? ""} id="info" onChange={formik.handleChange} />
                </FormControl>
              </Stack>
            </Box>
          </DrawerBody>
          <DrawerFooter>
            <SimpleGrid columns={2} gridGap={4} width="100%">
              <PrimaryButton width="100%" type="submit">
                Save
              </PrimaryButton>
              <CancelButton onClick={handleCancel} width="100%">
                Cancel
              </CancelButton>
            </SimpleGrid>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
}

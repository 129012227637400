import { useContext } from "react";
import { AuthContext } from "../../../core/auth-manager";
import { MainNavSmallLink } from "./main-nav-small-link";
import { Text, Box } from "@chakra-ui/react";

export function MainNavSmallProfile() {
  const authContext = useContext(AuthContext);

  return (
    <Box>
      <Box marginBottom={4}>
        <Text style={{ fontWeight: "bold" }}>{authContext.viewer?.user?.fullName ?? "Not Logged In"}</Text>
      </Box>
      <Box marginBottom={2}>
        <MainNavSmallLink to="/profile">Profile</MainNavSmallLink>
      </Box>
      <Box marginBottom={2}>
        <MainNavSmallLink
          to=""
          onClick={() => {
            authContext.logout();
          }}
        >
          Logout
        </MainNavSmallLink>
      </Box>
    </Box>
  );
}

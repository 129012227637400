import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { faStripe } from "@fortawesome/free-brands-svg-icons";
import { CardElement } from "@stripe/react-stripe-js";
import { AlertInfo } from "../../components/alerts";
import AppIcon from "../../components/app-icon";
import { CancelButton, PrimaryButton } from "../../components/buttons";
import { useAddPaymentMethodModal } from "./add-payment-method-modal-hook";
import "./add-payment-method.css";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAdded: () => void;
  partnerId: number;
}

export function AddPaymentMethodModal(props: Props) {
  const { isOpen, onClose } = props;
  const { handleSubmit, isSubmitting, showTestData } = useAddPaymentMethodModal(props);
  return (
    <form onSubmit={handleSubmit}>
      <Modal onClose={onClose} isOpen={isOpen} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Payment Method</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <AlertInfo>
                <Text>Your card will not be billed automatically unless you setup Automatic Payments.</Text>
              </AlertInfo>
              {showTestData && (
                <AlertInfo marginBottom={8}>
                  <Stack spacing={0}>
                    <Heading size="sm" marginBottom={2}>
                      TEST DATA
                    </Heading>
                    <Text>
                      <strong>Card Number: </strong> 4242424242424242
                    </Text>
                    <Text>
                      <strong>Expiration: </strong> Any future month/year
                    </Text>
                    <Text>
                      <strong>CVC: </strong> Any value
                    </Text>
                  </Stack>
                </AlertInfo>
              )}

              <FormControl>
                <FormLabel>Card Information</FormLabel>
                <div>
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: "1rem",
                        },
                      },
                    }}
                  />
                </div>
                <FormHelperText>
                  <AppIcon icon={faStripe} size="lg" standardRightMargin />
                  All payment information is stored securely by Stripe.
                </FormHelperText>
              </FormControl>
              <FormControl>
                <Checkbox>
                  I authorize Red Canyon Media to send instructions to the financial institution that issued my card to
                  take payments from my card account in accordance with the terms of my agreement with you.
                </Checkbox>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Stack spacing={2} isInline>
              <PrimaryButton onClick={handleSubmit} type="submit" isLoading={isSubmitting}>
                Save
              </PrimaryButton>
              <CancelButton onClick={onClose}>Cancel</CancelButton>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </form>
  );
}

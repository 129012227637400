import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { useOrderStatusesQuery } from "../../generated/graphql";
import { CustomMultiSelect } from "../custom-selector";

interface Props {
  selectedStatusIds: string[];
  onStatusSelected: (statusIds: string[]) => void;
  emptyValueLabel?: string;
  showSelectAll?: boolean;
}

export function OrderStatusSelector(props: Props) {
  const { selectedStatusIds, onStatusSelected, emptyValueLabel, showSelectAll } = props;

  const { data } = useOrderStatusesQuery({ fetchPolicy: "network-only" });

  const items =
    data?.orderStatuses.map((node) => ({
      value: node.id,
      displayText: node.name,
    })) ?? [];

  return (
    <CustomMultiSelect
      onChange={onStatusSelected}
      value={selectedStatusIds}
      items={items}
      selectorIcon={faTasks}
      emptyValueTitle={emptyValueLabel}
      showSelectAll={showSelectAll}
    />
  );
}

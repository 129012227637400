import { DateTime } from "luxon";
import { Flex, Heading, Stack, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { Loader } from "../../components/loader";
import { DATE_TIME_FORMAT } from "../../constants/constants";
import { DomainDetailsQueryResult } from "../../generated/graphql";

interface Props {
  domainDetailsQueryResult: DomainDetailsQueryResult;
}

export function DomainDetailsOverview(props: Props) {
  const { domainDetailsQueryResult } = props;

  const { data, loading } = domainDetailsQueryResult;
  const domainDetails = data?.domain;

  return (
    <>
      {loading && (
        <>
          <PanelGroup>
            <PanelTop showBottomBorder>
              <Heading size="md">Basic Information</Heading>
            </PanelTop>
            <PanelBottom>
              <Loader type="content" />
            </PanelBottom>
          </PanelGroup>
        </>
      )}
      {!loading && !!domainDetails && (
        <>
          <PanelGroup>
            <PanelTop showBottomBorder>
              <Flex>
                <Heading size="md">Basic Information</Heading>
              </Flex>
            </PanelTop>
            <PanelBottom paddingBottom={12}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Domain Name</FormLabel>
                  <Input variant="outline" value={domainDetails.id} isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <Input variant="outline" value={domainDetails.status.name} isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Created</FormLabel>
                  <Input
                    variant="outline"
                    value={DateTime.fromJSDate(domainDetails.createdAt).toFormat(DATE_TIME_FORMAT)}
                    isReadOnly
                  />
                </FormControl>
                {!!domainDetails.expiresAt && (
                  <FormControl>
                    <FormLabel>Expires</FormLabel>
                    <Input
                      variant="outline"
                      value={DateTime.fromISO(domainDetails.expiresAt).toFormat(DATE_TIME_FORMAT)}
                      isReadOnly
                    />
                  </FormControl>
                )}
                {!!domainDetails.purchasedAt && (
                  <FormControl>
                    <FormLabel>Purchased Date</FormLabel>
                    <Input
                      variant="outline"
                      value={DateTime.fromJSDate(domainDetails.purchasedAt).toFormat(DATE_TIME_FORMAT)}
                      isReadOnly
                    />
                  </FormControl>
                )}
                {!!domainDetails.availabilityLastCheckedAt && (
                  <FormControl>
                    <FormLabel>Availability Last Checked At</FormLabel>
                    <Input
                      variant="outline"
                      value={DateTime.fromJSDate(domainDetails.availabilityLastCheckedAt).toFormat(DATE_TIME_FORMAT)}
                      isReadOnly
                    />
                  </FormControl>
                )}
                {!!domainDetails.registrar && (
                  <FormControl>
                    <FormLabel>Registrar</FormLabel>
                    <Input
                      variant="outline"
                      value={`${domainDetails.registrar}(${domainDetails.registrarId})`}
                      isReadOnly
                    />
                  </FormControl>
                )}
                {!!domainDetails.purchaseRequestedByUser?.fullName && (
                  <FormControl>
                    <FormLabel>Purchaser</FormLabel>
                    <Input variant="outline" value={domainDetails.purchaseRequestedByUser.fullName} isReadOnly />
                  </FormControl>
                )}
              </Stack>
            </PanelBottom>
          </PanelGroup>
        </>
      )}
    </>
  );
}

import { Badge } from "@chakra-ui/react";

interface Props {
  startsAt: Date;
  isCurrent: boolean;
}

export function ClientCampaignStatusIndicator(props: Props) {
  const { isCurrent, startsAt } = props;

  let color: string | undefined = undefined;
  let status = "SCHEDULED";
  if (isCurrent) {
    color = "green";
    status = "ACTIVE";
  } else if (startsAt > new Date()) {
    color = "yellow";
    status = "SCHEDULED";
  } else if (startsAt < new Date()) {
    color = "red";
    status = "PAST"; // TODO: verify name
  }
  return <Badge colorScheme={color}>{status}</Badge>;
}

import { DateTime } from "luxon";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  DrawerFooter,
  SimpleGrid,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useEditOrderTopicDrawer } from "./use-edit-order-topic-drawer";
import { AlertWarning } from "../../components/alerts";
import { RichContent } from "../../components/rich-content";
import { LockedInput } from "../../components/locked-input";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { PrimaryButton, CancelButton } from "../../components/buttons";
import { CustomerKeywordSelector } from "../customer-keyword-selector";
import { DrawerProposedOrderTopic } from "./common";

interface Props {
  onClose: () => void;
  onEdited: () => void;
  open: boolean;
  taskId: number;
  customerId: number;
  customerProposalId: number;
  orderTopic: DrawerProposedOrderTopic | null;
}

export function EditOrderTopicDrawer(props: Props) {
  const { open, orderTopic, customerId } = props;
  const { handleCancel, formik } = useEditOrderTopicDrawer(props);

  return (
    <Drawer placement="right" onClose={handleCancel} isOpen={open} size="lg" isFullHeight>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit Order</DrawerHeader>
        <DrawerBody>
          <Stack spacing={2}>
            {!!orderTopic?.rejectionComment && (
              <AlertWarning>
                <Stack spacing={1}>
                  <Text>
                    <strong>Rejection Comments</strong>
                  </Text>
                  <RichContent content={orderTopic.rejectionComment} />
                </Stack>
              </AlertWarning>
            )}
            <FormControl>
              <FormLabel>Order ID</FormLabel>
              <LockedInput value={orderTopic?.orderId ?? ""} variant="outline" />
            </FormControl>
            <FormControl>
              <FormLabel>Type</FormLabel>
              <LockedInput value={orderTopic?.type ?? ""} variant="outline" />
            </FormControl>
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <LockedInput
                value={orderTopic ? DateTime.fromJSDate(orderTopic.startDate).toFormat(DATE_MONTH_FORMAT) : "?"}
                variant="outline"
              />
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.customerKeywordId && !!formik.touched.customerKeywordId}>
              <FormLabel>Keyword</FormLabel>
              <CustomerKeywordSelector
                customerId={customerId}
                keywordTypes="primary"
                onSelectedCustomerKeywordChange={(keywordId) => {
                  formik.setFieldValue("customerKeywordId", keywordId);
                }}
                variant="outline"
                selectedCustomerKeywordId={formik.values.customerKeywordId}
              />
              <FormErrorMessage>{formik.errors.customerKeywordId}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.topic && !!formik.touched.topic}>
              <FormLabel>Topic</FormLabel>
              <Input variant="outline" id="topic" value={formik.values.topic} onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.topic}</FormErrorMessage>
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <SimpleGrid gridGap={2} columns={2} width="100%">
            <PrimaryButton
              isLoading={formik.isSubmitting}
              isDisabled={formik.isSubmitting}
              onClick={() => {
                formik.submitForm();
              }}
            >
              Save
            </PrimaryButton>
            <CancelButton onClick={handleCancel}>Cancel</CancelButton>
          </SimpleGrid>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

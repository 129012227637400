import { Box, FormControl, FormLabel, Select, Stack, Stat, StatLabel, StatNumber, Text } from "@chakra-ui/react";

import { AdminBadge } from "../../components/admin-badge";
import { AlertInfo } from "../../components/alerts";
import AppLink from "../../components/app-link";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelBox, PanelGroup, PanelTop } from "../../components/panels";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { currencyFormatter } from "../../util/formatter";
import { useAdminBilling } from "./admin-billing-hook";

export function AdminBillingRoute() {
  const { billablePartners, selectedRange, handleRangeChange, totalBillableAmount, loading } = useAdminBilling();
  return (
    <LayoutBase pageTitle="Billing (Admin)">
      <MainNav />
      <Container centerHorizontally>
        <Stack spacing={4}>
          <Box>
            <PageHeading preventMarginY>Admin Billing Overview</PageHeading>
            <Text>
              <AdminBadge />
            </Text>
          </Box>
          <AlertInfo>Only partners with &quot;Is Billing Enforced&quot; enabled will be shown here.</AlertInfo>
          <Stack spacing={4} shouldWrapChildren>
            <PanelBox maxWidth={350}>
              <Stat>
                <StatLabel>Total Billable Wholesale for Range</StatLabel>
                <StatNumber>{currencyFormatter.format(totalBillableAmount)}</StatNumber>
              </Stat>
            </PanelBox>
          </Stack>
          <PanelGroup>
            <PanelTop>
              <FormControl>
                <FormLabel>Show Orders With Start Date</FormLabel>
                <Select value={selectedRange} onChange={handleRangeChange}>
                  <option value="day">In the Next Day</option>
                  <option value="week">In the Next Week</option>
                  <option value="month">In the Next Month</option>
                </Select>
              </FormControl>
            </PanelTop>
            <PanelBottom containsTable>
              <Table isEmpty={billablePartners.length === 0} isLoading={loading}>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>Partner</TableHeaderCell>
                    <TableHeaderCell>Billable Orders</TableHeaderCell>
                    <TableHeaderCell>Total Billable Amount</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billablePartners.map((partner) => (
                    <TableRow key={partner.id}>
                      <TableCell>
                        <AppLink to={`/admin/billing/${partner.id}?range=${selectedRange}`}>{partner.name}</AppLink>
                      </TableCell>
                      <TableCell>{partner.billableOrders.nodes.length}</TableCell>
                      <TableCell>
                        {currencyFormatter.format(
                          partner.billableOrders.nodes.reduce((acc, item) => acc + item.totalWholesaleAmount, 0)
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </PanelBottom>
          </PanelGroup>
        </Stack>
      </Container>
    </LayoutBase>
  );
}

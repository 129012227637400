import { ButtonProps, Button } from "@chakra-ui/react";
import Color from "color";
import { AppColors } from "../../core/custom-theme";

export function DangerLinkButton(props: ButtonProps) {
  return (
    <Button
      variant="link"
      color={AppColors.error}
      _hover={{
        color: Color(AppColors.error).darken(0.2).hex(),
      }}
      _active={{
        color: Color(AppColors.error).darken(0.5).hex(),
      }}
      _focus={{
        outline: "none",
      }}
      fontSize="14px"
      {...props}
    />
  );
}

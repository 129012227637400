import { useEffect, useMemo, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { usePublishPostTaskWorkspaceQuery, useUpdatePublishPostMutation } from "../../generated/graphql";
import { handleGraphQLResponseError } from "../../util/error-helper";

interface Options {
  taskId: number;
  onComplete: () => void;
}

export function useTaskWorkspacePublishPost(options: Options) {
  const { taskId, onComplete } = options;
  const [isPostsPublished, setIsPostsPublished] = useState<boolean[]>([]);
  const toast = useToast();

  const queryResult = usePublishPostTaskWorkspaceQuery({
    variables: {
      taskId,
    },
    fetchPolicy: "network-only",
  });

  const [updateIsPostPublished] = useUpdatePublishPostMutation();

  const task = queryResult.data?.task ?? null;
  const customer = queryResult.data?.task?.customer ?? null;
  const publishPostOrders = useMemo(() => queryResult.data?.task?.publishPostOrders ?? [], [queryResult]);
  const canSubmit = isPostsPublished.every((postPublished) => postPublished);

  useEffect(() => {
    if (publishPostOrders.length !== isPostsPublished.length) {
      const length = publishPostOrders.length;
      const flags = [];
      for (let i = 0; i < length; i++) {
        flags.push(false);
      }
      setIsPostsPublished(flags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishPostOrders]);

  async function changePublishedPost(orderContentItemId: number, isPostPublished: boolean) {
    try {
      const response = await updateIsPostPublished({
        variables: {
          input: {
            orderContentItemId,
            isPostPublished,
          },
        },
      });

      if (response.data?.updateOrderContentItem.ok) {
        toast({
          title: "Success",
          description: "Post Published updated successfully!",
          status: "success",
        });
      } else {
        toast({
          title: "Error",
          description: "Update of post published failed!",
          status: "error",
        });
      }
    } catch (e: any) {
      handleGraphQLResponseError(e, toast);
    }
  }

  async function handleComplete() {
    if (!task) {
      toast({
        title: "Task data can not be loaded",
        status: "error",
      });
      return;
    }

    try {
      await Promise.all(
        isPostsPublished.map((isPostPublished, index) => {
          changePublishedPost(publishPostOrders[index].orderContentItems?.[0].id ?? 0, isPostPublished);
        })
      );
      await queryResult.refetch();
    } catch (e: any) {
      toast({
        title: "Error",
        description: "Update of post published failed!",
        status: "error",
      });
    }
    onComplete();
  }

  function handleChangePublishedPost(index: number) {
    const flags = [...isPostsPublished];
    flags[index] = !isPostsPublished[index];
    setIsPostsPublished(flags);
  }

  return {
    taskId,
    task,
    customer,
    handleComplete,
    isPostsPublished,
    publishPostOrders,
    handleChangePublishedPost,
    canSubmit,
  };
}

import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { PrimaryButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";
import {
  PartnerAvailableProducts_ProductFragment,
  useAddPartnerAvailableProductMutation,
} from "../../generated/graphql";
import { LockedInput } from "../../components/locked-input";

interface Props {
  partnerId: number;
  product: PartnerAvailableProducts_ProductFragment | null;
  isOpen: boolean;
  onClose: () => void;
  onAdded: () => void;
}

interface FormValues {
  wholesalePrice: number;
}

const validationSchema = yup.object().shape({
  wholesalePrice: yup.number().label("Wholesale Price").required(),
});

export function AddPartnerAvailableProductDrawer(props: Props) {
  const { onClose, onAdded, isOpen, partnerId, product } = props;
  const [createAvailableProduct] = useAddPartnerAvailableProductMutation();
  const toast = useToast();

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    if (!product) {
      return;
    }
    try {
      const response = await createAvailableProduct({
        variables: {
          input: {
            partnerId,
            productId: product.id,
            wholesalePrice: values.wholesalePrice,
          },
        },
      });

      if (response.data?.createPartnerAvailableProduct.partnerAvailableProduct.id) {
        toast({
          title: "Partner Available Product",
          description: "Product added to partner available product successfully!",
          status: "success",
        });
        formikHelpers.resetForm();
        onAdded();
        onClose();
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      wholesalePrice: product?.defaultWholesalePrice ?? 0,
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema,
  });

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="sm" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add Available Base Product Type</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Base Product Type</FormLabel>
              <LockedInput variant="outline" value={product?.name ?? ""} />
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.wholesalePrice && !!formik.touched.wholesalePrice}>
              <FormLabel>Wholesale Price</FormLabel>
              <InputGroup>
                <InputLeftElement color="gray.300" fontSize="1.2em">
                  $
                </InputLeftElement>
                <Input
                  placeholder="Enter Wholesale Price"
                  variant="outline"
                  id="wholesalePrice"
                  type="number"
                  value={formik.values.wholesalePrice}
                  onChange={formik.handleChange}
                />
              </InputGroup>
              <FormErrorMessage>{formik.errors.wholesalePrice}</FormErrorMessage>
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <PrimaryButton width="100%" onClick={formik.submitForm} isDisabled={formik.isSubmitting}>
            Save
          </PrimaryButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

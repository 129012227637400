import StarRatings from "react-star-ratings";
import Color from "color";
import { PanelGroup, PanelTop, PanelBottom } from "../../components/panels";
import { useTaskWorkspaceContentReview } from "./use-task-workspace-content-review";
import { ContentEditor } from "../../components/content-editor";
import {
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Skeleton,
  FormErrorMessage,
  Box,
  Switch,
  Textarea,
  Flex,
  Text,
  Image,
  Select,
} from "@chakra-ui/react";
import { faInfoCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { DangerButton, PrimaryButton, PrimaryIconButton, SecondaryButton } from "../../components/buttons";
import { PageHeading } from "../../components/page-heading";
import { WorkflowNotes } from "../workflow-notes";
import { HelpText } from "../../components/help-text";
import { AppColors } from "../../core/custom-theme";
import { ReviewCommentsPanel } from "../review-comments-panel";
import { UploadFileDialog } from "../upload-file-modal";
import AppIcon from "../../components/app-icon";
import { htmlDecode } from "../../util/decode";

interface Props {
  taskId: number;
  onAbandon: () => void;
  onComplete: (isApproved?: boolean, reviewComment?: string) => void;
}

export function TaskWorkspaceContentReview(props: Props) {
  const { onAbandon } = props;
  const {
    loading,
    taskId,
    contentItem,
    formik,
    task,
    reviewTasks,
    reviewTargetTask,
    showThumbnailUpload,
    onThumbnailUpload,
    onThumbnailUploadCancel,
    onThumbnailUploaded,
    onThumbnailRemove,
    feedbackContentItemFormik,
  } = useTaskWorkspaceContentReview(props);
  return (
    <>
      <UploadFileDialog
        isOpen={showThumbnailUpload}
        onFileUploaded={onThumbnailUploaded}
        onCancel={onThumbnailUploadCancel}
        instructionText="Thumbnail must be a png or jpeg. Recommended size: 600px x 400px or smaller."
      />
      <PageHeading>Content Review Task</PageHeading>
      <Grid templateColumns={{ base: "1fr", xl: "2fr 1fr" }} gridGap={4}>
        <Stack spacing={4} gridColumn={1}>
          <ReviewCommentsPanel reviewTasks={reviewTasks} />
          <form noValidate onSubmit={formik.handleSubmit}>
            <PanelGroup>
              <PanelTop>
                <Stack spacing={4}>
                  <FormControl isRequired isInvalid={!!formik.touched.title && !!formik.errors.title}>
                    <FormLabel>Title</FormLabel>
                    <Input id="title" value={formik.values.title} onChange={formik.handleChange} />
                    <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired isInvalid={!!formik.touched.content && !!formik.errors.content}>
                    <FormLabel>Content</FormLabel>
                    {loading && <Skeleton height={50} />}
                    {!loading && (
                      <ContentEditor
                        isInvalid={!!formik.touched.content && !!formik.errors.content}
                        initialValue={contentItem?.content ?? ""}
                        onChange={(html) => {
                          formik.setFieldValue("content", html);
                        }}
                      />
                    )}
                    <FormErrorMessage>{formik.errors.content}</FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Meta Title</FormLabel>
                    <Input value={reviewTargetTask?.contentItem?.metaTitle ?? ""} isReadOnly />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Meta Description</FormLabel>
                    <Textarea
                      value={htmlDecode(reviewTargetTask?.contentItem?.metaDescription ?? "") ?? ""}
                      isReadOnly
                    />
                  </FormControl>
                  <Box>
                    <PanelTop>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Box>
                          <Heading size="md">Thumbnail</Heading>
                          <Text fontSize="sm" color={AppColors.textGray}>
                            Recommended Size: 600px x 400px
                          </Text>
                        </Box>
                        <SecondaryButton onClick={onThumbnailUpload}>Upload</SecondaryButton>
                      </Flex>
                    </PanelTop>
                    <PanelBottom>
                      {formik.values.file && (
                        <Box display="inline-flex" position="relative">
                          <Image
                            htmlHeight={400}
                            htmlWidth={600}
                            objectFit="cover"
                            src={formik.values.file.url}
                            alt={formik.values.file.name}
                            crossOrigin=""
                          />
                          <PrimaryIconButton
                            position="absolute"
                            top="-7px"
                            right="-15px"
                            justifyContent="flex-end"
                            paddingLeft={0}
                            paddingRight={0}
                            marginLeft={0}
                            marginRight={0}
                            height="auto"
                            backgroundColor="white"
                            outline="none"
                            minWidth="auto"
                            borderRadius="50%"
                            icon={faTimesCircle}
                            actionName="Remove Image"
                            onClick={onThumbnailRemove}
                            zIndex={1}
                          />
                        </Box>
                      )}
                      {!formik.values.file && (
                        <Text>
                          <AppIcon icon={faInfoCircle} standardRightMargin />
                          Not provided.
                        </Text>
                      )}
                    </PanelBottom>
                  </Box>
                  <FormControl>
                    <Stack spacing={2} isInline>
                      <Switch
                        color="green"
                        id="isApproved"
                        isChecked={formik.values.isApproved}
                        onChange={formik.handleChange}
                      />
                      <FormLabel color={formik.values.isApproved ? undefined : AppColors.error}>
                        {formik.values.isApproved ? "Approved" : "Rejected"}
                      </FormLabel>
                    </Stack>
                  </FormControl>
                  {!formik.values.isApproved && (
                    <FormControl isRequired isInvalid={!!formik.touched.comments && !!formik.errors.comments}>
                      <FormLabel>Comments</FormLabel>
                      {loading && <Skeleton height={50} />}
                      {!loading && (
                        <ContentEditor
                          isInvalid={!!formik.touched.comments && !!formik.errors.comments}
                          onChange={(html) => {
                            formik.setFieldValue("comments", html);
                          }}
                        />
                      )}
                      <FormErrorMessage>{formik.errors.comments}</FormErrorMessage>
                    </FormControl>
                  )}
                  {/* Currently, We are submitting feedback on Complete task button*/}
                  {formik.values.isApproved && (
                    <>
                      <FormControl>
                        <FormLabel>Star</FormLabel>
                        <StarRatings
                          rating={feedbackContentItemFormik.values.rating}
                          changeRating={(newValue: number) => {
                            feedbackContentItemFormik.setFieldValue("rating", newValue);
                          }}
                          starRatedColor={AppColors.blue}
                          starHoverColor={Color(AppColors.blue).darken(0.1).hex()}
                          starDimension="20px"
                          starSpacing="2px"
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Feedback</FormLabel>
                        <ContentEditor
                          isInvalid={
                            !!feedbackContentItemFormik.touched.feedback && !!feedbackContentItemFormik.errors.feedback
                          }
                          onChange={(html) => {
                            feedbackContentItemFormik.setFieldValue("feedback", html);
                          }}
                        />
                      </FormControl>
                    </>
                  )}
                </Stack>
              </PanelTop>
              <PanelBottom showTopBorder>
                <Stack isInline spacing={2} justifyContent="space-between" shouldWrapChildren>
                  <Stack spacing={2} isInline>
                    <PrimaryButton type="submit">Complete Task</PrimaryButton>
                  </Stack>
                  <Box>
                    <DangerButton onClick={onAbandon}>Unassign Task</DangerButton>
                    <HelpText standardLeftMargin>
                      &quot;Unassign Task&quot; will unassign this task from you and return it to the queue.{" "}
                      <strong>This cannot be undone and may result in lost work.</strong>
                    </HelpText>
                  </Box>
                </Stack>
              </PanelBottom>
            </PanelGroup>
          </form>
        </Stack>
        <Stack spacing={4} gridColumn={{ base: 1, xl: 2 }}>
          <PanelGroup isCollapsible>
            <PanelTop showBottomBorder>
              <Heading size="md">Information</Heading>
            </PanelTop>
            <PanelBottom>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Review Task ID</FormLabel>
                  <Input variant="outline" isReadOnly value={reviewTargetTask?.id ?? "-"} />
                </FormControl>
                <FormControl>
                  <FormLabel>Client Instructions</FormLabel>
                  <Textarea value={task?.customer?.instructions ?? "-"} variant="outline" resize="none" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Writer Name</FormLabel>
                  <Input variant="outline" isReadOnly value={reviewTargetTask?.assignedUser?.fullName ?? "-"} />
                </FormControl>
                <FormControl>
                  <FormLabel>Writer Email</FormLabel>
                  <Input variant="outline" isReadOnly value={reviewTargetTask?.assignedUser?.email ?? "-"} />
                </FormControl>
                <FormControl>
                  <FormLabel>Task ID</FormLabel>
                  <Input value={taskId} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Client</FormLabel>
                  <Input value={task?.customer.name ?? "-"} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Product Type</FormLabel>
                  <Input value={task?.order.partnerProduct.product.name ?? "-"} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Keyword</FormLabel>
                  <Input value={task?.keyword ?? "-"} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight={700}>Landing Page URL</FormLabel>
                  <Input value={task?.landingPageUrl ?? task?.customer?.website ?? "-"} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Topic</FormLabel>
                  <Input value={task?.order.topic ?? "-"} variant="outline" isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Would you like a featured image provided with onsite content?</FormLabel>
                  <Select
                    id="isAddImage"
                    value={
                      task?.customer?.customerQuestionnaire?.isAddImage
                        ? task.customer.customerQuestionnaire.isAddImage
                          ? "1"
                          : "0"
                        : ""
                    }
                    placeholder="Not provided"
                    variant="outline"
                    isDisabled
                  >
                    <option value="1" key="true">
                      Yes
                    </option>
                    <option value="0" key="false">
                      No
                    </option>
                  </Select>
                </FormControl>
              </Stack>
            </PanelBottom>
          </PanelGroup>
          <WorkflowNotes taskId={taskId} />
        </Stack>
      </Grid>
    </>
  );
}

import {
  Flex,
  Box,
  Popover,
  PopoverTrigger,
  Image,
  PopoverContent,
  Switch,
  FormLabel,
  Stack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { PageHeading } from "../../components/page-heading";
import { Container } from "../../components/layout/container";
import { PanelGroup, PanelBottom, PanelTop } from "../../components/panels";
import { CancelButton, PrimaryButton, SecondaryButton } from "../../components/buttons";
import { AddPartnerUserDrawer } from "../../common/add-partner-user-drawer";
import { SearchInput } from "../../components/search-input/search-input";
import FilterSVG from "../../assets/filter.svg";
import { LayoutBase, MainNav } from "../../components/layout";
import { ConfigurationSecondaryNav } from "../../common/configuration-secondary-nav";
import { useConfigurationUsers } from "./configuration-users-hook";
import {
  Table,
  TableBody,
  TableCell,
  TableFoot,
  TableHead,
  TableHeaderCell,
  TablePagination,
  TableRow,
} from "../../components/table";

export function ConfigurationUsersRoute() {
  const {
    partner,
    closeAddUserDrawer,
    closeFilter,
    loading,
    onChange,
    onNextPage,
    onPreviousPage,
    onSwitchChange,
    onUserAdded,
    openAddUserDrawer,
    openFilter,
    partnerId,
    partnerUsers,
    showAddPartnerUserDrawer,
    showFilter,
    totalCount,
    includeInactiveFlag,
    offset,
    limit,
    isActiveOpen,
    isAdminOpen,
    onActiveChange,
    onActiveClose,
    onAdminChange,
    onAdminClose,
    updateToggleActive,
    updateToggleAdmin,
    toggleActive,
    toggleAdmin,
    activeCancelRef,
    adminCancelRef,
  } = useConfigurationUsers();

  const prefixPartnerName = partner ? `${partner.name} ` : "";

  return (
    <LayoutBase pageTitle={`${prefixPartnerName}Users`}>
      <MainNav hasSecondaryNav />
      <ConfigurationSecondaryNav partnerId={partnerId.toString()} activeSection="users" />
      <Container centerHorizontally>
        <Flex alignItems="center">
          <PageHeading>{prefixPartnerName}Users</PageHeading>
        </Flex>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                <SearchInput onChange={onChange} />
              </Box>
              <Stack isInline spacing={2}>
                <Box>
                  <SecondaryButton onClick={openFilter}>
                    Filter <Image src={FilterSVG} ml={2} />
                  </SecondaryButton>
                  <Popover isOpen={showFilter} onClose={closeFilter}>
                    <PopoverTrigger>
                      <Box top="-8px" position="relative" />
                    </PopoverTrigger>
                    <PopoverContent zIndex={4}>
                      <Flex justifyContent="space-between" padding={4}>
                        <FormLabel htmlFor="inactive-switch">Hide Inactive Users</FormLabel>
                        <Switch id="inactive-switch" isChecked={!includeInactiveFlag} onChange={onSwitchChange} />
                      </Flex>
                    </PopoverContent>
                  </Popover>
                </Box>
                <Box marginRight={2}>
                  <PrimaryButton onClick={openAddUserDrawer}>Add User</PrimaryButton>
                </Box>
              </Stack>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <Table isEmpty={partnerUsers.length === 0} isLoading={loading}>
              <TableHead>
                <TableRow key="head">
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Email</TableHeaderCell>
                  <TableHeaderCell>Active</TableHeaderCell>
                  <TableHeaderCell>Admin</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partnerUsers.map((partnerUser) => (
                  <TableRow key={partnerUser.id}>
                    <TableCell>{partnerUser.user.fullName}</TableCell>
                    <TableCell>{partnerUser.user.email}</TableCell>
                    <TableCell>
                      <Switch onChange={onActiveChange.bind(null, partnerUser)} isChecked={partnerUser.isActive} />
                    </TableCell>
                    <TableCell>
                      <Switch onChange={onAdminChange.bind(null, partnerUser)} isChecked={partnerUser.isAdmin} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFoot>
                <TablePagination
                  totalCount={totalCount}
                  offset={offset}
                  limit={limit}
                  onPreviousPage={onPreviousPage}
                  onNextPage={onNextPage}
                />
              </TableFoot>
            </Table>
          </PanelBottom>
        </PanelGroup>
      </Container>
      <AddPartnerUserDrawer
        partnerId={partnerId}
        isOpen={showAddPartnerUserDrawer}
        onAdded={onUserAdded}
        onClose={closeAddUserDrawer}
      />
      <AlertDialog isOpen={isActiveOpen} onClose={onActiveClose} leastDestructiveRef={activeCancelRef as any}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{toggleActive?.isActive ? "Deactivate" : "Activate"} User</AlertDialogHeader>
          <AlertDialogBody>
            {toggleActive &&
              `Are you sure you want to ${toggleActive?.isActive ? "deactivate" : "activate"} "${
                toggleActive?.userName
              }"?`}
          </AlertDialogBody>
          <AlertDialogFooter>
            <CancelButton onClick={onActiveClose} ref={activeCancelRef}>
              Cancel
            </CancelButton>
            <PrimaryButton ml={3} onClick={updateToggleActive}>
              {toggleActive?.isActive ? "Deactivate" : "Activate"}
            </PrimaryButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog isOpen={isAdminOpen} onClose={onAdminClose} leastDestructiveRef={adminCancelRef as any}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Modify User</AlertDialogHeader>
          <AlertDialogBody>
            {toggleAdmin
              ? `Are you sure you want to ${
                  toggleAdmin.isAdmin ? "revoke admin access from" : "grant admin access to"
                } "${toggleAdmin.userName}"?`
              : ""}
          </AlertDialogBody>
          <AlertDialogFooter>
            <CancelButton onClick={onAdminClose} ref={adminCancelRef}>
              Cancel
            </CancelButton>
            <PrimaryButton ml={3} onClick={updateToggleAdmin}>
              Confirm
            </PrimaryButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </LayoutBase>
  );
}

import { Badge, Box, Flex, Heading, Tooltip, useToast } from "@chakra-ui/react";
import axios from "axios";
import { DateTime } from "luxon";
import { useContext } from "react";
import { SecondaryButton } from "../../../components/buttons";
import { DataDisplayRow } from "../../../components/data-display-row";
import { Loader } from "../../../components/loader";
import { PanelBottom, PanelBox, PanelGroup, PanelTop } from "../../../components/panels";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../../components/table";
import { DATE_MONTH_FORMAT, DATE_TIME_FORMAT } from "../../../constants/constants";
import { AuthContext } from "../../../core/auth-manager";
import { AppColors } from "../../../core/custom-theme";
import { useOrderDetailsWorkflow } from "./workflow-details-hook";

interface Props {
  orderId: number;
}

export function WorkflowDetails({ orderId }: Props) {
  const authContext = useContext(AuthContext);
  const toast = useToast();
  const { workflow, loading } = useOrderDetailsWorkflow(orderId);

  if (loading) {
    return (
      <PanelBox>
        <Loader type="panel" />
      </PanelBox>
    );
  }

  if (!workflow) {
    return <PanelBox>No workflow found for order.</PanelBox>;
  }

  const executeWorkflow = async () => {
    if (!authContext.isAdmin()) {
      return null;
    }
    await axios.get(`${process.env.REACT_APP_API_BASE}/admin/jobs/execute-workflow/${workflow.id}`, {
      withCredentials: true,
    });
    toast({ title: "Execute Workflow", description: "Execute Workflow job was created.", status: "success" });
  };

  return (
    <Box>
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Flex justifyContent={authContext.isAdmin() ? "space-between" : "flex-start"} alignItems="center">
            <Heading size="md">Workflow Details</Heading>
            {authContext.isAdmin() && (
              <SecondaryButton color={AppColors.blue} variant="outline" onClick={executeWorkflow}>
                Execute Workflow
              </SecondaryButton>
            )}
          </Flex>
        </PanelTop>
        <PanelBottom containsTable>
          <Table isEmpty={false} isLoading={loading}>
            <TableBody>
              <DataDisplayRow label="Workflow ID" value={workflow.id} />
              <DataDisplayRow label="Workflow Type" value={workflow.type} />
              <DataDisplayRow label="Status" value={workflow.status.name} />
              <DataDisplayRow label="Internal Status" value={workflow.internalStatus} />
            </TableBody>
          </Table>
        </PanelBottom>
      </PanelGroup>
      <PanelGroup>
        <PanelTop showBottomBorder>
          <Heading size="md" textAlign="left">
            Associated Tasks
          </Heading>
        </PanelTop>
        <PanelBottom containsTable>
          <Table isEmpty={workflow?.tasks.nodes.length === 0} isLoading={false}>
            <TableHead>
              <TableRow key="head">
                <TableHeaderCell>ID</TableHeaderCell>
                <TableHeaderCell>Task Type</TableHeaderCell>
                <TableHeaderCell>Vendor</TableHeaderCell>
                <TableHeaderCell>Available At</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell>Assigned At</TableHeaderCell>
                <TableHeaderCell>Completed At</TableHeaderCell>
                <TableHeaderCell>Assigned User</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workflow?.tasks.nodes.map((task) => (
                <TableRow key={task.id}>
                  <TableCell>{task.id}</TableCell>
                  <TableCell>
                    {task.taskType.name}
                    {task.taskType.isFulfilledByCustomer && (
                      <Tooltip label="Fulfilled by Client">
                        <Badge colorScheme="blue" ml={2}>
                          Client
                        </Badge>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>{task.vendor ? `${task.vendor.name} (ID: ${task.vendor.id})` : `-`}</TableCell>
                  <TableCell>
                    <Tooltip
                      label={DateTime.fromJSDate(task.availableAt).toFormat(DATE_TIME_FORMAT)}
                      aria-label={DateTime.fromJSDate(task.availableAt).toFormat(DATE_TIME_FORMAT)}
                    >
                      <span>{DateTime.fromJSDate(task.availableAt).toFormat(DATE_MONTH_FORMAT)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{task.status.name}</TableCell>
                  <TableCell>
                    {!!task.assignedAt && (
                      <Tooltip
                        label={DateTime.fromJSDate(task.assignedAt).toFormat(DATE_TIME_FORMAT)}
                        aria-label={DateTime.fromJSDate(task.assignedAt).toFormat(DATE_TIME_FORMAT)}
                      >
                        <span>{DateTime.fromJSDate(task.assignedAt).toFormat(DATE_MONTH_FORMAT)}</span>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    {!!task.completedAt && (
                      <Tooltip
                        label={DateTime.fromJSDate(task.completedAt).toFormat(DATE_TIME_FORMAT)}
                        aria-label={DateTime.fromJSDate(task.completedAt).toFormat(DATE_TIME_FORMAT)}
                      >
                        <span>{DateTime.fromJSDate(task.completedAt).toFormat(DATE_MONTH_FORMAT)}</span>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>{task.assignedUser?.fullName ?? "Unassigned"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PanelBottom>
      </PanelGroup>
    </Box>
  );
}

import { useContext } from "react";
import { BoxProps, Box, Flex } from "@chakra-ui/react";
import { PanelContext } from "./context";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { PrimaryIconButton } from "../buttons/primary-icon-button";
import { getStatusColor } from "./status";

interface Props extends BoxProps {
  showBottomBorder?: boolean;
  isHeader?: boolean;
}

export function PanelTop(props: Props) {
  const { showBottomBorder, isHeader, children, ...rest } = props;
  const context = useContext(PanelContext);
  const borderStyle = "1px solid #E3E8EF";

  const panelColors = getStatusColor(context.status) ?? { backgroundColor: "white", color: "black" };

  return (
    <Box
      {...panelColors}
      borderBottom={showBottomBorder || isHeader ? borderStyle : undefined}
      borderLeft={borderStyle}
      borderTop={borderStyle}
      borderRight={borderStyle}
      borderRadius="4px 4px 0 0"
      padding={4}
      paddingY={isHeader ? 6 : 4}
      overflowX="auto"
      width="100%"
      {...rest}
    >
      {context.isCollapsible && (
        <Flex justifyContent="stretch" alignItems="center">
          <Box flexGrow={1}>{children}</Box>
          <Box marginLeft={2}>
            <PrimaryIconButton
              actionName={context.isCollapsed ? "Expand" : "Collapse"}
              icon={context.isCollapsed ? faAngleDown : faAngleUp}
              onClick={context.toggleCollapse}
            />
          </Box>
        </Flex>
      )}
      {!context.isCollapsible && <>{children}</>}
    </Box>
  );
}

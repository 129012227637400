import { DateTime } from "luxon";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
  TablePagination,
  TableFoot,
} from "../../components/table";
import { SitesQueryResult } from "../../generated/graphql";
import { PAGE_LIMIT, DATE_FORMAT } from "../../constants/constants";

interface Props {
  sitesQueryResult: SitesQueryResult;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

export function AdminSitesTable(props: Props) {
  const { sitesQueryResult, onNextPage, onPreviousPage } = props;
  const totalCount = sitesQueryResult.data?.sites.totalCount ?? 0;
  const sites = sitesQueryResult.data?.sites.nodes ?? [];

  return (
    <Table isEmpty={sites.length === 0} isLoading={sitesQueryResult.loading}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>ID</TableHeaderCell>
          <TableHeaderCell>Category</TableHeaderCell>
          <TableHeaderCell>Deploy Status</TableHeaderCell>
          <TableHeaderCell>Last Deploy Date</TableHeaderCell>
          <TableHeaderCell>Site Template Name</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sites.map((site) => (
          <TableRow key={site.id}>
            <TableCell>{site.id}</TableCell>
            <TableCell>{site.category ? site.category.name : "-"}</TableCell>
            <TableCell>{site.deployStatus.name}</TableCell>
            <TableCell>
              {site.lastPublishedAt ? DateTime.fromJSDate(site.lastPublishedAt).toFormat(DATE_FORMAT) : "-"}
            </TableCell>
            <TableCell>{site.siteTemplate ? site.siteTemplate.name : "-"}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFoot>
        <TablePagination
          totalCount={totalCount}
          offset={sitesQueryResult.variables?.offset ?? 0}
          limit={sitesQueryResult.variables?.limit ?? PAGE_LIMIT}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
      </TableFoot>
    </Table>
  );
}

import { InputProps, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import AppIcon from "../app-icon";
import { faLock } from "@fortawesome/free-solid-svg-icons";

export function LockedInput(props: InputProps) {
  return (
    <InputGroup>
      <Input isDisabled {...props} />
      <InputRightElement>
        <AppIcon icon={faLock} />
      </InputRightElement>
    </InputGroup>
  );
}

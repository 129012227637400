import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrayParam, StringParam, useQueryParam, withDefault } from "use-query-params";

import {
  useAdminPublisherLinkLocationsQuery,
  useUpdatePublisherLinkLocationCategoriesMutation,
} from "../../generated/graphql";
import { PAGE_LIMIT } from "../../constants/constants";
import { usePagination } from "../../util/use-pagination";

export function useAdminPublisherLinkLocations() {
  const { id } = useParams<"id">();
  const publisherId = parseInt(id ?? "");

  const navigate = useNavigate();
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [statusIds, setStatusIds] = useQueryParam("statusIds", withDefault(ArrayParam, []));
  const pagination = usePagination(PAGE_LIMIT);
  const [showAddLinkLocation, setShowAddLinkLocation] = useState(false);

  const queryResult = useAdminPublisherLinkLocationsQuery({
    variables: {
      publisherId,
      search,
      statusIds: statusIds as string[],
      offset: pagination.offset,
      limit: pagination.limit,
    },
    fetchPolicy: "network-only",
  });

  const [updateLinkLocation] = useUpdatePublisherLinkLocationCategoriesMutation();

  let filterCount = 0;
  if (search && search.length > 0) {
    filterCount++;
  }
  if (statusIds.length > 0) {
    filterCount++;
  }

  const totalCount = queryResult.data?.publisherLinkLocations.totalCount ?? 0;
  const publisherLinkLocations = queryResult.data?.publisherLinkLocations.nodes ?? [];

  function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
    pagination.resetOffset();
  }

  function onStatusIdsChange(statusIds: string[]) {
    setStatusIds(statusIds);
    pagination.resetOffset();
  }

  function onNextPage() {
    pagination.onNextPage();
  }

  function onPreviousPage() {
    pagination.onPreviousPage();
  }

  function clearFilters() {
    navigate({ search: "" });
  }

  function onAddLinkLocation() {
    setShowAddLinkLocation(true);
  }

  function onCloseAddLinkLocation() {
    setShowAddLinkLocation(false);
  }

  function onAddedLinkLocation() {
    setShowAddLinkLocation(false);
    queryResult.refetch();
  }

  async function updateLinkLocationCategories(linkLocationId: number, categoryIds: number[]) {
    const { data } = await updateLinkLocation({
      variables: {
        input: {
          linkLocationId,
          categoryIds,
        },
      },
    });

    if (data?.updateLinkLocationCategories.ok && queryResult) {
      queryResult.refetch();
    }
  }

  return {
    publisherId,
    totalCount,
    publisherLinkLocations,
    search,
    onSearchChange,
    statusIds,
    onStatusIdsChange,
    clearFilters,
    hasFilters: filterCount > 0,
    loading: queryResult.loading,
    offset: pagination.offset,
    limit: pagination.limit,
    onNextPage,
    onPreviousPage,
    showAddLinkLocation,
    onAddLinkLocation,
    onCloseAddLinkLocation,
    onAddedLinkLocation,
    updateLinkLocationCategories,
  };
}

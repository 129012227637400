import { Box, FormControl, FormLabel, Heading, Stack, Text, Textarea } from "@chakra-ui/react";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { AdminBadge } from "../../components/admin-badge";
import { AlertWarning } from "../../components/alerts";
import AppIcon from "../../components/app-icon";
import { PrimaryButton } from "../../components/buttons";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelBox, PanelGroup, PanelTop } from "../../components/panels";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { useAdminImportInvoices } from "./admin-import-invoices-hook";

export function AdminImportInvoices() {
  const { handleInputChange, input, loading, results, handleStartImport, errorMessage } = useAdminImportInvoices();
  return (
    <LayoutBase pageTitle="Import Invoices">
      <MainNav />
      <Container centerHorizontally>
        <Stack spacing={2} marginY={4}>
          <PageHeading preventMarginY>Import Invoices</PageHeading>
          <Box>
            <AdminBadge />
          </Box>
        </Stack>
        <PanelBox>
          <Stack spacing={4}>
            <Text>Each line of CSV Content should be in the following format:</Text>
            <Text>
              <code>ORDER_ID,PROCESSOR,PROCESSOR_REFERENCE_ID,AMOUNT</code>
            </Text>
            <FormControl>
              <FormLabel>CSV Content</FormLabel>
              <Textarea value={input} onChange={handleInputChange} rows={10} />
            </FormControl>
            <Box>
              <PrimaryButton onClick={handleStartImport} isLoading={loading}>
                Start Import
              </PrimaryButton>
            </Box>
          </Stack>
        </PanelBox>
        {errorMessage && (
          <AlertWarning marginTop={4}>
            <Text>{errorMessage}</Text>
          </AlertWarning>
        )}
        {(loading || results.length > 0) && (
          <PanelGroup marginTop={4}>
            <PanelTop showBottomBorder>
              <Heading size="md">Results</Heading>
            </PanelTop>
            <PanelBottom containsTable>
              <Table isEmpty={false} isLoading={loading}>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>Order ID</TableHeaderCell>
                    <TableHeaderCell>Success</TableHeaderCell>
                    <TableHeaderCell>Message</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.map((result) => (
                    <TableRow key={result.orderId}>
                      <TableCell>{result.orderId}</TableCell>
                      <TableCell>
                        {result.ok && <AppIcon icon={faCheckCircle} color="green" />}
                        {!result.ok && <AppIcon icon={faTimesCircle} color="red" />}
                      </TableCell>
                      <TableCell>{result.message}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </PanelBottom>
          </PanelGroup>
        )}
      </Container>
    </LayoutBase>
  );
}

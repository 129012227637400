import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Textarea,
  Switch,
} from "@chakra-ui/react";

import { CancelButton, PrimaryButton } from "../../components/buttons";
import { useAddPublisherDrawer } from "./use-add-publisher-drawer";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAdded: (publisherId: number) => void;
}

export function AddPublisherDrawer(props: Props) {
  const { isOpen } = props;

  const { handleSubmit, handleCancel, formik } = useAddPublisherDrawer({
    onAdded: props.onAdded,
    onClose: props.onClose,
  });

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="sm" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add Publisher</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={!!formik.errors.name && !!formik.touched.name}>
              <FormLabel>Name</FormLabel>
              <Input value={formik.values.name} id="name" onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.contactEmail && !!formik.touched.contactEmail}>
              <FormLabel>Contact Email</FormLabel>
              <Input value={formik.values.contactEmail} id="contactEmail" onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.contactEmail}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.firstName && !!formik.touched.firstName}>
              <FormLabel>Contact First Name</FormLabel>
              <Input variant="outline" value={formik.values.firstName} id="firstName" onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.lastName && !!formik.touched.lastName}>
              <FormLabel>Contact Last Name</FormLabel>
              <Input variant="outline" value={formik.values.lastName} id="lastName" onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formik.errors.paymentMethod && !!formik.touched.paymentMethod}>
              <FormLabel>Payment Method</FormLabel>
              <Select value={formik.values.paymentMethod} id="paymentMethod" onChange={formik.handleChange}>
                <option value="" key="none">
                  Select Payment Method
                </option>
                <option value="PayPal" key="PayPal">
                  PayPal
                </option>
                <option value="Payoneer" key="Payoneer">
                  Payoneer
                </option>
              </Select>
              <FormErrorMessage>{formik.errors.paymentMethod}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formik.errors.paymentMethodId && !!formik.touched.paymentMethodId}>
              <FormLabel>Payment Method ID</FormLabel>
              <Input value={formik.values.paymentMethodId} id="paymentMethodId" onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.paymentMethodId}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formik.errors.isSlowPartner && !!formik.touched.isSlowPartner}>
              <FormLabel>Is Slow Partner?</FormLabel>
              <Switch id="isSlowPartner" isChecked={formik.values.isSlowPartner} onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.isSlowPartner}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formik.errors.isAutoPayEnabled && !!formik.touched.isAutoPayEnabled}>
              <FormLabel>Is Auto Pay Enabled?</FormLabel>
              <Switch id="isAutoPayEnabled" isChecked={formik.values.isAutoPayEnabled} onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.isAutoPayEnabled}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formik.errors.isInvoiceRequired && !!formik.touched.isInvoiceRequired}>
              <FormLabel>Is Invoice Required?</FormLabel>
              <Switch
                id="isInvoiceRequired"
                isChecked={formik.values.isInvoiceRequired}
                onChange={formik.handleChange}
              />
              <FormErrorMessage>{formik.errors.isInvoiceRequired}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formik.errors.instructions && !!formik.touched.instructions}>
              <FormLabel>Instructions</FormLabel>
              <Textarea
                id="instructions"
                value={formik.values.instructions}
                onChange={formik.handleChange}
                resize="none"
                placeholder="Instructions"
              />
              <FormErrorMessage>{formik.errors.instructions}</FormErrorMessage>
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <SimpleGrid columns={2} gridGap={4} width="100%">
            <PrimaryButton type="submit" width="100%" isLoading={formik.isSubmitting} onClick={handleSubmit}>
              Save
            </PrimaryButton>
            <CancelButton width="100%" onClick={handleCancel}>
              Cancel
            </CancelButton>
          </SimpleGrid>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

import { Badge } from "@chakra-ui/react";

interface Props {
  isActive: boolean;
}

export function PartnerPackageStatusIndicator(props: Props) {
  const { isActive } = props;

  return <Badge colorScheme={isActive ? "green" : "red"}>{isActive ? "Active" : "Inactive"}</Badge>;
}

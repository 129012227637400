import { useUserSelectorV2Query } from "../../generated/graphql";

interface Options {
  onUserSelected: (userId: number | null) => void;
  showAll?: boolean;
  isAdminOrSuperAdmin?: boolean;
}

export function useUserSelectorV2(options: Options) {
  const { showAll, isAdminOrSuperAdmin, onUserSelected } = options;

  const userSelectorQueryResult = useUserSelectorV2Query({
    variables: {
      isAdminOrSuperAdmin,
    },
    fetchPolicy: "network-only",
  });

  const users = userSelectorQueryResult.data?.users?.nodes ?? [];
  const userOptions = users.map((user) => {
    return {
      value: `${user.id}`,
      displayText: user.fullName,
    };
  });

  if (showAll) {
    userOptions.unshift({
      value: "",
      displayText: "All",
    });
  }

  function onUserChange(value: string | null) {
    if (value) {
      onUserSelected(parseInt(value, 10));
    } else {
      onUserSelected(null);
    }
  }

  return {
    loading: userSelectorQueryResult.loading,
    userOptions,
    onUserChange,
  };
}

import {
  Heading,
  FormLabel,
  Input,
  FormControl,
  Stack,
  FormErrorMessage,
  Flex,
  Box,
  Text,
  Image,
  Select,
} from "@chakra-ui/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { LayoutBase } from "../../components/layout";
import { MainNav } from "../../components/layout";
import { PageHeading } from "../../components/page-heading";
import { Container } from "../../components/layout/container";
import { PanelGroup, PanelBottom, PanelTop } from "../../components/panels";
import { PrimaryButton, CancelButton, SecondaryButton } from "../../components/buttons";
import TimeZoneSelector from "./timezone-selector";
import { UploadFileDialog } from "../../common/upload-file-modal";
import { AppColors } from "../../core/custom-theme";
import { useUserProfile } from "./use-user-profile";
import AppIcon from "../../components/app-icon";
import { SimpleGrid } from "@chakra-ui/layout";
import { EMAIL_PREFERENCE } from "../../constants/user-email-preferences";

export function ProfileRoute() {
  const {
    user,
    profileUser,
    formik,
    showProfilePictureUpload,
    onProfilePictureUploaded,
    onProfilePictureUpload,
    onProfilePictureUploadCancel,
  } = useUserProfile();

  return (
    <>
      <UploadFileDialog
        isOpen={showProfilePictureUpload}
        onFileUploaded={onProfilePictureUploaded}
        onCancel={onProfilePictureUploadCancel}
        instructionText="Profile Picture must be a png. Recommended size: 300px x 100px or smaller."
      />
      <LayoutBase pageTitle="Profile">
        <MainNav />
        <Container centerHorizontally>
          <PageHeading>Profile</PageHeading>
          <SimpleGrid columns={{ base: 1, xl: 2 }} gridGap={4}>
            <PanelGroup>
              <PanelTop showBottomBorder>
                <Heading size="md">Preferences</Heading>
              </PanelTop>
              <PanelBottom>
                <Stack spacing={4}>
                  <FormControl isReadOnly>
                    <FormLabel>First Name</FormLabel>
                    <Input value={user?.firstName} variant="outline" />
                  </FormControl>
                  <FormControl isReadOnly>
                    <FormLabel>Last Name</FormLabel>
                    <Input value={user?.lastName} variant="outline" />
                  </FormControl>
                  <FormControl isReadOnly>
                    <FormLabel>Email</FormLabel>
                    <Input value={user?.email} variant="outline" />
                  </FormControl>
                  <FormControl isInvalid={!!formik.touched.emailPreference && !!formik.errors.emailPreference}>
                    <FormLabel>Email Preference</FormLabel>
                    <Select
                      id="emailPreference"
                      value={formik.values.emailPreference}
                      variant="outline"
                      onChange={formik.handleChange}
                    >
                      {Object.values(EMAIL_PREFERENCE).map((option) => (
                        <option value={option.id} key={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Select>
                    {<FormErrorMessage>{formik.errors.emailPreference}</FormErrorMessage>}
                  </FormControl>
                  <FormControl>
                    <TimeZoneSelector timezone={formik.values.timezone} handleChange={formik.handleChange} />
                  </FormControl>
                  <FormControl isInvalid={!!formik.touched.currentPassword && !!formik.errors.currentPassword}>
                    <FormLabel>Current Password</FormLabel>
                    <Input
                      type="password"
                      value={formik.values.currentPassword}
                      variant="outline"
                      onChange={formik.handleChange}
                      id="currentPassword"
                    />
                    {!!formik.touched.currentPassword && !!formik.errors.currentPassword && (
                      <FormErrorMessage>{formik.errors.currentPassword}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={!!formik.touched.newPassword && !!formik.errors.newPassword}>
                    <FormLabel>New Password</FormLabel>
                    <Input
                      type="password"
                      value={formik.values.newPassword}
                      variant="outline"
                      onChange={formik.handleChange}
                      id="newPassword"
                    />
                    {!!formik.touched.newPassword && !!formik.errors.newPassword && (
                      <FormErrorMessage>{formik.errors.newPassword}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={!!formik.touched.confirmPassword && !!formik.errors.confirmPassword}>
                    <FormLabel>Confirm Password</FormLabel>
                    <Input
                      type="password"
                      value={formik.values.confirmPassword}
                      variant="outline"
                      onChange={formik.handleChange}
                      id="confirmPassword"
                    />
                    {!!formik.touched.confirmPassword && !!formik.errors.confirmPassword && (
                      <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={!!formik.touched.calendarUrl && !!formik.errors.calendarUrl}>
                    <FormLabel>Calendar URL</FormLabel>
                    <Input
                      value={formik.values.calendarUrl ?? ""}
                      variant="outline"
                      onChange={formik.handleChange}
                      id="calendarUrl"
                    />
                    <FormErrorMessage>{formik.errors.calendarUrl}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formik.touched.title && !!formik.errors.title}>
                    <FormLabel>Title</FormLabel>
                    <Input
                      value={formik.values.title ?? ""}
                      variant="outline"
                      onChange={formik.handleChange}
                      id="title"
                    />
                    <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
                  </FormControl>
                  {formik.dirty && (
                    <Stack isInline spacing={2} marginTop={8}>
                      <PrimaryButton onClick={formik.submitForm}>Save</PrimaryButton>
                      <CancelButton onClick={() => formik.resetForm()}>Cancel</CancelButton>
                    </Stack>
                  )}
                </Stack>
              </PanelBottom>
            </PanelGroup>
            <PanelGroup>
              <PanelTop>
                <Flex justifyContent="space-between" alignItems="center">
                  <Box>
                    <Heading size="md">Profile Picture</Heading>
                    <Text fontSize="sm" color={AppColors.textGray}>
                      Recommended Size: 300px x 100px
                    </Text>
                  </Box>
                  <SecondaryButton onClick={onProfilePictureUpload}>Upload</SecondaryButton>
                </Flex>
              </PanelTop>
              <PanelBottom>
                {profileUser?.profilePictureFile && (
                  <Image
                    boxSize="150px"
                    objectFit="cover"
                    src={profileUser.profilePictureFile.url}
                    alt={profileUser.profilePictureFile.name}
                    crossOrigin=""
                  />
                )}
                {!profileUser?.profilePictureFile && (
                  <Text>
                    <AppIcon icon={faInfoCircle} standardRightMargin />
                    Not provided.
                  </Text>
                )}
              </PanelBottom>
            </PanelGroup>
          </SimpleGrid>
        </Container>
      </LayoutBase>
    </>
  );
}

import { useState } from "react";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import {
  useAdminBillingCreateInvoicePartnersQuery,
  useAdminBillingPartnerQuery,
  useCreatePartnerInvoiceMutation,
} from "../../generated/graphql";
import { handleGraphQLResponseError } from "../../util/error-helper";
import { useToast } from "@chakra-ui/react";

export function useAdminBillingCreateInvoice() {
  const navigate = useNavigate();
  const { partnerId: partnerIdRaw } = useParams<"partnerId">();
  const partnerId = parseInt(partnerIdRaw ?? "", 10);
  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [selectedRange, setSelectedRange] = useQueryParam("range", withDefault(StringParam, "daily"));
  const toast = useToast();

  let startDate = DateTime.utc().plus({ days: 1 }).endOf("day").toJSDate();
  if (selectedRange === "week") {
    startDate = DateTime.utc().plus({ weeks: 1 }).endOf("day").toJSDate();
  } else if (selectedRange === "month") {
    startDate = DateTime.utc().plus({ months: 1 }).endOf("day").toJSDate();
  }
  const queryResult = useAdminBillingPartnerQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerId,
      startDate: {
        lte: startDate,
      },
    },
  });

  const partnersQueryResult = useAdminBillingCreateInvoicePartnersQuery({
    fetchPolicy: "network-only",
  });
  const [createPartnerInvoiceMutation, { loading: isCreatingInvoice }] = useCreatePartnerInvoiceMutation();

  const partners = partnersQueryResult.data?.partners.nodes ?? [];

  const partner = queryResult.data?.partner ?? null;
  const billableOrders = partner?.billableOrders.nodes ?? [];

  const selectedOrders = billableOrders.filter((x) => selectedOrderIds.includes(x.id));

  const selectedOrdersWholesaleTotal = selectedOrders.reduce((total, order) => total + order.totalWholesaleAmount, 0);

  function toggleOrder(orderId: number) {
    const order = billableOrders.find((x) => x.id === orderId);
    setIsConfirmed(false);
    if (!order) {
      return;
    }
    if (selectedOrderIds.includes(orderId)) {
      setSelectedOrderIds(selectedOrderIds.filter((x) => x !== orderId));
    } else {
      setSelectedOrderIds([...selectedOrderIds, orderId]);
    }
  }

  function handleCheckAll() {
    if (selectedOrderIds.length === 0) {
      setSelectedOrderIds(billableOrders.map((x) => x.id));
    } else {
      setSelectedOrderIds([]);
    }
  }

  function handleRangeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedRange(e.target.value);
  }

  function handlePartnerChange(e: React.ChangeEvent<HTMLSelectElement>) {
    navigate(`/admin/billing/${e.target.value}?range=${selectedRange}`);
  }

  async function handleCreateInvoice() {
    if (selectedOrderIds.length === 0) {
      toast({
        title: "Unable to Create Invoice",
        description: "At least one order needs to be selected to create an invoice.",
        status: "error",
      });
      return;
    }
    try {
      const response = await createPartnerInvoiceMutation({
        variables: {
          input: {
            partnerId,
            orderIds: selectedOrderIds,
          },
        },
      });
      if (!response.data?.createPartnerInvoice.ok) {
        throw new Error(
          response.data?.createPartnerInvoice.error?.message ??
            "An error occurred while creating the invoice. Please try again."
        );
      }
      toast({
        title: "Invoice Created",
        description: "The Invoice was created successfully.",
        status: "success",
      });
      setTimeout(() => {
        queryResult.refetch();
      }, 0);
    } catch (e: any) {
      handleGraphQLResponseError(e, toast);
    }
  }

  function handleConfirmedChange(e: React.ChangeEvent<HTMLInputElement>) {
    setIsConfirmed(e.target.checked);
  }

  return {
    partner,
    partners,
    billableOrders,
    partnerId,
    selectedOrderIds,
    toggleOrder,
    handleCheckAll,
    selectedOrdersWholesaleTotal,
    selectedRange,
    handleRangeChange,
    handlePartnerChange,
    handleCreateInvoice,
    isCreatingInvoice,
    isConfirmed,
    handleConfirmedChange,
  };
}

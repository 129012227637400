import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  SimpleGrid,
  FormErrorMessage,
  Select,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  FormHelperText,
} from "@chakra-ui/react";
import { PrimaryButton, CancelButton } from "../../components/buttons";
import { useAddPackageItemDrawer } from "./use-add-package-item-drawer";
import AppIcon from "../../components/app-icon";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { currencyFormatter } from "../../util/formatter";

interface Props {
  partnerId: number;
  monthIndex: number;
  partnerPackageId: number;
  isOpen: boolean;
  onClose: () => void;
  onAdded: () => void;
}

export function AddPackageItemDrawer(props: Props) {
  const { isOpen, ...options } = props;
  const { formik, handleCancel, handleSave, partnerProducts } = useAddPackageItemDrawer(options);

  const partnerProduct = partnerProducts.find((x) => x.id === formik.values.partnerProductId);
  let total: number | null = null;
  if (partnerProduct) {
    total = (partnerProduct.currentPartnerProductPrice?.price ?? 0) * formik.values.quantity;
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="sm" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add Package Item</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Month</FormLabel>
              <InputGroup>
                <Input variant="outline" value={`Month ${props.monthIndex + 1}`} isReadOnly />
                <InputRightElement>
                  <AppIcon icon={faLock} />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.partnerProductId && !!formik.touched.partnerProductId}>
              <FormLabel>Product Type</FormLabel>
              <Select
                variant="outline"
                value={formik.values.partnerProductId}
                onChange={(e) => {
                  formik.setFieldValue("partnerProductId", parseInt(e.target.value));
                }}
                id="partnerProductId"
                placeholder="Select a product type"
              >
                {partnerProducts.map((partnerProduct) => (
                  <option key={partnerProduct.id} value={partnerProduct.id}>
                    {partnerProduct.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{formik.errors.partnerProductId}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.quantity && !!formik.touched.quantity}>
              <FormLabel>Quantity</FormLabel>
              <Input
                id="quantity"
                variant="outline"
                value={formik.values.quantity}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue("quantity", parseInt(event.target.value));
                }}
              />
              <FormErrorMessage>{formik.errors.quantity}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Is Recurring</FormLabel>
              <Switch id="isRecurring" onChange={formik.handleChange} isChecked={formik.values.isRecurring} />
              <FormHelperText>
                If isRecurring is true then order item will be created for every month at time of campaign creation
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>Total</FormLabel>
              <InputGroup>
                <Input variant="outline" value={currencyFormatter.format(total ?? 0)} isReadOnly />
                <InputRightElement>
                  <AppIcon icon={faLock} />
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <SimpleGrid columns={2} gridGap={4} width="100%">
            <PrimaryButton width="100%" onClick={handleSave.bind(null, false)}>
              Save
            </PrimaryButton>
            <CancelButton onClick={handleCancel}>Cancel</CancelButton>
          </SimpleGrid>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

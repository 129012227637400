import { Box, BoxProps } from "@chakra-ui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import AppIcon from "../../components/app-icon";

export function AlertWarning(props: BoxProps) {
  const { children, ...rest } = props;
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      p={4}
      backgroundColor="#FEEBC8"
      borderLeft="5px solid #DD6B20"
      my={2}
      {...rest}
    >
      <AppIcon icon={faExclamationTriangle} color="#DD6B20" wideRightMargin />
      {children}
    </Box>
  );
}

import { useRef, useState } from "react";
import { DateTime } from "luxon";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Switch,
  useToast,
} from "@chakra-ui/react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
  TablePagination,
  TableFoot,
} from "../../components/table";
import { ClientUsersQueryResult, useUpdateClientUserMutation } from "../../generated/graphql";
import { DATE_MONTH_FORMAT, PAGE_LIMIT } from "../../constants/constants";
import { CancelButton, PrimaryButton, PrimaryIconButton } from "../../components/buttons";
import { SSOManagementModal } from "../../common/sso-management-modal";
import { getDisplayMessageForError } from "../../util/error-helper";

interface ToggleActive {
  id: number;
  isActive: boolean;
  userName: string;
}

interface Props {
  clientUsersQueryResult: ClientUsersQueryResult;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

export function UsersTable(props: Props) {
  const { clientUsersQueryResult, onNextPage, onPreviousPage } = props;
  const [toggleActive, setToggleActive] = useState<ToggleActive | null>(null);
  const [isActiveOpen, setIsActiveOpen] = useState(false);
  const [showClientUser, setClientUser] = useState<number | null>(null);
  const totalCount = clientUsersQueryResult.data?.customer?.customerUsers.totalCount ?? 0;
  const customerUsers = clientUsersQueryResult.data?.customer?.customerUsers.nodes ?? [];
  const [updateClientUser] = useUpdateClientUserMutation();
  const toast = useToast();

  const activeCancelRef = useRef<HTMLButtonElement>(null);

  function onActiveClose() {
    setIsActiveOpen(false);
    setToggleActive(null);
  }

  async function onActiveChange(customerUser: any) {
    setToggleActive({
      id: customerUser.id,
      isActive: customerUser.isActive,
      userName: customerUser.user.fullName,
    });
    setIsActiveOpen(true);
  }

  async function updateToggleActive() {
    if (!toggleActive) {
      return;
    }
    try {
      const response = await updateClientUser({
        variables: {
          input: {
            customerUserId: toggleActive.id,
            isActive: !toggleActive.isActive,
          },
        },
      });

      if (response.data?.updateCustomerUser.customerUser.id) {
        toast({
          title: "Success",
          description: `"${toggleActive.userName}" has been ${
            toggleActive.isActive ? "deactivated" : "activated"
          } successfully.`,
          status: "success",
        });
        clientUsersQueryResult.refetch();
      }
    } catch (error: any) {
      toast({ title: "Success", description: getDisplayMessageForError(error), status: "error" });
    }
    setToggleActive(null);
    setIsActiveOpen(false);
  }

  function openSSOModal(clientUserId: number) {
    setClientUser(clientUserId);
  }

  function closeSSOModal() {
    setClientUser(null);
  }

  return (
    <>
      <SSOManagementModal customerUserId={showClientUser ?? 0} isOpen={!!showClientUser} onClose={closeSSOModal} />
      <Table isEmpty={customerUsers.length === 0} isLoading={clientUsersQueryResult.loading}>
        <TableHead>
          <TableRow key="head">
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Email</TableHeaderCell>
            <TableHeaderCell>Creation Date</TableHeaderCell>
            <TableHeaderCell>Active</TableHeaderCell>
            <TableHeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {customerUsers.map((customerUser) => (
            <TableRow key={customerUser.id}>
              <TableCell>{customerUser.user.fullName}</TableCell>
              <TableCell>{customerUser.user.email}</TableCell>
              <TableCell>{DateTime.fromJSDate(customerUser.createdAt).toFormat(DATE_MONTH_FORMAT)}</TableCell>
              <TableCell>
                <Switch onChange={onActiveChange.bind(null, customerUser)} isChecked={customerUser.isActive} />
              </TableCell>
              <TableCell>
                <PrimaryIconButton
                  icon={faKey}
                  actionName="Single Sign On Management"
                  onClick={openSSOModal.bind(null, customerUser.id)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFoot>
          <TablePagination
            totalCount={totalCount}
            offset={clientUsersQueryResult.variables?.offset ?? 0}
            limit={clientUsersQueryResult.variables?.limit ?? PAGE_LIMIT}
            onPreviousPage={onPreviousPage}
            onNextPage={onNextPage}
          />
        </TableFoot>
      </Table>
      <AlertDialog isOpen={isActiveOpen} onClose={onActiveClose} leastDestructiveRef={activeCancelRef as any}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{toggleActive?.isActive ? "Deactivate" : "Activate"} User</AlertDialogHeader>
          <AlertDialogBody>
            {toggleActive &&
              `Are you sure you want to ${toggleActive?.isActive ? "deactivate" : "activate"} "${
                toggleActive?.userName
              }"?`}
          </AlertDialogBody>
          <AlertDialogFooter>
            <CancelButton onClick={onActiveClose} ref={activeCancelRef}>
              Cancel
            </CancelButton>
            <PrimaryButton ml={3} onClick={updateToggleActive}>
              {toggleActive?.isActive ? "Deactivate" : "Activate"}
            </PrimaryButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

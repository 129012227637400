import { useUserAutoComplete } from "./use-user-auto-complete";
import { AutoCompleteInput } from "../../components/auto-complete-input/auto-complete-input";

interface Props {
  selectedUserId: number | null;
  onUserChanged: (userId: number | null) => void;
  isAdminOrSuperAdmin?: boolean;
  isClearable?: boolean;
}

export function UserAutoComplete(props: Props) {
  const { selectedUserId, onUserChanged, isAdminOrSuperAdmin, isClearable, ...rest } = props;

  const { options, onSearchChange } = useUserAutoComplete({
    isAdminOrSuperAdmin,
  });

  return (
    <AutoCompleteInput
      items={options ?? []}
      onSelectedValueChanged={(value) => {
        onUserChanged(value ? parseInt(value) : null);
      }}
      onInputChanged={onSearchChange}
      selectedValue={selectedUserId ? `${selectedUserId}` : undefined}
      placeholder={"Select User"}
      isClearable={isClearable}
      suggestionBoxMaxHeight={100}
      {...rest}
    />
  );
}

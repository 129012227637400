import { Box, FormControl, FormLabel, Heading, Stack, Switch, Text } from "@chakra-ui/react";

import { PartnerPaymentMethodSelector } from "../../common/partner-payment-method-selector";
import { PanelBox } from "../../components/panels";
import { AppColors } from "../../core/custom-theme";
import { AutomaticPaymentsConfirmModal } from "./automatic-payments-confirm-modal";
import { useAutomaticPayments } from "./automatic-payments-hook";

interface Props {
  partnerId: number;
}

export function AutomaticPayments(props: Props) {
  const {
    partnerId,
    partner,
    handleEnabledClick,
    handlePartnerPaymentMethodSelected,
    selectedPartnerPaymentMethodId,
    showPaymentMethod,
    handleEnableCancel,
    handleEnableConfirm,
    showConfirm,
    handleTermsAcceptedChange,
    isTermsAccepted,
    isProcessing,
  } = useAutomaticPayments(props.partnerId);
  return (
    <PanelBox>
      <Stack isInline spacing={4} alignItems="center">
        <FormControl maxW="100px">
          <Switch size="lg" isChecked={partner?.isAutopayEnabled ?? false} onChange={handleEnabledClick} />
        </FormControl>
        <Stack spacing={2} width="50%">
          <Heading size="sm">{partner?.isAutopayEnabled ? "Enabled" : "Disabled"}</Heading>
          {partner?.isAutopayEnabled ? (
            <Text color={AppColors.textGray}>
              Automatic billing has been enabled. Your payment details will be safe and secure, and you can stop the
              recurring payment at any time.
            </Text>
          ) : (
            <Text color={AppColors.textGray}>
              Automatic billing is not enabled. In order for work to begin, you must make one-time payments.
            </Text>
          )}
        </Stack>
        {showPaymentMethod && (
          <Box width="35%">
            <FormControl>
              <FormLabel>Payment Method</FormLabel>
              <PartnerPaymentMethodSelector
                isDisabled
                onPartnerPaymentSelected={handlePartnerPaymentMethodSelected}
                partnerId={props.partnerId}
                selectedPartnerPaymentMethodId={partner?.autopayPartnerPaymentMethodId ?? null}
              />
            </FormControl>
          </Box>
        )}
      </Stack>
      <AutomaticPaymentsConfirmModal
        isOpen={showConfirm}
        isProcessing={isProcessing}
        onClose={handleEnableCancel}
        onConfirm={handleEnableConfirm}
        onSelectedPartnerPaymentMethodChanged={handlePartnerPaymentMethodSelected}
        partnerId={partnerId}
        selectedPartnerPaymentMethodId={selectedPartnerPaymentMethodId}
        isTermsAccepted={isTermsAccepted}
        onTermsAcceptedChange={handleTermsAcceptedChange}
        dueImmediatelyAmount={partner?.enableAutopayInformation?.dueImmediatelyAmount ?? 0}
        nextBillingAmount={partner?.enableAutopayInformation?.nextBillingAmount ?? null}
        nextBillingDate={partner?.enableAutopayInformation?.nextBillingDate ?? null}
      />
    </PanelBox>
  );
}

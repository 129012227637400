export const LINK_LOCATION_TYPES = {
  PBN: {
    id: "pbn",
    name: "PBN",
  },
  OUTREACH: {
    id: "outreach",
    name: "Outreach",
  },
  LOCAL_BUSINESS: {
    id: "local-business",
    name: "Local Business",
  },
};

export const ORDER_BILLING_STATUS = {
  UNPAID: {
    id: "unpaid",
    name: "Unpaid",
    description: "Payment has not yet been processed for this order.",
  },
  PENDING: {
    id: "pending",
    name: "Pending",
    description: "This order has been associated to an invoice, but the invoice has not yet been paid.",
  },
  PAID: {
    id: "paid",
    name: "Paid",
    description: "Payment has been processed for this order.",
  },
  REFUNDED: {
    id: "refunded",
    name: "Refunded",
    description: "Payment was processed for this order but was later refunded.",
  },
};

import { ConversationDetails } from "../../common/conversation-details";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";

export function ConversationDetailsRoute() {
  return (
    <LayoutBase pageTitle="Message Details">
      <MainNav hasSecondaryNav />
      <Container centerHorizontally>
        <ConversationDetails />
      </Container>
    </LayoutBase>
  );
}

import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { PRODUCT_CATEGORY } from "../../constants/products";
import { CustomMultiSelect } from "../custom-selector";

interface Props {
  selectedProductCategoryIds: string[];
  onProductCategorySelected: (productCategoryIds: string[]) => void;
  emptyValueLabel?: string;
}

export function ProductCategorySelector(props: Props) {
  const { selectedProductCategoryIds, onProductCategorySelected, emptyValueLabel } = props;

  const items = Object.entries(PRODUCT_CATEGORY).map((data) => ({
    value: data[0],
    displayText: data[1].name,
  }));

  return (
    <CustomMultiSelect
      onChange={onProductCategorySelected}
      value={selectedProductCategoryIds}
      items={items}
      selectorIcon={faBoxOpen}
      emptyValueTitle={emptyValueLabel}
    />
  );
}

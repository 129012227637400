import { DateTime } from "luxon";
import { PanelGroup, PanelTop, PanelBottom, PanelBox } from "../../components/panels";
import {
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Box,
  Flex,
  Textarea,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { DangerButton, DangerIconButton, PrimaryButton } from "../../components/buttons";
import { PageHeading } from "../../components/page-heading";
import { WorkflowNotes } from "../workflow-notes";
import { HelpText } from "../../components/help-text/help-text";
import { useTaskWorkspaceSiteAudit } from "./task-workspace-site-audit-hook";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { FileDropUpload } from "../../components/file-drop-upload";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { AppColors } from "../../core/custom-theme";
import { ContentEditor } from "../../components/content-editor";

interface Props {
  taskId: number;
  onAbandon: () => void;
  onComplete: () => void;
}

export function TaskWorkspaceSiteAudit(props: Props) {
  const { taskId, onAbandon } = props;
  const {
    task,
    customer,
    handleComplete,
    onFileSelected,
    removeFile,
    auditFile,
    summary,
    onChangeSummary,
    isProcessing,
  } = useTaskWorkspaceSiteAudit(props);
  return (
    <>
      <PageHeading>{task?.order.partnerProduct.product.name ?? "Site Audit"}</PageHeading>
      <Grid templateColumns={{ base: "1fr", xl: "1fr 1.5fr" }} gridGap={4}>
        <Stack spacing={4} gridColumn={1}>
          <PanelGroup>
            <PanelTop showBottomBorder>
              <Heading size="md">Client Details</Heading>
            </PanelTop>
            <PanelBottom>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Client Name</FormLabel>
                  <Input variant="outline" value={customer?.name ?? ""} isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Start Date</FormLabel>
                  <Input
                    variant="outline"
                    value={
                      task?.order.startDate ? DateTime.fromJSDate(task.order.startDate).toFormat(DATE_MONTH_FORMAT) : ""
                    }
                    isReadOnly
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Website URL</FormLabel>
                  <Input variant="outline" value={customer?.website ?? ""} isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Business Category</FormLabel>
                  <Input variant="outline" value={customer?.category?.name ?? ""} isReadOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>Business Type</FormLabel>
                  <Input
                    variant="outline"
                    value={customer?.customerQuestionnaire?.customerMarketSize ?? ""}
                    isReadOnly
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Brand Manager Instructions</FormLabel>
                  <Textarea value={task?.order.instructions ?? "Not Provided"} variant="outline" isDisabled />
                </FormControl>
                <FormControl>
                  <FormLabel>Client Instructions</FormLabel>
                  <Textarea value={task?.customer?.instructions ?? "Not Provided"} variant="outline" isDisabled />
                </FormControl>
              </Stack>
            </PanelBottom>
          </PanelGroup>
          <WorkflowNotes taskId={taskId} />
        </Stack>
        <Stack spacing={4} gridColumn={{ base: 1, xl: 2 }}>
          <PanelBox>
            <Box>
              <FileDropUpload
                acceptedFileExtensions={{
                  "application/pdf": [],
                  "application/vnd.ms-excel": [],
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
                }}
                onFileUpload={onFileSelected}
              />
              <Box textAlign="center" marginTop={1} marginBottom={2}>
                <Text fontSize="sm" color={AppColors.textGray}>
                  .xls, .xlsx, and .pdf files accepted
                </Text>
              </Box>
              {auditFile && (
                <InputGroup>
                  <Input variant="outline" value={auditFile.name} />
                  <InputRightElement>
                    <DangerIconButton actionName="Remove" icon={faTimes} onClick={removeFile} />
                  </InputRightElement>
                </InputGroup>
              )}
            </Box>
            <Box>
              <FormControl>
                <FormLabel>Summary</FormLabel>
                <ContentEditor onChange={onChangeSummary} />
              </FormControl>
            </Box>
            {auditFile && (
              <InputGroup>
                <Input variant="outline" value={auditFile.name} />
                <InputRightElement>
                  <DangerIconButton actionName="Remove" icon={faTimes} onClick={removeFile} />
                </InputRightElement>
              </InputGroup>
            )}
          </PanelBox>
          <Flex justifyContent="space-between">
            <Box>
              <DangerButton isDisabled={isProcessing} onClick={onAbandon}>
                Unassign Task
              </DangerButton>
              <HelpText standardLeftMargin>
                &quot;Unassign Task&quot; will unassign this task from you and return it to the queue.{" "}
                <strong>This cannot be undone and may result in lost work.</strong>
              </HelpText>
            </Box>
            <PrimaryButton
              isDisabled={!(auditFile || summary)}
              onClick={handleComplete}
              isLoading={isProcessing}
              loadingText="Completing"
            >
              Complete
            </PrimaryButton>
          </Flex>
        </Stack>
      </Grid>
    </>
  );
}

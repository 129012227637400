import * as yup from "yup";
import { useMemo } from "react";
import { FormikHelpers, useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import { DateTime } from "luxon";

import { getDisplayMessageForError } from "../../util/error-helper";
import {
  useCreateClientStatusChangeMutation,
  useUpdateClientStatusChangeMutation,
  useDeleteClientStatusChangeMutation,
  useClientStatusQuery,
} from "../../generated/graphql";
import { DATE_FORMAT, DATE_MONTH_FORMAT } from "../../constants/constants";

interface Options {
  onEdited: () => void;
  customerId: number;
}

interface FormValues {
  status: string;
  effectiveAt: Date | null;
  reason: string;
}

const updateCustomerStatusSchema = yup.object().shape({
  status: yup.string().label("Status").required(),
  effectiveAt: yup
    .date()
    .label("Effective At")
    .min(DateTime.utc().plus({ days: 1 }).startOf("day").toJSDate().toISOString()),
  reason: yup.string().label("Reason").required(),
});

export function useClientStatus(options: Options) {
  const { customerId, onEdited } = options;

  const [createClientStatusChange] = useCreateClientStatusChangeMutation();
  const [updateClientStatusChange] = useUpdateClientStatusChangeMutation();
  const [deleteClientStatusChange, { loading: deleteClientStatusLoading }] = useDeleteClientStatusChangeMutation();
  const { data, loading, refetch } = useClientStatusQuery({
    variables: {
      id: customerId,
    },
    fetchPolicy: "network-only",
  });
  const toast = useToast();

  const customer = data?.customer ?? null;
  const currentStatus = customer?.status;
  const scheduledStatusChange = useMemo(() => data?.customer?.customerStatusChanges.nodes?.[0], [data]);

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      if (scheduledStatusChange) {
        const response = await updateClientStatusChange({
          variables: {
            input: {
              customerStatusChangeId: scheduledStatusChange.id,
              effectiveAt: DateTime.fromJSDate(values?.effectiveAt ?? new Date()).toFormat(DATE_FORMAT),
              reason: values.reason,
            },
          },
        });
        if (response.data?.updateCustomerStatusChange.customerStatusChange.id) {
          toast({ title: "Success", description: "Status change updated successfully.", status: "success" });
          onEdited();
        } else {
          toast({ title: "Error", description: "Something went wrong", status: "error" });
        }
      } else {
        const response = await createClientStatusChange({
          variables: {
            input: {
              customerId,
              effectiveAt: values.effectiveAt
                ? DateTime.fromJSDate(values.effectiveAt).toUTC().toFormat(DATE_FORMAT)
                : DateTime.utc().plus({ days: 1 }).toFormat(DATE_FORMAT),
              reason: values.reason,
              statusId: values.status,
            },
          },
        });
        if (response.data?.createCustomerStatusChange.customerStatusChange.id) {
          toast({ title: "Success", description: "Status change was scheduled successfully.", status: "success" });
          onEdited();
          refetch();
        } else {
          toast({ title: "Error", description: "Something went wrong", status: "error" });
        }
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      status: scheduledStatusChange?.status?.id ?? "",
      effectiveAt: scheduledStatusChange?.effectiveAt
        ? new Date(scheduledStatusChange.effectiveAt)
        : DateTime.utc().plus({ days: 1 }).toJSDate(),
      reason: scheduledStatusChange?.reason ?? "",
    },
    validationSchema: updateCustomerStatusSchema,
    onSubmit: handleSubmit,
  });

  function handleDateChange(date: Date) {
    formik.setFieldValue("effectiveAt", date ?? new Date());
  }

  async function handleRemoveStatus() {
    const response = await deleteClientStatusChange({
      variables: {
        input: {
          customerStatusChangeId: scheduledStatusChange?.id ?? 0,
        },
      },
    });

    if (response.data?.deleteCustomerStatusChange.isDeleted) {
      toast({ title: "Success", description: "Status change was successfully removed.", status: "success" });
      onEdited();
      await refetch();
      formik.resetForm();
    } else {
      toast({ title: "Error", description: "Something went wrong", status: "error" });
    }
  }

  function getScheduleChangeDate() {
    if (scheduledStatusChange) {
      const effectiveAt = new Date(scheduledStatusChange?.effectiveAt ?? new Date());
      return `(${DateTime.fromJSDate(effectiveAt).toUTC().toFormat(DATE_MONTH_FORMAT)}, Midnight UTC)`;
    }
    return "";
  }

  return {
    loading,
    deleteClientStatusLoading,
    currentStatus,
    scheduledStatusChange,
    customer,
    formik,
    handleDateChange,
    handleRemoveStatus,
    getScheduleChangeDate,
  };
}

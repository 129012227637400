import { forwardRef } from "react";
import { ButtonProps, Button } from "@chakra-ui/react";

export const CancelButton = forwardRef<HTMLButtonElement, ButtonProps>(function CancelButton(props, ref) {
  return (
    <Button
      variant="ghost"
      color="black"
      _hover={{ bg: "#F5F6F8" }}
      fontSize="13px"
      paddingX="20px"
      paddingY="10px"
      ref={ref}
      {...props}
    />
  );
});

import { ButtonProps, Button } from "@chakra-ui/react";
import Color from "color";
import { AppColors } from "../../core/custom-theme";

export function DangerButton(props: ButtonProps) {
  return (
    <Button
      variant="solid"
      bgColor={AppColors.error}
      borderColor={AppColors.error}
      color={AppColors.white}
      _hover={{
        bg: Color(AppColors.error).darken(0.2).hex(),
        color: "white",
      }}
      _active={{
        bg: Color(AppColors.error).darken(0.3).hex(),
      }}
      fontSize="14px"
      paddingX="30px"
      paddingY="10px"
      {...props}
    />
  );
}

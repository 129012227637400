import { SimpleGrid } from "@chakra-ui/react";

import { AddPartnerBillingContactModal } from "../../common/add-partner-billing-contact-modal";
import { PrimaryButtonLink } from "../../components/buttons";
import { PanelBox } from "../../components/panels";
import { BillingContactItem } from "./billing-contact-item";
import { useBillingContacts } from "./billing-contacts-hook";

interface Props {
  partnerId: number;
}

export function BillingContacts(props: Props) {
  const { partnerId } = props;
  const { partnerBillingContacts, isAdding, handleAdd, handleAddCancel, handleAdded, handleEdit, handleRemove } =
    useBillingContacts(partnerId);
  return (
    <>
      <AddPartnerBillingContactModal
        isOpen={isAdding}
        onAdded={handleAdded}
        onClose={handleAddCancel}
        partnerId={partnerId}
      />
      <SimpleGrid columns={2} spacing={4}>
        {partnerBillingContacts.map((partnerBillingContact) => (
          <BillingContactItem
            key={partnerBillingContact.id}
            partnerBillingContact={partnerBillingContact}
            onEdit={() => {
              handleEdit(partnerBillingContact);
            }}
            onRemove={() => {
              handleRemove(partnerBillingContact.id);
            }}
          />
        ))}
        <PanelBox height="120px" display="flex" justifyContent="center" alignItems="center">
          <PrimaryButtonLink onClick={handleAdd}>Add a billing contact</PrimaryButtonLink>
        </PanelBox>
      </SimpleGrid>
    </>
  );
}

import { Text, Stack, Box } from "@chakra-ui/react";
import { PrimaryIconButton } from "../../components/buttons";
import { faTrashAlt, faPlus, faCheckCircle, faAsterisk } from "@fortawesome/free-solid-svg-icons";
import AppIcon from "../../components/app-icon";
import { AppColors } from "../../core/custom-theme";

interface Props {
  product: {
    id: string;
    name: string;
  };
  taskType: {
    id: string;
    name: string;
  };
  isGranted: boolean;
  isGrantedByWildcard: boolean;
  onCreate: () => void;
  onDelete: () => void;
}

export function PermissionItem(props: Props) {
  const { isGranted, isGrantedByWildcard, product, taskType, onCreate, onDelete } = props;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      _hover={{ backgroundColor: "#dfdfdf" }}
      paddingY={1}
      paddingX={2}
    >
      <Text fontSize="sm">
        {product.name}/{taskType.name}
      </Text>
      {isGranted && !isGrantedByWildcard && (
        <Stack spacing={1} isInline alignItems="center">
          <AppIcon icon={faCheckCircle} color={AppColors.success} />
          <PrimaryIconButton icon={faTrashAlt} onClick={onDelete} actionName="Remove" />
        </Stack>
      )}
      {isGrantedByWildcard && (
        <Stack spacing={1} isInline alignItems="center">
          <AppIcon icon={faCheckCircle} color={AppColors.success} />
          <PrimaryIconButton
            isDisabled
            color={AppColors.success}
            icon={faAsterisk}
            actionName="Permission Granted by Wildcard"
          />
        </Stack>
      )}
      {!isGranted && <PrimaryIconButton icon={faPlus} onClick={onCreate} actionName="Add Permission" />}
    </Box>
  );
}

import { DateTime } from "luxon";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  Box,
} from "@chakra-ui/react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import DatePicker from "react-datepicker";
import { PrimaryButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";
import { ClientDetailsClientCampaignFragment, useUpdateClientCampaignMutation } from "../../generated/graphql";
import { DATE_PICKER_FORMAT } from "../../constants/constants";
import { CampaignCategorySelector } from "../campaign-category-selector";

interface Props {
  campaign: ClientDetailsClientCampaignFragment;
  isOpen: boolean;
  onClose: () => void;
  onEdited: (clientCampaignId: number) => void;
}

interface FormValues {
  name: string;
  startsAt: Date;
  month?: number;
  amount?: number;
}

const editClientCampaignSchema = yup.object().shape({
  name: yup.string().label("Name").required(),
  startsAt: yup.date().label("Starts At").required(),
  month: yup.number().integer().label("Month"),
  amount: yup.number().label("Amount").required(),
});

export function EditClientCampaignDrawer(props: Props) {
  const { onClose, onEdited, isOpen, campaign } = props;
  const [updateClientCampaign] = useUpdateClientCampaignMutation();
  const toast = useToast();

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const endDate = DateTime.fromJSDate(values.startsAt)
        .plus({ months: values.month ?? 0 })
        .startOf("day")
        .minus({ milliseconds: 1 })
        .toJSDate();
      const response = await updateClientCampaign({
        variables: {
          input: {
            customerCampaignId: campaign.id,
            name: values.name,
            startsAt: campaign.isCurrent ? undefined : values.startsAt,
            endsAt: endDate,
            amount: values.amount,
          },
        },
      });

      if (response.data?.updateCustomerCampaign.customerCampaign.id) {
        toast({ title: "Success", description: "Client campaign updated successfully!", status: "success" });
        formikHelpers.resetForm();
        onEdited(response.data?.updateCustomerCampaign.customerCampaign.id);
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      name: campaign.name,
      startsAt: campaign.startsAt,
      month: Math.round(
        DateTime.fromJSDate(campaign.endsAt).diff(DateTime.fromJSDate(campaign.startsAt), "months").months
      ),
      amount: campaign.amount,
    },
    validationSchema: editClientCampaignSchema,
    onSubmit: handleSubmit,
  });

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  function onStartDateChange(date: Date) {
    formik.setFieldValue("startsAt", date ?? new Date());
  }

  const isCampaignActive = campaign.startsAt < new Date();

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="md" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit Campaign</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={!!formik.errors.name && !!formik.touched.name}>
              <FormLabel>Campaign Name</FormLabel>
              <Input autoFocus variant="outline" id="name" value={formik.values.name} onChange={formik.handleChange} />
              {!!formik.errors.name && !!formik.touched.name && (
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Campaign Category</FormLabel>
              <CampaignCategorySelector
                variant="outline"
                selectedCampaignCategoryId={campaign.campaignCategory.id}
                isDisabled
              />
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.startsAt && !!formik.touched.startsAt} zIndex={10}>
              <FormLabel>Campaign Start</FormLabel>
              <Box>
                <DatePicker
                  onChange={onStartDateChange}
                  selected={formik.values.startsAt}
                  minDate={
                    isCampaignActive
                      ? new Date()
                      : DateTime.utc().minus({ months: 1 }).plus({ days: 1 }).startOf("day").toJSDate()
                  }
                  maxDate={isCampaignActive ? new Date() : DateTime.now().plus({ months: 6 }).toJSDate()}
                  dateFormat={DATE_PICKER_FORMAT}
                  customInput={<Input variant="outline" width="100%" />}
                  placeholderText="Select start date"
                  disabledKeyboardNavigation
                  disabled={isCampaignActive}
                />
              </Box>
              {!!formik.errors.startsAt && formik.touched.startsAt && (
                <FormErrorMessage>{formik.errors.startsAt as string}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={!!formik.errors.month && !!formik.touched.month}>
              <FormLabel>Campaign Length (in months)</FormLabel>
              <Input
                variant="outline"
                type="number"
                id="month"
                value={formik.values.month}
                onChange={formik.handleChange}
              />
              {!!formik.errors.month && !!formik.touched.month && (
                <FormErrorMessage>{formik.errors.month}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.amount && !!formik.touched.amount}>
              <FormLabel>Amount</FormLabel>
              <InputGroup>
                <InputLeftElement color="gray.300" fontSize="1.2em">
                  $
                </InputLeftElement>
                <Input
                  placeholder="Enter amount"
                  variant="outline"
                  id="amount"
                  type="number"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                />
              </InputGroup>
              {!!formik.errors.amount && !!formik.touched.amount && (
                <FormErrorMessage>{formik.errors.amount}</FormErrorMessage>
              )}
            </FormControl>
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <PrimaryButton width="100%" onClick={formik.submitForm}>
            Save
          </PrimaryButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

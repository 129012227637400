import * as yup from "yup";
import { Box, Flex, useToast, Image, FormControl, FormLabel, Input, FormErrorMessage, Text } from "@chakra-ui/react";
import { useFormik, FormikHelpers } from "formik";
import { Helmet } from "react-helmet";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/red-canyon-media-logo-horizontal-gray@2x.png";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useRequestPasswordResetMutation } from "../../generated/graphql";
import { AppColors } from "../../core/custom-theme";
import { PrimaryButton } from "../../components/buttons";
import AppLink from "../../components/app-link";
import AppIcon from "../../components/app-icon";

const resetPasswordSchema = yup.object().shape({
  email: yup.string().label("Email").required().email().max(255),
});

interface FormValues {
  email: string;
}

export function RequestPasswordReset() {
  const [requestPasswordReset] = useRequestPasswordResetMutation();
  const toast = useToast();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleFormSubmit(values: FormValues, _formikHelpers: FormikHelpers<FormValues>) {
    try {
      await requestPasswordReset({
        variables: {
          input: {
            email: values.email,
          },
        },
      });
      toast({
        title: "Password Reset Requested",
        description: "Please check your email for instructions on resetting your password.",
        status: "info",
      });
      navigate("/login");
    } catch (e: any) {
      toast({ title: "Reset Password", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      email: "",
    },
    validateOnBlur: true,
    validationSchema: resetPasswordSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <>
      <Helmet title="Request Password Reset" />
      <Box
        bg={AppColors.body}
        display="flex"
        justifyContent="center"
        alignItems={{ xs: "flex-start", lg: "center" }}
        height="100vh"
      >
        <Box p={4} mt={1} width={{ xs: 350, lg: 414 }} borderRadius={4}>
          <Flex justifyContent="center">
            <Image
              justifyContent="center"
              src={Logo}
              alt="Creatives Scale"
              width="250px"
              height="100px"
              objectFit="contain"
            />
          </Flex>
          <Box
            padding={8}
            marginBottom={2}
            textAlign="left"
            bg={AppColors.white}
            borderRadius={4}
            border={`1px solid ${AppColors.whiteSmoke}`}
          >
            <AppLink fontSize="sm" marginBottom={4} to="/">
              <AppIcon icon={faChevronLeft} standardRightMargin />
              Back to Login
            </AppLink>
            <Text fontWeight={600} color={AppColors.textNormal} fontSize={24}>
              Password Recovery
            </Text>
            <Text fontSize={16} color={AppColors.textDarkGray} mb={12}>
              Don’t worry, happens to the best of us
            </Text>
            <form onSubmit={formik.handleSubmit} noValidate={true}>
              <FormControl isInvalid={!!formik.errors.email && formik.touched.email}>
                <FormLabel fontSize={14} fontWeight="normal">
                  Email associated with your account
                </FormLabel>
                <Input type="email" id="email" value={formik.values.email} onChange={formik.handleChange} autoFocus />
                {formik.touched.email && formik.errors.email && (
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                )}
              </FormControl>
              <Box marginTop={8}>
                <PrimaryButton
                  width="100%"
                  bg={AppColors.textNormal}
                  _hover={{ bg: AppColors.textNormal }}
                  _active={{ bg: AppColors.textNormal }}
                  type="submit"
                  isDisabled={formik.isSubmitting}
                  isLoading={formik.isSubmitting}
                  loadingText="Sending Reset Email..."
                >
                  Email Recovery Link
                </PrimaryButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
}

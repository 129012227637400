import React from "react";
import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import {
  useAdminPartnerOverviewQuery,
  useAdminPartnerOverview_UpdatePartnerMutation,
  useSavePartnerAnalyticCredentialMutation,
} from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { FormHelpers } from "../../util/form-helpers";
import { useShowPasswordTrack } from "../../common/use-show-password-track";

interface FormValues {
  name: string;
  billingEmail: string;
  isUpfrontPaymentRequired: boolean;
  isBillingEnforced: boolean;
  stripeAccountId: string;
  portal: string;
}

export function useAdminPartnerOverview() {
  const { id } = useParams<"id">();
  const partnerId = parseInt(id ?? "");

  const queryResult = useAdminPartnerOverviewQuery({
    fetchPolicy: "network-only",
    variables: {
      id: partnerId,
    },
  });
  const [updatePartnerMutation] = useAdminPartnerOverview_UpdatePartnerMutation();
  const [saveAnalyticCredentialMutation, { loading: isSubmitting }] = useSavePartnerAnalyticCredentialMutation();
  const toast = useToast();
  const [showReportingLogoUpload, setShowReportingLogoUpload] = useState(false);
  const [showEmailLogoUpload, setShowEmailLogoUpload] = useState(false);
  const [user, setUser] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [isDirty, setIsDirty] = useState(false);
  const partner = queryResult.data?.partner ?? null;

  const { onPasswordClick, showPassword } = useShowPasswordTrack({
    entityId: partner?.partnerAnalyticCredential?.id,
    entityName: partner?.partnerAnalyticCredential?.__typename,
  });

  useEffect(() => {
    setPassword(partner?.partnerAnalyticCredential?.password ?? undefined);
    setUser(partner?.partnerAnalyticCredential?.userName ?? undefined);
  }, [partner?.partnerAnalyticCredential?.password, partner?.partnerAnalyticCredential?.userName]);

  useEffect(() => {
    if (
      user === (partner?.partnerAnalyticCredential?.userName ?? undefined) &&
      password === (partner?.partnerAnalyticCredential?.password ?? undefined)
    ) {
      setIsDirty(false);
    } else {
      setIsDirty(true);
    }
  }, [password, user, partner?.partnerAnalyticCredential?.userName, partner?.partnerAnalyticCredential?.password]);

  const formik = useFormik<FormValues>({
    initialValues: {
      name: partner?.name ?? "",
      billingEmail: partner?.billingEmail ?? "",
      isUpfrontPaymentRequired: partner?.isUpfrontPaymentRequired ?? true,
      isBillingEnforced: partner?.isBillingEnforced ?? false,
      stripeAccountId: partner?.stripeAccountId ?? "",
      portal: partner?.portal ?? "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await updatePartnerMutation({
          variables: {
            input: {
              partnerId,
              name: values.name,
              billingEmail: values.billingEmail,
              isUpfrontPaymentRequired: values.isUpfrontPaymentRequired,
              isBillingEnforced: values.isBillingEnforced,
              stripeAccountId: FormHelpers.processNullableString(values.stripeAccountId),
              portal: values.portal,
            },
          },
        });
      } catch (e: any) {
        toast({ title: "Unable to Save", description: getDisplayMessageForError(e), status: "error" });
      }
    },
    validationSchema: yup.object().shape({
      name: yup.string().label("Partner Name").required().min(1),
      billingEmail: yup.string().label("Billing Email").required().email(),
      isUpfrontPaymentRequired: yup.bool().label("Is Upfront Payment Required").required(),
      isBillingEnforced: yup.bool().label("Is Billing Enforced").required(),
      stripeAccountId: yup.string().label("Stripe Account ID").notRequired().nullable(true),
      analyticUser: yup.string().label("Analytic User").notRequired().nullable(true),
      analyticPassword: yup.string().label("Analytic Password").notRequired().nullable(true),
      portal: yup.string().label("Partner Type").notRequired().nullable(false),
    }),
  });

  function onReportingLogoUpload() {
    setShowReportingLogoUpload(true);
  }

  function onReportingLogoUploadCancel() {
    setShowReportingLogoUpload(false);
  }

  async function onReportingLogoUploaded(fileId: string | null) {
    try {
      await updatePartnerMutation({
        variables: {
          input: {
            partnerId,
            reportingLogoS3FileId: fileId,
          },
        },
      });
      toast({ title: "Reporting Logo saved.", status: "success" });
      setShowReportingLogoUpload(false);
    } catch (e: any) {
      toast({ title: "Unable to Save Reporting Logo", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  function onEmailLogoUpload() {
    setShowEmailLogoUpload(true);
  }

  function onEmailLogoUploadCancel() {
    setShowEmailLogoUpload(false);
  }

  async function onEmailLogoUploaded(fileId: string | null) {
    try {
      await updatePartnerMutation({
        variables: {
          input: {
            partnerId,
            emailLogoS3FileId: fileId,
          },
        },
      });
      toast({ title: "Email Logo saved.", status: "success" });
      setShowEmailLogoUpload(false);
    } catch (e: any) {
      toast({ title: "Unable to Save Email Logo", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  function onChange(key: string, e: React.ChangeEvent<HTMLInputElement>) {
    const callObj: any = {
      password: setPassword,
      user: setUser,
    };
    callObj[key](e.target.value);
  }

  async function onAnalyticSave() {
    try {
      const saveResponse = await saveAnalyticCredentialMutation({
        variables: {
          input: {
            partnerId: partnerId,
            userName: FormHelpers.processNullableString(user ?? ""),
            password: FormHelpers.processNullableString(password ?? ""),
          },
        },
      });
      if (saveResponse.data?.savePartnerAnalyticCredential.error) {
        throw new Error(saveResponse.data.savePartnerAnalyticCredential.error?.message);
      }
      queryResult.refetch();
    } catch (e: any) {
      toast({ title: "Unable to Save", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  function onAnalyticCancel() {
    setUser(partner?.partnerAnalyticCredential?.userName ?? undefined);
    setPassword(partner?.partnerAnalyticCredential?.password ?? undefined);
  }

  return {
    partnerId,
    loading: queryResult.loading,
    partner,
    formik,
    showReportingLogoUpload,
    onReportingLogoUpload,
    onReportingLogoUploadCancel,
    onReportingLogoUploaded,
    showEmailLogoUpload,
    onEmailLogoUpload,
    onEmailLogoUploadCancel,
    onEmailLogoUploaded,
    password,
    user,
    onChange,
    isDirty,
    isSubmitting,
    onAnalyticCancel,
    onAnalyticSave,
    onPasswordClick,
    showPassword,
  };
}

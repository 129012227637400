import React from "react";
import { sortBy } from "lodash";

import { useUserSelectorQuery } from "../../generated/graphql";

interface Options {
  onUserChanged: (userId: number | null) => void;
  isAdminOrSuperAdmin?: boolean;
}

export function useUserSelector(options: Options) {
  const { onUserChanged, isAdminOrSuperAdmin } = options;

  const queryResult = useUserSelectorQuery({
    variables: {
      isAdminOrSuperAdmin,
    },
    fetchPolicy: "network-only",
  });

  const users = sortBy(queryResult.data?.users.nodes ?? [], "fullName");

  function onUserChange(e: React.ChangeEvent<HTMLSelectElement>) {
    if (e.target.value === "") {
      onUserChanged(null);
    } else {
      onUserChanged(parseInt(e.target.value, 10));
    }
  }

  return {
    users,
    onUserChange,
    loading: queryResult.loading,
  };
}

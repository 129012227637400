import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  DelimitedArrayParam,
  DelimitedNumericArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import { useAccessiblePartnerCount } from "../../common/use-accessible-partner-count";
import { PAGE_LIMIT } from "../../constants/constants";
import { CustomersSort, useCustomersQuery } from "../../generated/graphql";
import { usePagination } from "../../util/use-pagination";
import { FormHelpers } from "../../util/form-helpers";
import { CAMPAIGN_CATEGORIES } from "../../constants/campaign-category";
import { useAuthContext } from "../../core/auth-manager/auth-manager-hook";

export function useClients() {
  const [showAddClientDrawer, setShowAddClientDrawer] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useAuthContext();
  const [searchValue, setSearchValueParam] = useQueryParam("search", StringParam);
  const [sort, setSortQueryParam] = useQueryParam("sort", withDefault(StringParam, CustomersSort.IdDesc));
  const [partnerIds, setPartnerIdsParam] = useQueryParam("partnerIds", withDefault(DelimitedNumericArrayParam, []));
  const [statusIds, setStatusIdsParam] = useQueryParam("statusIds", withDefault(DelimitedArrayParam, []));
  const [campaignCategoryId, setCampaignCategoryIdParam] = useQueryParam(
    "campaignCategoryId",
    withDefault(StringParam, "")
  );
  const [userId, setUserId] = useQueryParam("userId", withDefault(NumberParam, null));
  const [partnerType, setPartnerTypeParam] = useQueryParam("partnerType", withDefault(StringParam, ""));
  const pagination = usePagination(PAGE_LIMIT);
  const customersQueryResult = useCustomersQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      search: searchValue,
      sort: sort ? (sort as CustomersSort) : undefined,
      partnerIds: partnerIds as number[],
      statusIds: statusIds as string[],
      campaignCategoryId: FormHelpers.processNullableString(campaignCategoryId),
      rcmAccountManagerUserId: authContext.isAdmin() ? userId : null,
      partnerPortal: partnerType ?? undefined,
    },
  });
  const accessiblePartnerCount = useAccessiblePartnerCount();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  let filterCount = 0;
  if (statusIds.length > 0) {
    filterCount++;
  }
  if (partnerIds.length > 0) {
    filterCount++;
  }
  if (partnerType) {
    filterCount++;
  }

  useEffect(() => {
    if (location.search === "") {
      setStatusIdsParam(["active"]);
      setSortQueryParam(CustomersSort.IdDesc);
      setCampaignCategoryIdParam(CAMPAIGN_CATEGORIES.SEO.id);
      if (authContext.isAdmin()) {
        setUserId(authContext.viewer?.user?.id ?? null);
        setPartnerTypeParam("partner");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value === "") {
      setSearchValueParam(undefined);
    } else {
      setSearchValueParam(event.target.value);
    }
    pagination.resetOffset();
  }

  function clearFilters() {
    navigate({ search: "" });
  }

  function onSortChange(value: string) {
    setSortQueryParam(value);
    pagination.resetOffset();
  }

  function onAddClient() {
    setShowAddClientDrawer(true);
  }

  function closeAddClientDrawer() {
    setShowAddClientDrawer(false);
  }

  function onClientAdded(customerId: number, isAddingAnother: boolean) {
    if (isAddingAnother) {
      customersQueryResult.refetch();
    } else {
      closeAddClientDrawer();
      navigate(`/clients/${customerId}/overview`);
    }
  }

  function openFilters() {
    setIsFiltersOpen(true);
  }

  function closeFilters() {
    setIsFiltersOpen(false);
  }

  function onPartnerIdsChange(value: number[]) {
    setPartnerIdsParam(value);
    pagination.resetOffset();
  }

  function onStatusIdsChange(value: string[]) {
    setStatusIdsParam(value);
    pagination.resetOffset();
  }

  function onCampaignCategoryChange(value: string) {
    if (!value && (sort === CustomersSort.EndDateDesc || sort === CustomersSort.EndDateAsc)) {
      setSortQueryParam(CustomersSort.IdDesc);
    }
    setCampaignCategoryIdParam(value);
  }

  function onUserSelected(value: number | null) {
    setUserId(value);
  }

  function onPartnerTypeChange(value: string | null) {
    setPartnerTypeParam(value);
  }

  const customers = customersQueryResult.data?.customers.nodes ?? [];
  const totalCount = customersQueryResult.data?.customers.totalCount ?? 0;

  return {
    accessiblePartnerCount,
    clearFilters,
    closeAddClientDrawer,
    closeFilters,
    customers,
    filterCount,
    isFiltersOpen,
    loading: customersQueryResult.loading,
    onAddClient,
    onClientAdded,
    onPartnerIdsChange,
    onSearchChange,
    onSortChange,
    onStatusIdsChange,
    onCampaignCategoryChange,
    openFilters,
    pagination,
    partnerIds,
    showAddClientDrawer,
    sort,
    statusIds,
    campaignCategoryId,
    totalCount,
    userId,
    onUserSelected,
    partnerType,
    onPartnerTypeChange,
  };
}

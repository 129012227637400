import { useContext, useState } from "react";
import {
  Box,
  Image,
  Flex,
  FormLabel,
  Input,
  FormControl,
  Text,
  useToast,
  FormErrorMessage,
  Divider,
  Button,
} from "@chakra-ui/react";
import * as yup from "yup";
import { useFormik, FormikHelpers } from "formik";
import { useGoogleLogin, TokenResponse } from "@react-oauth/google";
import { Navigate, useNavigate } from "react-router-dom";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

import Logo from "../../assets/red-canyon-media-logo-horizontal-gray@2x.png";
import { PrimaryButton } from "../../components/buttons";
import { AppColors } from "../../core/custom-theme";
import { useLoginUserMutation } from "../../generated/graphql";
import { AuthContext } from "../../core/auth-manager";
import { getDisplayMessageForError } from "../../util/error-helper";
import AppLink from "../../components/app-link";
import { LayoutBase } from "../../components/layout";
import AppIcon from "../../components/app-icon";

interface FormValues {
  email: string;
  password: string;
}

const emailSchema = yup.string().label("Email").required().email().max(255);

const loginSchema = yup.object().shape({
  email: emailSchema,
  password: yup.string().label("Password").required(),
});

export function Login() {
  const [loginUserMutation] = useLoginUserMutation();
  const authContext = useContext(AuthContext);
  const toast = useToast();
  const navigate = useNavigate();
  const [canUseGoogle, setCanUseGoogle] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleFormSubmit(values: FormValues, _formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await loginUserMutation({
        variables: {
          input: {
            email: values.email,
            password: values.password,
          },
        },
      });
      if (response.data?.loginUser.isLoggedIn === true) {
        await authContext.refresh();
        navigate("/");
      } else {
        throw new Error("There was a problem logging in. Please try again.");
      }
    } catch (e: any) {
      toast({ title: "Login", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnBlur: true,
    validationSchema: loginSchema,
    onSubmit: handleFormSubmit,
  });

  async function onLoginWithEmail() {
    try {
      // reset password validation
      formik.setFieldTouched("password", false);
      const email = formik.values.email;
      await emailSchema.validate(email);
      await loginUserMutation({
        variables: {
          input: {
            email,
          },
        },
      });
      toast({
        title: "Login Link Sent",
        description: "A login link has been sent to your email address. Please click the link in the email to login.",
      });
    } catch (e: any) {
      if (e instanceof yup.ValidationError) {
        // set password error validation
        formik.setFieldTouched("email", true);
        formik.setFieldError("email", e?.errors?.[0]);
      } else {
        toast({ title: "Login", description: getDisplayMessageForError(e), status: "error" });
      }
    }
  }

  async function onGoogleLoginSuccess(googleResponse: TokenResponse) {
    if (!googleResponse.access_token) {
      toast({ title: "Login", description: "There was a problem logging in. Please try again.", status: "error" });
      return;
    }

    try {
      const response = await loginUserMutation({
        variables: {
          input: {
            googleAccessToken: googleResponse.access_token,
          },
        },
      });
      if (response.data?.loginUser.isLoggedIn === true) {
        await authContext.refresh();
        navigate("/");
      } else {
        throw new Error("There was a problem logging in. Please try again.");
      }
    } catch (e: any) {
      toast({ title: "Login", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  async function onGoogleLoginFailure(err: any) {
    console.log(err?.error);
    if (err?.error !== "popup_closed_by_user") {
      setCanUseGoogle(false);
      console.error("Google Login Error", { err });
      if (!err?.details.startsWith("Cookies are not enabled")) {
        toast({
          title: "Unable to login using Google",
          description: err.details ?? "Please try again.",
          status: "warning",
        });
      }
    }
  }

  const login = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
    onError: onGoogleLoginFailure,
  });

  function onGoogleLoginClick() {
    login();
  }

  if (authContext.isLoggedIn && !!authContext.viewer) {
    return <Navigate to="/" />;
  }

  return (
    <LayoutBase pageTitle="Login">
      <Box
        display="flex"
        justifyContent="center"
        alignItems={{ xs: "flex-start", lg: "center" }}
        height="100vh"
        paddingBottom={8}
      >
        <Box p={4} mt={1} width={{ xs: "350px", lg: "414px" }} borderRadius={4}>
          <Flex justifyContent="center" marginBottom={0}>
            <Image
              justifyContent="center"
              src={Logo}
              alt="Creatives Scale"
              width="250px"
              height="100px"
              objectFit="contain"
            />
          </Flex>
          <Box padding={8} marginBottom={2} textAlign="left" bg={AppColors.white} borderRadius={4}>
            <form onSubmit={formik.handleSubmit} noValidate={true}>
              <FormControl isRequired isInvalid={!!formik.errors.email && formik.touched.email}>
                <FormLabel fontSize={14} fontWeight="normal">
                  Email
                </FormLabel>
                <Input type="email" id="email" value={formik.values.email} onChange={formik.handleChange} autoFocus />
                {formik.touched.email && formik.errors.email && (
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired marginTop={4} isInvalid={!!formik.errors.password && formik.touched.password}>
                <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between" alignItems="center">
                  <FormLabel fontSize={14} fontWeight="normal">
                    Password
                  </FormLabel>
                  <AppLink to="/request-password-reset" fontSize="sm">
                    <Text fontSize={12} fontWeight={500} color={AppColors.blue} cursor="pointer">
                      Forgot your password?
                    </Text>
                  </AppLink>
                </Box>
                <Input type="password" id="password" value={formik.values.password} onChange={formik.handleChange} />
                {formik.touched.password && formik.errors.password && (
                  <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                )}
              </FormControl>
              <Box marginTop={4}>
                <PrimaryButton
                  width="100%"
                  bg={AppColors.textNormal}
                  _hover={{ bg: AppColors.textNormal }}
                  _active={{ bg: AppColors.textNormal }}
                  type="submit"
                  isDisabled={formik.isSubmitting}
                  isLoading={formik.isSubmitting}
                  loadingText="Logging In..."
                >
                  Login
                </PrimaryButton>
              </Box>
            </form>
            <Box marginTop={4}>
              <Divider mb={4} />
              <Button variant="outline" width="100%" mb={4} onClick={onLoginWithEmail}>
                Login with Email
              </Button>
              <Button onClick={onGoogleLoginClick} isDisabled={!canUseGoogle} width="100%" variant="outline">
                <AppIcon icon={faGoogle} wideRightMargin /> Login with Google
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </LayoutBase>
  );
}

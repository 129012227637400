import { Box, Checkbox, FormControl, FormLabel, Select, Stack, Text } from "@chakra-ui/react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { AdminBadge } from "../../components/admin-badge";
import AppIcon from "../../components/app-icon";
import AppLink from "../../components/app-link";
import { PrimaryButton } from "../../components/buttons";
import { FormattedDate } from "../../components/formatted-date";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { currencyFormatter } from "../../util/formatter";
import { useAdminBillingCreateInvoice } from "./admin-billing-create-invoice-hook";

export function AdminBillingCreateInvoiceRoute() {
  const {
    billableOrders,
    partnerId,
    partners,
    handlePartnerChange,
    selectedOrderIds,
    toggleOrder,
    handleCheckAll,
    selectedOrdersWholesaleTotal,
    handleRangeChange,
    selectedRange,
    handleCreateInvoice,
    isCreatingInvoice,
    handleConfirmedChange,
    isConfirmed,
  } = useAdminBillingCreateInvoice();
  return (
    <LayoutBase pageTitle="Create Invoice (Admin)">
      <MainNav />
      <Container centerHorizontally>
        <Stack spacing={4} marginTop={4}>
          <AppLink to="/admin/billing">
            <AppIcon icon={faChevronLeft} standardRightMargin />
            Back to All Partners
          </AppLink>
          <Box>
            <PageHeading preventMarginY>Create Partner Invoice</PageHeading>
            <Text>
              <AdminBadge />
            </Text>
          </Box>
          <PanelGroup>
            <PanelTop>
              <Stack isInline spacing={2}>
                {partners.length > 1 && (
                  <FormControl>
                    <FormLabel>Selected Partner</FormLabel>
                    <Select onChange={handlePartnerChange} value={partnerId ?? partners[0].id}>
                      {partners.map((p) => (
                        <option key={p.id} value={p.id}>
                          {p.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <FormControl>
                  <FormLabel>Show Unpaid Orders With Start Date</FormLabel>
                  <Select value={selectedRange} onChange={handleRangeChange}>
                    <option value="day">In the Next Day (or earlier)</option>
                    <option value="week">In the Next Week (or earlier)</option>
                    <option value="month">In the Next Month (or earlier)</option>
                  </Select>
                </FormControl>
              </Stack>
            </PanelTop>
            <PanelBottom containsTable>
              <Table isEmpty={billableOrders.length === 0} isLoading={false}>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>
                      <Checkbox
                        isChecked={selectedOrderIds.length > 0}
                        isIndeterminate={selectedOrderIds.length > 0 && selectedOrderIds.length < billableOrders.length}
                        onChange={handleCheckAll}
                      />
                    </TableHeaderCell>
                    <TableHeaderCell>Order ID</TableHeaderCell>
                    <TableHeaderCell>Start Date</TableHeaderCell>
                    <TableHeaderCell>Customer</TableHeaderCell>
                    <TableHeaderCell>Product</TableHeaderCell>
                    <TableHeaderCell>Total Wholesale Amount</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billableOrders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>
                        <Checkbox
                          isChecked={selectedOrderIds.includes(order.id)}
                          onChange={() => {
                            toggleOrder(order.id);
                          }}
                        />
                      </TableCell>
                      <TableCell>{order.id}</TableCell>
                      <TableCell>
                        <FormattedDate format={DATE_MONTH_FORMAT} date={order.startDate} />
                      </TableCell>
                      <TableCell>{order.customer.name}</TableCell>
                      <TableCell>
                        <Stack spacing={0}>
                          <Text>{order.partnerProduct.product.name}</Text>
                        </Stack>
                      </TableCell>
                      <TableCell>{currencyFormatter.format(order.totalWholesaleAmount)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </PanelBottom>
          </PanelGroup>
          <Box textAlign="right">
            <Stack spacing={1}>
              <Text>
                Total: <strong>{currencyFormatter.format(selectedOrdersWholesaleTotal)}</strong>
              </Text>
              {selectedOrderIds.length > 0 && (
                <FormControl>
                  <Checkbox isChecked={isConfirmed} onChange={handleConfirmedChange}>
                    I understand that this will create an &quot;open&quot; invoice for the {selectedOrderIds.length}{" "}
                    selected order(s) totaling {currencyFormatter.format(selectedOrdersWholesaleTotal)}.
                  </Checkbox>
                </FormControl>
              )}
              <Box>
                <PrimaryButton
                  isLoading={isCreatingInvoice}
                  onClick={handleCreateInvoice}
                  isDisabled={selectedOrderIds.length === 0 || !isConfirmed}
                >
                  Create Invoice
                </PrimaryButton>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </LayoutBase>
  );
}

import { useState } from "react";
import { useUserAutoCompleteQuery } from "../../generated/graphql";
import { PAGE_LIMIT } from "../../constants/constants";

interface Props {
  isAdminOrSuperAdmin?: boolean;
}

export function useUserAutoComplete(props: Props) {
  const { isAdminOrSuperAdmin } = props;

  const [search, setSearch] = useState("");
  const queryResult = useUserAutoCompleteQuery({
    variables: {
      search,
      isAdminOrSuperAdmin,
      offset: 0,
      limit: PAGE_LIMIT,
    },
    fetchPolicy: "cache-and-network",
  });

  function onSearchChange(value: string) {
    setSearch(value);
  }

  const users = queryResult.data?.users.nodes ?? [];

  const options = users.map((node) => ({
    value: `${node.id}`,
    label: `${node.fullName} (${node.email})`,
  }));

  return {
    options,
    search,
    onSearchChange,
  };
}

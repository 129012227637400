import { Box, Button, Flex, FormControl, FormLabel, HStack } from "@chakra-ui/react";
import { faBan, faSort } from "@fortawesome/free-solid-svg-icons";

import { OrdersSort } from "../../generated/graphql";

import AppIcon from "../../components/app-icon";
import { SearchInput } from "../../components/search-input/search-input";
import { HelpText } from "../../components/help-text";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { LayoutBase, MainNav } from "../../components/layout";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";

import { CustomerSelector } from "../../common/customer-selector";
import { CustomSingleSelect } from "../../common/custom-selector";
import { OrderStatusSelector } from "../../common/order-status-selector";
import { ProductCategorySelector } from "../../common/product-category-selector";

import { useOrders } from "./use-orders-hook";
import { OrdersTable } from "./orders-table";

export function OrdersRoute() {
  const {
    ordersQueryResult,
    sort,
    statusIds,
    clientIds,
    productCategoryIds,
    hasFilters,
    onSearchChange,
    onSortChange,
    onOrderStatusSelected,
    onCustomerSelected,
    onProductCategorySelected,
    onNextPage,
    onPreviousPage,
    clearFilters,
  } = useOrders();

  return (
    <LayoutBase pageTitle="Orders">
      <MainNav />
      <Container centerHorizontally>
        <Flex alignItems="center">
          <PageHeading>Orders</PageHeading>
          <HelpText standardLeftMargin>Orders for all existing work being completed for all clients</HelpText>
        </Flex>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between">
              <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end">
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Search</FormLabel>
                  <SearchInput onChange={onSearchChange} />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Sort</FormLabel>
                  <CustomSingleSelect
                    onChange={onSortChange}
                    allowEmpty={false}
                    value={sort ?? null}
                    emptyValueTitle="Newest First"
                    items={[
                      { displayText: "Newest First", value: OrdersSort.StartedAtDesc },
                      { displayText: "Oldest First", value: OrdersSort.StartedAtAsc },
                    ]}
                    selectorIcon={faSort}
                  />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Client</FormLabel>
                  <CustomerSelector
                    onCustomerSelected={onCustomerSelected}
                    selectedCustomerIds={clientIds}
                    emptyValueLabel="All Clients"
                    isMulti
                  />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Status</FormLabel>
                  <OrderStatusSelector
                    onStatusSelected={onOrderStatusSelected}
                    selectedStatusIds={statusIds}
                    emptyValueLabel="All Statuses"
                  />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Product Category</FormLabel>
                  <ProductCategorySelector
                    onProductCategorySelected={onProductCategorySelected}
                    selectedProductCategoryIds={productCategoryIds as string[]}
                    emptyValueLabel="All Product Categories"
                  />
                </FormControl>
                <Box>
                  {hasFilters && (
                    <Button onClick={clearFilters}>
                      <AppIcon icon={faBan} standardRightMargin />
                      Clear Filters
                    </Button>
                  )}
                </Box>
              </HStack>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <OrdersTable
              ordersQueryResult={ordersQueryResult}
              onNextPage={onNextPage}
              onPreviousPage={onPreviousPage}
            />
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}

export const TASK_REVIEW_STATUS = {
  APPROVED: {
    id: "approved",
    name: "Approved",
  },
  REJECTED: {
    id: "rejected",
    name: "Rejected",
  },
  REVISED: {
    id: "revised",
    name: "Revised",
  },
};

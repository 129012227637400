import { useState, useContext } from "react";
import AppLink from "../../app-link";
import LogoImage from "../../../assets/red-canyon-media-logo-horizontal-white@2x.png";
import AppIcon from "../../app-icon";
import { faBars, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { getMainNavDefinition } from "../main-nav-definition";
import { AuthContext } from "../../../core/auth-manager";
import { useLocation } from "react-router-dom";
import { MainNavSmallLink } from "./main-nav-small-link";
import { MainNavSmallDropdownMenu } from "./main-nav-small-dropdown-menu";
import { MainNavSmallProfile } from "./main-nav-small-profile";
import { Box, Drawer, Button, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerBody } from "@chakra-ui/react";

interface Props {
  hasSecondaryNav?: boolean;
}

export function MainNavSmall(props: Props) {
  const { hasSecondaryNav } = props;
  const authContext = useContext(AuthContext);
  const [isMainNavOpen, setIsMainNavOpen] = useState(false);
  const [isProfileNavOpen, setIsProfileNavOpen] = useState(false);
  const location = useLocation();

  const navDefinitions = getMainNavDefinition({ authContext });

  return (
    <Box
      height="64px"
      backgroundColor="#333333"
      position="sticky"
      top="0"
      zIndex={999}
      display="grid"
      alignItems="center"
      gridTemplateColumns="1fr 205px 1fr"
      marginBottom={hasSecondaryNav === true ? 0 : 2}
      boxShadow={hasSecondaryNav === true ? undefined : "2px 0px 12px rgba(0, 0, 0, 0.6)"}
    >
      <Drawer
        isOpen={isMainNavOpen}
        placement="left"
        onClose={() => {
          setIsMainNavOpen(false);
        }}
        closeOnOverlayClick={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Box padding={2} minWidth={250} marginTop={8}>
              {navDefinitions.map((navItem) => {
                if (!navItem.visible) {
                  return null;
                }
                if (!navItem.submenu) {
                  return (
                    <Box key={navItem.name} marginBottom={2} fontSize="1rem">
                      <MainNavSmallLink to={navItem.to} isActive={location.pathname.startsWith(navItem.to)}>
                        {navItem.name}
                      </MainNavSmallLink>
                    </Box>
                  );
                }
                return (
                  <MainNavSmallDropdownMenu
                    key={navItem.name}
                    title={navItem.name}
                    isActive={location.pathname.startsWith(navItem.to)}
                  >
                    {navItem.submenu.map((subItem) => {
                      if (!subItem.visible) {
                        return null;
                      }
                      return (
                        <Box key={subItem.name} marginBottom={2} marginLeft={2} fontSize="1rem">
                          <MainNavSmallLink
                            to={subItem.to}
                            isExternal={subItem.isExternal}
                            isActive={location.pathname.startsWith(subItem.to)}
                          >
                            {subItem.name}
                          </MainNavSmallLink>
                        </Box>
                      );
                    })}
                  </MainNavSmallDropdownMenu>
                );
              })}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Drawer isOpen={isProfileNavOpen} placement="right" onClose={() => setIsProfileNavOpen(false)}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <Box padding={2} minWidth={250}>
            <MainNavSmallProfile />
          </Box>
        </DrawerContent>
      </Drawer>
      <Box gridColumn={1} paddingLeft={1}>
        <Button
          variant="ghost"
          backgroundColor="transparent"
          _hover={{ backgroundColor: "transparent" }}
          _active={{ backgroundColor: "transparent" }}
          onClick={() => setIsMainNavOpen(true)}
        >
          <AppIcon icon={faBars} color="white" />
        </Button>
      </Box>
      <Box gridColumn={2}>
        <AppLink to="/">
          <img src={LogoImage} alt="Red Canyon Media" style={{ maxHeight: 50 }} />
        </AppLink>
      </Box>
      <Box gridColumn={3} textAlign="right" paddingRight={1}>
        <Button
          variant="ghost"
          onClick={() => setIsProfileNavOpen(true)}
          backgroundColor="transparent"
          _hover={{ backgroundColor: "transparent" }}
          _active={{ backgroundColor: "transparent" }}
        >
          <AppIcon icon={faUserCircle} color="white" />
        </Button>
      </Box>
    </Box>
  );
}

import { ArrayParam, DelimitedNumericArrayParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import { PAGE_LIMIT } from "../../constants/constants";
import { PartnerInvoiceSort, useAdminInvoicesQuery } from "../../generated/graphql";
import { usePagination } from "../../util/use-pagination";

export function useAdminInvoices() {
  const pagination = usePagination(PAGE_LIMIT);
  const [selectedPartnerIds, setPartnerIds] = useQueryParam("partnerIds", withDefault(DelimitedNumericArrayParam, []));
  const [sort, setSort] = useQueryParam("sort", withDefault(StringParam, PartnerInvoiceSort.IdDesc));
  const [statusIds, setStatusIds] = useQueryParam("statusIds", withDefault(ArrayParam, []));
  const [search, setSearch] = useQueryParam("search", StringParam);

  const queryResult = useAdminInvoicesQuery({
    fetchPolicy: "network-only",
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      partnerIds: selectedPartnerIds as number[],
      sort: sort as PartnerInvoiceSort,
      statusIds: statusIds as string[],
      search: (search?.length ?? 0) > 0 ? search : null,
    },
  });

  let filterCount = 0;

  if (selectedPartnerIds.length > 0) {
    filterCount++;
  }

  if (statusIds.length > 0) {
    filterCount++;
  }

  const partnerInvoices = queryResult.data?.partnerInvoices.nodes ?? [];

  function handlePartnersChange(value: number[]) {
    setPartnerIds(value);
    pagination.resetOffset();
  }

  function clearFilters() {
    setPartnerIds([]);
    setStatusIds([]);
    pagination.resetOffset();
  }

  function handleSortChange(newSort: string | null) {
    if (!newSort) {
      return;
    }
    setSort(newSort);
    pagination.resetOffset();
  }

  function handleStatusIdsChange(value: string[]) {
    setStatusIds(value);
    pagination.resetOffset();
  }

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  }

  return {
    loading: queryResult.loading,
    partnerInvoices,
    totalCount: queryResult.data?.partnerInvoices.totalCount ?? 0,
    pagination,
    handlePartnersChange,
    selectedPartnerIds: selectedPartnerIds as number[],
    filterCount,
    clearFilters,
    sort,
    handleSortChange,
    statusIds: statusIds as string[],
    handleStatusIdsChange,
    search,
    handleSearchChange,
  };
}

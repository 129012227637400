import * as yup from "yup";
import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { FormikHelpers, useFormik } from "formik";

import { useGuestArticle_UpdateOrderMutation, useWebsiteSelectionTaskWorkspaceQuery } from "../../../generated/graphql";
import { getDisplayMessageForError } from "../../../util/error-helper";
import { FormHelpers } from "../../../util/form-helpers";

interface Options {
  taskId: number;
  onComplete: (isApproved?: boolean, reviewComment?: string) => void;
}

interface FormValues {
  targetResultUrl?: string;
  targetLinkLocationId?: number;
  publisher?: string;
}

const validationSchema = yup.object().shape({
  targetResultUrl: yup.string().label("Target Result URL").notRequired().nullable(true),
  targetLinkLocationId: yup.number().label("Target Link Location").notRequired().nullable(true),
});

export function useWebsiteSelection(options: Options) {
  const { taskId, onComplete } = options;
  const [updateOrder] = useGuestArticle_UpdateOrderMutation();
  const toast = useToast();
  const [showAddLinkLocation, setShowAddLinkLocation] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const queryResult = useWebsiteSelectionTaskWorkspaceQuery({
    variables: {
      taskId,
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function onSubmit(values: FormValues, _helpers: FormikHelpers<FormValues>) {
    // Currently, we are updating result url at order level
    // we may have to do it at task level after approval we can update it at order level
    try {
      const response = await updateOrder({
        variables: {
          input: {
            orderId: queryResult.data?.task?.order?.id ?? 0,
            targetResultUrl: FormHelpers.processNullableString(values.targetResultUrl ?? ""),
            targetLinkLocationId: FormHelpers.processNullableInt(values.targetLinkLocationId),
          },
        },
      });
      if (!response.data?.updateOrder.order.id) {
        throw new Error("Something went wrong while updating order");
      }
      onComplete();
    } catch (e: any) {
      toast({ title: "Unable to create task outreach", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  function onClickAddLinkLocation() {
    setShowAddLinkLocation(true);
  }

  function onCloseAddLinkLocation() {
    setShowAddLinkLocation(false);
  }

  function onLinkLocationAdded() {
    setRefetch(true);
  }

  function onRefetchDone() {
    setRefetch(false);
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      targetResultUrl: queryResult.data?.task?.order.targetResultUrl ?? undefined,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  function onLinkLocationSelected(domain: string, linkLocationId: number, publisher: string) {
    formik.setFieldValue("targetResultUrl", `https://${domain}`);
    formik.setFieldValue("targetLinkLocationId", linkLocationId);
    formik.setFieldValue("publisher", publisher);
  }

  return {
    formik,
    showAddLinkLocation,
    onClickAddLinkLocation,
    onCloseAddLinkLocation,
    refetch,
    onLinkLocationAdded,
    onRefetchDone,
    onLinkLocationSelected,
    product: queryResult.data?.task?.order.partnerProduct.product,
  };
}

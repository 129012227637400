import { ReactNode } from "react";
import { DateTime } from "luxon";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Heading,
  Checkbox,
  Switch,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";

import { PanelBottom, PanelGroup, PanelMiddle, PanelTop } from "../../../components/panels";
import { DangerButton, PrimaryButton } from "../../../components/buttons";
import { useChecklistTask } from "./use-checklist-task";
import { AppColors } from "../../../core/custom-theme";
import { ContentEditor } from "../../../components/content-editor";
import { HelpText } from "../../../components/help-text";
import { DATE_MONTH_FORMAT } from "../../../constants/constants";

interface Props {
  heading: string;
  taskId: number;
  checklistItems: string[];
  onComplete: (isApproved?: boolean, reviewComment?: string) => Promise<void>;
  showApprove?: boolean;
  onAbandon: () => void;
  children?: ReactNode;
  completedAt?: Date;
  isDisabled?: boolean;
  hideUnassign?: boolean;
}

export function ChecklistTask(props: Props) {
  const {
    heading,
    onComplete,
    checklistItems,
    showApprove = true,
    onAbandon,
    isDisabled,
    completedAt,
    children,
    hideUnassign,
  } = props;

  const { isChecked, onCheckClick, formik, canSubmit } = useChecklistTask({
    noOfChecklistItems: checklistItems.length,
    isDisabled,
    onComplete,
  });

  return (
    <PanelGroup isCollapsible isCollapsedByDefault={isDisabled}>
      <PanelTop>
        <Flex justifyContent="space-between">
          <Heading as="h4" size="md">
            {heading}
          </Heading>
          {!!completedAt && <Text>Completed At {DateTime.fromJSDate(completedAt).toFormat(DATE_MONTH_FORMAT)}</Text>}
        </Flex>
      </PanelTop>
      <PanelMiddle>
        <Stack spacing={4}>
          {checklistItems.map((checklistItem, i) => (
            <Checkbox
              key={checklistItem}
              size="lg"
              colorScheme="blue"
              isChecked={isChecked[i]}
              onChange={onCheckClick.bind(null, i)}
              isDisabled={isDisabled}
            >
              {checklistItem}
            </Checkbox>
          ))}
          {children}
        </Stack>
      </PanelMiddle>
      <PanelBottom>
        <Stack spacing={4}>
          {showApprove && (
            <>
              <FormControl isDisabled={isDisabled}>
                <Stack spacing={2} isInline>
                  <Switch
                    color="green"
                    id="isApproved"
                    isChecked={formik.values.isApproved}
                    onChange={formik.handleChange}
                  />
                  <FormLabel color={formik.values.isApproved ? undefined : AppColors.error}>
                    {formik.values.isApproved ? "Approved" : "Rejected"}
                  </FormLabel>
                </Stack>
              </FormControl>
              {!formik.values.isApproved && (
                <FormControl
                  isRequired
                  isInvalid={!!formik.touched.comments && !!formik.errors.comments}
                  isDisabled={isDisabled}
                >
                  <FormLabel>Comments</FormLabel>
                  <ContentEditor
                    isInvalid={!!formik.touched.comments && !!formik.errors.comments}
                    onChange={(html) => {
                      formik.setFieldValue("comments", html);
                    }}
                  />
                  <FormErrorMessage>{formik.errors.comments}</FormErrorMessage>
                </FormControl>
              )}
            </>
          )}
          <Stack isInline spacing={2} justifyContent="space-between" shouldWrapChildren>
            <PrimaryButton
              width={160}
              isLoading={formik.isSubmitting}
              onClick={formik.submitForm}
              isDisabled={isDisabled || !canSubmit}
            >
              Complete Task
            </PrimaryButton>
            {!hideUnassign && (
              <Box>
                <DangerButton onClick={onAbandon} isDisabled={formik.isSubmitting || isDisabled}>
                  Unassign Task
                </DangerButton>
                <HelpText standardLeftMargin>
                  {`"Unassign Task" will unassign this task from you and return it to the queue. `}
                  <strong>This cannot be undone and may result in lost work.</strong>
                </HelpText>
              </Box>
            )}
          </Stack>
        </Stack>
      </PanelBottom>
    </PanelGroup>
  );
}

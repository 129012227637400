import { Box, Checkbox, FormControl, FormLabel, Heading, SimpleGrid, Stack } from "@chakra-ui/react";

import { PartnerPaymentMethodSelector } from "../../common/partner-payment-method-selector";
import { PrimaryButton } from "../../components/buttons";
import { DataDisplayRow } from "../../components/data-display-row";
import { PanelBottom, PanelBox, PanelGroup, PanelTop } from "../../components/panels";
import { Table, TableBody } from "../../components/table";
import { currencyFormatter } from "../../util/formatter";

interface Props {
  partnerId: number;
  totalAmount: number;
  isPaymentTermsAccepted: boolean;
  isPaymentProcessing: boolean;
  selectedPartnerPaymentMethodId: number | null;
  handlePartnerPaymentMethodChange: (partnerPaymentMethodId: number | null) => void;
  handlePaymentTermsAcceptedChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmitPayment: () => void;
}

export function MakePayment(props: Props) {
  const {
    handlePartnerPaymentMethodChange,
    handleSubmitPayment,
    handlePaymentTermsAcceptedChange,
    isPaymentProcessing,
    isPaymentTermsAccepted,
    partnerId,
    selectedPartnerPaymentMethodId,
    totalAmount,
  } = props;
  return (
    <PanelGroup>
      <PanelTop showBottomBorder>
        <Heading size="md">Pay Invoice</Heading>
      </PanelTop>
      <PanelBottom>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={4}>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Summary</FormLabel>
              <PanelBox containsTable>
                <Table isEmpty={false} isLoading={false}>
                  <TableBody>
                    <DataDisplayRow label="Amount to Be Billed" value={currencyFormatter.format(totalAmount)} />
                  </TableBody>
                </Table>
              </PanelBox>
            </FormControl>
          </Stack>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Payment Method</FormLabel>
              <PartnerPaymentMethodSelector
                onPartnerPaymentSelected={handlePartnerPaymentMethodChange}
                partnerId={partnerId}
                selectedPartnerPaymentMethodId={selectedPartnerPaymentMethodId}
                allowAddNewPaymentMethod
              />
            </FormControl>
            {selectedPartnerPaymentMethodId && (
              <FormControl>
                <Checkbox isChecked={isPaymentTermsAccepted} onChange={handlePaymentTermsAcceptedChange}>
                  I agree to the Terms and Conditions and authorize Red Canyon Media to charge{" "}
                  <strong>{currencyFormatter.format(totalAmount)}</strong> to the selected payment method immediately.
                </Checkbox>
              </FormControl>
            )}
            <Box textAlign="right">
              <PrimaryButton
                onClick={handleSubmitPayment}
                isLoading={isPaymentProcessing}
                isDisabled={!isPaymentTermsAccepted || !selectedPartnerPaymentMethodId}
              >
                Submit Payment
              </PrimaryButton>
            </Box>
          </Stack>
        </SimpleGrid>
      </PanelBottom>
    </PanelGroup>
  );
}

import { Stack, Text } from "@chakra-ui/react";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../components/app-icon";
import { DangerLinkButton } from "../../components/buttons";
import { PanelBox } from "../../components/panels";
import { AppColors } from "../../core/custom-theme";
import { Billing_PartnerBillingContactFragment } from "../../generated/graphql";

interface Props {
  partnerBillingContact: Billing_PartnerBillingContactFragment;
  onEdit: () => void;
  onRemove: () => void;
}

export function BillingContactItem(props: Props) {
  const {
    partnerBillingContact: { email, firstName, lastName },
    // onEdit,
    onRemove,
  } = props;
  return (
    <PanelBox height="120px" display="flex" justifyContent="space-between" alignItems="center" padding={8}>
      <Stack spacing={8} isInline>
        <AppIcon icon={faUser} size="3x" color={AppColors.textDarkGray} />
        <Stack spacing={8} isInline>
          <Stack spacing={1}>
            <Text>
              {firstName} {lastName}
            </Text>
            <Text fontSize="sm">{email}</Text>
          </Stack>
        </Stack>
      </Stack>
      <Stack isInline spacing={8}>
        {/* <PrimaryLinkButton onClick={onEdit}>Edit</PrimaryLinkButton> */}
        <DangerLinkButton onClick={onRemove}>Remove</DangerLinkButton>
      </Stack>
    </PanelBox>
  );
}

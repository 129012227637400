import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { useState } from "react";
import * as yup from "yup";
import {
  useCreatePartnerProductWholesalePriceMutation,
  useDeletePartnerProductWholesalePriceMutation,
  useEditPartnerAvailableProductQuery,
  useUpdatePartnerProductWholesalePriceMutation,
} from "../../generated/graphql";
import { handleFormGraphQLError, handleGraphQLResponseError } from "../../util/error-helper";
import { Props } from "./common";

interface AddFormValues {
  effectiveAt: Date;
  wholesaleAmount: number;
}

const addValidationSchema = yup.object().shape({
  effectiveAt: yup.date().label("Effective Date").required(),
  wholesaleAmount: yup.number().label("Amount").required(),
});

export function useEditPartnerAvailableProduct(props: Props) {
  const { onClose, partnerAvailableProductId } = props;
  const [wholesalePriceIdForEdit, setWholesalePriceIdForEdit] = useState<number | null>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [createPartnerProductWholesalePriceMutation] = useCreatePartnerProductWholesalePriceMutation();
  const [updatePartnerProductWholesalePriceMutation] = useUpdatePartnerProductWholesalePriceMutation();
  const [deletePartnerProductWholesalePriceMutation] = useDeletePartnerProductWholesalePriceMutation();
  const toast = useToast();
  const queryResult = useEditPartnerAvailableProductQuery({
    fetchPolicy: "network-only",
    variables: {
      partnerAvailableProductId: partnerAvailableProductId ?? 0,
    },
    skip: !partnerAvailableProductId,
  });

  const partnerAvailableProduct = queryResult.data?.partnerAvailableProduct ?? null;
  const partnerProductWholesalePrices = partnerAvailableProduct?.partnerProductWholesalePrices.nodes ?? [];
  const earliestNewEffectiveDate = DateTime.max(
    DateTime.fromJSDate(partnerProductWholesalePrices.find((x) => x.isCurrent)?.effectiveAt ?? new Date())
      .plus({ days: 1 })
      .startOf("day"),
    DateTime.now().plus({ days: 1 }).startOf("day")
  ).toJSDate();
  const wholesalePriceForEdit = partnerAvailableProduct?.partnerProductWholesalePrices.nodes.find(
    (x) => x.id === wholesalePriceIdForEdit
  );

  const addFormik = useFormik<AddFormValues>({
    initialValues: {
      effectiveAt: earliestNewEffectiveDate,
      wholesaleAmount: wholesalePriceForEdit?.wholesaleAmount ?? 0,
    },
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      if (!partnerAvailableProduct) {
        return;
      }
      try {
        const response = await createPartnerProductWholesalePriceMutation({
          variables: {
            input: {
              effectiveAt: values.effectiveAt,
              partnerId: partnerAvailableProduct.partner.id,
              productId: partnerAvailableProduct.product.id,
              wholesaleAmount: values.wholesaleAmount,
            },
          },
        });

        if (response.data?.createPartnerProductWholesalePrice.ok) {
          toast({
            title: "Wholesale Price Scheduled",
            status: "success",
          });
          setIsAdding(false);
          queryResult.refetch();
        } else {
          throw new Error(response.data?.createPartnerProductWholesalePrice.error?.message ?? "Something went wrong.");
        }
      } catch (e: any) {
        handleFormGraphQLError(e, "Unable to Save", toast, helpers.setErrors);
      }
    },
    validationSchema: addValidationSchema,
  });

  const editFormik = useFormik<AddFormValues>({
    initialValues: {
      effectiveAt: wholesalePriceForEdit?.effectiveAt ?? earliestNewEffectiveDate,
      wholesaleAmount: wholesalePriceForEdit?.wholesaleAmount ?? 0,
    },
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      if (!wholesalePriceForEdit) {
        return;
      }
      try {
        const response = await updatePartnerProductWholesalePriceMutation({
          variables: {
            input: {
              partnerProductWholesalePriceId: wholesalePriceForEdit.id,
              effectiveAt: values.effectiveAt,
              wholesaleAmount: values.wholesaleAmount,
            },
          },
        });

        if (response.data?.updatePartnerProductWholesalePrice.ok) {
          toast({
            title: "Wholesale Price updated.",
            status: "success",
          });
          helpers.resetForm();
          setWholesalePriceIdForEdit(null);
          queryResult.refetch();
        }
      } catch (e: any) {
        handleFormGraphQLError(e, "Unable to Save", toast, helpers.setErrors);
      }
    },
    validationSchema: addValidationSchema,
  });

  function onWholesalePriceEdit(partnerProductWholesalePriceId: number) {
    setWholesalePriceIdForEdit(partnerProductWholesalePriceId);
  }

  function onWholesalePriceEditCancel() {
    setWholesalePriceIdForEdit(null);
  }

  function onWholesalePriceEdited() {
    queryResult.refetch();
  }

  function onAdd() {
    setIsAdding(true);
  }

  function onAddCancel() {
    setIsAdding(false);
    addFormik.resetForm();
  }

  function handleCancel() {
    onAddCancel();
    onWholesalePriceEditCancel();
    onClose();
  }

  async function onDelete(partnerProductWholesalePriceId: number) {
    try {
      await deletePartnerProductWholesalePriceMutation({
        variables: {
          input: {
            partnerProductWholesalePriceId,
          },
        },
      });
      toast({
        title: "Wholesale Price change removed.",
        status: "success",
      });
      queryResult.refetch();
    } catch (e: any) {
      handleGraphQLResponseError(e, toast);
    }
  }

  return {
    loading: queryResult.loading,
    partnerAvailableProduct,
    addFormik,
    editFormik,
    handleCancel,
    wholesalePriceForEdit,
    onWholesalePriceEdit,
    onWholesalePriceEditCancel,
    onWholesalePriceEdited,
    isAdding,
    onAdd,
    onAddCancel,
    partnerProductWholesalePrices,
    earliestNewEffectiveDate,
    onDelete,
  };
}

import { useWorkflowNotesQuery } from "../../generated/graphql";
import { useState } from "react";

export function useWorkflowNotes(taskId: number) {
  const workflowNotesQueryResult = useWorkflowNotesQuery({
    fetchPolicy: "network-only",
    variables: {
      taskId,
    },
  });
  const [showEditor, setShowEditor] = useState(false);

  function onWorkflowNoteCreated() {
    workflowNotesQueryResult.refetch();
    setShowEditor(false);
  }

  function onAddNote() {
    setShowEditor(true);
  }

  function onAddNoteCancel() {
    setShowEditor(false);
  }

  return {
    loading: workflowNotesQueryResult.loading,
    workflowNotes: workflowNotesQueryResult.data?.task?.workflow?.notes.nodes ?? [],
    workflowId: workflowNotesQueryResult.data?.task?.workflow?.id ?? 0,
    totalCount: workflowNotesQueryResult.data?.task?.workflow?.notes.totalCount ?? 0,
    onWorkflowNoteCreated,
    onAddNote,
    onAddNoteCancel,
    showEditor,
  };
}

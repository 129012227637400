import { SelectProps, Select } from "@chakra-ui/react";
import { useDomainStatusesQuery } from "../../generated/graphql";

interface Props extends SelectProps {
  selectedDomainStatusId?: string | null;
  onDomainStatusSelected: (doaminStatusId: string) => void;
}

export function DomainStatusSelector(props: Props) {
  const { selectedDomainStatusId, onDomainStatusSelected, ...rest } = props;
  const domainStatusesQueryResult = useDomainStatusesQuery({
    fetchPolicy: "cache-and-network",
  });

  function onChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    onDomainStatusSelected(value);
  }

  const domainStatuses = domainStatusesQueryResult.data?.domainStatuses ?? [];

  return (
    <Select
      isDisabled={domainStatusesQueryResult.loading}
      value={selectedDomainStatusId ?? ""}
      onChange={onChange}
      {...rest}
    >
      <option value="">Not Selected</option>
      {domainStatuses.map((domainStatus) => (
        <option key={domainStatus.id} value={domainStatus.id}>
          {domainStatus.name}
        </option>
      ))}
    </Select>
  );
}

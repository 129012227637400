import { Stack, useToast } from "@chakra-ui/react";
import { faMap, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Interval } from "luxon";

import { ClientCampaignStatusIndicator } from "../../common/client-campaign-status-indicator/client-campaign-status-indicator";
import AppLink from "../../components/app-link";
import { PrimaryIconButton } from "../../components/buttons";
import { FormattedDateUTC } from "../../components/formatted-date-utc";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from "../../components/table";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import {
  ClientDetailsClientCampaignFragment,
  ClientDetailsClientCampaignsQueryResult,
  useDeleteClientCampaignMutation,
} from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";

interface Props {
  clientCampaignsQueryResult: ClientDetailsClientCampaignsQueryResult;
  showClientCampaignEditDrawer: (campaign: ClientDetailsClientCampaignFragment) => void;
}

export function CampaignsTable(props: Props) {
  const { clientCampaignsQueryResult, showClientCampaignEditDrawer } = props;
  const campaigns = clientCampaignsQueryResult.data?.customer?.customerCampaigns.nodes ?? [];
  const [deleteClientCampaign] = useDeleteClientCampaignMutation();
  const toast = useToast();

  async function onDelete(campaignId: number) {
    try {
      const response = await deleteClientCampaign({
        variables: {
          input: {
            customerCampaignId: campaignId,
          },
        },
      });

      if (!response.data?.deleteCustomerCampaign.isDeleted) {
        throw new Error("Something went wrong. Please try again.");
      }
      toast({ title: "Campaign Deleted", description: "Campaign was deleted successfully.", status: "success" });
      clientCampaignsQueryResult.refetch();
    } catch (e: any) {
      toast({
        title: "Unable to Delete Campaign",
        description: getDisplayMessageForError(e),
        status: "error",
      });
    }
  }

  // Not showing past campaigns
  const showCampaigns = campaigns.filter((campaign) => campaign.startsAt > new Date() || campaign.isCurrent);

  return (
    <Table isEmpty={showCampaigns.length === 0} isLoading={clientCampaignsQueryResult.loading}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Start Date</TableHeaderCell>
          <TableHeaderCell>Duration</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {showCampaigns.map((campaign) => {
          const campaignInterval = Interval.fromDateTimes(campaign.startsAt, campaign.endsAt);
          const campaignMonths = Math.round(campaignInterval.length("months"));
          return (
            <TableRow key={campaign.id}>
              <TableCell>{campaign.name}</TableCell>
              <TableCell>
                <FormattedDateUTC date={campaign.startsAt} format={DATE_MONTH_FORMAT} />
              </TableCell>
              <TableCell>{campaignMonths} months</TableCell>
              <TableCell>
                <ClientCampaignStatusIndicator startsAt={campaign.startsAt} isCurrent={campaign.isCurrent} />
              </TableCell>
              <TableCell>
                <Stack isInline spacing={2} justifyContent="flex-end">
                  <PrimaryIconButton
                    icon={faPen}
                    actionName="Edit"
                    onClick={showClientCampaignEditDrawer.bind(null, campaign)}
                  />
                  <AppLink to="../roadmap">
                    <PrimaryIconButton icon={faMap} actionName="Roadmap" />
                  </AppLink>
                  <PrimaryIconButton
                    icon={faTrash}
                    actionName="Delete"
                    isDisabled={campaign.isCurrent}
                    onClick={onDelete.bind(null, campaign.id)}
                  />
                </Stack>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

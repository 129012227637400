import { Badge, Tooltip } from "@chakra-ui/react";

import { ORDER_BILLING_STATUS } from "../../constants/order-billing-status";

interface Props {
  billingStatus: {
    id: string;
    name: string;
    description: string;
  };
  startDate: Date;
  isEnforced: boolean;
}

export function OrderBillingStatusIndicator(props: Props) {
  const { billingStatus, startDate, isEnforced } = props;
  if (!isEnforced) {
    return null;
  }
  const isInPast = new Date() >= startDate;
  let color = "gray";
  const label = billingStatus.description;
  if (billingStatus.id === ORDER_BILLING_STATUS.UNPAID.id && isInPast) {
    color = "red";
  } else if (billingStatus.id === ORDER_BILLING_STATUS.PENDING.id) {
    color = "blue";
  } else if (billingStatus.id === ORDER_BILLING_STATUS.PAID.id) {
    color = "green";
  }
  const indicator = (
    <Badge cursor="help" colorScheme={color}>
      {billingStatus.name}
    </Badge>
  );

  return <Tooltip label={label}>{indicator}</Tooltip>;
}

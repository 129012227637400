import { DateTime } from "luxon";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { useAdminPublisherPublisherPaymentBatchReview } from "./use-admin-publisher-payment-batch-review";
import { PanelGroup, PanelTop, PanelBottom, PanelMiddle } from "../../components/panels";
import { Heading, Grid, Stack, FormControl, FormLabel, Input, Checkbox } from "@chakra-ui/react";
import { Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell } from "../../components/table";
import { currencyFormatter } from "../../util/formatter";
import { PrimaryButton } from "../../components/buttons";
import { DATE_MONTH_FORMAT } from "../../constants/constants";
import { AlertInfo } from "../../components/alerts";

export function AdminPublisherPaymentBatchReviewRoute() {
  const {
    loading,
    paymentBatch,
    paymentDetails,
    processor,
    isAccepted,
    onAcceptedChange,
    onApprove,
    handleDownloadCSV,
    isPaymentSubmitted,
  } = useAdminPublisherPublisherPaymentBatchReview();

  return (
    <LayoutBase pageTitle="Payment Batch Funding Review">
      <MainNav />
      <Container centerHorizontally>
        <PageHeading>Payment Batch Review</PageHeading>
        <Grid gridTemplateColumns="1fr 2fr" gridGap={4}>
          <PanelGroup>
            <PanelTop showBottomBorder>
              <Heading size="md">Overview</Heading>
            </PanelTop>
            <PanelMiddle>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Batch Creation Date</FormLabel>
                  <Input
                    variant="outline"
                    isDisabled
                    value={DateTime.fromJSDate(paymentBatch?.createdAt ?? new Date()).toFormat(DATE_MONTH_FORMAT)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Payments Total Amount</FormLabel>
                  <Input
                    variant="outline"
                    isDisabled
                    value={currencyFormatter.format(paymentBatch?.paymentTotalAmount ?? 0)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Fees Total Amount</FormLabel>
                  <Input
                    variant="outline"
                    isDisabled
                    value={currencyFormatter.format(paymentBatch?.feesTotalAmount ?? 0)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Total Funding Required</FormLabel>
                  <Input
                    variant="outline"
                    isDisabled
                    size="lg"
                    value={currencyFormatter.format(paymentBatch?.totalAmount ?? 0)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Processor</FormLabel>
                  <Input variant="outline" isDisabled size="lg" value={processor ?? ""} />
                </FormControl>
                {/*!loading && !paymentBatch?.fundingApprovedAt && processor === "PayPal" && (
                  <FormControl>
                    <Checkbox onChange={onAcceptedChange} isChecked={isAccepted}>
                      {`I understand clicking Approve will immediately initiate a bank transfer to Publisher's PayPal in
                      the amount of ${currencyFormatter.format(paymentBatch?.totalAmount ?? 0)}`}
                      .
                    </Checkbox>
                  </FormControl>
                )*/}
                {!loading && !paymentBatch?.fundingApprovedAt && (
                  <FormControl>
                    <Checkbox onChange={onAcceptedChange} isChecked={isAccepted}>
                      All payments were processed successfully
                    </Checkbox>
                  </FormControl>
                )}
                <PrimaryButton onClick={handleDownloadCSV}>Download CSV</PrimaryButton>
              </Stack>
            </PanelMiddle>
            <PanelBottom>
              {!loading && !paymentBatch?.fundingApprovedAt && (
                <PrimaryButton
                  width="100%"
                  size="lg"
                  isDisabled={!isAccepted || !isPaymentSubmitted}
                  onClick={onApprove}
                  isLoading={loading}
                >
                  Approve
                </PrimaryButton>
              )}
              {!loading && !!paymentBatch?.fundingApprovedAt && (
                <AlertInfo>
                  This Payment Batch was approved on{" "}
                  {DateTime.fromJSDate(paymentBatch.fundingApprovedAt).toFormat(DATE_MONTH_FORMAT)}.
                </AlertInfo>
              )}
            </PanelBottom>
          </PanelGroup>
          <PanelGroup>
            <PanelTop showBottomBorder>
              <Heading size="md">Payment Details</Heading>
            </PanelTop>
            <PanelBottom containsTable>
              <Table isEmpty={paymentDetails.length === 0} isLoading={loading}>
                <TableHead>
                  <TableRow key="head">
                    <TableHeaderCell>Publisher</TableHeaderCell>
                    <TableHeaderCell>Orders</TableHeaderCell>
                    <TableHeaderCell>Total Orders</TableHeaderCell>
                    <TableHeaderCell>Total Amount</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentDetails.map((item) => (
                    <TableRow key={item?.publisher?.id ?? "key"}>
                      <TableCell>{item?.publisher?.name ?? ""}</TableCell>
                      <TableCell>{item?.orderIds}</TableCell>
                      <TableCell>{item.orderCount}</TableCell>
                      <TableCell>{currencyFormatter.format(item.paymentAmount)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </PanelBottom>
          </PanelGroup>
        </Grid>
      </Container>
    </LayoutBase>
  );
}

import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, useToast } from "@chakra-ui/react";

import { useConfirmEmailMutation } from "../../generated/graphql";
import { Loader } from "../../components/loader";
import { AppColors } from "../../core/custom-theme";

export function ConfirmEmail() {
  const { confirmationToken } = useParams<"confirmationToken">();
  const navigate = useNavigate();
  const [confirmEmail, { loading }] = useConfirmEmailMutation();
  const toast = useToast();

  useEffect(() => {
    async function callConfirmEmail() {
      const response = await confirmEmail({
        variables: {
          input: {
            confirmationToken: confirmationToken ?? "",
          },
        },
      });

      if (response.data?.confirmEmail.ok) {
        toast({ title: "Confirm Email", description: "Your email address has been confirmed.", status: "error" });
      } else {
        toast({
          title: "Confirm Email",
          description: "Something went wrong while confirming your email. Please try again.",
          status: "error",
        });
      }
      navigate("/login");
    }

    callConfirmEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box bg={AppColors.body} display="flex" justifyContent="center" alignItems="center" height="100vh">
      {loading && <Loader message="Confirming your email" />}
    </Box>
  );
}

import {
  As,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  Portal,
  Stack,
  Text,
} from "@chakra-ui/react";
import AppIcon from "../../components/app-icon";
import { faCaretDown, faColumns } from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface CustomSelectorOption {
  icon?: IconDefinition;
  iconColor?: string;
  displayText: string;
  value: string;
  isDisabled?: boolean;
}

interface Props {
  items: CustomSelectorOption[];
  value: string | null;
  onChange: (value: string | null) => void;
  emptyValueTitle?: string;
  selectorIcon?: IconDefinition;
  allowEmpty?: boolean;
  dontUsePortal?: boolean;
  menuButtonAs?: As;
  isDisabled?: boolean;
}

export function CustomSingleSelect(props: Props) {
  const {
    items,
    onChange,
    value,
    emptyValueTitle,
    selectorIcon,
    allowEmpty,
    dontUsePortal = false,
    menuButtonAs = Button,
    isDisabled,
  } = props;

  function handleSelect(selectedValue: string | null) {
    if (selectedValue === value) {
      onChange(null);
    } else {
      onChange(selectedValue);
    }
  }

  const nullItem: CustomSelectorOption = {
    displayText: emptyValueTitle ?? "None",
    value: "",
  };

  let displayItems = items;
  if (allowEmpty) {
    displayItems = [nullItem, ...items];
  }

  const selectedItem = items.find((x) => x.value === value ?? "");

  const menuList = (
    <MenuList maxH={200} overflowY="scroll">
      {displayItems.length === 0 && <MenuItem isFocusable={false}>No items.</MenuItem>}
      {displayItems.map((item, index) => (
        <MenuItemOption
          fontSize="sm"
          key={index}
          value={item.value}
          isChecked={value === item.value}
          onClick={() => {
            handleSelect(item.value);
          }}
          isDisabled={item.isDisabled}
        >
          {item.displayText}
        </MenuItemOption>
      ))}
    </MenuList>
  );

  return (
    <Menu closeOnSelect={false} isLazy preventOverflow>
      <MenuButton as={menuButtonAs} isDisabled={isDisabled}>
        <Stack isInline spacing={2}>
          <AppIcon icon={selectorIcon ? selectorIcon : faColumns} />
          <Text isTruncated>{selectedItem?.displayText ?? emptyValueTitle ?? "None"}</Text>
          <AppIcon icon={faCaretDown} standardLeftMargin />
        </Stack>
      </MenuButton>
      {dontUsePortal ? menuList : <Portal>{menuList}</Portal>}
    </Menu>
  );
}

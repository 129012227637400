import { DateTime } from "luxon";
import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import {
  useAddOrderDrawer_CreateOrderMutation,
  useAddOrderDrawerQuery,
  AddOrderDrawer_PartnerProductFragment,
} from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useMemo } from "react";
import { DATE_FORMAT } from "../../constants/constants";
import { ACCOUNT_MANAGEMENT_PRODUCTS } from "../../constants/products";

interface FormValues {
  partnerProductId: number;
  instructions: string;
  quantity: number;
  startDate: Date;
  startImmediately: boolean;
  customerCampaignId: number;
}

interface Props {
  customerId: number;
  customerCampaignId: number;
  campaignCategoryId: string;
  onCancel: () => void;
  onOrderAdded: (orderId: number) => void;
  minimumDate?: Date | null;
  maximumDate?: Date | null;
}

export function useAddOrderDrawer(props: Props) {
  const { customerId, customerCampaignId, campaignCategoryId, onOrderAdded, onCancel } = props;
  const useAddOrderDrawerQueryResult = useAddOrderDrawerQuery({
    fetchPolicy: "network-only",
    variables: {
      customerId,
      campaignCategoryId,
    },
  });
  const [createOrderMutation] = useAddOrderDrawer_CreateOrderMutation();
  const toast = useToast();

  const partner = useAddOrderDrawerQueryResult.data?.customer?.partner ?? null;
  const customerKeywords = useAddOrderDrawerQueryResult.data?.customer?.customerKeywords.nodes ?? [];
  const partnerProducts = useAddOrderDrawerQueryResult.data?.customer?.partner.partnerProducts.nodes ?? [];

  const dateRangeStart = useMemo(
    () =>
      props.minimumDate
        ? DateTime.max(DateTime.now(), DateTime.fromJSDate(props.minimumDate)).toJSDate()
        : DateTime.now().plus({ hours: 1 }).startOf("hour").toJSDate(),
    [props.minimumDate]
  );
  const dateRangeEnd = props.maximumDate ?? DateTime.now().plus({ years: 1 }).toJSDate();

  const initialValues: FormValues = useMemo(
    () => ({
      partnerProductId: 0,
      customerKeywordId: null,
      instructions: "",
      quantity: 1,
      startDate: dateRangeStart,
      startImmediately: false,
      topic: "",
      customerCampaignId,
    }),
    [dateRangeStart, customerCampaignId]
  );

  const formik = useFormik<FormValues>({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers) => {
      const selectedPartnerProduct = partnerProducts.find((x) => x.id === values.partnerProductId);
      if (!selectedPartnerProduct) {
        return;
      }

      try {
        const response = await createOrderMutation({
          variables: {
            input: {
              customerId,
              partnerProductId: values.partnerProductId,
              customerKeywordId: null,
              instructions: values.instructions,
              quantity: values.quantity,
              startDate: DateTime.fromJSDate(values.startDate).toFormat(DATE_FORMAT),
              startImmediately: values.startImmediately,
              topic: null,
              customerCampaignId: values.customerCampaignId,
            },
          },
        });

        if (!response.data?.createOrder.order?.id) {
          toast({
            title: "Add Order",
            description: getDisplayMessageForError(
              response.errors?.[0] ?? new Error("Something went wrong while creating the order.")
            ),
            status: "error",
          });
          formikHelpers.setSubmitting(false);
          return;
        }
        onOrderAdded(response.data.createOrder.order.id);
        onCancel();
      } catch (e: any) {
        toast({ title: "Unable to create order.", description: getDisplayMessageForError(e), status: "error" });
        formikHelpers.setSubmitting(false);
      }
    },
  });

  const selectedPartnerProduct: AddOrderDrawer_PartnerProductFragment | null =
    partnerProducts.find((x) => x.id === formik.values.partnerProductId) ?? null;

  let price = 0;
  if (selectedPartnerProduct) {
    price =
      selectedPartnerProduct.partnerProductPrices.nodes.find((x) => x.startsAt <= formik.values.startDate)?.price ?? 0;
  }

  function handleCancel() {
    formik.resetForm();
    onCancel();
  }

  const canStartImmediately = DateTime.fromJSDate(formik.values.startDate).hasSame(DateTime.now(), "day");
  const isAccountManagementProductSelected = ACCOUNT_MANAGEMENT_PRODUCTS.includes(
    selectedPartnerProduct?.productId ?? ""
  );

  return {
    partnerProductsLoading: useAddOrderDrawerQueryResult.loading,
    partnerProducts,
    customerKeywords,
    formik,
    canStartImmediately,
    handleCancel,
    dateRangeStart,
    dateRangeEnd,
    price,
    partnerId: partner?.id ?? 0,
    isAccountManagementProductSelected,
  };
}

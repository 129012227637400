import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  SimpleGrid,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

import { CancelButton, PrimaryButton } from "../../components/buttons";
import { PublisherSelector } from "../publisher-selector";
import { LinkLocationSelector } from "../link-locations-selector";
import { useMapPublisherLinkLocation } from "./use-map-publisher-link-location";
import { LINK_LOCATION_TYPES } from "../../constants/link-location-type";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function MapPublisherLinkLocationDrawer(props: Props) {
  const { isOpen, onClose } = props;

  const { formik, onPublisherSelected, onLinkLocationSelected, onSave, onCancel } = useMapPublisherLinkLocation({
    onClose,
  });

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onCancel} placement="right" size="md" closeOnOverlayClick={false} isFullHeight>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Map Publisher to Link Location</DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Publisher</FormLabel>
                <PublisherSelector
                  onPublisherSelected={onPublisherSelected}
                  selectedPublisherId={formik.values.publisherId}
                  allowNull={true}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Link Location</FormLabel>
                <LinkLocationSelector
                  typeIds={[LINK_LOCATION_TYPES.OUTREACH.id]}
                  onLinkLocationSelected={onLinkLocationSelected}
                  selectedLinkLocationId={formik.values.linkLocationId}
                  allowNull={true}
                />
              </FormControl>
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <SimpleGrid columns={2} gridGap={2} width="100%">
              <PrimaryButton width="100%" onClick={onSave} isDisabled={formik.isSubmitting}>
                Save
              </PrimaryButton>
              <CancelButton onClick={onCancel} isDisabled={formik.isSubmitting}>
                Cancel
              </CancelButton>
            </SimpleGrid>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

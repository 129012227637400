import { useContext, useRef, useEffect } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Stack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  SimpleGrid,
  FormErrorMessage,
  Checkbox,
} from "@chakra-ui/react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { getDisplayMessageForError } from "../../util/error-helper";
import { useAddVendorMutation } from "../../generated/graphql";
import { PartnerSelector } from "../partner-selector";
import { AuthContext } from "../../core/auth-manager";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAdded: (vendorId: number) => void;
}

interface FormValues {
  partnerId: number | null;
  name: string;
  isExternal: boolean;
  createAnother: boolean;
}

const createVendorSchema = yup.object().shape({
  name: yup.string().label("Vendor Name").required(),
});

export function AddVendorDrawer(props: Props) {
  const { onClose, onAdded, isOpen } = props;
  const [addVendor] = useAddVendorMutation();
  const toast = useToast();
  const authContext = useContext(AuthContext);
  const nameFieldRef = useRef<HTMLInputElement>(null);

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await addVendor({
        variables: {
          input: {
            name: values.name,
            partnerId: values.partnerId,
            isExternal: values.isExternal,
          },
        },
      });

      if (response.data?.createVendor.vendor?.id) {
        toast({ title: "Success", description: "Vendor created successfully!", status: "success" });
        formikHelpers.resetForm();
        onAdded(response.data.createVendor.vendor.id);
      }

      if (values.createAnother) {
        formikHelpers.setSubmitting(false);
        nameFieldRef.current?.focus();
      } else {
        onClose();
      }
    } catch (e: any) {
      toast({ title: "Error", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      partnerId: authContext.isAdmin() ? null : 0,
      name: "",
      isExternal: false,
      createAnother: false,
    },
    enableReinitialize: true,
    validationSchema: createVendorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (formik.values.partnerId && formik.values.isExternal) {
      formik.setFieldValue("isExternal", false, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.partnerId, formik.values.isExternal]);

  function handleCancel() {
    formik.resetForm();
    onClose();
  }

  function handleSave(andCreateAnother?: boolean) {
    formik.setFieldValue("createAnother", andCreateAnother);
    formik.handleSubmit();
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel} placement="right" size="sm" closeOnOverlayClick={false}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add Vendor</DrawerHeader>
        <DrawerBody>
          <Stack spacing={4}>
            <FormControl isRequired isInvalid={!!formik.errors.name && !!formik.touched.name}>
              <FormLabel>Partner</FormLabel>
              <PartnerSelector
                onPartnerSelected={(partnerId) => {
                  formik.setFieldValue("partnerId", partnerId);
                }}
                selectedPartnerId={formik.values.partnerId}
                allowNull={authContext.isAdmin()}
                nullValueText="No Partner (System Vendor)"
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!formik.errors.name && !!formik.touched.name}>
              <FormLabel>Name</FormLabel>
              <Input variant="outline" value={formik.values.name} id="name" onChange={formik.handleChange} />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
            {authContext.isAdmin() && (
              <FormControl>
                <Checkbox
                  isDisabled={!!formik.values.partnerId}
                  isChecked={formik.values.isExternal}
                  onChange={formik.handleChange}
                  id="isExternal"
                >
                  Is External
                </Checkbox>
              </FormControl>
            )}
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <SimpleGrid columns={2} gridGap={4} width="100%">
            <PrimaryButton width="100%" onClick={handleSave.bind(null, false)}>
              Save
            </PrimaryButton>
            <SecondaryButton width="100%" onClick={handleSave.bind(null, true)}>
              Save &amp; Add Another
            </SecondaryButton>
          </SimpleGrid>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

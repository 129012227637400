import { PanelGroup, PanelTop, PanelBottom, PanelMiddle } from "../../components/panels";
import {
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Box,
  FormHelperText,
  FormErrorMessage,
  Textarea,
} from "@chakra-ui/react";
import { DangerButton, PrimaryButton, CancelButton } from "../../components/buttons";
import { PageHeading } from "../../components/page-heading";
import { WorkflowNotes } from "../workflow-notes";
import { HelpText } from "../../components/help-text";
import { useTaskWorkspaceChecklist } from "./use-task-workspace-checklist";
import { ChecklistItem } from "./checklist-item";

interface Props {
  taskId: number;
  onAbandon: () => void;
  onComplete: () => void;
}

export function TaskWorkspaceChecklist(props: Props) {
  const { taskId, onAbandon, onComplete } = props;
  const { task, checklistItems, onChecklistItemEdited, completedCount, onOrderResultUrlFormCancel, formik } =
    useTaskWorkspaceChecklist(props);
  return (
    <>
      <PageHeading>{task?.order.partnerProduct.name ?? "Checklist Task"}</PageHeading>
      <Grid templateColumns={{ base: "1fr", xl: "2fr 1fr" }} gridGap={4}>
        <Stack spacing={4} gridColumn={1}>
          <PanelGroup>
            <PanelTop showBottomBorder>
              <Heading size="md">Checklist</Heading>
            </PanelTop>
            <PanelMiddle>
              <Stack spacing={4} shouldWrapChildren>
                {checklistItems.map((checklistItem) => (
                  <ChecklistItem
                    key={checklistItem.id}
                    checklistItem={checklistItem}
                    onEdited={onChecklistItemEdited}
                  />
                ))}
              </Stack>
            </PanelMiddle>
            {completedCount === checklistItems.length && (
              <PanelMiddle>
                <form onSubmit={formik.handleSubmit}>
                  <Stack spacing={4}>
                    <FormControl isInvalid={!!formik.touched.resultUrl && !!formik.errors.resultUrl}>
                      <FormLabel>Order Result URL</FormLabel>
                      <Input
                        variant="outline"
                        id="resultUrl"
                        value={formik.values.resultUrl}
                        onChange={formik.handleChange}
                        placeholder="No Result URL"
                      />
                      <FormErrorMessage>{formik.errors.resultUrl}</FormErrorMessage>
                      <FormHelperText>Optional, must begin with http:// or https://</FormHelperText>
                    </FormControl>
                    <FormControl
                      isInvalid={!!formik.touched.resultUrlDomainAuthority && !!formik.errors.resultUrlDomainAuthority}
                    >
                      <FormLabel>Order Result URL Domain Authority</FormLabel>
                      <Input
                        variant="outline"
                        value={formik.values.resultUrlDomainAuthority ?? undefined}
                        name="resultUrlDomainAuthority"
                        type="number"
                        onChange={formik.handleChange}
                      />
                      <FormHelperText>Optional</FormHelperText>
                      <FormErrorMessage>{formik.errors.resultUrlDomainAuthority}</FormErrorMessage>
                    </FormControl>
                    {formik.dirty && (
                      <Stack spacing={2} isInline>
                        <PrimaryButton type="submit">Save</PrimaryButton>
                        <CancelButton onClick={onOrderResultUrlFormCancel}>Cancel</CancelButton>
                      </Stack>
                    )}
                  </Stack>
                </form>
              </PanelMiddle>
            )}
            <PanelBottom showTopBorder>
              <Stack isInline spacing={2} justifyContent="space-between" shouldWrapChildren>
                <PrimaryButton
                  isDisabled={formik.dirty}
                  onClick={() => {
                    onComplete();
                  }}
                >
                  Complete Task
                </PrimaryButton>
                <Box>
                  <DangerButton onClick={onAbandon}>Unassign Task</DangerButton>
                  <HelpText standardLeftMargin>
                    &quot;Unassign Task&quot; will unassign this task from you and return it to the queue.{" "}
                    <strong>This cannot be undone and may result in lost work.</strong>
                  </HelpText>
                </Box>
              </Stack>
            </PanelBottom>
          </PanelGroup>
          <WorkflowNotes taskId={taskId} />
        </Stack>
        <Stack spacing={4} gridColumn={{ base: 1, xl: 2 }}>
          <PanelGroup isCollapsible>
            <PanelTop showBottomBorder>
              <Heading size="md">Information</Heading>
            </PanelTop>
            <PanelBottom>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Task ID</FormLabel>
                  <Input value={taskId} variant="outline" isDisabled />
                </FormControl>
                <FormControl>
                  <FormLabel>Client</FormLabel>
                  <Input value={task?.customer.name ?? ""} variant="outline" isDisabled />
                </FormControl>
                <FormControl>
                  <FormLabel>Product Type</FormLabel>
                  <Input value={task?.order.partnerProduct.name ?? ""} variant="outline" isDisabled />
                </FormControl>
                <FormControl>
                  <FormLabel>Instructions</FormLabel>
                  <Textarea value={task?.order.instructions ?? "Not Provided"} variant="outline" isDisabled />
                </FormControl>
                <FormControl>
                  <FormLabel>Client Instructions</FormLabel>
                  <Textarea value={task?.customer?.instructions ?? "Not Provided"} variant="outline" isDisabled />
                </FormControl>
              </Stack>
            </PanelBottom>
          </PanelGroup>
        </Stack>
      </Grid>
    </>
  );
}

import { sortBy } from "lodash";
import { usePartnerSelectorV2Query } from "../../generated/graphql";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { CustomMultiSelect } from "../custom-selector";

interface Props {
  selectedPartnerIds: number[];
  onChange: (value: number[]) => void;
}

export function PartnerMultiSelect(props: Props) {
  const { onChange, selectedPartnerIds } = props;
  const queryResult = usePartnerSelectorV2Query({
    fetchPolicy: "network-only",
  });

  const partners = sortBy(queryResult.data?.partners.nodes ?? [], "name");

  function handlePartnerChanged(value: string[]) {
    onChange(value.map((x) => parseInt(x, 10)));
  }

  return (
    <CustomMultiSelect
      items={partners.map((partner) => ({ displayText: partner.name, value: partner.id.toString() }))}
      onChange={handlePartnerChanged}
      value={selectedPartnerIds.map((p) => p.toString())}
      pluralizedItemName="Partners"
      emptyValueTitle="All"
      selectorIcon={faHandshake}
    />
  );
}

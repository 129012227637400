import { sortBy } from "lodash";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";

import { useTaskTypeSelectorQuery } from "../../generated/graphql";
import { CustomMultiSelect } from "../custom-selector";
import { faUser } from "@fortawesome/free-regular-svg-icons";

interface Props {
  selectedTaskTypeIds: string[];
  onChange: (value: string[]) => void;
  excludeCustomerReviewTaskTypes?: true;
  showSelectAll?: boolean;
}

export function TaskTypeMultiSelect(props: Props) {
  const { excludeCustomerReviewTaskTypes = false } = props;

  const queryResult = useTaskTypeSelectorQuery({ fetchPolicy: "network-only" });

  const allTaskTypes = queryResult.data?.taskTypes.nodes ?? [];

  let unsortedTaskTypes = allTaskTypes;
  if (excludeCustomerReviewTaskTypes) {
    unsortedTaskTypes = allTaskTypes.filter(({ id }) => !(id.includes("customer") && id.includes("review")));
  }

  const taskTypes = sortBy(unsortedTaskTypes, "name");

  const items = taskTypes.map((taskType) => ({
    displayText: taskType.name,
    value: taskType.id,
    icon: taskType.isFulfilledByCustomer ? faUser : undefined,
  }));

  return (
    <CustomMultiSelect
      items={items}
      onChange={props.onChange}
      value={props.selectedTaskTypeIds}
      pluralizedItemName="Task Types"
      emptyValueTitle="All"
      selectorIcon={faClipboardList}
      showSelectAll={props.showSelectAll}
    />
  );
}

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
  TablePagination,
  TableFoot,
} from "../../components/table";
import { Text } from "@chakra-ui/react";
import AppLink from "../../components/app-link";
import { AppColors } from "../../core/custom-theme";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import { DATE_TIME_FORMAT, PAGE_LIMIT } from "../../constants/constants";
import AppIcon from "../../components/app-icon";
import { CustomerConversationsQueryResult } from "../../generated/graphql";
import ErrorCard from "../../components/error-card";
import { useAccessiblePartnerCount } from "../../common/use-accessible-partner-count";
import { TruncatedText } from "../../components/truncated-text";

interface Props {
  queryResult: CustomerConversationsQueryResult;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

function ConversationsTable(props: Props) {
  const { queryResult, onNextPage, onPreviousPage } = props;
  const { data, loading, error } = queryResult;
  const accessiblePartnerCount = useAccessiblePartnerCount();

  const totalCount = data?.customerConversations?.totalCount ?? 0;
  const customerConversations = data?.customerConversations.nodes ?? [];

  if (error) {
    return <ErrorCard error={error} />;
  }

  return (
    <Table isEmpty={customerConversations.length === 0} isLoading={loading}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>Subject</TableHeaderCell>
          <TableHeaderCell>Client</TableHeaderCell>
          <TableHeaderCell>Created At</TableHeaderCell>
          <TableHeaderCell>Last Client Reply</TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {customerConversations?.map((conversation) => {
          const message = conversation.customerMessages.nodes.find((message) => message.isFromCustomer);
          return (
            <TableRow key={conversation.id}>
              <TableCell>
                <AppLink to={`/messages/${conversation.id}`}>{conversation.subject}</AppLink>
              </TableCell>
              <TableCell>
                <AppLink to={`/clients/${conversation.customer.id}`}>{conversation.customer.name}</AppLink>
                {accessiblePartnerCount > 1 && (
                  <TruncatedText maxLength={30} fontSize="sm">
                    {conversation.customer.partner.name}
                  </TruncatedText>
                )}
              </TableCell>
              <TableCell>{DateTime.fromJSDate(conversation.createdAt).toFormat(DATE_TIME_FORMAT)}</TableCell>
              <TableCell>{message ? DateTime.fromJSDate(message.createdAt).toRelative() : "-"}</TableCell>
              <TableCell>
                {conversation.helpRequestedAt && (
                  <Text>
                    <AppIcon icon={faExclamationTriangle} standardRightMargin color="#FF8541" />
                    <Text color={AppColors.textNormal}>Help Requested</Text>
                  </Text>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <TableFoot>
        <TablePagination
          totalCount={totalCount}
          offset={queryResult.variables?.offset ?? 0}
          limit={queryResult.variables?.limit ?? PAGE_LIMIT}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
      </TableFoot>
    </Table>
  );
}

export default ConversationsTable;

import { Box, Popover, PopoverContent, PopoverTrigger, PopoverArrow, Stack } from "@chakra-ui/react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../components/app-icon";
import { AppColors } from "../../core/custom-theme";

interface OrderIssue {
  title: string;
  message: string;
}

interface Props {
  orderIssues: OrderIssue[];
}

export function OrderIssuesIndicator(props: Props) {
  const { orderIssues } = props;
  if (orderIssues.length === 0) {
    return null;
  }

  const issueCount = orderIssues.length;
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Stack isInline alignItems="center" spacing={1} style={{ color: AppColors.warning, cursor: "help" }}>
          <AppIcon icon={faExclamationTriangle} />
          <span style={{ marginLeft: "0.25rem" }}>{issueCount}</span>
        </Stack>
      </PopoverTrigger>
      <PopoverContent maxW={200}>
        <PopoverArrow />
        <Box backgroundColor="#333333" color="white" p={2} maxW={200}>
          <Stack spacing={2}>
            {orderIssues.map((issue) => (
              <Box key={issue.title}>{issue.message}</Box>
            ))}
          </Stack>
        </Box>
      </PopoverContent>
    </Popover>
  );
}

import { useState } from "react";
import { BoxProps, Box } from "@chakra-ui/react";
import { PanelContext } from "./context";
import { PanelStatus } from "./status";

interface Props extends BoxProps {
  isCollapsible?: boolean;
  isCollapsedByDefault?: boolean;
  status?: PanelStatus;
}

export function PanelGroup(props: Props) {
  const { isCollapsible, isCollapsedByDefault, status, ...rest } = props;
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedByDefault ?? false);

  function toggleCollapse() {
    setIsCollapsed(!isCollapsed);
  }

  return (
    <PanelContext.Provider value={{ isCollapsed, isCollapsible: isCollapsible ?? false, toggleCollapse, status }}>
      <Box marginBottom={4} {...rest} />
    </PanelContext.Provider>
  );
}

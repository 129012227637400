import { useEffect } from "react";
import { sortBy } from "lodash";
import { usePartnerSelectorQuery } from "../../generated/graphql";
import { SelectProps, Select } from "@chakra-ui/react";

interface Props extends SelectProps {
  selectedPartnerId?: number | null;
  onPartnerSelected: (partnerId: number | null) => void;
  allowNull?: boolean;
  nullValueText?: string;
}

export function PartnerSelector(props: Props) {
  const { onPartnerSelected, selectedPartnerId, allowNull, nullValueText, ...rest } = props;
  const queryResult = usePartnerSelectorQuery({
    fetchPolicy: "network-only",
  });

  const partners = sortBy(queryResult.data?.partners.nodes ?? [], "name");

  useEffect(() => {
    if (partners.length > 0 && (selectedPartnerId === 0 || selectedPartnerId === null) && !allowNull) {
      onPartnerSelected(partners[0].id);
    }
  }, [partners, selectedPartnerId, allowNull, onPartnerSelected]);

  return (
    <Select
      isDisabled={queryResult.loading}
      value={selectedPartnerId?.toString() ?? ""}
      onChange={(e) => {
        if (e.target.value === "") {
          onPartnerSelected(null);
        } else {
          onPartnerSelected(parseInt(e.target.value));
        }
      }}
      {...rest}
    >
      {allowNull && <option value="">{nullValueText ?? "No Partner"}</option>}
      {partners.map((partner) => (
        <option key={partner.id} value={partner.id.toString()}>
          {partner.name}
        </option>
      ))}
    </Select>
  );
}

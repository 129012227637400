import { StringParam, NumberParam, useQueryParam, withDefault } from "use-query-params";

import {
  CustomerConversationsSort,
  CustomerMessagesSort,
  useCustomerConversationsQuery,
} from "../../generated/graphql";
import { PAGE_LIMIT } from "../../constants/constants";

export function useMessages() {
  const [pageOffset, setPageOffset] = useQueryParam("pageOffset", withDefault(NumberParam, 0));
  const [search, setSearchParam] = useQueryParam("search", StringParam);
  const [sort, setSortParam] = useQueryParam("sort", withDefault(StringParam, CustomerConversationsSort.CreatedAtDesc));
  const [customerId, setCustomerIdParam] = useQueryParam("customerId", NumberParam);

  const hasFilters = !!search || !!customerId;

  const queryResult = useCustomerConversationsQuery({
    variables: {
      customerId: customerId as number,
      search,
      limit: PAGE_LIMIT,
      offset: pageOffset,
      sort: sort as CustomerConversationsSort,
      messageLimit: PAGE_LIMIT,
      messageOffset: 0,
      messageSort: CustomerMessagesSort.CreatedAtDesc,
    },
    fetchPolicy: "network-only",
  });

  function handleSort(value: any) {
    setSortParam(value as string);
  }

  function onCustomerSelected(selectedCustomerIds: number) {
    setCustomerIdParam(selectedCustomerIds);
    setPageOffset(0);
  }

  function onNextPage() {
    setPageOffset(pageOffset + PAGE_LIMIT);
  }

  function onPreviousPage() {
    setPageOffset(Math.max(pageOffset - PAGE_LIMIT, 0));
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam(event.target.value);
    setPageOffset(0);
  }

  function clearFilters() {
    setSearchParam("");
    setSortParam(CustomerConversationsSort.CreatedAtDesc);
    setCustomerIdParam(undefined);
    setPageOffset(0);
  }

  return {
    search,
    sort,
    customerId,
    hasFilters,
    queryResult,
    handleSort,
    onCustomerSelected,
    onNextPage,
    onPreviousPage,
    onChange,
    clearFilters,
  };
}

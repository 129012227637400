import { ReactNode } from "react";
import { Box, Popover, PopoverArrow, PopoverContent, PopoverTrigger, Text, Tooltip, useToast } from "@chakra-ui/react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
  TablePagination,
  TableFoot,
} from "../../components/table";
import {
  DomainsQueryResult,
  DomainTableFragment,
  useRefreshDomainAvailabilityMutation,
  usePurchaseDomainMutation,
  useCreateSiteMutation,
} from "../../generated/graphql";
import { PAGE_LIMIT } from "../../constants/constants";
import { DOMAIN_STATUS } from "../../constants/domain-status";
import AppLink from "../../components/app-link";
import { PrimaryButton } from "../../components/buttons";
import { DomainStatusIndicator } from "../../common/domain-status-indicator/domain-status-indicator";
import { getDisplayMessageForError } from "../../util/error-helper";
import { currencyFormatter } from "../../util/formatter";

interface Props {
  domainsQueryResult: DomainsQueryResult;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

export function AdminDomainsTable(props: Props) {
  const { domainsQueryResult, onNextPage, onPreviousPage } = props;
  const toast = useToast();
  const [refreshDomainAvailability, { loading: refereshLoading }] = useRefreshDomainAvailabilityMutation();
  const [createSite, { loading: createSiteLoading }] = useCreateSiteMutation();
  const [purchaseDomain, { loading: purchaseLoading }] = usePurchaseDomainMutation();
  const totalCount = domainsQueryResult.data?.domains.totalCount ?? 0;
  const domains = domainsQueryResult.data?.domains.nodes ?? [];

  async function onRefreshDomainAvailability(domainId: string) {
    try {
      const response = await refreshDomainAvailability({
        variables: {
          input: {
            domainId,
          },
        },
      });

      if (response.data?.refreshDomainAvailability.ok) {
        toast({
          title: "Success",
          description: `${domainId} will be refreshed.`,
          status: "success",
        });
      }
    } catch (e: any) {
      toast({
        title: "Error",
        description: getDisplayMessageForError(e),
        status: "error",
      });
    }
  }

  async function onCreateSite(domainId: string) {
    try {
      const response = await createSite({
        variables: {
          input: {
            id: domainId,
          },
        },
      });

      if (response.data?.createSite.site.id) {
        toast({
          title: "Success",
          description: `${domainId} will be refreshed.`,
          status: "success",
        });
      }
    } catch (e: any) {
      toast({
        title: "Error",
        description: getDisplayMessageForError(e),
        status: "error",
      });
    }
  }

  async function onPurchaseDomain(domainId: string) {
    try {
      const response = await purchaseDomain({
        variables: {
          input: {
            domainId,
          },
        },
      });

      if (response.data?.purchaseDomain.domain.id) {
        toast({
          title: "Success",
          description: `${domainId} was requested for purchase.`,
          status: "success",
        });
      }
    } catch (e: any) {
      toast({
        title: "Error",
        description: getDisplayMessageForError(e),
        status: "error",
      });
    }
  }

  function renderActionButton(domain: DomainTableFragment) {
    switch (domain.status.id) {
      case DOMAIN_STATUS.UNAVAILABLE.id:
      case DOMAIN_STATUS.UNKNOWN.id:
        return (
          <Tooltip
            label="Domain availability can be refreshed as often as once per hour."
            aria-label="Domain availability can be refreshed as often as once per hour."
          >
            <span>
              <PrimaryButton
                onClick={onRefreshDomainAvailability.bind(null, domain.id)}
                isDisabled={!domain.canRefreshAvailability || refereshLoading}
              >
                Refresh Availability
              </PrimaryButton>
            </span>
          </Tooltip>
        );
      case DOMAIN_STATUS.PURCHASED.id:
        return (
          <PrimaryButton onClick={onCreateSite.bind(null, domain.id)} isDisabled={!!domain.site || createSiteLoading}>
            {domain.site ? "Site Created" : "Create Site"}
          </PrimaryButton>
        );
      case DOMAIN_STATUS.AVAILABLE.id:
        return (
          <PrimaryButton onClick={onPurchaseDomain.bind(null, domain.id)} isDisabled={purchaseLoading}>
            Request Purchase{" "}
            {!!domain.purchasePrice &&
              domain.purchasePrice > 0 &&
              `(${currencyFormatter.format(domain.purchasePrice)})`}
          </PrimaryButton>
        );
    }
  }

  return (
    <Table isEmpty={domains.length === 0} isLoading={domainsQueryResult.loading}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>Domain/Name</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Registrar/Registrar ID</TableHeaderCell>
          <TableHeaderCell>Categories</TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {domains.map((domain) => {
          let categoryTooltip: ReactNode | string = "No categories.";
          if (domain?.domainCategories?.nodes) {
            categoryTooltip = domain.domainCategories.nodes.map((category) => (
              <Text key={category.id}>{category.category && category.category.name ? category.category.name : ""}</Text>
            ));
          }
          return (
            <TableRow key={domain.id}>
              <TableCell>
                <AppLink to={`/admin/domains/${domain.id}`}>{domain.id}</AppLink>
              </TableCell>
              <TableCell>
                <DomainStatusIndicator {...domain.status} message={domain.purchaseError || ""} />
              </TableCell>
              <TableCell>{domain.registrar ? `${domain.registrar}/${domain.registrarId}` : "-"}</TableCell>
              <TableCell>
                {/* TODO: Fix issue of popover */}
                <Popover trigger="hover">
                  <PopoverTrigger>
                    <span>{domain?.domainCategories?.nodes.length ?? 0}</span>
                  </PopoverTrigger>
                  <PopoverContent maxW={200}>
                    <PopoverArrow bg="#333333" />
                    <Box backgroundColor="#333333" color="white" p={2} maxW={200}>
                      {categoryTooltip}
                    </Box>
                  </PopoverContent>
                </Popover>
              </TableCell>
              <TableCell>
                <Box textAlign="right">{renderActionButton(domain)}</Box>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <TableFoot>
        <TablePagination
          totalCount={totalCount}
          offset={domainsQueryResult.variables?.offset ?? 0}
          limit={domainsQueryResult.variables?.limit ?? PAGE_LIMIT}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
      </TableFoot>
    </Table>
  );
}

export const FULFILLMENT_OPTIONS = {
  INTERNAL_FULFILLMENT: {
    id: "internal-fulfillment",
    display: "Internal Fulfillment",
  },
  EXTERNAL_FULFILLMENT: {
    id: "external-fulfillment",
    display: "External Fulfillment",
  },
};

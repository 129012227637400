import { Table, TableHead, TableBody, TableRow, TableHeaderCell, TableCell } from "../../components/table";
import { Text, Switch, useToast, Box, Button } from "@chakra-ui/react";
import { HelpText } from "../../components/help-text";
import {
  CustomerKeywordsQueryResult,
  UpdateCustomerKeywordInput,
  useUpdateCustomerKeywordMutation,
} from "../../generated/graphql";
import { getDisplayMessageForError } from "../../util/error-helper";
import { RichContent } from "../../components/rich-content";
import AppLink from "../../components/app-link";
import { ChangeIndicatorAbsoluteValue } from "../../components/change-indicator";
import AppIcon from "../../components/app-icon";
import { useMemo, useState } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { KeywordRankingChart } from "./keyword-ranking-chart";

interface Props {
  customerKeywordsQueryResult: CustomerKeywordsQueryResult;
  isPrimary?: boolean;
  searchEngine: string;
}

export function KeywordsTable(props: Props) {
  const { customerKeywordsQueryResult, isPrimary, searchEngine } = props;
  const [updateCustomerKeyword] = useUpdateCustomerKeywordMutation();
  const toast = useToast();
  const [selectedKeywordId, setSelectedKeywordId] = useState<number | null>(null);

  const customerKeywords = useMemo(
    () =>
      customerKeywordsQueryResult.data?.customer?.customerKeywords?.nodes?.filter(
        (keyword) => keyword.isPrimary === isPrimary
      ) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPrimary, customerKeywordsQueryResult.data?.customer?.customerKeywords?.nodes]
  );

  const selectedKeyword = useMemo(() => {
    return customerKeywords.find((x) => x.id === selectedKeywordId);
  }, [customerKeywords, selectedKeywordId]);

  async function updateKeyword(input: UpdateCustomerKeywordInput) {
    if (!input) {
      return;
    }

    try {
      await updateCustomerKeyword({
        variables: {
          input,
        },
      });
      if (input.isActive === false) {
        toast({
          title: "Keyword de-activated",
          description: "The keyword is now at the bottom of the list.",
          status: "success",
        });
      } else {
        toast({ title: "Customer Details Keyword", description: "Keyword was updated.", status: "success" });
      }
      await customerKeywordsQueryResult.refetch();
    } catch (error: any) {
      toast({ title: "Customer Details Keyword", description: getDisplayMessageForError(error), status: "error" });
    }
  }

  function selectKeyword(keywordId: number) {
    setSelectedKeywordId(keywordId);
  }

  return (
    <Table isEmpty={customerKeywords.length === 0} isLoading={customerKeywordsQueryResult.loading}>
      <TableHead>
        <TableRow key="head">
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Location</TableHeaderCell>
          <TableHeaderCell>Country</TableHeaderCell>
          <TableHeaderCell>Landing Page URL</TableHeaderCell>
          <TableHeaderCell>Instructions</TableHeaderCell>
          <TableHeaderCell>Start Rank</TableHeaderCell>
          <TableHeaderCell>Latest Rank</TableHeaderCell>
          <TableHeaderCell align="center">Active</TableHeaderCell>
          <TableHeaderCell align="center">
            Primary
            <HelpText standardLeftMargin>
              <Text fontSize="sm">
                Primary Keyword determines whether the keyword can be selected for orders that require a keyword to be
                specified.
              </Text>
            </HelpText>
          </TableHeaderCell>
          <TableHeaderCell align="center">Is Tracking Map</TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {customerKeywords.map((keyword) => {
          let latestRankChange: number | null = null;
          if (keyword.startRank) {
            if (keyword.latestRank) {
              // As rank is in reverse order 1 - high and 100 - low
              // We have calculated reverse change start - current not current - start
              latestRankChange = keyword.startRank.rank - keyword.latestRank.rank;
            }
          }

          const isSelected = selectedKeywordId === keyword.id;
          return (
            <>
              <TableRow key={keyword.id}>
                <TableCell>
                  <AppLink to={`/clients/${keyword.customerId}/keywords/${keyword.id}`}>{keyword.name}</AppLink>
                </TableCell>
                <TableCell>{keyword.location ?? "-"}</TableCell>
                <TableCell>{keyword.country ?? "-"}</TableCell>
                <TableCell>{keyword.landingPageUrl ?? "-"}</TableCell>
                <TableCell>
                  {!keyword.instructions && "-"}
                  {keyword.instructions && <RichContent content={keyword.instructions ?? ""} />}
                </TableCell>
                <TableCell>{keyword.startRank?.rank ?? "-"}</TableCell>
                <TableCell>
                  <ChangeIndicatorAbsoluteValue
                    changeAmount={latestRankChange}
                    absoluteValue={keyword.latestRank?.rank}
                    hideDownValue={true}
                  />
                </TableCell>
                <TableCell>
                  <Box textAlign="center">
                    <Switch
                      onChange={() => updateKeyword({ id: keyword.id, isActive: !keyword.isActive })}
                      isChecked={keyword.isActive}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box textAlign="center">
                    <Switch
                      isDisabled={!keyword.isActive}
                      onChange={() =>
                        updateKeyword({
                          id: keyword.id,
                          isPrimary: !keyword.isPrimary,
                        })
                      }
                      isChecked={keyword.isPrimary}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box textAlign="center">
                    <Switch
                      isDisabled={!keyword.isActive}
                      onChange={() =>
                        updateKeyword({
                          id: keyword.id,
                          isTrackingMapRank: !keyword.isTrackingMapRank,
                        })
                      }
                      isChecked={keyword.isTrackingMapRank}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Button
                    size="sm"
                    onClick={() => selectKeyword(keyword.id)}
                    isDisabled={isSelected}
                    variant="link"
                    colorScheme="blue"
                  >
                    {isSelected && <AppIcon icon={faCheck} standardRightMargin />}
                    {isSelected ? "Selected" : "View"}
                  </Button>
                </TableCell>
              </TableRow>
              {selectedKeywordId === keyword.id && (
                <TableRow>
                  <TableCell colSpan={11}>
                    <KeywordRankingChart keyword={selectedKeyword} searchEngine={searchEngine} />
                  </TableCell>
                </TableRow>
              )}
            </>
          );
        })}
      </TableBody>
    </Table>
  );
}

import { Box, Breadcrumb, BreadcrumbItem, Stack, Text } from "@chakra-ui/react";
import { faArrowRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../components/app-icon";
import { LayoutBase, MainNav } from "../../components/layout";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { AppColors } from "../../core/custom-theme";
import { useBillingPay } from "./billing-pay-hook";
import { BillingPaySelectOrders } from "./billing-pay-select-orders";
import { BillingPayReview } from "./billing-pay-review";
import { PartnerSelector } from "../../common/partner-selector";
import AppLink from "../../components/app-link";
// import { BillingStats } from "./billing-stats";

export function BillingPayRoute() {
  const useBillingPayValue = useBillingPay();
  const { currentView, partnerId, VIEWS, handlePartnerChange } = useBillingPayValue;
  return (
    <LayoutBase pageTitle="One Time Payment">
      {/* TODO: Change Prompt
      <Prompt when={selectedOrderIds.length > 0} message="Are you sure you want to discard your selections?" />
      */}
      <MainNav />
      <Container centerHorizontally>
        <Stack spacing={1} marginY={4}>
          <Box>
            <AppLink to={`/billing/${partnerId}`}>
              <AppIcon icon={faChevronLeft} standardRightMargin />
              Back to Billing
            </AppLink>
          </Box>
          <PageHeading preventMarginY>One Time Payment</PageHeading>
          {currentView === VIEWS.SELECT_ORDERS && (
            <PartnerSelector
              onPartnerSelected={handlePartnerChange}
              selectedPartnerId={partnerId}
              backgroundColor="white"
              maxW={300}
            />
          )}
        </Stack>
        <Breadcrumb spacing={4} separator={<AppIcon icon={faArrowRight} />} marginBottom={4}>
          <BreadcrumbItem>
            <Text
              fontWeight={currentView === VIEWS.SELECT_ORDERS ? "bold" : undefined}
              color={currentView === VIEWS.SELECT_ORDERS ? undefined : AppColors.textGray}
            >
              1. Select Orders
            </Text>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text
              fontWeight={currentView === VIEWS.REVIEW ? "bold" : undefined}
              color={currentView === VIEWS.REVIEW ? undefined : AppColors.textGray}
            >
              2. Review
            </Text>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text
              fontWeight={currentView === VIEWS.CONFIRMATION ? "bold" : undefined}
              color={currentView === VIEWS.CONFIRMATION ? undefined : AppColors.textGray}
            >
              3. Payment Submitted
            </Text>
          </BreadcrumbItem>
        </Breadcrumb>
        {currentView === VIEWS.SELECT_ORDERS && <BillingPaySelectOrders useBillingPayValue={useBillingPayValue} />}
        {currentView === VIEWS.REVIEW && <BillingPayReview useBillingPayValue={useBillingPayValue} />}
      </Container>
    </LayoutBase>
  );
}

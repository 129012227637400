import { ChecklistTaskWorkspace_ChecklistItemFragment } from "../../generated/graphql";
import { useChecklistItem } from "./use-checklist-item";
import { Checkbox } from "@chakra-ui/react";

interface Props {
  checklistItem: ChecklistTaskWorkspace_ChecklistItemFragment;
  onEdited: () => void;
}

export function ChecklistItem(props: Props) {
  const { checklistItem } = props;
  const { onCheckClick } = useChecklistItem(props);

  return (
    <Checkbox size="lg" colorScheme="blue" isChecked={!!checklistItem.completedAt} onChange={onCheckClick}>
      {checklistItem.name}
    </Checkbox>
  );
}

import { useEffect } from "react";
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import * as yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import DatePicker from "react-datepicker";
import { getDisplayMessageForError } from "../../util/error-helper";
import { PartnerProductDetailPriceFragment, useUpdatePartnerProductPriceMutation } from "../../generated/graphql";
import { HelpText } from "../../components/help-text";
import { DATE_PICKER_FORMAT } from "../../constants/constants";

interface Props {
  partnerProductPrice: PartnerProductDetailPriceFragment;
  onClose: () => void;
  onUpdated: () => void;
  enableSubmit: () => void;
  submitForm: boolean;
}

interface FormValues {
  price: number;
  startsAt: Date;
}

const updatePartnerProductPriceSchema = yup.object().shape({
  price: yup.number().label("Price"),
  startsAt: yup.date().label("Starts At"),
});

export function UpdatePartnerProductPrice(props: Props) {
  const { onClose, onUpdated, partnerProductPrice, submitForm, enableSubmit } = props;
  const [updatePartnerProductPrice] = useUpdatePartnerProductPriceMutation();
  const toast = useToast();

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const response = await updatePartnerProductPrice({
        variables: {
          input: {
            partnerProductPriceId: partnerProductPrice.id,
            price: values.price,
            startsAt: values.startsAt,
          },
        },
      });

      if (response.data?.updatePartnerProductPrice.partnerProductPrice.id) {
        toast({ title: "Price updated.", description: "Price was updated successfully.", status: "success" });
        formikHelpers.resetForm();
        onUpdated();
        onClose();
        enableSubmit();
      }
    } catch (e: any) {
      toast({ title: "Unable to Save Price", description: getDisplayMessageForError(e), status: "error" });
      formikHelpers.setSubmitting(false);
      enableSubmit();
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      price: partnerProductPrice.price,
      startsAt: partnerProductPrice.startsAt,
    },
    enableReinitialize: true,
    validationSchema: updatePartnerProductPriceSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (submitForm) {
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitForm]);

  function onStartDateChange(date: Date) {
    formik.setFieldValue("startsAt", date ?? new Date());
  }

  return (
    <Stack spacing={4}>
      <FormControl isInvalid={!!formik.errors.price && !!formik.touched.price}>
        <FormLabel>New Price</FormLabel>
        <InputGroup>
          <InputLeftElement color="gray.300" fontSize="1.2em">
            $
          </InputLeftElement>
          <Input
            placeholder="Enter Price"
            variant="outline"
            id="price"
            type="number"
            value={formik.values.price}
            onChange={formik.handleChange}
          />
        </InputGroup>
        <FormErrorMessage>{formik.errors.price}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!formik.errors.startsAt && !!formik.touched.startsAt}>
        <FormLabel>
          Start Date <HelpText standardLeftMargin>Changes will go into effect at 12:00 AM UTC</HelpText>
        </FormLabel>
        <DatePicker
          onChange={onStartDateChange}
          selected={formik.values.startsAt}
          minDate={new Date()}
          dateFormat={DATE_PICKER_FORMAT}
          customInput={<Input variant="outline" width="100%" />}
          placeholderText="Select start date"
          disabledKeyboardNavigation
        />
        <FormErrorMessage>{formik.errors.startsAt as string}</FormErrorMessage>
      </FormControl>
    </Stack>
  );
}

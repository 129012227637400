import { Input, InputGroup, InputRightAddon } from "@chakra-ui/react";
import { PrimaryIconButton } from "../../components/buttons";
import { faArrowDown, faArrowUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AppColors } from "../../core/custom-theme";

interface Props {
  name: string;
  onNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onMoveUp: () => void;
  isUpDisabled: boolean;
  onMoveDown: () => void;
  isDownDisabled: boolean;
  onDelete: () => void;
}

export function ChecklistItem(props: Props) {
  const { name, onNameChange, onMoveUp, isUpDisabled, onMoveDown, isDownDisabled, onDelete } = props;

  return (
    <InputGroup size="sm">
      <Input placeholder="Checklist Item" variant="outline" value={name} onChange={onNameChange} />
      <InputRightAddon backgroundColor={AppColors.white} borderTop={0} borderRight={0}>
        <PrimaryIconButton icon={faArrowUp} actionName="Move up" isDisabled={isUpDisabled} onClick={onMoveUp} />
        <PrimaryIconButton icon={faArrowDown} actionName="Move down" isDisabled={isDownDisabled} onClick={onMoveDown} />
        <PrimaryIconButton icon={faTrash} actionName="Delete" onClick={onDelete} />
      </InputRightAddon>
    </InputGroup>
  );
}
